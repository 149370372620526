import { IconProps } from './iconProps';

export const ChevronDownIcon: React.FC<IconProps> = ({ strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8 9.5999L12 14.3999L7.19999 9.5999"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
