export const routes = {
  // Public Routes
  auth: '/auth',
  login: '/auth/login',
  changePassword: '/auth/change-password',
  resetPassword: '/auth/reset-password',
  register: {
    root: '/auth/register',
    chooseTypeRegistration: ':userType',
    registrationSteps: ':userType/:registrationType',
    registerSuccess: '/auth/register/success',
    registerFailed: '/auth/register/failed',
  },
  forgotPassword: '/auth/forgot-password',
  verify: '/verify',
  expiredPage: '/expired',
  // Protected Routes
  root: '/',
  home: {
    root: '/home',
    aboutUs: '/home/about-us',
    newsletter: '/home/newsletter',
  },
  settings: '/settings',
  account: {
    root: '/account',
    overview: '/account/overview',
    profile: '/account/profile',
    accountManagement: {
      root: '/account/account-management',
      deleteAccount: '/account/account-management/delete-account',
    },
    address: '/account/address',
    verification: '/account/verification',
    wallet: '/account/wallet',
    transactions: {
      root: '/account/transactions',
      singleTransaction: '/account/transactions/:transactionType/:id',
    },
  },
  cases: {
    root: '/cases',
    active: {
      root: '/cases/active-cases',
      caseDetails: '/cases/active-cases/:id',
    },
    history: {
      root: '/cases/case-history',
      caseDetails: '/cases/case-history/:id',
    },
  },
  support: '/support',
  offering: {
    root: '/offering',
    smartNfts: {
      root: '/offering/smart-nfts',
      collections: '/offering/smart-nfts/collections',
      singleCollection: '/offering/smart-nfts/collections/:collectionId',
      collectibles: '/offering/smart-nfts/collections/:collectionId/collectibles',
      singleView: '/offering/smart-nfts/collections/:collectionId/collectibles/:nftId',
    },
    smartBonds: {
      root: '/offering/smart-bonds',
      singleView: '/offering/smart-bonds/:id',
    },
    smartShares: {
      root: '/offering/smart-shares',
      singleView: '/offering/smart-shares/:id',
    },
  },
  portfolio: {
    root: '/portfolio',
    overview: '/portfolio/overview',
    smartNfts: {
      root: '/portfolio/smart-nfts',
      nftHistory: '/portfolio/smart-nfts/history',
      assetDetails: '/portfolio/smart-nfts/:assetId',
    },
    smartShares: {
      root: '/portfolio/smart-shares',
      history: '/portfolio/smart-shares/history',
      details: '/portfolio/smart-shares/:assetId',
    },
    smartBonds: {
      root: '/portfolio/smart-bonds',
      bondHistory: '/portfolio/smart-bonds/history',
      assetDetails: '/portfolio/smart-bonds/:assetId',
    },
  },
  payment: {
    root: '/payment',
    success: '/payment/success',
    failed: '/payment/failed',
  },
  pageNotFound: '/page-not-found',
} as const;
