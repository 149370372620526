/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NftPortfolioAssetResponse } from '../models/NftPortfolioAssetResponse';
import type { PageableItems } from '../models/PageableItems';
import type { PortfolioBondAssetDTO } from '../models/PortfolioBondAssetDTO';
import type { PortfolioBondInvestmentStatisticsResponseDTO } from '../models/PortfolioBondInvestmentStatisticsResponseDTO';
import type { PortfolioInvestmentStatisticsResponseDTO } from '../models/PortfolioInvestmentStatisticsResponseDTO';
import type { PortfolioNFTInvestmentStatisticsResponseDTO } from '../models/PortfolioNFTInvestmentStatisticsResponseDTO';
import type { PortfolioShareAssetDTO } from '../models/PortfolioShareAssetDTO';
import type { PortfolioShareInvestmentStatisticsResponseDTO } from '../models/PortfolioShareInvestmentStatisticsResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientPortfolioApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns PortfolioInvestmentStatisticsResponseDTO Portfolio statistics
   * @throws ApiError
   */
  public getInvestmentStatistics(): CancelablePromise<PortfolioInvestmentStatisticsResponseDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/client/portfolio/investment-statistics',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param offset
   * @param limit
   * @param page
   * @returns any Get all Smart Share investments
   * @throws ApiError
   */
  public getSmartShareInvestments(
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/client/portfolio/share-assets',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns PortfolioShareAssetDTO Get single Smart Share investment
   * @throws ApiError
   */
  public getSmartShareInvestmentByOrderId(
    id: string,
  ): CancelablePromise<PortfolioShareAssetDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/client/portfolio/share-assets/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param offset
   * @param limit
   * @param page
   * @param serialNumber
   * @returns any Get all Smart NFT investments
   * @throws ApiError
   */
  public getSmartNftInvestments(
    offset?: number,
    limit?: number,
    page?: number,
    serialNumber?: string,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/client/portfolio/nft-assets',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'serialNumber': serialNumber,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns NftPortfolioAssetResponse Get single Smart NFT portfolio
   * @throws ApiError
   */
  public getSmartNftInvestmentByOrderId(
    id: string,
  ): CancelablePromise<NftPortfolioAssetResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/client/portfolio/nft-assets/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns PortfolioNFTInvestmentStatisticsResponseDTO Get Smart NFT investment statistics
   * @throws ApiError
   */
  public getSmartNftInvestmentStatistics(): CancelablePromise<PortfolioNFTInvestmentStatisticsResponseDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/client/portfolio/nft-investment-statistics',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns PortfolioShareInvestmentStatisticsResponseDTO Get Smart Share investment statistics
   * @throws ApiError
   */
  public getSmartShareInvestmentStatistics(): CancelablePromise<PortfolioShareInvestmentStatisticsResponseDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/client/portfolio/share-investment-statistics',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param offset
   * @param limit
   * @param page
   * @returns any Get Smart Bond investments
   * @throws ApiError
   */
  public getSmartBondInvestments(
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/client/portfolio/bond-assets',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns PortfolioBondAssetDTO Get Single Smart Bond investment
   * @throws ApiError
   */
  public getSmartBondInvestmentByOrderId(
    id: string,
  ): CancelablePromise<PortfolioBondAssetDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/client/portfolio/bond-assets/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns PortfolioBondInvestmentStatisticsResponseDTO Get Smart Bond investment statistics
   * @throws ApiError
   */
  public getSmartBondInvestmentStatistics(): CancelablePromise<PortfolioBondInvestmentStatisticsResponseDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/client/portfolio/bond-investment-statistics',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
