import { FC } from 'react';
import { ClipLoader } from 'react-spinners';
import { useTheme } from 'styled-components';

import { DocumentIcon, InvalidFileTypeIcon, UploadFailedIcon } from '../../../iconography';
import { Text } from '../../atoms/text';
import { ThemedIcon } from '../theme-icon';

import { DropzoneStatus } from './enums';
import { ContentWrapper, DropzoneWrapper, TextWrapper } from './styles/dropzone';

type DropzoneProps = {
  initialTitle: string;
  initialSubtitle: string;
  invalidTitle: string;
  invalidSubtitle: string;
  failedTitle: string;
  failedSubtitle: string;
  dropzoneStatus?: DropzoneStatus;
};

export const Dropzone: FC<DropzoneProps> = ({
  dropzoneStatus = DropzoneStatus.INITIAL,
  initialTitle,
  initialSubtitle,
  invalidTitle,
  invalidSubtitle,
  failedTitle,
  failedSubtitle,
}) => {
  const theme = useTheme();
  const renderDocumentIcon = () => (
    <ThemedIcon icon={DocumentIcon} customStrokeColor={theme.v2.text.bodySecondary} size={'xxl'} />
  );

  const renderInvalidFileIcon = () => (
    <ThemedIcon icon={InvalidFileTypeIcon} customStrokeColor={theme.v2.icon.error} size={'xxl'} />
  );

  const renderUploadFailedIcon = () => (
    <ThemedIcon icon={UploadFailedIcon} customStrokeColor={theme.v2.icon.error} size={'xxl'} />
  );
  return (
    <DropzoneWrapper>
      {dropzoneStatus === DropzoneStatus.INITIAL && (
        <ContentWrapper>
          {renderDocumentIcon()}
          <TextWrapper>
            <Text fontWeight={'bold'} fontSize={'base'}>
              {initialTitle}
            </Text>
            <Text fontSize={'sm'} lineHeight={'medium'} color={theme.v2.text.bodySecondary}>
              {initialSubtitle}
            </Text>
          </TextWrapper>
        </ContentWrapper>
      )}
      {dropzoneStatus === DropzoneStatus.LOADING && (
        <>
          <ClipLoader color={theme.colors.primary.hue300} />
        </>
      )}
      {dropzoneStatus === DropzoneStatus.INVALID && (
        <ContentWrapper>
          {renderInvalidFileIcon()}
          <TextWrapper>
            <Text fontWeight={'bold'} fontSize={'base'} color={theme.v2.text.error}>
              {invalidTitle}
            </Text>
            <Text fontSize={'sm'} color={theme.v2.text.bodySecondary}>
              {invalidSubtitle}
            </Text>
          </TextWrapper>
        </ContentWrapper>
      )}
      {dropzoneStatus === DropzoneStatus.FAILED && (
        <ContentWrapper>
          {renderUploadFailedIcon()}
          <TextWrapper>
            <Text fontWeight={'bold'} color={theme.v2.text.error}>
              {failedTitle}
            </Text>
            <Text fontSize={'sm'} color={theme.v2.text.bodySecondary}>
              {failedSubtitle}
            </Text>
          </TextWrapper>
        </ContentWrapper>
      )}
    </DropzoneWrapper>
  );
};
