import { styled } from 'styled-components';

export const CaseCardHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;

export const DateStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 0.625rem;
  border-left: 1px solid ${({ theme }) => theme.v2.border.primary};
`;

export const CaseDescriptionWrapper = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
