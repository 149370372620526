import { UserResponse } from '@metaswiss/api';

export const defaultUser: UserResponse = {
  id: '1',
  email: '',
  role: '',
  status: 'APPROVED',
  isFullyRegistered: true,
  isActive: true,
  displayName: '',
  userImage: '',
  city: '',
  address: '',
  zipCode: '',
  country: {
    id: '1',
    name: '',
    code: '',
    flag: '',
    phonePrefix: '',
    nationality: '',
    region: {
      id: '',
      name: '',
      code: '',
    },
  },
  phoneNumber: '',
  phoneNumberPrefix: '',
  firstTimeLogin: false,
  preferredLanguage: '',
};
