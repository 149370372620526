import { FieldValues, Path, FieldError, Controller, Control } from 'react-hook-form';
import styled from 'styled-components';

import { InputV2, InputV2Props } from '../../atoms/input/Input';

export type FormInputV2Props<T extends FieldValues> = {
  name: Path<T>;
  error?: FieldError;
  control: Control<T>;
  fill: boolean;
  disabled?: boolean;
  pattern?: RegExp;
} & Omit<InputV2Props, 'name' | 'error' | 'onChange' | 'value' | 'pattern'>;

export const StyledContainerV2 = styled.div<{ $fill?: boolean }>`
  margin-bottom: 1.25rem;
  margin-top: 0.5rem;
  max-width: ${({ $fill }) => ($fill ? '100%' : '450px')};
  width: 100%;
  position: relative;
  height: 3.5rem;
  z-index: 0;
`;

export const FormInputV2 = <T extends FieldValues>({
  name,
  error,
  control,
  fill,
  disabled = false,
  pattern,
  customErrorExists,
  ...inputProps
}: FormInputV2Props<T>) => {
  return (
    <StyledContainerV2 $fill={fill}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <InputV2
            onChange={(e) => {
              const value = pattern && !pattern?.test(e.target.value) ? field.value : e.target.value;
              field.onChange(value);
            }}
            value={field.value ?? ''}
            error={error?.message}
            disabled={disabled}
            customErrorExists={field.value ? customErrorExists : false}
            {...inputProps}
          />
        )}
      />
    </StyledContainerV2>
  );
};
