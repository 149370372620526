import { FC } from 'react';

import { IconProps } from './iconProps';

export const InformationCircle: FC<IconProps> = ({ color = 'none', strokeColor = '#222223' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00156 7.99961L8.00156 11.1996M8.00156 5.62773V5.59961M1.60156 7.99961C1.60156 4.46499 4.46694 1.59961 8.00156 1.59961C11.5362 1.59961 14.4016 4.46499 14.4016 7.99961C14.4016 11.5342 11.5362 14.3996 8.00156 14.3996C4.46694 14.3996 1.60156 11.5342 1.60156 7.99961Z"
        stroke={strokeColor}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
