import { FC } from 'react';

import { IconProps } from './iconProps';

export const HearthIcon: FC<IconProps> = ({ color = 'none', strokeColor = 'white' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.88097 3.78298C1.26201 5.49362 1.26201 8.26712 2.88097 9.97776L10.0001 17.5L17.1192 9.97776C18.7382 8.26712 18.7382 5.49362 17.1192 3.78298C15.5002 2.07234 12.8754 2.07234 11.2564 3.78298L10.0001 5.11043L8.74377 3.78298C7.1248 2.07234 4.49994 2.07234 2.88097 3.78298Z"
        stroke={strokeColor}
        strokeWidth="2"
      />
    </svg>
  );
};
