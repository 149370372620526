import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { routes } from '../../router/routes';

export const useRouteValidationCheck = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname.endsWith('/')) {
      navigate(routes.pageNotFound);
    }
  }, [navigate, pathname]);
};
