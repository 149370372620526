import { UserResponse } from '@metaswiss/api';
import { LoaderContainer } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import React, { FC } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { api } from '../../api/msApi';
import { getAccessToken } from '../../api/tokenHelpers';
import { ApiResource } from '../../enums/resource.enum';
import { AppState, useAppState } from '../../global-state/zustand';
import { useCheckResetTokenPassword } from '../../hooks/use-check-reset-token-password/useCheckResetTokenPassword';
import { routes } from '../../router/routes';
import { getQueryKey } from '../../shared/helpers/getQueryKey.helper';
import { Container } from '../protected-route/protectedRoute.styles';

type Props = {
  fallback?: string;
  customCondition?: boolean;
  children: React.ReactNode;
};

const PublicRoute: FC<Props> = ({ fallback, customCondition = true, children }) => {
  const accessToken = getAccessToken();
  const [searchParam] = useSearchParams();
  const tokenParam = searchParam.get('token') ?? '';

  const { setUser } = useAppState((state: AppState) => state);

  const { isIdle, isLoading } = useCheckResetTokenPassword({ resetToken: tokenParam });

  const { data: user } = useQuery({
    queryKey: getQueryKey(ApiResource.USER_RESPONSE),
    queryFn: () => {
      return api.users.getUserResponse();
    },
    onSuccess: (response: UserResponse) => {
      setUser(response);
    },
    enabled: !!accessToken,
  });

  if (isLoading || (isIdle && tokenParam)) {
    return (
      <Container>
        <LoaderContainer />
      </Container>
    );
  }

  if (accessToken && !user?.firstTimeLogin) {
    return <Navigate to={routes.home.root} />;
  }

  if (!customCondition) {
    return <Navigate to={fallback || '/'} />;
  }

  return <>{children}</>;
};

export default PublicRoute;
