import { FC } from 'react';

import { IconProps } from './iconProps';

export const EmailBaseIcon: FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width="28" height="22" viewBox="0 0 28 22" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.14112 2.3335L13.0895 10.3694C13.6374 10.7487 14.363 10.7487 14.9109 10.3694L26.0002 2.48321M4.4002 20.5999H23.6002C25.3675 20.5999 26.8002 19.1672 26.8002 17.3999V4.5999C26.8002 2.83259 25.3675 1.3999 23.6002 1.3999H4.4002C2.63288 1.3999 1.2002 2.83259 1.2002 4.5999V17.3999C1.2002 19.1672 2.63288 20.5999 4.4002 20.5999Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
