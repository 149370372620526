import { ReactNode, useRef } from 'react';

import { ShellContainer } from './pageShell.styles';

export type PageShellProps = {
  firstComponent?: ReactNode;
  secondComponent?: ReactNode;
  children: ReactNode;
  hasInfiniteScroll?: boolean;
  leftColor?: string;
  rightColor?: string;
};

export const PageShell = ({ firstComponent, secondComponent, children, leftColor, rightColor }: PageShellProps) => {
  const upperComponentWrapper = useRef<HTMLDivElement>(null);

  return (
    <ShellContainer $leftColor={leftColor} $rightColor={rightColor}>
      {(firstComponent || secondComponent) && (
        <div ref={upperComponentWrapper}>
          {firstComponent}
          {secondComponent}
        </div>
      )}

      {children}
    </ShellContainer>
  );
};
