import { Flex, SmallChipV2, SmallChipVariant } from '@metaswiss/ui-kit';

import { SectionHeaderContainer, SectionHeaderLine, SmallChipWrapper } from './chatHistorySectionHeader.styles';

type ChatHistorySectionHeaderProps = {
  isActive: boolean;
  date: string;
};

export const ChatHistorySectionHeader = ({ isActive, date }: ChatHistorySectionHeaderProps) => {
  return (
    <SectionHeaderContainer>
      <Flex alignItems={'center'} gap={'1rem'}>
        <SectionHeaderLine />
        <SmallChipWrapper>
          <SmallChipV2 label={date} variant={isActive ? SmallChipVariant.PRIMARY : SmallChipVariant.TERTIARY} />
        </SmallChipWrapper>
        <SectionHeaderLine />
      </Flex>
    </SectionHeaderContainer>
  );
};
