import { styled } from 'styled-components';

export const AvatarDropdownWrapper = styled.div`
  position: relative;
`;

export const ClientProfileDropdownWrapper = styled.div`
  position: relative;
  background-color: red;
  width: 100%;
`;
