import { IconProps } from './iconProps';

export const AvatarIcon: React.FC<IconProps> = ({ color = '#CED2DA' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2032_85462)">
        <rect width="180" height="180" fill={color} />
        <path
          d="M90.4246 29H89.5758C49.867 29 49.867 96.437 89.5758 96.437H90.4246C130.134 96.437 130.134 29 90.4246 29Z"
          fill="white"
        />
        <path
          d="M105.24 105.307C125.823 105.307 148.892 119.805 151.178 135.061C151.178 135.061 157.527 166.781 151.178 174.438C132.008 197.559 50.7419 195.457 29.1744 174.438C22.032 167.477 29.1744 135.061 29.1744 135.061C32.198 119.671 54.5297 105.307 75.1127 105.307H105.24Z"
          fill="white"
        />
      </g>
      <rect x="0.5" y="0.5" width="179" height="179" stroke="none" />
      <defs>
        <clipPath id="clip0_2032_85462">
          {/* TODO: replace white with light/dark variable from theme */}
          <rect width="100%" height="100%" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
