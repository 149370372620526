import styled from 'styled-components';

export const StyledButton = styled.button`
  border: 0;
  outline: 0;
  background: transparent;
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
`;
