import {
  ArrowRefresh02,
  CheckBroken,
  FolderIconPlus,
  Loader1Icon,
  DeleteIcon,
  SmallChipVariant,
  IconProps,
} from '@metaswiss/ui-kit';
import { TextColor } from '@metaswiss/ui-kit/src/theme/theme';
import { FC } from 'react';

import { CaseStatusEnum } from '../../enums/caseStatus.enum';

type CaseChipProps = {
  chipVariant: SmallChipVariant;
  chipTranslationKey: string;
  chipIcon: FC<IconProps>;
  chipIconColor: TextColor;
  textToolTipKey: string;
};

export const getCaseChipInfo = (caseStatus: string): CaseChipProps => {
  switch (caseStatus) {
    case CaseStatusEnum.OPENED:
      return {
        chipVariant: SmallChipVariant.PRIMARY,
        chipTranslationKey: 'chipStatus.opened',
        chipIcon: FolderIconPlus,
        chipIconColor: 'information',
        textToolTipKey: 'chipStatusToolTipText.opened',
      };
    case CaseStatusEnum.PENDING:
      return {
        chipVariant: SmallChipVariant.WARNING,
        chipTranslationKey: 'chipStatus.pending',
        chipIcon: Loader1Icon,
        chipIconColor: 'warning',
        textToolTipKey: 'chipStatusToolTipText.pending',
      };
    case CaseStatusEnum.CHANGES_REQUESTED:
      return {
        chipVariant: SmallChipVariant.ERROR,
        chipTranslationKey: 'chipStatus.changesRequested',
        chipIcon: ArrowRefresh02,
        chipIconColor: 'error',
        textToolTipKey: 'chipStatusToolTipText.changesRequested',
      };
    case CaseStatusEnum.APPROVED:
      return {
        chipVariant: SmallChipVariant.NEUTRAL,
        chipTranslationKey: 'chipStatus.resolved',
        chipIcon: CheckBroken,
        chipIconColor: 'disabled',
        textToolTipKey: 'chipStatusToolTipText.approved',
      };
    default:
      return {
        chipVariant: SmallChipVariant.ERROR,
        chipTranslationKey: 'unknown',
        chipIcon: DeleteIcon,
        chipIconColor: 'error',
        textToolTipKey: 'chipStatusToolTipText.opened',
      };
  }
};
