import { FC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTheme } from 'styled-components';

import { CameraIcon, UploadErrorIcon } from '../../../iconography';
import { AvatarIcon } from '../../../iconography/AvatarIcon';
import { Text } from '../../atoms/text';
import { ThemedIcon } from '../theme-icon';

import { AddAvatarIconWrapper } from './styles/addAvatarIconWrapper';
import { ErrorContainer } from './styles/errorContainer';
import { ImageBox } from './styles/imageBox';
import { ImageContainer } from './styles/imageContainer';
import { ProfileImage } from './styles/profileImage';
import { UploadAvatarContainer } from './styles/uploadAvatarContainer';
import { UploadAvatarProps } from './types';

export const UploadAvatar: FC<UploadAvatarProps> = ({
  uploadImage,
  imageSrc,
  isError,
  errorMessage,
  className,
  id,
  disabled = false,
  isRequiredError = false,
}) => {
  const theme = useTheme();

  const onDropAccepted = useCallback((acceptedFiles: File[]) => {
    uploadImage(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    accept: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'image/png': ['.png'],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'image/jpg': ['.jpg'],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'image/jpeg': ['.jpeg'],
    },
    maxFiles: 1,
    maxSize: 25 * 1000000,
    onDropAccepted,
    disabled: disabled,
  });

  return (
    <UploadAvatarContainer id={id} className={className}>
      <ImageBox $disabled={disabled} $isError={isRequiredError} {...getRootProps({ isDragAccept, isDragReject })}>
        {isError ? (
          <ErrorContainer>
            <UploadErrorIcon color={theme.v2.icon.error} />
            <Text color={theme.v2.text.error} fontSize={'xsm'}>
              {errorMessage}
            </Text>
          </ErrorContainer>
        ) : (
          <>
            {imageSrc ? (
              <ProfileImage src={imageSrc} />
            ) : (
              <ImageContainer>
                <AvatarIcon />
              </ImageContainer>
            )}
          </>
        )}
        {!disabled && (
          <AddAvatarIconWrapper $disabled={disabled}>
            <ThemedIcon icon={CameraIcon} customColor={theme.v2.icon.neutral} />
          </AddAvatarIconWrapper>
        )}
        <input {...getInputProps()} />
      </ImageBox>
    </UploadAvatarContainer>
  );
};
