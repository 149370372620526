import { Text } from '@metaswiss/ui-kit';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { CardWrapper, Logo, LogoContainer, TextWrapper } from './resourceWithLogo.styles';

type Props = {
  title: string;
  subTitle: string;
  addressTitle: string;
  logoTitle: string;
  issuerLogoUrl: string;
};
export const ResourceWithLogo: FC<Props> = ({ title, subTitle, logoTitle, addressTitle, issuerLogoUrl }) => {
  const theme = useTheme();

  return (
    <CardWrapper>
      <LogoContainer>
        <Logo src={issuerLogoUrl} alt={logoTitle} />
      </LogoContainer>
      <TextWrapper>
        <Text fontSize={'sm'} lineHeight={'medium'} color={theme.v2.text.disabled}>
          {subTitle}
        </Text>
        <Text fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
          {title}
        </Text>
        <Text fontSize={'sm'} lineHeight={'medium'} fontWeight={'semi'} color={theme.v2.text.headingPrimary}>
          {addressTitle}
        </Text>
      </TextWrapper>
    </CardWrapper>
  );
};
