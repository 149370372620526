import { TextLinkButton } from '@metaswiss/ui-kit/src/components/atoms/text-link/styles/textLinkButton';
import { styled } from 'styled-components';

export const ExtraDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding-bottom: 1rem;
`;

export const ExtraDetailsHeadingWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

export const CardItem = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

export const ItemTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemIconWrapper = styled.div`
  padding: 0.5rem;
  font-size: 0;
`;

export const StyledLink = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.25rem;
  padding-bottom: 1rem;
  ${TextLinkButton} {
    display: flex;
    gap: 0.75rem;
    align-items: center;
  }
`;
