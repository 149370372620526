import { NftPortfolioAssetResponse } from '@metaswiss/api';
import { mapDate } from '@metaswiss/lib';
import {
  DataStatusPage,
  NftDetails,
  PageStateContainer,
  ThemedIcon,
  TrendUpCollectibleIcon,
  TrendUpNftIcon,
} from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi';
import { AssetDetails } from '../../../../../components/asset-details/AssetDetails';
import { AssetType } from '../../../../../enums/assetType';
import { ProductType } from '../../../../../enums/productType.enum';
import { ApiResource } from '../../../../../enums/resource.enum';
import { useHeaderOptions, useShellNavigationState } from '../../../../../global-state/zustand';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../router/routes';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper';
import { tenantConfig } from '../../../../../tenantConfig.ts';

export const NftAssetDetails = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { assetId } = useParams();
  const { textTranslation } = useTextTranslation();
  const { removeTabs } = useShellNavigationState();
  const { setHeaderTitle } = useHeaderOptions();
  const [isLoadingDownloadImage, setIsLoadingDownLoadImage] = useState<boolean>(false);

  const {
    data: nftData,
    isLoading: isLoadingNftData,
    isError: isErrorNftData,
    refetch: refetchNftData,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT_TRANSACTION, assetId),
    queryFn: () => api.clientPortfolio.getSmartNftInvestmentByOrderId(assetId || ''),
    enabled: !!assetId,
  });

  const handleDownloadImage = async (data: NftPortfolioAssetResponse | undefined, imageName: string) => {
    if (!data) return;

    setIsLoadingDownLoadImage(true);
    try {
      const response = await fetch(data.nftImageUrl);
      const imageBlob = await response.blob();
      createLink(imageBlob);
    } finally {
      setIsLoadingDownLoadImage(false);
    }

    function createLink(imageData: Blob) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(imageData);
      link.download = imageName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    }
  };

  useLayoutEffect(() => {
    removeTabs();
    setHeaderTitle(textTranslation('portfolio.assetDetails'));
  }, [removeTabs, textTranslation, setHeaderTitle]);

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={isLoadingNftData}
      showError={true}
      isError={isErrorNftData}
      onTryAgain={async () => await Promise.all([refetchNftData()])}
      showEmptyState={true}
      isEmptyState={!nftData}
      textTranslation={textTranslation}
      customEmptyStateComponent={
        <DataStatusPage
          icon={
            <ThemedIcon
              icon={tenantConfig.useNftName ? TrendUpNftIcon : TrendUpCollectibleIcon}
              customColor={theme.v2.surface.successLight}
              customStrokeColor={theme.v2.text.success}
              size={'full'}
            />
          }
          title={textTranslation('portfolio.noNfts')}
          content={textTranslation('portfolio.noNftsPurchased')}
          buttonText={textTranslation('portfolio.exploreNfts')}
          action={() => navigate(routes.offering.smartNfts.root)}
          additionalContent={textTranslation('portfolio.noNftsFollow')}
          iconWidth={'8.5rem'}
          iconHeight={'8.5rem'}
        />
      }
    >
      <AssetDetails
        heading={textTranslation('portfolio.assetDetails')}
        amount={`${nftData?.nftPrice}`}
        date={mapDate(nftData?.investmentDate || '')}
        extraDetails={
          nftData && (
            <NftDetails
              nftImageUrl={nftData.nftThumbnailUrl}
              nftId={nftData.nftId}
              //TODO - remove chain once the design gets updated
              chain={'MetaSwiss / GreenState Tenant'}
              creator={nftData.issuer.issuerName}
              productImageUrl={nftData.productImageUrl}
              productHeading={nftData.productName}
              productDescription={nftData.productDescription.join(', ')}
              textTranslation={textTranslation}
              detailsTranslationKey={'global.details'}
              nftIdTranslationKey={'global.nftId'}
              chainTranslationKey={'global.chain'}
              creatorTranslationKey={'global.creator'}
              productDetailsTranslationKey={'global.productDetails'}
              withDownloadButton={true}
              downloadTextTranslationKey={'portfolio.nftImageDownloadText'}
              downloadButtonTextTranslationKey={'portfolio.download'}
              downloadImgBtn={{
                handleDownloadImage: () => handleDownloadImage(nftData, 'downloaded_image'),
                loadingDownloadImage: isLoadingDownloadImage,
              }}
            />
          )
        }
        productType={ProductType.NFT}
        transcationId={nftData?.transactionId ?? ''}
        documents={nftData?.documents || []}
        assetType={AssetType.PORTFOLIO}
      />
    </PageStateContainer>
  );
};
