import { BaseCard } from '@metaswiss/ui-kit';
import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import { styled } from 'styled-components';

export const AboutUsContent = styled.div`
  display: flex;
  gap: 1rem;
`;

export const AboutUsCard = styled(BaseCard)`
  width: 100%;
`;

export const AboutUsTextContainer = styled.div`
  display: flex;
  height: 8.75rem;
  flex: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  white-space: pre-wrap;
`;

export const AboutUsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
`;

export const AboutUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
  flex: 1;

  @media ${breakpoints.laptopMd} {
    border-top: ${({ theme }) => `1px solid ${theme.v2.border.primary}`};
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  height: 8.75rem;
  width: 17.5rem;
`;
