import { format, formatISO, isToday, parse, parseISO } from 'date-fns';

type MapIsoStringToDateProps = {
  date: string;
  splitChar?: string;
  endWithChar?: boolean;
};
export const mapDateToIsoString = (date: string) => {
  const dateObject = parse(date, 'dd/MM/yyyy', new Date());
  return formatISO(dateObject, { representation: 'date' });
};

export const mapIsoStringToDate = ({ date, splitChar = '/', endWithChar = false }: MapIsoStringToDateProps) => {
  if (date.length === 0) {
    return '';
  }
  const formattedDate = parseISO(date);

  return format(formattedDate, `dd${splitChar}MM${splitChar}yyyy${endWithChar ? splitChar : ''}`);
};

export const mapDate = (date: string) => {
  if (date.length === 0) {
    return '';
  }
  const dateObject = new Date(date);
  return format(dateObject, 'dd/MM/yyyy');
};

export const getFormattedTime = (date: string) => {
  if (date.length === 0) {
    return '';
  }
  const dateObject = new Date(date);
  return format(dateObject, 'HH:mm');
};

export const checkIsCurrentDay = (date: string) => {
  if (!date) {
    return false;
  }
  const isoDate = date.split('/').reverse().join('-');
  const givenDate = parseISO(isoDate);

  return isToday(givenDate);
};

export const mapIsoStringToDateStartingWithYear = (date: string) => format(new Date(date), 'yyyy-MM-dd');
