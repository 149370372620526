import { BaseHttpRequest, OpenAPIConfig, CancelablePromise } from '@metaswiss/api';
import { ApiRequestOptions } from '@metaswiss/api/src/api/core/ApiRequestOptions';
import axios from 'axios';
import axiosRetry from 'axios-retry';

import { request } from './customAxiosRequest';

export class CustomAxiosHttpRequest extends BaseHttpRequest {
  axiosInstance = axios.create();

  constructor(config: OpenAPIConfig) {
    super(config);
    axiosRetry(this.axiosInstance);
  }

  public override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
    return request(this.config, options, this.axiosInstance);
  }
}
