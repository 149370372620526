import { NftCollectionResponse } from '@metaswiss/api';
import { formatAmount } from '@metaswiss/lib';
import { BaseCard, Flex, Text } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../../api/msApi.ts';
import { AssignedUserAssetEnum } from '../../../../../../enums/assignedUserAssetEnum.ts';
import { ApiResource } from '../../../../../../enums/resource.enum.ts';
import { AppState, useAppState } from '../../../../../../global-state/zustand.ts';
import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation.ts';
import { routes } from '../../../../../../router/routes.ts';
import { findAssetByType } from '../../../../../../shared/helpers/findAssetByType.helper.ts';
import { getQueryKey } from '../../../../../../shared/helpers/getQueryKey.helper.ts';

import { BodySection, HeaderSection, LogoContainer } from './collectionCard.styles.ts';

type CollectionCardProps = {
  collection: NftCollectionResponse;
};
export const CollectionCard: FC<CollectionCardProps> = ({ collection }) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const navigate = useNavigate();
  const currency = useAppState((state: AppState) => state.currency);

  const { data: bannerUrl } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT_COLLECTION_BANNER, collection?.id),
    queryFn: () => {
      const banner = findAssetByType(collection.assets, AssignedUserAssetEnum.BANNER);
      return api.assets.getS3SignedAssignedUrl({ assetId: banner?.id || '' });
    },
  });

  const { data: logoUrl } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT_COLLECTION_LOGO, collection?.id),
    queryFn: () => {
      const logo = findAssetByType(collection.assets, AssignedUserAssetEnum.LOGO);
      return api.assets.getS3SignedAssignedUrl({ assetId: logo?.id || '' });
    },
  });

  return (
    <BaseCard
      override={{
        borderRadius: theme.v2.radius.small,
        backgroundColor: theme.v2.surface.primary,
        borderColor: theme.v2.border.primary,
      }}
      width={'100%'}
      padding={'0rem'}
      disableStateEffects={true}
      hasCursorPointer={true}
      onClick={() => navigate(`${routes.offering.smartNfts.collections}/${collection.id}`)}
    >
      <HeaderSection $bannerUrl={bannerUrl?.url || ''}>
        <LogoContainer $logoUrl={logoUrl?.url || ''} />
      </HeaderSection>
      <BodySection>
        <Flex gap={'0.25rem'} flexDirection={'column'}>
          <Text fontWeight={'bold'}>{collection.name}</Text>
          <Flex gap={'1rem'}>
            <Text fontSize={'sm'} lineHeight={'medium'}>
              {textTranslation('home.pricePerNft')}
            </Text>
            <Text fontSize={'sm'} fontWeight={'bold'} lineHeight={'medium'}>
              {formatAmount(collection.price, currency?.decimals, currency?.rate, currency?.currencyCode)}
            </Text>
          </Flex>
        </Flex>
      </BodySection>
    </BaseCard>
  );
};
