import { ChangeEvent, FC, useCallback, useState } from 'react';
import { useTheme } from 'styled-components';

import { ThemedIcon } from '../../../components';
import { AlertIcon, EyeClosedIcon, EyeOpenIcon } from '../../../iconography';
import { ColorPalette, Colors, IconSize } from '../../../theme';

import {
  EndIconWrapper,
  StartIconWrapper,
  StyledAlertIconWrapper,
  StyledButton,
  StyledError,
  StyledField,
  StyledInput,
  StyledLabel,
  StyledLegend,
  StyledSpan,
  StyledText,
  TextWrapper,
} from './input.styles.ts';
import { MissingValue } from './shared/components/MissingValue.tsx';

type InputElementProps = Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange'>;

type ExtraInputProps = {
  error?: string;
  disableFocus?: boolean;
  renderStartIcon?: FC<{ iconSize: IconSize }>;
  renderEndIcon?: FC<{ iconSize: IconSize }>;
  onEndIconClicked?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  fitHeight?: boolean;
  isLocked?: boolean;
  customErrorExists?: boolean;
  isOptional?: boolean;
  alertIconShown?: boolean;
  backgroundColor?: Colors<ColorPalette>;
  palette?: ColorPalette;
  isVisibleLabel?: boolean;
};

export type InputProps = InputElementProps & ExtraInputProps;

export const Input: React.FC<InputProps> = ({
  onEndIconClicked,
  error,
  renderStartIcon,
  renderEndIcon,
  disableFocus = false,
  disabled = false,
  value,
  label,
  type,
  onChange,
  fitHeight = false,
  isLocked = false,
  customErrorExists = false,
  isOptional = false,
  alertIconShown = false,
  isVisibleLabel = true,
  ...props
}) => {
  const theme = useTheme();
  const startIcon = renderStartIcon?.({ iconSize: 'medium' });
  const endIcon = renderEndIcon?.({ iconSize: 'medium' });
  const [revealPassword, setRevealPassword] = useState<boolean>(false);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const isPassword = type === 'password';
  const toggleRevealPassword = () => {
    setRevealPassword((passwordType) => !passwordType);
  };

  const handleMouseDownPassword = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
  }, []);
  return (
    <>
      <StyledLabel $fitHeight={fitHeight} $isLocked={isLocked}>
        {isLocked && !value ? (
          <MissingValue label={label} isOptional={isOptional} />
        ) : (
          <>
            <StyledInput
              $isActiveInput={!!value}
              $isVisibleLabel={isVisibleLabel}
              $isError={!!error}
              $isIconShown={!!renderStartIcon}
              $isIconEndShown={!!renderEndIcon}
              $customErrorExists={!!customErrorExists}
              $disableFocus={disableFocus}
              $isPassword={isPassword}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
              value={value}
              onChange={onChange}
              disabled={disabled}
              $isLocked={isLocked}
              $isInputFocused={isInputFocused}
              type={revealPassword ? 'text' : type}
              {...props}
            />
            <TextWrapper $isLocked={isLocked}>
              {!value && !isOptional && !renderStartIcon && !isInputFocused && alertIconShown && (
                <StyledAlertIconWrapper>
                  <ThemedIcon icon={AlertIcon} customStrokeColor={theme.v2.icon.error} size="medium" />
                </StyledAlertIconWrapper>
              )}

              <StyledText
                $isAlertIconShown={!isOptional && !value && alertIconShown}
                $isIconShown={!!renderStartIcon}
                $isLocked={isLocked}
              >
                {label}
              </StyledText>
            </TextWrapper>
            {!isLocked && endIcon && (
              <StyledButton $isDisabled={disabled} tabIndex={-1} type={'button'}>
                <EndIconWrapper onClick={onEndIconClicked}>{endIcon}</EndIconWrapper>
              </StyledButton>
            )}
            {isPassword && (
              <>
                <StyledButton
                  tabIndex={-1}
                  type="button"
                  onClick={toggleRevealPassword}
                  onMouseDown={handleMouseDownPassword}
                  $isDisabled={disabled}
                >
                  <EndIconWrapper>
                    {!revealPassword ? (
                      <ThemedIcon icon={EyeClosedIcon} size="medium" customStrokeColor={theme.v2.icon.primary} />
                    ) : (
                      <ThemedIcon icon={EyeOpenIcon} size="medium" customStrokeColor={theme.v2.icon.primary} />
                    )}
                  </EndIconWrapper>
                </StyledButton>
              </>
            )}
            {error && !isLocked && <StyledError>{error}</StyledError>}
          </>
        )}
        <StyledField $isLocked={isLocked}>
          <StyledLegend $isLocked={isLocked} $hasContent={!!value}>
            <StyledSpan>{label}</StyledSpan>
          </StyledLegend>
          {startIcon && <StartIconWrapper>{startIcon}</StartIconWrapper>}
        </StyledField>
      </StyledLabel>
    </>
  );
};
