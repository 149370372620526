import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import { styled } from 'styled-components';

export const BannerContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  margin-bottom: 1rem;
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.default};
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @media ${breakpoints.laptopMd} {
    height: 25rem;
    max-width: 25rem;
    margin-bottom: 0;
  }
`;

export const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: center;
  object-position: left;
`;
