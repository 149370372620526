import { IconProps } from './iconProps';

export const LuggageIcon: React.FC<IconProps> = ({ color = 'none', strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.39999 7.80001V5.40001C8.39999 4.73727 8.93725 4.20001 9.59999 4.20001H13.8C14.4627 4.20001 15 4.73727 15 5.40001V7.80001M4.79999 19.8H19.2C20.5255 19.8 21.6 18.7255 21.6 17.4V10.2C21.6 8.87453 20.5255 7.80001 19.2 7.80001H4.79999C3.47451 7.80001 2.39999 8.87453 2.39999 10.2V17.4C2.39999 18.7255 3.47451 19.8 4.79999 19.8Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
