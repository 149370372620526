import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import ProtectedRoute from '../components/protected-route/ProtectedRoute';
import PublicRoute from '../components/public-route/PublicRoute';
import { ProtectedLayout } from '../layouts/protected-layout/ProtectedLayout';
import { PublicLayout } from '../layouts/public-layout/PublicLayout';
import { VerifyLayout } from '../layouts/verify-layout/VerifyLayout';
import { Error404 } from '../pages/error/error-404/Error404';
import { Maintenance } from '../pages/maintenance-mode/Maintenance.tsx';
import { Account } from '../pages/protected/account/Account';
import { AccountManagement } from '../pages/protected/account/account-management/AccountManagement';
import { AccountManagementOveriew } from '../pages/protected/account/account-management/account-management-overview/AccountManagementOverview.tsx';
import { DeleteAccount } from '../pages/protected/account/account-management/delete-account/DeleteAccount.tsx';
import { Address } from '../pages/protected/account/address/Address';
import { Overview } from '../pages/protected/account/overview/Overview.tsx';
import { Profile } from '../pages/protected/account/profile/Profile';
import { Transactions } from '../pages/protected/account/transactions/Transactions.tsx';
import { AllTransactions } from '../pages/protected/account/transactions/all-transactions/AllTransactions.tsx';
import { SingleTransaction } from '../pages/protected/account/transactions/single-transaction/SingleTransaction.tsx';
import { Verification } from '../pages/protected/account/verification/Verification';
import { Wallet } from '../pages/protected/account/wallet/Wallet.tsx';
import { Cases } from '../pages/protected/cases/Cases';
import { ActiveCases } from '../pages/protected/cases/active-cases/ActiveCases';
import { ActiveCasesRoot } from '../pages/protected/cases/active-cases/ActiveCasesRoot.tsx';
import { CaseDetails } from '../pages/protected/cases/case-details/CaseDetails.tsx';
import { CaseHistory } from '../pages/protected/cases/cases-history/CaseHistory';
import { CaseHistoryRoot } from '../pages/protected/cases/cases-history/CaseHistoryRoot.tsx';
import { Home } from '../pages/protected/home/Home';
import { AboutUs } from '../pages/protected/home/about-us/AboutUs';
import { Dashboard } from '../pages/protected/home/dashboard/Dashboard';
import { Newsletter } from '../pages/protected/home/newsletter/Newsletter';
import { Settings } from '../pages/protected/home/settings/Settings';
import { Offering } from '../pages/protected/offering/Offering';
import { SmartBonds } from '../pages/protected/offering/smart-bonds/SmartBonds';
import { AllBonds } from '../pages/protected/offering/smart-bonds/all-bonds/AllBonds.tsx';
import SingleBond from '../pages/protected/offering/smart-bonds/single-bond/SingleBond.tsx';
import { SmartNftsOffering } from '../pages/protected/offering/smart-nfts/SmartNftsOffering';
import { Collectibles } from '../pages/protected/offering/smart-nfts/collectibles/Collectibles';
import { Collections } from '../pages/protected/offering/smart-nfts/collections/Collections.tsx';
import { NftsHandler } from '../pages/protected/offering/smart-nfts/nfts-handler/NftsHandler.tsx';
import { SingleCollection } from '../pages/protected/offering/smart-nfts/single-collection/SingleCollection.tsx';
import { SingleView } from '../pages/protected/offering/smart-nfts/single-view/SingleView';
import { SmartShares } from '../pages/protected/offering/smart-shares/SmartShares.tsx';
import { SharesHandler } from '../pages/protected/offering/smart-shares/shares-handler/SharesHandler.tsx';
import { SingleShareHandler } from '../pages/protected/offering/smart-shares/single-share/SingleShare';
import { BasePayment } from '../pages/protected/payment/BasePayment';
import { Portfolio } from '../pages/protected/portfolio/Portfolio';
import { Overview as OverviewPortfolio } from '../pages/protected/portfolio/overview/Overview.tsx';
import { BondsPortfolio } from '../pages/protected/portfolio/smart-bonds/BondsPortfolio';
import { SmartBonds as SmartBondsPortfolio } from '../pages/protected/portfolio/smart-bonds/SmartBonds.tsx';
import { BondAssetDetails } from '../pages/protected/portfolio/smart-bonds/asset-details/BondAssetDetails';
import { SmartBondHistory } from '../pages/protected/portfolio/smart-bonds/history/SmartBondHistory';
import { SmartNfts as SmartNftsPortfolioPage } from '../pages/protected/portfolio/smart-nfts/SmartNfts';
import { SmartNftsPortfolio } from '../pages/protected/portfolio/smart-nfts/SmartNftsPortfolio.tsx';
import { NftAssetDetails } from '../pages/protected/portfolio/smart-nfts/nft-asset-details/NftAssetDetails.tsx';
import { NftHistory } from '../pages/protected/portfolio/smart-nfts/nft-history/NftHistory.tsx';
import { SmartSharesPortfolio } from '../pages/protected/portfolio/smart-shares/SmartShares.tsx';
import { SmartSharesRoot } from '../pages/protected/portfolio/smart-shares/SmartSharesRoot.tsx';
import { ShareDetails } from '../pages/protected/portfolio/smart-shares/share-details/ShareDetails.tsx';
import { SharesHistory } from '../pages/protected/portfolio/smart-shares/shares-history/SharesHistory.tsx';
import { ChangePassword } from '../pages/public/change-password/ChangePassword.tsx';
import { ForgotPassword } from '../pages/public/forgot-password/ForgotPassword';
import { Login } from '../pages/public/login/Login';
import { RegistrationFailed } from '../pages/public/register/steps/registration-failed/RegistrationFailed';
import { RegistrationSuccess } from '../pages/public/register/steps/registration-success/RegistrationSuccess';
import { SetRegistrationType } from '../pages/public/register/steps/set-registration-type/SetRegistrationType';
import { SetUserTypeRegistration } from '../pages/public/register/steps/set-user-type-registration/SetUserTypeRegistration';
import { RegistrationWizardSteps } from '../pages/public/register/steps/wizard-step/RegistrationWizardSteps';
import { ExpiredLink } from '../pages/public/reset-password/ExpiredLink.tsx';
import { ResetPassword } from '../pages/public/reset-password/ResetPassword.tsx';

import { routes } from './routes';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* Public Routes */}
      <Route
        path={routes.auth}
        element={
          <PublicRoute>
            <PublicLayout />
          </PublicRoute>
        }
      >
        <Route index element={<Login />} />
        <Route path={routes.login} element={<Login />} />
        <Route path={routes.changePassword} element={<ChangePassword />} />
        <Route path={routes.resetPassword} element={<ResetPassword />} />
        <Route path={routes.register.root}>
          <Route index element={<SetUserTypeRegistration />} />
          <Route path={routes.register.chooseTypeRegistration} element={<SetRegistrationType />} />
          <Route path={routes.register.registrationSteps} element={<RegistrationWizardSteps />} />
          <Route path={routes.register.registerSuccess} element={<RegistrationSuccess />} />
          <Route path={routes.register.registerFailed} element={<RegistrationFailed />} />
        </Route>
        <Route path={routes.forgotPassword} element={<ForgotPassword />} />
      </Route>

      {/* Protected Routes */}
      <Route
        path={routes.root}
        element={
          <ProtectedRoute>
            <ProtectedLayout />
          </ProtectedRoute>
        }
      >
        <Route path={routes.account.root} element={<Account />}>
          <Route path={routes.account.overview} element={<Overview />} />
          <Route path={routes.account.profile} element={<Profile />} />
          <Route path={routes.account.accountManagement.root} element={<AccountManagement />}>
            <Route index element={<AccountManagementOveriew />} />
            <Route path={routes.account.accountManagement.deleteAccount} element={<DeleteAccount />} />
          </Route>
          <Route path={routes.account.address} element={<Address />} />
          <Route path={routes.account.verification} element={<Verification />} />
          <Route path={routes.account.transactions.root} element={<Transactions />}>
            <Route index path={routes.account.transactions.root} element={<AllTransactions />} />
            <Route path={routes.account.transactions.singleTransaction} element={<SingleTransaction />} />
          </Route>
          <Route path={routes.account.wallet} element={<Wallet />} />
        </Route>
        <Route index element={<Dashboard />} />
        <Route path={routes.home.root} element={<Home />}>
          <Route index element={<Dashboard />} />
          <Route path={routes.home.newsletter} element={<Newsletter />} />
          <Route path={routes.home.aboutUs} element={<AboutUs />} />
        </Route>
        <Route path={routes.settings} element={<Settings />} />
        <Route path={routes.offering.root} element={<Offering />}>
          <Route index element={<Offering />} />
          <Route path={routes.offering.smartShares.root} element={<SmartShares />}>
            <Route path={routes.offering.smartShares.root} element={<SharesHandler />} />
            <Route path={routes.offering.smartShares.singleView} element={<SingleShareHandler />} />
          </Route>
          <Route path={routes.offering.smartNfts.root} element={<SmartNftsOffering />}>
            <Route index path={routes.offering.smartNfts.root} element={<NftsHandler />} />
            <Route path={routes.offering.smartNfts.collectibles} element={<Collectibles />} />
            <Route path={routes.offering.smartNfts.singleCollection} element={<SingleCollection />} />
            <Route path={routes.offering.smartNfts.collections} element={<Collections />} />
            <Route path={routes.offering.smartNfts.singleView} element={<SingleView />} />
          </Route>
          <Route path={routes.offering.smartBonds.root} element={<SmartBonds />}>
            <Route path={routes.offering.smartBonds.root} element={<AllBonds />} />
            <Route path={routes.offering.smartBonds.singleView} element={<SingleBond />} />
          </Route>
          <Route path={routes.offering.smartBonds.root} element={<SmartBonds />}>
            <Route path={routes.offering.smartBonds.root} element={<AllBonds />} />
          </Route>
        </Route>
        <Route path={routes.cases.root} element={<Cases />}>
          <Route path={routes.cases.active.root} element={<ActiveCasesRoot />}>
            <Route index path={routes.cases.active.root} element={<ActiveCases />} />
            <Route path={routes.cases.active.caseDetails} element={<CaseDetails />} />
          </Route>
          <Route path={routes.cases.history.root} element={<CaseHistoryRoot />}>
            <Route index path={routes.cases.history.root} element={<CaseHistory />} />
            <Route path={routes.cases.history.caseDetails} element={<CaseDetails />} />
          </Route>
        </Route>
        <Route path={routes.portfolio.root} element={<Portfolio />}>
          <Route path={routes.portfolio.overview} element={<OverviewPortfolio />} />
          <Route path={routes.portfolio.smartNfts.root} element={<SmartNftsPortfolio />}>
            <Route index element={<SmartNftsPortfolioPage />} />
            <Route path={routes.portfolio.smartNfts.nftHistory} element={<NftHistory />} />
            <Route path={routes.portfolio.smartNfts.assetDetails} element={<NftAssetDetails />} />
          </Route>
          <Route path={routes.portfolio.smartShares.root} element={<SmartSharesRoot />}>
            <Route path={routes.portfolio.smartShares.root} element={<SmartSharesPortfolio />} />
            <Route path={routes.portfolio.smartShares.history} element={<SharesHistory />} />
            <Route path={routes.portfolio.smartShares.details} element={<ShareDetails />} />
          </Route>
          <Route path={routes.portfolio.smartBonds.root} element={<BondsPortfolio />}>
            <Route index path={routes.portfolio.smartBonds.root} element={<SmartBondsPortfolio />} />
            <Route path={routes.portfolio.smartBonds.bondHistory} element={<SmartBondHistory />} />
            <Route path={routes.portfolio.smartBonds.assetDetails} element={<BondAssetDetails />} />
          </Route>
        </Route>
        <Route path={routes.payment.root}>
          <Route index path={routes.payment.root} element={<BasePayment />} />
          <Route path={routes.payment.success} element={<BasePayment />} />
        </Route>
      </Route>

      <Route path={routes.verify} element={<VerifyLayout />} />
      <Route path={routes.expiredPage} element={<ExpiredLink />} />
      <Route path={'*'} element={<Error404 />} />
    </>
  )
);

export const routerMaintenance = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={'*'} element={<Maintenance />} />
    </>
  )
);
