import styled from 'styled-components';

type Props = {
  $showLabel: boolean;
  $disableFloat: boolean;
  $isLocked: boolean;
};

export const Legend = styled.legend<Props>`
  float: unset;
  width: auto;
  overflow: hidden;
  display: block;
  height: 0.125rem;
  visibility: hidden;
  white-space: nowrap;
  max-width: 0%;
  margin-left: ${({ $isLocked }) => ($isLocked ? '-0.5rem' : '0rem')};
  ${({ $disableFloat, $showLabel }) => {
    if (!$disableFloat) {
      return `
        max-width: ${$showLabel ? '100%' : '0'};
      `;
    }
  }}
  transition: max-width 0.2s ease-out;
`;
