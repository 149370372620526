import * as zod from 'zod';

import { FormNames } from '../../../../../shared/forms/formNames';

export const createSignatureSchema = () =>
  zod.object({
    file: zod.object({ fileId: zod.string().optional(), url: zod.string().optional() }),
  });

export const schema = createSignatureSchema();

export type SignatureFormData = zod.infer<typeof schema>;

export const signatureSchema: FormNames<SignatureFormData> = {
  file: 'file',
} as const;
