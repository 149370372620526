import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints.ts';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  margin: 0 auto;
  margin-top: 0;
  max-width: 77.5rem;
`;

export const SpinnerContainer = styled.div<{ $loading: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${({ $loading }) => ($loading ? 'visible' : 'hidden')};
  z-index: ${({ $loading }) => ($loading ? '1' : '-1')};

  &:before {
    border-top-color: transparent;
    border-radius: 50%;
    animation: spinnerAnimation 1s linear infinite;
  }

  @keyframes spinnerAnimation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const UpperRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  @media ${breakpoints.laptopSm} {
    flex-direction: row;
    gap: 1rem;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const LowerRowWrapper = styled.div`
  width: inherit;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  @media ${breakpoints.laptopSm} {
    flex-direction: row;
  }
`;

export const LeftContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  min-width: 33.75rem;
`;

export const RightContentWrapper = styled.div<{ $hasMarginBottom?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ $hasMarginBottom }) => ($hasMarginBottom ? '4.5rem' : '0')};
  width: 100%;
  min-width: 18.75rem;

  @media ${breakpoints.laptopSm} {
    margin-bottom: 0;
  }
`;
