import { BaseCard } from '@metaswiss/ui-kit';
import { TextTemplate } from '@metaswiss/ui-kit/src/shared/typography/TextTemplate.styles';
import styled from 'styled-components';

export const StyledCardTip = styled(BaseCard)<{ $backgroundColor: string; $borderColor: string }>`
  display: flex;
  max-width: unset;
  padding: 1.25rem;
  width: 100%;
  border-radius: ${({ theme }) => theme.v2.radius.small};
  background: ${({ $backgroundColor }) => $backgroundColor};
  border: 1px solid ${({ $borderColor }) => $borderColor};
  position: relative;
`;

export const CardTipContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
  width: 100%;
`;

export const CardTipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const CardTipText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  ${TextTemplate}:first-child {
    letter-spacing: 0.1rem;
  }
`;

export const CardTipList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CardTipItem = styled.span`
  line-height: 1.25rem;
  color: ${({ theme }) => theme.v2.text.bodyPrimary};
  font-size: 0.875rem;
`;
