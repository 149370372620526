/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RatingOptionResponse } from '../models/RatingOptionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RatingApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns RatingOptionResponse Fetch all rating options
   * @throws ApiError
   */
  public getAllRatingOptions(): CancelablePromise<Array<RatingOptionResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/rating-option',
      errors: {
        500: `Bad Request`,
      },
    });
  }

}
