import { mapIsoStringToDate } from '@metaswiss/lib';
import { AlertIcon, Text, ThemedIcon } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi';
import { ApiResource } from '../../../../../enums/resource.enum';
import { AppState, useAppState } from '../../../../../global-state/zustand';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { defaultUser } from '../../../../../shared/helpers/defaultUser';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper';
import { NoValueWrapper, SingleInformationContainer } from '../overview.styles';

export const CorporateUserOverview = () => {
  const theme = useTheme();
  const user = useAppState((state: AppState) => state.user) || defaultUser;
  const { textTranslation } = useTextTranslation();

  const { data: corporateUser } = useQuery({
    queryKey: getQueryKey(ApiResource.CORPORATE_USER, user.id),
    queryFn: () => api.users.getCorporateUser(user.id),
    enabled: !!user?.id,
  });

  const checkIfUserValueExist = (value: string | undefined, translation: string) => {
    if (value) {
      return (
        <SingleInformationContainer>
          <Text fontSize="xsm" lineHeight="extraSmall">
            {textTranslation(translation)}
          </Text>
          <Text fontWeight="bold" color={theme.v2.text.headingPrimary}>
            {value}
          </Text>
        </SingleInformationContainer>
      );
    }

    return (
      <NoValueWrapper>
        <ThemedIcon icon={AlertIcon} customStrokeColor={theme.v2.icon.error} size="medium" />
        <Text color={theme.v2.text.headingPrimary}>{textTranslation(translation)}</Text>
      </NoValueWrapper>
    );
  };

  return (
    <>
      {checkIfUserValueExist(corporateUser?.corporateUser.ceo, 'account.ceo')}
      {checkIfUserValueExist(corporateUser?.corporateUser.owner, 'account.owner')}
      {checkIfUserValueExist(
        corporateUser?.corporateUser.dateFounded &&
          mapIsoStringToDate({ date: corporateUser?.corporateUser?.dateFounded }),
        'account.dateFounded'
      )}
      {checkIfUserValueExist(corporateUser?.corporateUser?.businessFocus?.name, 'account.businessFocus')}
      {checkIfUserValueExist(corporateUser?.corporateUser?.legalForm?.name, 'account.legalForm')}
      {checkIfUserValueExist(
        corporateUser?.phoneNumber ? `+${corporateUser.phoneNumberPrefix} ${corporateUser.phoneNumber}` : undefined,
        'account.phoneNumber'
      )}
    </>
  );
};
