import { FC } from 'react';

import { IconProps } from './iconProps';

export const CurrencyEuroIcon: FC<IconProps> = ({ color = 'none', strokeColor = '#222223' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 22 22" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9984 7.62174C13.4174 6.95754 12.6812 6.50827 11.8821 6.33025C11.0831 6.15222 10.2568 6.25338 9.50686 6.62104C8.75691 6.98869 8.11668 7.60648 7.66647 8.39693C7.21625 9.18738 6.97609 10.1153 6.97609 11.0644C6.97609 12.0134 7.21625 12.9413 7.66647 13.7318C8.11668 14.5223 8.75691 15.14 9.50686 15.5077C10.2568 15.8753 11.0831 15.9765 11.8821 15.7985C12.6812 15.6205 13.4174 15.1712 13.9984 14.507M11.1066 9.68732H5.59844M5.59844 12.4414H11.1066M20.5984 10.9984C20.5984 16.3004 16.3004 20.5984 10.9984 20.5984C5.6965 20.5984 1.39844 16.3004 1.39844 10.9984C1.39844 5.6965 5.6965 1.39844 10.9984 1.39844C16.3004 1.39844 20.5984 5.6965 20.5984 10.9984Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
