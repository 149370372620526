import { CoinsRotateIcon, HelpIcon, LogoutIcon, UserProfileIcon } from '@metaswiss/ui-kit';
import { ProfileDropdown } from '@metaswiss/ui-kit/src/components/organism/profile-dropdown';
import { UserStatus } from '@metaswiss/ui-kit/src/components/organism/profile-dropdown/types';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppState } from '../../global-state/zustand';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../router/routes';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onLogoutClick: () => void;
  onSupportClick: () => void;
  imagePath?: string;
};

const getUserStatus = (isFullyRegistered?: boolean, status?: 'APPROVED' | 'PENDING' | 'SUSPENDED'): UserStatus =>
  !isFullyRegistered
    ? UserStatus.NOT_FULLY_REGISTERED
    : status === 'PENDING'
      ? UserStatus.PENDING
      : UserStatus.FULLY_REGISTERED;

export const ClientProfileDropdown: FC<Props> = ({ isOpen, imagePath, onLogoutClick, setIsOpen, onSupportClick }) => {
  const { textTranslation } = useTextTranslation();

  const user = useAppState((state) => state.user);

  const navigate = useNavigate();

  const userStatus = useMemo(() => {
    return getUserStatus(user?.isFullyRegistered, user?.status);
  }, [user]);

  return (
    <ProfileDropdown
      username={user?.displayName || ''}
      userStatus={userStatus}
      userStatusTranslation={textTranslation(`clientProfileDropdown.${userStatus}`)}
      avatarSrc={imagePath}
      isOpen={isOpen}
      items={[
        {
          name: textTranslation('clientProfileDropdown.account'),
          icon: UserProfileIcon,
          onClick: () => {
            setIsOpen(false);
            navigate(routes.account.overview);
          },
          hasError: !user?.isFullyRegistered,
        },
        {
          name: textTranslation('clientProfileDropdown.transactions'),
          icon: CoinsRotateIcon,
          onClick: () => {
            setIsOpen(false);
            navigate(routes.account.transactions.root, { replace: true });
          },
        },
        {
          name: textTranslation('clientProfileDropdown.support'),
          icon: HelpIcon,
          onClick: () => onSupportClick(),
        },
        {
          name: textTranslation('clientProfileDropdown.logout'),
          icon: LogoutIcon,
          logout: true,
          onClick: () => onLogoutClick(),
        },
      ]}
    />
  );
};
