import { BlurredBackground, MaintenanceFeedback, Text } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';

import { api } from '../../api/msApi';
import { ApiResource } from '../../enums/resource.enum';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';
import { getQueryKey } from '../../shared/helpers/getQueryKey.helper';
import { tenantConfig } from '../../tenantConfig.ts';

import { LogoContainer, MaintenanceContent } from './maintenance.styles';

export const Maintenance: FC = () => {
  const { textTranslation } = useTextTranslation();
  // Added here to trigger api call to get out the maintance mode when response code not 503
  useQuery({
    queryKey: getQueryKey(ApiResource.VAT),
    queryFn: () => api.vat.getVat(),
    retry: false,
  });

  const maintanceTitleRender = (title: string) => (
    <Text fontWeight="bold" fontSize="h3" lineHeight="h3">
      {title}
    </Text>
  );
  const maintanceSubtTitleRender = (subTitle: string) => (
    <Text lineHeight="medium" textColor={'hue500'}>
      {subTitle}
    </Text>
  );

  return (
    <BlurredBackground palette={'primary'} customBlurBackground={tenantConfig.blurBackground}>
      <LogoContainer src={tenantConfig.logoBig} />
      <MaintenanceContent>
        <MaintenanceFeedback
          fill
          titleRender={maintanceTitleRender}
          subTitleRender={maintanceSubtTitleRender}
          title={textTranslation('home.maintenanceTitle')}
          subTitle={textTranslation('home.maintenanceText')}
          icon={<img src={tenantConfig.maintenanceIcon} />}
        />
      </MaintenanceContent>
    </BlurredBackground>
  );
};
