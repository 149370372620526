import { formatAmount, formatAmountWithDot } from '@metaswiss/lib';
import { ArrowChartIcon, BarChartNoBorderIcon, CollectibleIcon, NftIcon } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { api } from '../../../../../../api/msApi';
import { AppState, useAppState } from '../../../../../../global-state/zustand';
import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../../router/routes';
import { tenantConfig } from '../../../../../../tenantConfig.ts';
import { BuyCard } from '../buy-card/BuyCard';

import { BuyCardsContainerWrapper } from './buyCardsContainer.styles';

export const BuyCardsContainer = () => {
  const { textTranslation } = useTextTranslation();

  const currency = useAppState((state: AppState) => state.currency);
  const navigate = useNavigate();

  const { data: bondOffering } = useQuery({
    queryKey: ['bondOffering'],
    queryFn: () => api.bonds.getTenantOffering(),
  });

  const { data: shareOffering } = useQuery({
    queryKey: ['shareOffering'],
    queryFn: () => api.shares.getTenantOffering(),
  });

  const { data: nftOffering } = useQuery({
    queryKey: ['nftOffering'],
    queryFn: () => api.nftCollectionCore.getTenantOffering(),
  });

  if (!nftOffering && !shareOffering && !bondOffering) {
    return null;
  }

  return (
    <BuyCardsContainerWrapper>
      {nftOffering && (
        <BuyCard
          icon={tenantConfig.useNftName ? NftIcon : CollectibleIcon}
          title={formatAmount(nftOffering.offer, currency?.decimals, currency?.rate, currency?.currencyCode)}
          onClick={() => navigate(routes.offering.smartNfts.root)}
          subtitle={textTranslation('home.pricePerNft')}
          buttonText={textTranslation('home.buyNft')}
          descriptionCount={nftOffering.avaliableNftCollections}
          description={textTranslation('home.nftAvailable')}
        />
      )}
      {shareOffering && (
        <BuyCard
          icon={BarChartNoBorderIcon}
          title={formatAmount(shareOffering.offer, currency?.decimals, currency?.rate, currency?.currencyCode)}
          onClick={() => navigate(routes.offering.smartShares.root)}
          subtitle={textTranslation('home.pricePerShare')}
          buttonText={textTranslation('home.buyShare')}
          descriptionCount={formatAmountWithDot(shareOffering.availableForSale)}
          description={textTranslation('home.smartShareAvailable')}
        />
      )}
      {bondOffering && (
        <BuyCard
          icon={ArrowChartIcon}
          title={`${bondOffering?.offer ?? 0}%`}
          onClick={() => navigate(routes.offering.smartBonds.root)}
          subtitle={textTranslation('home.interestBond')}
          buttonText={textTranslation('home.buyBond')}
          descriptionCount={formatAmountWithDot(bondOffering?.availableForSale ?? 0)}
          description={textTranslation('home.smartBondAvailable')}
        />
      )}
    </BuyCardsContainerWrapper>
  );
};
