import * as zod from 'zod';

import { FormNames } from '../../../../../../../shared/forms/formNames';
import { staticTranslationFunction } from '../../../../../../../shared/forms/staticTranslationFunction.ts';

export const createQuicCorporateSchema = (translate: (key: string) => string) =>
  zod.object({
    name: zod.string().trim().nonempty(translate('error.requiredField')),
    email: zod.string().nonempty(translate('error.requiredField')).email(),
    password: zod.string().nonempty(translate('error.requiredField')).min(5),
    passwordConfirmation: zod.string().nonempty(translate('error.requiredField')).min(5),
    country: zod.object({
      value: zod.string().nonempty(translate('error.requiredField')),
      label: zod.string().nonempty(translate('error.requiredField')),
      iconSrc: zod.string().optional(),
      meta: zod.string().optional(),
    }),
    termsAndConditions: zod.literal<boolean>(true),
  });

export const schema = createQuicCorporateSchema(staticTranslationFunction);

export type QuickCorporateRegistrationFormData = zod.infer<typeof schema>;

export const quickRegistrationCorporateSchema: FormNames<QuickCorporateRegistrationFormData> = {
  name: 'name',
  email: 'email',
  password: 'password',
  passwordConfirmation: 'passwordConfirmation',
  country: 'country',
  termsAndConditions: 'termsAndConditions',
} as const;
