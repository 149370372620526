import styled from 'styled-components';

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;
  overflow-y: auto;
  margin-left: 1rem;
`;

export const StyledElementContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledElementWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const StyledIcon = styled.img`
  width: 2rem;
  height: 1.5rem;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: auto;
`;
