import { FC } from 'react';

import { IconProps } from './iconProps';

export const UploadFailedIcon: FC<IconProps> = ({ strokeColor = 'red' }) => {
  return (
    <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M56.0252 40C52.6659 50.2206 43.0447 57.6 31.7 57.6C17.5615 57.6 6.10002 46.1385 6.10002 32C6.10002 17.8615 17.5615 6.40002 31.7 6.40002C41.1756 6.40002 49.4488 11.5482 53.8752 19.2M46.1 22.4H58.9V9.60002"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
