import { BaseModal, ErrorDialog, Text } from '@metaswiss/ui-kit';
import { useEffect, useMemo, useState } from 'react';
import { unstable_useBlocker } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { IdentityVerification } from '../../../../../../enums/identityVerification';
import { useMotionExitTransition } from '../../../../../../hooks/use-motion-exit-transition/useMotionExitTransition';
import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';
import { useVerificationDocumentContext } from '../../shared/context/VerificationDocumentContext';
import { ModalHeader } from '../../styles/verification.styles';

import { FirstStep } from './FirstStep';
import { ResponseModal } from './ResponseModal';
import { SecondStep } from './SecondStep';

type Props = {
  closeModal: () => void;
};

export const Base = ({ closeModal }: Props) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const { identityProofFlow, setIdentityProofFlow } = useVerificationDocumentContext();

  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState<boolean>(false);

  const isPresent = useMotionExitTransition();

  const isDirtyForm = useMemo(
    () =>
      identityProofFlow.document?.id === IdentityVerification.ID
        ? !!identityProofFlow.backSide?.fileId || !!identityProofFlow.frontSide?.fileId || !!identityProofFlow.isLoading
        : !!identityProofFlow.frontSide?.fileId || !!identityProofFlow.isLoading,
    [
      identityProofFlow.document?.id,
      identityProofFlow.frontSide?.fileId,
      identityProofFlow.backSide?.fileId,
      identityProofFlow.isLoading,
    ]
  );

  const blocker = unstable_useBlocker(isDirtyForm);

  useEffect(() => {
    if (isDirtyForm && blocker.state === 'blocked') {
      setIsDiscardModalOpen(true);
      setIdentityProofFlow({ ...identityProofFlow, isDisabledOutsideClick: true });
    }
  }, [blocker, isDirtyForm]);

  const onClose = () => {
    setIsDiscardModalOpen(false);
    blocker.reset?.();
  };

  const onCancel = () => {
    setIsDiscardModalOpen(false);
    blocker.reset?.();
    setIdentityProofFlow({
      ...identityProofFlow,
      step: 0,
      frontSide: undefined,
      backSide: undefined,
      isSuccess: undefined,
      document: undefined,
      isDisabledOutsideClick: false,
    });
    closeModal();
  };

  const onCloseModal = () => {
    closeModal();
    setIdentityProofFlow({
      ...identityProofFlow,
      step: 0,
      frontSide: undefined,
      backSide: undefined,
      isSuccess: undefined,
      document: undefined,
      isDisabledOutsideClick: false,
    });
  };

  return (
    <>
      {typeof identityProofFlow.isSuccess !== 'boolean' ? (
        <>
          <ErrorDialog
            primaryText={textTranslation('account.discardChanges')}
            secondaryText={textTranslation('account.areYouSureDiscard')}
            isOpen={isDiscardModalOpen}
            close={onClose}
            primaryButtonText={textTranslation('account.discard')}
            onPrimaryButtonClick={onCancel}
            secondaryButtonText={textTranslation('account.cancel')}
            onSecondaryButtonClick={onClose}
          />
          <BaseModal
            isActive={isPresent}
            closeModal={onCloseModal}
            disabledOutsideClick={isDirtyForm}
            position={'fixed'}
            padding={'0rem'}
            width={'58.75rem'}
            height={'fit-content'}
            smallDevicesWidth={'44.5rem'}
            onOutsideClick={isDirtyForm ? () => setIsDiscardModalOpen(true) : onCloseModal}
          >
            <ModalHeader>
              <Text fontWeight={'bold'} lineHeight={'h4'} color={theme.v2.text.headingPrimary}>
                {textTranslation('account.identityVerification')}
              </Text>
            </ModalHeader>
            {identityProofFlow.step === 0 && (
              <FirstStep openModal={() => setIsDiscardModalOpen(true)} closeModal={onCloseModal} />
            )}
            {identityProofFlow.step === 1 && <SecondStep />}
          </BaseModal>
        </>
      ) : (
        <ResponseModal isActive={isPresent} closeModal={closeModal} />
      )}
    </>
  );
};
