import { formatAmountWithoutDecimals, mapDate } from '@metaswiss/lib';
import {
  BarChartWithoutBorder,
  CoinHandIcon,
  CoinUnbrokenIcon,
  DataStatusPage,
  PageStateContainer,
  Text,
  ThemedIcon,
  UserProfileIcon,
} from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi';
import { AssetDetails } from '../../../../../components/asset-details/AssetDetails';
import { CouponCard } from '../../../../../components/coupon-card/CouponCard';
import { AssetType } from '../../../../../enums/assetType';
import { ProductType } from '../../../../../enums/productType.enum';
import { ApiResource } from '../../../../../enums/resource.enum';
import { useHeaderOptions, useShellNavigationState } from '../../../../../global-state/zustand';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../router/routes';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper';

import { Content, CouponsContainer, CouponsWrapper, HeadingWrapper } from './bondAssetDetails.styles';

export const BondAssetDetails = () => {
  const navigate = useNavigate();
  const { textTranslation } = useTextTranslation();
  const { removeTabs } = useShellNavigationState();
  const { setHeaderTitle } = useHeaderOptions();
  const theme = useTheme();

  const { assetId } = useParams();

  const {
    data: details,
    isLoading: isLoadingDetails,
    isError: isErrorDetails,
    refetch: refetchDetails,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.PORTFOLIO, assetId),
    queryFn: () => api.clientPortfolio.getSmartBondInvestmentByOrderId(assetId || ''),
    enabled: !!assetId,
  });

  useEffect(() => {
    removeTabs();
    setHeaderTitle(textTranslation('portfolio.assetDetails'));
  }, [removeTabs, setHeaderTitle, textTranslation]);

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={isLoadingDetails}
      showError={true}
      isError={isErrorDetails}
      textTranslation={textTranslation}
      onTryAgain={async () => await refetchDetails()}
      showEmptyState={true}
      isEmptyState={!details}
      customEmptyStateComponent={
        <DataStatusPage
          icon={
            <ThemedIcon
              icon={BarChartWithoutBorder}
              customStrokeColor={theme.v2.text.information}
              customColor={theme.v2.surface.informationLight}
              size={'full'}
            />
          }
          title={textTranslation('portfolio.noBonds')}
          content={textTranslation('portfolio.noBondsPurchased')}
          buttonText={textTranslation('portfolio.startInvesting')}
          action={() => navigate(routes.offering.smartBonds.root)}
          additionalContent={textTranslation('portfolio.noBondsFollow')}
          iconWidth={'8.5rem'}
          iconHeight={'8.5rem'}
        />
      }
    >
      <AssetDetails
        heading={textTranslation('portfolio.assetDetails')}
        items={[
          {
            icon: <ThemedIcon icon={CoinHandIcon} palette={'primary'} color={'hue300'} />,
            subText: textTranslation('portfolio.pricePerBond'),
            text: details?.denomination || '',
          },
          // TODO: Uncomment when annual interest is available from BE
          // {
          //   icon: <ThemedIcon icon={TrendUpIcon} palette={'primary'} color={'hue300'} />,
          //   subText: textTranslation('portfolio.annualInterest'),
          //   text: `${details?.annualInterest}%`,
          // },
          {
            icon: <ThemedIcon icon={CoinUnbrokenIcon} palette={'primary'} strokeColor={'hue300'} />,
            subText: textTranslation('portfolio.quantity'),
            text: formatAmountWithoutDecimals(details?.bondsBought || 0),
          },
          {
            icon: <ThemedIcon icon={UserProfileIcon} palette={'primary'} strokeColor={'hue300'} />,
            subText: textTranslation('portfolio.smartBondCode'),
            text: details?.smartBondCode || '',
          },
        ]}
        transcationId={details?.transactionId ?? ''}
        productType={ProductType.BOND_PAPERS}
        documents={details?.documents || []}
        assetType={AssetType.PORTFOLIO}
        amount={`${details?.investedAmount}`}
        date={mapDate(details?.investmentDate || '')}
      />
      <CouponsWrapper>
        <Content>
          <HeadingWrapper>
            <Text fontSize={'lg'} lineHeight={'h4'} fontWeight={'bold'}>
              {textTranslation('portfolio.coupons')}
            </Text>
          </HeadingWrapper>
          <CouponsContainer>
            {details?.coupons?.map((coupon) => {
              return (
                <CouponCard
                  key={coupon.id}
                  serialNumber={coupon.serialNumber}
                  status={coupon.status}
                  price={`${coupon.couponAmount}`}
                />
              );
            })}
          </CouponsContainer>
        </Content>
      </CouponsWrapper>
    </PageStateContainer>
  );
};
