import { ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTheme } from 'styled-components';

import { useOutsideClick } from '../../../shared/hooks/use-outside-click/useOutsideClick';
import { RemSize } from '../../../theme';

import { StyledBackdrop, StyledModal } from './baseModal.styles';

export type ModalPosition = 'absolute' | 'fixed';

export interface BaseModalProps {
  isActive: boolean;
  position?: ModalPosition;
  closeModal: () => void;
  disabledOutsideClick: boolean;
  children: ReactNode;
  padding?: RemSize | `${RemSize} ${RemSize}` | `${RemSize} ${RemSize} ${RemSize} ${RemSize}`;
  width?: RemSize | '100%' | 'fit-content';
  height?: RemSize | '100%' | 'fit-content';
  smallDevicesWidth?: string;
  onOutsideClick?: () => void;
  borderRadius?: string;
}

export const BaseModal = ({
  children,
  isActive,
  position = 'fixed',
  closeModal,
  disabledOutsideClick,
  borderRadius,
  width,
  height,
  padding,
  smallDevicesWidth,
  onOutsideClick,
}: BaseModalProps) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isTransitionActive, setIsTransitionActive] = useState<boolean>(false);
  const theme = useTheme();

  useOutsideClick([modalRef], () => {
    if (!disabledOutsideClick) {
      closeModal();
    } else {
      if (onOutsideClick) {
        onOutsideClick();
      }
    }
  });

  useEffect(() => {
    if (isActive) {
      setIsModalOpen(true);
      const timer = setTimeout(() => {
        setIsTransitionActive(true);
      }, 50);

      return () => clearTimeout(timer);
    }

    setIsTransitionActive(false);
  }, [isActive]);

  const handleModalTransition = () => {
    if (!isActive) {
      setIsModalOpen(false);
      closeModal();
    }
  };

  const modalContent = (
    <StyledBackdrop $isOpen={isTransitionActive} $position={position} onTransitionEnd={handleModalTransition}>
      <StyledModal
        ref={modalRef}
        $width={width}
        $height={height}
        $padding={padding}
        $smallDevicesWidth={smallDevicesWidth}
        $borderRadius={borderRadius ?? theme.v2.radius.medium}
      >
        {children}
      </StyledModal>
    </StyledBackdrop>
  );

  return <>{isModalOpen && (position === 'fixed' ? createPortal(modalContent, document.body) : modalContent)}</>;
};
