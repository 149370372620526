import { CreditCardIcon, BankIcon } from '@metaswiss/ui-kit';
import { useEffect } from 'react';

import { PaymentMethodCard } from '../../../../components/payment-method-card/PaymentMethodCard';
import { PaymentMethod } from '../../../../enums/paymentMethod.enum';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { PaymentTypeOption, usePaymentContext } from '../context/PaymentContext';
import { StepWrapper } from '../payment.styles';

export const ChoosePaymentMethod = () => {
  const { paymentMethod, setPaymentMethod } = usePaymentContext();
  const { textTranslation } = useTextTranslation();

  const PaymentTypeOptions: PaymentTypeOption[] = [
    { id: PaymentMethod.STRIPE, value: '' },
    { id: PaymentMethod.BANK, value: '' },
  ];
  useEffect(() => {
    setPaymentMethod(paymentMethod || PaymentTypeOptions[0].id);
  }, [setPaymentMethod]);

  return (
    <StepWrapper>
      <PaymentMethodCard
        icon={CreditCardIcon}
        paymentTypeText={textTranslation('payment.creditCard')}
        description={textTranslation('payment.investUsingYourCreditCard')}
        isSelected={paymentMethod === PaymentMethod.STRIPE}
        onClick={() => {
          setPaymentMethod(PaymentTypeOptions[0].id);
        }}
        selectedRadioButton={{ id: paymentMethod, value: '' }}
        labelRadioButton={PaymentTypeOptions[0]}
      />
      <PaymentMethodCard
        icon={BankIcon}
        paymentTypeText={textTranslation('payment.bankTransfer')}
        description={textTranslation('payment.investUsingYourBankAccount')}
        isSelected={paymentMethod === PaymentMethod.BANK}
        onClick={() => {
          setPaymentMethod(PaymentTypeOptions[1].id);
        }}
        selectedRadioButton={{ id: paymentMethod, value: '' }}
        labelRadioButton={PaymentTypeOptions[1]}
      />
    </StepWrapper>
  );
};
