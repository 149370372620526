import { RemSize } from '@metaswiss/ui-kit';
import { styled } from 'styled-components';

export const RegistrationFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Divider = styled.div<{ $marginTop?: RemSize }>`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  margin-top: ${({ $marginTop }) => $marginTop ?? 0};
`;
