import { NftExtendedV5Response, PaymentResponse } from '@metaswiss/api';
import { formatAmount } from '@metaswiss/lib';
import { BankIcon, DetailsList, NftDetails, ResponsiveBill, SendIcon } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';

import { api } from '../../../api/msApi';
import { PaymentMethod } from '../../../enums/paymentMethod.enum';
import { ProductType } from '../../../enums/productType.enum';
import { ApiResource } from '../../../enums/resource.enum';
import { useTextTranslation } from '../../../hooks/use-text-translation/useTextTranslation';
import { swissCode } from '../../../shared/constants/currencyCode';
import { calculatePriceWithoutVat, calculateVat } from '../../../shared/helpers/calculateVat.helper';
import { getNftProductDescription } from '../../../shared/helpers/getNftProductDescription.helper';
import { getQueryKey } from '../../../shared/helpers/getQueryKey.helper';

type Props = {
  paymentResponse: PaymentResponse;
  item: NftExtendedV5Response;
  productType: ProductType;
  paymentMethod: PaymentMethod;
};

export const SuccessfullyPurchasedNft: FC<Props> = ({ paymentResponse, item, paymentMethod, productType }) => {
  const { textTranslation } = useTextTranslation();

  const { data: deliveryAddress } = useQuery({
    queryKey: getQueryKey(ApiResource.DELIVERY_ADDRESS, paymentResponse.transactionId),
    queryFn: () => api.userDeliveryAddresses.getUserDeliveryAddress(),
    enabled: true,
  });

  const { data: productAsset } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT_PRODUCT_LOGO, item?.nftCollection?.product?.id),
    queryFn: () => {
      return api.nftProduct.getNftProductAssetById(item?.nftCollection?.product?.id || '');
    },
    enabled: productType === ProductType.NFT && !!item?.nftCollection?.product?.id,
  });

  const { data: productImageUrl } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT_COLLECTION_LOGO, productAsset?.id),
    queryFn: () => {
      return api.assets.getS3SignedAssignedUrl({ assetId: productAsset?.id || '' });
    },
    enabled: !!productAsset?.id,
  });

  const { data: vat } = useQuery({
    queryKey: getQueryKey(ApiResource.VAT),
    queryFn: () => {
      return api.vat.getVat();
    },
    enabled: true,
  });

  const { data: transaction } = useQuery({
    queryKey: getQueryKey(ApiResource.TRANSACTIONS, paymentResponse.transactionId),
    queryFn: () => api.transactions.getTransactionDetails(paymentResponse.transactionId),
    enabled: !!paymentResponse?.transactionId,
  });

  const nftDetails = {
    title: textTranslation('payment.paymentDetails'),
    isVisibleTitleStep: 1,
    items: [
      {
        icon: BankIcon,
        subText: textTranslation('payment.paymentMethod'),
        text:
          paymentMethod === PaymentMethod.BANK
            ? textTranslation('payment.bankTransfer')
            : textTranslation('payment.creditCard'),
        isVisibleStep: 1,
      },
      {
        icon: SendIcon,
        subText: textTranslation('payment.deliveryAddress'),
        text: `${deliveryAddress?.address}, ${deliveryAddress?.city}, ${deliveryAddress?.country?.name}`,
        isVisibleStep: 1,
      },
    ],
  };

  return (
    <ResponsiveBill
      title={textTranslation('payment.overview')}
      amountText={textTranslation('payment.totalAmount')}
      amount={transaction?.detectedAmount ?? ''}
      extraBillInfo={[
        {
          text: textTranslation('payment.itemPrice'),
          price: formatAmount(
            calculatePriceWithoutVat(Number(paymentResponse.fiat), Number(vat?.value ?? 0)),
            2,
            1,
            swissCode
          ),
        },
        {
          text: textTranslation('payment.tax'),
          price: formatAmount(calculateVat(Number(paymentResponse.fiat), Number(vat?.value ?? 0)), 2, 1, swissCode),
        },
      ]}
      isResponsive={false}
      billBottomPadding={'4rem'}
      isPaymentSuccessful={true}
    >
      {productType === ProductType.NFT && (
        <NftDetails
          nftId={item?.code?.split('-')[1]}
          chain={item.nftCollection?.name}
          creator={item?.nftCollection?.issuer?.name}
          nftImageUrl={item?.thumbnailUrl}
          productDescription={getNftProductDescription(item?.nftCollection?.product?.description?.content)}
          productHeading={item?.nftCollection?.product?.title}
          productImageUrl={productImageUrl?.url ?? ''}
          productDetailsTranslationKey={'global.productDetails'}
          detailsTranslationKey={'global.details'}
          nftIdTranslationKey={'global.nftId'}
          chainTranslationKey={'global.chain'}
          creatorTranslationKey={'global.creator'}
          textTranslation={textTranslation}
        />
      )}
      <DetailsList {...nftDetails} isNftDetails={productType === ProductType.NFT} step={1} />
    </ResponsiveBill>
  );
};
