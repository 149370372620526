import { StyledStep, StyledStepperContainer, StyledStepWrapper } from './stepper.styles';

export interface StepperProps {
  currentStep: number;
  numberOfSteps: number;
}

export const Stepper: React.FC<StepperProps> = ({ numberOfSteps, currentStep }) => {
  return (
    <StyledStepperContainer>
      {Array.from({ length: numberOfSteps }, (_, stepIndex) => (
        <StyledStepWrapper key={stepIndex}>
          <StyledStep $activeStep={currentStep === stepIndex} />
        </StyledStepWrapper>
      ))}
    </StyledStepperContainer>
  );
};
