import { IconProps } from './iconProps';

export const ArrowLeftSmIcon: React.FC<IconProps> = ({ strokeColor }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.1667 16.375L7 12M7 12L11.1667 7.625M7 12H17"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
