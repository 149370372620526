import { FC } from 'react';

import { IconProps } from './iconProps';

export const CurrencyChfIcon: FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0004 7.00049H11.1728C10.0682 7.00049 9.17281 7.89592 9.17281 9.00049V11.2783M9.17281 11.2783V14.7922M9.17281 11.2783H15.2245M9.17281 14.7922V17.5005M9.17281 14.7922H7.0004M9.17281 14.7922H13.6728M21.6004 12.0004C21.6004 17.3023 17.3023 21.6004 12.0004 21.6004C6.69846 21.6004 2.40039 17.3023 2.40039 12.0004C2.40039 6.69846 6.69846 2.40039 12.0004 2.40039C17.3023 2.40039 21.6004 6.69846 21.6004 12.0004Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};
