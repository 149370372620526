import styled from 'styled-components';

export const RibbonWrapper = styled.div<{
  $color: string;
  $padding: string;
  $translateX: string;
  $translateY: string;
}>`
  background: ${({ theme, $color }) => theme.v2.colors[`${$color}100`]};
  color: ${({ theme }) => theme.v2.text.onAction};
  padding: ${({ $padding }) => $padding};
  position: absolute;
  z-index: 1;
  min-width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  transform: translate(${({ $translateX }) => $translateX}, ${({ $translateY }) => $translateY}) rotate(45deg);
  -moz-transform: translate(${({ $translateX }) => $translateX}, ${({ $translateY }) => $translateY}) rotate(45deg);
  -webkit-transform: translate(${({ $translateX }) => $translateX}, ${({ $translateY }) => $translateY}) rotate(45deg);
  filter: drop-shadow(0px 1.892px 3.784px rgba(16, 24, 40, 0.06)) drop-shadow(0px 1.892px 5.676px rgba(16, 24, 40, 0.1));

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    margin: 0 -1px;
    width: 5rem;
    height: 100%;
    background: ${({ theme, $color }) => theme.v2.colors[`${$color}100`]};
  }

  &:before {
    right: 100%;
  }

  &:after {
    left: 100%;
  }
`;
