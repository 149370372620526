import { BondFullResponseDTO, NftExtendedV5Response, ShareFullResponseDTO } from '@metaswiss/api';
import { usePaymentWizard } from '@metaswiss/ui-kit';
import { PaymentBankCard } from '@metaswiss/ui-kit/src/components/molecules/cards/payment-bank-card/PaymentBankCard';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { api } from '../../../../api/msApi';
import { PaymentMethod } from '../../../../enums/paymentMethod.enum';
import { ProductType } from '../../../../enums/productType.enum';
import { ApiResource } from '../../../../enums/resource.enum';
import { AppState, useAppState } from '../../../../global-state/zustand';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../router/routes';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';
import { usePaymentContext } from '../context/PaymentContext';
import { StepWrapper } from '../payment.styles';

export const BankTransfer = () => {
  const { textTranslation } = useTextTranslation();
  const { productType, currency, deliveryAddressId, item, setIsError, quantity } = usePaymentContext();
  const { setIsButtonLoading, setIsButtonDisabled, changeNextStepOnClick, isButtonLoading } = usePaymentWizard();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user = useAppState((state: AppState) => state.user);

  const getProductName = useCallback((type: ProductType) => {
    switch (type) {
      case ProductType.BOND_PAPERS:
        return 'Bonds';
      case ProductType.ACTIONS:
        return 'Shares';
      case ProductType.NFT:
        return 'NFT';
    }
  }, []);

  const { mutate: bankTransfer } = useMutation({
    mutationFn: () =>
      api.payment.bankCharge({
        currencyId: currency?.id || '',
        quantity: productType === ProductType.NFT ? 1 : quantity,
        paymentMethod: PaymentMethod.BANK,
        productType: productType,
        itemId: item?.id || '',
        useDefaultAddress: false,
        deliveryAddress: deliveryAddressId || '',
      }),
    onMutate: () => {
      setIsButtonLoading(true);
    },
    onSuccess: async (response) => {
      navigate(routes.payment.success, {
        state: { item, productType, paymentMethod: PaymentMethod.BANK, paymentResponse: response, currency },
        replace: true,
      });
      setIsButtonLoading(false);
      setIsButtonDisabled(false);
      await queryClient.invalidateQueries(getQueryKey(ApiResource.TRANSACTIONS, user?.id));
      if (productType === ProductType.NFT) {
        const nft = item as NftExtendedV5Response;
        await queryClient.invalidateQueries(getQueryKey(ApiResource.NFT, nft.id));
        await queryClient.invalidateQueries(getQueryKey(ApiResource.NFT, nft.nftCollection.id));
        await queryClient.invalidateQueries(getQueryKey(ApiResource.COLLECTIBLES, nft.nftCollection.id));
        await queryClient.invalidateQueries(getQueryKey(ApiResource.NFT_COLLECTION_STATISTICS, nft.nftCollection.id));
      }
      if (productType === ProductType.ACTIONS) {
        const share = item as ShareFullResponseDTO;
        await queryClient.invalidateQueries(getQueryKey(ApiResource.SHARE_LISTING));
        await queryClient.invalidateQueries(getQueryKey(ApiResource.SHARE_FULL));
        await queryClient.invalidateQueries(getQueryKey(ApiResource.SHARE_STATISTICS, share?.issuer?.id));
      }
      if (productType === ProductType.BOND_PAPERS) {
        const bond = item as BondFullResponseDTO;
        await queryClient.invalidateQueries(getQueryKey(ApiResource.BOND, bond.id));
        await queryClient.invalidateQueries(getQueryKey(ApiResource.BOND));
      }
    },
    onError: () => {
      setIsError(true);
      setIsButtonLoading(true);
    },
  });

  useEffect(() => {
    const handlePaymentRequest = async () => {
      if (!isButtonLoading) {
        await bankTransfer();
      }
    };

    changeNextStepOnClick(handlePaymentRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankTransfer, isButtonLoading]);

  return (
    <StepWrapper>
      <PaymentBankCard
        instructions={[
          textTranslation('payment.submitReservation'),
          textTranslation('payment.transferFunds'),
          textTranslation('payment.productWillShow', { product: getProductName(productType) }),
        ]}
        title={textTranslation('payment.paymentBankTransferTitle')}
      />
    </StepWrapper>
  );
};
