import { Theme } from '@metaswiss/ui-kit';
import { useEffect, useMemo, useState } from 'react';

import { TenantLabels } from './shared/tenant-labels/context';
import { greenStateTheme, metaswissTheme } from './shared/theme/theme';

interface AppConfig {
  theme: Theme;
  tenantLabels: TenantLabels;
}

async function fetchConfig(tenant: string): Promise<AppConfig> {
  if (tenant === 'metaswiss') {
    return { theme: metaswissTheme, tenantLabels: { appTitle: 'METASWISS', craftedBy: 'meta.swiss' } };
  } else if (tenant === 'greenstate') {
    return { theme: greenStateTheme, tenantLabels: { appTitle: 'GREENSTATE', craftedBy: 'greenstate.ch' } };
  }

  throw new Error('Unknown tenant');
}

export function useAppConfig(tenant: string) {
  const [config, setConfig] = useState<AppConfig | undefined>();
  const isLoading = useMemo(() => !config, [config]);

  useEffect(() => {
    fetchConfig(tenant).then((appConfig) => setConfig(appConfig));
  }, []);

  return { isLoading, config };
}
