import { IconProps } from './iconProps';

export const FileIcon: React.FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 176 145" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M63.0023 53.9883H117.002M63.0023 80.9883H117.002M63.0023 107.988H90.0023M49.5002 17.9883H130.502C140.443 17.9883 148.502 26.0474 148.502 35.9886L148.5 143.989C148.5 153.93 140.441 161.988 130.5 161.988L49.4994 161.988C39.5583 161.988 31.4994 153.929 31.4995 143.988L31.5002 35.9882C31.5003 26.0471 39.5591 17.9883 49.5002 17.9883Z"
        stroke={strokeColor}
        strokeWidth="7.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
