import { PortfolioShareAssetsDTO } from '@metaswiss/api';
import { formatAmountWithoutDecimals } from '@metaswiss/lib';
import {
  DataStatusPage,
  FlatList,
  PageStateContainer,
  PortfolioTransactionCard,
  ThemedIcon,
  TrendUpWithBackgroundIcon,
} from '@metaswiss/ui-kit';
import { BarChart } from '@metaswiss/ui-kit/src/iconography/BarChart';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi';
import { NumberOfItems } from '../../../../../components/number-of-items/NumberOfItems';
import { ProductType } from '../../../../../enums/productType.enum';
import { ApiResource } from '../../../../../enums/resource.enum';
import { useHeaderOptions, useShellNavigationState } from '../../../../../global-state/zustand';
import { usePagination } from '../../../../../hooks/use-pagination/usePagination';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../router/routes';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper';

export const SharesHistory = () => {
  const { textTranslation } = useTextTranslation();
  const { removeTabs } = useShellNavigationState();
  const { setHeaderTitle } = useHeaderOptions();
  const theme = useTheme();
  const navigate = useNavigate();

  const { data, lastItemRef, isError, fetchNextPage, isLoading, isFetchingNextPage, totalItems } =
    usePagination<PortfolioShareAssetsDTO>({
      queryKey: getQueryKey(ApiResource.PORTFOLIO, ProductType.ACTIONS),
      queryFn: (offset: number, limit: number) => api.clientPortfolio.getSmartShareInvestments(offset, limit),
      enabled: true,
    });

  useEffect(() => {
    removeTabs();
    setHeaderTitle(textTranslation('portfolio.shareHistory'));
  }, [removeTabs, setHeaderTitle, textTranslation]);

  return (
    <PageStateContainer
      isLoading={isLoading}
      isError={isError && data?.length !== 0}
      onTryAgain={async () => await Promise.all([fetchNextPage()])}
      showError={true}
      showLoading={true}
      textTranslation={textTranslation}
      isEmptyState={!isLoading && data?.length === 0}
      showEmptyState={true}
      customEmptyStateComponent={
        <DataStatusPage
          icon={
            <ThemedIcon
              icon={TrendUpWithBackgroundIcon}
              customStrokeColor={theme.v2.text.action2}
              customColor={theme.v2.surface.action2Light}
              size={'full'}
            />
          }
          title={textTranslation('portfolio.noShares')}
          content={textTranslation('portfolio.noSharesPurchased')}
          buttonText={textTranslation('portfolio.startInvesting')}
          action={() => navigate(routes.offering.smartShares.root)}
          additionalContent={textTranslation('portfolio.noSharesFollow')}
          iconWidth={'8.5rem'}
          iconHeight={'8.5rem'}
        />
      }
    >
      <FlatList
        data={data || []}
        isFetching={isFetchingNextPage}
        headerComponent={<NumberOfItems numberOfAssets={totalItems || 0} />}
        ref={lastItemRef}
        renderItem={(item) => (
          <PortfolioTransactionCard
            headerLabel={textTranslation('portfolio.pricePerShare')}
            headerValue={item.pricePerShare}
            quantityLabel={textTranslation('portfolio.quantity')}
            quantity={formatAmountWithoutDecimals(item.sharesBought)}
            amountLabel={textTranslation('portfolio.amount')}
            amount={item.investedAmount}
            icon={BarChart}
            onClick={() => navigate(`${routes.portfolio.smartShares.root}/${item.id}`)}
          />
        )}
        keyExtractor={(item) => item.id}
      />
    </PageStateContainer>
  );
};
