import { useEffect, useState } from 'react';

import { Languages } from '../../enums/language';
import { useTextTranslation } from '../use-text-translation/useTextTranslation';

export const useBrowserLanguage = () => {
  const { currentLanguage } = useTextTranslation();
  const [language, setLanguage] = useState(currentLanguage);

  useEffect(() => {
    if (navigator.language.split('-')[0] === Languages.GERMAN) {
      setLanguage(Languages.GERMAN);
    }
  }, [language]);

  return language;
};
