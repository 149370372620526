import { FC } from 'react';

import { IconProps } from './iconProps';

export const SendIcon: FC<IconProps> = ({ color = 'none', strokeColor = '#222223' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0688 2.92998L10.4049 13.5939M3.26953 8.23566L19.8754 2.47443C20.8981 2.11964 21.8792 3.10076 21.5244 4.1234L15.7632 20.7293C15.3685 21.8669 13.771 21.8981 13.3322 20.7768L10.6953 14.038C10.5636 13.7015 10.2974 13.4352 9.96079 13.3035L3.22207 10.6666C2.10073 10.2278 2.13192 8.63034 3.26953 8.23566Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
