import styled from 'styled-components';

import { TextTemplate } from '../../../../shared/typography/TextTemplate.styles';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DateContainer = styled.div`
  margin: 0.25rem 0 0.5rem 0;
`;

export const DescriptionContainer = styled.div`
  ${TextTemplate} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;
