import { ProductType } from '../../enums/productType.enum';
import { TransactionType } from '../../enums/transactionType';
import { routes } from '../../router/routes';

export const getSingleTransactionRouteByType = (transactionType: ProductType, id: string) => {
  switch (transactionType) {
    case ProductType.ACTIONS:
      return `${routes.account.transactions.root}/${TransactionType.SHARE}/${id}`;
    case ProductType.BOND_PAPERS:
      return `${routes.account.transactions.root}/${TransactionType.BOND}/${id}`;
    case ProductType.NFT:
      return `${routes.account.transactions.root}/${TransactionType.NFT}/${id}`;
    default:
      return routes.pageNotFound;
  }
};
