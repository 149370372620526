import styled, { css } from 'styled-components';

export const DetailsWrapper = styled.div`
  height: fit-content;
  width: 100%;
`;

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const DetailsInfoWrapper = styled.div`
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
`;

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const StatisticContainerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1.5rem;
`;

export const LeftColumnContainer = styled.div<{ $isShares: boolean }>`
  width: ${({ $isShares }) => ($isShares ? '100%' : '50%')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  border-radius: ${({ theme }) => theme.v2.radius.small};
  padding: 1rem;
`;

export const RightColumnContainer = styled.div<{ $isShares: boolean }>`
  width: ${({ $isShares }) => ($isShares ? 'auto' : '50%')};
  min-width: ${({ $isShares }) => $isShares && '12rem'};
  display: block;
`;

export const ChartWrapper = styled.div<{ $isShares: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;

  ${({ $isShares }) =>
    $isShares
      ? css`
          gap: 1.25rem;
          flex-direction: column;
        `
      : css`
          gap: 1rem;
          flex-direction: row;
          justify-content: flex-start;
        `}
`;
