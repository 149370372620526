import { FC } from 'react';

import { IconProps } from '../../../iconography/iconProps.ts';

type Item = {
  icon: FC<IconProps>;
  name: string;
  onClick: () => void;
  hasError?: boolean;
  logout?: boolean;
};

export enum UserStatus {
  NOT_FULLY_REGISTERED = 'notFullyRegistered',
  FULLY_REGISTERED = 'fullyRegistered',
  PENDING = 'pending',
}

export type Props = {
  username: string;
  userStatus: UserStatus;
  userStatusTranslation: string;
  items: Item[];
  isOpen: boolean;
  avatarSrc?: string;
  onOutsideClick?: () => void;
};

export type DropdownProps = {
  $isOpen: boolean;
};
