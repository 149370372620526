import styled from 'styled-components';

import { TextTemplate } from '../../../../../shared/typography/TextTemplate.styles';

export const StyledMissingValueContainer = styled.div<{ $isOptional: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 1.25rem;
  height: 3.5rem;
  border: 1px solid transparent;

  ${TextTemplate} {
    position: absolute;
    transform: translateY(1px);
    left: ${({ $isOptional }) => !$isOptional && '3.5rem'};

    @supports (-moz-appearance: none) {
      transform: translateY(0);
    }
  }
`;

export const StyledAlertIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  justify-content: center;
  display: flex;
  align-items: center;
`;
