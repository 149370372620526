import { FC } from 'react';

import { IconProps } from './iconProps';

export const CalendarIcon: FC<IconProps> = ({ color, strokeColor = 'black' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.875 8.57139H19.625M5.91071 2V3.71449M17.375 2V3.71428M20.75 6.71428L20.75 19C20.75 20.6569 19.4069 22 17.75 22H5.75C4.09315 22 2.75 20.6569 2.75 19V6.71428C2.75 5.05742 4.09315 3.71428 5.75 3.71428H17.75C19.4069 3.71428 20.75 5.05742 20.75 6.71428Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
