import styled from 'styled-components';

export const StyledLegend = styled.legend<{ $hasContent: boolean }>`
  float: unset;
  width: auto;
  max-width: 0;
  overflow: hidden;
  display: block;
  padding: 0;
  height: 0.125rem;
  font-size: ${({ theme }) => theme.typography.sizes.xsm};
  visibility: hidden;
  white-space: nowrap;
  margin-left: 0.25rem;
  text-align: left;
  transition: ${({ $hasContent }) => ($hasContent ? 'max-width 0.2s ease-in-out, ' : '')}margin 0.4s;
`;

export const StyledField = styled.fieldset`
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  right: 0;
  width: 100%;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0 1rem;
  pointer-events: none;
  overflow: hidden;
  min-width: 0%;
  border-radius: ${({ theme }) => theme.dimensions.borderRadii.large};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  transition: opacity 0.4s;
  display: flex;
  align-items: baseline;
`;

export const TextWrapper = styled.div`
  height: 100%;
  top: -0.25rem;
  width: calc(100% - 1.25rem);
  position: relative;
  transform: translate(1.25rem, -100%);
  border: 1px solid transparent;
  pointer-events: none;
  transition: transform 0.4s;
  user-select: none;
`;

export const StyledText = styled.p`
  color: ${({ theme }) => theme.v2.text.disabled};
  position: absolute;
  left: 0;
  top: 1.75rem;
  transform: translate(0, -50%);
  display: block;
  background-color: transparent;
  transition: 0.2s cubic-bezier(1, 1, 0.62, 0.89);
  transition-property: transform position top left;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 1.25rem);
  text-overflow: ellipsis;
  margin: 0;
  z-index: 1;
`;

export const StyledSpan = styled.span`
  padding: 0 0.25rem;
  display: inline-block;
  opacity: 0;
  visibility: visible;
  color: ${({ theme }) => theme.v2.text.headingPrimary};
`;
