import { FC } from 'react';

import { IconProps } from './iconProps';

export const Icon: FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3329 1.27631C10.7013 0.907895 11.2987 0.907895 11.6671 1.27631L14.3581 3.96731C14.7265 4.33573 14.7265 4.93305 14.3581 5.30147L11.6671 7.99247C11.2987 8.36089 10.7013 8.36088 10.3329 7.99247L7.64192 5.30147C7.27351 4.93305 7.27351 4.33573 7.64193 3.96731L10.3329 1.27631Z"
        stroke={strokeColor}
        strokeWidth="1.5"
      />
      <path
        d="M16.6985 7.64192C17.0669 7.27351 17.6643 7.27351 18.0327 7.64192L20.7237 10.3329C21.0921 10.7013 21.0921 11.2987 20.7237 11.6671L18.0327 14.3581C17.6643 14.7265 17.0669 14.7265 16.6985 14.3581L14.0075 11.6671C13.6391 11.2987 13.6391 10.7013 14.0075 10.3329L16.6985 7.64192Z"
        stroke={strokeColor}
        strokeWidth="1.5"
      />
      <path
        d="M3.96731 7.64192C4.33573 7.2735 4.93305 7.27351 5.30147 7.64192L7.99247 10.3329C8.36089 10.7013 8.36088 11.2987 7.99247 11.6671L5.30147 14.3581C4.93305 14.7265 4.33573 14.7265 3.96731 14.3581L1.27631 11.6671C0.907895 11.2987 0.907895 10.7013 1.27631 10.3329L3.96731 7.64192Z"
        stroke={strokeColor}
        strokeWidth="1.5"
      />
      <path
        d="M10.3329 14.0075C10.7013 13.6391 11.2987 13.6391 11.6671 14.0075L14.3581 16.6985C14.7265 17.0669 14.7265 17.6643 14.3581 18.0327L11.6671 20.7237C11.2987 21.0921 10.7013 21.0921 10.3329 20.7237L7.64192 18.0327C7.27351 17.6643 7.27351 17.0669 7.64192 16.6985L10.3329 14.0075Z"
        stroke={strokeColor}
        strokeWidth="1.5"
      />
    </svg>
  );
};
