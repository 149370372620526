import { FC } from 'react';

import { IconProps } from './iconProps';

export const TrendUpNftIcon: FC<IconProps> = ({ color = 'none', strokeColor = '#A752E0' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 136 136" fill={'none'} xmlns="http://www.w3.org/2000/svg">
      <rect width="100%" height="100%" rx="10" fill={color} />
      <path
        d="M27.7175 45.0469L67.5175 22.9358C67.8195 22.768 68.1867 22.768 68.4888 22.9358L108.289 45.0469C108.606 45.2233 108.803 45.5579 108.803 45.9211V90.0776C108.803 90.4408 108.606 90.7754 108.289 90.9518L68.4888 113.063C68.1867 113.231 67.8195 113.231 67.5175 113.063L27.7175 90.9518C27.4 90.7754 27.2031 90.4408 27.2031 90.0776V45.9211C27.2031 45.5579 27.4 45.2233 27.7175 45.0469Z"
        stroke={strokeColor}
        strokeWidth="2.66667"
      />
      <path
        d="M65.7341 77.0659V67.9993M71.9787 58.9326H65.7341V67.9993M65.7341 67.9993H71.7636M81.6008 58.9326H89.3415M97.0822 58.9326H89.3415M89.3415 58.9326V77.0659M56.2822 58.9326V77.0659L40.8008 58.9326V77.0659"
        stroke={strokeColor}
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
