import { FC, useState } from 'react';
import { useTheme } from 'styled-components';

import { Button, Text, ThemedIcon } from '../..';
import { ChevronUpIcon } from '../../../iconography';
import { Overlay } from '../../atoms/popup/styles/overlay';

import {
  ButtonWrapper,
  ContentSeparator,
  ExpandableCardContainer,
  ExpandableContainer,
  ExpandableContextWrapper,
  IconContainer,
  TitleWrapper,
  VisibleContainer,
} from './expandableTabletCard.styles';
import { ExpandableTabletCardProps } from './types';

export const ExpandableTabletCard: FC<ExpandableTabletCardProps> = ({
  title,
  visibleContentComponent,
  extandableContentComponent,
  includeArrowButton,
  buttonText,
  onClick,
  buttonDisabled,
  buttonLoading,
}) => {
  const theme = useTheme();
  const [isCardExpanded, setIsCardExpanded] = useState<boolean>(false);

  const toggleExpandableCard = () => {
    setIsCardExpanded((prevState) => !prevState);
  };

  return (
    <>
      {isCardExpanded && <Overlay onClick={toggleExpandableCard} />}
      <ExpandableCardContainer $isCardExpanded={isCardExpanded}>
        <VisibleContainer onClick={toggleExpandableCard}>
          {title && (
            <TitleWrapper>
              <Text color={theme.v2.text.headingPrimary} fontSize={'lg'} lineHeight={'h4'} fontWeight={'bold'}>
                {title}
              </Text>
            </TitleWrapper>
          )}
          {includeArrowButton && (
            <IconContainer $isCardExpanded={isCardExpanded}>
              <ThemedIcon icon={ChevronUpIcon} size={'large'} customStrokeColor={theme.v2.icon.default} />
            </IconContainer>
          )}
          {visibleContentComponent}
          <ContentSeparator />
        </VisibleContainer>
        <ExpandableContainer $isSelected={isCardExpanded} $isExpandedDefault={false}>
          <ExpandableContextWrapper>{extandableContentComponent}</ExpandableContextWrapper>
        </ExpandableContainer>
        {buttonText && (
          <ButtonWrapper>
            <Button text={buttonText} fill={true} onClick={onClick} disabled={buttonDisabled} loading={buttonLoading} />
          </ButtonWrapper>
        )}
      </ExpandableCardContainer>
    </>
  );
};
