import React, { useDeferredValue, memo, forwardRef } from 'react';

import { LocalSpinner } from '../../atoms/local-spinner/LocalSpinner';

import { SingleColumnWrapper, Wrapper } from './flatList.styles';

type FlatListProps<T> = {
  data: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
  headerComponent?: React.ReactNode;
  footerComponent?: React.ReactNode;
  numColumns?: number | 'auto-fill';
  numberOfColumnsOnSmallerDevices?: number;
  keyExtractor: (item: T) => string;
  isFetching?: boolean;
  fetchingTriggerIndex?: number;
};

const FlatListComponent = forwardRef(<T,>(props: FlatListProps<T>, ref: React.Ref<HTMLDivElement>) => {
  const {
    data,
    renderItem,
    headerComponent,
    footerComponent,
    isFetching,
    numColumns = 2,
    numberOfColumnsOnSmallerDevices = 1,
    keyExtractor,
    fetchingTriggerIndex = 4,
  } = props;
  const deferredData = useDeferredValue(data);

  return (
    <Wrapper $numColumns={numColumns} $numberOfColumnsOnSmallerDevices={numberOfColumnsOnSmallerDevices}>
      {headerComponent && <SingleColumnWrapper>{headerComponent}</SingleColumnWrapper>}
      {deferredData?.map((item, index) => {
        const isFetchingTriggerIndex = index === deferredData.length - fetchingTriggerIndex;
        return (
          <div ref={ref && isFetchingTriggerIndex ? ref : null} key={keyExtractor(item)}>
            {renderItem(item, index)}
          </div>
        );
      })}
      {isFetching && (
        <SingleColumnWrapper>
          <LocalSpinner isActive={isFetching} />
        </SingleColumnWrapper>
      )}
      {footerComponent && <SingleColumnWrapper>{footerComponent}</SingleColumnWrapper>}
    </Wrapper>
  );
});

export const FlatList = memo(FlatListComponent) as <T>(
  props: FlatListProps<T> & { ref?: React.Ref<HTMLDivElement> }
) => JSX.Element;
