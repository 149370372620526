import { useMemo } from 'react';

import { ButtonMonth } from './styles/btnStyles';
import { MonthsContainer } from './styles/datePickerSlider';
import { PickerProps } from './types';
import { isMonthInvalid } from './utils/getBtnsValidation';

export const MonthPicker = ({
  onChange,
  date,
  minDate,
  maxDate,
  activePicker,
  preferredLanguage = 'en-US',
}: PickerProps) => {
  const months = useMemo(() => {
    return Array.from({ length: 12 }, (_, i) => new Date(0, i).toLocaleString(preferredLanguage, { month: 'long' }));
  }, [preferredLanguage]);

  return (
    <MonthsContainer>
      {months.map((month, index) => {
        const isButtonInvalid = isMonthInvalid({ date, index, month, minDate, maxDate });

        return (
          <ButtonMonth
            key={month}
            $isSelected={Number(date.month) === index + 1}
            onClick={() => onChange((index + 1).toString().padStart(2, '0'))}
            disabled={isButtonInvalid}
            tabIndex={activePicker === 1 ? 0 : -1}
          >
            {month}
          </ButtonMonth>
        );
      })}
    </MonthsContainer>
  );
};
