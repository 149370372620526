import { ErrorFeedback } from '@metaswiss/ui-kit';

import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { useAuthContext } from '../../../shared/auth-context/authContext';
import { useSetAuthConfig } from '../../../shared/auth-context/useSetAuthConfig';
import { FeedbackWrapper } from '../../shared/styles';

export const ForgotPasswordFailed = () => {
  const { textTranslation } = useTextTranslation();
  const setForgotPasswordStep = useAuthContext((state) => state.setForgotPasswordStep);

  useSetAuthConfig(
    {
      wrapperStyle: 'authFinish',
      containerStyle: 'authFinish',
      removeLogo: true,
      nextButton: {
        animationDirection: 'backward',
        text: 'registration.tryAgainButton',
        onClick: () => setForgotPasswordStep(1),
      },
    },
    [setForgotPasswordStep]
  );

  return (
    <FeedbackWrapper>
      <ErrorFeedback
        fill
        title={textTranslation('forgotPassword.verificationErrorTitle')}
        subTitle={textTranslation('forgotPassword.verificationErrorMsg')}
      />
    </FeedbackWrapper>
  );
};
