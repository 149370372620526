import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle<{}>`
  :root {
    --font-xxsm: '0.625rem';
    --font-xsm: '0.75rem';
    --font-sm: '0.875rem';
    --font-base: '1rem';
    --font-lg: '1.25rem';
    --font-h4: '1.5rem';
    --font-h3: '2rem';
    --font-h2: '2.75rem';
    --font-h1: '4rem';
    --weight-reg: '400';
    --weight-semi: '600';
    --weight-bold: '700';
    --line-height-base: '1.5';
    --line-height-sm: '1.2'
  }

  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
    scrollbar-width: none;
  }

  body {
    background-color: ${({ theme }) => (theme.type === 'dark' ? theme.colorsDark.hue700 : theme.colors.neutrals.hue0)};
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 400;
    overflow-x: hidden;
  }

  ul[role='list'],
  ol[role='list'],
  menu {
    list-style: none;
  }
  li {
    user-select: none;
  }

  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
    outline: none;
    cursor: pointer;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  input::-webkit-contacts-auto-fill-button, 
  input::-webkit-credentials-auto-fill-button { 
    visibility: hidden;
    display: none;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  button,
  a {
    background-color: transparent;
    text-align: left;
    border: 0;
    text-decoration: none;
    cursor: pointer;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }

  @media (prefers-reduced-motion: reduce) {
    html {
      scroll-behavior: auto;
    }
  }
`;
