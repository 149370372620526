import { FC } from 'react';

import { IconProps } from './iconProps';

export const InvalidFileTypeIcon: FC<IconProps> = ({ strokeColor = 'red' }) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 24V14.4M24 31.1157V31.2M43.2 24C43.2 34.6039 34.6039 43.2 24 43.2C13.3961 43.2 4.79999 34.6039 4.79999 24C4.79999 13.3962 13.3961 4.80005 24 4.80005C34.6039 4.80005 43.2 13.3962 43.2 24Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
