import { DocumentDTO } from '@metaswiss/api';
import { HeaderCard, LinkAngled, Text, TextLink, ThemedIcon } from '@metaswiss/ui-kit';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { AssetType } from '../../enums/assetType';
import { DocumentType } from '../../enums/documentType';
import { ProductType } from '../../enums/productType.enum';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';
import { getSingleTransactionRouteByType } from '../../shared/helpers/getSingleTransactionRouteByType';

import {
  AssetItem,
  DocumentsInfoContainer,
  DocumentsWrapper,
  ExtraDetailsContainer,
  ItemIconWrapper,
  ItemTextWrapper,
  LayoutContainer,
  StyledLink,
} from './assetDetails.styles';
import { DocumentAsset } from './components/document-asset/DocumentAsset';
import { LayoutHeader } from './components/layout-header/LayoutHeader';
import { AssetDetailsProps } from './types';

export const AssetDetails: FC<AssetDetailsProps> = ({
  heading,
  amount,
  date,
  transactionStatus,
  extraDetails,
  items,
  documents,
  assetType,
  transcationId,
  productType,
}) => {
  const theme = useTheme();
  const { textTranslation, currentLanguage } = useTextTranslation();
  const navigate = useNavigate();

  const filteredDocuments = useMemo(() => {
    const documentsByPrefferedLanguage = documents.filter(
      (document) => document.fileName.includes(currentLanguage) || !document.fileName.includes('_')
    );

    return getAssetDocuments({ documents: documentsByPrefferedLanguage, assetType });
  }, [documents, assetType, currentLanguage]);

  const bodyPadding = productType === ProductType.NFT ? '1rem 1rem 0rem 1rem' : '0rem';

  return (
    <LayoutContainer>
      <Text fontSize={'lg'} fontWeight={'bold'} lineHeight={'h4'}>
        {heading}
      </Text>
      <HeaderCard
        removeBackground={false}
        header={<LayoutHeader amount={amount} date={date} transactionStatus={transactionStatus} />}
        headerPadding={'1rem'}
        bodyPadding={bodyPadding}
      >
        {extraDetails && <ExtraDetailsContainer>{extraDetails}</ExtraDetailsContainer>}
        {items &&
          items.map((item) => (
            <AssetItem key={item.subText}>
              <ItemIconWrapper>{item.icon}</ItemIconWrapper>
              <ItemTextWrapper>
                <Text fontSize={'sm'} lineHeight={'medium'} color={theme.v2.text.disabled}>
                  {item.subText}
                </Text>
                <Text fontWeight={'semi'} color={theme.v2.text.headingPrimary}>
                  {item.text}
                </Text>
              </ItemTextWrapper>
            </AssetItem>
          ))}
        {assetType === AssetType.PORTFOLIO && (
          <StyledLink>
            <TextLink
              onClick={() => navigate(getSingleTransactionRouteByType(productType as ProductType, transcationId))}
            >
              {textTranslation('portfolio.viewTransaction')}
              <ThemedIcon icon={LinkAngled} palette="primary" strokeColor="hue300" />
            </TextLink>
          </StyledLink>
        )}
      </HeaderCard>
      {!!filteredDocuments.length && (
        <>
          <Text fontSize={'lg'} fontWeight={'bold'} lineHeight={'h4'}>
            {textTranslation('global.documents')}
          </Text>
          <DocumentsInfoContainer>
            {filteredDocuments && (
              <DocumentsWrapper>
                {filteredDocuments.map((document) => (
                  <DocumentAsset {...document} key={document.key} />
                ))}
              </DocumentsWrapper>
            )}
          </DocumentsInfoContainer>
        </>
      )}
    </LayoutContainer>
  );
};

const getAssetDocuments = ({ documents, assetType }: { documents: DocumentDTO[]; assetType: AssetType }) => {
  switch (assetType) {
    case AssetType.TRANSACTIONS:
      return documents.filter((document) => document.type === DocumentType.INVOICE);
    case AssetType.PORTFOLIO:
      return documents.filter((document) => document.type !== DocumentType.INVOICE);
    default:
      throw new Error('Unsupported documents type');
  }
};
