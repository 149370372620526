import { IconProps } from './iconProps';

export const UserProfileIcon: React.FC<IconProps> = ({ strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.23773 16.5C2.56314 14.2892 5.46801 12.7762 10 12.7762C14.532 12.7762 17.4369 14.2892 18.7623 16.5M13.6 5.1C13.6 7.08823 11.9882 8.7 10 8.7C8.01178 8.7 6.40001 7.08823 6.40001 5.1C6.40001 3.11177 8.01178 1.5 10 1.5C11.9882 1.5 13.6 3.11177 13.6 5.1Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
