import { zodResolver } from '@hookform/resolvers/zod';
import { UpdatePrivateUserRequestV4 } from '@metaswiss/api';
import { convertDocumentSize } from '@metaswiss/lib';
import {
  AcceptedFileTypes,
  Button,
  CheckContained,
  Indicator,
  Line2,
  Stepper,
  Text,
  ThemedIcon,
  UploadFile,
} from '@metaswiss/ui-kit';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { api } from '../../../../../../api/msApi';
import { IdentityVerification } from '../../../../../../enums/identityVerification';
import { ApiResource } from '../../../../../../enums/resource.enum';
import { AppState, useAppState } from '../../../../../../global-state/zustand';
import { useFormSchema } from '../../../../../../hooks/use-form-schema/useFormSchema';
import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';
import { getQueryKey } from '../../../../../../shared/helpers/getQueryKey.helper';
import { FileInfoProps } from '../../../../../../shared/types/fileInfo';
import { useVerificationDocumentContext } from '../../shared/context/VerificationDocumentContext';
import {
  ButtonWrapper,
  ContentWrapper,
  DocumentTypeContainer,
  LineContainer,
  ModalMain,
  OptionsContainer,
  SecondStepContainer,
  StepContainer,
  StepSection,
  StepWrapper,
  SubHeaderSection,
  UploadContainer,
} from '../../styles/verification.styles';
import {
  createIdCardSchema,
  createPassportSchema,
  IdCardFormData,
  idCardSchema,
  PassportFormData,
  passportSchema,
} from '../identityProof.schema';

export const SecondStep = () => {
  const { textTranslation } = useTextTranslation();

  const { identityProofFlow, setIdentityProofFlow } = useVerificationDocumentContext();

  const user = useAppState((state: AppState) => state.user);
  const queryClient = useQueryClient();

  const [uploadedFrontSide, setUploadedFrontSide] = useState<{ fileId?: string; url?: string } | undefined>(undefined);
  const [uploadedBackSide, setUploadedBackSide] = useState<{ fileId?: string; url?: string } | undefined>(undefined);
  const [uploadedFrontSideFileInfo, setUploadedFrontSideFileInfo] = useState<FileInfoProps>({ name: '', size: '' });
  const [uploadedBackSideFileInfo, setUploadedBackSideFileInfo] = useState<FileInfoProps>({ name: '', size: '' });

  const schema = useFormSchema(
    identityProofFlow.document?.id === IdentityVerification.ID ? createIdCardSchema : createPassportSchema
  );

  const currentSchema = useMemo(
    () => (identityProofFlow.document?.id === IdentityVerification.ID ? idCardSchema : passportSchema),
    [identityProofFlow.document?.id]
  );

  const { setValue, getValues, reset } = useForm<IdCardFormData | PassportFormData>({
    mode: 'onChange',
    resolver: zodResolver(schema),
  });

  const {
    mutate: uploadFrontSide,
    isError: isErrorUploadFrontSide,
    isLoading: isLoadingUploadFrontSide,
  } = useMutation({
    mutationFn: (file: File) => {
      setUploadedFrontSideFileInfo({ name: file.name, size: convertDocumentSize(file.size) });
      return api.assets.uploadUnassignedFile({ file: file, fileType: file.type });
    },
    onSuccess: (response) => {
      setValue(
        currentSchema.frontSide,
        { fileId: response?.fileId, url: response?.url },
        { shouldValidate: true, shouldDirty: true }
      );
      setUploadedFrontSide({ fileId: response?.fileId, url: response?.url });
    },
  });

  const {
    mutate: uploadBackSide,
    isError: isErrorUploadBackSide,
    isLoading: isLoadingUploadBackSide,
  } = useMutation({
    mutationFn: (file: File) => {
      setUploadedBackSideFileInfo({ name: file.name, size: convertDocumentSize(file.size) });
      return api.assets.uploadUnassignedFile({ file: file, fileType: file.type });
    },
    onSuccess: (response) => {
      setValue(
        idCardSchema.backSide,
        { fileId: response?.fileId, url: response?.url },
        { shouldValidate: true, shouldDirty: true }
      );
      setUploadedBackSide({ fileId: response?.fileId, url: response?.url });
    },
  });

  const { refetch: refetchFrontSide, isFetching: isFetchingFrontSide } = useQuery({
    queryKey: getQueryKey(ApiResource.UNASSIGNED_URL),
    queryFn: () => {
      return api.assets.getS3SignedUnassignedUrl({ assetId: getValues(currentSchema.frontSide)?.fileId ?? '' });
    },
    enabled: !!identityProofFlow.frontSide?.fileId,
  });

  const { refetch: refetchBackSide, isFetching: isFetchingBackSide } = useQuery({
    queryKey: getQueryKey(ApiResource.UNASSIGNED_URL),
    queryFn: () => {
      return api.assets.getS3SignedUnassignedUrl({ assetId: getValues(idCardSchema.backSide)?.fileId ?? '' });
    },
    enabled: !!identityProofFlow.backSide?.fileId,
  });

  const { mutate: updateUser, isLoading: isLoadingUpdate } = useMutation({
    mutationKey: getQueryKey(ApiResource.PRIVATE_USER, user?.id),
    mutationFn: () => {
      const updateData: Partial<UpdatePrivateUserRequestV4> =
        identityProofFlow.document?.id === IdentityVerification.ID
          ? { frontSide: identityProofFlow.frontSide, backSide: identityProofFlow.backSide }
          : { passport: identityProofFlow.frontSide };
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return api.users.updatePrivateDetails(user!.id, updateData as UpdatePrivateUserRequestV4);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.PRIVATE_USER, user?.id) });
      await queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.ASSET, user?.id) });
      reset({}, { keepValues: true });
      setIdentityProofFlow((prevState) => ({
        ...prevState,
        isSuccess: true,
      }));
    },
    onError: () => {
      setIdentityProofFlow((prevState) => ({
        ...prevState,
        isSuccess: false,
      }));
    },
  });

  useEffect(() => {
    setIdentityProofFlow((prevState) => ({
      ...prevState,
      isLoading: isLoadingUploadFrontSide || isLoadingUploadBackSide || isFetchingFrontSide || isFetchingBackSide,
    }));
  }, [
    isLoadingUploadFrontSide,
    isLoadingUploadBackSide,
    isFetchingFrontSide,
    isFetchingBackSide,
    setIdentityProofFlow,
  ]);

  useEffect(() => {
    if (!uploadedFrontSide?.fileId) return;
    refetchFrontSide().then((response) => {
      setIdentityProofFlow((prevState) => ({
        ...prevState,
        frontSide: { url: response.data?.url, fileId: uploadedFrontSide?.fileId },
        isDisabledOutsideClick: true,
      }));
    });
  }, [uploadedFrontSide, refetchFrontSide, setIdentityProofFlow]);

  useEffect(() => {
    if (!uploadedBackSide?.fileId) return;
    refetchBackSide().then((response) => {
      setIdentityProofFlow((prevState) => ({
        ...prevState,
        backSide: { url: response.data?.url, fileId: uploadedBackSide?.fileId },
        isDisabledOutsideClick: true,
      }));
    });
  }, [uploadedBackSide, refetchBackSide, setIdentityProofFlow]);

  const handleFrontSideUpload = (file: File) => {
    uploadFrontSide(file);
  };

  const handleBackSideUpload = (file: File) => {
    uploadBackSide(file);
  };

  const onSubmit = () => {
    updateUser();
  };

  const onDeleteFrontSide = () => {
    setValue(currentSchema.frontSide, { fileId: undefined, url: undefined }, { shouldDirty: false });
    setIdentityProofFlow((prevState) => ({
      ...prevState,
      frontSide: undefined,
      isDisabledOutsideClick:
        identityProofFlow.document?.id === IdentityVerification.PASSPORT ? false : !!identityProofFlow.backSide?.fileId,
    }));
    setUploadedFrontSide(undefined);
  };

  const onDeleteBackSide = () => {
    setValue(idCardSchema.backSide, { fileId: undefined, url: undefined }, { shouldDirty: false });
    setIdentityProofFlow((prevState) => ({
      ...prevState,
      backSide: undefined,
      isDisabledOutsideClick: !!identityProofFlow.frontSide?.fileId,
    }));
    setUploadedBackSide(undefined);
  };

  return (
    <>
      <StepSection>
        <StepWrapper>
          <StepContainer>
            <ThemedIcon icon={CheckContained} palette={'primary'} strokeColor={'hue300'} size={'medium'} />
            <Text fontSize={'sm'} lineHeight={'medium'}>
              {textTranslation('account.documentType')}
            </Text>
          </StepContainer>
          <LineContainer>
            <ThemedIcon icon={Line2} palette={'primary'} strokeColor={'hue100'} size={'full'} />
          </LineContainer>
          <SecondStepContainer>
            <Indicator number={2} active={true} />
            <Text fontWeight={'semi'} fontSize={'sm'} lineHeight={'medium'}>
              {textTranslation('account.uploadingDocuments')}
            </Text>
          </SecondStepContainer>
        </StepWrapper>
      </StepSection>
      <ModalMain>
        <ContentWrapper>
          <SubHeaderSection>
            <Text fontWeight="bold">
              {identityProofFlow.document?.id === IdentityVerification.PASSPORT
                ? textTranslation('account.uploadPassport')
                : textTranslation('account.uploadId')}
            </Text>
          </SubHeaderSection>
          <OptionsContainer $step={1}>
            <DocumentTypeContainer>
              <UploadContainer>
                <Text fontWeight={'bold'}>{textTranslation('account.frontSide')}</Text>
                <UploadFile
                  uploadFile={handleFrontSideUpload}
                  onDelete={onDeleteFrontSide}
                  initialTitle={textTranslation('registration.uploadDocumentTitle')}
                  initialSubtitle={textTranslation('registration.uploadDocumentSubtitle')}
                  invalidTitle={textTranslation('registration.invalidPhotoTitle')}
                  invalidSubtitle={textTranslation('registration.uploadDocumentSubtitle')}
                  failedTitle={textTranslation('registration.failedPhotoUploadTitle')}
                  failedSubtitle={textTranslation('registration.failedPhotoUploadSubtitle')}
                  acceptedFileTypes={AcceptedFileTypes.ALL}
                  value={identityProofFlow.frontSide}
                  $width={'100%'}
                  $height={'100%'}
                  onViewDocument={() => window.open(identityProofFlow.frontSide?.url ?? '', '_blank')}
                  isLoading={isLoadingUploadFrontSide || (isFetchingFrontSide && !!uploadedFrontSide?.fileId)}
                  isError={isErrorUploadFrontSide}
                  viewFileTitle={uploadedFrontSideFileInfo.name}
                  viewFileSubtitle={uploadedFrontSideFileInfo.size}
                />
              </UploadContainer>
              {identityProofFlow.document?.id === IdentityVerification.ID && (
                <UploadContainer>
                  <Text fontWeight="bold">{textTranslation('account.backSide')}</Text>
                  <UploadFile
                    uploadFile={handleBackSideUpload}
                    onDelete={onDeleteBackSide}
                    initialTitle={textTranslation('registration.uploadDocumentTitle')}
                    initialSubtitle={textTranslation('registration.uploadDocumentSubtitle')}
                    invalidTitle={textTranslation('registration.invalidPhotoTitle')}
                    invalidSubtitle={textTranslation('registration.uploadDocumentSubtitle')}
                    failedTitle={textTranslation('registration.failedPhotoUploadTitle')}
                    failedSubtitle={textTranslation('registration.failedPhotoUploadSubtitle')}
                    acceptedFileTypes={AcceptedFileTypes.ALL}
                    $width={'100%'}
                    $height={'100%'}
                    value={identityProofFlow.backSide}
                    onViewDocument={() => window.open(identityProofFlow.backSide?.url ?? '', '_blank')}
                    isLoading={isLoadingUploadBackSide || (isFetchingBackSide && !!uploadedBackSide?.fileId)}
                    isError={isErrorUploadBackSide}
                    viewFileTitle={uploadedBackSideFileInfo.name}
                    viewFileSubtitle={uploadedBackSideFileInfo.size}
                  />
                </UploadContainer>
              )}
            </DocumentTypeContainer>
          </OptionsContainer>
          <Stepper currentStep={1} numberOfSteps={2} />
        </ContentWrapper>
        <ButtonWrapper>
          <Button
            variant={'outlined'}
            color={'neutral'}
            text={textTranslation('account.back')}
            onClick={() =>
              setIdentityProofFlow((prevState) => ({
                ...prevState,
                step: 0,
              }))
            }
            fill
          />
          <Button
            text={
              identityProofFlow.document?.id === IdentityVerification.ID
                ? textTranslation('account.submitDocuments')
                : textTranslation('account.submitDocument')
            }
            fill
            disabled={
              identityProofFlow.document?.id === IdentityVerification.PASSPORT
                ? !identityProofFlow.frontSide?.fileId
                : !identityProofFlow.frontSide?.fileId || !identityProofFlow.backSide?.fileId
            }
            onClick={onSubmit}
            loading={isLoadingUpdate}
          />
        </ButtonWrapper>
      </ModalMain>
    </>
  );
};
