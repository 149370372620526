import { motion } from 'framer-motion';
import styled from 'styled-components';

import { TextTemplate } from '../../../shared/typography/TextTemplate.styles';
interface CircleProps {
  circumference: number;
  offset: number;
}

export const Wrapper = styled.div`
  position: relative;
  width: 3.75rem;
  height: 3.75rem;
`;

export const Container = styled.svg`
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
`;

export const BackgroundCircle = styled.circle`
  fill: transparent;
  stroke: ${({ theme }) => theme.colors.neutrals.hue25};
  stroke-width: 8px;
`;

export const ProgressCircle = styled(motion.circle)<CircleProps>`
  fill: transparent;
  stroke: ${({ theme }) => theme.colors.primary.hue300};
  stroke-width: 8px;
  stroke-linecap: round;
  stroke-dasharray: ${(props) => props.circumference};
`;

export const StyledText = styled(TextTemplate)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
