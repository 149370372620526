import { styled } from 'styled-components';

export const IconButtonHolder = styled.div<{
  $color: string;
  $hoverColor: string;
}>`
  cursor: pointer;

  path {
    stroke: ${({ $color }) => $color};
  }

  &:hover {
    path {
      stroke: ${({ $hoverColor }) => $hoverColor};
    }
  }
`;
