import { PuffLoader } from 'react-spinners';
import { LengthType } from 'react-spinners/helpers/props';

import { LoaderContainer } from './initialLoader.styles';

type InitialLoaderProps = {
  size?: LengthType;
  color?: string;
};

export const InitialLoader = ({ size = 64, color = '#A6ABB2' }: InitialLoaderProps) => {
  return (
    <LoaderContainer>
      <PuffLoader size={size} color={color} />
    </LoaderContainer>
  );
};
