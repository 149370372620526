import { BaseCard } from '@metaswiss/ui-kit';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const UpperWrapper = styled.div`
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
`;

export const LowerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
`;

export const IconWrapper = styled.div`
  display: flex;
  padding: 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 62.5rem;
  background-color: ${({ theme }) => theme.v2.icon.primary};
  width: 6rem;
  height: 6rem;
`;

export const TextWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ContactWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const Card = styled(BaseCard)`
  background: ${({ theme }) => theme.v2.surface.primary};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.small};
  cursor: pointer;
`;
