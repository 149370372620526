import { Text, ThemedIcon } from '@metaswiss/ui-kit';
import { BarChart } from '@metaswiss/ui-kit/src/iconography/BarChart';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';
import { swissCode } from '../../../../../../shared/constants/currencyCode';

import {
  BalanceWrapper,
  IconWrapper,
  LeftSideWrapper,
  PriceWrapper,
  StyledPortfolioCard,
  TextWrapper,
} from './portfolioShareCard.style';

type PortfolioShareCardProps = {
  title: string;
  quantity: number;
  sharesText: string;
  totalAmount: string;
};

export const PortfolioShareCard: FC<PortfolioShareCardProps> = ({ title, quantity, sharesText, totalAmount }) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();

  return (
    <StyledPortfolioCard
      disableStateEffects
      height={'fit-content'}
      padding={'1.5rem'}
      override={{
        backgroundColor: theme.v2.surface.primary,
        borderColor: theme.v2.border.primary,
        borderRadius: theme.v2.componentsRadius.cards.default,
      }}
    >
      <LeftSideWrapper>
        <IconWrapper>
          <ThemedIcon icon={BarChart} size={'full'} customStrokeColor={theme.v2.text.information} />
        </IconWrapper>
        <BalanceWrapper>
          <Text fontSize={'lg'} lineHeight={'h4'} fontWeight={'semi'} color={theme.v2.text.headingPrimary}>
            {textTranslation(title)}
          </Text>
          <TextWrapper>
            <Text fontSize={'lg'} lineHeight={'h4'} fontWeight={'semi'} color={theme.v2.text.headingPrimary}>
              {quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")}
            </Text>
            <Text fontSize={'base'} lineHeight={'base'} fontWeight={'reg'} color={theme.v2.text.headingPrimary}>
              {textTranslation(sharesText)}
            </Text>
          </TextWrapper>
        </BalanceWrapper>
        <PriceWrapper>
          <Text fontSize={'h4'} lineHeight={'h4'} fontWeight={'bold'} color={theme.v2.text.information}>
            {totalAmount}
          </Text>
          <Text fontSize={'lg'} lineHeight={'h4'} color={theme.v2.text.headingPrimary}>
            {swissCode}
          </Text>
        </PriceWrapper>
      </LeftSideWrapper>
    </StyledPortfolioCard>
  );
};
