import Cookies from 'js-cookie';

const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const RESEND_TOKEN_KEY = 'resendToken';

export function setAccessToken(accessToken: string) {
  Cookies.set(ACCESS_TOKEN_KEY, accessToken, { sameSite: 'none', secure: true });
}

export function getAccessToken() {
  return Cookies.get(ACCESS_TOKEN_KEY) ?? undefined;
}

export function removeAccessToken() {
  return Cookies.remove(ACCESS_TOKEN_KEY);
}

export function setRefreshToken(refreshToken: string) {
  Cookies.set(REFRESH_TOKEN_KEY, refreshToken, { sameSite: 'none', secure: true });
}

export function getRefreshToken() {
  return Cookies.get(REFRESH_TOKEN_KEY) ?? undefined;
}

export function removeRefreshToken() {
  return Cookies.remove(REFRESH_TOKEN_KEY);
}

export function setResendToken(resendToken: string) {
  Cookies.set(RESEND_TOKEN_KEY, resendToken, { sameSite: 'none', secure: true });
}

export function getResendToken() {
  return Cookies.get(RESEND_TOKEN_KEY) ?? undefined;
}

export function removeResendToken() {
  return Cookies.remove(RESEND_TOKEN_KEY);
}
