import { CaseDetailsV5Response } from '@metaswiss/api';
import {
  Flex,
  FolderOpenIcon,
  ResourceInfoSize,
  ResourceInfoV2,
  ResourceInfoVariant,
  SmallChipV2,
  Text,
  ThemedIcon,
  TooltipV2,
} from '@metaswiss/ui-kit';
import { useTheme } from 'styled-components';

import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';
import { getCaseChipInfo } from '../../../../../../shared/helpers/getCaseChipInfo';

import { StyledCaseHeader } from './caseHeader.styles';

type CaseHeaderProps = {
  caseDetails: CaseDetailsV5Response;
  caseId: string;
};

export const CaseHeader = ({ caseDetails }: CaseHeaderProps) => {
  const theme = useTheme();

  const { textTranslation } = useTextTranslation();

  const { chipVariant, chipTranslationKey, chipIcon, chipIconColor, textToolTipKey } = getCaseChipInfo(
    caseDetails.status
  );

  return (
    <StyledCaseHeader>
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <ResourceInfoV2
          icon={FolderOpenIcon}
          size={ResourceInfoSize.SMALL}
          variant={ResourceInfoVariant.PRIMARY}
          title={caseDetails.name}
          renderSubTitleComponent={
            <Flex alignItems={'center'} gap={'0.75rem'}>
              <Text fontSize={'sm'} lineHeight={'medium'}>
                {textTranslation('cases.caseId')}{' '}
                <Text fontSize={'sm'} lineHeight={'medium'} fontWeight={'semi'}>
                  {caseDetails.caseId}
                </Text>
              </Text>
              <Flex alignItems={'center'} gap={'0.5rem'}>
                <SmallChipV2
                  label={textTranslation(chipTranslationKey)}
                  variant={chipVariant}
                  renderComponent={
                    <ThemedIcon icon={chipIcon} size={'extraSmall'} customStrokeColor={theme.v2.text[chipIconColor]} />
                  }
                />
                <TooltipV2
                  label={'?'}
                  description={textTranslation(textToolTipKey)}
                  holderBackgroundColor={theme.v2.surface.tertiary}
                />
              </Flex>
            </Flex>
          }
        />
      </Flex>
    </StyledCaseHeader>
  );
};
