import React from 'react';

export function createContext<T extends object | undefined>() {
  const reactContext = React.createContext<T | undefined>(undefined);

  function useContext() {
    const context = React.useContext(reactContext);

    if (context === undefined) {
      throw new Error('useContext must be called from within a Provider');
    }

    return context;
  }

  return [useContext, reactContext.Provider] as const;
}
