import { IconProps } from './iconProps';

export const ChevronLeftIcon: React.FC<IconProps> = ({ color, strokeColor }) => {
  return (
    <svg width="100%" height="100%" viewBox=" 0 0 24 24 " fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4 16.7998L9.59998 11.9998L14.4 7.19981"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
