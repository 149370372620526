import { InputNumber, ItemWithCustomIconProps } from '@metaswiss/ui-kit';
import { FieldValues, Path, Controller, Control } from 'react-hook-form';

import { InputNumberProps } from '../../atoms/input';
import { ItemProps } from '../selection-popup/types.ts';

import { StyledContainer } from './FormInput';

export type FormInputNumberProps<T extends FieldValues> = {
  name?: Path<T>;
  error?: string;
  control?: Control<T>;
  fill?: boolean;
  disabled?: boolean;
  defaultItem?: ItemProps | ItemWithCustomIconProps;
} & Omit<InputNumberProps, 'name' | 'error' | 'onChange' | 'value'>;

export const FormInputNumber = <T extends FieldValues>({
  name,
  error,
  control,
  fill,
  disabled = false,
  ...InputProps
}: FormInputNumberProps<T>) => {
  return (
    <StyledContainer $fill={fill}>
      {name && (
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <InputNumber
              onChange={(e) => onChange(e)}
              value={value ?? ''}
              error={error}
              disabled={disabled}
              {...InputProps}
            />
          )}
        />
      )}
    </StyledContainer>
  );
};
