import { styled } from 'styled-components';

import { breakpoints } from '../../../breakpoints';

export const HeaderContainer = styled.div<{ $border: { width: string; style: string; color: string } }>`
  grid-area: Header;
  border-bottom: ${({ $border }) => `${$border.width} ${$border.style} ${$border.color}`};
  padding: 1rem;
  height: fit-content;
  background: ${({ theme }) => theme.v2.surface.primary};
  z-index: 3;
`;

export const TopRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  @media ${breakpoints.laptopSm} {
    position: static;
  }
`;

export const BottomRow = styled.div`
  width: 100%;
`;

export const LogoContainer = styled.div<{ $showLogo: boolean | undefined }>`
  display: ${({ $showLogo }) => ($showLogo ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  @media ${breakpoints.laptopSm} {
    display: none;
    flex-shrink: 0;
  }
`;
export const StartBlockContainer = styled.div`
  max-width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
`;

export const TopCenterBlockContainer = styled.div`
  display: none;

  @media ${breakpoints.tabletMd} {
    display: block;
    text-align: end;
    max-width: 100%;
    max-width: 25rem;
    min-width: fit-content;
    flex-shrink: 0;
  }
`;
export const BottomCenterBlockContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  text-align: start;
  width: 100%;

  @media ${breakpoints.tabletMd} {
    display: none;
  }
`;
export const EndBlockContainer = styled.div`
  max-width: 100%;
  min-width: fit-content;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const InputContainer = styled.div`
  width: 100%;
`;
