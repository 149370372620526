import { Button, Heading, RemSize, Text } from '@metaswiss/ui-kit';
import { FC } from 'react';

import {
  ButtonWrapper,
  ErrorContainer,
  ErrorContentContainer,
  ErrorWrapper,
  HeadingWrapper,
  ImageGroup,
} from './errorPageMessage.styles';

type Props = {
  icon: () => JSX.Element;
  title: string;
  description: string;
  additionalDescription?: string;
  buttonText?: string;
  onButtonClick?: () => void;
  iconHeight?: RemSize;
};

export const ErrorPageMessage: FC<Props> = ({
  icon,
  title,
  description,
  additionalDescription,
  buttonText,
  onButtonClick,
  iconHeight,
}) => {
  return (
    <ErrorContainer>
      <ErrorContentContainer>
        <ErrorWrapper>
          <ImageGroup $height={iconHeight}>
            <>{icon()}</>
          </ImageGroup>
          <HeadingWrapper>
            <Heading headingType={'h3'} fontWeight={'bold'} palette={'neutrals'} textColor={'hue700'} lineHeight={'h3'}>
              {title}
            </Heading>
          </HeadingWrapper>
          <Text palette={'neutrals'} textColor={'hue700'}>
            {description}
          </Text>
          {additionalDescription && (
            <Text palette={'neutrals'} textColor={'hue700'}>
              {additionalDescription}
            </Text>
          )}
        </ErrorWrapper>
        {buttonText && (
          <ButtonWrapper>
            <Button text={buttonText} onClick={onButtonClick} fill />
          </ButtonWrapper>
        )}
      </ErrorContentContainer>
    </ErrorContainer>
  );
};
