import styled from 'styled-components';

export const StyledError = styled.span`
  top: 100%;
  position: absolute;
  left: 0;
  color: ${({ theme }) => theme.v2.text.error};
  font-weight: ${({ theme }) => theme.typography.weights.reg};
  font-size: ${({ theme }) => theme.typography.sizes.xsm};
  margin-top: 0.25rem;
  margin-left: 1rem;
`;

export const StyledCustomError = styled(StyledError)`
  top: 75%;
`;
