import styled from 'styled-components';

export const AccManagementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 1.5rem;
  height: 100%;
`;

export const AccManagementInfo = styled.div`
  width: 100%;
`;

export const AccountInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
  padding-left: 1.25rem;
  margin-bottom: 3.75rem;
`;

export const EmailInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 1.5rem 4rem;
`;

export const ButtonsWrapper = styled.div`
  padding: 1rem;
  display: flex;
  gap: 1rem;
`;

export const InputsWrapper = styled.div`
  height: 16.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 1rem;
`;
