import { Text } from '@metaswiss/ui-kit';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';

import { NotificationDetailsProps } from './notificationItemTypes';
import {
  NotificationDetailsContent,
  NotificationDetailsHeader,
  NotificationDetailsHolder,
  NotificationDetailsWrapper,
} from './notificationPopup.styles';

export const NotificationDetails: FC<NotificationDetailsProps> = ({
  title,
  content,
  date,
  slideDetails,
  wrapperWidth,
}) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  return (
    <NotificationDetailsWrapper $slideDetails={slideDetails}>
      <NotificationDetailsHolder>
        <NotificationDetailsHeader $dynamicWidth={wrapperWidth}>
          <Text fontWeight="bold" color={theme.v2.text.headingPrimary}>
            {textTranslation('home.notificationDetails')}
          </Text>
          <Text fontSize="sm" lineHeight="medium" color={theme.v2.text.headingPrimary}>
            {date}
          </Text>
        </NotificationDetailsHeader>
        <NotificationDetailsContent $slideDetails={slideDetails} $dynamicWidth={wrapperWidth}>
          <Text fontWeight="bold" color={theme.v2.text.headingPrimary}>
            {title}
          </Text>
          <Text color={theme.v2.text.disabled}>{content}</Text>
        </NotificationDetailsContent>
      </NotificationDetailsHolder>
    </NotificationDetailsWrapper>
  );
};
