import { FC } from 'react';

import { IconProps } from './iconProps';

export const BankIcon: FC<IconProps> = ({ color = 'none', strokeColor = '"#3381FF"' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.00039 15.5004V12.8317M9.33372 15.5004V12.8317M14.6671 15.5004V12.8317M20.0004 15.5004V12.8317M2.40039 18.9004H21.6004V22.1004H2.40039V18.9004ZM2.40039 9.30039V7.16706L11.6059 2.90039L21.6004 7.16706V9.30039H2.40039Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
