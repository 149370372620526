import { AnimatePresence } from 'framer-motion';
import React, { useId } from 'react';
import { createPortal } from 'react-dom';

import { Overlay } from './styles/overlay';
import { PopupWindow, PopupWrapper } from './styles/popupWindow';
import { PopupProps } from './types';

export const Popup = ({
  isActive,
  onOutsideClick,
  onModalClick,
  children,
  height = '37.5rem',
  width = '28rem',
  fill = false,
  maxWidth,
  containerId,
}: PopupProps) => {
  const id = useId();
  const container = document.getElementsByClassName(containerId || '')[0];
  const target = container || document.body;

  return createPortal(
    <AnimatePresence>
      {isActive && (
        <React.Fragment key={id}>
          <Overlay initial={{ opacity: 0 }} animate={{ opacity: 0.6 }} exit={{ opacity: 0 }} />
          <PopupWrapper onClick={onOutsideClick}>
            <PopupWindow
              onClick={(e) => {
                e.stopPropagation();
                onModalClick?.();
              }}
              $height={height}
              $width={width}
              $fill={fill}
              $maxWidth={maxWidth}
              initial={{ opacity: 0, scale: 0.7 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.7 }}
            >
              {children}
            </PopupWindow>
          </PopupWrapper>
        </React.Fragment>
      )}
    </AnimatePresence>,
    target
  );
};
