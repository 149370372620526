import styled, { css } from 'styled-components';

const iconStyles = css`
  display: grid;
  place-content: center;
  width: 1.5rem;
`;

export const StartIconWrapper = styled.span`
  ${iconStyles}
  height: 100%;
`;

export const EndIconWrapper = styled.span`
  ${iconStyles}
`;

export const PhoneIconWrapper = styled.span`
  ${iconStyles}
  width: 1.5rem;
`;
