import { BtnsValidationProps } from '../types';

import { getDaysInMonthFromString, getMonthNameFromNumber } from './getDaysInMonth';
import { getIsLeapYear } from './getIsLeapYear';

export const isDayInvalid = ({ date, day, minDate, maxDate }: BtnsValidationProps & { day: number }) => {
  let isDayInvalidForSelectedDate = false;

  if (date.month) {
    const parseDateMonth = getMonthNameFromNumber(date.month.toString());
    const daysInMonth = getDaysInMonthFromString(parseDateMonth);

    isDayInvalidForSelectedDate = day > daysInMonth;
  }

  if (+date.year === maxDate.getFullYear() && +date.month - 1 === maxDate.getMonth() && day > maxDate.getDate())
    isDayInvalidForSelectedDate = true;

  if (+date.year === minDate.getFullYear() && +date.month - 1 === minDate.getMonth() && day < minDate.getDate())
    isDayInvalidForSelectedDate = true;

  if (getIsLeapYear(+date.year) && +date.month === 2 && day === 29) isDayInvalidForSelectedDate = false;

  return isDayInvalidForSelectedDate;
};

export const isMonthInvalid = ({
  date,
  index,
  month,
  minDate,
  maxDate,
}: BtnsValidationProps & { index: number; month: string }) => {
  let isMonthInvalidForSelectedDate = false;

  if (date.day) {
    const daysInMonth = getDaysInMonthFromString(month);
    isMonthInvalidForSelectedDate = +date.day > daysInMonth;
  }

  if (+date.year === maxDate.getFullYear() && index >= maxDate.getMonth()) {
    isMonthInvalidForSelectedDate = true;

    if (index === maxDate.getMonth() && +date.day <= maxDate.getDate()) {
      isMonthInvalidForSelectedDate = false;
    }
  }

  if (+date.year === minDate.getFullYear() && index <= minDate.getMonth()) {
    isMonthInvalidForSelectedDate = true;

    if (index === minDate.getMonth() && +date.day + 1 >= minDate.getDate()) {
      isMonthInvalidForSelectedDate = false;
    }
  }

  if (month === 'February' && +date.day === 29)
    isMonthInvalidForSelectedDate = !date.year || getIsLeapYear(+date.year) ? false : true;
  return isMonthInvalidForSelectedDate;
};

export const isYearInvalid = ({ date, year, minDate, maxDate }: BtnsValidationProps & { year: number }) => {
  let isYearInvalid = false;

  if (year === maxDate.getFullYear()) {
    isYearInvalid = new Date(`${+date.month} ${date.day}, ${year}`).getTime() > maxDate.getTime();
  }

  if (year === minDate.getFullYear() && !isYearInvalid) {
    isYearInvalid = new Date(`${+date.month} ${+date.day}, ${year}`).getTime() < minDate.getTime();
  }

  return (!getIsLeapYear(year) && +date.day === 29 && +date.month === 2) || isYearInvalid;
};
