import { ExpendableCard, Text } from '@metaswiss/ui-kit';
import { useNavigate } from 'react-router-dom';

import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../router/routes';
import { AnimationContainer } from '../../../shared/animations/AnimationContainer';
import { useAuthContext } from '../../../shared/auth-context/authContext';
import { useSetAuthConfig } from '../../../shared/auth-context/useSetAuthConfig';
import { RegisterHeader } from '../../shared/register-header/RegisterHeader';
import { registrationFirstStepData } from '../../shared/registration-steps-data/registrationTypeUserData';

import { ExpendableCardContent, ExpendableCardWrapper } from './setUserTypeRegistration.styles';

export const SetUserTypeRegistration = () => {
  const navigate = useNavigate();
  const { userRole, setUserRole } = useAuthContext((state) => state);
  const { textTranslation } = useTextTranslation();

  useSetAuthConfig(
    {
      wrapperStyle: 'registration',
      containerStyle: 'registration',
      nextButton: {
        text: 'button.next',
        onClick: () => navigate(`${routes.register.root}/${userRole}`),
      },
      actionText: {
        animationDirection: 'login-in',
        text: 'registration.alreadyHaveAccount',
        linkText: 'registration.logIn',
        onClick: () => navigate(routes.login),
      },
    },
    [navigate, userRole]
  );

  return (
    <AnimationContainer>
      <RegisterHeader
        headingText={textTranslation('registration.signUp')}
        descriptionText={textTranslation('registration.firstSubtitle')}
      />
      <ExpendableCardWrapper>
        {registrationFirstStepData.map(({ title, description }) => {
          return (
            <ExpendableCard
              key={textTranslation(`registration.${title}Type`)}
              title={textTranslation(`registration.${title}Type`)}
              children={
                <ExpendableCardContent>
                  <Text>{textTranslation(description)}</Text>
                </ExpendableCardContent>
              }
              isSelected={userRole === title}
              onSelect={() => setUserRole(title)}
              radioButtonSize={'large'}
            />
          );
        })}
      </ExpendableCardWrapper>
    </AnimationContainer>
  );
};
