import { RemSize } from '..';

type Props = {
  strokeColor?: string;
  width?: RemSize;
  height?: RemSize;
};

export const CheckIcon: React.FC<Props> = ({ width, height, strokeColor = '#3381FF' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="check">
        <path
          id="Icon"
          d="M16.8 8.40002L9.64043 15.6L7.19995 13.1457"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
