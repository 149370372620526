import { NftExtendedV5Response } from '@metaswiss/api';
import { formatAmount } from '@metaswiss/lib';
import {
  BankIcon,
  DetailsList,
  NftDetails,
  PaymentWizard,
  ResponsiveBill,
  SendIcon,
  usePaymentWizard,
} from '@metaswiss/ui-kit';
import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import { ExpandableCardContainer } from '@metaswiss/ui-kit/src/components/molecules/expandable-tablet-card/expandableTabletCard.styles';
import { PaymentWizardContainer } from '@metaswiss/ui-kit/src/components/organism/payment-wizard/paymentWizard.styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../api/msApi';
import { AddressType } from '../../../../enums/addressType.enum';
import { PaymentMethod } from '../../../../enums/paymentMethod.enum';
import { ProductType } from '../../../../enums/productType.enum';
import { ApiResource } from '../../../../enums/resource.enum';
import { AppState, useAppState } from '../../../../global-state/zustand';
import { useMediaQuery } from '../../../../hooks/use-media-query/useMediaQuery';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { swissCode } from '../../../../shared/constants/currencyCode';
import { calculatePriceWithoutVat, calculateVat } from '../../../../shared/helpers/calculateVat.helper';
import { getNftProductDescription } from '../../../../shared/helpers/getNftProductDescription.helper';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';
import { usePaymentContext } from '../context/PaymentContext';
import {
  BackgroundGradient,
  MetaSwissBacgroundGradient,
  PaymentBillContainer,
  PaymentFlowContainer,
  Wrapper,
} from '../payment.styles';
import { BankTransfer } from '../steps/BankTransfer';
import { ChoosePaymentMethod } from '../steps/ChoosePaymentMethod';
import { DeliveryAddress } from '../steps/DeliveryAddress';
import { CreditCardPayment } from '../steps/credit-card-payment/CreditCardPayment';

export const PaymentNft = () => {
  const {
    paymentMethod,
    newDeliveryAddress,
    setDeliveryAddressId,
    item,
    currency,
    setClientSecret,
    setPaymentIntentId,
    setTransactionId,
    setNewDeliveryAddress,
    setAddressType,
    addressType,
  } = usePaymentContext();
  const { textTranslation } = useTextTranslation();
  const { currentStep, setWizardSteps, onNext, buttonText, isButtonDisabled, isButtonLoading } = usePaymentWizard();
  const isBigScreen = useMediaQuery(breakpoints.desktopSm);
  const query = useQueryClient();
  const user = useAppState((state: AppState) => state.user);
  const navigate = useNavigate();
  const theme = useTheme();

  const isMetaswiss = theme.v2.tenant === 'MetaSwiss'; // TODO: temporary solution

  const nftItem = useMemo(() => item as NftExtendedV5Response, [item]);

  const { data: deliveryAddress } = useQuery({
    queryKey: getQueryKey(ApiResource.DELIVERY_ADDRESS),
    queryFn: () => api.userDeliveryAddresses.getUserDeliveryAddress(),
  });

  const { data: productAsset } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT_PRODUCT_LOGO, nftItem?.nftCollection?.product?.id),
    queryFn: () => {
      return api.nftProduct.getNftProductAssetById(nftItem?.nftCollection?.product?.id || '');
    },
    enabled: !!nftItem?.nftCollection?.product?.id,
  });

  const { data: productImageUrl } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT_COLLECTION_LOGO, productAsset?.id),
    queryFn: () => {
      return api.assets.getS3SignedAssignedUrl({ assetId: productAsset?.id || '' });
    },
    enabled: !!productAsset?.id,
  });

  const { mutate: createDeliveryAddress } = useMutation({
    mutationFn: () =>
      api.userDeliveryAddresses.createNewDeliveryAddress({
        address: newDeliveryAddress.address,
        zipCode: newDeliveryAddress.zipCode,
        city: newDeliveryAddress.city,
        countryId: newDeliveryAddress.country.id,
      }),
    onSuccess: async (response) => {
      setDeliveryAddressId(response.id);
      await query.invalidateQueries(getQueryKey(ApiResource.DELIVERY_ADDRESS));
      if (paymentMethod !== PaymentMethod.BANK) {
        createStripeBase(response.id);
      }
    },
  });

  const { mutate: createStripeBase } = useMutation({
    mutationFn: (deliveryAddress: string) => {
      return api.payment.createStripePaymentBase({
        currencyId: currency?.id || '',
        quantity: 1,
        paymentMethod: PaymentMethod.STRIPE,
        productType: ProductType.NFT,
        itemId: nftItem.id,
        useDefaultAddress: false,
        deliveryAddress: deliveryAddress,
        stripePaymentMethod: '',
      });
    },
    onSuccess: (response) => {
      const { clientSecret, paymentIntentId, transactionId } = response;
      setClientSecret(clientSecret);
      setPaymentIntentId(paymentIntentId);
      setTransactionId(transactionId);
    },
  });

  const { data: vat } = useQuery({
    queryKey: getQueryKey(ApiResource.VAT),
    queryFn: () => {
      return api.vat.getVat();
    },
    enabled: true,
  });

  useEffect(() => {
    if (deliveryAddress) {
      setAddressType(AddressType.DELIVERY_ADDRESS);
      setNewDeliveryAddress({
        country: {
          id: deliveryAddress.country.id,
          name: deliveryAddress.country.name,
        },
        city: deliveryAddress.city,
        address: deliveryAddress.address,
        zipCode: deliveryAddress.zipCode,
      });
    } else {
      setAddressType(AddressType.NEW_DELIVERY_ADDRESS);
      setNewDeliveryAddress({
        country: {
          id: user?.country?.id || '',
          name: user?.country?.name || '',
        },
        city: user?.city || '',
        address: user?.address || '',
        zipCode: user?.zipCode || '',
      });
    }
  }, [deliveryAddress, setAddressType, setNewDeliveryAddress, user]);

  const steps = useMemo(
    () => [
      {
        title: textTranslation('payment.choosePaymentMethod'),
        subtitle: textTranslation('payment.choosePaymentMethodSubtitle'),
        children: <ChoosePaymentMethod />,
        buttonText: textTranslation('payment.continueToDelivery'),
        currentStep: 1,
        totalSteps: 3,
        prevStep: () => navigate(-1),
      },
      {
        title: textTranslation('payment.chooseDeliveryAddress'),
        subtitle: textTranslation('payment.newDeliveryAddress'),
        children: <DeliveryAddress />,
        buttonText: textTranslation('payment.continueToPayment'),
        currentStep: 2,
        nextStep: createDeliveryAddress,
        totalSteps: 3,
      },
      {
        title:
          paymentMethod === PaymentMethod.BANK
            ? textTranslation('payment.bankTransferTitle')
            : textTranslation('global.stripe'),
        subtitle:
          paymentMethod === PaymentMethod.BANK
            ? textTranslation('payment.pleaseReadInstruction')
            : textTranslation('payment.creditCardDetails'),
        children: paymentMethod === PaymentMethod.BANK ? <BankTransfer /> : <CreditCardPayment />,
        buttonText:
          paymentMethod === PaymentMethod.BANK
            ? textTranslation('payment.submitReservation')
            : textTranslation('payment.payValue', {
                value: formatAmount(nftItem?.nftCollection?.price, 2, 1, swissCode),
              }),
        currentStep: 3,
        totalSteps: 3,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentMethod, createDeliveryAddress, nftItem]
  );

  //This use case is for mini tablets on step two nft payment flow
  // Determine if the screen is a mini tablet
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    const expendableCard = document.getElementsByClassName(ExpandableCardContainer.styledComponentId)[0];
    const contentContainer = document.getElementsByClassName(
      PaymentWizardContainer.styledComponentId
    )[0] as HTMLElement;
    if (currentStep === 2 && addressType === AddressType.NEW_DELIVERY_ADDRESS) {
      if (body.clientWidth < 1440) {
        contentContainer.style.marginBottom = `${expendableCard.clientHeight}px`;
      }
    } else {
      contentContainer.style.marginBottom = `0px`;
    }
  }, [currentStep, addressType]);

  useEffect(() => {
    setWizardSteps(steps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps]);

  const nftDetails = {
    title: textTranslation('payment.paymentDetails'),
    isVisibleTitleStep: 2,
    items: [
      {
        icon: BankIcon,
        subText: textTranslation('payment.paymentMethod'),
        text:
          paymentMethod === PaymentMethod.BANK
            ? textTranslation('payment.bankTransfer')
            : textTranslation('global.stripe'),
        isVisibleStep: 2,
      },
      {
        icon: SendIcon,
        subText: textTranslation('payment.deliveryAddress'),
        text: `${newDeliveryAddress?.address}, ${newDeliveryAddress?.city}, ${newDeliveryAddress?.country?.name}, ${newDeliveryAddress?.zipCode}`,
        isVisibleStep: 3,
      },
    ],
  };

  const wizardExitAnimation = useMemo(() => {
    if (isBigScreen)
      return {
        left: '-50%',
        opacity: 0,
      };
    return {
      top: '-50%',
      opacity: 0,
    };
  }, [isBigScreen]);

  const billExitAnimation = useMemo(() => {
    if (isBigScreen)
      return {
        left: '100%',
        opacity: 0,
      };
    return {
      bottom: '-25%',
      opacity: 0,
    };
  }, [isBigScreen]);

  const transition = useMemo(() => {
    return { ease: 'easeInOut', duration: 0.4 };
  }, []);

  return (
    <Wrapper exit={{ overflow: 'hidden' }}>
      <PaymentFlowContainer exit={wizardExitAnimation} transition={transition}>
        <PaymentWizard />
      </PaymentFlowContainer>
      <PaymentBillContainer exit={billExitAnimation} transition={transition}>
        {isMetaswiss ? <MetaSwissBacgroundGradient /> : <BackgroundGradient />}
        <ResponsiveBill
          title={textTranslation('payment.overview')}
          amountText={textTranslation('payment.totalAmount')}
          amount={formatAmount(nftItem?.nftCollection?.price, 2, 1, swissCode)}
          extraBillInfo={[
            {
              text: textTranslation('payment.itemPrice'),
              price: formatAmount(
                calculatePriceWithoutVat(nftItem?.nftCollection?.price, Number(vat?.value ?? 0)),
                2,
                1,
                swissCode
              ),
            },
            {
              text: textTranslation('payment.tax'),
              price: formatAmount(
                calculateVat(nftItem?.nftCollection?.price, Number(vat?.value ?? 0)),
                2,
                1,
                swissCode
              ),
            },
          ]}
          buttonText={buttonText}
          buttonDisabled={isButtonDisabled}
          buttonLoading={isButtonLoading}
          onClick={onNext}
        >
          <NftDetails
            nftId={nftItem?.code?.split('-')[1]}
            chain={nftItem?.nftCollection?.name}
            creator={nftItem?.nftCollection?.issuer?.name}
            nftImageUrl={nftItem?.thumbnailUrl}
            productDescription={getNftProductDescription(nftItem?.nftCollection?.product?.description?.content)}
            productHeading={nftItem?.nftCollection?.product?.title}
            productImageUrl={productImageUrl?.url ?? ''}
            productDetailsTranslationKey={'global.productDetails'}
            detailsTranslationKey={'global.details'}
            nftIdTranslationKey={'global.nftId'}
            chainTranslationKey={'global.chain'}
            creatorTranslationKey={'global.creator'}
            textTranslation={textTranslation}
          />
          <DetailsList {...nftDetails} step={currentStep} isNftDetails={true} />
        </ResponsiveBill>
      </PaymentBillContainer>
    </Wrapper>
  );
};
