import { ApiResult } from '@metaswiss/api/src/api/core/ApiResult';
import { BlurredBackground, Button, Heading, Text } from '@metaswiss/ui-kit';
import { breakpointValues } from '@metaswiss/ui-kit/src/breakpoints.ts';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import { useTheme } from 'styled-components';

import { api } from '../../api/msApi';
import { AppState, useAppState } from '../../global-state/zustand.ts';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../router/routes';
import { tenantConfig } from '../../tenantConfig.ts';

import {
  ButtonWrapper,
  ContentContainer,
  HeadingWrapper,
  Icon,
  Layout,
  LogoContainer,
  PuffLoaderWrapper,
  VerifyWrapper,
  Wrapper,
} from './styles/verify.styles';

export const Verify = () => {
  const { textTranslation } = useTextTranslation();

  const theme = useTheme();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [responseStatus, setResponseStatus] = useState<number | undefined>(undefined);

  const tokenParam = searchParams.get('token');

  const { mutate, isLoading, isSuccess, isError } = useMutation({
    mutationFn: (variables: { tokenParam: string }) =>
      api.auth.verifyUserAccount({ verifyAccountToken: variables.tokenParam }),
    onError: (response: ApiResult) => {
      setResponseStatus(response.body.code);
    },
  });

  const isTokenParamAdded = useMemo(() => !!tokenParam, [tokenParam]);
  const isExpiredTokenError = useMemo(() => responseStatus === 498, [responseStatus]);
  const isAlreadyVerifiedError = useMemo(() => responseStatus === 409, [responseStatus]);

  const isGlobalError = useMemo(
    () => isError && !isExpiredTokenError && !isAlreadyVerifiedError,
    [isError, isAlreadyVerifiedError, isExpiredTokenError]
  );

  useEffect(() => {
    if (tokenParam) {
      mutate({ tokenParam });
    }
  }, [searchParams]);

  const { setRedirectingFromVerifyViaMobile } = useAppState((state: AppState) => state);

  const handleMoveToLogin = () => {
    // we want to ensure that is mobile, this is temp solution - we should consider reordering routes logic
    if (window.innerWidth < breakpointValues.tabletSm) {
      setRedirectingFromVerifyViaMobile(true);
    }
    navigate(routes.auth);
  };

  return isLoading ? (
    <PuffLoaderWrapper>
      <PuffLoader loading={isLoading} size={64} color={theme.v2.icon.primary} />
    </PuffLoaderWrapper>
  ) : (
    <Layout>
      <BlurredBackground
        palette={isSuccess || isAlreadyVerifiedError ? 'primary' : 'error'}
        customBlurBackground={tenantConfig.blurBackground}
      >
        <ContentContainer>
          <LogoContainer src={tenantConfig.desktopLogo} />
          <VerifyWrapper>
            <Wrapper>
              <HeadingWrapper>
                {isGlobalError && <Icon src={tenantConfig.errorIcon} />}
                {!isTokenParamAdded && <Icon src={tenantConfig.errorIcon} />}
                {isExpiredTokenError && <Icon src={tenantConfig.errorIcon} />}
                {isAlreadyVerifiedError && <Icon src={tenantConfig.successIcon} />}
                {isSuccess && <Icon src={tenantConfig.successIcon} />}
                {isGlobalError && (
                  <Heading headingType={'h3'} color={theme.v2.text.headingPrimary} fontWeight={'bold'}>
                    {textTranslation('verify.errorHeading')}
                  </Heading>
                )}
                {!isTokenParamAdded && (
                  <Heading headingType={'h3'} color={theme.v2.text.headingPrimary} fontWeight={'bold'}>
                    {textTranslation('verify.errorHeading')}
                  </Heading>
                )}
                {isAlreadyVerifiedError && (
                  <Heading headingType={'h3'} color={theme.v2.text.headingPrimary} fontWeight={'bold'}>
                    {textTranslation('verify.alreadyActivatedHeading')}
                  </Heading>
                )}
                {isExpiredTokenError && (
                  <Heading headingType={'h3'} color={theme.v2.text.headingPrimary} fontWeight={'bold'}>
                    {textTranslation('verify.errorHeading')}
                  </Heading>
                )}
                {isSuccess && (
                  <Heading headingType={'h3'} color={theme.v2.text.headingPrimary} fontWeight={'bold'}>
                    {textTranslation('verify.successHeading')}
                  </Heading>
                )}
              </HeadingWrapper>
              {isGlobalError && <Text color={theme.v2.text.disabled}>{textTranslation('verify.errorText')}</Text>}
              {!isTokenParamAdded && <Text color={theme.v2.text.disabled}>{textTranslation('verify.errorText')}</Text>}
              {isExpiredTokenError && (
                <Text color={theme.v2.text.disabled}>{textTranslation('verify.expiredTokenText')}</Text>
              )}
              {isAlreadyVerifiedError && (
                <Text color={theme.v2.text.disabled}>{textTranslation('verify.alreadyActivatedText')}</Text>
              )}
              {isSuccess && <Text color={theme.v2.text.disabled}>{textTranslation('verify.successText')}</Text>}
            </Wrapper>
            <ButtonWrapper>
              {(isSuccess || isAlreadyVerifiedError) && (
                <Button text={textTranslation('verify.logIn')} onClick={() => handleMoveToLogin()} fill />
              )}
            </ButtonWrapper>
          </VerifyWrapper>
        </ContentContainer>
      </BlurredBackground>
    </Layout>
  );
};
