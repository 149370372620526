import styled from 'styled-components';

export const AddAvatarIconWrapper = styled.div<{ $disabled: boolean }>`
  height: 2.5rem;
  width: 2.5rem;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: ${({ $disabled }) => ($disabled ? 'auto' : 'pointer')};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  background-color: ${({ theme }) => theme.v2.surface.primary};
`;
