import { Theme } from '@metaswiss/ui-kit';
import { ThemeProvider as SCThemeProvider } from 'styled-components';

type ThemeProviderProps = {
  children: React.ReactNode;
  theme: Theme;
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children, theme }) => {
  return <SCThemeProvider theme={theme}>{children}</SCThemeProvider>;
};
