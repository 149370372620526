import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { breakpoints } from '../../../../breakpoints';

export const Container = styled.div`
  grid-area: Sidebar;
  border-right: 1px solid ${({ theme }) => theme.colors.neutrals.hue100};
  background-color: ${({ theme }) => theme.colors.neutrals.hue0};
  height: 100%;
  width: 6.5rem;
  padding: 1.5rem;
  z-index: 4;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media ${breakpoints.desktopSm} {
    width: 17.5rem;
  }
`;

export const IconLink = styled(NavLink)`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Icon = styled.img<{ $desktopIcon?: string }>`
  @media ${breakpoints.desktopSm} {
    content: url(${({ $desktopIcon }) => $desktopIcon});
  }
`;

export const SidebarHeader = styled.div`
  margin-bottom: 2rem;
  max-width: 13.25rem;
`;

export const SidebarFooter = styled.div`
  display: block;
`;
export const ItemWrapper = styled.div`
  position: relative;
`;
export const SubMenu = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 5.5rem;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.neutrals.hue0};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 17.5rem;
  transform-origin: top left;
  box-shadow:
    0px 0px 16px 0px rgba(0, 0, 0, 0.02),
    0px 1px 0px 0px rgba(0, 0, 0, 0.02),
    0px 10px 24px 0px rgba(0, 0, 0, 0.02);
  @media ${breakpoints.desktopSm} {
    display: flex;
    flex-direction: column;
    position: static;
    padding: 0;
    width: 100%;
    margin-top: 0.5rem;
    box-shadow: none;
  }
`;
