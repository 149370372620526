import {
  BottomNavigationBar,
  Breadcrumb,
  BreadcrumbType,
  Button,
  ChevronRightIcon,
  Dialog,
  Input,
  PageShell,
  SearchIcon,
  SettingsIcon,
  SideBar,
  Skeleton,
  SquareIconButton,
  TabsContainer,
  Text,
  ThemedIcon,
} from '@metaswiss/ui-kit';
import { LeavingIcon, PlusIcon, Xicon } from '@metaswiss/ui-kit/src/iconography';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { useTheme } from 'styled-components';

import { api } from '../../api/msApi';
import { removeAccessToken, removeRefreshToken } from '../../api/tokenHelpers';
import { AvatarDropdown } from '../../components/avatar-dropdown/AvatarDropdown';
import { ClientHeader } from '../../components/header/ClientHeader';
import { NotificationPopup } from '../../components/notification-popup/NotificationPopup';
import { SupportModal } from '../../components/support-modal/SupportModal';
import { AssignedUserAssetEnum } from '../../enums/assignedUserAssetEnum';
import { FilterType } from '../../enums/filterType.enum';
import { ApiResource } from '../../enums/resource.enum';
import { UserRole } from '../../enums/userRole';
import { WebSocketEvents } from '../../enums/webSocketEvents.enum';
import { environmentVariables } from '../../env/environmentVariables';
import {
  AppState,
  ShellNavigationState,
  useAppState,
  useHeaderOptions,
  useShellNavigationState,
} from '../../global-state/zustand';
import { useDeleteUser } from '../../hooks/use-delete-user/useDeleteUser';
import { useGetCurrentRoute } from '../../hooks/use-get-current-route/useGetCurrentRoute';
import { useRouteValidationCheck } from '../../hooks/use-route-validation-check/useRouteValidationCheck';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../router/routes';
import { swissCode } from '../../shared/constants/currencyCode';
import { filterClientHeader } from '../../shared/constants/filterClientHeader';
import { searchClientHeader } from '../../shared/constants/searchClientHeader';
import { defaultUser } from '../../shared/helpers/defaultUser';
import { formatRouteAsText } from '../../shared/helpers/formatRouteAsText';
import { getQueryKey } from '../../shared/helpers/getQueryKey.helper';
import { ClientHeaderSearch } from '../../shared/types/clientHeaderSearch.type';
import { SideBarItemProps } from '../../shared/types/sidebarItemProps';
import { NavigationTabProps } from '../../shared/types/tabProps';
import { tenantConfig } from '../../tenantConfig.ts';

import { createHeaderBreadcrumbs } from './helpers/createHeaderBreadcrumbs';
import { sidebarItems } from './shared/sidebar/items/items';
import { HeaderTitleWrapper, ProtectedLayoutContainer } from './styles/protectedLayout.styles';

type WebSocketMessage = {
  data: {
    payload: {
      userId: string;
      transactionId?: string;
      orderId?: string;
      id?: string;
    };
  };
  event: string;
};

export const ProtectedLayout: FC = () => {
  const { tabs, selectedTab, setSelectedTab, isChangeRouteAvailable } = useShellNavigationState(
    (state: ShellNavigationState) => state
  );
  const { currentRoute, locationPath } = useGetCurrentRoute();
  const { textTranslation, currentLanguage } = useTextTranslation();

  useRouteValidationCheck();

  const user = useAppState((state) => state.user) || defaultUser;
  const removeUser = useAppState((state: AppState) => state.removeUser);
  const setCurrency = useAppState((state: AppState) => state.setCurrency);
  const sidebarVisible = useAppState((state: AppState) => state.sidebarVisible);
  const headerVisible = useAppState((state: AppState) => state.headerVisible);
  const setSidebarVisible = useAppState((state: AppState) => state.setSidebarVisible);
  const setHeaderVisible = useAppState((state: AppState) => state.setHeaderVisible);
  const { leftBlurredSection, rightBlurredSection } = useAppState((state: AppState) => state);
  const { deleteUser } = useDeleteUser();

  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const queryClient = useQueryClient();
  const socketUrl = environmentVariables.webSocketUrl;

  const params = {
    userId: user.id,
    userType: 'endUser',
  };

  const { lastJsonMessage } = useWebSocket(socketUrl, {
    onOpen: () => console.log('opened'),
    onClose: () => console.log('closed'),
    queryParams: params,
    heartbeat: {
      message: 'ping',
      returnMessage: 'pong',
      timeout: 120000,
      interval: 60000,
    },
  });

  const userResource = useMemo(() => {
    return user?.role === UserRole.PRIVATE ? ApiResource.PRIVATE_USER : ApiResource.CORPORATE_USER;
  }, [user]);

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      return api.auth.logout();
    },
    onSuccess: () => {
      navigate(routes.login, { replace: true });
      removeRefreshToken();
      removeAccessToken();
      removeUser();
      queryClient.invalidateQueries();
      queryClient.removeQueries();
    },
  });

  const { mutate: updatePreferredLanguageMutate } = useMutation({
    mutationFn: (data: string) => {
      return api.users.updatePreferredLanguage(user?.id || '', { preferredLanguage: data });
    },
  });

  useEffect(() => {
    console.log(lastJsonMessage);
    switch ((lastJsonMessage as WebSocketMessage)?.event) {
      case WebSocketEvents.USER_DATA_UPDATE:
        queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.USER_RESPONSE) });
        queryClient.invalidateQueries({ queryKey: getQueryKey(userResource) });
        break;
      case WebSocketEvents.ADMIN_NOTIFICATION_SEND:
        queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.NOTIFICATIONS) });
        break;
      case WebSocketEvents.CASE_CREATED:
        queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.NOTIFICATIONS) });
        queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.CASE, 'active') });
        break;
      case WebSocketEvents.APPROVE_CASE:
      case WebSocketEvents.REJECT_CASE:
        queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.CASE, 'active') });
        queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.CASE, 'history') });
        queryClient.invalidateQueries({
          queryKey: getQueryKey(ApiResource.CASE, (lastJsonMessage as WebSocketMessage).data.payload.id),
        });
        break;
      case WebSocketEvents.ADMIN_RESPONSE_TO_CASE:
        queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.CASE, 'active') });
        queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.CASE, 'history') });
        queryClient.invalidateQueries({
          queryKey: getQueryKey(ApiResource.CASE, (lastJsonMessage as WebSocketMessage).data.payload.id),
        });
        queryClient.invalidateQueries({
          queryKey: getQueryKey(ApiResource.CASE_CONVERSATION, (lastJsonMessage as WebSocketMessage).data.payload.id),
        });
        queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.CASE, 'history') });
        break;
      case WebSocketEvents.TRANSACTION_ACCEPTED:
        queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.NOTIFICATIONS) });
        queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.TRANSACTIONS) });
        queryClient.invalidateQueries({
          queryKey: getQueryKey(
            ApiResource.SINGLE_TRANSACTION,
            (lastJsonMessage as WebSocketMessage).data.payload.transactionId
          ),
        });
        break;
      case WebSocketEvents.TRANSACTION_REJECTED:
        queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.NOTIFICATIONS) });
        queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.TRANSACTIONS) });
        queryClient.invalidateQueries({
          queryKey: getQueryKey(
            ApiResource.SINGLE_TRANSACTION,
            (lastJsonMessage as WebSocketMessage).data.payload.transactionId
          ),
        });
        break;
      case WebSocketEvents.TRANSACTION_DOCUMENTS_GENERATED:
        queryClient.invalidateQueries({
          queryKey: getQueryKey(
            ApiResource.SINGLE_TRANSACTION,
            (lastJsonMessage as WebSocketMessage).data.payload.transactionId
          ),
        });
        queryClient.invalidateQueries({
          queryKey: getQueryKey(ApiResource.PORTFOLIO, (lastJsonMessage as WebSocketMessage).data.payload.orderId),
        });
        queryClient.invalidateQueries({
          queryKey: getQueryKey(
            ApiResource.NFT_TRANSACTION,
            (lastJsonMessage as WebSocketMessage).data.payload.orderId
          ),
        });
        break;
      case WebSocketEvents.THUMBNAIL_GENERATED:
        queryClient.invalidateQueries({
          queryKey: getQueryKey(ApiResource.ASSET, (lastJsonMessage as WebSocketMessage).data.payload.userId),
        });
        queryClient.invalidateQueries({
          queryKey: getQueryKey(
            ApiResource.ASSET,
            `${AssignedUserAssetEnum.THUMBNAIL}/${(lastJsonMessage as WebSocketMessage).data.payload.userId}`
          ),
        });
        break;
      case WebSocketEvents.USER_DELETED:
        deleteUser();
        break;
      case WebSocketEvents.ADMIN_CREATED_CASE:
        queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.CASE, 'active') });
        break;
      case WebSocketEvents.SUSPEND_USER:
        if ((lastJsonMessage as WebSocketMessage).data.payload.userId === user.id) {
          mutate();
        }
    }
  }, [queryClient, lastJsonMessage, deleteUser, userResource, mutate, user.id]);

  const {
    setSearch,
    headerTitle: globalHeaderTitle,
    selectedFilters,
    setSelectedFilters,
    setIsFilterDisplayed,
    isFilterButtonDisplayed,
    headerLoading,
  } = useHeaderOptions();

  const [selectedSidebarItem, setSelectedSidebarItem] = useState<string | undefined>('');
  const [isAvatarOpen, setIsAvatarOpen] = useState<boolean>(false);
  const [isNotificationPopupOpen, setNotificationPopupOpen] = useState<boolean>(false);
  const [isOpenLogoutDialog, setIsOpenLogoutDialog] = useState<boolean>(false);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbType[]>([]);
  const [searchOptions, setSearchOptions] = useState<ClientHeaderSearch>({ showSearch: false });
  const [headerTitle, setHeaderTitle] = useState<string>('');
  const [isSupportModalOpen, setIsSupportModalOpen] = useState<boolean>(false);
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
  const [isFilterArrayFull, setIsFilterArrayFull] = useState<boolean>(false);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const { data: currencies, refetch } = useQuery({
    queryKey: getQueryKey(ApiResource.CURRENCY),
    queryFn: () => {
      return api.currencies.findAllCurrencies();
    },
  });

  useEffect(() => {
    updatePreferredLanguageMutate(currentLanguage);
  }, [currentLanguage]);

  const onLogout = async () => {
    await Promise.all([mutate()]);
  };

  useEffect(() => {
    const fetchCurrenciesInterval = setInterval(
      () => {
        refetch();
      },
      15 * 60 * 1000
    );

    return () => clearInterval(fetchCurrenciesInterval);
  }, [refetch]);

  useEffect(() => {
    if (currencies) {
      setCurrency(currencies.find((currency) => currency.currencyCode === swissCode));
    }
  }, [currencies, setCurrency]);

  useEffect(() => {
    const clientSearch = searchClientHeader.find((route) => location.pathname === route.route);
    if (!clientSearch) {
      setSearchOptions({ showSearch: false });
    } else {
      setSearchOptions({
        showSearch: true,
        searchText: textTranslation(`header.${clientSearch.translationKey}`),
        regex: clientSearch.regex,
      });
    }
  }, [location.pathname, searchClientHeader, filterClientHeader]);

  useEffect(() => {
    const filter = filterClientHeader.find((route) => !!matchPath(route.route, location.pathname));
    if (!filter) {
      setIsFilterVisible(false);
    } else {
      setIsFilterVisible(isFilterButtonDisplayed);
    }
  }, [isFilterButtonDisplayed, isFilterVisible, location.pathname]);

  useEffect(() => {
    const updateBreadcrumbs: BreadcrumbType[] = [];
    const locationPath = location.pathname;
    const locationPathNodes = locationPath.split('/').slice(1);
    const currentRoute = locationPathNodes[locationPathNodes.length - 1];
    const routeName = formatRouteAsText(currentRoute, textTranslation) as string;
    const currentTab = tabs?.find((tab) => tab.route === locationPath);

    if (!tabs) {
      createHeaderBreadcrumbs({
        locationPathNodes,
        breadcrumbs: updateBreadcrumbs,
        globalTitle: globalHeaderTitle,
        textTranslation,
      });
      setHeaderTitle(locationPathNodes.length > 2 && globalHeaderTitle ? globalHeaderTitle : routeName);
    } else {
      const labelTitle = currentTab ? currentTab.label?.replace(/\([^)]+\)/, '').trim() : '';
      setHeaderTitle(labelTitle);

      createHeaderBreadcrumbs({ locationPathNodes, breadcrumbs: updateBreadcrumbs, currentTab, textTranslation });
    }
    setBreadcrumbs(updateBreadcrumbs);
  }, [location.pathname, currentRoute, globalHeaderTitle, tabs, currentLanguage]);

  useEffect(() => {
    if (!location.pathname.includes(routes.payment.root)) {
      setSidebarVisible(true);
      setHeaderVisible(true);
    } else {
      setSidebarVisible(false);
      setHeaderVisible(false);
    }
  }, [location.pathname, setHeaderVisible, setSidebarVisible]);
  useEffect(() => {
    const selectedTab = tabs?.find((tab) => tab.route === locationPath);
    if (selectedTab) setSelectedTab(selectedTab);

    if (locationPath === routes.root) navigate(routes.home.root);
    const currentSidebarItem = sidebarItems.find((item) => item.route.includes(currentRoute ?? ''));
    setSelectedSidebarItem(currentSidebarItem?.value || undefined);
  }, [currentRoute, locationPath, navigate, setSelectedTab, tabs]);

  const headerOptions = {
    showLogo: true,
    showTitle: true,
    showBreadcrumbs: true,
    showInput: searchOptions.showSearch,
    showIcons: Array.from({ length: 4 }, () => true),
    inputAction: () => console.log('input action'),
    buttonAction: () => console.log('button action'),
  };

  const icons = useMemo(
    () => [
      <NotificationPopup isOpen={isNotificationPopupOpen} setIsOpen={setNotificationPopupOpen} />,

      <SquareIconButton
        icon={<ThemedIcon icon={() => <SettingsIcon />} size="medium" />}
        onClick={() => navigate(routes.settings)}
        padding="0.5rem"
        selectedButton={routes.settings === location.pathname}
      />,

      <AvatarDropdown
        isOpen={isAvatarOpen}
        setIsOpen={setIsAvatarOpen}
        onLogoutClick={() => {
          setIsOpenLogoutDialog(true);
          setIsAvatarOpen(false);
        }}
        onSupportClick={() => {
          setIsSupportModalOpen(true);
          setIsAvatarOpen(false);
        }}
      />,
    ],
    [isAvatarOpen, isNotificationPopupOpen, location.pathname, navigate, queryClient]
  );

  const handleSidebarItemClick = (item: SideBarItemProps) => {
    navigate(item.route);
  };

  const setShellNavigationCurrentTab = (tab: NavigationTabProps) => {
    if (tab?.route && isChangeRouteAvailable) {
      navigate(tab.route);
    }
  };

  const debounceSearchValueChange = debounce(() => {
    setSearch(searchInputValue, false);
    const clientSearch = searchClientHeader.find((route) => location.pathname === route.route);
    if (!selectedFilters.length) {
      clientSearch
        ? setSelectedFilters([
            ...(selectedFilters || []),
            {
              id: FilterType.SEARCH,
              label: FilterType.SEARCH,
              value: FilterType.SEARCH,
            },
          ])
        : setSelectedFilters([...(selectedFilters || [])]);
    }
  }, 500);

  const filteredSelectedFilters = useMemo(
    () => selectedFilters.filter((item) => item.value !== FilterType.SEARCH),
    [selectedFilters]
  );

  useEffect(() => {
    debounceSearchValueChange();
    return () => debounceSearchValueChange.cancel();
  }, [searchInputValue]);

  useEffect(() => {
    return () => {
      setSearchInputValue('');
    };
  }, [locationPath]);

  useEffect(() => {
    setIsFilterArrayFull(filteredSelectedFilters.length === 0);
  }, [filteredSelectedFilters]);

  const blurredBackgroundColors = useMemo(() => {
    const colors = {
      purple: theme.v2.colors.secondary100,
      blue: theme.v2.colors.primary100,
      green: theme.v2.colors.success100,
    };
    return {
      left: colors[leftBlurredSection],
      right: colors[rightBlurredSection],
    };
  }, [leftBlurredSection, rightBlurredSection, theme]);

  return (
    <ProtectedLayoutContainer $isSidebarVisible={sidebarVisible} $isHeaderVisible={headerVisible}>
      <Dialog
        isOpen={isOpenLogoutDialog}
        close={() => {
          setIsOpenLogoutDialog(false);
          setIsAvatarOpen(false);
        }}
        primaryText={textTranslation('logout.leaving')}
        secondaryText={textTranslation('logout.leavingFollowUp')}
        buttonColor={'error'}
        primaryButtonText={textTranslation('logout.logout')}
        onPrimaryButtonClick={async () => await onLogout()}
        secondaryButtonText={textTranslation('logout.cancel')}
        onSecondaryButtonClick={() => setIsOpenLogoutDialog(false)}
        isPrimaryButtonLoading={isLoading}
        iconBackgroundColor={'error'}
        renderIcon={() => (
          <ThemedIcon
            icon={LeavingIcon}
            size="large"
            customColor={theme.v2.icon.error}
            customStrokeColor={theme.v2.icon.onAction}
            hasBorderRadius={true}
          />
        )}
      />
      <SupportModal isOpen={isSupportModalOpen} close={() => setIsSupportModalOpen(false)} />
      <SideBar
        mobileIcon={tenantConfig.logoSmall}
        desktopIcon={tenantConfig.logoBig}
        position="fixed"
        selectedItem={selectedSidebarItem}
        items={sidebarItems.map((item) => ({
          ...item,
          label: textTranslation(`sidebar.${item.value}`),
          onClick: () => {
            handleSidebarItemClick(item);
          },
        }))}
      />
      <ClientHeader
        headerOptions={headerOptions}
        logo={<img src={tenantConfig.logo} onClick={() => navigate(routes.home.root)} />}
        title={
          <HeaderTitleWrapper>
            {headerLoading ? (
              <Skeleton width={'8.75rem'} height={'1.5rem'} />
            ) : (
              <Text fontSize="base" fontWeight="bold" color={theme.v2.text.headingPrimary}>
                {headerTitle || textTranslation('global.loading')}
              </Text>
            )}
          </HeaderTitleWrapper>
        }
        breadcrumbs={
          headerLoading ? (
            <Skeleton width={'18.75rem'} height={'1rem'} />
          ) : (
            <Breadcrumb
              onSelect={(e) => navigate(e)}
              breadcrumbs={breadcrumbs}
              fontSize="xsm"
              iconSize="1rem"
              renderIcon={() => (
                <ThemedIcon icon={ChevronRightIcon} size="full" customStrokeColor={theme.v2.text.disabled} />
              )}
            />
          )
        }
        icons={icons}
        isFilterVisible={isFilterVisible}
        input={
          <Input
            type="search"
            fitHeight
            isVisibleLabel={false}
            renderStartIcon={({ iconSize }) => (
              <ThemedIcon icon={SearchIcon} size={iconSize} customStrokeColor={theme.v2.text.headingPrimary} />
            )}
            label={searchOptions.searchText || ''}
            onChange={(event) => {
              const value = searchOptions.regex?.test(event.target.value) ? event.target.value : searchInputValue;
              if (value !== searchInputValue) {
                setSearch(value, true);
                setSearchInputValue(value);
              }
            }}
            value={searchInputValue}
          />
        }
      />
      <PageShell
        leftColor={blurredBackgroundColors.left}
        rightColor={blurredBackgroundColors.right}
        firstComponent={
          (tabs || filteredSelectedFilters.length) && (
            <TabsContainer
              isFilterArrayEmpty={!isFilterArrayFull}
              tabs={tabs || []}
              selectedTab={selectedTab}
              onTabSelect={setShellNavigationCurrentTab}
            >
              {filteredSelectedFilters.map((item) => (
                <Button
                  key={item.id}
                  variant="outlined"
                  color="neutral"
                  text={item.label}
                  size={'small'}
                  renderEndIcon={() => (
                    <ThemedIcon
                      onClick={() =>
                        setSelectedFilters(selectedFilters.filter((selectedItem) => selectedItem.id !== item.id))
                      }
                      icon={Xicon}
                      size="small"
                      customStrokeColor={theme.v2.icon.neutral}
                    />
                  )}
                />
              ))}
              {filteredSelectedFilters.length > 0 && (
                <Button
                  size={'small'}
                  onClick={() => setIsFilterDisplayed(true)}
                  renderEndIcon={() => (
                    <ThemedIcon icon={PlusIcon} size="small" customStrokeColor={theme.colors.neutrals.hue0} />
                  )}
                />
              )}
            </TabsContainer>
          )
        }
      >
        <Outlet />
      </PageShell>
      <BottomNavigationBar
        position="fixed"
        selectedItem={selectedSidebarItem}
        items={sidebarItems.map((item) => ({
          ...item,
          label: textTranslation(`sidebar.${item.value}`),
          onClick: () => handleSidebarItemClick(item),
        }))}
      />
    </ProtectedLayoutContainer>
  );
};
