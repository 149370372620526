import styled from 'styled-components';

import { CardContainer } from '../base-card/baseCard.styles';

export const HeaderContainer = styled.div`
  padding: 0.75rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
`;

export const TransactionCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2rem;
`;

export const ProductInfoContainer = styled.div`
  padding: 1rem;
`;

export const IssuerInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const IssuerLogoHolder = styled.div`
  width: 2rem;
  height: 1.5rem;
  border-radius: ${({ theme }) => theme.dimensions.borderRadii.medium};
  overflow: hidden;
`;

export const IssuerLogoImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TransactionInfoWrapper = styled.div`
  display: flex;
  gap: 1rem;
  height: 100%;
  max-height: 2.75rem;
`;

export const IconWrapper = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.v2.surface.tertiary};
  border-radius: ${({ theme }) => theme.v2.radius.smaller};
`;

export const ProductTypeValuesLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const ProductTypeArrow = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  transition: all 0.2s ease-out;

  ${CardContainer}:hover & {
    transform: translateX(0.5rem);
  }
`;
