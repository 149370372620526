import { IconProps } from './iconProps';

export const ChevronRightIcon: React.FC<IconProps> = ({ color, strokeColor }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.60002 7.2002L14.4 12.0002L9.60002 16.8002"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
