import { memo } from 'react';

import { MultipleProgressBarContainer, SingleBarContainer } from './multipleProgressBar.styles';

export type MultipleProgressBarItem = {
  value: number;
  color?: string;
};

export type MultipleProgressBarProps = {
  items: MultipleProgressBarItem[];
};

export const MultipleProgressBar = memo(({ items }: MultipleProgressBarProps) => {
  const sortedItems = items.sort((a, b) => b.value - a.value);

  const totalValueOfItems = sortedItems.reduce((total, item) => total + item.value, 0);

  return (
    <MultipleProgressBarContainer>
      {sortedItems.map((item) => {
        const itemBarWidth = (item.value / totalValueOfItems) * 100;

        return <SingleBarContainer key={`${item.color}`} $color={item.color} $flex={itemBarWidth} />;
      })}
    </MultipleProgressBarContainer>
  );
});
