import { FormCheckbox, FormSelectionPopup, Globe02Icon, Text, TextLink, ThemedIcon } from '@metaswiss/ui-kit';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { api } from '../../../../../../../api/msApi';
import { setResendToken } from '../../../../../../../api/tokenHelpers';
import ControlledForm from '../../../../../../../components/controlled-form/ControlledForm.tsx';
import { PasswordValidationInputs } from '../../../../../../../components/passwords-validation-inputs/PasswordsValidationInputs';
import { useCountries } from '../../../../../../../hooks/use-countries/useCountries';
import { useTextTranslation } from '../../../../../../../hooks/use-text-translation/useTextTranslation';
import { MainContentContainer } from '../../../../../../../layouts/public-layout/styles/publicLayout.styles.ts';
import { routes } from '../../../../../../../router/routes';
import { tenantConfig } from '../../../../../../../tenantConfig.ts';
import { useAuthContext } from '../../../../../shared/auth-context/authContext.ts';
import { useSetAuthConfig } from '../../../../../shared/auth-context/useSetAuthConfig.ts';
import { RegisterHeader } from '../../../../shared/register-header/RegisterHeader';
import { RegistrationGridWrapper, TextLinkWrapper } from '../../../../shared/styles/registrationStyles';
import { ValidatedInputField } from '../../../../shared/validated-input-field/ValidatedInputField.tsx';

import { QuickCorporateRegistrationContainer, TermsCheckboxWrapper } from './quickRegistrationCorporate.styles';
import {
  createQuicCorporateSchema,
  QuickCorporateRegistrationFormData,
  quickRegistrationCorporateSchema,
} from './quickRegistrationCorporateSchema';

export const QuickRegistrationCorporateFlowView = () => {
  const navigate = useNavigate();
  const { textTranslation, currentLanguage } = useTextTranslation();
  const { countriesAsDropdown } = useCountries();
  const { setUserDetails, setIsValid, setLoading, userRole, clearRegistrationContext, setUserEmailField } =
    useAuthContext((state) => state);
  const [doPasswordsMatch, setDoPasswordsMatch] = useState<boolean>(true);
  const [userExists, setUserExists] = useState<boolean>(false);
  const [emailExists, setEmailExists] = useState<boolean>(false);

  const {
    control,
    formState: { errors, isValid },
    getValues,
    setError,
    handleSubmit,
    setValue,
  } = useFormContext<QuickCorporateRegistrationFormData>();

  const { mutate, isLoading } = useMutation(
    (data: QuickCorporateRegistrationFormData) => {
      return api.auth.quickRegistrationCorporateUser({
        email: data.email,
        password: data.password,
        passwordConfirmation: data.passwordConfirmation,
        name: data.name,
        preferredLanguage: currentLanguage,
        termsAndConditions: data.termsAndConditions,
        countryId: data.country.value,
      });
    },
    {
      onSuccess: (result) => {
        setResendToken(result.resendToken);
        setUserDetails({});
        navigate(routes.register.registerSuccess);
        clearRegistrationContext();
        setUserEmailField(getValues('email'));
      },
      onError: () => {
        navigate(routes.register.registerFailed);
      },
    }
  );

  const handleBackClick = () => {
    setValue(quickRegistrationCorporateSchema.termsAndConditions, false);
    navigate(`${routes.register.root}/${userRole}`);
  };

  useEffect(() => {
    setLoading(isLoading);
    return () => {
      setLoading(false);
    };
  }, [isLoading, setLoading]);
  useEffect(() => {
    setIsValid(isValid && doPasswordsMatch && !userExists && !emailExists);
    return () => {
      setIsValid(true);
    };
  }, [doPasswordsMatch, userExists, isValid, setIsValid, emailExists]);

  useSetAuthConfig(
    {
      wrapperStyle: 'registrationWizard',
      containerStyle: 'registrationWizard',
      backButton: {
        onClick: () => {
          setUserDetails(getValues());
          handleBackClick();
        },
        text: 'button.back',
      },
      nextButton: {
        text: 'registration.createAccount',
        onClick: () => handleSubmit((data) => mutate(data))(),
      },
    },
    [handleSubmit, mutate, navigate, userRole]
  );

  const checkEmailMutation = useMutation({
    mutationFn: () => {
      return api.users.checkEmailExists(getValues().email);
    },
    onError: () => {
      setError('email', { message: textTranslation('error.default') });
    },
    onSuccess: (result) => {
      setEmailExists(result);
      if (result) {
        setError('email', { message: textTranslation('error.emailExist') });
      }
    },
  });

  const checkValueMutation = useMutation({
    mutationFn: () => {
      return api.users.checkCorporateNameExists(getValues().name);
    },
    onError: () => {
      setError('name', { message: textTranslation('error.default') });
    },
    onSuccess: (result) => {
      setUserExists(result);
      if (result) {
        setError('name', { message: textTranslation('error.emailExist') });
      }
    },
  });

  return (
    <>
      <RegisterHeader descriptionText={textTranslation('registration.corporateRegistration.corporateTitle')} />
      <QuickCorporateRegistrationContainer>
        <ValidatedInputField<QuickCorporateRegistrationFormData>
          name={quickRegistrationCorporateSchema.name}
          control={control}
          label={textTranslation('registration.corporate.corporateName')}
          error={errors[quickRegistrationCorporateSchema.name]}
          fill
          checkValueExists={checkValueMutation.mutate}
          isSpinnerIcon={checkValueMutation?.isLoading}
        />
        <ValidatedInputField<QuickCorporateRegistrationFormData>
          name={quickRegistrationCorporateSchema.email}
          error={errors[quickRegistrationCorporateSchema.email]}
          control={control}
          fill
          label={textTranslation('registration.email')}
          checkValueExists={checkEmailMutation.mutate}
          isSpinnerIcon={checkEmailMutation?.isLoading}
        />
        <RegistrationGridWrapper>
          <PasswordValidationInputs
            control={control}
            passwordName={quickRegistrationCorporateSchema.password}
            passwordConfirmationName={quickRegistrationCorporateSchema.passwordConfirmation}
            passwordError={errors[quickRegistrationCorporateSchema.password]}
            passwordConfirmationError={errors[quickRegistrationCorporateSchema.passwordConfirmation]}
            passwordLabel={textTranslation('registration.password')}
            passwordConfirmationLabel={textTranslation('registration.passwordConfirmation')}
            doPasswordsMatch={doPasswordsMatch}
            setDoPasswordsMatch={setDoPasswordsMatch}
          />
        </RegistrationGridWrapper>
        <FormSelectionPopup
          name={quickRegistrationCorporateSchema.country}
          error={errors[quickRegistrationCorporateSchema.country] as FieldError}
          control={control}
          fill
          label={textTranslation('registration.country')}
          title={textTranslation('registration.country')}
          searchText={textTranslation('registration.search')}
          renderEndIcon={() => <ThemedIcon icon={Globe02Icon} palette="primary" strokeColor="hue300" />}
          itemsList={countriesAsDropdown}
          closeButtonText={textTranslation('cancel')}
          selectButtonText={textTranslation('select')}
          containerId={MainContentContainer.styledComponentId}
        />
        <TermsCheckboxWrapper>
          <FormCheckbox<QuickCorporateRegistrationFormData>
            name={quickRegistrationCorporateSchema.termsAndConditions}
            control={control}
            error={errors[quickRegistrationCorporateSchema.termsAndConditions]}
          />
          <TextLinkWrapper>
            <Text>{textTranslation('registration.acceptLabel')}</Text>
            <TextLink
              textSize="sm"
              onClick={() => window.open(tenantConfig.termsAndConditionsLink(currentLanguage), '_blank')}
            >
              {textTranslation('registration.termsAndConditions')}
            </TextLink>
          </TextLinkWrapper>
        </TermsCheckboxWrapper>
      </QuickCorporateRegistrationContainer>
    </>
  );
};

export const QuickRegistrationCorporateFlow = () => {
  const userDetails = useAuthContext((state) => state.userDetails);

  return (
    <ControlledForm validationSchema={createQuicCorporateSchema} defaultValues={userDetails}>
      <QuickRegistrationCorporateFlowView />
    </ControlledForm>
  );
};
