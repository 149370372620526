import { styled } from 'styled-components';

import { RemSize } from '../../../../theme';

export const TextContainer = styled.div<{ $textGap: RemSize; $textWidth: RemSize | 'max-content' }>`
  width: 100%;
  max-width: ${({ $textWidth }) => $textWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: ${({ $textGap }) => $textGap};
`;
