import styled from 'styled-components';

import { breakpoints } from '../../../breakpoints';

export const SideBannerWrapper = styled.div<{ $imgUrl: string; $imageAlignment: string }>`
  display: none;

  @media ${breakpoints.laptopSm} {
    background-image: url(${({ $imgUrl }) => $imgUrl});
    background-size: cover;
    background-position: ${({ $imageAlignment }) => $imageAlignment};
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    padding: 3rem;
    z-index: 2;
  }
`;
