import { UserResponse } from '@metaswiss/api';
import { BondResponseDTO } from '@metaswiss/api/src/api/models/BondResponseDTO';
import { DataStatusPage, ErrorRegionIcon, FlatList, PageStateContainer, ThemedIcon } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { api } from '../../../../../api/msApi';
import { NumberOfItems } from '../../../../../components/number-of-items/NumberOfItems.tsx';
import { OfferingPopup } from '../../../../../components/offering-popup/OfferingPopup.tsx';
import { ProductType } from '../../../../../enums/productType.enum';
import { ApiResource } from '../../../../../enums/resource.enum';
import { UserStatus } from '../../../../../enums/userStatus.enum.ts';
import { useAppState } from '../../../../../global-state/zustand.ts';
import { usePagination } from '../../../../../hooks/use-pagination/usePagination';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation.ts';
import { routes } from '../../../../../router/routes.ts';
import { defaultUser } from '../../../../../shared/helpers/defaultUser.ts';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper';
import { BondCard } from '../bond-component/bond-card/BondCard.tsx';

import { BondCardWrapper } from './bonds.styles';

interface MyCustomError {
  message: string;
  messageCode: string;
  code: number;
}

export interface Response {
  body: MyCustomError;
}

export const AllBonds = () => {
  const { textTranslation } = useTextTranslation();
  const user = useAppState((state) => state.user) || defaultUser;
  const { setUser } = useAppState((state) => state);
  const [regionError, setRegionError] = useState<boolean>(false);

  const { data, lastItemRef, isError, fetchNextPage, isLoading, isFetchingNextPage, totalItems } =
    usePagination<BondResponseDTO>({
      queryKey: getQueryKey(ApiResource.BOND, 'ALL'),
      queryFn: (offset: number, limit: number) => api.bonds.getBonds(offset, limit),
      enabled: true,
      onError: (error) => {
        const response = error as Response;
        if (response?.body?.code === 405) {
          setRegionError(true);
        }
      },
      retry: false,
    });

  const navigate = useNavigate();

  const [isOpenOfferingPopup, setIsOpenOfferingPopup] = useState<boolean>(false);
  useQuery({
    queryKey: getQueryKey(ApiResource.USER_RESPONSE),
    queryFn: () => {
      return api.users.getUserResponse();
    },
    onSuccess: (response: UserResponse) => {
      setUser(response);
    },
  });
  const handleInvestButtonClick = (item: BondResponseDTO) => {
    if (user?.isFullyRegistered && user?.isActive && user?.status === UserStatus.APPROVED) {
      navigate(`${routes.payment.root}`, { state: { productType: ProductType.BOND_PAPERS, item } });
    } else {
      setIsOpenOfferingPopup(true);
    }
  };

  const isNotRegionError = useMemo(() => {
    if (regionError) {
      return false;
    }
    return isError;
  }, [regionError, isError]);

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={isLoading}
      showError={true}
      isError={isNotRegionError}
      showEmptyState={true}
      isEmptyState={data.length === 0}
      emptyStateContent={'offering.noBondsAvailable'}
      emptyStateTitle={'offering.nothingHereYet'}
      textTranslation={textTranslation}
      onTryAgain={fetchNextPage}
      customEmptyStateComponent={
        regionError && (
          <DataStatusPage
            icon={<ThemedIcon icon={ErrorRegionIcon} size={'full'} />}
            title={textTranslation('global.nothingHere')}
            content={textTranslation('global.notPossibleBonds')}
            action={fetchNextPage}
            iconWidth={'12rem'}
            iconHeight={'12rem'}
            buttonVariant={'outlined'}
            buttonColor={'neutral'}
          />
        )
      }
    >
      <OfferingPopup user={user} isOpen={isOpenOfferingPopup} setIsOpen={setIsOpenOfferingPopup} />
      <FlatList
        data={data ?? []}
        headerComponent={<NumberOfItems numberOfAssets={totalItems ?? 0} />}
        isFetching={isFetchingNextPage}
        ref={lastItemRef}
        renderItem={(item) => (
          <BondCardWrapper>
            <BondCard bond={item} handleInvestButtonClick={() => handleInvestButtonClick(item)} />
          </BondCardWrapper>
        )}
        keyExtractor={(item) => item.id}
      />
    </PageStateContainer>
  );
};
