import { styled } from 'styled-components';

export const NftsBuyingHistoryHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NftsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(11.75rem, 1fr));
  gap: 1rem;
`;

export const CounterButtonWrapper = styled.div`
  margin: 0 auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;
