import { css, styled } from 'styled-components';

export const TitledGridContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TitleWrapper = styled.div<{ $isContextProvided: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${({ $isContextProvided, theme }) =>
    $isContextProvided &&
    css`
      padding-bottom: 1rem;
      border-bottom: 1px solid ${theme.colors.neutrals.hue100};
    `}
`;

export const ContextWrapper = styled.div<{ $numberOfColumns: number }>`
  display: grid;
  grid-template-columns: repeat(${({ $numberOfColumns }) => $numberOfColumns}, 1fr);
  gap: 1rem;
`;
