import styled from 'styled-components';

export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
`;

export const IconWrapper = styled.div<{
  $isSelected: boolean;
}>`
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, $isSelected }) =>
    $isSelected ? theme.v2.surface.informationLight : theme.v2.surface.tertiary};
  border-radius: ${({ theme }) => theme.v2.radius.smaller};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
