import { IconProps } from './iconProps';

export const BarChart04Icon: React.FC<IconProps> = ({ strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.40039 20.7008V7.90078M8.80039 20.7008V13.2341M15.2004 20.7008V3.30078M21.6004 20.7008V7.90078"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
