import * as zod from 'zod';

import { FormNames } from '../../../../../../../../shared/forms/formNames';
import { staticTranslationFunction } from '../../../../../../../../shared/forms/staticTranslationFunction.ts';

export const createFullCorporateLogoSchema = (translate: (key: string) => string) =>
  zod.object({
    picture: zod.string().nonempty(translate('error.requiredField')),
  });
export const schema = createFullCorporateLogoSchema(staticTranslationFunction);

export type FullCorporateRegistrationUploadLogoData = zod.infer<typeof schema>;

export const fullCorporateRegistrationSchema: FormNames<FullCorporateRegistrationUploadLogoData> = {
  picture: 'picture',
} as const;
