import { RemSize } from '@metaswiss/ui-kit';
import styled from 'styled-components';

export const ErrorContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageGroup = styled.div<{ $height?: RemSize }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  height: ${({ $height }) => $height ?? '13.25rem'};
`;

export const HeadingWrapper = styled.div`
  padding-bottom: 1.5rem;
`;

export const ErrorContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 44.75rem;
  gap: 3rem;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  width: 20rem;
`;
