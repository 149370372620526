import { ErrorFeedback } from '@metaswiss/ui-kit';
import { useNavigate } from 'react-router-dom';

import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../router/routes';
import { AnimationContainer } from '../../../shared/animations/AnimationContainer';
import { useAuthContext } from '../../../shared/auth-context/authContext';
import { useSetAuthConfig } from '../../../shared/auth-context/useSetAuthConfig';
import { FeedbackWrapper } from '../../register.styles';

export const RegistrationFailed = () => {
  const navigate = useNavigate();
  const { userRole, registrationLength } = useAuthContext((state) => state);
  const { textTranslation } = useTextTranslation();

  useSetAuthConfig(
    {
      wrapperStyle: 'authFinish',
      containerStyle: 'authFinish',
      nextButton: {
        animationDirection: 'backward',
        type: 'button',
        onClick: () => navigate(`${routes.register.root}/${userRole}/${registrationLength}`),
        text: 'registration.tryAgainButton',
      },
    },
    [navigate, userRole, registrationLength]
  );

  return (
    <AnimationContainer centerContent={true}>
      <FeedbackWrapper>
        <ErrorFeedback
          imageWidth="19.75rem"
          fill
          title={textTranslation('registration.wrongRegister')}
          subTitle={textTranslation('registration.tryAgain')}
        />
      </FeedbackWrapper>
    </AnimationContainer>
  );
};
