import * as zod from 'zod';

import { FormNames } from '../../../../../../../../shared/forms/formNames';
import { staticTranslationFunction } from '../../../../../../../../shared/forms/staticTranslationFunction.ts';

export const createFullPrivateProofOfDomicileSchema = (translate: (key: string) => string) =>
  zod.object({
    proofOfDomicile: zod.string().nonempty(translate('error.requiredField')),
  });

export const schema = createFullPrivateProofOfDomicileSchema(staticTranslationFunction);

export type FullPrivateRegistrationProofOfDomicileData = zod.infer<typeof schema>;

export const fullRegistrationPrivateProofOfDomicileSchema: FormNames<FullPrivateRegistrationProofOfDomicileData> = {
  proofOfDomicile: 'proofOfDomicile',
} as const;
