import { styled } from 'styled-components';

export const PaymentBillContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const PaymentBillBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 50.25rem;
  height: calc(100% - 1rem); // 1rem is height of bill bottom svg
  padding: 1rem;
  background-color: ${({ theme }) => theme.v2.surface.primary};
  border-top-left-radius: ${({ theme }) => theme.v2.radius.medium};
  border-top-right-radius: ${({ theme }) => theme.v2.radius.medium};
`;

export const SuccessfullPaymentIconWrapper = styled.div`
  position: absolute;
  display: grid;
  place-content: center;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  background-color: ${({ theme }) => theme.v2.surface.primary};
  box-shadow: 0px 6px 16px 0px #7a7a7a1f;
  z-index: 1;
`;

export const PathContainer = styled.div`
  width: 100%;
`;

export const HeadingContentWrapper = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
`;
