import { styled } from 'styled-components';

export const LoaderWrapper = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

export const CollectiblesViewWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CounterButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 12rem;
  width: 100%;
  margin: 1rem auto 0 auto;
`;
