import { Text } from '@metaswiss/ui-kit';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';

import { HeaderContainer } from './numberOfItems.styles';

type Props = {
  numberOfAssets?: number;
};

export const NumberOfItems: FC<Props> = ({ numberOfAssets = 0 }) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  if (!numberOfAssets) {
    return null;
  }
  return (
    <HeaderContainer>
      <Text color={theme.v2.text.headingPrimary}>{`${textTranslation('global.results')} (${numberOfAssets})`}</Text>
    </HeaderContainer>
  );
};
