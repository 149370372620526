export const swipePageAnimation = (direction: 'forward' | 'backward' | 'login-in' | 'login-out') => {
  if (direction === 'login-in' || direction === 'login-out') {
    return {
      hidden: {
        opacity: 0,
      },
      visible: {
        opacity: 1,
      },
      exit: {
        opacity: 0,
      },
    };
  }
  return {
    hidden: {
      opacity: 0,
      x: direction === 'forward' ? '100%' : '-100%',
    },
    visible: {
      opacity: 1,
      x: 0,
      transitionEnd: {
        opacity: 1,
        x: 0,
      },
    },
    exit: {
      opacity: 0,
      x: direction === 'forward' ? '-100%' : '100%',
    },
  };
};

export const buttonTransition = {
  curve: 'spring',
  mass: 0.85,
  stiffness: 529,
  damping: 39,
};

export const buttonVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export const actionTextVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export const loginPageVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};
