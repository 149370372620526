import { FC } from 'react';

import { IconProps } from './iconProps';

export const Pencil03Icon: FC<IconProps> = ({ strokeColor = '#3381FF' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.79999 15.5999L8.99999 19.1999M12 22.1999H21.6M4.19999 15.5999L16.0314 3.35533C17.3053 2.08143 19.3707 2.08143 20.6446 3.35533C21.9185 4.62923 21.9185 6.69463 20.6446 7.96853L8.39999 19.7999L2.39999 21.5999L4.19999 15.5999Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
