import { BaseCard } from '@metaswiss/ui-kit';
import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import { styled } from 'styled-components';

export const NewsletterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NewsletterContent = styled(BaseCard)`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const NewsletterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;

  @media ${breakpoints.laptopMd} {
    padding-top: 0;
  }
`;
