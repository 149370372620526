import styled from 'styled-components';

import { Padding } from '../../../../theme/theme';
import { ButtonSize } from '../Button';

type ButtonContentProps = {
  $loading: boolean;
  $size: ButtonSize;
  $padding?: Padding;
};

export const ButtonContent = styled.div<ButtonContentProps>`
  visibility: ${({ $loading }) => ($loading ? 'hidden' : 'visible')};
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme, $size, $padding }) => {
    if ($padding) {
      return `padding: ${$padding};`;
    }

    let paddingValue = '';
    switch ($size) {
      case 'small':
        paddingValue = `${theme.v2.spacing['0.5']}`;
        break;
      case 'medium':
        paddingValue = `${theme.v2.spacing['0.75']} ${theme.v2.spacing['1']}`;
        break;
      case 'large':
        paddingValue = `${theme.v2.spacing['1']} ${theme.v2.spacing['1.5']}`;
        break;
    }
    return `padding: ${paddingValue};`;
  }}
`;
