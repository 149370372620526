/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NewslettersApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param language
   * @param offset
   * @param limit
   * @param page
   * @returns any
   * @throws ApiError
   */
  public getNewsletters(
    language: string,
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/newsletters',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'language': language,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
