import styled from 'styled-components';

import { TextTemplate } from '../../../shared/typography/TextTemplate.styles';

type TabProps = {
  $isSelected: boolean;
  $content: string;
  $isIndicatorActive: boolean;
};

export const TabButton = styled.button<TabProps>`
  position: relative;
  padding: 0.5rem 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  text-align: center;
  overflow: hidden;
  background-color: ${({ theme, $isSelected }) => $isSelected && theme.v2.surface.informationLight};
  border-radius: ${({ theme }) => theme.v2.radius.smaller};

  ${TextTemplate} {
    visibility: hidden;
  }

  &::before {
    position: absolute;
    content: '${({ $content }) => $content}';
    color: ${({ theme, $isSelected }) => ($isSelected ? theme.v2.text.action : theme.v2.text.headingPrimary)};
    font-weight: ${({ theme, $isSelected }) => $isSelected && theme.typography.weights.bold};
    padding-right: ${({ $isIndicatorActive }) => $isIndicatorActive && '1rem'};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: grid;
    place-content: center;
  }

  &:hover {
    background-color: ${({ theme, $isSelected }) => !$isSelected && theme.v2.surface.informationLight};
  }

  &:focus-visible,
  &:active {
    background-color: ${({ theme }) => theme.v2.surface.informationLight};

    &::before {
      color: ${({ theme }) => theme.v2.text.action};
    }
  }
`;

export const DotIndicator = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.v2.text.error};
`;
