import { NoResourcesIcon, ThemedIcon } from '@metaswiss/ui-kit';
import { ErrorPageIcon } from '@metaswiss/ui-kit/src/iconography/ErrorPageIcon.tsx';
import { ShellNavigationState, useShellNavigationState } from 'client/src/global-state/zustand';
import { routes } from 'client/src/router/routes.ts';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import { useTheme } from 'styled-components';

import DataStatusPage from '../../molecules/data-status-page/DataStatusPage.tsx';

import {
  ContentWrapper,
  MarginWrapper,
  PageStateContainerWrapper,
  StatusWrapper,
} from './pageStateContainer.styles.ts';
import { PageStateContainerProps } from './pageStateContainer.types.ts';

export const PageStateContainer: FC<PropsWithChildren<PageStateContainerProps>> = ({
  children,
  showLoading = true,
  customLoaderComponent = null,
  isLoading,
  showError = true,
  onTryAgain,
  customErrorComponent = null,
  isError,
  showEmptyState,
  customEmptyStateComponent,
  emptyStateContent,
  emptyStateTitle,
  isEmptyState,
  containerRef,
  onScrollHandler,
  containerWrapper,
  textTranslation,
  maxWidth,
  removeMargins = false,
}) => {
  const theme = useTheme();
  const tabs = useShellNavigationState((state: ShellNavigationState) => state.tabs);
  const { pathname } = useLocation();
  const tabsVisible = useMemo(() => !!tabs, [tabs]);
  const [loading, setLoading] = useState(true);

  // This is fix to get animation every time when page is opened even if loading is false
  useEffect(() => {
    setLoading(!!isLoading);
  }, [isLoading]);

  const isPortfolioPage = pathname.includes(routes.portfolio.root);

  const Wrapper = containerWrapper || PageStateContainerWrapper;

  const duration = 0.2;

  const renderContent = () => {
    if (showLoading && loading) {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration }}
          key={pathname}
        >
          {customLoaderComponent || (
            <StatusWrapper>
              <PuffLoader size={64} color={theme.colors.primary.hue300} loading={true} />
            </StatusWrapper>
          )}
        </motion.div>
      );
    }

    if (showError && isError) {
      return (
        <StatusWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration }}
          key={pathname}
        >
          {customErrorComponent || (
            <DataStatusPage
              icon={<ThemedIcon icon={ErrorPageIcon} size={'full'} />}
              title={textTranslation('global.somethingWentWrong')}
              content={textTranslation('global.anErrorOccurred')}
              buttonText={textTranslation('global.tryAgain')}
              action={onTryAgain}
              iconWidth={'16.875rem'}
              iconHeight={'11.125rem'}
              buttonVariant={'outlined'}
              buttonColor={'neutral'}
            />
          )}
        </StatusWrapper>
      );
    }
    if (showEmptyState && isEmptyState) {
      return (
        <StatusWrapper
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration, ease: 'easeOut' }}
          key={pathname}
          $isPortfolio={isPortfolioPage}
        >
          {customEmptyStateComponent || (
            <DataStatusPage
              icon={
                <ThemedIcon
                  icon={() => (
                    <NoResourcesIcon
                      foregroundColor={theme.colors.primary.hue300}
                      backgroundColor={theme.colors.primary.hue100}
                    />
                  )}
                  size={'full'}
                />
              }
              title={emptyStateTitle ? textTranslation(emptyStateTitle) : ''}
              content={emptyStateContent ? textTranslation(emptyStateContent) : ''}
              iconWidth={'11.185rem'}
              iconHeight={'11.875rem'}
            />
          )}
        </StatusWrapper>
      );
    }

    if (!loading && !isError && !isEmptyState) {
      return (
        <Wrapper
          key={pathname}
          ref={containerRef}
          onScroll={onScrollHandler}
          $tabsVisible={tabsVisible}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration, ease: 'easeOut' }}
        >
          <MarginWrapper $removeMargins={removeMargins}>
            <ContentWrapper $maxWidth={maxWidth}>{children}</ContentWrapper>
          </MarginWrapper>
        </Wrapper>
      );
    }
  };

  return (
    <AnimatePresence mode="wait" initial={false}>
      {renderContent()}
    </AnimatePresence>
  );
};
