import styled from 'styled-components';

export const LogoContainer = styled.div`
  justify-content: center;
  width: 4.5rem;
  height: 4.5rem;
  background-color: ${({ theme }) => theme.v2.surface.primary};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  border-radius: ${({ theme }) => theme.v2.radius.small};
  overflow: hidden;
  position: relative;
`;
export const Logo = styled.img`
  object-fit: cover;
  height: 100%;
`;
export const TextWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.25rem;
`;

export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const TextContent = styled.div`
  display: flex;
  gap: 0.25rem;
`;
