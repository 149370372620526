import { PuffLoader } from 'react-spinners';
import { useTheme } from 'styled-components';

import { StyledLoaderContainer } from './loaderContainer.styles';

export const LoaderContainer = () => {
  const theme = useTheme();

  return (
    <StyledLoaderContainer>
      <PuffLoader size={64} color={theme.colors.primary.hue300} />
    </StyledLoaderContainer>
  );
};
