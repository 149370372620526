import * as zod from 'zod';

import { FormNames } from '../../../../../shared/forms/formNames.ts';
import { staticTranslationFunction } from '../../../../../shared/forms/staticTranslationFunction.ts';

export const createChangePasswordSchema = (translate: (key: string) => string) =>
  zod.object({
    currentPassword: zod.string().nonempty(translate('error.requiredField')).min(5),
    newPassword: zod.string().nonempty(translate('error.requiredField')).min(5),
    passwordConfirmation: zod.string().nonempty(translate('error.requiredField')).min(5),
  });

export const schema = createChangePasswordSchema(staticTranslationFunction);

export type ChangePasswordFormData = zod.infer<typeof schema>;

export const changePasswordSchemaNames: FormNames<ChangePasswordFormData> = {
  currentPassword: 'currentPassword',
  newPassword: 'newPassword',
  passwordConfirmation: 'passwordConfirmation',
} as const;
