import { FC } from 'react';

import { IconProps } from './iconProps';

export const CheckBroken: FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 22 22" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.6004 11.0004C20.6004 16.3023 16.3023 20.6004 11.0004 20.6004C5.69846 20.6004 1.40039 16.3023 1.40039 11.0004C1.40039 5.69846 5.69846 1.40039 11.0004 1.40039C12.5066 1.40039 13.9318 1.74726 15.2004 2.36547M18.8004 5.00039L10.4004 13.4004L8.00039 11.0004"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
