import styled from 'styled-components';

import { DropdownHeight, DropdownWidth } from '../../../../theme/theme';

type Props = {
  $width: DropdownWidth;
  $height: DropdownHeight;
  $fill: boolean;
  $isLocked: boolean;
};

export const DropdownContainer = styled.div<Props>`
  position: relative;
  border-radius: ${({ theme }) => theme.v2.componentsRadius.inputs.large};
  display: inline-block;
  pointer-events: ${({ $isLocked }) => $isLocked && 'none'};
  width: ${({ theme, $width, $fill }) => ($fill ? '100%' : theme.dropdown.width[$width])};
  height: ${({ theme, $height }) => theme.dropdown.height[$height]};
`;
