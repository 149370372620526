import { IconProps } from './iconProps';

export const FolderOpenIcon: React.FC<IconProps> = ({ color = 'none', strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 22 22" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.39982 4.32947V2.52947C4.39982 1.86673 4.93708 1.32947 5.59982 1.32947H16.9998C17.6626 1.32947 18.1998 1.86673 18.1998 2.52947V7.32947M1.40076 18.2705L1.40085 9.14615C1.40085 8.23223 1.40051 6.9305 1.40022 5.98795C1.40002 5.32503 1.93735 4.78826 2.60027 4.78826H8.31848L11.0835 7.74189H19.3998C20.0625 7.74189 20.5998 8.27917 20.5998 8.94193L20.5995 18.2706C20.5995 19.5961 19.525 20.6705 18.1995 20.6705L3.80075 20.6705C2.47526 20.6705 1.40074 19.596 1.40076 18.2705Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
