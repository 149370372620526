import { ColorPalette, Colors, ResourceInfoVariant, Skeleton, Text, ThemedIcon } from '@metaswiss/ui-kit';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { ResourceInfoSize } from '../../..';
import { PropertyProps } from '../../../../components/molecules/resource-details/types';

import {
  CardWrapper,
  IconWrapper,
  SubtitleLoadingWrapper,
  TextContent,
  TextWrapper,
  TitleLoadingWrapper,
} from './resourceInfo.styles';
import { ResourceInfoProps } from './types';

type ResourceInfoColorProps = { palette: ColorPalette; iconColor: Colors<ColorPalette> };

export const ResourceInfoV2: FC<ResourceInfoProps> = ({
  icon,
  title,
  titleColor,
  subTitle,
  subTitleColor,
  variant,
  size = ResourceInfoSize.LARGE,
  isLightContent = false,
  renderSubTitleComponent,
  gap,
  isLoading,
}) => {
  const theme = useTheme();
  const { titleProps, subTitleProps } = getSize(size);

  const { palette, iconColor }: ResourceInfoColorProps =
    variant === ResourceInfoVariant.DISABLED
      ? { palette: 'neutrals', iconColor: 'hue200' }
      : { palette: variant, iconColor: 'hue400' };

  return (
    <CardWrapper>
      {isLoading ? (
        <Skeleton width={'3.5rem'} height={'3.5rem'} />
      ) : (
        <IconWrapper $palette={palette} $lightBackground={isLightContent}>
          <ThemedIcon icon={icon} size={'large'} palette={palette} strokeColor={isLightContent ? 'hue0' : iconColor} />
        </IconWrapper>
      )}
      <TextWrapper $gap={gap || (size === ResourceInfoSize.SMALL ? '0.25rem' : '0rem')}>
        <TextContent>
          {isLoading ? (
            <TitleLoadingWrapper>
              <Skeleton width={'9.75rem'} height={'1.5rem'} />
            </TitleLoadingWrapper>
          ) : (
            <Text
              color={titleColor || (isLightContent ? theme.v2.text.onAction : theme.v2.text.headingPrimary)}
              {...titleProps}
            >
              {title}
            </Text>
          )}
        </TextContent>
        {isLoading ? (
          <SubtitleLoadingWrapper>
            <Skeleton width={'7.5rem'} height={'1rem'} />
          </SubtitleLoadingWrapper>
        ) : (
          renderSubTitleComponent || (
            <Text
              color={subTitleColor || (isLightContent ? theme.v2.text.onAction : theme.v2.text.disabled)}
              {...subTitleProps}
            >
              {subTitle}
            </Text>
          )
        )}
      </TextWrapper>
    </CardWrapper>
  );
};

export const getSize = (
  size: ResourceInfoSize
): {
  titleProps: Partial<PropertyProps>;
  subTitleProps?: Partial<PropertyProps>;
} => {
  switch (size) {
    case ResourceInfoSize.SMALL:
      return {
        titleProps: {
          fontSize: 'base',
          lineHeight: 'base',
          fontWeight: 'bold',
        },
      };
    case ResourceInfoSize.MEDIUM:
      return {
        titleProps: {
          fontSize: 'base',
          lineHeight: 'base',
          fontWeight: 'reg',
        },
        subTitleProps: {
          fontSize: 'lg',
          lineHeight: 'h4',
          fontWeight: 'bold',
        },
      };
    case ResourceInfoSize.MEDIUM_LARGE:
      return {
        titleProps: {
          fontSize: 'lg',
          lineHeight: 'h4',
          fontWeight: 'bold',
        },
      };
    case ResourceInfoSize.LARGE:
      return {
        titleProps: {
          fontSize: 'h4',
          lineHeight: 'h4',
          fontWeight: 'bold',
        },
      };

    default:
      throw new Error(`Unsupported size: ${size}`);
  }
};
