/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelStripePaymentRequest } from '../models/CancelStripePaymentRequest';
import type { CryptoPaymentResponse } from '../models/CryptoPaymentResponse';
import type { PaymentRequest } from '../models/PaymentRequest';
import type { PaymentResponse } from '../models/PaymentResponse';
import type { StripePaymentRequest } from '../models/StripePaymentRequest';
import type { StripePaymentResponse } from '../models/StripePaymentResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PaymentApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns PaymentResponse Created
   * @throws ApiError
   */
  public bankCharge(
    requestBody: PaymentRequest,
  ): CancelablePromise<PaymentResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/payment/bank-charge',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns PaymentResponse Created
   * @throws ApiError
   */
  public stripeCharge(): CancelablePromise<PaymentResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/payment/stripe',
    });
  }

  /**
   * @param requestBody
   * @returns CryptoPaymentResponse Created
   * @throws ApiError
   */
  public cryptoCharge(
    requestBody: PaymentRequest,
  ): CancelablePromise<CryptoPaymentResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/payment/crypto',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns StripePaymentResponse Get Stripe payment intent
   * @throws ApiError
   */
  public createStripePaymentBase(
    requestBody: StripePaymentRequest,
  ): CancelablePromise<StripePaymentResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/payment/stripe/base',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public cancelStripePayment(
    requestBody: CancelStripePaymentRequest,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/payment/stripe/cancel',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
