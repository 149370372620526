/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateNftRequest } from '../models/CreateNftRequest';
import type { NftExtendedV5Response } from '../models/NftExtendedV5Response';
import type { PageableItems } from '../models/PageableItems';
import type { TotalStatisticsResponse } from '../models/TotalStatisticsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NftApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns any Create NFT
   * @throws ApiError
   */
  public createNft(
    requestBody: CreateNftRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/nft',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param offset
   * @param limit
   * @param page
   * @returns any Get NFTs by User Id
   * @throws ApiError
   */
  public getNftsByUserId(
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/nft',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns TotalStatisticsResponse Get total number of NFTs by User Id
   * @throws ApiError
   */
  public getTotalNumberOfNftsByUserId(): CancelablePromise<TotalStatisticsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/nft/portfolio',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @param offset
   * @param limit
   * @param page
   * @param status
   * @param serialNumber
   * @param serialNumberFrom
   * @param serialNumberTo
   * @returns PageableItems Get NFTs by NFT Collection Id
   * @throws ApiError
   */
  public getNftByCollectionId(
    id: string,
    offset?: number,
    limit?: number,
    page?: number,
    status?: Array<'available' | 'sold' | 'reserved' | 'owned'>,
    serialNumber?: string,
    serialNumberFrom?: string,
    serialNumberTo?: string,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/nft/{id}',
      path: {
        'id': id,
      },
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'status': status,
        'serialNumber': serialNumber,
        'serialNumberFrom': serialNumberFrom,
        'serialNumberTo': serialNumberTo,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns NftExtendedV5Response Get NFT by Id
   * @throws ApiError
   */
  public getNftById(
    id: string,
  ): CancelablePromise<NftExtendedV5Response> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/nft/{id}/details',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
