import { FC } from 'react';
import { useTheme } from 'styled-components';

import { AlertIcon } from '../../../iconography';
import { ThemedIcon } from '../theme-icon';

import { Dialog } from './Dialog';
import { DialogWrapperProps } from './types';

export const ErrorDialog: FC<DialogWrapperProps> = ({
  primaryText,
  secondaryText,
  isOpen,
  close,
  primaryButtonText,
  onPrimaryButtonClick,
  secondaryButtonText,
  onSecondaryButtonClick,
}) => {
  const theme = useTheme();
  return (
    <Dialog
      isOpen={isOpen}
      close={close}
      primaryText={primaryText}
      secondaryText={secondaryText}
      buttonColor="error"
      primaryButtonText={primaryButtonText}
      onPrimaryButtonClick={onPrimaryButtonClick}
      secondaryButtonText={secondaryButtonText}
      onSecondaryButtonClick={onSecondaryButtonClick}
      iconBackgroundColor={'error'}
      renderIcon={({ iconSize }) => (
        <ThemedIcon
          icon={AlertIcon}
          size={iconSize}
          customStrokeColor={theme.v2.icon.onAction}
          hasBorderRadius={true}
        />
      )}
    />
  );
};
