import { FC } from 'react';
import { useTheme } from 'styled-components';

import { Text } from '../../../../components';
import { Tooltip } from '../../../atoms/tooltip';

import {
  CardWrapper,
  ContentWrapper,
  DescriptionWrapper,
  HeaderWrapper,
  PriceWrapper,
} from './nftDescriptionCard.styles';

export type Props = {
  collectionName?: string;
  serialNumber?: string;
  description?: string;
  price?: string;
  tooltip: string;
};

export const NftDescriptionCard: FC<Props> = ({
  collectionName = '',
  serialNumber = '',
  description = '',
  price = '',
  tooltip,
}) => {
  const theme = useTheme();
  return (
    <CardWrapper
      width={'100%'}
      padding={'1rem 0rem 1rem 1rem'}
      height={'fit-content'}
      disableStateEffects
      override={{
        borderRadius: theme.v2.componentsRadius.cards.default,
        backgroundColor: theme.v2.surface.primary,
        borderColor: theme.v2.border.primary,
      }}
    >
      <PriceWrapper>
        <Text lineHeight={'h4'} fontSize={'h4'} fontWeight={'bold'} color={theme.v2.text.action}>
          {price}
        </Text>
        <Tooltip description={tooltip} />
      </PriceWrapper>
      <ContentWrapper>
        <HeaderWrapper>
          <Text lineHeight={'h4'} fontSize={'lg'} fontWeight={'bold'} color={theme.v2.text.action}>
            {collectionName}
          </Text>
          <Text
            lineHeight={'h4'}
            fontSize={'lg'}
            fontWeight={'semi'}
            color={theme.v2.text.headingPrimary}
          >{`#${serialNumber}`}</Text>
        </HeaderWrapper>
        <DescriptionWrapper>
          <Text color={theme.v2.text.disabled}>{description}</Text>
        </DescriptionWrapper>
      </ContentWrapper>
    </CardWrapper>
  );
};
