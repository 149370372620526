import { styled } from 'styled-components';

type Props = {
  $size: string;
  $checked: boolean;
  $disabled: boolean;
  $isError: boolean | undefined;
  $zIndex: boolean;
};

export const RadioLabel = styled.label<Props>`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  height: ${({ $size }) => $size};
  width: ${({ $size }) => $size};
  background: ${({ theme }) => theme.v2.surface.field};
  border: 1px solid ${({ theme }) => theme.v2.border.primaryHover};
  transition: all 0.2s linear;
  border-radius: 50%;
  z-index: ${({ $zIndex }) => $zIndex && -1};

  ${({ theme, $checked }) =>
    $checked &&
    `
  background: ${theme.v2.surface.action1};
  border: 1px solid ${theme.v2.surface.action1}
  `};
  ${({ theme, $isError }) =>
    $isError &&
    `
  background: ${theme.v2.surface.field};
  border: 1px solid ${theme.v2.border.error}
  `};
  ${({ theme, $disabled }) =>
    $disabled &&
    `
   background: ${theme.v2.surface.tertiary};
  border: 1px solid ${theme.v2.border.primaryHover}
  `};

  &::after {
    content: '';
    position: absolute;
    left: ${({ $size }) => `calc(${$size} / 2)`};
    top: 50%;
    transform: translate(-50%, -50%);

    border-radius: 50%;
    opacity: ${({ $checked }) => ($checked ? '1' : '0')};

    width: ${({ $size }) => `calc(${$size} * 0.4)`};
    height: ${({ $size }) => `calc(${$size} * 0.4)`};
    background-color: ${({ $disabled, theme }) => ($disabled ? theme.v2.icon.neutral : theme.v2.surface.primary)};
    box-sizing: content-box;
    z-index: ${({ $checked }) => ($checked ? '1' : '0')};
  }
`;
