import styled from 'styled-components';

export const StatusContainer = styled.div<{
  $backgroundColor: string;
}>`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  border-radius: ${({ theme }) => theme.v2.radius.small};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;
