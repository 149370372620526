export const regex = {
  numbersOnly: new RegExp('^[0-9]*$'),
  numbersAndDot: new RegExp(/^\d*(\.\d*)?$/),
  numbersWithDots: new RegExp('^[0-9]*\\.?[0-9]*$'),
  startsWithPlusAndOnlyNumbers: new RegExp(/^\+\d+$/),
  startsWithPlus: new RegExp(/^\+$/),
  containsDashBackslashOrSpace: new RegExp(/-|\/|\s+/),
  numbersAndDotAndComma: new RegExp(/[^0-9.,]/g),
  commaOnly: new RegExp(/,/g),
  notNumbers: new RegExp(/[^\d]/g),
  camelCase: new RegExp(/([a-z0-9])([A-Z])/g),
  dateFormat: new RegExp('^(0[1-9]|[1-2][0-9]|3[0-1])\\/(0[1-9]|1[0-2])\\/\\d{4}$'),
  phoneNumberPrefixPattern: new RegExp('^\\+\\d{1,4}$'),
  spaceOnly: new RegExp(/ /g),
  forwardSlashOnly: /\//g,
};
