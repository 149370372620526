import { IconProps } from './iconProps';

export const EyeOpenIcon: React.FC<IconProps> = ({ color = 'none', strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 22 17" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.275 8.75021C13.275 10.04 12.2005 11.0856 10.875 11.0856C9.54954 11.0856 8.47502 10.04 8.47502 8.75021C8.47502 7.46043 9.54954 6.41486 10.875 6.41486C12.2005 6.41486 13.275 7.46043 13.275 8.75021Z"
        stroke={strokeColor}
        strokeWidth="2"
      />
      <path
        d="M10.875 15.6058C18.1981 15.7256 20.475 8.8692 20.475 8.8692C20.475 8.8692 18.2904 1.89307 10.875 1.89307C3.45964 1.89307 1.27502 8.8692 1.27502 8.8692C1.27502 8.8692 3.55195 15.486 10.875 15.6058Z"
        stroke={strokeColor}
        strokeWidth="2"
      />
    </svg>
  );
};
