import { styled } from 'styled-components';

export const MultipleProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 0.75rem;
  border-radius: 6.25rem;
  overflow: hidden;
`;

export const SingleBarContainer = styled.div<{ $color?: string; $flex: number }>`
  background-color: ${({ theme, $color }) => $color ?? theme.v2.surface.action1};
  flex: ${({ $flex }) => $flex};
  height: 100%;
`;
