import { FC } from 'react';

import { IconProps } from './iconProps';

export const HelpIcon: FC<IconProps> = ({ strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5456 13.5454C12.1397 14.9513 9.8603 14.9513 8.45442 13.5454M13.5456 13.5454C14.9515 12.1395 14.9515 9.86009 13.5456 8.4542M13.5456 13.5454L17.364 17.3637M8.45442 13.5454C7.04853 12.1395 7.04853 9.86009 8.45442 8.4542M8.45442 13.5454L4.63604 17.3637M8.45442 8.4542C9.8603 7.04832 12.1397 7.04832 13.5456 8.4542M8.45442 8.4542L4.63604 4.63583M13.5456 8.4542L17.364 4.63583M17.7882 17.788C14.0392 21.537 7.96081 21.537 4.21178 17.788C0.462742 14.039 0.462742 7.9606 4.21178 4.21156C7.96081 0.462531 14.0392 0.462531 17.7882 4.21156C21.5373 7.9606 21.5373 14.039 17.7882 17.788Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
