import styled from 'styled-components';

export const MaintenanceContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  max-width: 42.5rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LogoContainer = styled.img`
  margin: 4.25rem auto;
  width: 16.25rem;
`;
