import {
  ExpandablePaymentCard,
  FormInput,
  FormSelectionPopup,
  Globe02Icon,
  Pencil03Icon,
  SendIcon,
  ThemedIcon,
  usePaymentWizard,
} from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { FieldError, Path, useFormContext } from 'react-hook-form';

import { api } from '../../../../api/msApi';
import ControlledForm from '../../../../components/controlled-form/ControlledForm';
import { PaymentMethodCard } from '../../../../components/payment-method-card/PaymentMethodCard';
import { AddressType } from '../../../../enums/addressType.enum';
import { ApiResource } from '../../../../enums/resource.enum';
import { useCountries } from '../../../../hooks/use-countries/useCountries';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';
import { AddressTypeOption, usePaymentContext } from '../context/PaymentContext';
import { DeliveryAddressForm, StepWrapper } from '../payment.styles';
import { createSecondStepSchema, SecondStepFormData, secondStepSchema } from '../schemas/secondStepSchema';

export const DeliveryAddressView = () => {
  const { addressType, setAddressType, setNewDeliveryAddress } = usePaymentContext();
  const { countriesAsDropdown } = useCountries();
  const { textTranslation } = useTextTranslation();
  const { setIsButtonDisabled } = usePaymentWizard();

  const isNewDeliveryAddress = addressType === AddressType.NEW_DELIVERY_ADDRESS;

  const { data: deliveryAddress } = useQuery({
    queryKey: getQueryKey(ApiResource.DELIVERY_ADDRESS),
    queryFn: () => api.userDeliveryAddresses.getUserDeliveryAddress(),
  });

  const {
    control,
    watch,
    getValues,
    formState: { errors, isValid },
  } = useFormContext<SecondStepFormData>();
  const DeliveryAddressOptions: AddressTypeOption[] = [
    { id: AddressType.DELIVERY_ADDRESS, value: '' },
    { id: AddressType.NEW_DELIVERY_ADDRESS, value: '' },
  ];

  useEffect(() => {
    const subscription = watch(({ country, city, address, zipCode }) => {
      setNewDeliveryAddress({
        country: {
          id: country?.value || '',
          name: country?.label || '',
        },
        city: city || '',
        address: address || '',
        zipCode: zipCode || '',
      });
    });
    return () => subscription.unsubscribe();
  }, [setNewDeliveryAddress, watch]);

  useEffect(() => {
    if (!isNewDeliveryAddress && deliveryAddress) {
      setNewDeliveryAddress({
        country: {
          id: deliveryAddress.country.id,
          name: deliveryAddress.country.name,
        },
        city: deliveryAddress.city,
        address: deliveryAddress.address,
        zipCode: deliveryAddress.zipCode,
      });
    } else {
      setNewDeliveryAddress({
        country: {
          id: getValues('country')?.value || '',
          name: getValues('country')?.label || '',
        },
        city: getValues('city') || '',
        address: getValues('address') || '',
        zipCode: getValues('zipCode') || '',
      });
    }
  }, [deliveryAddress, getValues, isNewDeliveryAddress, setNewDeliveryAddress]);

  useEffect(() => {
    if (isNewDeliveryAddress) {
      setIsButtonDisabled(!isValid);
    } else if (!deliveryAddress) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [deliveryAddress, isNewDeliveryAddress, isValid, setIsButtonDisabled]);

  const deliveryAddressDescription = useMemo(() => {
    if (deliveryAddress) {
      return `${deliveryAddress.address}, ${deliveryAddress.city}, ${deliveryAddress.country.name}, ${deliveryAddress.zipCode}`;
    }
    return textTranslation('payment.noDeliveryAddress');
  }, [deliveryAddress, textTranslation]);

  return (
    <StepWrapper>
      {deliveryAddress && (
        <PaymentMethodCard
          icon={SendIcon}
          paymentTypeText={textTranslation('payment.yourDeliveryAddress')}
          description={deliveryAddressDescription}
          isSelected={!isNewDeliveryAddress}
          onClick={() => {
            setAddressType(DeliveryAddressOptions[0].id);
          }}
          selectedRadioButton={{ id: addressType, value: '' }}
          labelRadioButton={DeliveryAddressOptions[0]}
        />
      )}

      <ExpandablePaymentCard
        onSelect={() => setAddressType(DeliveryAddressOptions[1].id)}
        isSelected={isNewDeliveryAddress}
        description={textTranslation('payment.addNewDeliveryAddress')}
        paymentTypeText={textTranslation('payment.newDeliveryAddress')}
        height={'5rem'}
        icon={Pencil03Icon}
        size={'full'}
        children={
          <DeliveryAddressForm>
            <FormSelectionPopup<SecondStepFormData>
              name={secondStepSchema.country as Path<SecondStepFormData>}
              error={errors[secondStepSchema.country] as FieldError}
              control={control}
              label={textTranslation('payment.country')}
              title={textTranslation('payment.country')}
              searchText={textTranslation('payment.search')}
              renderEndIcon={() => <ThemedIcon icon={Globe02Icon} palette="primary" strokeColor="hue300" />}
              itemsList={countriesAsDropdown}
              closeButtonText={textTranslation('payment.cancel')}
              selectButtonText={textTranslation('payment.select')}
              fill
            />
            <FormInput<SecondStepFormData>
              name={secondStepSchema.city as Path<SecondStepFormData>}
              error={errors[secondStepSchema.city]}
              control={control}
              fill
              label={textTranslation('payment.city')}
            />
            <FormInput<SecondStepFormData>
              name={secondStepSchema.address as Path<SecondStepFormData>}
              error={errors[secondStepSchema.address]}
              control={control}
              fill
              label={textTranslation('payment.address')}
            />
            <FormInput<SecondStepFormData>
              name={secondStepSchema.zipCode as Path<SecondStepFormData>}
              error={errors[secondStepSchema.zipCode]}
              control={control}
              fill
              label={textTranslation('payment.zipCode')}
            />
          </DeliveryAddressForm>
        }
      />
    </StepWrapper>
  );
};

export const DeliveryAddress = () => {
  const { newDeliveryAddress } = usePaymentContext();

  const { data: deliveryAddress } = useQuery({
    queryKey: getQueryKey(ApiResource.DELIVERY_ADDRESS),
    queryFn: () => api.userDeliveryAddresses.getUserDeliveryAddress(),
  });

  const defaultValues = deliveryAddress
    ? {
        country: {
          value: '',
          label: '',
        },
        city: '',
        address: '',
        zipCode: '',
      }
    : {
        ...newDeliveryAddress,
        country: { value: newDeliveryAddress.country.id, label: newDeliveryAddress.country.name },
      };

  return (
    <ControlledForm validationSchema={createSecondStepSchema} defaultValues={defaultValues}>
      <DeliveryAddressView />
    </ControlledForm>
  );
};
