import { PortfolioBondAssetsDTO } from '@metaswiss/api';
import { formatAmountWithoutDecimals } from '@metaswiss/lib';
import {
  ArrowChartIcon,
  DataStatusPage,
  FlatList,
  PageStateContainer,
  PortfolioTransactionCard,
  ThemedIcon,
  TrendUpWithBackgroundIcon,
} from '@metaswiss/ui-kit';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi';
import { NumberOfItems } from '../../../../../components/number-of-items/NumberOfItems';
import { ProductType } from '../../../../../enums/productType.enum';
import { ApiResource } from '../../../../../enums/resource.enum';
import { useHeaderOptions, useShellNavigationState } from '../../../../../global-state/zustand';
import { usePagination } from '../../../../../hooks/use-pagination/usePagination';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../router/routes';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper';

export const SmartBondHistory = () => {
  const { textTranslation } = useTextTranslation();
  const { removeTabs } = useShellNavigationState();
  const { setHeaderTitle } = useHeaderOptions();
  const theme = useTheme();
  const navigate = useNavigate();

  const { data, isError, lastItemRef, fetchNextPage, isLoading, isFetchingNextPage, totalItems } =
    usePagination<PortfolioBondAssetsDTO>({
      queryKey: getQueryKey(ApiResource.PORTFOLIO, ProductType.BOND_PAPERS),
      queryFn: (offset: number, limit: number) => api.clientPortfolio.getSmartBondInvestments(offset, limit),
      enabled: true,
    });

  useEffect(() => {
    removeTabs();
    setHeaderTitle(textTranslation('portfolio.bondHistory'));
  }, [removeTabs, setHeaderTitle, textTranslation]);

  return (
    <PageStateContainer
      isLoading={isLoading}
      isError={isError && data?.length !== 0}
      onTryAgain={async () => await Promise.all([fetchNextPage()])}
      showError={true}
      showLoading={true}
      textTranslation={textTranslation}
      isEmptyState={!isLoading && data?.length === 0}
      showEmptyState={true}
      customEmptyStateComponent={
        <DataStatusPage
          icon={
            <ThemedIcon
              icon={TrendUpWithBackgroundIcon}
              customStrokeColor={theme.v2.text.action2}
              customColor={theme.v2.surface.action2Light}
              size={'full'}
            />
          }
          title={textTranslation('portfolio.noBonds')}
          content={textTranslation('portfolio.noBondsPurchased')}
          buttonText={textTranslation('portfolio.startInvesting')}
          action={() => navigate(routes.offering.smartBonds.root)}
          additionalContent={textTranslation('portfolio.noBondsFollow')}
          iconWidth={'8.5rem'}
          iconHeight={'8.5rem'}
        />
      }
    >
      <FlatList
        data={data || []}
        isFetching={isFetchingNextPage}
        ref={lastItemRef}
        renderItem={(item) => (
          <PortfolioTransactionCard
            headerLabel={textTranslation('portfolio.annualInterest')}
            headerValue={`${item.annualInterest}%`}
            quantityLabel={textTranslation('portfolio.quantity')}
            quantity={formatAmountWithoutDecimals(item.bondsBought)}
            amountLabel={textTranslation('portfolio.amount')}
            amount={item.investedAmount}
            issuerInfo={{
              name: item.issuerName,
              logoUrl: item.issuerLogoUrl,
            }}
            icon={ArrowChartIcon}
            onClick={() => navigate(`${routes.portfolio.smartBonds.root}/${item.id}`)}
          />
        )}
        keyExtractor={(item) => item.id}
        headerComponent={<NumberOfItems numberOfAssets={totalItems || 0} />}
      />
    </PageStateContainer>
  );
};
