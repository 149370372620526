import { StyledIconButton } from './styles/styledIconButton';

// TODO: add size prop
export type Props = {
  icon: JSX.Element;
  // if onClick not provided, treat button as disabled
  onClick?: () => void;
  padding: string;
  radius: string;
  borderWidth: string;
  selectedButton?: boolean;
};

export const BaseIconButton: React.FC<Props> = ({
  icon,
  onClick,
  padding = '0.5rem',
  radius,
  borderWidth,
  selectedButton = false,
}) => {
  return (
    <StyledIconButton
      $padding={padding}
      $selectedButton={selectedButton}
      $radius={radius}
      $borderWidth={borderWidth}
      disabled={typeof onClick !== 'function'}
      onClick={() => onClick?.()}
    >
      {icon}
    </StyledIconButton>
  );
};
