import { Text } from '@metaswiss/ui-kit';
import { useMemo } from 'react';

import { ItemsCounterWrapper, CounterBar, DisplayItemsBar } from './itemsCounter.styles';

type ItemsCounterProps = {
  displayedItems: number;
  totalItems: number;
  ofText: string;
  itemsText: string;
  displayedItemsText?: string;
  totalItemsText?: string;
};

export const ItemsCounter = ({
  displayedItems,
  totalItems = 0,
  ofText,
  itemsText,
  totalItemsText,
  displayedItemsText,
}: ItemsCounterProps) => {
  const counterBarSubtext = `${displayedItemsText || displayedItems} ${ofText} ${
    totalItemsText || totalItems
  } ${itemsText}`;

  const itemsBarWidth = useMemo(() => (displayedItems / totalItems) * 100 + '%', [displayedItems, totalItems]);

  return (
    <ItemsCounterWrapper>
      <Text fontSize={'sm'} lineHeight={'medium'}>
        {counterBarSubtext}
      </Text>
      <CounterBar>
        <DisplayItemsBar $width={itemsBarWidth} />
      </CounterBar>
    </ItemsCounterWrapper>
  );
};
