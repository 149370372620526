import { motion } from 'framer-motion';
import { styled } from 'styled-components';

export const DetailsListContainer = styled(motion.div)<{ $isNftDetails: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: ${({ $isNftDetails }) => ($isNftDetails ? '1rem' : '0')};
  padding-top: ${({ $isNftDetails }) => ($isNftDetails ? '1rem' : '0')};
  position: relative;
  z-index: 1;
  border-top: ${({ $isNftDetails, theme }) => ($isNftDetails ? `1px solid ${theme.v2.border.primary}` : 'none')};
  @media only screen and (min-height: 1440px) {
    padding-top: ${({ $isNftDetails }) => ($isNftDetails ? '1rem' : '0')};
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const ItemContainer = styled(motion.div)`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  padding: 0.75rem;
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.default};
`;

export const IconContainer = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
`;

export const ItemTextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
