import { FC } from 'react';

import { IconProps } from './iconProps';

export const WaveIcon: FC<IconProps> = ({ color, strokeColor }) => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.59979 36.3999V19.5999M27.9998 50.3998V5.59985M16.7998 41.9998V13.9999M50.3998 19.5999V36.3999M39.1998 13.9999L39.1998 41.9998"
        stroke={strokeColor}
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
