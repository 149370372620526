import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form';
import styled from 'styled-components';

import { Checkbox } from '../../atoms/checkbox';
import { CheckboxProps } from '../../atoms/checkbox/Checkbox';

export type FormCheckboxProps<T extends FieldValues> = {
  name: Path<T>;
  error?: FieldError | undefined;
  control: Control<T>;
} & Omit<CheckboxProps, 'id' | 'checked'>;

export const StyledContainer = styled.div`
  position: relative;
  display: block;
`;
export const FormCheckbox = <T extends FieldValues>({
  label,
  name,
  error,
  control,
  ...checkboxProps
}: FormCheckboxProps<T>) => {
  return (
    <StyledContainer>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Checkbox
            onChange={onChange}
            checked={value || false}
            label={label}
            isError={value ? !!error : false}
            id={name}
            {...checkboxProps}
          />
        )}
      />
    </StyledContainer>
  );
};
