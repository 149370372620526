import React from 'react';

import { IconProps } from './iconProps';

export const BarChartNoBorderIcon: React.FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width={'100%'} height={'100%'} viewBox="0 0 53 52" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.53369 44.85V17.1167M19.4004 44.85V28.6722M33.267 44.85V7.15001M47.1337 44.85V17.1167"
        stroke={strokeColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
