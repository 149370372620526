import { BarChartIcon, FolderOpenIcon, HomeIcon, MultipleFileIcon } from '@metaswiss/ui-kit';

import { routes } from '../../../../../router/routes';
import { SideBarItemProps } from '../../../../../shared/types/sidebarItemProps';

export const sidebarItems: SideBarItemProps[] = [
  {
    label: 'Home',
    icon: HomeIcon,
    value: 'home',
    route: routes.home.root,
  },
  {
    label: 'Offering',
    icon: BarChartIcon,
    value: 'offering',
    route: routes.offering.smartNfts.root,
  },
  {
    label: 'Cases',
    icon: FolderOpenIcon,
    value: 'cases',
    route: routes.cases.active.root,
  },
  {
    label: 'Portfolio',
    icon: MultipleFileIcon,
    value: 'portfolio',
    route: routes.portfolio.overview,
  },
];
