/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NftUploadResponse } from '../models/NftUploadResponse';
import type { UploadAssetRequest } from '../models/UploadAssetRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NftAssetsForAdminApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param formData
   * @returns NftUploadResponse Add unassigned NFT asset
   * @throws ApiError
   */
  public uploadUnassignedNftFile(
    formData: UploadAssetRequest,
  ): CancelablePromise<NftUploadResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/admin/nft-asset/unassigned-nft',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
