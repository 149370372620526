import { RemSize } from '@metaswiss/ui-kit';
import { ReactNode } from 'react';

import { FlexWrapper } from './flex.styles';

type FlexProps = {
  flexDirection?: 'column' | 'row' | 'column-reverse';
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'normal';
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  wrap?: 'wrap' | 'nowrap';
  gap?: RemSize;
  children: ReactNode;
  fill?: boolean;
};

export const Flex = ({
  flexDirection = 'row',
  alignItems = 'normal',
  justifyContent = 'flex-start',
  gap = '0rem',
  wrap = 'nowrap',
  children,
  fill = false,
}: FlexProps) => {
  return (
    <FlexWrapper
      $flexDirection={flexDirection}
      $alignItems={alignItems}
      $justifyContent={justifyContent}
      $gap={gap}
      $wrap={wrap}
      $fill={fill}
    >
      {children}
    </FlexWrapper>
  );
};
