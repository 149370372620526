export enum ToastType {
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

export type ToastProps = {
  id: string;
  message: string;
  heading: string;
  type: ToastType;
};

export const DEFAULT_DURATION = 2000;

export type ToastQueue = {
  enqueue: (type: ToastType, message: string, heading: string) => void;
  dequeue: (id: string) => void;
  queue: ToastProps[];
  clearQueue: () => void;
};

export type ToastQueueProviderProps = {
  children: React.ReactNode;
  version?: 'v1' | 'v2';
};
