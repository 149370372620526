import styled from 'styled-components';

export const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const LogoContainer = styled.img`
  margin-top: 4.25rem;
  height: 3.5rem;
  width: auto;
`;

export const VerifyWrapper = styled.div`
  display: flex;
  max-width: 36rem;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  height: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  text-align: center;
  padding: 0 1rem;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.25rem;
`;

export const Icon = styled.img`
  width: 19.5rem;
  height: 15rem;
`;
