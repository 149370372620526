import { NewsletterResponse } from '@metaswiss/api';
import { mapIsoStringToDate } from '@metaswiss/lib';
import { LocalSpinner, PageStateContainer, Text } from '@metaswiss/ui-kit';
import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';

import { api } from '../../../../api/msApi';
import { TryAgain } from '../../../../components/try-again/TryAgain';
import { ApiResource } from '../../../../enums/resource.enum';
import { useAppState, useHeaderOptions } from '../../../../global-state/zustand';
import { usePagination } from '../../../../hooks/use-pagination/usePagination';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { defaultUser } from '../../../../shared/helpers/defaultUser';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';
import { NewsletterItem } from '../dashboard/components/newsletter-item/NewsletterItem';

import {
  Dot,
  ItemOnTimeline,
  NewsletterContent,
  NewsletterYearsIndicatorContainer,
  YearBlock,
  YearContainer,
} from './newsletter.styles';

type YearData = {
  [year: string]: NewsletterResponse[];
};

const mapNewsletterData = (inputData: NewsletterResponse[]): YearData => {
  const groupedData: YearData = {};

  inputData.forEach((item) => {
    const year = new Date(item.publishDate).getFullYear().toString();
    if (!groupedData[year]) {
      groupedData[year] = [];
    }

    groupedData[year].push({
      title: item.title,
      description: item.description,
      image: {
        url: item.image.url,
        fileName: item.image.fileName,
      },
      document: {
        url: item.document.url,
        fileName: item.document.fileName,
      },
      tenant: item.tenant,
      language: item.language,
      createdAt: new Date(item.createdAt).toISOString(),
      updatedAt: new Date(item.updatedAt).toISOString(),
      publishDate: new Date(item.publishDate).toISOString(),
    });
  });

  return groupedData;
};

export const Newsletter = () => {
  const theme = useTheme();
  const { textTranslation, currentLanguage } = useTextTranslation();
  const user = useAppState((state) => state.user) || defaultUser;
  const { setHeaderTitle } = useHeaderOptions();

  useEffect(() => {
    setHeaderTitle(textTranslation('home.newsletter'));
  }, [setHeaderTitle]);

  const {
    data: newsletters,
    lastItemRef,
    isError,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  } = usePagination<NewsletterResponse>({
    queryKey: getQueryKey(ApiResource.NEWSLETTER, user.id),
    queryFn: (offset: number, limit: number) => {
      return api.newsletters.getNewsletters(currentLanguage, offset, limit);
    },
    enabled: !!user,
  });

  const data = mapNewsletterData(newsletters || []);

  const years = Object.keys(data).sort((a, b) => Number(b) - Number(a));

  return (
    <PageStateContainer
      isError={isError && !newsletters}
      isLoading={isLoading}
      showError={true}
      showLoading={true}
      onTryAgain={fetchNextPage}
      textTranslation={textTranslation}
      showEmptyState={false}
    >
      <Text fontWeight="bold" fontSize={'lg'} color={theme.v2.text.headingPrimary}>
        {textTranslation('home.newsletterTitle')}
      </Text>
      {newsletters && newsletters.length && (
        <NewsletterContent>
          <NewsletterYearsIndicatorContainer />
          {Object.keys(data)
            .sort((a, b) => Number(b) - Number(a))
            .map((year, index) => {
              return (
                <React.Fragment key={year}>
                  <ItemOnTimeline>
                    <Dot $isFirst={!index} />
                    <YearContainer $isFirst={!index}>
                      <Text
                        color={!index ? theme.v2.text.information : theme.v2.text.disabled}
                        fontSize={'xsm'}
                        fontWeight={'bold'}
                        lineHeight={'extraSmall'}
                      >
                        {year}
                      </Text>
                    </YearContainer>
                  </ItemOnTimeline>
                  <YearBlock>
                    {data[year].map((item, articleIndex) => {
                      const isLastItem = index === years.length - 1 && articleIndex === data[year].length - 1;

                      return (
                        <span key={`${item.title}-${item.createdAt}`} ref={isLastItem ? lastItemRef : null}>
                          <NewsletterItem
                            title={item.title}
                            date={mapIsoStringToDate({ date: item.publishDate, splitChar: '.', endWithChar: true })}
                            description={item.description}
                            buttonText={textTranslation('home.readMore')}
                            onClick={() => window.open(item.image.url, '_blank')}
                            image={item.document.url}
                            width={'14.375rem'}
                            height={'14.25rem'}
                          />
                        </span>
                      );
                    })}
                  </YearBlock>
                </React.Fragment>
              );
            })}
          {isFetchingNextPage && <LocalSpinner isActive={isFetchingNextPage} />}
          {isError && newsletters.length && <TryAgain action={fetchNextPage} />}
        </NewsletterContent>
      )}
    </PageStateContainer>
  );
};
