/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveCasesStatisticResponse } from '../models/ActiveCasesStatisticResponse';
import type { AddCaseDocumentsRequest } from '../models/AddCaseDocumentsRequest';
import type { CaseDetailsResponse } from '../models/CaseDetailsResponse';
import type { CaseDetailsV5Response } from '../models/CaseDetailsV5Response';
import type { CaseResponse } from '../models/CaseResponse';
import type { CreateCaseRequest } from '../models/CreateCaseRequest';
import type { DeleteCaseAssetsRequest } from '../models/DeleteCaseAssetsRequest';
import type { PageableItems } from '../models/PageableItems';
import type { RequestCaseChangesRequest } from '../models/RequestCaseChangesRequest';
import type { RequestChangesResponse } from '../models/RequestChangesResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CaseApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns CaseResponse Case created
   * @throws ApiError
   */
  public createCase(
    requestBody: CreateCaseRequest,
  ): CancelablePromise<CaseResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/case',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param recipientId
   * @param offset
   * @param limit
   * @param page
   * @returns any Get cases by recipient
   * @throws ApiError
   */
  public getCasesByUser(
    recipientId: string,
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/case',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'recipientId': recipientId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any Delete case assets
   * @throws ApiError
   */
  public deleteCaseAssets(
    requestBody: DeleteCaseAssetsRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v5/case',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param offset
   * @param limit
   * @param page
   * @returns any Get active cases by recipient
   * @throws ApiError
   */
  public getActiveCasesByUser(
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/case/active',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param offset
   * @param limit
   * @param page
   * @returns any Get active cases by recipient
   * @throws ApiError
   */
  public getHistoryCasesByUser(
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/case/history',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns CaseDetailsResponse Get case details
   * @throws ApiError
   */
  public getCaseDetails(
    id: string,
  ): CancelablePromise<CaseDetailsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/case/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param caseId
   * @returns CaseResponse Case approved
   * @throws ApiError
   */
  public approveCase(
    caseId: string,
  ): CancelablePromise<CaseResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/case/approve-case',
      query: {
        'caseId': caseId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns RequestChangesResponse Request case changes
   * @throws ApiError
   */
  public requestCaseChanges(
    id: string,
    requestBody: RequestCaseChangesRequest,
  ): CancelablePromise<RequestChangesResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/case/{id}/request-changes',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param caseId
   * @param requestBody
   * @returns any Add case assets
   * @throws ApiError
   */
  public addCaseDocuments(
    caseId: string,
    requestBody: AddCaseDocumentsRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/case/add-case-assets',
      query: {
        'caseId': caseId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns ActiveCasesStatisticResponse Get User Active Cases Statistic
   * @throws ApiError
   */
  public getActiveCasesStatisticByUser(): CancelablePromise<ActiveCasesStatisticResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/case/active/statistic',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns CaseDetailsV5Response Get case details
   * @throws ApiError
   */
  public getCaseDetails1(
    id: string,
  ): CancelablePromise<CaseDetailsV5Response> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/case/{id}/details',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @param offset
   * @param limit
   * @param page
   * @returns any Get case conversation
   * @throws ApiError
   */
  public getCaseConversation(
    id: string,
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/case/{id}/conversation',
      path: {
        'id': id,
      },
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
