import { FC } from 'react';

import { IconProps } from './iconProps';

export const Nft02Icon: FC<IconProps> = ({ strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.51436 6.71425L11.5144 2.2698C11.8164 2.10201 12.1836 2.10201 12.4856 2.2698L20.4856 6.71425C20.8031 6.89062 21 7.22524 21 7.5884V16.4116C21 16.7748 20.8031 17.1094 20.4856 17.2858L12.4856 21.7302C12.1836 21.898 11.8164 21.898 11.5144 21.7302L3.51436 17.2858C3.19689 17.1094 3 16.7748 3 16.4116V7.5884C3 7.22524 3.19689 6.89062 3.51436 6.71425Z"
        stroke={strokeColor}
        strokeWidth="1.5"
      />
      <path
        d="M11.5 14V12M12.8775 10H11.5V12M11.5 12H12.83M15 10H16.7075M18.415 10H16.7075M16.7075 10V14M9.41502 10V14L6 10V14"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
