export const ZigZagPath = ({ backgroundColor }: { backgroundColor: string }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 600 16" fill={backgroundColor} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11140_53115)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H36C27.1634 0 20 7.16344 20 16H0V0ZM84 0H36C44.8366 0 52 7.16344 52 16H68C68 7.16344 75.1634 0 84 0ZM132 0C123.163 0 116 7.16344 116 16H100C100 7.16344 92.8366 0 84 0H132ZM180 0H132C140.837 0 148 7.16344 148 16H164C164 7.16344 171.163 0 180 0ZM228 0C219.163 0 212 7.16344 212 16H196C196 7.16344 188.837 0 180 0H228ZM276 0H228C236.837 0 244 7.16344 244 16H260C260 7.16344 267.163 0 276 0ZM324 0C315.163 0 308 7.16344 308 16H292C292 7.16344 284.837 0 276 0H324ZM372 0H324C332.837 0 340 7.16344 340 16H356C356 7.16344 363.163 0 372 0ZM420 0C411.163 0 404 7.16344 404 16H388C388 7.16344 380.837 0 372 0H420ZM468 0H420C428.837 0 436 7.16344 436 16H452C452 7.16344 459.163 0 468 0ZM516 0C507.163 0 500 7.16344 500 16H484C484 7.16344 476.837 0 468 0H516ZM564 0H516C524.837 0 532 7.16344 532 16H548C548 7.16344 555.163 0 564 0ZM580 16H600V0H564C572.837 0 580 7.16344 580 16Z"
          shapeRendering="crispEdges"
          fill={backgroundColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_11140_53115">
          <rect width="100%" height="100%" fill={backgroundColor} />
        </clipPath>
      </defs>
    </svg>
  );
};
