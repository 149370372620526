import styled from 'styled-components';

import { TextTemplate } from '../../../../shared/typography/TextTemplate.styles';

export const DateOption = styled.button<{ $optionValue: string }>`
  position: relative;
  width: 5rem;
  height: 2rem;
  display: grid;
  place-content: center;

  ${TextTemplate} {
    word-break: normal;
  }

  &:hover,
  &:focus {
    ${TextTemplate} {
      color: ${({ theme }) => theme.v2.text.information};
    }
  }

  &:disabled {
    pointer-events: none;

    ${TextTemplate} {
      color: ${({ theme }) => theme.v2.text.disabled};
    }
  }

  ${TextTemplate} {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.25s ease-in-out;
  }

  ${TextTemplate}:first-child {
    opacity: ${({ $optionValue }) => ($optionValue ? 0 : 1)};
  }

  ${TextTemplate}:last-child {
    opacity: ${({ $optionValue }) => ($optionValue ? 1 : 0)};
  }
`;
