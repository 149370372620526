import { IconProps } from './iconProps';

export const CloseSmallIcon: React.FC<IconProps> = ({ strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.6668 9.33203L9.3335 22.6654M22.6668 22.6654L9.3335 9.33203"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
