type Props = {
  backgroundColor?: string;
  foregroundColor?: string;
  borderColor?: string;
};

export const ErrorFeedbackIcon: React.FC<Props> = ({ backgroundColor, foregroundColor, borderColor }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 240 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="27.2578" y="17.2529" width="39.0014" height="30.0011" rx="15.0005" fill={backgroundColor} />
      <rect x="78.2617" y="17.2529" width="135.005" height="30.0011" rx="15.0005" fill={backgroundColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9961 80.2535C12.9961 71.9689 19.7121 65.2529 27.9966 65.2529H223.004C231.288 65.2529 238.004 71.9689 238.004 80.2535C238.004 88.538 231.288 95.254 223.004 95.254H199.118C191.443 95.254 186.254 104.079 186.254 111.755C186.254 119.157 191.277 127.505 198.679 127.505H199.757C214.255 127.505 226.008 139.258 226.008 153.756C226.008 168.254 214.255 180.007 199.757 180.007H27.2509C12.7529 180.007 1 168.254 1 153.756C1 139.258 12.753 127.505 27.251 127.505H52.325C59.7276 127.505 64.7499 119.157 64.7499 111.755C64.7499 104.079 59.5616 95.254 51.8858 95.254H27.9966C19.7121 95.254 12.9961 88.538 12.9961 80.2535Z"
        fill={backgroundColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.9665 45.5813C72.8603 39.7592 82.358 39.8173 88.1801 45.7111L119.74 77.6595L151.3 45.7111C157.122 39.8173 166.619 39.7592 172.513 45.5813C178.407 51.4034 178.465 60.901 172.643 66.7949L140.825 99.0045L172.643 131.214C178.465 137.108 178.407 146.606 172.513 152.428C166.619 158.25 157.122 158.192 151.3 152.298L119.74 120.349L88.1801 152.298C82.3579 158.192 72.8603 158.25 66.9665 152.428C61.0727 146.606 61.0145 137.108 66.8367 131.214L98.6545 99.0045L66.8367 66.7949C61.0146 60.901 61.0727 51.4034 66.9665 45.5813Z"
        stroke={borderColor || 'white'}
        strokeWidth="6.00022"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.975 56.2515L77.5117 141.755"
        stroke={foregroundColor}
        strokeWidth="30.0011"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.5056 56.2515L161.969 141.755"
        stroke={foregroundColor}
        strokeWidth="30.0011"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2536 43.5129L15.2536 54.9134M20.9538 49.2131L9.55334 49.2131"
        stroke={foregroundColor}
        strokeWidth="2.37509"
        strokeLinecap="round"
      />
      <path
        d="M201.837 144.726L201.837 161.227M210.088 152.976L193.587 152.976"
        stroke={foregroundColor}
        strokeWidth="4.62517"
        strokeLinecap="round"
      />
      <path
        d="M72.0667 5.40039L72.0667 12.6006M75.6668 9.00052L68.4666 9.00052"
        stroke={foregroundColor}
        strokeWidth="1.50005"
        strokeLinecap="round"
      />
    </svg>
  );
};
