import { styled } from 'styled-components';

export const StyledCaseHeader = styled.div`
  position: relative;
  padding: 1rem;
  background-color: ${({ theme }) => theme.v2.surface.primary};
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
`;

export const CaseDetailsButtonWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
`;
