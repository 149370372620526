import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import { TextLinkButton } from '@metaswiss/ui-kit/src/components/atoms/text-link/styles/textLinkButton';
import { styled } from 'styled-components';

export const DetailsCard = styled.div`
  width: 100%;
  height: fit-content;
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.default};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  background: ${({ theme }) => theme.v2.surface.primary};
  display: flex;
  flex-direction: column;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.5rem;
  height: 7.5rem;
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  background: ${({ theme }) => theme.v2.surface.primary};
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.default};
  padding: 2rem;
`;

export const Logo = styled.img`
  object-fit: cover;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
`;

export const DetailsLogoWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 1rem;
`;

export const CountryWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
`;

export const Flag = styled.img`
  width: 2rem;
  border-radius: 0.25rem;
`;

export const AboutTextContainer = styled.div`
  width: 100%;
  height: fit-content;
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  background: ${({ theme }) => theme.v2.surface.primary};
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.default};
  padding: 1rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  -webkit-line-clamp: 15;
  white-space: pre-wrap;
`;

export const VideoContainer = styled.div`
  width: auto;
  height: 23.75rem;
  @media ${breakpoints.laptopSm} {
    height: 34.75rem;
  }
`;

export const VideoThumbnail = styled.div`
  width: 100%;
  height: 100%;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
`;

export const LinkWrapper = styled.div`
  width: fit-content;
  position: absolute;
  right: 1rem;

  ${TextLinkButton} {
    &:hover {
      svg {
        path {
          stroke: ${({ theme }) => theme.colors.primary.hue200};
        }
      }
    }

    &:active {
      svg {
        path {
          stroke: ${({ theme }) => theme.colors.primary.hue400};
        }
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1.5rem;
`;

export const AddressWrapper = styled.div`
  width: 100%;
`;

export const BannerImage = styled.img`
  width: 100%;
  height: 15rem;
  object-fit: cover;
  border-radius: 0.75rem 0.75rem 0 0;
`;

export const SingleImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  object-fit: cover;
`;

export const DoubleImage = styled.img`
  width: 100%;
  height: 9.9375rem;
  border-radius: 0.75rem;
  object-fit: cover;
`;

export const MultipleImages = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media ${breakpoints.laptopSm} {
    flex-direction: row;
  }
`;

export const HorizontalImagesWrapper = styled.div`
  display: flex;
  gap: 1rem;
  height: 28.75rem;
  @media ${breakpoints.laptopSm} {
    height: 20.875rem;
    max-width: 30.5rem;
  }
`;

export const OneImageWrapper = styled.div`
  width: 100%;
`;

export const TwoImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  @media ${breakpoints.laptopSm} {
    max-width: 30.5rem;
  }
`;

export const TextOverflowContainer = styled.div<{ $width: string }>`
  width: ${({ $width }) => $width};
  min-width: 27rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  @media ${breakpoints.laptopSm} {
    min-width: 35rem;
  }
`;

export const LinkTextIcon = styled.div`
  display: flex;
  gap: 0.25rem;
`;
