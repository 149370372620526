import { IconProps } from './iconProps';

export const HomeIcon: React.FC<IconProps> = ({ color = 'none', strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 25 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.54999 21.5999V14.2963C9.54999 13.624 10.1231 13.079 10.83 13.079H14.67C15.3769 13.079 15.95 13.624 15.95 14.2963V21.5999M12.0082 2.62515L3.6882 8.25167C3.35054 8.48001 3.14999 8.84967 3.14999 9.24369V19.774C3.14999 20.7824 4.00961 21.5999 5.06999 21.5999H20.43C21.4904 21.5999 22.35 20.7824 22.35 19.774V9.24369C22.35 8.84967 22.1494 8.48001 21.8118 8.25167L13.4918 2.62515C13.0477 2.32482 12.4523 2.32482 12.0082 2.62515Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
