import {
  Dispatch,
  FC,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

export type Step = {
  title: string;
  subtitle: string;
  children: ReactNode;
  nextStep?: () => void;
  prevStep?: () => void;
  buttonText: string;
};

type Props = {
  steps?: Step[];
};

type PaymentWizardContext = {
  title: string;
  subtitle: string;
  totalSteps: number;
  currentStep: number;
  onBack: () => void;
  onNext: () => void;
  animationDirection: 'forward' | 'backward';
  buttonText?: string;
  children: ReactNode;
  isButtonLoading: boolean;
  isButtonDisabled: boolean;
  setIsButtonLoading: (isLoading: boolean) => void;
  setIsButtonDisabled: (isDisabled: boolean) => void;
  setWizardSteps: Dispatch<SetStateAction<Step[]>>;
  changeNextStepOnClick: (onNext: () => void) => void;
  changePrevStepOnClick: (onPrev: () => void) => void;
  setIsNextStepDisabled: (isEnabled: boolean) => void;
  activeStep: number;
  setActiveStep: (step: number) => void;
  setIsBackStepDisabled: (isDisabled: boolean) => void;
  isBackStepDisabled: boolean;
  isNextStepDisabled: boolean;
};

const PaymentWizardContext = createContext({} as PaymentWizardContext);

const usePaymentWizard = () => useContext(PaymentWizardContext);

const PaymentWizardProvider: FC<PropsWithChildren<Props>> = ({ children, steps = [] }) => {
  const [activeStep, setActiveStep] = useState<number>(1);
  const [animationDirection, setAnimationDirection] = useState<'forward' | 'backward'>('forward');
  const [wizardSteps, setWizardSteps] = useState<Step[]>(steps);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isNextStepDisabled, setIsNextStepDisabled] = useState<boolean>(false);
  const [isBackStepDisabled, setIsBackStepDisabled] = useState<boolean>(false);

  const step = wizardSteps[activeStep - 1] || {};

  const nextStepHandler = useCallback(() => {
    setAnimationDirection('forward');
    step.nextStep?.();
    if (activeStep !== wizardSteps.length && !isNextStepDisabled) {
      setActiveStep(activeStep + 1);
    }
  }, [activeStep, isNextStepDisabled, step.nextStep, wizardSteps.length]);

  const backStepHandler = useCallback(() => {
    setAnimationDirection('backward');
    step.prevStep?.();

    if (activeStep > 1 && !isBackStepDisabled) {
      setActiveStep(activeStep - 1);
      setIsBackStepDisabled(false);
    }
  }, [activeStep, step.prevStep, isBackStepDisabled]);

  const changeNextStepOnClick = useCallback(
    (onNext: () => void) => {
      setAnimationDirection('forward');
      const currentStepObj = { ...wizardSteps[activeStep - 1] };
      currentStepObj.nextStep = onNext;

      const newWizardSteps = [...wizardSteps];
      newWizardSteps[activeStep - 1] = currentStepObj;
      setWizardSteps(newWizardSteps);
    },
    [activeStep, wizardSteps]
  );

  const changePrevStepOnClick = useCallback(
    (onPrev: () => void) => {
      setAnimationDirection('backward');
      const currentStepObj = { ...wizardSteps[activeStep - 1] };
      currentStepObj.prevStep = onPrev;

      const newWizardSteps = [...wizardSteps];
      newWizardSteps[activeStep - 1] = currentStepObj;
      setWizardSteps(newWizardSteps);
    },
    [activeStep, wizardSteps]
  );

  useEffect(() => {
    return () => {
      setIsButtonDisabled(false);
      setIsButtonLoading(false);
    };
  }, [activeStep]);

  return (
    <PaymentWizardContext.Provider
      value={{
        title: step.title,
        subtitle: step.subtitle,
        totalSteps: wizardSteps.length,
        currentStep: activeStep,
        animationDirection,
        onBack: backStepHandler,
        onNext: nextStepHandler,
        buttonText: step.buttonText,
        children: step.children,
        isButtonLoading,
        isButtonDisabled,
        setIsButtonLoading,
        setIsButtonDisabled,
        setWizardSteps,
        changeNextStepOnClick,
        changePrevStepOnClick,
        setIsNextStepDisabled,
        setActiveStep,
        activeStep,
        setIsBackStepDisabled,
        isBackStepDisabled,
        isNextStepDisabled,
      }}
    >
      {children}
    </PaymentWizardContext.Provider>
  );
};

export { PaymentWizardContext, PaymentWizardProvider, usePaymentWizard };
