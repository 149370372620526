import { css, styled } from 'styled-components';

import { TextTemplate } from '../../../../components';

const flexStyles = css`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const DocumentContainer = styled.div<{ $isDisabled: boolean }>`
  ${flexStyles};
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.v2.border.primaryHover};
  border-radius: ${({ theme }) => theme.dimensions.borderRadii.large};
  background-color: ${({ $isDisabled, theme }) => $isDisabled && theme.v2.surface.disabled};

  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.colors.neutrals.hue25};
      }

      &:active {
        background-color: transparent;
      }
    `}
`;

export const DocumentInfoWrapper = styled.div`
  ${flexStyles}
`;

export const DocumentTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${TextTemplate} {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const DeleteIconWrapper = styled.div`
  border-radius: 50%;

  svg:hover rect {
    fill: ${({ theme }) => theme.colors.error.hue400};
  }

  svg:active rect {
    fill: ${({ theme }) => theme.colors.error.hue600};
  }
`;

export const SpinnerWrapper = styled.div`
  flex-shrink: 0;
`;
