import { FC } from 'react';
import { useTheme } from 'styled-components';

import { Text } from '../../../atoms/text';
import { BaseCard } from '../base-card/BaseCard';

import { Content, Description, Image, Wrapper } from './productDetailsCard.styles';

export type Props = {
  imageUrl?: string;
  productTranslation: string;
  name?: string;
  description?: string;
};

export const ProductDetailsCard: FC<Props> = ({ imageUrl = '', productTranslation, name = '', description = '' }) => {
  const theme = useTheme();
  return (
    <BaseCard
      width={'100%'}
      height={'100%'}
      padding={'1rem'}
      disableStateEffects
      override={{
        borderRadius: theme.v2.componentsRadius.cards.default,
        backgroundColor: theme.v2.surface.primary,
        borderColor: theme.v2.border.primary,
      }}
    >
      <Wrapper>
        <Text fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
          {productTranslation}
        </Text>
        <Content>
          <Image src={imageUrl} />
          <Description>
            <Text fontWeight={'semi'} color={theme.v2.text.headingPrimary}>
              {name}
            </Text>
            <Text color={theme.v2.text.disabled}>{description}</Text>
          </Description>
        </Content>
      </Wrapper>
    </BaseCard>
  );
};
