import { ClipLoader } from 'react-spinners';
import { useTheme } from 'styled-components';

import { Text, ThemedIcon } from '../../../components';
import { ArrowRotateLeft, FileIcon05 } from '../../../iconography';

import {
  DocumentContainer,
  DocumentStateWrapper,
  IconWrapper,
  LeftSideContainer,
  TextsWrapper,
} from './document.styles';
import { DocumentState } from './enums';
import { DocumentProps } from './types';

type ColorDefinitions = {
  containerColor: 'disabled' | 'primary';
  iconColor: 'primary' | 'error';
  subTitleColor?: 'disabled' | 'error';
};

export const Document = ({
  text,
  icon = FileIcon05,
  onClick,
  isLoading,
  isError,
  status,
  processingText,
  processingFailedText,
  loadingFailedText,
}: DocumentProps) => {
  const theme = useTheme();

  const { subTitleColor, containerColor, iconColor } = getColorDefinitions(status);

  return (
    <DocumentContainer
      onClick={onClick}
      $backgroundColor={containerColor}
      $isHoverAllowed={status === DocumentState.COMPLETED || isError}
    >
      <LeftSideContainer>
        <IconWrapper>
          <ThemedIcon size={'largeX'} icon={icon} customStrokeColor={theme.v2.icon[iconColor]} />
        </IconWrapper>

        <TextsWrapper>
          <Text fontWeight={'semi'} color={theme.v2.text.headingPrimary}>
            {text}
          </Text>
          {(status !== DocumentState.COMPLETED || isError) && (
            <DocumentStateWrapper>
              {status === DocumentState.PROCESSING && (
                <ClipLoader size={theme.icon.sizes.extraSmall} color={theme.colors.primary.hue300} />
              )}
              <Text fontSize={'xsm'} lineHeight={'extraSmall'} color={theme.v2.text[subTitleColor || 'disabled']}>
                {isError
                  ? loadingFailedText
                  : status === DocumentState.PROCESSING
                    ? processingText
                    : processingFailedText}
              </Text>
            </DocumentStateWrapper>
          )}
        </TextsWrapper>
      </LeftSideContainer>
      {isLoading && <ClipLoader size={theme.icon.sizes.medium} color={theme.v2.icon.primary} />}
      {isError && <ThemedIcon icon={ArrowRotateLeft} customStrokeColor={theme.v2.icon.error} />}
    </DocumentContainer>
  );
};

const getColorDefinitions = (status: DocumentState): ColorDefinitions => {
  switch (status) {
    case DocumentState.PROCESSING:
      return {
        containerColor: 'primary',
        iconColor: 'primary',
        subTitleColor: 'disabled',
      };
    case DocumentState.COMPLETED:
      return {
        containerColor: 'primary',
        iconColor: 'primary',
      };
    case DocumentState.FAILED:
      return {
        containerColor: 'disabled',
        iconColor: 'error',
        subTitleColor: 'error',
      };
    default:
      throw new Error('Unsupported document status!');
  }
};
