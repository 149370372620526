import {
  CalendarIcon,
  FormCheckbox,
  FormDatePicker,
  FormDropdown,
  FormInput,
  FormSelectionPopup,
  InputPhoneNumber,
  LuggageIcon,
  Text,
  TextLink,
  ThemedIcon,
} from '@metaswiss/ui-kit';
import { ItemProps } from '@metaswiss/ui-kit/src/components/molecules/selection-popup/types';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { FieldError, Path, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { api } from '../../../../../../../../api/msApi';
import ControlledForm from '../../../../../../../../components/controlled-form/ControlledForm.tsx';
import { PasswordValidationInputs } from '../../../../../../../../components/passwords-validation-inputs/PasswordsValidationInputs.tsx';
import { useBusinesses } from '../../../../../../../../hooks/use-businesses/useBusinesses';
import { useCountries } from '../../../../../../../../hooks/use-countries/useCountries';
import { useLegalForm } from '../../../../../../../../hooks/use-legal-form/useLegalForm';
import { useTextTranslation } from '../../../../../../../../hooks/use-text-translation/useTextTranslation';
import { MainContentContainer } from '../../../../../../../../layouts/public-layout/styles/publicLayout.styles.ts';
import { routes } from '../../../../../../../../router/routes.ts';
import { swissPhonePrefix } from '../../../../../../../../shared/constants/swissPhonePrefix.ts';
import { tenantConfig } from '../../../../../../../../tenantConfig.ts';
import { useAuthContext } from '../../../../../../shared/auth-context/authContext.ts';
import { useSetAuthConfig } from '../../../../../../shared/auth-context/useSetAuthConfig.ts';
import { RegisterHeader } from '../../../../../shared/register-header/RegisterHeader';
import {
  CheckboxWrapper,
  OneLineElement,
  RegistrationGridWrapper,
  TextLinkWrapper,
} from '../../../../../shared/styles/registrationStyles';
import { ValidatedInputField } from '../../../../../shared/validated-input-field/ValidatedInputField.tsx';
import { initialFullCorporateRegistrationValues } from '../fullCorporateRegistrationState.ts';

import {
  createFullCorporateInformationSchema,
  FullCorporateRegistrationCorporateInfoFormData,
  fullCorporateRegistrationCorporateInfoSchema,
} from './corporateInformationSchema';

export const CorporateInformationView = () => {
  const navigate = useNavigate();

  const { countriesPhoneNumberPrefixesAsDropdown } = useCountries();
  const { businesses } = useBusinesses();
  const { legalForms } = useLegalForm();
  const { textTranslation, currentLanguage } = useTextTranslation();
  const { setIsValid, setUserDetails, nextStep, userRole, setCheckedEmail } = useAuthContext((state) => state);

  const [doPasswordsMatch, setDoPasswordsMatch] = useState<boolean>(true);
  const [userExist, setUserExist] = useState<boolean>(false);

  const {
    control,
    setValue,
    getValues,
    setError,
    formState: { errors, isValid },
    handleSubmit,
  } = useFormContext<FullCorporateRegistrationCorporateInfoFormData>();

  const setRegistrationContextState = useCallback(() => {
    setUserDetails({ ...initialFullCorporateRegistrationValues, ...getValues() });
  }, [getValues, setUserDetails]);

  useEffect(() => {
    setIsValid(isValid && doPasswordsMatch && !userExist && !Object.keys(errors || {}).length);
    return () => {
      setIsValid(true);
    };
  }, [doPasswordsMatch, isValid, setIsValid, userExist, errors]);

  useEffect(() => {
    const defaultPhoneNumberPrefix = countriesPhoneNumberPrefixesAsDropdown.find(
      (item: ItemProps) => item.meta === swissPhonePrefix
    );
    const phoneNumberPrefixValue = defaultPhoneNumberPrefix?.meta ?? '';
    if (!getValues(fullCorporateRegistrationCorporateInfoSchema.phoneNumberPrefix)) {
      setValue(fullCorporateRegistrationCorporateInfoSchema.phoneNumberPrefix, phoneNumberPrefixValue);
    }
  }, [countriesPhoneNumberPrefixesAsDropdown, getValues, setValue]);

  useEffect(() => {
    return () => {
      setRegistrationContextState();
    };
  }, [setRegistrationContextState]);

  const handlePhonePrefixSelection = (item: ItemProps) => {
    setValue(fullCorporateRegistrationCorporateInfoSchema.phoneNumberPrefix, `${item.meta}`, { shouldValidate: true });
  };

  const handleBirthDateSelection = (date: string) => {
    setValue(fullCorporateRegistrationCorporateInfoSchema.dateFounded, date, { shouldValidate: true });
  };

  const getSelectedNumberPrefix = useCallback(() => {
    return countriesPhoneNumberPrefixesAsDropdown.find((country) => country.meta === getValues('phoneNumberPrefix'));
  }, [countriesPhoneNumberPrefixesAsDropdown, getValues]);

  const checkEmailMutation = useMutation({
    mutationFn: () => {
      return api.users.checkEmailExists(getValues().email);
    },
    onMutate: () => {
      setCheckedEmail(getValues().email);
    },
    onError: () => {
      setError('email', { message: textTranslation('error.default') });
    },
    onSuccess: (result) => {
      if (result) {
        setError('email', { message: textTranslation('error.emailExist') });
      }
    },
  });

  const checkValueMutation = useMutation({
    mutationFn: () => {
      return api.users.checkCorporateNameExists(getValues().name);
    },
    onError: () => {
      setError('email', { message: textTranslation('error.default') });
    },
    onSuccess: (result) => {
      setUserExist(result);
      if (result) {
        setError('name', { message: textTranslation('error.emailExist') });
      }
    },
  });

  const onCorporateInfoSubmit = useCallback(() => {
    setRegistrationContextState();
    nextStep();
  }, [nextStep, setRegistrationContextState]);

  const handleBackClick = () => {
    setValue(fullCorporateRegistrationCorporateInfoSchema.termsAndConditions, false);
    navigate(`${routes.register.root}/${userRole}`);
  };

  useSetAuthConfig(
    {
      wrapperStyle: 'registrationWizard',
      containerStyle: 'registrationWizard',
      backButton: {
        onClick: () => handleBackClick(),
        text: 'button.back',
      },
      nextButton: {
        text: 'button.next',
        onClick: () => handleSubmit(onCorporateInfoSubmit)(),
      },
      showStepper: true,
    },
    [handleSubmit, onCorporateInfoSubmit, navigate, userRole]
  );

  return (
    <>
      <RegisterHeader descriptionText={textTranslation('registration.corporateRegistration.corporateTitle')} />
      <RegistrationGridWrapper>
        <OneLineElement>
          <ValidatedInputField<FullCorporateRegistrationCorporateInfoFormData>
            name={
              fullCorporateRegistrationCorporateInfoSchema.name as Path<FullCorporateRegistrationCorporateInfoFormData>
            }
            error={errors[fullCorporateRegistrationCorporateInfoSchema.name]}
            control={control}
            fill
            label={textTranslation('registration.corporateRegistration.corporateName')}
            checkValueExists={checkValueMutation.mutate}
            isSpinnerIcon={checkValueMutation?.isLoading}
          />
        </OneLineElement>
        <FormInput<FullCorporateRegistrationCorporateInfoFormData>
          name={
            fullCorporateRegistrationCorporateInfoSchema.ceo as Path<FullCorporateRegistrationCorporateInfoFormData>
          }
          error={errors[fullCorporateRegistrationCorporateInfoSchema.ceo]}
          control={control}
          fill
          label={textTranslation('registration.corporateRegistration.ceo')}
        />
        <FormInput<FullCorporateRegistrationCorporateInfoFormData>
          name={
            fullCorporateRegistrationCorporateInfoSchema.owner as Path<FullCorporateRegistrationCorporateInfoFormData>
          }
          error={errors[fullCorporateRegistrationCorporateInfoSchema.owner]}
          control={control}
          fill
          label={textTranslation('registration.corporateRegistration.owner')}
        />
        <OneLineElement>
          <ValidatedInputField<FullCorporateRegistrationCorporateInfoFormData>
            name={
              fullCorporateRegistrationCorporateInfoSchema.email as Path<FullCorporateRegistrationCorporateInfoFormData>
            }
            error={errors[fullCorporateRegistrationCorporateInfoSchema.email]}
            control={control}
            fill
            label={textTranslation('registration.email')}
            checkValueExists={checkEmailMutation.mutate}
            isSpinnerIcon={checkEmailMutation?.isLoading}
          />
        </OneLineElement>
        <PasswordValidationInputs
          control={control}
          passwordName={fullCorporateRegistrationCorporateInfoSchema.password}
          passwordConfirmationName={fullCorporateRegistrationCorporateInfoSchema.passwordConfirmation}
          passwordError={errors[fullCorporateRegistrationCorporateInfoSchema.password]}
          passwordConfirmationError={errors[fullCorporateRegistrationCorporateInfoSchema.passwordConfirmation]}
          passwordLabel={textTranslation('registration.password')}
          passwordConfirmationLabel={textTranslation('registration.passwordConfirmation')}
          doPasswordsMatch={doPasswordsMatch}
          setDoPasswordsMatch={setDoPasswordsMatch}
        />
        <FormDropdown<FullCorporateRegistrationCorporateInfoFormData>
          name={
            fullCorporateRegistrationCorporateInfoSchema.legalFormId as Path<FullCorporateRegistrationCorporateInfoFormData>
          }
          error={errors[fullCorporateRegistrationCorporateInfoSchema.legalFormId] as FieldError}
          control={control}
          options={legalForms}
          disableFloat={false}
          label={textTranslation('registration.corporateRegistration.legalForm')}
        />
        <FormSelectionPopup
          name={
            fullCorporateRegistrationCorporateInfoSchema.businessFocusId as Path<FullCorporateRegistrationCorporateInfoFormData>
          }
          error={errors[fullCorporateRegistrationCorporateInfoSchema.businessFocusId] as FieldError}
          control={control}
          fill
          label={textTranslation('registration.businessFocus')}
          title={textTranslation('registration.businessFocus')}
          searchText={textTranslation('registration.search')}
          renderEndIcon={() => <ThemedIcon icon={LuggageIcon} palette="primary" strokeColor="hue300" />}
          itemsList={businesses}
          closeButtonText={textTranslation('cancel')}
          selectButtonText={textTranslation('select')}
          containerId={MainContentContainer.styledComponentId}
        />
        <InputPhoneNumber<FullCorporateRegistrationCorporateInfoFormData>
          name={
            fullCorporateRegistrationCorporateInfoSchema.phoneNumber as Path<FullCorporateRegistrationCorporateInfoFormData>
          }
          error={
            errors[fullCorporateRegistrationCorporateInfoSchema.phoneNumberPrefix] ||
            errors[fullCorporateRegistrationCorporateInfoSchema.phoneNumber]
          }
          control={control}
          fill
          label={textTranslation('registration.phoneNumber')}
          itemList={countriesPhoneNumberPrefixesAsDropdown}
          title={textTranslation('registration.numberPrefixTitle')}
          handleSelectedItem={handlePhonePrefixSelection}
          searchText={textTranslation('registration.numberPrefixTitle')}
          selectedItem={getSelectedNumberPrefix()}
          closeButtonText={textTranslation('cancel')}
          selectButtonText={textTranslation('select')}
          defaultItem={countriesPhoneNumberPrefixesAsDropdown.find((item: ItemProps) => item.meta === swissPhonePrefix)}
          containerId={MainContentContainer.styledComponentId}
          maxLength={16}
        />
        <FormDatePicker<FullCorporateRegistrationCorporateInfoFormData>
          name={
            fullCorporateRegistrationCorporateInfoSchema.dateFounded as Path<FullCorporateRegistrationCorporateInfoFormData>
          }
          error={errors[fullCorporateRegistrationCorporateInfoSchema.dateFounded]}
          control={control}
          fill
          label={textTranslation('registration.corporateRegistration.dateFounded')}
          dateSelectOptions={[
            textTranslation('global.day'),
            textTranslation('global.month'),
            textTranslation('global.year'),
          ]}
          selectText={textTranslation('selectDate')}
          title={textTranslation('registration.corporateRegistration.dateFounded')}
          renderEndIcon={() => <ThemedIcon icon={CalendarIcon} palette="primary" strokeColor="hue300" />}
          onSelect={handleBirthDateSelection}
          closeBtnText={textTranslation('cancel')}
          confirmBtnText={textTranslation('confirm')}
          containerId={MainContentContainer.styledComponentId}
          preferredLanguage={currentLanguage}
        />
        <OneLineElement>
          <CheckboxWrapper>
            <FormCheckbox<FullCorporateRegistrationCorporateInfoFormData>
              name={fullCorporateRegistrationCorporateInfoSchema.termsAndConditions}
              control={control}
              error={errors[fullCorporateRegistrationCorporateInfoSchema.termsAndConditions]}
            />
            <TextLinkWrapper>
              <Text fontSize="sm">{textTranslation('registration.acceptLabel')}</Text>
              <TextLink
                textSize="sm"
                onClick={() => window.open(tenantConfig.termsAndConditionsLink(currentLanguage), '_blank')}
              >
                {textTranslation('registration.termsAndConditions')}
              </TextLink>
            </TextLinkWrapper>
          </CheckboxWrapper>
        </OneLineElement>
      </RegistrationGridWrapper>
    </>
  );
};

export const CorporateInformation = () => {
  const userDetails = useAuthContext((state) => state.userDetails);

  return (
    <ControlledForm validationSchema={createFullCorporateInformationSchema} defaultValues={userDetails}>
      <CorporateInformationView />
    </ControlledForm>
  );
};
