import { ToastType, useToastQueueContext } from '@metaswiss/lib';
import { Heading, Text } from '@metaswiss/ui-kit';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { api } from '../../../api/msApi.ts';
import ControlledForm from '../../../components/controlled-form/ControlledForm.tsx';
import { PasswordValidationInputs } from '../../../components/passwords-validation-inputs/PasswordsValidationInputs';
import { ApiResource } from '../../../enums/resource.enum';
import { useTextTranslation } from '../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../router/routes.ts';
import { getQueryKey } from '../../../shared/helpers/getQueryKey.helper';
import { FormWrapper, HeadingContainer, HeadingWrapper } from '../change-password/changePassword.styles.ts';
import { useAuthContext } from '../shared/auth-context/authContext.ts';
import { useSetAuthConfig } from '../shared/auth-context/useSetAuthConfig.ts';

import { resetPasswordSchema, resetPasswordFormShema, FormData } from './resetPasswordSchema.ts';

export const ResetPasswordView = () => {
  const navigate = useNavigate();
  const { textTranslation } = useTextTranslation();

  const queryClient = useQueryClient();

  const [doPasswordsMatch, setDoPasswordsMatch] = useState<boolean>(true);
  const [searchParam] = useSearchParams();
  const tokenParam = searchParam.get('token');
  const { enqueue } = useToastQueueContext();

  const { setLoading, setIsValid, clearAuthContext } = useAuthContext();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useFormContext<FormData>();

  const { mutate } = useMutation({
    mutationFn: (data: FormData) => {
      return api.auth.resetPasswordWithToken({
        invitationToken: tokenParam || '',
        newPassword: data.password,
        passwordConfirmation: data.confirmPassword,
      });
    },
    onMutate: () => {
      setLoading(true);
    },
    onError: () => {
      enqueue(ToastType.ERROR, textTranslation('error.default'), textTranslation('error.error'));
      setLoading(false);
    },
    onSuccess: async () => {
      clearAuthContext();
      await queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.USER_RESPONSE) });
      navigate(routes.root, { replace: true });
      setLoading(false);
    },
  });

  useEffect(() => {
    setIsValid(isValid && doPasswordsMatch);
    return () => {
      setIsValid(true);
    };
  }, [doPasswordsMatch, isValid, setIsValid]);

  useSetAuthConfig(
    {
      nextButton: {
        text: 'changePassword.confirm',
        onClick: () => {
          handleSubmit((data) => mutate(data))();
        },
      },
    },
    [handleSubmit, mutate]
  );

  return (
    <>
      <HeadingWrapper>
        <HeadingContainer>
          <Heading headingType="h4" fontWeight="bold">
            {textTranslation('changePassword.title')}
          </Heading>
          <Text>{textTranslation('changePassword.description')}</Text>
        </HeadingContainer>
      </HeadingWrapper>
      <FormWrapper>
        <PasswordValidationInputs
          control={control}
          passwordName={resetPasswordFormShema.password}
          passwordConfirmationName={resetPasswordFormShema.confirmPassword}
          passwordError={errors[resetPasswordFormShema.password]}
          passwordConfirmationError={errors[resetPasswordFormShema.confirmPassword]}
          passwordLabel={textTranslation('changePassword.newPassword')}
          passwordConfirmationLabel={textTranslation('changePassword.confirmPassword')}
          doPasswordsMatch={doPasswordsMatch}
          setDoPasswordsMatch={setDoPasswordsMatch}
        />
      </FormWrapper>
    </>
  );
};

export const ResetPassword = () => {
  return (
    <ControlledForm validationSchema={resetPasswordSchema}>
      <ResetPasswordView />
    </ControlledForm>
  );
};
