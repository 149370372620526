import { useTheme } from 'styled-components';

import { CheckContainedIcon } from '../../../iconography';
import { ThemedIcon } from '../theme-icon';

import { Toast } from './Toast';
import { ToastWrapperProps } from './types';

export const SuccessToast: React.FC<ToastWrapperProps> = ({ ...props }) => {
  const theme = useTheme();
  return (
    <Toast
      renderToastIcon={() => <ThemedIcon icon={CheckContainedIcon} customStrokeColor={theme.v2.text.success} />}
      backgroundColor={theme.v2.surface.successLight}
      borderColor={theme.v2.border.success}
      headingColor={theme.v2.text.success}
      messageColor={theme.v2.text.bodySecondary}
      {...props}
    />
  );
};
