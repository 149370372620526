export const statusThemeMapper = (
  status: 'OPENED' | 'PENDING' | 'CHANGES_REQUESTED' | 'APPROVED'
): 'neutrals' | 'error' | 'warning' | 'primary' => {
  switch (status) {
    case 'APPROVED':
      return 'neutrals';
    case 'CHANGES_REQUESTED':
      return 'error';
    case 'OPENED':
      return 'primary';
    case 'PENDING':
      return 'warning';
  }
};
