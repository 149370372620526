import { styled } from 'styled-components';

import { breakpoints } from '../../../breakpoints';
import { RemSize } from '../../../theme';
import { Overlay } from '../../atoms/popup/styles/overlay';
import { ExpandableCardContainer } from '../../molecules/expandable-tablet-card/expandableTabletCard.styles';
import { PaymentBillContainer, PathContainer } from '../../molecules/payment-bill/paymentBill.styles';

export const ResponsiveBillContainer = styled.div<{ $isResponsive: boolean; $billBottomPadding?: RemSize }>`
  ${PaymentBillContainer} {
    display: ${({ $isResponsive }) => ($isResponsive ? 'none' : 'initial')};
  }

  ${ExpandableCardContainer} {
    display: ${({ $isResponsive }) => ($isResponsive ? 'initial' : 'none')};
  }

  ${PathContainer} {
    ${({ $billBottomPadding }) => $billBottomPadding && `padding-bottom: ${$billBottomPadding}`};
  }

  @media ${breakpoints.desktopSm} {
    max-width: 37.5rem;
    width: 100%;
    display: flex;
    align-items: center;

    ${PaymentBillContainer} {
      display: initial;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    ${Overlay}, ${ExpandableCardContainer} {
      display: none;
    }
  }
`;

export const BillInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const ExtandableBodyWrapper = styled.div`
  padding-top: 1rem;
`;

export const PriceInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
