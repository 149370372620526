import { FormInput, ThemedIcon, FormSelectionPopup, Globe02Icon } from '@metaswiss/ui-kit';
import { useCallback, useEffect } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';

import ControlledForm from '../../../../../../../../components/controlled-form/ControlledForm.tsx';
import { useCountries } from '../../../../../../../../hooks/use-countries/useCountries';
import { useTextTranslation } from '../../../../../../../../hooks/use-text-translation/useTextTranslation';
import { MainContentContainer } from '../../../../../../../../layouts/public-layout/styles/publicLayout.styles.ts';
import { useAuthContext } from '../../../../../../shared/auth-context/authContext.ts';
import { useSetAuthConfig } from '../../../../../../shared/auth-context/useSetAuthConfig.ts';
import { RegisterHeader } from '../../../../../shared/register-header/RegisterHeader';
import { RegistrationGridWrapper, OneLineElement } from '../../../../../shared/styles/registrationStyles';

import {
  FullCorporateRegistrationAddressFormData,
  fullRegistrationCorporateAddressSchema,
  createFullCorporateAddressSchema,
} from './corporateAddressSchema';

export const CorporateAddressView = () => {
  const { countriesAsDropdown } = useCountries();
  const { textTranslation } = useTextTranslation();
  const { setIsValid, setUserDetails, nextStep, prevStep } = useAuthContext((state) => state);

  const {
    control,
    getValues,
    formState: { errors, isValid },
    handleSubmit,
  } = useFormContext<FullCorporateRegistrationAddressFormData>();

  const setRegistrationContextState = useCallback(() => {
    setUserDetails(getValues());
  }, [getValues, setUserDetails]);

  const onAddressSubmit = useCallback(() => {
    setRegistrationContextState();
    nextStep();
  }, [nextStep, setRegistrationContextState]);

  useEffect(() => {
    setIsValid(isValid);
    return () => {
      setIsValid(true);
    };
  }, [isValid, setIsValid]);

  useEffect(() => {
    return () => {
      setRegistrationContextState();
    };
  }, [setRegistrationContextState]);

  useSetAuthConfig(
    {
      wrapperStyle: 'registrationWizard',
      containerStyle: 'registrationWizard',
      backButton: {
        onClick: () => prevStep(),
        text: 'button.back',
      },
      nextButton: {
        text: 'button.next',
        onClick: () => handleSubmit(onAddressSubmit)(),
      },
      showStepper: true,
    },
    [handleSubmit, onAddressSubmit, prevStep]
  );

  return (
    <>
      <RegisterHeader descriptionText={textTranslation('registration.corporateRegistration.corporateAddressTitle')} />
      <RegistrationGridWrapper>
        <OneLineElement>
          <FormSelectionPopup
            name={fullRegistrationCorporateAddressSchema.country}
            error={errors[fullRegistrationCorporateAddressSchema.country] as FieldError}
            control={control}
            fill
            label={textTranslation('registration.country')}
            title={textTranslation('registration.country')}
            searchText={textTranslation('registration.search')}
            renderEndIcon={() => <ThemedIcon icon={Globe02Icon} palette="primary" strokeColor="hue300" />}
            itemsList={countriesAsDropdown}
            closeButtonText={textTranslation('cancel')}
            selectButtonText={textTranslation('select')}
            containerId={MainContentContainer.styledComponentId}
          />
        </OneLineElement>
        <OneLineElement>
          <FormInput<FullCorporateRegistrationAddressFormData>
            name={fullRegistrationCorporateAddressSchema.city}
            error={errors[fullRegistrationCorporateAddressSchema.city]}
            control={control}
            fill
            label={textTranslation('registration.city')}
          />
        </OneLineElement>
        <OneLineElement>
          <FormInput<FullCorporateRegistrationAddressFormData>
            name={fullRegistrationCorporateAddressSchema.address}
            error={errors[fullRegistrationCorporateAddressSchema.address]}
            control={control}
            fill
            label={textTranslation('global.address')}
          />
        </OneLineElement>
        <OneLineElement>
          <FormInput<FullCorporateRegistrationAddressFormData>
            name={fullRegistrationCorporateAddressSchema.zipCode}
            error={errors[fullRegistrationCorporateAddressSchema.zipCode]}
            control={control}
            fill
            label={textTranslation('registration.zipCode')}
          />
        </OneLineElement>
      </RegistrationGridWrapper>
    </>
  );
};

export const CorporateAddress = () => {
  const userDetails = useAuthContext((state) => state.userDetails);

  return (
    <ControlledForm validationSchema={createFullCorporateAddressSchema} defaultValues={userDetails}>
      <CorporateAddressView />
    </ControlledForm>
  );
};
