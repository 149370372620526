import { AnimatePresence } from 'framer-motion';
import { FC } from 'react';
import { createPortal } from 'react-dom';

import { RemSize } from '../../../theme';

import { Overlay, SidePanelWrapper } from './sidePanel.styles';

export type SidePanelProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
  padding?: RemSize;
};

export const SidePanel: FC<SidePanelProps> = ({ isOpen, setIsOpen, children, padding = '0rem' }) => {
  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <Overlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <SidePanelWrapper
            initial={{ opacity: 0, right: -420 }}
            animate={{ opacity: 1, right: 0 }}
            exit={{ opacity: 0, right: -420 }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            $padding={padding}
          >
            {children}
          </SidePanelWrapper>
        </Overlay>
      )}
    </AnimatePresence>,
    document.body
  );
};
