import { FC } from 'react';

import { IconProps } from './iconProps';

export const ArrowRotateRight: FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.8216 15.0004C19.5618 18.8331 15.9539 21.6004 11.6996 21.6004C6.39768 21.6004 2.09961 17.3023 2.09961 12.0004C2.09961 6.69846 6.39768 2.40039 11.6996 2.40039C14.8094 2.40039 17.5739 3.87909 19.3283 6.17174M20.0153 7.20039C19.8081 6.84224 19.5785 6.49871 19.3283 6.17174M19.3283 6.17174L17.0996 8.40039H21.8996V3.60039L19.3283 6.17174ZM19.4996 8.00039L20.9996 6.00039"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
