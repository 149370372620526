import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

export const TotalAmountWrapper = styled.div`
  margin-top: 0.5rem;
`;
