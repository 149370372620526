import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Content = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const Image = styled.img`
  aspect-ratio: 1/1;
  height: 5.5rem;
  border-radius: 0.5rem;
`;

export const Description = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
