import styled from 'styled-components';

export const HeaderContainer = styled.div`
  padding: 0.75rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
`;

export const TransactionCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2rem;
`;

export const InfoContainer = styled.div`
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const CardWrapper = styled.div`
  width: 100%;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
