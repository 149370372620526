import { AssetResponse, PrivateUserFullResponse } from '@metaswiss/api';
import { mapIsoStringToDate } from '@metaswiss/lib';
import { ItemProps } from '@metaswiss/ui-kit';

import { AssignedUserAssetEnum } from '../../../../../enums/assignedUserAssetEnum';
import { findAssetByType } from '../../../../../shared/helpers/findAssetByType.helper';

export const parseFormObjectPrivateUser = (
  response: PrivateUserFullResponse,
  citizenship?: ItemProps,
  phoneNumberPrefixCountry?: ItemProps,
  assets?: AssetResponse[]
) => {
  const picture = assets ? findAssetByType(assets, AssignedUserAssetEnum.PICTURE) : undefined;

  return {
    firstName: response?.privateUser?.firstName,
    lastName: response?.privateUser?.lastName,
    phoneNumber: response?.phoneNumber,
    citizenship: {
      label: citizenship?.label || '',
      value: citizenship?.value || '',
      iconSrc: citizenship?.iconSrc,
      meta: citizenship?.meta,
    },
    phoneNumberPrefix: {
      value: phoneNumberPrefixCountry?.value ?? '',
      label: phoneNumberPrefixCountry?.label ?? '',
      meta: phoneNumberPrefixCountry?.meta,
      iconSrc: phoneNumberPrefixCountry?.iconSrc,
    },
    birthDate: response?.privateUser?.birthDate ? mapIsoStringToDate({ date: response.privateUser.birthDate }) : '',
    picture: { fileId: picture?.id ?? '', url: picture?.key ?? '' },
  };
};
