import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;

export const OfferingPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OfferingPopupText = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  text-align: center;
  margin: 1.5rem 0 2rem;
`;
