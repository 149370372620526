import styled from 'styled-components';

export const TextWrapper = styled.div`
  padding-right: 1rem;
  &:not(:first-child) {
    border-left: 1px solid ${({ theme }) => theme.colors.neutrals.hue100};
    padding-left: 1rem;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
