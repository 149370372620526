import styled from 'styled-components';

import { CardSizeProp } from '../types';

export const BodyContextWrapper = styled.div<{ $size: CardSizeProp }>`
  min-height: 0;
  overflow: hidden;

  & > div {
    padding-top: ${({ $size }) => ($size === 'small' ? '1.25rem' : '1rem')};
  }
`;
