import { IconProps } from './iconProps';

export const Globe02Icon: React.FC<IconProps> = ({ color, strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.2 18.35L15.6923 14.2154H13.4769L12 12.7385L14.9539 8.30769H20.1231M6.83079 4.61538L8.3972 6.18179C9.2389 7.02349 9.498 8.28581 9.05592 9.39101V9.39101C8.60403 10.5207 7.50986 11.2615 6.29311 11.2615H3.13849M21.6 12C21.6 17.3019 17.302 21.6 12 21.6C6.69809 21.6 2.40002 17.3019 2.40002 12C2.40002 6.69806 6.69809 2.39999 12 2.39999C17.302 2.39999 21.6 6.69806 21.6 12Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
