import { IconProps } from './iconProps';

export const TagIcon: React.FC<IconProps> = ({ strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3543 7.65092L16.3479 7.65089M19.5229 2.84983L13.7226 2.40365C13.2083 2.36409 12.7024 2.55122 12.3377 2.91593L2.91787 12.3358C2.2279 13.0258 2.2279 14.1444 2.91787 14.8344L9.16441 21.081C9.85439 21.7709 10.9731 21.7709 11.663 21.081L21.0829 11.6611C21.4476 11.2964 21.6347 10.7905 21.5952 10.2763L21.149 4.47591C21.0822 3.60704 20.3918 2.91667 19.5229 2.84983Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
