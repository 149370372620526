import styled from 'styled-components';

export const HeadingWrapper = styled.div`
  text-align: center;
  margin: 3.5rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const FeedbackWrapper = styled.div`
  margin: auto 0;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

export const CodeTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
