import { styled } from 'styled-components';

export const ItemsCounterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

export const CounterBar = styled.div`
  position: relative;
  width: 100%;
  height: 0.25rem;
  background-color: ${({ theme }) => theme.colors.neutrals.hue100};
  overflow: hidden;
  border-radius: ${({ theme }) => theme.dimensions.borderRadii.xxl};
`;

export const DisplayItemsBar = styled.div<{ $width: string }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${({ $width }) => $width};
  background-color: ${({ theme }) => theme.colors.primary.hue300};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 0 auto;
  gap: 1rem;
`;
