import styled, { css, keyframes } from 'styled-components';

import { TextTemplate } from '../../../../shared/typography/TextTemplate.styles';
import { RemSize } from '../../../../theme';

type AnimatedCircleProps = { circumference: number; offset: number; $color: 'success' | 'warning' | 'error' };

const progress = (offset: number, circumference: number) => keyframes`
    0% {
        stroke-dashoffset: ${circumference};
    }
    100% {
        stroke-dashoffset: ${offset};
    }
`;
export const Wrapper = styled.div<{ $size?: RemSize }>`
  position: relative;
  width: ${({ $size }) => $size || '6.25rem'};
  height: ${({ $size }) => $size || '6.25rem'};
`;

export const Container = styled.svg`
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
`;

export const Circle = styled.circle`
  fill: transparent;
  stroke: ${({ theme }) => theme.colors.neutrals.hue25};
  stroke-width: 9px;
`;

export const AnimatedCircle = styled.circle<AnimatedCircleProps>`
  fill: transparent;
  stroke: ${({ theme, $color }) => theme.v2.icon[$color]};
  stroke-width: 9px;
  stroke-linecap: round;
  stroke-dasharray: ${(props) => props.circumference};
  stroke-dashoffset: ${(props) => props.offset};
  ${(props) => css`
    animation: ${progress(props.offset, props.circumference)} 1s linear forwards;
  `}
`;

export const StyledText = styled(TextTemplate)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
`;
