import { FC } from 'react';

import { IconProps } from './iconProps';

export const UserProfileCircle: FC<IconProps> = ({ strokeColor = 'black', color = 'white' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.39844 19.1984C5.85961 18.6819 8.01948 16.3051 8.65244 16.3051H15.3448C16.262 16.3051 18.1344 18.2753 18.5984 18.9698M21.5984 11.9984C21.5984 17.3004 17.3004 21.5984 11.9984 21.5984C6.6965 21.5984 2.39844 17.3004 2.39844 11.9984C2.39844 6.6965 6.6965 2.39844 11.9984 2.39844C17.3004 2.39844 21.5984 6.6965 21.5984 11.9984ZM15.4373 9.72639C15.4373 7.89488 13.8911 6.39844 11.9987 6.39844C10.1063 6.39844 8.56017 7.89488 8.56017 9.72639C8.56017 11.5579 10.1063 13.0544 11.9987 13.0544C13.8911 13.0544 15.4373 11.5579 15.4373 9.72639Z"
        stroke={strokeColor}
        strokeWidth="1.5"
      />
    </svg>
  );
};
