import { FC } from 'react';

import { IconProps } from './iconProps';

export const NftTransactionIcon: FC<IconProps> = ({ color = 'none', strokeColor = '#222223' }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.51436 9.04693L15.5144 2.93582C15.8164 2.76803 16.1836 2.76803 16.4856 2.93582L27.4856 9.04693C27.8031 9.2233 28 9.55792 28 9.92109V22.0776C28 22.4408 27.8031 22.7754 27.4856 22.9518L16.4856 29.0629C16.1836 29.2307 15.8164 29.2307 15.5144 29.0629L4.51436 22.9518C4.19689 22.7754 4 22.4408 4 22.0776V9.92109C4 9.55792 4.19689 9.2233 4.51436 9.04693Z"
        stroke={strokeColor}
        strokeWidth="1.5"
      />
      <path
        d="M15.3333 18.6673V16.0007M17.17 13.334H15.3333V16.0007M15.3333 16.0007H17.1067M20 13.334H22.2767M24.5534 13.334H22.2767M22.2767 13.334V18.6673M12.5534 13.334V18.6673L8 13.334V18.6673"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
