import { Text } from '@metaswiss/ui-kit';
import { ProductType } from '@metaswiss/ui-kit/src/components/molecules/cards/transaction-card/enums';
import { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';

import { DescriptionWrapper, StyledOverviewGraphCard } from './overviewGraph.styles';
import { OverviewGraphCardProps } from './overviewGraphTypes';

export const OverviewGraphCard: FC<OverviewGraphCardProps> = ({ totalInvestedAmount, count, type, titleCurrency }) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();

  const portfolioInformation = useMemo(() => getPortfolioInformation(type), [type]);

  const { title, descriptionCount, color } = portfolioInformation;

  const isShowCard = useMemo(() => parseInt(count) > 0, [count]);

  return (
    <>
      {isShowCard && (
        <StyledOverviewGraphCard $color={color}>
          <Text fontWeight="semi" color={theme.v2.text.disabled}>
            {textTranslation(title)}
          </Text>
          <DescriptionWrapper>
            <Text fontWeight="bold" color={theme.v2.text.headingPrimary}>
              {totalInvestedAmount}
            </Text>
            <Text fontSize="xsm" color={theme.v2.text.headingPrimary}>
              {titleCurrency}
            </Text>
          </DescriptionWrapper>
          <DescriptionWrapper>
            <Text color={theme.v2.text.headingPrimary}>{count}</Text>
            <Text color={theme.v2.text.headingPrimary}>{textTranslation(descriptionCount)}</Text>
          </DescriptionWrapper>
        </StyledOverviewGraphCard>
      )}
    </>
  );

  function getPortfolioInformation(type: ProductType) {
    let title: string;
    let descriptionCount: string;
    let color: string;

    switch (type) {
      case ProductType.ACTIONS:
        title = 'portfolio.smartShares';
        descriptionCount = 'registration.shares';
        color = 'information';
        break;
      case ProductType.NFT:
        title = 'portfolio.smartNfts';
        descriptionCount = 'registration.nfts';
        color = 'success';
        break;
      case ProductType.BOND_PAPERS:
        title = 'portfolio.smartBonds';
        descriptionCount = 'registration.bonds';
        color = 'action2';
        break;
      default:
        title = 'portfolio.smartShares';
        descriptionCount = 'registration.shares';
        color = 'information';
    }

    return { title, descriptionCount, color };
  }
};
