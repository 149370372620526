import { FC, useState } from 'react';
import { useTheme } from 'styled-components';

import { BottomCenterIcon, InformationCircle } from '../../../iconography';
import { IconProps } from '../../../iconography/iconProps';
import { ThemedIcon } from '../../molecules/theme-icon';
import { Text } from '../text';

import { IconHolder, TipWrapper, TooltipContent, TooltipHolder, Wrapper } from './styles/tooltip.styles';

export type Props = {
  icon?: FC<IconProps>;
  description: string;
};

export const Tooltip: FC<Props> = ({ icon = InformationCircle, description }) => {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const onMouseEnter = () => {
    setIsVisible(true);
  };

  const onMouseLeave = () => {
    setIsVisible(false);
  };

  return (
    <Wrapper>
      {isVisible && (
        <TooltipHolder>
          <TooltipContent>
            <Text fontSize={'xsm'} lineHeight={'extraSmall'} color={theme.v2.text.headingPrimary}>
              {description}
            </Text>
          </TooltipContent>
          <TipWrapper>
            <ThemedIcon icon={BottomCenterIcon} customStrokeColor={theme.v2.surface.tertiary} />
          </TipWrapper>
        </TooltipHolder>
      )}
      <IconHolder onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <ThemedIcon icon={icon} customStrokeColor={theme.v2.icon.default} size={'extraSmall'} />
      </IconHolder>
    </Wrapper>
  );
};
