import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import { styled } from 'styled-components';

export const BuyCardsContainerWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 1.25rem;

  @media ${breakpoints.laptopMd} {
    margin-bottom: 0.25rem;
  }

  @media ${breakpoints.desktopSm} {
    margin-bottom: 0.5rem;
  }
`;
