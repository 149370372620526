import { routes } from '../../../../router/routes';
import { NavigationTabProps } from '../../../../shared/types/tabProps';

export const portfolioTabs: NavigationTabProps[] = [
  {
    label: 'overview',
    route: routes.portfolio.overview,
    value: 'overview',
  },
  {
    label: 'SmartNfts',
    route: routes.portfolio.smartNfts.root,
    value: 'smartNfts',
  },
  {
    label: 'smartShares',
    route: routes.portfolio.smartShares.root,
    value: 'smartShares',
  },
  {
    label: 'smartBonds',
    route: routes.portfolio.smartBonds.root,
    value: 'smartBonds',
  },
];
