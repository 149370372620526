import { BrokenSheet, Button, DataStatusPage, Text, ThemedIcon } from '@metaswiss/ui-kit';
import { useMutation } from '@tanstack/react-query';
import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { api } from '../../../../../api/msApi';
import { useHeaderOptions, useShellNavigationState } from '../../../../../global-state/zustand';
import { useDeleteUser } from '../../../../../hooks/use-delete-user/useDeleteUser';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../router/routes';

import { AdditionalInfoWrapper, ButtonsWrapper, DeleteAccountContainer } from './deleteAccount.styles';

export const DeleteAccount = () => {
  const { deleteUser } = useDeleteUser();
  const navigate = useNavigate();

  const { removeTabs } = useShellNavigationState();
  const { setHeaderTitle } = useHeaderOptions();
  const { textTranslation } = useTextTranslation();

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      return api.auth.deleteUser();
    },
    onSuccess: () => deleteUser(),
  });

  const onDeleteAccount = async () => {
    await Promise.all([mutate()]);
  };

  const handleCancelClick = () => {
    navigate(routes.account.accountManagement.root);
  };

  useLayoutEffect(() => {
    removeTabs();
    setHeaderTitle(textTranslation('account.deleteAccount'));
  }, [removeTabs, textTranslation, setHeaderTitle]);

  return (
    <DeleteAccountContainer>
      <DataStatusPage
        icon={<ThemedIcon icon={BrokenSheet} size={'full'} />}
        iconHeight={'11.25rem'}
        iconWidth={'11.25rem'}
        title={textTranslation('account.deleteAccountCheck')}
        content={textTranslation('account.deleteAccountErase')}
        additionalComponent={
          <AdditionalInfoWrapper>
            <Text fontWeight={'semi'}>{textTranslation('account.deleteAreYouSure')}</Text>
            <ButtonsWrapper>
              <Button
                variant={'outlined'}
                color={'neutral'}
                text={textTranslation('global.cancel')}
                fill={true}
                onClick={handleCancelClick}
                disabled={isLoading}
              />
              <Button
                color={'error'}
                text={textTranslation('global.delete')}
                fill
                loading={isLoading}
                onClick={onDeleteAccount}
              />
            </ButtonsWrapper>
          </AdditionalInfoWrapper>
        }
      />
    </DeleteAccountContainer>
  );
};
