import { IconProps } from './iconProps';

export const Globe04: React.FC<IconProps> = ({ color, strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.76 21.6C6.45804 21.6 2.15997 17.3019 2.15997 12C2.15997 6.69806 6.45804 2.39999 11.76 2.39999C15.7888 2.39999 19.238 4.8818  20.6622 8.39999M3.35997 15.6H6.15998C7.26454 15.6 8.15997 14.7046 8.15997 13.6V11C8.15997 9.89542 9.0554 8.99999 10.16 8.99999H12.16C13.2645 8.99999 14.16 8.10456 14.16 7V3.59999M18 15.84V15.7678M21.84 15.7565C21.84 18.2609 18 21.6 18 21.6C18 21.6 14.16 18.2609 14.16 15.7565C14.16 13.6818 15.8792 12 18 12C20.1207 12 21.84 13.6818 21.84 15.7565Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
