import { FC } from 'react';

import { IconProps } from './iconProps';

export const FileUp2: FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8016 21.6003H6.00155C4.67607 21.6003 3.60155 20.5258 3.60156 19.2003L3.60166 4.80038C3.60166 3.4749 4.67618 2.40039 6.00166 2.40039H16.8019C18.1274 2.40039 19.2019 3.47491 19.2019 4.80039V11.4004M15.6019 18.043L18.0562 15.6004M18.0562 15.6004L20.4019 17.9326M18.0562 15.6004V21.6004M7.80194 7.20039H15.0019M7.80194 10.8004H15.0019M7.80194 14.4004H11.4019"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
