import styled from 'styled-components';

import { StyledField, StyledLegend, StyledText, TextWrapper } from './otherComponentsStyles';
import {
  activeStylesTextAreaFont,
  activeTextAreaStyles,
  errorActiveStateStyles,
  errorStateStyles,
} from './textareaStateStyles';

type StyledInputProps = {
  $isActiveInput: boolean;
  $isError: boolean;
  $isIconShown?: boolean;
  $isIconEndShown?: boolean;
  $disableFocus: boolean;
  $customErrorExists?: boolean;
  $isPassword?: boolean;
  $isVisibleLabel?: boolean;
};

export const StyledLabel = styled.label`
  display: inline-block;
  position: relative;
  width: 100%;
  height: 10rem;
`;

export const StyledTextArea = styled.textarea<StyledInputProps>`
  font-size: ${({ theme }) => theme.typography.sizes.base};
  border: none;
  white-space: pre-wrap;
  box-sizing: border-box;
  width: 100%;
  padding: 0 1.25rem 1rem 1.25rem;
  border-top: 1rem solid ${({ theme }) => theme.v2.surface.field};
  resize: none;
  border-radius: ${({ theme }) => theme.dimensions.borderRadii.large};
  background-color: ${({ theme }) => theme.v2.surface.field};
  color: ${({ theme }) => theme.v2.text.headingPrimary};
  height: 100%;
  overflow-y: auto;
  text-overflow: ellipsis;
  ${({ $isActiveInput, $isVisibleLabel }) => $isActiveInput && activeStylesTextAreaFont($isVisibleLabel)}
  ${({ $isError, $customErrorExists, $isActiveInput }) =>
    ($isError || $customErrorExists) && $isActiveInput
      ? errorActiveStateStyles
      : $isError || $customErrorExists
        ? errorStateStyles
        : undefined}
    transition: padding 0.4s;

  &:disabled {
    border-color: ${({ theme, $disableFocus }) => !$disableFocus && theme.v2.border.primaryHover};
    border-top: 1rem solid ${({ theme }) => theme.v2.surface.disabled};
    background-color: ${({ theme, $disableFocus }) => $disableFocus && theme.v2.surface.disabled};
    pointer-events: none;
    color: ${({ theme }) => theme.v2.text.disabled};
    ~ ${TextWrapper} {
      ${StyledText} {
        ${({ $disableFocus }) => !$disableFocus && activeTextAreaStyles()};
        color: ${({ theme, $disableFocus }) => !$disableFocus && theme.v2.text.disabled};
      }
    }

    ~ ${StyledField} {
      border-color: ${({ theme }) => theme.v2.border.primaryHover};

      * {
        stroke: ${({ theme, $disableFocus }) =>
          !$disableFocus ? theme.v2.text.disabled : theme.v2.text.headingPrimary};
      }

      ${StyledLegend} {
        opacity: 1;
        max-width: ${({ $disableFocus }) => !$disableFocus && '100%'};
      }
    }
  }

  &:focus-visible {
    outline: none;
    cursor: text;
    font-weight: ${({ theme }) => theme.typography.weights.bold};

    ~ div {
      ${StyledText} {
        color: ${({ theme, $isError, $customErrorExists }) =>
          $isError || $customErrorExists ? theme.v2.text.error : theme.v2.text.information};
        ${({ $isVisibleLabel }) => activeTextAreaStyles($isVisibleLabel)};
      }
    }

    ~ ${StyledField} {
      border: 1px solid
        ${({ theme, $isError, $customErrorExists }) =>
          !$isError && !$customErrorExists ? theme.v2.border.information : theme.v2.border.error};

      ${StyledLegend} {
        max-width: ${({ $isVisibleLabel }) => ($isVisibleLabel ? '100%' : '0')};
        opacity: ${({ $isVisibleLabel }) => ($isVisibleLabel ? '1' : '0')};
      }
    }
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.v2.surface.field} inset;
    -webkit-text-fill-color: ${({ theme }) => theme.v2.text.headingPrimary};

    ~ div {
      ${StyledText} {
        color: ${({ theme, $isError, $customErrorExists }) =>
          $isError || $customErrorExists ? theme.v2.text.error : theme.v2.text.information};
        ${activeTextAreaStyles()}
      }
    }

    ~ ${StyledField} {
      background: transparent;
      border: 1px solid
        ${({ theme, $isError, $customErrorExists }) => !$isError && !$customErrorExists && theme.v2.border.primary};

      ${StyledLegend} {
        max-width: 100%;
        opacity: 1;
      }
    }
  }
`;

export const StyledError = styled.span`
  top: 100%;
  position: absolute;
  left: 0;
  color: ${({ theme }) => theme.v2.text.error};
  font-weight: ${({ theme }) => theme.typography.weights.reg};
  font-size: ${({ theme }) => theme.typography.sizes.xsm};
  margin-top: 0.25rem;
  margin-left: 1rem;
`;
