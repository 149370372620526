import styled from 'styled-components';

import { TextTemplate } from '../../../../shared/typography/TextTemplate.styles';
import { uploadFileDeleteIconContainerStyles } from '../../upload-file/styles/uploadFile';

export const UploadedFileStyled = styled.div<{
  $width: string;
  isDisabled?: boolean;
  pointer?: boolean;
}>`
  padding: 1rem;
  width: ${({ $width }) => $width};
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid ${({ theme }) => theme.v2.border.primaryHover};
  border-radius: ${({ theme }) => theme.v2.componentsRadius.inputs.large};
  background: ${({ theme, isDisabled }) => (isDisabled ? theme.v2.surface.disabled : theme.v2.surface.primary)};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
  height: 4.75rem;

  &:hover &:focus {
    background: ${({ theme }) => theme.v2.surface.primary};
  }
`;

export const UploadedRejectedFileStyled = styled(UploadedFileStyled)`
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  height: 12.5rem;
  border: 0;

  &:hover &:focus {
    background: ${({ theme }) => theme.v2.surface.primary};
  }
`;

export const IconWrapper = styled.div<{ $width?: string; $height?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const IconRejectedWrapper = styled(IconWrapper)`
  width: 4rem;
  height: 4rem;
`;

export const IconRejectedCloseWrapper = styled(IconWrapper)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;
export const TextWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${TextTemplate}:first-child {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const TextRejectedWrapper = styled(TextWrapper)`
  align-items: center;
`;
export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const DeleteIconContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  ${uploadFileDeleteIconContainerStyles}
`;
