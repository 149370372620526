import {
  ErrorToast,
  ErrorToastV2,
  SuccessToast,
  SuccessToastV2,
  WarningToast,
  WarningToastV2,
} from '@metaswiss/ui-kit';
import uniqueId from 'lodash/uniqueId';
import { createContext, useCallback, useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import { styled } from 'styled-components';

import { ToastProps, ToastQueue, ToastQueueProviderProps, ToastType } from './toastQueue.types';

export const ToastQueueContext = createContext({} as ToastQueue);

// eslint-disable-next-line react-refresh/only-export-components
export const useToastQueueContext = () => useContext(ToastQueueContext);

export const ToastQueueProvider = ({ children, version = 'v1' }: ToastQueueProviderProps) => {
  const [queue, setQueue] = useState<ToastProps[]>([]);

  const enqueue = (type: ToastType, message: string, heading: string) => {
    const toast = { id: uniqueId(), type, message, heading };

    if (queue.length < 3) {
      setQueue((toasts) => [toast, ...toasts]);
    }
  };

  const dequeue = (id: string) => {
    setQueue((queue) => queue.filter((toast) => toast.id !== id));
  };

  const clearQueue = useCallback(() => {
    setQueue([]);
  }, []);

  const renderToast = (toast: ToastProps) => {
    let ToastComponent;

    switch (toast.type) {
      case ToastType.ERROR:
        ToastComponent = version === 'v1' ? ErrorToast : ErrorToastV2;
        break;
      case ToastType.WARNING:
        ToastComponent = version === 'v1' ? WarningToast : WarningToastV2;
        break;
      case ToastType.SUCCESS:
        ToastComponent = version === 'v1' ? SuccessToast : SuccessToastV2;
        break;
      default:
        throw new Error('The toast you selected does not exist');
    }

    return (
      <ToastComponent
        key={toast.id}
        heading={toast.heading}
        message={toast.message}
        id={toast.id}
        clearToast={dequeue}
      />
    );
  };

  return (
    <ToastQueueContext.Provider value={{ enqueue, dequeue, queue, clearQueue }}>
      {children}
      {createPortal(<ToastsContainer>{queue.map((toast) => renderToast(toast))}</ToastsContainer>, document.body)}
    </ToastQueueContext.Provider>
  );
};

const ToastsContainer = styled.div`
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  top: 0;
  right: 0;
`;
