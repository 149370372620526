import { AssetResponse } from '@metaswiss/api';
import { AlertIcon, AlertTriangleIcon, PageStateContainer, Status } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import { api } from '../../../../api/msApi';
import { AssignedUserAssetEnum } from '../../../../enums/assignedUserAssetEnum';
import { DocumentStatus } from '../../../../enums/documentStatus.enum';
import { ApiResource } from '../../../../enums/resource.enum';
import { UserRole } from '../../../../enums/userRole';
import { UserStatus } from '../../../../enums/userStatus.enum';
import { AppState, useAppState } from '../../../../global-state/zustand';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { defaultUser } from '../../../../shared/helpers/defaultUser';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';

import { CorporateVerification } from './CorporateVerification';
import { PrivateVerification } from './PrivateVerification';
import { CommercialRegister } from './commercial-register/CommercialRegister';
import { DomicileProof } from './domicile-proof/DomicileProof';
import { IdentityProof } from './identity-proof/IdentityProof';
import { Signature } from './signature/Signature';

type DocumentsStatusesType = { [key: string]: DocumentStatus };

export const Verification = () => {
  const user = useAppState((state: AppState) => state.user) || defaultUser;

  const { textTranslation } = useTextTranslation();

  const [isDomicileModalOpen, setIsDomicileModalOpen] = useState<boolean>(false);
  const [isCommercialRegisterModalOpen, setIsCommercialRegisterModalOpen] = useState<boolean>(false);
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState<boolean>(false);
  const [isIdentityModalOpen, setIsIdentityModalOpen] = useState<boolean>(false);

  const { data: assets, isLoading } = useQuery({
    queryKey: getQueryKey(ApiResource.ASSET, user.id),
    queryFn: () => {
      return user?.role === UserRole.PRIVATE
        ? api.users.getPrivateUserAssets(user.id)
        : api.users.getCorporateUserAssets(user.id);
    },
    enabled: !!user?.role && !!user?.id,
  });

  const documentsStatuses = useMemo(() => {
    const documentsStatuses: DocumentsStatusesType = {};

    assets?.forEach((asset: AssetResponse) => {
      documentsStatuses[asset.type] =
        user?.status === UserStatus.APPROVED ? DocumentStatus.VERIFIED : DocumentStatus.SUBMITTED;
    });

    if (user.role === UserRole.PRIVATE) {
      const identityProofStatus =
        (documentsStatuses[AssignedUserAssetEnum.ID_FRONT] && documentsStatuses[AssignedUserAssetEnum.ID_BACK]) ||
        documentsStatuses[AssignedUserAssetEnum.PASSPORT] ||
        DocumentStatus.NOT_VERIFIED;

      const proofOfDomicileStatus =
        documentsStatuses[AssignedUserAssetEnum.PROOF_OF_DOMICILE] || DocumentStatus.NOT_VERIFIED;

      const areDocumentsInReview =
        identityProofStatus === DocumentStatus.SUBMITTED && proofOfDomicileStatus === DocumentStatus.SUBMITTED;

      const hasDocuments =
        identityProofStatus !== DocumentStatus.NOT_VERIFIED && proofOfDomicileStatus !== DocumentStatus.NOT_VERIFIED;

      return {
        identityProofStatus,
        proofOfDomicileStatus,
        areDocumentsInReview,
        hasDocuments,
      };
    }

    if (user.role === UserRole.CORPORATE) {
      const commercialRegisterStatus =
        documentsStatuses[AssignedUserAssetEnum.COMMERCIAL_REGISTER] || DocumentStatus.NOT_VERIFIED;

      const signatureStatus = documentsStatuses[AssignedUserAssetEnum.SIGNATURE] || DocumentStatus.NOT_VERIFIED;

      const proofOfDomicileStatus =
        documentsStatuses[AssignedUserAssetEnum.PROOF_OF_DOMICILE] || DocumentStatus.NOT_VERIFIED;

      const areDocumentsInReview =
        commercialRegisterStatus === DocumentStatus.SUBMITTED &&
        signatureStatus === DocumentStatus.SUBMITTED &&
        proofOfDomicileStatus === DocumentStatus.SUBMITTED;

      const hasDocuments =
        commercialRegisterStatus !== DocumentStatus.NOT_VERIFIED &&
        proofOfDomicileStatus !== DocumentStatus.NOT_VERIFIED &&
        signatureStatus !== DocumentStatus.NOT_VERIFIED;

      return {
        commercialRegisterStatus,
        signatureStatus,
        proofOfDomicileStatus,
        areDocumentsInReview,
        hasDocuments,
      };
    }

    return {};
  }, [assets, user.role, user.status]);

  const closeDomicileProofModal = () => {
    setIsDomicileModalOpen(false);
  };

  const closeIdentityProofModal = () => {
    setIsIdentityModalOpen(false);
  };

  const closeCommercialRegisterModal = () => {
    setIsCommercialRegisterModalOpen(false);
  };

  const closeSignatureModal = () => {
    setIsSignatureModalOpen(false);
  };

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={isLoading}
      showEmptyState={false}
      showError={false}
      textTranslation={textTranslation}
      maxWidth="44.5rem"
    >
      {!documentsStatuses?.hasDocuments && (
        <Status status="error" text={textTranslation('account.missingDocuments')} icon={AlertIcon} />
      )}
      {documentsStatuses.areDocumentsInReview && (
        <Status status="inReview" text={textTranslation('account.needToReview')} icon={AlertTriangleIcon} />
      )}
      {user.role === UserRole.PRIVATE && (
        <>
          <PrivateVerification
            proofOfDomicileStatus={documentsStatuses.proofOfDomicileStatus as DocumentStatus}
            identityProofStatus={documentsStatuses.identityProofStatus as DocumentStatus}
            setIsDomicileModalOpen={setIsDomicileModalOpen}
            setIsIdentityModalOpen={setIsIdentityModalOpen}
          />
          <DomicileProof isActive={isDomicileModalOpen} closeModal={closeDomicileProofModal} />
          <IdentityProof isActive={isIdentityModalOpen} closeModal={closeIdentityProofModal} />
        </>
      )}
      {user.role === UserRole.CORPORATE && (
        <>
          <CorporateVerification
            proofOfDomicileStatus={documentsStatuses.proofOfDomicileStatus as DocumentStatus}
            signatureStatus={documentsStatuses.signatureStatus as DocumentStatus}
            commercialRegisterStatus={documentsStatuses.commercialRegisterStatus as DocumentStatus}
            setIsCommercialRegisterModalOpen={setIsCommercialRegisterModalOpen}
            setIsDomicileModalOpen={setIsDomicileModalOpen}
            setIsSignatureModalOpen={setIsSignatureModalOpen}
          />
          <DomicileProof isActive={isDomicileModalOpen} closeModal={closeDomicileProofModal} />
          <CommercialRegister isActive={isCommercialRegisterModalOpen} closeModal={closeCommercialRegisterModal} />
          <Signature isActive={isSignatureModalOpen} closeModal={closeSignatureModal} />
        </>
      )}
    </PageStateContainer>
  );
};
