import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import { HeaderContainer } from '@metaswiss/ui-kit/src/components/molecules/header/header.styles';
import { StyledBottomNavigationBar } from '@metaswiss/ui-kit/src/components/organism/bottom-navigation-bar/bottomNavigationBar.styles';
import { StyledSidebar } from '@metaswiss/ui-kit/src/components/organism/side-bar/sideBar.styles';
import styled from 'styled-components';

type Props = {
  $isSidebarVisible: boolean;
  $isHeaderVisible: boolean;
};

export const ProtectedLayoutContainer = styled.div<Props>`
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'Header Header'
    'Content Content';
  ${StyledBottomNavigationBar} {
    display: block;
  }
  ${StyledSidebar} {
    display: none;
  }

  @media ${breakpoints.laptopSm} {
    grid-template-columns: 6.5rem 1fr;
    gap: 0px;
    grid-template-areas:
      'Sidebar Header'
      'Sidebar Content';
    height: 100vh;
    width: 100%;
    overflow: hidden;
    ${StyledBottomNavigationBar} {
      display: none;
    }
    ${StyledSidebar} {
      display: block;
    }
  }

  @media ${breakpoints.desktopSm} {
    grid-template-columns: 17.5rem 1fr;
  }

  ${({ $isSidebarVisible }) =>
    !$isSidebarVisible &&
    `
    grid-template-areas:
      'Header Header'
      'Content Content';
    ${StyledSidebar} {
      display: none;
    }
    ${StyledBottomNavigationBar} {
      display: none;
    }
    grid-template-columns: 1fr;
    @media ${breakpoints.laptopSm} {
      grid-template-columns: 1fr;
    }
  `}
  ${({ $isHeaderVisible }) =>
    !$isHeaderVisible &&
    `
    grid-template-areas:
      'Sidebar Content'
      'Sidebar Content';
      ${HeaderContainer} {
        display: none;
      }
  `}

  ${({ $isSidebarVisible, $isHeaderVisible }) =>
    !$isSidebarVisible &&
    !$isHeaderVisible &&
    `
    grid-template-columns:1fr;
    grid-template-areas:
      'Content Content'
      'Content Content';
    @media ${breakpoints.laptopSm} {
      grid-template-columns:1fr;
      grid-template-areas:
      'Content Content'
      'Content Content';
    }
  `}
`;

export const HeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
