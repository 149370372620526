import { IconProps } from './iconProps';

export const CurrencyBitcoinIcon: React.FC<IconProps> = ({ strokeColor = 'black' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.39999 8.01917H13.7847C14.3203 8.01917 14.8339 8.23192 15.2126 8.6106C15.5912 8.98929 15.804 9.5029 15.804 10.0384C15.804 10.574 15.5912 11.0876 15.2126 11.4663C14.8339 11.845 14.3203 12.0577 13.7847 12.0577M13.7847 12.0577C14.3203 12.0577 14.8339 12.2705 15.2126 12.6491C15.5912 13.0278 15.804 13.5414 15.804 14.077C15.804 14.6125 15.5912 15.1261 15.2126 15.5048C14.8339 15.8835 14.3203 16.0963 13.7847 16.0963H8.39999M13.7847 12.0577H9.74617M9.74617 8.01917V16.0963M10.4193 5.9999V8.01917M13.1116 5.9999V8.01917M10.4193 16.0963V18.1155M13.1116 16.0963V18.1155M21.6 11.9999C21.6 17.3018 17.3019 21.5999 12 21.5999C6.69806 21.5999 2.39999 17.3018 2.39999 11.9999C2.39999 6.69797 6.69806 2.3999 12 2.3999C17.3019 2.3999 21.6 6.69797 21.6 11.9999Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
