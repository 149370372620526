import { FC } from 'react';

import { IconProps } from './iconProps';

export const CalendarCheck: FC<IconProps> = ({ color = 'none ', strokeColor = '#3381FF' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.625 8.46875H20.375M6.66071 2V3.68771M18.125 2V3.6875M18.125 3.6875H6.875C5.01104 3.6875 3.5 5.19854 3.5 7.0625V18.3126C3.5 20.1766 5.01104 21.6876 6.875 21.6876H18.125C19.989 21.6876 21.5 20.1766 21.5 18.3126L21.5 7.0625C21.5 5.19854 19.989 3.6875 18.125 3.6875ZM9.6875 15.2188L11.375 16.9063L15.3125 12.9688"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
