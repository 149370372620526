import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import { styled } from 'styled-components';

export const DesktopContainer = styled.div`
  display: none;
  width: 100%;
  @media ${breakpoints.laptopMd} {
    display: flex;
  }
`;

export const TabletContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${breakpoints.laptopMd} {
    display: none;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  max-width: 49.5rem;
  flex: 1;
  border-right: ${({ theme }) => `1px solid ${theme.v2.border.primary}`};
`;

export const LeftContainerContent = styled.div`
  display: flex;
  margin-right: 1.5rem;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const RightContainer = styled.div`
  display: flex;
  width: 25rem;
  flex-direction: column;
  margin-left: 1.5rem;
  gap: 1rem;

  @media ${breakpoints.laptopMd} {
    gap: 1.25rem;
  }

  @media ${breakpoints.desktopSm} {
    gap: 1.5rem;
  }
`;

export const PosterContainerDesktop = styled.img`
  display: flex;
  height: 25rem;
  width: 25rem;
  border-radius: 0.75rem;
`;

export const PosterContainerTablet = styled.img`
  display: flex;
  height: 8.75rem;
  width: 100%;
  border-radius: 0.75rem;
  margin-bottom: 1rem;
`;
