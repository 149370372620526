import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import styled from 'styled-components';

export const BondCardWrapper = styled.div`
  margin: auto;
  width: 100%;

  @media ${breakpoints.laptopSm} {
    max-width: 38.25rem;
  }
`;
