import { useLayoutEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { TransactionType } from '../../../../../enums/transactionType';
import { useHeaderOptions, useShellNavigationState } from '../../../../../global-state/zustand';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../router/routes';

import { SingleBond } from './single-bond/SingleBond';
import { SingleNft } from './single-nft/SingleNft';
import { SingleShare } from './single-share/SingleShare';

export const SingleTransaction = () => {
  const navigate = useNavigate();

  const { transactionType } = useParams();
  const { removeTabs } = useShellNavigationState();
  const { setHeaderTitle } = useHeaderOptions();
  const { textTranslation } = useTextTranslation();

  useLayoutEffect(() => {
    removeTabs();
    setHeaderTitle(textTranslation('account.transactionDetails'));
  }, [removeTabs, textTranslation, setHeaderTitle]);

  switch (transactionType) {
    case TransactionType.SHARE:
      return <SingleShare />;
    case TransactionType.BOND:
      return <SingleBond />;
    case TransactionType.NFT:
      return <SingleNft />;
    default:
      navigate(routes.pageNotFound);
  }
};
