import { IconProps } from './iconProps';

export const LockIcon: React.FC<IconProps> = ({ color = 'none', strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 18 22" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.6 7.7999V6.88562C3.6 3.84671 6.00857 1.3999 9 1.3999C11.9914 1.3999 14.4 3.84671 14.4 6.88562V7.7999M3.6 7.7999C2.61 7.7999 1.8 8.62276 1.8 9.62847V18.7713C1.8 19.777 2.61 20.5999 3.6 20.5999H14.4C15.39 20.5999 16.2 19.777 16.2 18.7713V9.62847C16.2 8.62276 15.39 7.7999 14.4 7.7999M3.6 7.7999H14.4"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
