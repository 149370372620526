import { styled } from 'styled-components';

export const SectionHeaderContainer = styled.div`
  width: 100%;
`;

export const SmallChipWrapper = styled.div`
  flex-shrink: 0;
`;

export const SectionHeaderLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.v2.border.primary};
`;
