import { IconProps } from './iconProps';

export const CloseIcon: React.FC<IconProps> = ({ color, strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 25 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3512 9.3129C16.7417 8.92237 16.7417 8.28921 16.3512 7.89868C15.9607 7.50816 15.3275 7.50816 14.937 7.89868L16.3512 9.3129ZM8.14877 14.6869C7.75825 15.0774 7.75825 15.7106 8.14877 16.1011C8.5393 16.4916 9.17246 16.4916 9.56299 16.1011L8.14877 14.6869ZM14.937 16.1011C15.3275 16.4916 15.9607 16.4916 16.3512 16.1011C16.7417 15.7106 16.7417 15.0774 16.3512 14.6869L14.937 16.1011ZM9.56299 7.89868C9.17246 7.50816 8.5393 7.50816 8.14877 7.89868C7.75825 8.28921 7.75825 8.92237 8.14877 9.3129L9.56299 7.89868ZM20.85 11.9999C20.85 16.7496 16.9996 20.5999 12.25 20.5999V22.5999C18.1042 22.5999 22.85 17.8541 22.85 11.9999H20.85ZM12.25 20.5999C7.50034 20.5999 3.64999 16.7496 3.64999 11.9999H1.64999C1.64999 17.8541 6.39578 22.5999 12.25 22.5999V20.5999ZM3.64999 11.9999C3.64999 7.25025 7.50034 3.3999 12.25 3.3999V1.3999C6.39578 1.3999 1.64999 6.14568 1.64999 11.9999H3.64999ZM12.25 3.3999C16.9996 3.3999 20.85 7.25025 20.85 11.9999H22.85C22.85 6.14568 18.1042 1.3999 12.25 1.3999V3.3999ZM14.937 7.89868L11.5429 11.2928L12.9571 12.707L16.3512 9.3129L14.937 7.89868ZM11.5429 11.2928L8.14877 14.6869L9.56299 16.1011L12.9571 12.707L11.5429 11.2928ZM16.3512 14.6869L12.9571 11.2928L11.5429 12.707L14.937 16.1011L16.3512 14.6869ZM12.9571 11.2928L9.56299 7.89868L8.14877 9.3129L11.5429 12.707L12.9571 11.2928Z"
        fill={strokeColor}
      />
    </svg>
  );
};
