/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageableItems } from '../models/PageableItems';
import type { TransactionFullResponseDTO } from '../models/TransactionFullResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientTransactionApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param offset
   * @param limit
   * @param page
   * @param companyName
   * @param status
   * @param type
   * @param payment
   * @returns any
   * @throws ApiError
   */
  public getClientTransactions(
    offset?: number,
    limit?: number,
    page?: number,
    companyName?: string,
    status?: Array<'PENDING' | 'VALIDATING' | 'ACCEPTED' | 'CANCELLED' | 'FAILED' | 'STRIPE_PENDING' | 'STRIPE_CANCELLED'>,
    type?: Array<'BOND_PAPERS' | 'ACTIONS' | 'NFT'>,
    payment?: Array<'STRIPE' | 'BANK' | 'CRYPTO'>,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/client/transactions',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'companyName': companyName,
        'status': status,
        'type': type,
        'payment': payment,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns TransactionFullResponseDTO
   * @throws ApiError
   */
  public getClientTransactionById(
    id: string,
  ): CancelablePromise<TransactionFullResponseDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/client/transactions/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
