import { NotificationType } from '../../enums/notificationType';

export type NotificationPopupProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export type NotificationDetailsProps = {
  title: string;
  content: string;
  date: string;
  slideDetails: boolean;
  wrapperWidth: number;
};

export type NotificationItemResponse = {
  id: string;
  title: string;
  createdAt: string;
  content: string;
  isRead?: boolean;
  type: NotificationType;
};

export type SelectedNotificationItemProps = Pick<NotificationItemResponse, 'createdAt' | 'content' | 'title' | 'id'>;

export type NotificationPopup = {
  $isOpen: boolean;
  $showNotification: boolean;
};

export const initialSelectedNotificationItemState: SelectedNotificationItemProps = {
  id: '',
  createdAt: '',
  content: '',
  title: '',
};
