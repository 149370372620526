import { CaseResponse } from '@metaswiss/api';
import { mapIsoStringToDate } from '@metaswiss/lib';
import { CaseCard, FlatList, PageStateContainer } from '@metaswiss/ui-kit';
import { generatePath, useNavigate } from 'react-router-dom';

import { api } from '../../../../api/msApi';
import { NumberOfItems } from '../../../../components/number-of-items/NumberOfItems.tsx';
import { ApiResource } from '../../../../enums/resource.enum';
import { usePagination } from '../../../../hooks/use-pagination/usePagination';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../router/routes.ts';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';
import { statusTranslationMapper } from '../../../../shared/mappers/statusTranslation.mapper';
import { statusThemeMapper } from '../shared/mappers/statusTheme.mapper';

export const ActiveCases = () => {
  const { textTranslation } = useTextTranslation();
  const navigate = useNavigate();

  const { data, lastItemRef, isError, isLoading, isFetchingNextPage, refetch, totalItems } =
    usePagination<CaseResponse>({
      queryKey: getQueryKey(ApiResource.CASE, 'active'),
      queryFn: (offset: number, limit: number) => {
        return api.case.getActiveCasesByUser(offset, limit);
      },
      enabled: true,
    });

  const isEmpty = !isLoading && data?.length === 0;
  const isErrorState = isError && data?.length !== 0;

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={isLoading}
      showEmptyState={true}
      showError={true}
      onTryAgain={refetch}
      isError={isErrorState}
      emptyStateContent={'cases.noCasesActive'}
      emptyStateTitle={'cases.nothingHereYet'}
      isEmptyState={isEmpty}
      textTranslation={textTranslation}
    >
      <FlatList
        data={data ?? []}
        keyExtractor={(item) => item.id}
        isFetching={isFetchingNextPage}
        ref={lastItemRef}
        headerComponent={<NumberOfItems numberOfAssets={totalItems} />}
        renderItem={(item, index) => (
          <CaseCard
            title={`${index + 1}. ` + item.name}
            date={mapIsoStringToDate({ date: item.createdAt })}
            description={item.description}
            chipText={statusTranslationMapper(item.status, textTranslation)}
            status={statusThemeMapper(item.status)}
            onClick={() =>
              navigate(
                generatePath(routes.cases.active.caseDetails, {
                  id: item.id,
                })
              )
            }
          />
        )}
      />
    </PageStateContainer>
  );
};
