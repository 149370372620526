import { styled } from 'styled-components';

import { BodyContainer } from '../selectable-card/styles/bodyContainer';

export const ExpandableCardContainer = styled.div<{
  $isCardExpanded: boolean;
}>`
  position: absolute;
  width: 100%;
  left: 0;
  // TODO: this is quick fix for now, we need to find better solution
  // max-height: 80%;
  bottom: 0;
  z-index: ${({ $isCardExpanded }) => ($isCardExpanded ? '150' : '1')};
  background-color: ${({ theme }) => theme.v2.surface.primary};
  border-top-right-radius: ${({ theme }) => theme.v2.radius.medium};
  border-top-left-radius: ${({ theme }) => theme.v2.radius.medium};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
`;

export const VisibleContainer = styled.div`
  padding: 1rem 1rem 0 1rem;
  position: relative;
  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const ContentSeparator = styled.div`
  margin-top: 1rem;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.v2.border.primary};
`;

export const IconContainer = styled.div<{ $isCardExpanded: boolean }>`
  position: absolute;
  top: 1rem;
  right: 1rem;
  transform: rotate(${({ $isCardExpanded }) => ($isCardExpanded ? '180deg' : '0')});
  transition: transform 0.15s;
`;

export const ExpandableContainer = styled(BodyContainer)`
  transition: all 0.15s ease-in;
  padding: 0 1rem;
`;

export const ExpandableContextWrapper = styled.div`
  min-height: 0;
  overflow: auto;
  max-height: 21.5rem;
`;

export const ButtonWrapper = styled.div`
  padding: 1rem;
`;
