import { CompanyDocumentResponse } from '@metaswiss/api';
import { Document, Text } from '@metaswiss/ui-kit';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { DocumentState } from '../../../../../../enums/documentState';
import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';

import { DocumentsHeader, DocumentsWrapper } from './documents.styles';

export const Documents: FC<{ documents: CompanyDocumentResponse[] }> = ({ documents }) => {
  const { textTranslation } = useTextTranslation();
  const theme = useTheme();
  return (
    <DocumentsWrapper>
      <DocumentsHeader>
        <Text fontSize={'lg'} fontWeight={'bold'} lineHeight="h4" color={theme.v2.text.headingPrimary}>
          {textTranslation('home.documents')}
        </Text>
      </DocumentsHeader>
      {documents.map((document) => {
        return (
          <Document
            key={document.title + document.createdAt}
            text={document.title}
            onClick={() => window.open(document.document.url, '_blank')}
            status={DocumentState.COMPLETED}
          />
        );
      })}
    </DocumentsWrapper>
  );
};
