import styled from 'styled-components';

import { RemSize } from '../../../../theme';

export const IconWrapper = styled.div<{ $iconWidth?: RemSize; $iconHeight?: RemSize }>`
  width: ${({ $iconWidth }) => $iconWidth || '100%'};
  height: ${({ $iconHeight }) => $iconHeight || '100%'};
  margin-bottom: 2rem;
`;

export const ButtonWrapper = styled.div`
  width: 20rem;
  margin-top: 3rem;
`;
