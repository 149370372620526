import * as zod from 'zod';

import { FormNames } from '../../../../../shared/forms/formNames';

export const createIdCardSchema = () =>
  zod.object({
    frontSide: zod.object({ fileId: zod.string().optional(), url: zod.string().optional() }),
    backSide: zod.object({ fileId: zod.string().optional(), url: zod.string().optional() }),
  });

export const idSchema = createIdCardSchema();

export type IdCardFormData = zod.infer<typeof idSchema>;

export const idCardSchema: FormNames<IdCardFormData> = {
  frontSide: 'frontSide',
  backSide: 'backSide',
} as const;

export const createPassportSchema = () =>
  zod.object({
    frontSide: zod.object({ fileId: zod.string().optional(), url: zod.string().optional() }),
  });

export const schema = createPassportSchema();

export type PassportFormData = zod.infer<typeof schema>;

export const passportSchema: FormNames<PassportFormData> = {
  frontSide: 'frontSide',
} as const;
