import { FC } from 'react';

import { IconProps } from './iconProps';

export const FileIcon05: FC<IconProps> = ({ strokeColor }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.0006 4V10C25.0006 11.1046 25.8961 12 27.0006 12H33.0006M14.0006 12H18.0006M14.0006 18H26.0006M14.0006 24H26.0006M30.0006 7C29.1105 6.20357 28.1868 5.25896 27.6037 4.64546C27.2157 4.23722 26.6796 4 26.1163 4H11.0002C8.79103 4 7.00017 5.79085 7.00015 7.99998L7 31.9999C6.99999 34.209 8.79084 35.9999 11 35.9999L29.0001 36C31.2092 36 33 34.2092 33.0001 32.0001L33.0006 10.7964C33.0006 10.285 32.8055 9.79339 32.4506 9.42522C31.7943 8.74441 30.6983 7.62419 30.0006 7Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
