import { getDaysInMonth, set, parse, format } from 'date-fns';

export const getDaysInMonthFromString = (month: string, year = new Date().getFullYear()): number => {
  const monthIndex = parse(month, 'MMMM', new Date()).getMonth();
  const date = new Date(year, monthIndex, 1);
  const lastDayOfMonth = set(date, { month: monthIndex });

  return getDaysInMonth(lastDayOfMonth);
};

export const getMonthNameFromNumber = (month: string): string => {
  const date = new Date(2000, parseInt(month, 10) - 1);
  return format(date, 'MMMM');
};
