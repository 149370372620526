import { styled } from 'styled-components';

type Props = {
  $radius: string;
  $size: string;
  $isCursorPointer: boolean;
};

export const Container = styled.div<Props>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ $isCursorPointer }) => ($isCursorPointer ? 'pointer' : 'auto')};
  border-radius: ${({ $radius }) => $radius};
  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};
`;
