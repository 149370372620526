import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: fit-content;
`;

export const UpperRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  @media ${breakpoints.laptopSm} {
    flex-direction: row;
    gap: 1rem;
  }
`;

export const BenefitsWrapper = styled.div`
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4.5rem;

  @media ${breakpoints.laptopSm} {
    margin-bottom: 0;
  }
`;

export const BenefitsContainer = styled.div`
  height: fit-content;
  width: 100%;
  max-width: 77.5rem;
`;

export const LeftContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 33.75rem;
  gap: 1rem;
`;

export const RightContentWrapper = styled.div<{ $hasMarginBottom?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 18.75rem;
  margin-bottom: ${({ $hasMarginBottom }) => ($hasMarginBottom ? '3.5rem' : '0')};

  @media ${breakpoints.laptopSm} {
    margin-bottom: 0;
  }
`;

export const LowerRowWrapper = styled.div`
  width: inherit;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;

  @media ${breakpoints.laptopSm} {
    flex-direction: row;
    height: 26rem;
    padding-bottom: 0;
  }
`;
