import { CurrencyResponse } from '@metaswiss/api';
import {
  CurrencyChfIcon,
  CurrencyDollarIcon,
  CurrencyEuroIcon,
  ItemWithCustomIconProps,
  IconProps,
} from '@metaswiss/ui-kit';
import { FC } from 'react';

export const mapCurrencyResponse = (currencies: CurrencyResponse[]): ItemWithCustomIconProps[] => {
  return currencies.map((currency) => {
    return {
      label: currency.currencyCode,
      value: currency.currencyCode,
      iconSrc: mapIcon(currency),
    };
  });
};

export const mapIcon = (currency: CurrencyResponse): FC<IconProps> => {
  switch (currency.currencyCode) {
    case 'CHF':
      return CurrencyChfIcon;
    case 'USD':
      return CurrencyDollarIcon;
    case 'EUR':
      return CurrencyEuroIcon;
    default:
      return CurrencyChfIcon;
  }
};
