import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form';
import styled from 'styled-components';

import { CodeInput, Props } from '../../atoms/code-input/CodeInput';

export type FormCodeInputProps<T extends FieldValues> = {
  name: Path<T>;
  error?: FieldError | undefined;
  control: Control<T>;
  fill: boolean;
  onComplete: () => void;
} & Omit<Props, 'name' | 'error' | 'onChange' | 'value' | 'onCodeFilled'>;

export const StyledContainer = styled.div<{ $fill?: boolean }>`
  max-width: ${({ $fill }) => ($fill ? '100%' : '450px')};
  width: 100%;
  position: relative;
`;
export const FormCodeInput = <T extends FieldValues>({
  name,
  error,
  control,
  fill,
  onComplete,
  ...props
}: FormCodeInputProps<T>) => {
  return (
    <StyledContainer $fill={fill}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <CodeInput
              onComplete={onComplete}
              onCodeFilled={onChange as (value: string) => void}
              errorMessage={error?.message}
              {...props}
            />
          );
        }}
      />
    </StyledContainer>
  );
};
