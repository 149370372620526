import { RegistrationLength } from '../../../../../enums/registrationLength';
import { UserRole } from '../../../../../enums/userRole';
import { routes } from '../../../../../router/routes';
import {
  FullPrivateRegistrationSteps,
  QuickPrivateRegistrationSteps,
  QuickCorporateRegistrationSteps,
  FullCorporateRegistrationSteps,
} from '../RegistrationSteps';

export type Step = {
  component: React.ReactElement;
  formId?: string;
};

export function getWizardStepsHelper(locationPath: string, redirect: () => void): Step[] {
  let wizardSteps: Step[] = [];

  switch (locationPath) {
    case `${routes.register.root}/${UserRole.PRIVATE}/${RegistrationLength.FULL}`:
      wizardSteps = FullPrivateRegistrationSteps;
      break;
    case `${routes.register.root}/${UserRole.CORPORATE}/${RegistrationLength.FULL}`:
      wizardSteps = FullCorporateRegistrationSteps;
      break;
    case `${routes.register.root}/${UserRole.PRIVATE}/${RegistrationLength.QUICK}`:
      wizardSteps = QuickPrivateRegistrationSteps;
      break;
    case `${routes.register.root}/${UserRole.CORPORATE}/${RegistrationLength.QUICK}`:
      wizardSteps = QuickCorporateRegistrationSteps;
      break;
    default:
      redirect();
  }
  return wizardSteps;
}
