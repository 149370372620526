import * as zod from 'zod';

import { FormNames } from '../../../../../shared/forms/formNames';
import { staticTranslationFunction } from '../../../../../shared/forms/staticTranslationFunction.ts';

export const createUserAddressSchema = (translate: (key: string) => string) =>
  zod.object({
    country: zod.object({
      value: zod.string().optional(),
      label: zod.string().optional(),
      iconSrc: zod.string().optional(),
      meta: zod.string().optional(),
    }),
    city: zod.string().trim().nonempty(translate('error.requiredField')),
    address: zod.string().trim().nonempty(translate('error.requiredField')),
    zipCode: zod.string().trim().nonempty(translate('error.requiredField')),
  });

export const schema = createUserAddressSchema(staticTranslationFunction);

export type FormData = zod.infer<typeof schema>;

export const userAddressSchema: FormNames<FormData> = {
  country: 'country',
  city: 'city',
  address: 'address',
  zipCode: 'zipCode',
} as const;
