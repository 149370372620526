import * as zod from 'zod';

import { FormNames } from '../../../shared/forms/formNames';
import { staticTranslationFunction } from '../../../shared/forms/staticTranslationFunction.ts';

export const changePasswordSchema = (translate: (key: string) => string) =>
  zod.object({
    password: zod.string().nonempty(translate('error.requiredField')).min(5),
    confirmPassword: zod.string().nonempty(translate('error.requiredField')).min(5),
  });

export const schema = changePasswordSchema(staticTranslationFunction);

export type FormData = zod.infer<typeof schema>;

export const changePasswordFormShema: FormNames<FormData> = {
  password: 'password',
  confirmPassword: 'confirmPassword',
} as const;
