import { css, styled } from 'styled-components';

import { RemSize } from '../../../../theme';

const styledFlex = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const FormSectionContainer = styled.div<{
  $hasBottomBorder: boolean;
  $hasPaddingTop: boolean;
  $paddingBottom: RemSize;
}>`
  ${styledFlex}
  padding-bottom: ${({ $paddingBottom }) => $paddingBottom};
  padding-top: ${({ $hasPaddingTop }) => $hasPaddingTop && '1.5rem'};
  border-bottom: ${({ theme, $hasBottomBorder }) => $hasBottomBorder && `1px solid ${theme.colors.neutrals.hue100}`};
`;

export const ContextWrapper = styled.div`
  width: 100%;
  ${styledFlex}
  gap: 1rem;
`;

export const TitleDescriptionWrapper = styled.div`
  ${styledFlex}
  gap: 0.25rem;
`;
