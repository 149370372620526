import { CardContainer } from '@metaswiss/ui-kit/src/components/molecules/selectable-card/styles/cardContainer';
import { TextTemplate } from '@metaswiss/ui-kit/src/shared/typography/TextTemplate.styles';
import styled from 'styled-components';

export const SelectableCardRow = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
`;

export const SelectableCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SelectableCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  min-width: 30rem;

  > ${CardContainer} {
    width: 100%;

    ${SelectableCardContainer}:last-child {
      ${SelectableCardRow} {
        border-bottom: none;
      }
    }
  }
`;

export const SelectableCardText = styled.p`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  ${TextTemplate}:first-child {
    text-transform: uppercase;
  }
`;
