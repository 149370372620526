import { ButtonDayYear } from './styles/btnStyles';
import { DaysYearsContainer } from './styles/datePickerSlider';
import { PickerProps } from './types';
import { isDayInvalid } from './utils/getBtnsValidation';

export const DayPicker = ({ onChange, date, minDate, maxDate, activePicker }: PickerProps) => {
  return (
    <DaysYearsContainer>
      {Array.from({ length: 31 }).map((_, index) => {
        const day = index + 1;

        const isButtonInvalid = isDayInvalid({ date, day, minDate, maxDate });

        return (
          <ButtonDayYear
            key={day}
            $isSelected={Number(date.day) === day}
            onClick={() => onChange(day.toString().padStart(2, '0'))}
            disabled={isButtonInvalid}
            tabIndex={!activePicker ? 0 : -1}
          >
            {day}
          </ButtonDayYear>
        );
      })}
    </DaysYearsContainer>
  );
};
