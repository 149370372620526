/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CountryResponse } from '../models/CountryResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CountriesApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns CountryResponse Fetch all country
   * @throws ApiError
   */
  public getCountries(): CancelablePromise<Array<CountryResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/countries',
      errors: {
        500: `Bad Request`,
      },
    });
  }

}
