import styled from 'styled-components';

export const PortfolioItemsWapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(11.75rem, 1fr));
  gap: 1rem;
`;

export const PortfolioCardsWapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(26.25rem, 1fr));
  grid-gap: 1rem;
`;

export const TransactionCardWrapper = styled.div`
  width: 100%;
`;
