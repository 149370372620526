import { styled } from 'styled-components';

export const BannerContainer = styled.div<{ $gradientColor: string }>`
  width: 100%;
  padding: 1.5rem;
  background: ${({ $gradientColor }) => $gradientColor};
  border-radius: ${({ theme }) => theme.v2.radius.small};
  position: relative;
`;

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 5.75rem;
`;

export const ImageWrapper = styled.img`
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  position: absolute;
`;

export const IconWrapper = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  left: 48.5%;
`;
