import styled from 'styled-components';

export const ForgotPasswordContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
`;

export const TextsWrapper = styled.div`
  text-align: center;
  margin: 3.5rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  //The transform property fix the stacking order of inputs (z-index) issue on safari
  & > * {
    transform: translate3d(0, 0, 0);
  }
`;
