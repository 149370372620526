import { RadioButtonGroup } from '@metaswiss/ui-kit';
import { FieldValues, Path, FieldError, Controller, Control } from 'react-hook-form';
import styled from 'styled-components';

import { RadioButtonGroupProps } from '../radio-button-group/RadioButtonGroup';

export type FormRadioButtonGroupProps<T extends FieldValues> = {
  name: Path<T>;
  error?: FieldError | undefined;
  control: Control<T>;
} & Omit<RadioButtonGroupProps, 'error' | 'onSelect' | 'selected'>;

const StyledError = styled.span`
  top: 100%;
  position: absolute;
  left: 0;
  color: ${({ theme }) => theme.colors.error.hue100};
  font-weight: ${({ theme }) => theme.typography.weights.reg};
  font-size: ${({ theme }) => theme.typography.sizes.xsm};
  margin: 6px 0 6px 25px;
`;

export const StyledContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const FormRadioButtonGroup = <T extends FieldValues>({
  name,
  error,
  control,
  ...props
}: FormRadioButtonGroupProps<T>) => {
  return (
    <StyledContainer>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <RadioButtonGroup onSelect={onChange} selected={value || undefined} error={!!error} {...props} />
        )}
      />

      {error && <StyledError>{error.message}</StyledError>}
    </StyledContainer>
  );
};
