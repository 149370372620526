import { isArray, isObject } from 'lodash';

import { ApiResource } from '../../enums/resource.enum';

export const getQueryKey = (resource: ApiResource, param?: string | string[] | {}) => {
  if (!param) {
    return [resource];
  }

  if (typeof param === 'string' || isObject(param)) {
    return [resource, param];
  }

  if (isArray(param)) {
    return [resource, ...param];
  }

  throw new Error('Invalid parameter type');
};
