/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NftClientExtendedResponse } from '../models/NftClientExtendedResponse';
import type { NftClientSingleCollectionResponse } from '../models/NftClientSingleCollectionResponse';
import type { PageableItems } from '../models/PageableItems';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NftCollectionClientApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param offset
   * @param limit
   * @param page
   * @param creatorId
   * @returns any Get All NFT Collections
   * @throws ApiError
   */
  public getAll(
    offset?: number,
    limit?: number,
    page?: number,
    creatorId?: string,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/client/nft-collections',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'creatorId': creatorId,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns NftClientSingleCollectionResponse Get NFT Collection by id
   * @throws ApiError
   */
  public getNftCollectionById(
    id: string,
  ): CancelablePromise<NftClientSingleCollectionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/client/nft-collections/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param collectionId
   * @param offset
   * @param limit
   * @param page
   * @param serialNumber
   * @param status
   * @returns any Get All NFTs in Nft Collection
   * @throws ApiError
   */
  public getAllNftsByCollectionId(
    collectionId: string,
    offset?: number,
    limit?: number,
    page?: number,
    serialNumber?: string,
    status?: string,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/client/nft-collections/{collectionId}/nfts',
      path: {
        'collectionId': collectionId,
      },
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'serialNumber': serialNumber,
        'status': status,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns NftClientExtendedResponse Get NFT by id
   * @throws ApiError
   */
  public getNftById(
    id: string,
  ): CancelablePromise<NftClientExtendedResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/client/nft-collections/{collectionId}/nfts/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
