import { AssetUploadUnassignedResponse } from '@metaswiss/api';
import { UploadFile, Text } from '@metaswiss/ui-kit';
import { AcceptedFileTypes } from '@metaswiss/ui-kit/src/components/molecules/upload-file/enums';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import ControlledForm from '../../../../../../../../components/controlled-form/ControlledForm.tsx';
import { usePostAndGetDocument } from '../../../../../../../../hooks/use-post-and-get-document/usePostAndGetDocument';
import { useTextTranslation } from '../../../../../../../../hooks/use-text-translation/useTextTranslation';
import { useAuthContext } from '../../../../../../shared/auth-context/authContext.ts';
import { useSetAuthConfig } from '../../../../../../shared/auth-context/useSetAuthConfig.ts';
import { StepTextContainer } from '../../../../../register.styles';
import { RegisterHeader } from '../../../../../shared/register-header/RegisterHeader';
import { PhotoDocumentProps } from '../../../../../shared/types/documentUploadType';
import { FullPrivateRegistrationData, initialFullPrivateRegistrationValues } from '../fullPrivateRegistrationState';

import {
  fullRegistrationPrivateProfilePhotoSchema,
  FullPrivateRegistrationProfilePhotoData,
  createFullPrivateProfilePhotoSchema,
} from './profilePhotoSchema';

export const ProfilePhotoView = () => {
  const { textTranslation } = useTextTranslation();
  const { setIsValid, userDetails, setUserDetails, prevStep, nextStep } = useAuthContext((state) => state);
  const profilePhoto = (userDetails as FullPrivateRegistrationData).profilePhoto;
  const {
    getValues,
    setValue,
    formState: { isValid },
  } = useFormContext<FullPrivateRegistrationProfilePhotoData>();

  const { mutate, isLoading, isError } = usePostAndGetDocument({
    onMutate: handlePhotoMutation,
    onRefetch: handlePhotoRefetch,
    fileId: profilePhoto?.fileId,
  });

  const setRegistrationFlowProfilePhoto = ({ fileId, url }: PhotoDocumentProps) => {
    setUserDetails({ profilePhoto: { fileId: fileId, url: url } });
  };

  function handlePhotoMutation({ fileId, url }: AssetUploadUnassignedResponse) {
    setValue(fullRegistrationPrivateProfilePhotoSchema.image, fileId, { shouldValidate: true });
    setRegistrationFlowProfilePhoto({ fileId, url });
  }

  function handlePhotoRefetch(documentUrl: string) {
    setRegistrationFlowProfilePhoto({
      fileId: getValues(fullRegistrationPrivateProfilePhotoSchema.image),
      url: documentUrl,
    });
  }

  useEffect(() => {
    return () => {
      setIsValid(true);
    };
  }, [setIsValid]);

  useEffect(() => {
    setIsValid(isValid);
  }, [isValid, setIsValid]);

  const handleProfilePhotoUpload = (file: File) => {
    mutate(file);
  };

  const handleProfilePhotoDelete = () => {
    setRegistrationFlowProfilePhoto({ fileId: '', url: '' });
    setValue(fullRegistrationPrivateProfilePhotoSchema.image, '', { shouldValidate: true });
  };

  useSetAuthConfig(
    {
      wrapperStyle: 'registrationWizard',
      containerStyle: 'registrationWizard',
      backButton: {
        onClick: () => prevStep(),
        text: 'button.back',
      },
      nextButton: {
        text: 'button.next',
        onClick: () => nextStep(),
      },
      showStepper: true,
    },
    [nextStep, prevStep]
  );

  return (
    <>
      <RegisterHeader descriptionText={textTranslation('registration.profilePhotoHeader')} />
      <StepTextContainer>
        <Text fontWeight="bold">{textTranslation('registration.profilePhotoPageTitle')}</Text>
      </StepTextContainer>
      <UploadFile
        uploadFile={handleProfilePhotoUpload}
        onDelete={handleProfilePhotoDelete}
        isLoading={isLoading}
        isError={isError}
        initialTitle={textTranslation('registration.uploadDocumentTitle')}
        initialSubtitle={textTranslation('registration.uploadPhotoSubtitle')}
        invalidTitle={textTranslation('registration.invalidPhotoTitle')}
        invalidSubtitle={textTranslation('registration.uploadPhotoSubtitle')}
        failedTitle={textTranslation('registration.failedPhotoUploadTitle')}
        failedSubtitle={textTranslation('registration.failedPhotoUploadSubtitle')}
        acceptedFileTypes={AcceptedFileTypes.IMAGES}
        $width="37.5rem"
        $height="26rem"
        onViewDocument={() => window.open(profilePhoto.url, '_blank')}
        value={profilePhoto || initialFullPrivateRegistrationValues.profilePhoto}
      />
    </>
  );
};

export const ProfilePhoto = () => {
  const userDetails = useAuthContext((state) => state.userDetails) as FullPrivateRegistrationData;

  return (
    <ControlledForm
      validationSchema={createFullPrivateProfilePhotoSchema}
      defaultValues={{ image: userDetails?.profilePhoto?.fileId || '' }}
    >
      <ProfilePhotoView />
    </ControlledForm>
  );
};
