import styled from 'styled-components';

type Props = {
  $transition: boolean;
};

export const SelectionContainer = styled.div<Props>`
  display: flex;
  gap: 0.62rem;
  width: 100%;
  height: 100%;
  justify-content: start;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.25s ease-in;
  z-index: 2;

  ${({ $transition = true }) =>
    $transition &&
    `
    opacity: 1;
    `}
`;
