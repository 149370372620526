import styled, { css } from 'styled-components';

import { TextTemplate } from '../../../shared/typography/TextTemplate.styles.tsx';

type StyledInputProps = {
  $isActiveInput: boolean;
  $isError: boolean;
  $isIconShown?: boolean;
  $isIconEndShown?: boolean;
  $disableFocus: boolean;
  $customErrorExists?: boolean;
  $isPassword?: boolean;
  $isVisibleLabel?: boolean;
  $isLocked?: boolean;
  $isInputFocused?: boolean;
};

export const activeInputTextStyles = ($isLabelVisible = true, $isLocked = false) => css`
  display: ${$isLabelVisible ? 'block' : 'none'};
  padding: 0;
  top: 0;
  left: 0.25rem;
  transform: translate(${$isLocked ? '0' : '0.25rem'}, -50%) scale(0.75);
  transform-origin: left;
`;

export const activeStylesInputFont = ($isLegendVisible = true, $isLocked = false) => css`
  font-weight: ${({ theme }) => theme.typography.weights.semi};

  ~ ${TextWrapper} {
    ${StyledText} {
      ${activeInputTextStyles($isLegendVisible, $isLocked)}
    }
  }

  ~ ${StyledField} {
    border: 1px solid ${({ theme }) => theme.v2.border.primary};

    ${StyledLegend} {
      max-width: ${$isLegendVisible ? '100%' : '0'};
      opacity: ${$isLegendVisible ? '1' : '0'};
    }
  }
`;

export const StyledButton = styled.button<{ $isDisabled: boolean }>`
  border: 0;
  outline: 0;
  background: transparent;
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'auto' : 'pointer')};
`;

export const StyledError = styled.span`
  top: 100%;
  position: absolute;
  left: 0;
  color: ${({ theme }) => theme.v2.text.error};
  font-weight: ${({ theme }) => theme.typography.weights.reg};
  font-size: ${({ theme }) => theme.typography.sizes.xsm};
  margin-top: 0.25rem;
  margin-left: 1rem;
`;

export const StyledCustomError = styled(StyledError)`
  top: 75%;
`;

export const StyledField = styled.fieldset<{ $isLocked: boolean }>`
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  right: 0;
  width: 100%;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0 1.25rem;
  pointer-events: none;
  overflow: hidden;
  min-width: 0;
  border-radius: ${({ theme }) => theme.v2.componentsRadius.inputs.large};
  border: ${({ theme }) => `1px solid ${theme.v2.border.primaryHover}`};
  background: transparent;
  opacity: ${({ $isLocked }) => ($isLocked ? '0' : '1')};
  transition: opacity 0.4s;
  display: flex;
  align-items: baseline;
`;

export const StyledSpan = styled.span`
  padding: 0 0.25rem;
  display: inline-block;
  opacity: 0;
  visibility: visible;
  color: ${({ theme }) => theme.v2.text.disabled};
`;

export const StyledLegend = styled.legend<{ $isLocked: boolean; $hasContent: boolean }>`
  float: unset;
  width: auto;
  max-width: 0;
  overflow: hidden;
  display: block;
  padding: 0;
  height: 0.125rem;
  font-size: ${({ theme }) => theme.typography.sizes.xsm};
  visibility: hidden;
  white-space: nowrap;
  margin-left: ${({ $isLocked }) => ($isLocked ? '-0.5rem' : '0.25rem')};
  transition:
    ${({ $hasContent }) => $hasContent && 'max-width 0.2s ease-in-out'},
    margin 0.4s;
`;

export const StyledText = styled.p<{ $isIconShown?: boolean; $isAlertIconShown?: boolean; $isLocked?: boolean }>`
  color: ${({ theme }) => theme.v2.text.disabled};
  position: absolute;
  left: ${({ $isIconShown, $isAlertIconShown }) => ($isIconShown || $isAlertIconShown ? '2.25rem' : '0')};
  top: 50%;
  transform: translate(0, -50%);
  display: block;
  background-color: transparent;
  transition: 0.2s cubic-bezier(1, 1, 0.62, 0.89);
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 1.25rem);
  text-overflow: ellipsis;
  margin: 0;
  z-index: 1;
`;

export const StyledTextNumber = styled(StyledText)<{ $leftPosition: number }>`
  left: ${({ $leftPosition }) => `calc(${$leftPosition}px + 0.75rem)`};
`;

const iconStyles = css`
  display: grid;
  place-content: center;
  width: 1.5rem;
`;

export const StartIconWrapper = styled.span`
  ${iconStyles};
  height: 100%;
`;

export const EndIconWrapper = styled.span`
  ${iconStyles};
`;

export const PhoneIconWrapper = styled.span`
  ${iconStyles};
  width: 1.5rem;
`;

export const TextWrapper = styled.div<{ $isLocked: boolean }>`
  height: 100%;
  width: calc(100% - 1.25rem);
  position: relative;
  transform: translate(${({ $isLocked }) => ($isLocked ? '1rem' : '1.25rem')}, -100%);
  border: 1px solid transparent;
  pointer-events: none;
  transition: transform 0.4s;
  user-select: none;
`;

export const StyledInput = styled.input<StyledInputProps>`
  font-size: ${({ theme }) => theme.typography.sizes.base};
  border: none;
  box-sizing: border-box;
  width: 100%;
  border-radius: ${({ theme }) => theme.v2.componentsRadius.inputs.large};
  background-color: ${({ theme, $isLocked }) => ($isLocked ? 'transparent' : theme.v2.surface.field)};
  color: ${({ theme }) => theme.v2.text.headingPrimary};
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: ${({ theme, $isIconEndShown, $isPassword }) =>
    $isIconEndShown || $isPassword ? '3.5rem' : theme.v2.spacing['1.25']};
  padding-left: ${({ $isIconShown, theme }) => ($isIconShown ? '3.5rem' : theme.v2.spacing['1.25'])};
  ${({ $isActiveInput, $isVisibleLabel, $isLocked }) =>
    $isActiveInput && activeStylesInputFont($isVisibleLabel, $isLocked)}
  ${({ $isError, $customErrorExists, $isActiveInput }) =>
    ($isError || $customErrorExists) && $isActiveInput
      ? errorActiveStateStyles
      : $isError || $customErrorExists
        ? errorStateStyles
        : undefined}
    transition: padding 0.4s;

  &:disabled {
    border-color: ${({ theme, $disableFocus }) => !$disableFocus && theme.v2.border.primaryHover};
    background-color: ${({ theme, $disableFocus }) => $disableFocus && theme.v2.surface.disabled};
    pointer-events: none;
    color: ${({ theme, $disableFocus }) => $disableFocus && theme.v2.text.disabled};

    ~ ${TextWrapper} {
      ${StyledText} {
        ${({ $disableFocus }) => !$disableFocus && activeInputTextStyles()};
        color: ${({ theme, $disableFocus }) => !$disableFocus && theme.v2.text.disabled};
      }
    }

    ~ ${StyledField} {
      border-color: ${({ theme }) => theme.v2.border.primaryHover};

      * {
        stroke: ${({ theme, $disableFocus }) =>
          !$disableFocus ? theme.v2.text.disabled : theme.v2.text.headingPrimary};
      }

      ${StyledLegend} {
        opacity: 1;
        max-width: ${({ $disableFocus }) => !$disableFocus && '100%'};
      }
    }
  }

  &:focus-visible {
    border: 0;
    outline: none;
    cursor: text;
    font-weight: ${({ theme }) => theme.typography.weights.bold};

    ~ div {
      ${StyledText} {
        color: ${({ theme, $isError, $customErrorExists }) =>
          $isError || $customErrorExists ? theme.v2.text.error : theme.v2.text.information};
        ${({ $isVisibleLabel }) => activeInputTextStyles($isVisibleLabel)};
      }
    }

    ~ ${StyledField} {
      border: 1px solid
        ${({ theme, $isError, $customErrorExists }) =>
          !$isError && !$customErrorExists ? theme.v2.border.information : theme.v2.border.error};

      ${StyledLegend} {
        max-width: ${({ $isVisibleLabel }) => ($isVisibleLabel ? '100%' : '0')};
        opacity: ${({ $isVisibleLabel }) => ($isVisibleLabel ? '1' : '0')};
      }
    }
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.v2.surface.field} inset;
    -webkit-text-fill-color: ${({ theme }) => theme.v2.text.headingPrimary};

    ~ div {
      ${StyledText} {
        color: ${({ theme, $isError, $customErrorExists, $isInputFocused, $isActiveInput }) => {
          if ($isInputFocused) {
            return $isError || $customErrorExists ? theme.v2.text.error : theme.v2.text.information;
          }
          if ($isActiveInput && !$isInputFocused) {
            return $isError || $customErrorExists ? theme.v2.text.error : theme.v2.text.disabled;
          }
          return theme.v2.text.disabled;
        }};
        ${activeInputTextStyles()}
      }

      ~ ${StyledField} {
        background: transparent;
        border: 1px solid
          ${({ theme, $isError, $customErrorExists, $isInputFocused, $isActiveInput }) => {
            if ($isInputFocused) {
              return $isError || $customErrorExists ? theme.v2.text.error : theme.v2.text.information;
            }
            if ($isActiveInput && !$isInputFocused) {
              return $isError || $customErrorExists ? theme.v2.text.error : theme.v2.border.primary;
            }
            return theme.v2.border.primary;
          }};

        ${StyledLegend} {
          max-width: 100%;
          opacity: 1;
        }
      }
    }
  }
`;

export const StyledInputNumber = styled(StyledInput)<StyledInputProps & { $paddingLeft: number }>`
  ${StyledInput};
  padding-left: ${({ $paddingLeft }) => `calc(${$paddingLeft}px + 32px)`};
  display: ${({ $paddingLeft }) => ($paddingLeft === 0 ? 'none' : 'flex')};
  transition: none;
`;

export const StyledLabel = styled.label<{ $fitHeight: boolean; $isLocked: boolean }>`
  display: inline-block;
  position: relative;
  width: 100%;
  height: ${({ $fitHeight }) => ($fitHeight ? '100%' : '3.5rem')};
  pointer-events: ${({ $isLocked }) => $isLocked && 'none'};
`;

export const StyledMissingValueContainer = styled.div<{ $isOptional: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 1.25rem;
  height: 3.5rem;
  border: 1px solid transparent;

  ${TextTemplate} {
    position: absolute;
    transform: translateY(1px);
    left: ${({ $isOptional }) => !$isOptional && '3.5rem'};

    @supports (-moz-appearance: none) {
      transform: translateY(0);
    }
  }
`;

export const StyledAlertIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  justify-content: center;
  display: flex;
  align-items: center;
`;

const errorStateCommonStyles = css`
  border: 0;
  outline: none;

  ~ ${StyledField} {
    border: 1px solid ${({ theme }) => theme.v2.border.error};

    ${StyledLegend} {
      max-width: 100%;
      opacity: 1;
    }
  }
`;

export const errorActiveStateStyles = css`
  ${errorStateCommonStyles}
  &:not(focus) {
    ~ ${TextWrapper} {
      ${StyledText} {
        color: ${({ theme }) => theme.v2.text.error};

        ${activeInputTextStyles()}
        ${StyledSpan} {
          color: ${({ theme }) => theme.v2.text.error};
        }
      }
    }
  }
`;

export const errorStateStyles = css`
  ${errorStateCommonStyles}
  &:not(:focus) {
    ~ ${StyledField} {
      ${StyledLegend} {
        max-width: 0;

        ${StyledSpan} {
          color: ${({ theme }) => theme.colors.error.hue100}; // TODO: check this
        }
      }
    }
  }
`;
