import { zodResolver } from '@hookform/resolvers/zod';
import { FC, ReactNode, useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { ZodType } from 'zod';

import { useFormSchema } from '../../hooks/use-form-schema/useFormSchema.ts';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation.ts';

interface Props {
  validationSchema: (translate: (key: string) => string) => ZodType;
  children: ReactNode;
  defaultValues?: FieldValues;
}

const ControlledForm: FC<Props> = ({ validationSchema, defaultValues, children }) => {
  const { currentLanguage } = useTextTranslation();
  const [isFirstRender, setIsFirstRender] = useState(true);

  const schema = useFormSchema(validationSchema);

  const methods = useForm({ mode: 'onChange', resolver: zodResolver(schema), defaultValues });

  useEffect(() => {
    if (!isFirstRender && currentLanguage) {
      const validate = async () => {
        await methods.trigger(Object.keys(methods.formState.errors));
      };
      validate();
    }

    return () => {
      setIsFirstRender(false);
    };
  }, [currentLanguage, isFirstRender, methods]);

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default ControlledForm;
