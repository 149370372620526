import { BaseSelectableCard } from './BaseSelectableCard';
import { WrapperCardProps } from './types';

export const ExpendableCard = ({ size = 'base', children, ...props }: WrapperCardProps) => {
  return (
    <BaseSelectableCard size={size} isExpandedDefault={false} {...props}>
      {children}
    </BaseSelectableCard>
  );
};
