import * as zod from 'zod';

import { IdentityVerification } from '../../../../../../../../enums/identityVerification';
import { FormNames } from '../../../../../../../../shared/forms/formNames';
import { staticTranslationFunction } from '../../../../../../../../shared/forms/staticTranslationFunction.ts';
import { useAuthContext } from '../../../../../../shared/auth-context/authContext';
import { FullPrivateRegistrationData } from '../fullPrivateRegistrationState';

export const createFullPrivateDocumentUploadSchema = (translate: (key: string) => string) =>
  (useAuthContext.getState().userDetails as FullPrivateRegistrationData).identityVerification ===
  IdentityVerification.ID
    ? zod.object({
        frontSide: zod.string().nonempty(translate('error.requiredField')),
        backSide: zod.string().nonempty(translate('error.requiredField')),
      })
    : zod.object({
        passport: zod.string().nonempty(translate('error.requiredField')),
      });

export const schema = createFullPrivateDocumentUploadSchema(staticTranslationFunction);

export type FullPrivateRegistrationDocumentUploadData = zod.infer<typeof schema>;

export const fullRegistrationPrivateDocumentUploadSchema: FormNames<FullPrivateRegistrationDocumentUploadData> = {
  frontSide: 'frontSide',
  backSide: 'backSide',
  passport: 'passport',
} as const;
