import { FC } from 'react';

import { IconProps } from './iconProps';

export const DocumentIcon: FC<IconProps> = ({ strokeColor = '#222223' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.5003 4.40002V11C27.5003 12.2151 28.4853 13.2 29.7003 13.2H36.3003M33.0003 7.70002C32.0212 6.82395 31.0052 5.78488 30.3637 5.11003C29.9369 4.66097 29.3472 4.40002 28.7276 4.40002H12.0998C9.66977 4.40002 7.69983 6.36996 7.69982 8.8L7.69965 35.1999C7.69963 37.63 9.66957 39.5999 12.0996 39.5999L31.8997 39.6C34.3297 39.6 36.2997 37.6301 36.2997 35.2001L36.3003 11.8761C36.3003 11.3135 36.0857 10.7728 35.6953 10.3678C34.9733 9.61888 33.7677 8.38664 33.0003 7.70002Z"
        stroke={strokeColor}
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
