import { usePresence } from 'framer-motion';
import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export function useMotionExitTransition(transitionDuration: number = 300) {
  const [isPresent, safeToRemove] = usePresence();

  useEffect(() => {
    !isPresent && setTimeout(safeToRemove, transitionDuration);
  }, [isPresent, safeToRemove, transitionDuration]);

  return isPresent;
}
