import { useMemo } from 'react';
import styled from 'styled-components';

import { Text, HeaderCard, ThemedIcon } from '../../../../components';
import { ChevronRightIcon } from '../../../../iconography';

import { InfoCardProps } from './types';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
`;

const ArrowWrapper = styled.button`
  display: grid;
  place-content: center;
`;

export const InfoCard = ({
  title,
  headerContent,
  onClick,
  children,
  headerPalette = 'neutrals',
  arrowColor = 'hue700',
  titleColor = 'hue700',
  titleSize = 'base',
  titleWeight = 'bold',
  borderRadius = 'large',
  palette = 'neutrals',
  borderColor = 'hue200',
}: InfoCardProps) => {
  const header = useMemo(
    () => (
      <HeaderContainer>
        <Text palette={headerPalette} textColor={titleColor} fontSize={titleSize} fontWeight={titleWeight}>
          {title}
        </Text>
        {headerContent}
        {onClick && (
          <ArrowWrapper onClick={onClick}>
            <ThemedIcon icon={ChevronRightIcon} palette={headerPalette} strokeColor={arrowColor} />
          </ArrowWrapper>
        )}
      </HeaderContainer>
    ),
    []
  );

  return (
    <HeaderCard header={header} borderRadius={borderRadius} palette={palette} borderColor={borderColor}>
      {children}
    </HeaderCard>
  );
};
