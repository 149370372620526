import { BondFullResponseDTO, CurrencyResponse, PaymentResponse, ShareFullResponseDTO } from '@metaswiss/api';
import { extractNumericDigitsWithDecimals, formatAmount } from '@metaswiss/lib';
import {
  BankIcon,
  CoinUnbrokenIcon,
  DetailsList,
  ResponsiveBill,
  SendIcon,
  UserProfileCircle,
} from '@metaswiss/ui-kit';
import { CurrencyChfIcon } from '@metaswiss/ui-kit/src/iconography/CurrencyChfIcon';
import { useQuery } from '@tanstack/react-query';
import { FC, useMemo } from 'react';

import { api } from '../../../api/msApi';
import { ProductType } from '../../../enums/productType.enum';
import { ApiResource } from '../../../enums/resource.enum';
import { useTextTranslation } from '../../../hooks/use-text-translation/useTextTranslation';
import { DetailsWrapper } from '../../../pages/protected/payment/payment.styles';
import { getQueryKey } from '../../../shared/helpers/getQueryKey.helper';

type Props = {
  paymentResponse: PaymentResponse;
  item: ShareFullResponseDTO | BondFullResponseDTO;
  productType: ProductType;
  currency: CurrencyResponse;
};

export const SuccessfullyPurchasedBondShare: FC<Props> = ({ paymentResponse, item, productType, currency }) => {
  const { textTranslation } = useTextTranslation();

  const { data: transaction } = useQuery({
    queryKey: getQueryKey(ApiResource.TRANSACTIONS, paymentResponse.transactionId),
    queryFn: () => api.transactions.getTransactionDetails(paymentResponse.transactionId),
    enabled: !!paymentResponse?.transactionId,
  });

  const pricePerItem = useMemo(() => {
    if (productType === ProductType.ACTIONS) {
      return formatAmount(
        (item as ShareFullResponseDTO).rawPricePerShare,
        currency.decimals,
        currency.rate,
        currency.currencyCode
      );
    }

    const rawDenomination = extractNumericDigitsWithDecimals((item as BondFullResponseDTO).denomination.split(' ')[0]);
    return formatAmount(rawDenomination, currency.decimals, currency.rate, currency.currencyCode);
  }, [currency, item, productType]);

  const issuerDetails = {
    title: textTranslation('payment.issuerInfo'),
    isVisibleTitleStep: 1,
    items: [
      {
        icon: UserProfileCircle,
        subText: textTranslation('payment.paymentName'),
        text: item.issuer?.issuerName ?? '',
        isVisibleStep: 1,
      },
      {
        icon: SendIcon,
        subText: textTranslation('global.address'),
        text: item.issuer?.address ?? '',
        isVisibleStep: 1,
      },
    ],
  };

  const productDetails = {
    title:
      productType === ProductType.ACTIONS
        ? textTranslation('payment.shareDetails')
        : textTranslation('payment.bondDetails'),
    isVisibleTitleStep: 1,
    items: [
      {
        icon: CurrencyChfIcon,
        subText:
          productType === ProductType.ACTIONS
            ? textTranslation('payment.pricePerShare')
            : textTranslation('payment.pricePerBond'),
        text: pricePerItem,
        isVisibleStep: 1,
      },
      {
        icon: CoinUnbrokenIcon,
        subText: textTranslation('account.quantity'),
        text: `${paymentResponse.quantity}`,
        isVisibleStep: 1,
      },
    ],
  };

  const paymentDetails = {
    title: textTranslation('portfolio.paymentDetails'),
    isVisibleTitleStep: 1,
    items: [
      {
        icon: BankIcon,
        subText: textTranslation('global.paymentMethod'),
        text: textTranslation('payment.bankTransfer'),
        isVisibleStep: 1,
      },
    ],
  };

  return (
    <ResponsiveBill
      amountText={textTranslation('payment.totalAmount')}
      amount={transaction?.detectedAmount ?? ''}
      title={textTranslation('payment.overview')}
      isResponsive={false}
      billBottomPadding={'4rem'}
      isPaymentSuccessful={true}
    >
      <DetailsWrapper>
        <DetailsList {...issuerDetails} step={1} />
        <DetailsList {...productDetails} step={1} />
        <DetailsList {...paymentDetails} step={1} />
      </DetailsWrapper>
    </ResponsiveBill>
  );
};
