import styled from 'styled-components';

export const Fieldset = styled.fieldset<{
  $isError: boolean;
  $isLocked: boolean;
}>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: opacity, 0.4s;
  opacity: ${({ $isLocked }) => ($isLocked ? 0 : 1)};
  border: 1px solid ${({ theme, $isError }) => ($isError ? theme.v2.border.error : theme.v2.border.primary)};
  border-radius: ${({ theme }) => theme.v2.componentsRadius.inputs.large};
  padding: ${({ theme }) => `${theme.v2.spacing['1']} ${theme.v2.spacing['1.25']}`};
`;
