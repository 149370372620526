import { BaseCard } from '@metaswiss/ui-kit';
import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import styled from 'styled-components';

export const MainCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

export const PriceIconsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
`;

export const ButtonDiv = styled.div`
  border-radius: 0.5rem;
  width: calc(100vw - 2rem);

  @media ${breakpoints.laptopSm} {
    width: 100%;
  }
`;

export const StyledResourceCard = styled(BaseCard)`
  min-width: 19.5rem;
  flex: 1 1 0;
`;

export const StickyPriceCardWrapper = styled.div<{ $headerOffset: number }>`
  position: absolute;
  top: ${({ $headerOffset }) => `${$headerOffset}px`};
  z-index: 2;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: ${({ theme }) => theme.v2.surface.primary};
  border-top: 1px solid ${({ theme }) => theme.v2.border.primary};
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
  display: flex;
  justify-content: center;

  @media ${breakpoints.laptopSm} {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 77.5rem;
  padding-left: 1px;
  padding-right: 1px;
`;
