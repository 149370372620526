import { FC } from 'react';

import { IconProps } from './iconProps';

export const FaceId01: FC<IconProps> = ({ color = 'none', strokeColor = '3381FF' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 48 48" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5329 1.59961H6.57739C3.82824 1.59961 1.59961 3.82824 1.59961 6.57739V16.5329M16.5329 46.3996H6.57739C3.82824 46.3996 1.59961 44.171 1.59961 41.4218V31.4663M31.4663 1.59961H41.4218C44.171 1.59961 46.3996 3.82824 46.3996 6.57739V16.5329M46.3996 31.4663V41.4218C46.3996 44.171 44.171 46.3996 41.4218 46.3996H31.4663M14.1996 17.3852V14.6552M33.7996 17.3852V14.6552M16.1891 32.4C19.3815 34.8735 26.5403 34.8735 31.0011 32.4M22.5996 25.5751L23.1795 25.0097C23.7046 24.4978 23.9996 23.8034 23.9996 23.0793V16.0202"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
