import styled from 'styled-components';

export const HistoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TransactionsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  gap: 1rem;
`;

export const TransactionCardWrapper = styled.div`
  width: 100%;
  max-width: 38.25rem;
`;

export const IndicatorGroup = styled.div`
  margin: 0 auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;
