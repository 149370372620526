/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChartResponse } from '../models/ChartResponse';
import type { PageableItems } from '../models/PageableItems';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PortfolioApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param productType
   * @param userId
   * @param offset
   * @param limit
   * @param page
   * @returns PageableItems User portfolio
   * @throws ApiError
   */
  public getUserPortfolio(
    productType: string,
    userId: string,
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/portfolios',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'productType': productType,
        'userId': userId,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param productType
   * @param userId
   * @returns ChartResponse Portfolio chart
   * @throws ApiError
   */
  public getChartPortfolio(
    productType: string,
    userId: string,
  ): CancelablePromise<ChartResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/portfolios/chart',
      query: {
        'productType': productType,
        'userId': userId,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
