import React from 'react';
import { useTheme } from 'styled-components';

import { ThemedIcon } from '../../../components';
import { IconChecked } from '../../../iconography';
import { TypographyWeight } from '../../../theme/theme';

import { HiddenCheckbox } from './styles/hiddenCheckbox';
import { StyledCheckbox } from './styles/styledCheckbox';
import { StyledLabel } from './styles/styledLabel';
import { StyledLabelSpan } from './styles/styledLabelSpan';

export type CheckboxProps = {
  id: string;
  name?: string;
  label?: string;
  value?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isError?: boolean;
  fontWeight?: TypographyWeight;
  checked: boolean;
  disabled?: boolean;
};

export const Checkbox = ({
  id,
  name,
  label,
  isError = false,
  onChange,
  fontWeight,
  checked,
  disabled,
}: CheckboxProps) => {
  const theme = useTheme();
  return (
    <StyledLabel htmlFor={id}>
      <HiddenCheckbox id={id} name={name} checked={checked} type="checkbox" onChange={onChange} disabled={disabled} />
      <StyledCheckbox $checked={checked} $isError={isError}>
        {checked && !isError ? (
          <ThemedIcon icon={IconChecked} size={'medium'} customStrokeColor={theme.v2.icon.onAction} />
        ) : null}
      </StyledCheckbox>
      {label && (
        <StyledLabelSpan $fontWeight={fontWeight ?? 'reg'} $fontSize="base">
          {label}
        </StyledLabelSpan>
      )}
    </StyledLabel>
  );
};
