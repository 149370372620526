import styled from 'styled-components';

import { HiddenCheckbox } from './hiddenCheckbox';

export const StyledCheckbox = styled.div<{
  $checked: boolean;
  $isError: boolean;
}>`
  display: inline-block;
  width: ${({ theme }) => theme.checkbox.width.medium};
  height: ${({ theme }) => theme.checkbox.width.medium};
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.v2.border.primaryHover};
  background: ${({ theme }) => theme.v2.surface.field};
  ${({ theme, $checked }) =>
    $checked &&
    `
    border: 1px solid ${theme.v2.surface.action1};
    background: ${theme.v2.surface.action1};
    `};
  ${({ theme, $isError }) =>
    $isError &&
    `
    border: 1px solid ${theme.v2.border.error};
    background: ${theme.v2.surface.field};
    `};

  ${HiddenCheckbox}:disabled + & {
    background: ${({ theme }) => theme.v2.surface.tertiary};
    border: 1px solid ${({ theme }) => theme.v2.border.primaryHover};
  }

  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.small};
  transition: all 150ms;
  cursor: pointer;
`;
