import { ErrorRetrieveIcon } from '@metaswiss/ui-kit';

import { ErrorPageMessage } from '../../../../../../../components/error-page-message/ErrorPageMessage';
import { LoaderContainer } from '../../../../../../../components/loader-container/LoaderContainer';
import { useTextTranslation } from '../../../../../../../hooks/use-text-translation/useTextTranslation';

type Props = {
  isLoading: boolean;
  isError: boolean;
  data?: {};
  refetch: () => {};
};

export const LoadingError = ({ isLoading, isError, data, refetch }: Props) => {
  const { textTranslation } = useTextTranslation();

  if (isLoading) return <LoaderContainer />;

  if (isError && !data)
    return (
      <ErrorPageMessage
        icon={ErrorRetrieveIcon}
        title={textTranslation('global.somethingWentWrong')}
        description={textTranslation('global.anErrorOccurred')}
        buttonText={textTranslation('global.tryAgain')}
        onButtonClick={refetch}
      />
    );
};
