import styled from 'styled-components';

import { RemSize } from '../../../..';
import { StyledError } from '../../../atoms/input/input.styles.ts';
import { BaseCard } from '../base-card/BaseCard';

export const StyledCurrencyCard = styled(BaseCard)<{ $isError: boolean; $borderRadius: string; $isDisabled: boolean }>`
  box-sizing: border-box;
  border: 1px solid
    ${({ theme, $isError, $isDisabled }) =>
      $isDisabled ? theme.v2.border.primaryHover : $isError ? theme.v2.border.error : theme.v2.border.primary};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  width: 100%;
  background: ${({ theme }) => theme.v2.surface.disabled};
  color: ${({ theme }) => theme.v2.text.bodyPrimary};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0.5rem 1.25rem;
  display: flex;
  height: 3.5rem;
  align-items: center;
  margin-bottom: 1.25rem;
  margin-top: 0.5rem;
`;
export const CurrencyCardWrapper = styled.div<{ $width: RemSize | 'auto' }>`
  position: relative;
  flex: 0 1 ${({ $width }) => $width};
  max-width: ${({ $width }) => $width};

  ${StyledError} {
    top: 4rem;
  }
`;

export const CurrencyCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
