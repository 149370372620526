/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CurrencyResponse } from '../models/CurrencyResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CurrenciesApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns CurrencyResponse Fetch all currencies
   * @throws ApiError
   */
  public findAllCurrencies(): CancelablePromise<Array<CurrencyResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/currencies',
      errors: {
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns CurrencyResponse Update rates
   * @throws ApiError
   */
  public getApiRatesAndSetThemToDb(): CancelablePromise<CurrencyResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/currencies/rates',
      errors: {
        500: `Bad Request`,
      },
    });
  }

}
