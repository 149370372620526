/* eslint-disable @typescript-eslint/naming-convention */
export const baseColors = {
  default: {
    white: '#ffffff',
    black: '#000000',
    gray0: '#f9f9fb',
    gray25: '#e8e9f1',
    gray50: '#d3d3e1',
    gray100: '#acadc5',
    gray200: '#757da1',
    gray300: '#3E4564',
    gray400: '#292E46',
    gray500: '#1F2337',
    gray600: '#131728',
    gray700: '#080916',
    green300: '#023f20',
    green200: '#069e52',
    green100: '#08de73',
    green50: '#39e58f',
    green25: 'rgba(8, 222, 115, 0.1200)',
    green0: 'rgba(8, 222, 115, 0.0400)',
    red300: '#241716',
    red200: '#aa3028',
    red100: '#f04438',
    red50: '#f36960',
    red25: 'rgba(240, 68, 56, 0.1200)',
    red0: 'rgba(240, 68, 56, 0.0400)',
    yellow300: '#513309',
    yellow200: '#b57112',
    yellow100: '#ff9f1a',
    yellow50: '#ffb248',
    yellow25: 'rgba(255, 159, 26, 0.1200)',
    yellow0: 'rgba(255, 159, 26, 0.0400)',
    blue300: '#121b29',
    blue200: '#003b99',
    blue100: '#3381ff',
    blue50: '#66a1ff',
    blue25: 'rgba(51, 129, 255, 0.1200)',
    blue0: 'rgba(51, 129, 255, 0.0400)',
    purple300: '#250939',
    purple200: '#571782',
    purple100: '#9126d9',
    purple50: '#a752e0',
    purple25: 'rgba(145, 38, 217, 0.1200)',
    purple0: 'rgba(145, 38, 217, 0.0400)',
  },
  other: {
    gs: {
      green300: '#192216',
      green200: '#356627',
      green100: '#65c549',
      green50: '#7bcc63',
      green25: 'rgba(101, 197, 73, 0.1200)',
      green0: 'rgba(101, 197, 73, 0.0400)',
      orange300: '#44290e',
      orange200: '#88501a',
      orange100: '#e2842c',
      orange50: '#f89130',
      orange25: 'rgba(226, 132, 44, 0.1200)',
      orange0: 'rgba(226, 132, 44, 0.0400)',
    },
  },
};

export const aliases = {
  skywave: {
    colors: {
      white: baseColors.default.white,
      black: baseColors.default.black,
      neutral0: baseColors.default.gray0,
      neutral25: baseColors.default.gray25,
      neutral50: baseColors.default.gray50,
      neutral100: baseColors.default.gray100,
      neutral200: baseColors.default.gray200,
      neutral300: baseColors.default.gray300,
      neutral400: baseColors.default.gray400,
      neutral500: baseColors.default.gray500,
      neutral600: baseColors.default.gray600,
      neutral700: baseColors.default.gray700,
      primary0: baseColors.default.blue0,
      primary25: baseColors.default.blue25,
      primary50: baseColors.default.blue50,
      primary100: baseColors.default.blue100,
      primary200: baseColors.default.blue200,
      primary300: baseColors.default.blue300,
      secondary0: baseColors.default.purple0,
      secondary25: baseColors.default.purple25,
      secondary50: baseColors.default.purple50,
      secondary100: baseColors.default.purple100,
      secondary200: baseColors.default.purple200,
      secondary300: baseColors.default.purple300,
      error0: baseColors.default.red0,
      error25: baseColors.default.red25,
      error50: baseColors.default.red50,
      error100: baseColors.default.red100,
      error200: baseColors.default.red200,
      error300: baseColors.default.red300,
      warning0: baseColors.default.yellow0,
      warning25: baseColors.default.yellow25,
      warning50: baseColors.default.yellow50,
      warning100: baseColors.default.yellow100,
      warning200: baseColors.default.yellow200,
      warning300: baseColors.default.yellow300,
      success0: baseColors.default.green0,
      success25: baseColors.default.green25,
      success50: baseColors.default.green50,
      success100: baseColors.default.green100,
      success200: baseColors.default.green200,
      success300: baseColors.default.green300,
    },
    radius: {
      smallest: '4px',
      smaller: '8px',
      small: '12px',
      medium: '16px',
      large: '20px',
      larger: '24px',
      largest: '32px',
      full: '100000px',
    },
    spacing: {
      '1': '16px',
      '2': '32px',
      '3': '48px',
      '4': '64px',
      '5': '80px',
      '6': '96px',
      '8': '128px',
      '10': '160px',
      '12': '192px',
      '14': '224px',
      '16': '256px',
      '0.25': '4px',
      '0.5': '8px',
      '0.75': '12px',
      '1.25': '20px',
      '1.5': '24px',
      '2.5': '40px',
    },
    borderWidth: {
      small: '1px',
      medium: '2px',
      large: '4px',
    },
    componentsRadius: {
      buttons: {
        large: '8px',
        medium: '8px',
        small: '8px',
      },
      inputs: {
        large: '8px',
        small: '8px',
      },
      cards: {
        xlarge: '16px',
        large: '16px',
        default: '12px',
        mediums: '8px',
        small: '4px',
      },
    },
    tenant: 'SkyWave',
  },
  greenstate: {
    colors: {
      white: baseColors.default.white,
      black: baseColors.default.black,
      neutral0: baseColors.default.gray0,
      neutral25: baseColors.default.gray25,
      neutral50: baseColors.default.gray50,
      neutral100: baseColors.default.gray100,
      neutral200: baseColors.default.gray200,
      neutral300: baseColors.default.gray300,
      neutral400: baseColors.default.gray400,
      neutral500: baseColors.default.gray500,
      neutral600: baseColors.default.gray600,
      neutral700: baseColors.default.gray700,
      primary0: baseColors.other.gs.green0,
      primary25: baseColors.other.gs.green25,
      primary50: baseColors.other.gs.green50,
      primary100: baseColors.other.gs.green100,
      primary200: baseColors.other.gs.green200,
      primary300: baseColors.other.gs.green300,
      secondary0: baseColors.other.gs.orange0,
      secondary25: baseColors.other.gs.orange25,
      secondary50: baseColors.other.gs.orange50,
      secondary100: baseColors.other.gs.orange100,
      secondary200: baseColors.other.gs.orange200,
      secondary300: baseColors.other.gs.orange300,
      error0: baseColors.default.red0,
      error25: baseColors.default.red25,
      error50: baseColors.default.red50,
      error100: baseColors.default.red100,
      error200: baseColors.default.red200,
      error300: baseColors.default.red300,
      warning0: baseColors.default.yellow0,
      warning25: baseColors.default.yellow25,
      warning50: baseColors.default.yellow50,
      warning100: baseColors.default.yellow100,
      warning200: baseColors.default.yellow200,
      warning300: baseColors.default.yellow300,
      success0: baseColors.default.green0,
      success25: baseColors.default.green25,
      success50: baseColors.default.green50,
      success100: baseColors.default.green100,
      success200: baseColors.default.green200,
      success300: baseColors.default.green300,
    },
    radius: {
      smallest: '4px',
      smaller: '8px',
      small: '12px',
      medium: '16px',
      large: '20px',
      larger: '24px',
      largest: '32px',
      full: '100000px',
    },
    spacing: {
      '1': '16px',
      '2': '32px',
      '3': '48px',
      '4': '64px',
      '5': '80px',
      '6': '96px',
      '8': '128px',
      '10': '160px',
      '12': '192px',
      '14': '224px',
      '16': '256px',
      '0.25': '4px',
      '0.5': '8px',
      '0.75': '12px',
      '1.25': '20px',
      '1.5': '24px',
      '2.5': '40px',
    },
    borderWidth: {
      small: '1px',
      medium: '2px',
      large: '4px',
    },
    componentsRadius: {
      buttons: {
        large: '8px',
        medium: '8px',
        small: '8px',
      },
      inputs: {
        large: '8px',
        small: '8px',
      },
      cards: {
        xlarge: '16px',
        large: '12px',
        default: '8px',
        medium: '8px',
        small: '4px',
      },
    },
    tenant: 'GreenState',
  },
  metaswiss: {
    colors: {
      white: baseColors.default.white,
      black: baseColors.default.black,
      neutral0: baseColors.default.gray0,
      neutral25: baseColors.default.gray25,
      neutral50: baseColors.default.gray50,
      neutral100: baseColors.default.gray100,
      neutral200: baseColors.default.gray200,
      neutral300: baseColors.default.gray300,
      neutral400: baseColors.default.gray400,
      neutral500: baseColors.default.gray500,
      neutral600: baseColors.default.gray600,
      neutral700: baseColors.default.gray700,
      primary0: baseColors.default.blue0,
      primary25: baseColors.default.blue25,
      primary50: baseColors.default.blue50,
      primary100: baseColors.default.blue100,
      primary200: baseColors.default.blue200,
      primary300: baseColors.default.blue300,
      secondary0: baseColors.default.purple0,
      secondary25: baseColors.default.purple25,
      secondary50: baseColors.default.purple50,
      secondary100: baseColors.default.purple100,
      secondary200: baseColors.default.purple200,
      secondary300: baseColors.default.purple300,
      error0: baseColors.default.red0,
      error25: baseColors.default.red25,
      error50: baseColors.default.red50,
      error100: baseColors.default.red100,
      error200: baseColors.default.red200,
      error300: baseColors.default.red300,
      warning0: baseColors.default.yellow0,
      warning25: baseColors.default.yellow25,
      warning50: baseColors.default.yellow50,
      warning100: baseColors.default.yellow100,
      warning200: baseColors.default.yellow200,
      warning300: baseColors.default.yellow300,
      success0: baseColors.default.green0,
      success25: baseColors.default.green25,
      success50: baseColors.default.green50,
      success100: baseColors.default.green100,
      success200: baseColors.default.green200,
      success300: baseColors.default.green300,
    },
    radius: {
      smallest: '4px',
      smaller: '8px',
      small: '12px',
      medium: '16px',
      large: '20px',
      larger: '24px',
      largest: '32px',
      full: '100000px',
    },
    spacing: {
      '1': '16px',
      '2': '32px',
      '3': '48px',
      '4': '64px',
      '5': '80px',
      '6': '96px',
      '8': '128px',
      '10': '160px',
      '12': '192px',
      '14': '224px',
      '16': '256px',
      '0.25': '4px',
      '0.5': '8px',
      '0.75': '12px',
      '1.25': '20px',
      '1.5': '24px',
      '2.5': '40px',
    },
    borderWidth: {
      small: '1px',
      medium: '2px',
      large: '4px',
    },
    componentsRadius: {
      buttons: {
        large: '12px',
        medium: '12px',
        small: '8px',
      },
      inputs: {
        large: '12px',
        small: '12px',
      },
      cards: {
        xlarge: '24px',
        large: '16px',
        default: '12px',
        medium: '12px',
        small: '8px',
      },
    },
    tenant: 'MetaSwiss',
  },
};
export const tokens = (tenant: 'metaswiss' | 'greenstate') => ({
  light: {
    text: {
      headingPrimary: aliases[tenant].colors.neutral700,
      bodyPrimary: aliases[tenant].colors.neutral600,
      bodySecondary: aliases[tenant].colors.neutral400,
      disabled: aliases[tenant].colors.neutral200,
      onDisabled: aliases[tenant].colors.neutral200,
      action: aliases[tenant].colors.primary100,
      actionHover: aliases[tenant].colors.primary50,
      action2: aliases[tenant].colors.secondary100,
      action2Hover: aliases[tenant].colors.secondary50,
      onAction: aliases[tenant].colors.white,
      success: aliases[tenant].colors.success100,
      successHover: aliases[tenant].colors.success50,
      warning: aliases[tenant].colors.warning100,
      warningHover: aliases[tenant].colors.warning50,
      error: aliases[tenant].colors.error100,
      errorHover: aliases[tenant].colors.error50,
      information: aliases[tenant].colors.primary100,
      informationHover: aliases[tenant].colors.primary50,
    },
    surface: {
      primary: aliases[tenant].colors.white,
      secondary: aliases[tenant].colors.neutral0,
      tertiary: aliases[tenant].colors.neutral25,
      field: aliases[tenant].colors.white,
      disabled: aliases[tenant].colors.neutral0,
      action1: aliases[tenant].colors.primary100,
      action1Hover: aliases[tenant].colors.primary50,
      action2: aliases[tenant].colors.secondary100,
      action2Hover: aliases[tenant].colors.secondary50,
      action2Light: aliases[tenant].colors.secondary25,
      success: aliases[tenant].colors.success100,
      successHover: aliases[tenant].colors.success50,
      successLight: aliases[tenant].colors.success25,
      error: aliases[tenant].colors.error100,
      errorHover: aliases[tenant].colors.error50,
      errorLight: aliases[tenant].colors.error25,
      warning: aliases[tenant].colors.warning100,
      warningHover: aliases[tenant].colors.warning50,
      warningLight: aliases[tenant].colors.warning25,
      information: aliases[tenant].colors.primary100,
      informationHover: aliases[tenant].colors.primary50,
      informationLight: aliases[tenant].colors.primary25,
    },
    border: {
      primary: aliases[tenant].colors.neutral25,
      primaryHover: aliases[tenant].colors.neutral50,
      divider: aliases[tenant].colors.neutral0,
      action: aliases[tenant].colors.primary100,
      action2: aliases[tenant].colors.secondary100,
      information: aliases[tenant].colors.primary100,
      success: aliases[tenant].colors.success100,
      warning: aliases[tenant].colors.warning100,
      error: aliases[tenant].colors.error100,
    },
    icon: {
      default: aliases[tenant].colors.neutral700,
      primary: aliases[tenant].colors.primary100,
      secondary: aliases[tenant].colors.secondary100,
      neutral: aliases[tenant].colors.neutral100,
      success: aliases[tenant].colors.success100,
      warning: aliases[tenant].colors.warning100,
      error: aliases[tenant].colors.error100,
      onAction: aliases[tenant].colors.white,
    },
    other: {
      type: 'Light',
      overlay: 'Light',
    },
  },
  dark: {
    text: {
      headingPrimary: aliases[tenant].colors.neutral0,
      bodyPrimary: aliases[tenant].colors.neutral25,
      bodySecondary: aliases[tenant].colors.neutral200,
      disabled: aliases[tenant].colors.neutral100,
      onDisabled: aliases[tenant].colors.neutral300,
      action: aliases[tenant].colors.primary100,
      actionHover: aliases[tenant].colors.primary100,
      action2: aliases[tenant].colors.secondary100,
      action2Hover: aliases[tenant].colors.secondary100,
      onAction: aliases[tenant].colors.white,
      success: aliases[tenant].colors.success100,
      successHover: aliases[tenant].colors.success50,
      warning: aliases[tenant].colors.warning100,
      warningHover: aliases[tenant].colors.warning50,
      error: aliases[tenant].colors.error100,
      errorHover: aliases[tenant].colors.error50,
      information: aliases[tenant].colors.primary100,
      informationHover: aliases[tenant].colors.primary50,
    },
    surface: {
      primary: aliases[tenant].colors.neutral500,
      secondary: aliases[tenant].colors.neutral700,
      tertiary: aliases[tenant].colors.neutral400,
      field: aliases[tenant].colors.neutral600,
      disabled: aliases[tenant].colors.neutral400,
      action1: aliases[tenant].colors.primary100,
      action1Hover: aliases[tenant].colors.primary50,
      action2: aliases[tenant].colors.secondary100,
      action2Hover: aliases[tenant].colors.secondary50,
      action2Light: aliases[tenant].colors.secondary25,
      success: aliases[tenant].colors.success100,
      successHover: aliases[tenant].colors.success50,
      successLight: aliases[tenant].colors.success25,
      error: aliases[tenant].colors.error100,
      errorHover: aliases[tenant].colors.error50,
      errorLight: aliases[tenant].colors.error25,
      warning: aliases[tenant].colors.warning100,
      warningHover: aliases[tenant].colors.warning50,
      warningLight: aliases[tenant].colors.warning25,
      information: aliases[tenant].colors.primary100,
      informationHover: aliases[tenant].colors.primary50,
      informationLight: aliases[tenant].colors.primary25,
    },
    border: {
      primary: aliases[tenant].colors.neutral400,
      primaryHover: aliases[tenant].colors.neutral300,
      divider: aliases[tenant].colors.neutral500,
      action: aliases[tenant].colors.primary100,
      action2: aliases[tenant].colors.secondary100,
      information: aliases[tenant].colors.primary100,
      success: aliases[tenant].colors.success100,
      warning: aliases[tenant].colors.warning100,
      error: aliases[tenant].colors.error100,
    },
    icon: {
      default: aliases[tenant].colors.neutral25,
      primary: aliases[tenant].colors.primary100,
      secondary: aliases[tenant].colors.secondary100,
      neutral: aliases[tenant].colors.neutral200,
      success: aliases[tenant].colors.success100,
      warning: aliases[tenant].colors.warning100,
      error: aliases[tenant].colors.error100,
      onAction: aliases[tenant].colors.white,
    },
    other: {
      type: 'Dark',
      overlay: 'Dark',
    },
  },
});

export const metaswissTokens = {
  ...aliases.metaswiss,
  ...tokens('metaswiss').dark,
};

export const greenstateTokens = {
  ...aliases.greenstate,
  ...tokens('greenstate').light,
};
