import { FC } from 'react';

import { IconProps } from './iconProps';

export const PortfolioNftIcon: FC<IconProps> = ({ color = 'none', strokeColor = 'white' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 284 140" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.1" clipPath="url(#clip0_14035_66073)">
        <path
          d="M81.8138 -67.4224L171.389 -81.575C172.565 -81.7609 173.754 -81.3264 174.533 -80.4258L233.873 -11.8482C234.692 -10.9016 234.933 -9.58537 234.503 -8.4097L204.345 74.1079C203.915 75.2836 202.882 76.1339 201.646 76.3292L112.07 90.4818C110.894 90.6677 109.705 90.2332 108.926 89.3327L49.5868 20.755C48.7677 19.8084 48.5263 18.4922 48.9559 17.3165L79.1144 -65.2011C79.5441 -66.3768 80.5774 -67.2271 81.8138 -67.4224Z"
          stroke={strokeColor}
          strokeWidth="9.19122"
        />
        <path
          d="M131.102 20.1416L137.412 2.87618M155.614 -10.0432L143.722 -14.3893L137.412 2.87618M137.412 2.87618L148.894 7.07256M173.937 -3.3465L188.677 2.04083M203.418 7.42815L188.677 2.04083M188.677 2.04083L176.057 36.5717M125.723 -20.9675L113.103 13.5634L96.2422 -31.7422L83.6219 2.78871"
          stroke={strokeColor}
          strokeWidth="9.19122"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_14035_66073">
          <rect width="220.589" height="220.589" fill={strokeColor} transform="translate(76 -137) rotate(20.0763)" />
        </clipPath>
      </defs>
    </svg>
  );
};
