import { IconProps } from './iconProps';

export const TickCheckedCircleIcon: React.FC<IconProps> = ({ color }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9974 2.66406C8.65073 2.66406 2.66406 8.65073 2.66406 15.9974C2.66406 23.3441 8.65073 29.3307 15.9974 29.3307C23.3441 29.3307 29.3307 23.3441 29.3307 15.9974C29.3307 8.65073 23.3441 2.66406 15.9974 2.66406ZM22.3707 12.9307L14.8107 20.4907C14.6241 20.6774 14.3707 20.7841 14.1041 20.7841C13.8374 20.7841 13.5841 20.6774 13.3974 20.4907L9.62406 16.7174C9.2374 16.3307 9.2374 15.6907 9.62406 15.3041C10.0107 14.9174 10.6507 14.9174 11.0374 15.3041L14.1041 18.3707L20.9574 11.5174C21.3441 11.1307 21.9841 11.1307 22.3707 11.5174C22.7574 11.9041 22.7574 12.5307 22.3707 12.9307Z"
        fill={color}
      />
    </svg>
  );
};
