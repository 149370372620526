import { useEffect } from 'react';

import { useAuthContext, AuthConfig } from './authContext';

export const useSetAuthConfig = (config: AuthConfig, deps: unknown[] = []) => {
  const setAuthConfig = useAuthContext((state) => state.setAuthConfig);

  useEffect(() => {
    setAuthConfig(config);
  }, [...deps]);
};
