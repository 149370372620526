import { BaseModal, Button, ErrorFeedback, SuccessFeedback } from '@metaswiss/ui-kit';
import { FC } from 'react';

import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';
import { ResponseButtonWrapper, ResponseWrapper } from '../../styles/verification.styles';
import { useVerificationDocumentContext } from '../context/VerificationDocumentContext';

type Props = {
  isActive: boolean;
  closeModal: () => void;
};

export const ResponseModal: FC<Props> = ({ isActive, closeModal }) => {
  const { textTranslation } = useTextTranslation();

  const { verificationDocumentFlow, setVerificationDocumentFlow } = useVerificationDocumentContext();

  const resetContext = () => {
    setVerificationDocumentFlow({
      ...verificationDocumentFlow,
      file: undefined,
      isSuccess: undefined,
    });
    closeModal();
  };

  return (
    <BaseModal
      isActive={isActive}
      closeModal={closeModal}
      disabledOutsideClick={false}
      position={'fixed'}
      padding={'2.5rem 1rem 1rem 1rem'}
      width={'35rem'}
    >
      <ResponseWrapper>
        {verificationDocumentFlow.isSuccess ? (
          <SuccessFeedback
            title={textTranslation('account.success')}
            subTitle={textTranslation('account.successfullySubmittedDomicile')}
            imageWidth={'16.5rem'}
            contentGap={'2rem'}
            padding={'1.5rem 1rem 0rem 1rem'}
            fill
          />
        ) : (
          <ErrorFeedback
            title={textTranslation('account.somethingWentWrong')}
            subTitle={textTranslation('account.errorDomicile')}
            imageWidth={'15rem'}
            contentGap={'2rem'}
            padding={'1.5rem 1rem 0rem 1rem'}
            fill
          />
        )}
        <ResponseButtonWrapper>
          <Button
            text={
              verificationDocumentFlow.isSuccess
                ? textTranslation('account.continue')
                : textTranslation('account.tryAgain')
            }
            onClick={
              verificationDocumentFlow.isSuccess
                ? resetContext
                : () => setVerificationDocumentFlow({ ...verificationDocumentFlow, isSuccess: undefined })
            }
            fill
          />
        </ResponseButtonWrapper>
      </ResponseWrapper>
    </BaseModal>
  );
};
