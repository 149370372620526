import { BaseCard, Heading, Text } from '@metaswiss/ui-kit';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { StyledContainer } from './detailCard.styles';

type Props = {
  value: string;
  label: string;
};

export const DetailCard: FC<Props> = ({ value, label }) => {
  const theme = useTheme();
  return (
    <BaseCard
      padding={'1rem 4rem'}
      disableStateEffects={true}
      override={{
        borderRadius: theme.v2.radius.small,
        backgroundColor: theme.v2.surface.primary,
        borderColor: theme.v2.border.primary,
      }}
    >
      <StyledContainer>
        <Heading headingType={'h4'} color={theme.v2.text.action} fontWeight={'bold'}>
          {value}
        </Heading>
        <Text palette={'neutrals'} color={theme.v2.text.headingPrimary}>
          {label}
        </Text>
      </StyledContainer>
    </BaseCard>
  );
};
