import { FC } from 'react';

import { IconProps } from './iconProps';

export const AlertIcon: FC<IconProps> = ({ strokeColor = 'red' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 10.9999V6.1999M11 14.5577V14.5999M20.6 10.9999C20.6 16.3018 16.302 20.5999 11 20.5999C5.69809 20.5999 1.40002 16.3018 1.40002 10.9999C1.40002 5.69797 5.69809 1.3999 11 1.3999C16.302 1.3999 20.6 5.69797 20.6 10.9999Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
