import { IconProps } from './iconProps';

export const FaceIdIcon: React.FC<IconProps> = ({ color = 'none', strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 180 148" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M65.999 17.9883H33.999C25.1625 17.9883 17.999 25.1517 17.999 33.9883V65.9883M65.999 161.988H33.999C25.1625 161.988 17.999 154.825 17.999 145.988V113.988M113.999 17.9883H145.999C154.836 17.9883 161.999 25.1517 161.999 33.9883V65.9883M161.999 113.988V145.988C161.999 154.825 154.836 161.988 145.999 161.988H113.999M58.499 68.7275V59.9525M121.499 68.7275V59.9525M64.8939 116.99C75.1551 124.94 98.1655 124.94 112.504 116.99M85.499 95.0525L87.363 93.2351C89.0508 91.5895 89.999 89.3575 89.999 87.0303V64.34"
        stroke={strokeColor}
        strokeWidth="7.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
