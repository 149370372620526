import { IconProps } from './iconProps';

export const IconChecked: React.FC<IconProps> = ({ color, strokeColor = 'white' }) => {
  return (
    <svg viewBox="0 0 25 26" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8 8.3999L9.6405 15.5999L7.20001 13.1456"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
