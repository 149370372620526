import styled from 'styled-components';

import { ColorPalette, RemSize } from '../../../../theme';

export const IconWrapper = styled.div<{
  $palette: ColorPalette;
  $lightBackground: boolean;
}>`
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, $palette, $lightBackground }) =>
    $lightBackground ? `${theme.colors[$palette].hue0}1F` : `${theme.colors[$palette].hue400}1F`};
  border-radius: ${({ theme }) => theme.dimensions.borderRadii.xl};
`;

export const TextWrapper = styled.div<{ $gap: RemSize }>`
  height: 100%;
  display: flex;
  gap: ${({ $gap }) => $gap};
  flex-direction: column;
`;

export const CardWrapper = styled.div`
  display: flex;
  width: auto;
  gap: 1rem;
`;

export const TextContent = styled.div`
  display: flex;
  gap: 0.25rem;
`;

export const TitleLoadingWrapper = styled.div`
  padding: 0.25rem 0;
`;

export const SubtitleLoadingWrapper = styled.div`
  padding-top: 0.5rem;
`;
