export const composeDropdownOptions = <T>(
  items: T[],
  idProp: keyof T,
  translationKeyProp: keyof T,
  textTranslation: (key: string) => string,
  metaProp?: keyof T
) => {
  return items.map((item) => ({
    id: item[idProp],
    label: textTranslation(item[translationKeyProp] as string),
    meta: metaProp ? item[metaProp] : undefined,
  }));
};
