import { FC } from 'react';

import { IconProps } from './iconProps';

export const FileLockIcon: FC<IconProps> = ({ color, strokeColor }) => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.4 50.4001H16.8C12.1608 50.4001 8.40002 46.6393 8.40002 42.0001V14.0001C8.40002 9.36091 12.1608 5.6001 16.8 5.6001H36.4C41.0392 5.6001 44.8 9.36091 44.8 14.0001V21.0001M35 35.0001V32.2001C35 29.8805 36.8804 28.0001 39.2 28.0001C41.5196 28.0001 43.4 29.8805 43.4 32.2001V36.4001M35 16.8001H16.8M26.6 25.2001H16.8M22.4 33.6001H16.8M33.6 50.4001H44.8C46.3464 50.4001 47.6 49.1465 47.6 47.6001V39.2001C47.6 37.6537 46.3464 36.4001 44.8 36.4001H33.6C32.0536 36.4001 30.8 37.6537 30.8 39.2001V47.6001C30.8 49.1465 32.0536 50.4001 33.6 50.4001Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
