import { Button, ButtonColor, ButtonType, Heading, Text } from '@metaswiss/ui-kit';
import { IconProps } from '@metaswiss/ui-kit/src/iconography/iconProps.ts';
import { FC, ReactElement, ReactNode } from 'react';
import { useTheme } from 'styled-components';

import {
  ButtonWrapper,
  IconWrapper,
  NoDataContainer,
  NoDataContentWrapper,
  TextWrapper,
} from './dataStatusPage.styles.ts';

type Props = {
  icon?: ReactElement<IconProps>;
  title: string;
  content: string;
  additionalContent?: string;
  buttonText?: string;
  buttonVariant?: ButtonType;
  buttonColor?: ButtonColor;
  action?: () => void;
  iconWidth?: string;
  iconHeight?: string;
  additionalComponent?: ReactNode | boolean;
};

const DataStatusPage: FC<Props> = ({
  icon,
  title,
  content,
  additionalContent,
  buttonText,
  action,
  iconHeight,
  iconWidth,
  buttonVariant,
  buttonColor,
  additionalComponent,
}) => {
  const theme = useTheme();
  return (
    <NoDataContainer>
      <NoDataContentWrapper $hadAdditionalContent={!!additionalComponent}>
        {icon && (
          <IconWrapper $width={iconWidth} $height={iconHeight}>
            {icon}
          </IconWrapper>
        )}
        <Heading headingType={'h3'} fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
          {title}
        </Heading>
        <TextWrapper>
          <Text color={theme.v2.text.headingPrimary}>{content}</Text>
          {additionalContent && <Text>{additionalContent}</Text>}
        </TextWrapper>
        {buttonText && action && (
          <ButtonWrapper>
            <Button variant={buttonVariant} color={buttonColor} text={buttonText} fill onClick={action} />
          </ButtonWrapper>
        )}
        {additionalComponent && additionalComponent}
      </NoDataContentWrapper>
    </NoDataContainer>
  );
};

export default DataStatusPage;
