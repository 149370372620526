import { BaseModal, Button, ErrorFeedback, SuccessFeedback } from '@metaswiss/ui-kit';
import { FC } from 'react';

import { IdentityVerification } from '../../../../../../enums/identityVerification';
import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';
import { useVerificationDocumentContext } from '../../shared/context/VerificationDocumentContext';
import { ResponseButtonWrapper, ResponseWrapper } from '../../styles/verification.styles';

type Props = {
  isActive: boolean;
  closeModal: () => void;
};

export const ResponseModal: FC<Props> = ({ isActive, closeModal }) => {
  const { textTranslation } = useTextTranslation();
  const { identityProofFlow, setIdentityProofFlow } = useVerificationDocumentContext();

  const resetContext = () => {
    setIdentityProofFlow({
      ...identityProofFlow,
      step: undefined,
      backSide: undefined,
      frontSide: undefined,
      isSuccess: undefined,
      document: undefined,
    });
    closeModal();
  };

  return (
    <BaseModal
      isActive={isActive}
      closeModal={closeModal}
      disabledOutsideClick={false}
      position={'fixed'}
      padding={'2.5rem 1rem 1rem 1rem'}
      width={'35rem'}
    >
      <ResponseWrapper>
        {identityProofFlow.isSuccess ? (
          <SuccessFeedback
            title={textTranslation('account.success')}
            subTitle={
              identityProofFlow.document?.id === IdentityVerification.ID
                ? textTranslation('account.successfullySubmittedId')
                : textTranslation('account.successfullySubmittedPassport')
            }
            imageWidth={'16.5rem'}
            contentGap={'2rem'}
            padding={'1.5rem 1rem 0rem 1rem'}
            fill
          />
        ) : (
          <ErrorFeedback
            title={textTranslation('account.somethingWentWrong')}
            subTitle={
              identityProofFlow.document?.id === IdentityVerification.ID
                ? textTranslation('account.errorId')
                : textTranslation('account.errorPassport')
            }
            imageWidth={'16.5rem'}
            contentGap={'2rem'}
            padding={'1.5rem 1rem 0rem 1rem'}
            fill
          />
        )}
        <ResponseButtonWrapper>
          <Button
            text={
              identityProofFlow.isSuccess ? textTranslation('account.continue') : textTranslation('account.tryAgain')
            }
            onClick={
              identityProofFlow.isSuccess
                ? resetContext
                : () =>
                    setIdentityProofFlow({
                      ...identityProofFlow,
                      step: 1,
                      isSuccess: undefined,
                    })
            }
            fill
          />
        </ResponseButtonWrapper>
      </ResponseWrapper>
    </BaseModal>
  );
};
