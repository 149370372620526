import { ErrorDialog, HeaderCard, PageStateContainer, Text, TextLink, UploadAvatar } from '@metaswiss/ui-kit';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Control, useFormContext, UseFormGetValues, UseFormHandleSubmit, UseFormSetValue } from 'react-hook-form';
import { unstable_useBlocker } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../api/msApi';
import ControlledForm from '../../../../components/controlled-form/ControlledForm.tsx';
import { AssignedUserAssetEnum } from '../../../../enums/assignedUserAssetEnum';
import { ApiResource } from '../../../../enums/resource.enum';
import { UserRole } from '../../../../enums/userRole';
import { AppState, ShellNavigationState, useAppState, useShellNavigationState } from '../../../../global-state/zustand';
import { useBusinesses } from '../../../../hooks/use-businesses/useBusinesses.ts';
import { useCountries } from '../../../../hooks/use-countries/useCountries';
import { useLegalForm } from '../../../../hooks/use-legal-form/useLegalForm.ts';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { findAssetByType } from '../../../../shared/helpers/findAssetByType.helper';
import { getIsUserPictureUploaded } from '../../../../shared/helpers/getIsUserPictureUploaded.ts';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';
import { setFieldValues } from '../../../../shared/helpers/setFieldValues.helper';

import { CorporateUserProfile } from './components/CorporateUserProfile';
import { PrivateUserProfile } from './components/PrivateUserProfile';
import { parseFormObjectCorporateUser } from './mappers/corporateUser.mapper.ts';
import { parseFormObjectPrivateUser } from './mappers/privateUser.mapper';
import {
  CorporateFormData,
  createEditProfileCorporateSchema,
  profileCorporateSchema,
} from './schemas/corporateProfile.schema.ts';
import { createEditProfileSchema, ProfileFormData, profileSchema } from './schemas/profile.schema';
import { AvatarContainer, HeaderContainer } from './styles/profile.styles';

export const ProfileView = () => {
  const theme = useTheme();
  const user = useAppState((state: AppState) => state.user);
  const { findBusiness } = useBusinesses();
  const { findLegalForm } = useLegalForm();
  const { textTranslation } = useTextTranslation();
  const { setIsChangeRouteAvailable } = useShellNavigationState((state: ShellNavigationState) => state);

  const { selectedCitizenship, selectedPhoneNumberPrefix } = useCountries();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [unassignedUrlId, setUnassignedUrlId] = useState<string | undefined>(undefined);
  const [uploadedPicture, setUploadedPicture] = useState<{ fileId?: string; url?: string } | undefined>(undefined);
  const {
    setValue,
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isValid, isDirty },
  } = useFormContext<ProfileFormData | CorporateFormData>();

  const blocker = unstable_useBlocker(isDirty);

  useEffect(() => {
    if (isDirty && blocker.state === 'blocked') {
      setIsModalOpen(true);
    }
  }, [blocker, isDirty]);

  const { data: privateUser, isSuccess: isSuccessPrivateUser } = useQuery({
    queryKey: getQueryKey(ApiResource.PRIVATE_USER, user?.id),
    queryFn: () => api.users.getPrivateUser(user!.id),
    enabled: !!user?.id && user?.role === UserRole.PRIVATE,
  });

  const { data: corporateUser, isSuccess: isSuccessCorporateUser } = useQuery({
    queryKey: getQueryKey(ApiResource.CORPORATE_USER, user?.id),
    queryFn: () => api.users.getCorporateUser(user!.id),
    enabled: !!user?.id && user?.role === UserRole.CORPORATE,
  });

  const { data: assets } = useQuery({
    queryKey: getQueryKey(ApiResource.ASSET, user?.id),
    queryFn: () => {
      return user?.role === UserRole.PRIVATE
        ? api.users.getPrivateUserAssets(user!.id)
        : api.users.getCorporateUserAssets(user!.id);
    },
    enabled: !!user?.role && !!user?.id,
  });

  const isPictureUploaded = getIsUserPictureUploaded(assets);

  useEffect(() => {
    if (isSuccessPrivateUser) {
      reset(
        parseFormObjectPrivateUser(
          privateUser,
          selectedCitizenship(privateUser?.privateUser?.citizenship ?? ''),
          selectedPhoneNumberPrefix(privateUser?.phoneNumberPrefix ?? ''),
          assets
        )
      );
    }
    if (isSuccessCorporateUser) {
      reset(
        parseFormObjectCorporateUser(
          corporateUser,
          findBusiness(corporateUser?.corporateUser.businessFocus ?? ''),
          findLegalForm(corporateUser?.corporateUser?.legalForm ?? ''),
          selectedPhoneNumberPrefix(corporateUser?.phoneNumberPrefix ?? ''),
          assets
        )
      );
    }
  }, [isSuccessPrivateUser, isSuccessCorporateUser]);

  const { data: imageUrl, refetch: refetchImage } = useQuery({
    queryKey: getQueryKey(ApiResource.ASSIGNED_URL, findAssetByType(assets, AssignedUserAssetEnum.PICTURE)?.id),
    queryFn: () => {
      const picture = findAssetByType(assets, AssignedUserAssetEnum.PICTURE);
      if (picture) {
        return api.assets.getS3SignedAssignedUrl({ assetId: picture?.id ?? '' });
      }
    },
    enabled: isPictureUploaded,
  });

  const { mutate, isError } = useMutation({
    mutationFn: (file: File) => api.assets.uploadUnassignedFile({ file: file, fileType: file.type }),
    onSuccess: (response) => {
      if (user?.role === UserRole.PRIVATE) {
        setValue(
          profileSchema.picture,
          { fileId: response?.fileId, url: response?.url },
          { shouldValidate: true, shouldDirty: true }
        );
      }
      if (user?.role === UserRole.CORPORATE) {
        setValue(
          profileCorporateSchema.picture,
          { fileId: response?.fileId, url: response?.url },
          { shouldValidate: true, shouldDirty: true }
        );
      }
      setUnassignedUrlId(response.fileId);
      setUploadedPicture({ fileId: response?.fileId, url: response?.url });
    },
  });

  const { refetch } = useQuery({
    queryKey: getQueryKey(ApiResource.UNASSIGNED_URL),
    queryFn: () => {
      return api.assets.getS3SignedUnassignedUrl({ assetId: getValues('picture').fileId });
    },
    enabled: !!unassignedUrlId,
  });

  useEffect(() => {
    if (assets && imageUrl) {
      const picture = findAssetByType(assets, AssignedUserAssetEnum.PICTURE);
      setUploadedPicture({ url: imageUrl?.url, fileId: picture?.id });
    }
  }, [assets, imageUrl]);

  useEffect(() => {
    if (privateUser) {
      setFieldValues<ProfileFormData>(
        parseFormObjectPrivateUser(
          privateUser,
          selectedCitizenship(privateUser?.privateUser?.citizenship ?? ''),
          selectedPhoneNumberPrefix(privateUser?.phoneNumberPrefix ?? '')
        ),
        setValue as UseFormSetValue<ProfileFormData>
      );
    }
    if (corporateUser) {
      const businessFocus = corporateUser?.corporateUser?.businessFocus;

      if (businessFocus && corporateUser?.corporateUser?.legalForm) {
        setFieldValues<CorporateFormData>(
          parseFormObjectCorporateUser(
            corporateUser,
            findBusiness(corporateUser?.corporateUser.businessFocus ?? ''),
            findLegalForm(corporateUser?.corporateUser?.legalForm ?? ''),
            selectedPhoneNumberPrefix(corporateUser?.phoneNumberPrefix ?? ''),
            assets
          ),
          setValue as UseFormSetValue<CorporateFormData>
        );
      }
    }
  }, [privateUser, corporateUser, assets]);

  const onCancelHandler = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
    setIsChangeRouteAvailable(true);

    reset({}, { keepValues: true });
    if (user?.role === UserRole.PRIVATE && !!privateUser) {
      setFieldValues(
        parseFormObjectPrivateUser(
          privateUser,
          selectedCitizenship(privateUser?.privateUser?.citizenship ?? ''),
          selectedPhoneNumberPrefix(privateUser?.phoneNumberPrefix ?? ''),
          assets
        ),
        setValue
      );
      const picture = findAssetByType(assets, AssignedUserAssetEnum.PICTURE);
      setUploadedPicture({ url: imageUrl?.url, fileId: picture?.id });
      reset(
        parseFormObjectPrivateUser(
          privateUser,
          selectedCitizenship(privateUser?.privateUser?.citizenship ?? ''),
          selectedPhoneNumberPrefix(privateUser?.phoneNumberPrefix ?? ''),
          assets
        )
      );
    }
    if (user?.role === UserRole.CORPORATE && !!corporateUser) {
      setFieldValues(
        parseFormObjectCorporateUser(
          corporateUser,
          findBusiness(corporateUser?.corporateUser?.businessFocus ?? ''),
          findLegalForm(corporateUser?.corporateUser?.legalForm ?? ''),
          selectedPhoneNumberPrefix(corporateUser?.phoneNumberPrefix ?? ''),
          assets
        ),
        setValue
      );
      const picture = findAssetByType(assets, AssignedUserAssetEnum.PICTURE);
      setUploadedPicture({ url: imageUrl?.url, fileId: picture?.id });
      reset(
        parseFormObjectCorporateUser(
          corporateUser,
          findBusiness(corporateUser?.corporateUser?.businessFocus ?? ''),
          findLegalForm(corporateUser?.corporateUser?.legalForm ?? ''),
          selectedPhoneNumberPrefix(corporateUser?.phoneNumberPrefix ?? ''),
          assets
        )
      );
    }
    blocker.proceed?.();
  };

  useEffect(() => {
    if (!unassignedUrlId) return;
    refetch().then((response) => {
      setUploadedPicture((prevState) => ({ url: response.data?.url, fileId: prevState?.fileId }));
    });
  }, [unassignedUrlId]);

  const onUpload = (file: File) => {
    mutate(file);
  };

  const onClose = () => {
    setIsModalOpen(false);
    blocker.reset?.();
  };

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={!user}
      showEmptyState={false}
      showError={false}
      textTranslation={textTranslation}
      maxWidth="44.5rem"
    >
      {isEditMode && (
        <ErrorDialog
          primaryText={textTranslation('account.discardChanges')}
          secondaryText={textTranslation('account.areYouSureDiscard')}
          isOpen={isModalOpen}
          close={onClose}
          primaryButtonText={textTranslation('account.discard')}
          onPrimaryButtonClick={onCancelHandler}
          secondaryButtonText={textTranslation('account.cancel')}
          onSecondaryButtonClick={onClose}
        />
      )}
      <HeaderCard
        header={
          <HeaderContainer>
            <Text fontWeight="bold" color={theme.v2.text.headingPrimary}>
              {textTranslation('account.profile')}
            </Text>
            <TextLink
              textSize="base"
              color={isEditMode ? 'error' : 'primary'}
              padding={'0.25rem'}
              onClick={() => {
                !isEditMode ? setIsEditMode(true) : isDirty ? setIsModalOpen(true) : setIsEditMode(false);
              }}
            >
              {isEditMode ? textTranslation('account.discard') : textTranslation('account.edit')}
            </TextLink>
          </HeaderContainer>
        }
        removeBackground={false}
        height={'fit-content'}
        removeOverflow={true}
      >
        <AvatarContainer>
          <UploadAvatar
            uploadImage={onUpload}
            imageSrc={uploadedPicture?.url}
            isError={isError}
            errorMessage={textTranslation('error.uploadError')}
            disabled={!isEditMode}
            isRequiredError={user?.role === UserRole.CORPORATE && !uploadedPicture?.fileId}
          />
        </AvatarContainer>
        {user?.role === UserRole.PRIVATE && (
          <PrivateUserProfile
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            control={control as Control<ProfileFormData>}
            errors={errors}
            getValues={getValues as UseFormGetValues<ProfileFormData>}
            setValue={setValue as UseFormSetValue<ProfileFormData>}
            handleSubmit={handleSubmit as UseFormHandleSubmit<ProfileFormData>}
            isValid={isValid}
            isDirty={isDirty}
            isPictureUpdated={!!unassignedUrlId}
            cancelHandler={onCancelHandler}
            refetchImage={refetchImage}
            reset={reset}
          />
        )}
        {user?.role === UserRole.CORPORATE && (
          <CorporateUserProfile
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            control={control as Control<CorporateFormData>}
            errors={errors}
            getValues={getValues as UseFormGetValues<CorporateFormData>}
            setValue={setValue as UseFormSetValue<CorporateFormData>}
            handleSubmit={handleSubmit as UseFormHandleSubmit<CorporateFormData>}
            isValid={isValid}
            isDirty={isDirty}
            isPictureUpdated={!!unassignedUrlId}
            cancelHandler={onCancelHandler}
            refetchImage={refetchImage}
            reset={reset}
            setUnassignedUrlId={setUnassignedUrlId}
          />
        )}
      </HeaderCard>
    </PageStateContainer>
  );
};

export const Profile = () => {
  const user = useAppState((state: AppState) => state.user);
  return (
    <ControlledForm
      validationSchema={user?.role === UserRole.PRIVATE ? createEditProfileSchema : createEditProfileCorporateSchema}
    >
      <ProfileView />
    </ControlledForm>
  );
};
