import { FC } from 'react';

import { IconProps } from './iconProps';

export const ClockBackward: FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5194 12.0004C20.5194 17.3023 16.2202 21.6004 10.9169 21.6004C5.61363 21.6004 1.31445 17.3023 1.31445 12.0004C1.31445 6.69846 5.61363 2.40039 10.9169 2.40039C14.4712 2.40039 17.5745 4.33094 19.2348 7.20039M17.8852 13.0539L20.2858 10.6539L22.6865 13.0539M14.5145 14.6181L10.9145 13.4181V8.40039"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
