import { UserResponse } from '@metaswiss/api';
import { mapIsoStringToDate, regionMapper } from '@metaswiss/lib';
import {
  CalendarArrowIcon,
  CardTip,
  CoinUnbrokenIcon,
  ColorPalette,
  CouponIcon,
  ErrorDialog,
  FloatButton,
  Globe,
  PageStateContainer,
  TrendUpIcon,
} from '@metaswiss/ui-kit';
import { CalendarCheck } from '@metaswiss/ui-kit/src/iconography/CalendarCheck.tsx';
import { CalendarIcon } from '@metaswiss/ui-kit/src/iconography/CalendarIcon.tsx';
import { CurrencyDollarIcon } from '@metaswiss/ui-kit/src/iconography/CurrencyDollarIcon.tsx';
import { useQuery } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import { useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi';
import { Documents } from '../../../../../components/documents/Documents';
import { OfferingPopup } from '../../../../../components/offering-popup/OfferingPopup.tsx';
import { ProductDetails } from '../../../../../components/product-details/ProductDetails.tsx';
import { ProductInfo } from '../../../../../components/product-info/ProductInfo.tsx';
import { ProductPrice } from '../../../../../components/product-price/ProductPrice.tsx';
import { CouponsPaid } from '../../../../../enums/couponsPaid.enum.ts';
import { ProductType } from '../../../../../enums/productType.enum';
import { ApiResource } from '../../../../../enums/resource.enum';
import { UserStatus } from '../../../../../enums/userStatus.enum.ts';
import { useAppState, useHeaderOptions, useShellNavigationState } from '../../../../../global-state/zustand';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../router/routes.ts';
import { defaultUser } from '../../../../../shared/helpers/defaultUser.ts';
import { getFormattedLanguageName } from '../../../../../shared/helpers/getFormattedLanguageName.helper.ts';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper';
import { currencyMapper } from '../../../../../shared/mappers/currency.mapper.ts';

import { LeftContentWrapper, RightContentWrapper, UpperRowWrapper } from './singleBond.styles';

const convertString = (documentName: string) => {
  return documentName
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const SingleBond = () => {
  const theme = useTheme();
  const user = useAppState((state) => state.user) || defaultUser;
  const { setUser } = useAppState((state) => state);
  const navigate = useNavigate();
  const { removeTabs } = useShellNavigationState();
  const { setHeaderTitle } = useHeaderOptions();
  const { textTranslation, currentLanguage } = useTextTranslation();
  const { id: bondId } = useParams();

  const [isOpenOfferingPopup, setIsOpenOfferingPopup] = useState<boolean>(false);
  const [isOpenBenefitCard, setIsOpenBenefitCard] = useState<boolean>(false);
  const [isNoResourcesModalOpen, setIsNoResourcesModalOpen] = useState<boolean>(false);

  useLayoutEffect(() => {
    removeTabs();
    setHeaderTitle(textTranslation('offering.bondDetails'));
  }, [removeTabs, textTranslation, setHeaderTitle]);

  const {
    data: bond,
    isLoading: isLoadingBond,
    isError: isErrorBond,
    refetch: refetchBond,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.BOND, `${bondId}/${currentLanguage}`),
    queryFn: () => api.bonds.getBondOfferingById(bondId as string, currentLanguage),
    enabled: !!bondId,
  });

  const {
    data: benefits,
    isLoading: isLoadingBenefits,
    isError: isErrorBenefits,
    refetch: refetchBenefits,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.BOND_BENEFITS, currentLanguage),
    queryFn: () => api.contentful.getSalesInformation(getFormattedLanguageName(currentLanguage), 'bond-benefits'),
  });

  useQuery({
    queryKey: getQueryKey(ApiResource.USER_RESPONSE),
    queryFn: () => {
      return api.users.getUserResponse();
    },
    onSuccess: (response: UserResponse) => {
      setUser(response);
    },
  });

  const isLoading = useMemo(() => isLoadingBond || isLoadingBenefits, [isLoadingBond, isLoadingBenefits]);

  const isError = useMemo(() => isErrorBond || isErrorBenefits, [isErrorBond, isErrorBenefits]);

  const onTryAgain = async () => {
    await Promise.all([refetchBond(), refetchBenefits()]);
  };

  const handleInvestButtonClick = () => {
    if (bond && bond.availableBonds < 0) {
      setIsNoResourcesModalOpen(true);
      return;
    }
    if (user?.isFullyRegistered && user?.isActive && user?.status === UserStatus.APPROVED) {
      navigate(`${routes.payment.root}`, { state: { item: bond, productType: ProductType.BOND_PAPERS } });
    } else {
      setIsOpenOfferingPopup(true);
    }
  };

  const bondInfoPrice = !bond
    ? []
    : [
        {
          icon: CoinUnbrokenIcon,
          iconColor: theme.v2.colors.secondary100,
          text: 'offering.denomination',
          palette: 'secondary' as ColorPalette,
          value: bond.denomination.toString(),
        },
        {
          icon: CalendarIcon,
          iconColor: theme.v2.colors.secondary100,
          text: 'offering.maturity',
          palette: 'secondary' as ColorPalette,
          value: bond.maturity.toString(),
        },
        {
          icon: CalendarArrowIcon,
          iconColor: theme.v2.icon.primary,
          text: 'offering.firstReturn',
          palette: 'primary' as ColorPalette,
          value: textTranslation('offering.return', { value: bond.firstReturn }),
        },
      ];
  const bondInfoItems = [
    {
      icon: CalendarCheck,
      iconColor: theme.v2.text.disabled,
      text: 'offering.issuingDate',
      value: mapIsoStringToDate({ date: bond?.issuingDate ?? '', splitChar: '.' }),
    },
    {
      icon: CouponIcon,
      iconColor: theme.v2.text.disabled,
      text: 'offering.couponsPaid',
      value: textTranslation(couponsPaidMapper(bond?.couponsPaid ?? '')),
    },
    {
      icon: CalendarIcon,
      iconColor: theme.v2.text.disabled,
      text: 'offering.offeringDate',
      value: mapIsoStringToDate({ date: bond?.offeringEndDate ?? '' }),
    },
    {
      icon: Globe,
      iconColor: theme.v2.text.disabled,
      text: 'offering.availableTo',
      value: regionMapper(bond?.regions ?? []),
    },

    {
      icon: CurrencyDollarIcon,
      iconColor: theme.v2.text.disabled,
      text: 'offering.sellInCurrencies',
      value: currencyMapper(bond?.currencies ?? []),
    },
  ];

  const parseDocuments = useMemo(() => {
    return (bond?.documents ?? []).map((document) => ({
      id: document.id,
      name: convertString(document.type),
      url: document.url,
    }));
  }, [bond?.documents]);

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={isLoading}
      showError={true}
      isError={isError}
      showEmptyState={false}
      textTranslation={textTranslation}
      onTryAgain={onTryAgain}
    >
      {/*TODO: We need this so we can lose ! also without this it will fail since render will be passed to HOC*/}
      {bond && (
        <>
          <FloatButton
            label={benefits?.heading[0] || ''}
            onClick={() => setIsOpenBenefitCard(!isOpenBenefitCard)}
            isOpenFloatButtonCard={isOpenBenefitCard}
            closedFloatButtonCard={() => setIsOpenBenefitCard(false)}
            icon={TrendUpIcon}
            backgroundColor={theme.v2.colors.secondary100}
            hoverBackgroundColor={theme.v2.colors.secondary50}
          >
            <AnimatePresence>
              {isOpenBenefitCard && (
                <motion.div
                  initial={{ x: '100%' }}
                  animate={{ x: 0 }}
                  exit={{ x: '120%' }}
                  transition={{ type: 'spring', mass: 1, stiffness: 406, damping: 25 }}
                >
                  <CardTip
                    title={benefits?.heading[0] || ''}
                    descriptionList={benefits?.content || []}
                    icon={TrendUpIcon}
                    borderColor={theme.v2.colors.secondary100}
                    background={theme.v2.surface.action2Light}
                    iconColor={theme.v2.surface.action2}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </FloatButton>
          <OfferingPopup user={user} isOpen={isOpenOfferingPopup} setIsOpen={setIsOpenOfferingPopup} />
          <ErrorDialog
            primaryText={textTranslation('offering.productNotAvailable', { product: 'Bonds' })}
            secondaryText={textTranslation('offering.noProductAvailable', { product: 'Bonds' })}
            isOpen={isNoResourcesModalOpen}
            close={() => setIsNoResourcesModalOpen(false)}
            primaryButtonText={textTranslation('offering.confirm')}
            onPrimaryButtonClick={() => setIsNoResourcesModalOpen(false)}
          />
          <ProductPrice
            formatValue={`${bond?.interestRate.toString()}%`}
            type="offering.annualInterest"
            items={bondInfoPrice}
            handleInvestButtonClick={handleInvestButtonClick}
            isShares={false}
          />
          <UpperRowWrapper>
            <LeftContentWrapper>
              <ProductDetails
                companyInfo={bond.issuer.issuerName}
                address={bond.issuer.address}
                productOffered={bond.faceValue}
                productSold={bond.fundsRaised}
                totalCollected={bond.fundsRaised}
                investors={bond.investors}
                productType={'Bonds'}
                total={bond.totalBonds}
                value={bond.availableBonds}
                remainings={'offering.bondsRemaining'}
                product={ProductType.BOND_PAPERS}
                progressBarEmptyColor={theme.v2.surface.action2Light}
                progressBarFilledColor={theme.v2.colors.secondary100}
                issuerLogoUrl={bond.issuer.issuerLogoUrl}
              />
            </LeftContentWrapper>
            <RightContentWrapper>
              <ProductInfo items={bondInfoItems} headingCardTitle="global.details" product={ProductType.BOND_PAPERS} />
            </RightContentWrapper>
          </UpperRowWrapper>
          {bond?.documents.length > 0 && <Documents documents={parseDocuments} palette="secondary" />}
        </>
      )}
    </PageStateContainer>
  );
};

export default SingleBond;

const couponsPaidMapper = (couponsPaid: string) => {
  switch (couponsPaid) {
    case CouponsPaid.ONCE_A_YEAR:
      return 'offering.onceAYear';
    case CouponsPaid.TWICE_A_YEAR:
      return 'offering.twiceAYear';
    case CouponsPaid.THREE_TIMES_A_YEAR:
      return 'offering.threeTimesAYear';
    case CouponsPaid.FOUR_TIMES_A_YEAR:
      return 'offering.fourTimesAYear';
    default:
      return '';
  }
};
