export enum AssignedUserAssetEnum {
  PICTURE = 'picture',
  PROOF_OF_DOMICILE = 'proof of domicile',
  SIGNATURE = 'signature',
  PASSPORT = 'passport',
  COMMERCIAL_REGISTER = 'commercial register',
  IMAGE = 'image',
  BANNER = 'banner',
  LOGO = 'logo',
  ID_FRONT = 'front side',
  ID_BACK = 'back side',
  THUMBNAIL = 'thumbnail',
}
