import { FieldValues } from 'react-hook-form';

import { FormInputProps } from '../form-input/FormInput';
import { SelectionPopup } from '../selection-popup';
import { ItemProps } from '../selection-popup/types';

export interface InputNumberProps {
  itemList: ItemProps[];
  handleSelectedItem?: (item: ItemProps) => void;
  selectedItem?: ItemProps;
  title: string;
  searchText?: string;
  defaultItem?: ItemProps;
  closeButtonText: string;
  selectButtonText: string;
  containerId?: string;
}

export const InputPhoneNumber = <T extends FieldValues>({
  control,
  name,
  label = '',
  error,
  disabled,
  itemList,
  handleSelectedItem,
  selectedItem,
  title,
  searchText,
  closeButtonText,
  selectButtonText,
  containerId,
  ...props
}: FormInputProps<T> & InputNumberProps) => {
  return (
    <SelectionPopup
      renderInputNumber={true}
      error={error?.message}
      name={name}
      control={control}
      disabled={disabled}
      title={title}
      label={label}
      itemsList={itemList}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onSelect={handleSelectedItem}
      selectedItem={selectedItem || undefined}
      searchText={searchText}
      closeButtonText={closeButtonText}
      selectButtonText={selectButtonText}
      containerId={containerId}
      {...props}
    />
  );
};
