import { BreadcrumbType } from '@metaswiss/ui-kit';

import { NavigationTabProps } from '../../../shared/types/tabProps';

type Props = {
  locationPathNodes: string[];
  breadcrumbs: BreadcrumbType[];
  globalTitle?: string;
  currentTab?: NavigationTabProps;
  textTranslation: (key: string, prop?: {}) => string;
};
const toCamelCase = (str: string) => {
  const string = str.replace('-', ' ').replace(/\//g, '');
  const result = string.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
    index === 0 ? letter.toLowerCase() : letter.toUpperCase()
  );
  return result.replace(/\s+/g, '');
};

export const createHeaderBreadcrumbs = ({
  locationPathNodes,
  breadcrumbs,
  globalTitle,
  currentTab,
  textTranslation,
}: Props) => {
  locationPathNodes.map((item, index) => {
    if (index > 2 && globalTitle) return;
    const currentBreadcrumbPath =
      globalTitle && index === 2 ? locationPathNodes.join('/') : '/' + locationPathNodes.slice(0, index + 1).join('/');
    const isCurrentTabRoute = currentBreadcrumbPath === currentTab?.route;

    const label = isCurrentTabRoute
      ? currentTab.label.replace(/\([^)]+\)/, '').trim()
      : globalTitle && index === 2
        ? globalTitle
        : textTranslation(`routes.${toCamelCase(item)}`);
    breadcrumbs.push({
      id: index.toString(),
      label,
      path: currentBreadcrumbPath,
    });
  });
};
