import { FC } from 'react';
import { useTheme } from 'styled-components';

import { AvatarV2 } from '../../..';
import { Button } from '../../atoms/button';
import { Text } from '../../atoms/text';
import { BaseModal } from '../../organism/modal';

import {
  AvatarWrapperWithoutBorder,
  ButtonContainer,
  DialogContent,
  IconContainer,
  IconWrapper,
  ItemsContainer,
  TextContainer,
  TextWrapper,
} from './styles/dialog.styles';
import { DialogProps } from './types';

export const Dialog: FC<DialogProps> = ({
  isOpen,
  close,
  buttonColor,
  buttonSize = 'large',
  primaryText,
  primaryTextSize = 'lg',
  primaryTextWeight = 'bold',
  secondaryText,
  secondaryTextSize = 'base',
  secondaryTextWeight = 'semi',
  renderIcon,
  primaryButtonText,
  onPrimaryButtonClick,
  secondaryButtonText,
  onSecondaryButtonClick,
  isSecondaryButtonLoading = false,
  iconSize = 'large',
  iconBackgroundColor = 'neutral',
  isPrimaryButtonLoading,
  children,
  avatarImg = false,
  avatarImgPath,
  width = '32.5rem',
  disabledPrimaryButton = false,
  hasChildren = false,
}) => {
  const theme = useTheme();
  const icon = renderIcon?.({ iconSize: iconSize }) ?? renderIcon?.({ iconSize: 'medium' });

  return (
    <BaseModal
      isActive={isOpen}
      closeModal={close}
      position={'fixed'}
      disabledOutsideClick={false}
      padding={'1rem'}
      width={width}
      smallDevicesWidth={'32.5rem'}
    >
      <DialogContent>
        {avatarImg && (
          <AvatarWrapperWithoutBorder>
            <AvatarV2 imagePath={avatarImgPath} customSize={'6rem'} isCursorPointer={false} />
          </AvatarWrapperWithoutBorder>
        )}
        {icon && (
          <IconContainer>
            <IconWrapper $iconBackgroundColor={iconBackgroundColor}>{icon}</IconWrapper>
          </IconContainer>
        )}
        <TextContainer $hasChildren={hasChildren}>
          <Text
            color={theme.v2.text.headingPrimary}
            lineHeight={'h4'}
            fontSize={primaryTextSize}
            fontWeight={primaryTextWeight}
          >
            {primaryText}
          </Text>
          <TextWrapper>
            <Text color={theme.v2.text.disabled} fontSize={secondaryTextSize} fontWeight={secondaryTextWeight}>
              {secondaryText}
            </Text>
          </TextWrapper>
          {children && <ItemsContainer>{children}</ItemsContainer>}
        </TextContainer>
        <ButtonContainer>
          {secondaryText && onSecondaryButtonClick && (
            <Button
              size={buttonSize}
              onClick={onSecondaryButtonClick}
              variant={'outlined'}
              color={'neutral'}
              text={secondaryButtonText}
              loading={isSecondaryButtonLoading}
              fill
            />
          )}
          <Button
            size={buttonSize}
            onClick={onPrimaryButtonClick}
            disabled={disabledPrimaryButton}
            color={buttonColor}
            text={primaryButtonText}
            loading={isPrimaryButtonLoading}
            fill
          />
        </ButtonContainer>
      </DialogContent>
    </BaseModal>
  );
};
