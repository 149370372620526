import styled from 'styled-components';

import { BaseCard } from '../base-card/BaseCard';

export const CardWrapper = styled(BaseCard)`
  position: static;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 100%;
  overflow-y: hidden;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
  position: relative;
`;

export const PriceWrapper = styled.div`
  position: absolute;
  width: fit-content;
  height: 2rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  right: 1rem;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const DescriptionWrapper = styled.div`
  position: relative;
  transition: all 0.3s ease-out;
  overflow-y: auto;
  padding-right: 1rem;
`;
