import React from 'react';

import { IconProps } from './iconProps';

export const NftIcon: React.FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width={'100%'} height={'100%'} viewBox="0 0 52 52" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.01436 14.8809L25.5144 4.60313C25.8164 4.43534 26.1836 4.43534 26.4856 4.60313L44.9856 14.8809C45.3031 15.0573 45.5 15.3919 45.5 15.7551V36.2449C45.5 36.6081 45.3031 36.9427 44.9856 37.1191L26.4856 47.3969C26.1836 47.5647 25.8164 47.5647 25.5144 47.3969L7.01436 37.1191C6.69689 36.9427 6.5 36.6081 6.5 36.2449V15.7551C6.5 15.3919 6.69689 15.0573 7.01436 14.8809Z"
        stroke={strokeColor}
        strokeWidth="3"
      />
      <path
        d="M24.9167 30.3333V26M27.9012 21.6667H24.9167V26M24.9167 26H27.7984M32.5 21.6667H36.1996M39.8992 21.6667H36.1996M36.1996 21.6667V30.3333M20.3992 21.6667V30.3333L13 21.6667V30.3333"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
