import * as zod from 'zod';

import { FileStatus } from '../../../../../../enums/fileStatus';
import { FormNames } from '../../../../../../shared/forms/formNames';

const fileSchema = zod.object({
  fileId: zod.string().optional(),
  url: zod.string().optional(),
  size: zod.string().optional(),
  name: zod.string().optional(),
  status: zod.nativeEnum(FileStatus).optional(),
  file: zod.instanceof(File).optional(),
});

export const createCaseChatSchema = () =>
  zod.object({
    message: zod.string().trim(),
    files: zod.array(fileSchema).optional(),
  });

export const schema = createCaseChatSchema();

export type CreateCaseChatFormData = zod.infer<typeof schema>;

export const caseChatSchema: Required<FormNames<CreateCaseChatFormData>> = {
  message: 'message',
  files: 'files',
};
