import { FC } from 'react';

import { IconProps } from './iconProps.ts';

export const BondTransactionIcon: FC<IconProps> = ({ color = 'none', strokeColor = '#222223' }) => (
  <svg width="100%" height="100%" viewBox="0 0 28 16" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.19922 14.3996L8.36722 7.5073L14.5112 13.415L26.7992 1.59961M26.7992 1.59961H17.5832M26.7992 1.59961V10.4611"
      stroke={strokeColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
