import styled from 'styled-components';

type SpinnerContainerProps = {
  $size?: string;
  $loading?: boolean;
};

export const SpinnerContainer = styled.div<SpinnerContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${({ $loading }) => ($loading ? 'visible' : 'hidden')};
  z-index: ${({ $loading }) => ($loading ? '1' : '-1')};

  &:before {
    width: ${({ $size }) => $size};
    height: ${({ $size }) => $size};
  }
`;
