import { styled } from 'styled-components';

export const IconWrapper = styled.div`
  line-height: ${({ theme }) => theme.typography.lineHeight.extraSmall};
  transition: transform 0.2s ease-out;
`;

export const InteractiveChipContainer = styled.div`
  cursor: pointer;
  width: fit-content;
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.v2.surface.primary};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  border-radius: ${({ theme }) => theme.v2.radius.full};

  &:hover {
    box-shadow:
      0px 0px 16px 0px #00000005,
      0px 1px 0px 0px #00000005,
      0px 10px 24px 0px #00000005;

    ${IconWrapper} {
      transform: translateX(0.25rem);
    }
  }

  &:active {
    box-shadow: none;
  }
`;
