import * as zod from 'zod';

import { FormNames } from '../../../../../../../../shared/forms/formNames';
import { staticTranslationFunction } from '../../../../../../../../shared/forms/staticTranslationFunction.ts';

export const createFullPrivateProfilePhotoSchema = (translate: (key: string) => string) =>
  zod.object({
    image: zod.string().nonempty(translate('error.requiredField')),
  });

export const schema = createFullPrivateProfilePhotoSchema(staticTranslationFunction);

export type FullPrivateRegistrationProfilePhotoData = zod.infer<typeof schema>;

export const fullRegistrationPrivateProfilePhotoSchema: FormNames<FullPrivateRegistrationProfilePhotoData> = {
  image: 'image',
} as const;
