import { useTheme } from 'styled-components';

import { Text, ThemedIcon } from '../..';
import { IconProps } from '../../../iconography/iconProps';
import { TypographyTextSize, TypographyWeight } from '../../../theme/theme';

import { SidebarItemTooltip, StyledItemTextWrapper, StyledSidebarIcon, StyledSidebarItem } from './sidebarItem.styles';

export interface SidebarItemProps {
  label: string;
  onClick: () => void;
  isSelected: boolean;
  value: string;
  textSize?: TypographyTextSize;
  textWeight?: TypographyWeight;
  icon: React.FC<IconProps>;
}

export const SidebarItem = ({ label, onClick, textSize, textWeight, icon, isSelected }: SidebarItemProps) => {
  const theme = useTheme();
  return (
    <StyledSidebarItem $isSelected={isSelected} onClick={onClick}>
      <StyledSidebarIcon $isSelected={isSelected}>
        <ThemedIcon icon={icon} size={'medium'} customStrokeColor={theme.v2.icon.neutral} />
      </StyledSidebarIcon>
      <StyledItemTextWrapper>
        <Text fontSize={textSize ?? 'base'} fontWeight={textWeight ?? 'reg'}>
          {label}
        </Text>
      </StyledItemTextWrapper>
      <SidebarItemTooltip>
        <Text color={theme.v2.text.headingPrimary}>{label}</Text>
      </SidebarItemTooltip>
    </StyledSidebarItem>
  );
};
