import { PaymentBillBody } from '@metaswiss/ui-kit/src/components/molecules/payment-bill/paymentBill.styles';
import { ResponsiveBillContainer } from '@metaswiss/ui-kit/src/components/organism/responsive-bill/responsiveBill.styles';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Wrapper = styled(motion.div)`
  position: relative;
  width: 37.5rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  top: 4rem;
  z-index: 1;

  ${ResponsiveBillContainer} {
    height: auto;
  }

  ${PaymentBillBody} {
    max-height: none;
    min-height: 50.25rem;
    height: auto;
  }
`;

export const UpperContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const IconWrapper = styled.div`
  width: 7rem;
  height: 7rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.v2.surface.primary};
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a;
`;

export const IconSmallerWrapper = styled.div`
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.v2.surface.informationLight};
  border-radius: 0.625rem;
`;

export const ImageHolder = styled.img`
  width: 6.25rem;
  height: 6.25rem;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.v2.radius.smaller};
  overflow: hidden;
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
`;

export const LowerText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AmountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
