import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import { NotificationDot } from '@metaswiss/ui-kit/src/components/molecules/cards/notification-card/notificationCard.styles';
import styled from 'styled-components';

import { NotificationPopup } from './notificationItemTypes';

export const NotificationWrapper = styled.div`
  position: static;
  @media ${breakpoints.laptopSm} {
    position: relative;
  }
`;

export const NotificationButtonWrapper = styled.div`
  position: relative;
`;

export const NotificationPopupHolder = styled.div<NotificationPopup>`
  display: flex;
  z-index: 110;
  right: 0;
  top: 3.4rem;
  transition: all ease-in 0.2s;
  width: ${({ $showNotification }) => ($showNotification ? ' 100%' : '50%')};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  transform: scale(${({ $isOpen }) => ($isOpen ? 1 : 0)});
  transform-origin: right top;
  position: absolute;
  overflow: hidden;
  box-shadow: 0 2rem 4rem -0.75rem rgba(16, 24, 40, 0.14);
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.default};
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  @media ${breakpoints.laptopSm} {
    top: 3rem;
    width: auto;
    transition: all 0.15s ease-in-out;
  }
`;

export const NotificationPopupWrapper = styled.div<{ $showNotification: boolean }>`
  transform: translateX(0);
  flex-grow: 1;
  width: 0;
  background-color: ${({ theme }) => theme.v2.surface.primary};
  border-right: ${({ theme, $showNotification }) =>
    `${$showNotification ? '1px' : '0'} solid ${theme.v2.border.primary}`};
  @media ${breakpoints.laptopSm} {
    width: 27.5rem;
  }
`;

export const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
  width: 100%;
`;

export const NotificationItemWrapper = styled.div<{ $isScrollable: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  overflow: ${($isScrollable) => ($isScrollable ? 'auto' : '')};
  max-height: 33.5rem;
  width: 100%;
`;

export const NotificationDetailsWrapper = styled.div<{ $slideDetails: boolean }>`
  transition: width 0.2s ease-in;
  background-color: ${({ theme }) => theme.v2.surface.field};
  width: ${({ $slideDetails }) => ($slideDetails ? ' 50%' : '0')};
  @media ${breakpoints.laptopSm} {
    width: ${({ $slideDetails }) => ($slideDetails ? ' 27.5rem' : '0')};
    transition: width 0.15s ease-in 0.1s;
    position: relative;
  }
`;

export const NotificationDetailsHolder = styled.div`
  width: 100%;
  display: block;
  height: 100%;
  @media ${breakpoints.laptopSm} {
    width: 27.5rem;
  }
`;

export const NotificationDetailsHeader = styled.div<{ $dynamicWidth: number }>`
  display: flex;
  padding: 1.25rem;
  justify-content: space-between;
  align-items: center;
  width: ${({ $dynamicWidth }) => `${$dynamicWidth}rem`};
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
`;

export const NotificationDetailsContent = styled.div<{ $slideDetails: boolean; $dynamicWidth: number }>`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-y: auto;
  max-height: 33.5rem;
  width: ${({ $dynamicWidth }) => `${$dynamicWidth}rem`};
`;

export const NotificationIconDot = styled(NotificationDot)`
  position: absolute;
  right: 0.75rem;
  bottom: 1.5rem;
  border: 1px solid ${({ theme }) => theme.v2.surface.primary};
`;

export const NotificationErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 1rem;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background: ${({ theme }) => `linear-gradient(to top, ${theme.v2.surface.primary} 50%, transparent)`};
    top: -1.95rem;
    width: 100%;
    height: 3.2rem;
  }
`;

export const NotificationLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  grid-column-end: span 2;
  width: 100%;
`;
