import { NftCollectionV5Response } from '@metaswiss/api';
import { formatAmount, formatAmountWithoutDecimals } from '@metaswiss/lib';
import { Heading, Text, TextLink, breakpointValues } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { api } from '../../api/msApi';
import { AssignedUserAssetEnum } from '../../enums/assignedUserAssetEnum';
import { ApiResource } from '../../enums/resource.enum';
import { AppState, useAppState } from '../../global-state/zustand';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';
import { findAssetByType } from '../../shared/helpers/findAssetByType.helper';
import { getFormattedLanguageName } from '../../shared/helpers/getFormattedLanguageName.helper';
import { getQueryKey } from '../../shared/helpers/getQueryKey.helper';

import {
  BannerContainer,
  BannerImageWrapper,
  BannerWrapper,
  CardsWrapper,
  CollectionContainer,
  ContentContainer,
  ContentWrapper,
  DescriptionWrapper,
  DetailsWrapper,
  LogoContainer,
  LogoWrapper,
  StyledLink,
  UpperDescriptionWrapper,
} from './collectionDetails.styles';
import { maxDescriptionHeight } from './constants/maxDescriptionHeight';
import { DetailCard } from './detail-card/DetailCard';

type Props = {
  nftCollection: NftCollectionV5Response;
};

export const CollectionDetails: FC<Props> = ({ nftCollection }) => {
  const theme = useTheme();
  const { textTranslation, currentLanguage } = useTextTranslation();
  const textDescriptionRef = useRef<HTMLDivElement | null>(null);

  const currency = useAppState((state: AppState) => state.currency);

  const [isReadMoreOn, setIsReadMoreOn] = useState<boolean>(false);
  const [isDescriptionTextOverflowing, setIsDescriptionTextOverflowing] = useState<boolean>(false);

  const nftDescription = useMemo(() => {
    const formattedLanguage = getFormattedLanguageName(currentLanguage);
    return formattedLanguage === 'english' ? nftCollection?.description : nftCollection?.germanDescription;
  }, [currentLanguage, nftCollection]);

  const { data: bannerUrl } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT_COLLECTION_BANNER, nftCollection?.id),
    queryFn: () => {
      const banner = findAssetByType(nftCollection.assets, AssignedUserAssetEnum.BANNER);
      return api.assets.getS3SignedAssignedUrl({ assetId: banner?.id || '' });
    },
  });

  const { data: logoUrl } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT_COLLECTION_LOGO, nftCollection?.id),
    queryFn: () => {
      const logo = findAssetByType(nftCollection.assets, AssignedUserAssetEnum.LOGO);
      return api.assets.getS3SignedAssignedUrl({ assetId: logo?.id || '' });
    },
  });

  const { data: statistics } = useQuery({
    queryKey: getQueryKey(ApiResource.NFT_COLLECTION_STATISTICS, nftCollection?.id),
    queryFn: () => api.nftCollection.getNftCollectionStatistics(nftCollection?.id),
    enabled: !!nftCollection?.id,
  });

  const handleReadMoreAppearance = useCallback(() => {
    if (textDescriptionRef.current && !isReadMoreOn) {
      setIsDescriptionTextOverflowing(
        textDescriptionRef.current.scrollHeight > textDescriptionRef.current.clientHeight
      );
    }
  }, [isReadMoreOn]);

  const handleReadMoreAppearanceOnResize = useCallback(() => {
    handleReadMoreAppearance();

    if (
      textDescriptionRef.current &&
      ((window.innerWidth < breakpointValues.laptopSm &&
        textDescriptionRef.current.clientHeight <= maxDescriptionHeight.smallDevice) ||
        (window.innerWidth >= breakpointValues.laptopSm &&
          textDescriptionRef.current.clientHeight <= maxDescriptionHeight.largeDevice)) &&
      isReadMoreOn
    ) {
      setIsReadMoreOn(false);
    }
  }, [handleReadMoreAppearance, isReadMoreOn]);

  useEffect(() => {
    handleReadMoreAppearance();

    window.addEventListener('resize', handleReadMoreAppearanceOnResize);

    return () => {
      window.removeEventListener('resize', handleReadMoreAppearanceOnResize);
    };
  }, [isReadMoreOn, handleReadMoreAppearance, handleReadMoreAppearanceOnResize]);

  return (
    <CollectionContainer>
      <BannerWrapper>
        <BannerImageWrapper>
          <BannerContainer src={bannerUrl?.url} />
        </BannerImageWrapper>
        <LogoWrapper>
          <LogoContainer src={logoUrl?.url} />
        </LogoWrapper>
      </BannerWrapper>
      <DetailsWrapper>
        <ContentWrapper>
          <Heading headingType={'h4'} fontWeight={'bold'} color={theme.v2.text.headingPrimary} lineHeight={'h4'}>
            {nftCollection?.name}
          </Heading>
          <ContentContainer>
            <UpperDescriptionWrapper>
              <DescriptionWrapper $isReadMoreOn={isReadMoreOn} ref={textDescriptionRef}>
                <Text color={theme.v2.text.disabled}>{nftDescription}</Text>
              </DescriptionWrapper>
              {isDescriptionTextOverflowing && (
                <StyledLink>
                  <TextLink
                    color={isReadMoreOn ? 'error' : 'primary'}
                    onClick={() => setIsReadMoreOn((prevState) => !prevState)}
                  >
                    {isReadMoreOn ? textTranslation('offering.readLess') : textTranslation('offering.readMore')}
                  </TextLink>
                </StyledLink>
              )}
            </UpperDescriptionWrapper>
            <CardsWrapper>
              <DetailCard
                value={formatAmountWithoutDecimals(statistics?.total || 0).replace(/’/g, '.')}
                label={textTranslation('offering.items')}
              />
              <DetailCard
                value={formatAmount(nftCollection?.price, currency?.decimals, currency?.rate, currency?.currencyCode)}
                label={textTranslation('offering.nftPrice')}
              />
            </CardsWrapper>
          </ContentContainer>
        </ContentWrapper>
      </DetailsWrapper>
    </CollectionContainer>
  );
};
