import { styled } from 'styled-components';

export const IdCardUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
export const StepTextContainer = styled.div`
  display: block;
`;
