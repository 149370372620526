import styled, { keyframes } from 'styled-components';

import { IconProps } from './iconProps';

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled.svg<IconProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  animation: ${spinAnimation} 3s linear infinite;
`;

export const SpinnerIcon: React.FC<IconProps> = ({ color = 'none', strokeColor }) => {
  return (
    <StyledSpinner width="100%" height="100%" viewBox="0 0 38 37" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 9.44107V1.8125M19 35.1875V27.5589M28.0589 18.5H35.6875M2.3125 18.5H9.94107M25.4062 12.0947L30.8004 6.70048M7.19898 30.3001L12.5932 24.9059M25.4062 24.9053L30.8004 30.2995M7.19898 6.69991L12.5932 12.0941"
        stroke={strokeColor}
        strokeWidth="3.17857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSpinner>
  );
};
