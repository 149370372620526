/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageableItems } from '../models/PageableItems';
import type { RejectTransactionRequest } from '../models/RejectTransactionRequest';
import type { TransactionDetailInfoResponse } from '../models/TransactionDetailInfoResponse';
import type { TransactionResponse } from '../models/TransactionResponse';
import type { ValidateHashRequest } from '../models/ValidateHashRequest';
import type { ValidateHashResponse } from '../models/ValidateHashResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TransactionsApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param id
   * @returns TransactionResponse Accepted transaction
   * @throws ApiError
   */
  public acceptTransaction(
    id: string,
  ): CancelablePromise<TransactionResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v5/transactions/{id}/accept',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns TransactionResponse Rejected transaction
   * @throws ApiError
   */
  public rejectTransaction(
    id: string,
    requestBody: RejectTransactionRequest,
  ): CancelablePromise<TransactionResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v5/transactions/{id}/reject',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns ValidateHashResponse Validate hash
   * @throws ApiError
   */
  public validateHash(
    requestBody: ValidateHashRequest,
  ): CancelablePromise<ValidateHashResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/transactions/validate-hash',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param offset
   * @param limit
   * @param page
   * @param transactionsForReview
   * @param referenceNumber
   * @param transactionsPending
   * @returns PageableItems Get all transactions
   * @throws ApiError
   */
  public getAllTransactions(
    offset?: number,
    limit?: number,
    page?: number,
    transactionsForReview?: boolean,
    referenceNumber?: string,
    transactionsPending?: string,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/transactions',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'transactionsForReview': transactionsForReview,
        'referenceNumber': referenceNumber,
        'transactionsPending': transactionsPending,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @param offset
   * @param limit
   * @param page
   * @param status
   * @returns PageableItems Get transactions by item id
   * @throws ApiError
   */
  public getTransactionsByItemId(
    id: string,
    offset?: number,
    limit?: number,
    page?: number,
    status?: string,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/transactions/item/{id}',
      path: {
        'id': id,
      },
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'status': status,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @param offset
   * @param limit
   * @param page
   * @returns any Get transaction by user id
   * @throws ApiError
   */
  public getTransactionsByUserId(
    id: string,
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/transactions/user/{id}',
      path: {
        'id': id,
      },
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns TransactionDetailInfoResponse Get transaction from buyer by id
   * @throws ApiError
   */
  public getTransactionDetails(
    id: string,
  ): CancelablePromise<TransactionDetailInfoResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/transactions/buyer/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns any Get invested amount
   * @throws ApiError
   */
  public getShareInvestedAmount(
    id: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/transactions/invested/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param offset
   * @param limit
   * @param page
   * @returns PageableItems Get accepted transactions
   * @throws ApiError
   */
  public getAcceptedTransactions(
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/transactions/accepted',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param userId
   * @param offset
   * @param limit
   * @param page
   * @returns PageableItems Get shareholders ledger
   * @throws ApiError
   */
  public getShareholdersLedger(
    userId: string,
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/transactions/shareholders',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'userId': userId,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public getShareholdersPdf(): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/transactions/shareholders/pdf',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
