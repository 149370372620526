import styled from 'styled-components';

import { DropdownProps } from './types';

export const ProfileDropdownWrapper = styled.div<DropdownProps>`
  width: 18.75rem;
  border-radius: ${({ theme }) => theme.v2.radius.small};
  right: 0;
  margin-top: 0.5rem;
  background-color: ${({ theme }) => theme.v2.surface.primary};
  z-index: 110;
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  transform: scale(${({ $isOpen }) => ($isOpen ? 1 : 0)});
  transition: all 0.2s ease-in-out;
  transform-origin: right top;
  position: absolute;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  box-shadow: 0 0.5rem 0.5rem -0.25rem #10182808;
`;

export const Header = styled.div`
  height: 4.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
`;

export const ProfileInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;

  > span {
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const StatusWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
`;

export const Ellipsis = styled.div<{ $color: 'error' | 'success' | 'warning' }>`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: ${({ theme, $color }) => theme.v2.text[$color]};
`;

export const MenuWrapper = styled.ul`
  li:last-child {
    border-bottom-left-radius: ${({ theme }) => theme.v2.radius.small};
    border-bottom-right-radius: ${({ theme }) => theme.v2.radius.small};
  }
`;

export const MenuItem = styled.li`
  height: 3.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  z-index: 0;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.v2.surface.primary};

  &:hover {
    animation-delay: 1ms;
    background-color: ${({ theme }) => theme.v2.surface.tertiary};
  }

  &:active {
    animation-delay: 1ms;
    background-color: ${({ theme }) => theme.v2.surface.informationLight};
  }
`;

export const MenuOption = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;
