import { IconProps } from './iconProps';

export const BarChartIcon: React.FC<IconProps> = ({ color = 'none', strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 22 22" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.20002 15.8V13.4M11 15.8V11M15.8 15.8V6.20002M3.80002 20.6C2.47454 20.6 1.40002 19.5255 1.40002 18.2V3.80002C1.40002 2.47454 2.47454 1.40002 3.80002 1.40002H18.2C19.5255 1.40002 20.6 2.47454 20.6 3.80002V18.2C20.6 19.5255 19.5255 20.6 18.2 20.6H3.80002Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
