import { FC } from 'react';

import { IconProps } from './iconProps';

export const Line2: FC<IconProps> = ({ strokeColor = 'white', color = 'none' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 171 2" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 1H169.5" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
