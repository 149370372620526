import { FC } from 'react';

import { IconProps } from './iconProps';

export const CoinsDouble: FC<IconProps> = ({ strokeColor = '#A752E0' }) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4933 25.8821C17.0925 25.9594 16.6779 26 16.2533 26C12.7997 26 10 23.3137 10 20C10 16.6863 12.7997 14 16.2533 14C16.6779 14 17.0925 14.0406 17.4933 14.118M30 20C30 23.3137 27.2003 26 23.7467 26C20.293 26 17.4933 23.3137 17.4933 20C17.4933 16.6863 20.293 14 23.7467 14C27.2003 14 30 16.6863 30 20Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
