import { RemSize } from '../../../theme';

import { AcceptedFileTypes } from './enums';

export interface UploadFileProps {
  name?: string;
  id?: string;
  isLoading?: boolean;
  uploadFile?: (file: File) => void;
  value?: { fileId?: string; url?: string };
  isError?: boolean;
  edit?: boolean;
  onViewDocument: () => void;
  onDelete?: (name: string) => void;
  className?: string;
  disabled?: boolean;
  acceptedFileTypes?: AcceptedFileTypes;
  viewFileTitle?: string;
  viewFileSubtitle?: string;
  initialTitle: string;
  initialSubtitle: string;
  invalidTitle: string;
  invalidSubtitle: string;
  failedTitle: string;
  failedSubtitle: string;
  $width: string;
  $height: string;
  imgContainerPadding?: RemSize;
}

export const imageFileTypes = (acceptedFileTypes: AcceptedFileTypes) => ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'image/png': acceptedFileTypes !== AcceptedFileTypes.DOCUMENTS ? ['.png'] : [],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'image/jpg': acceptedFileTypes !== AcceptedFileTypes.DOCUMENTS ? ['.jpg'] : [],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'image/jpeg': acceptedFileTypes !== AcceptedFileTypes.DOCUMENTS ? ['.jpeg'] : [],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'image/gif': acceptedFileTypes !== AcceptedFileTypes.DOCUMENTS ? ['.gif'] : [],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'image/svg+xml': acceptedFileTypes !== AcceptedFileTypes.DOCUMENTS ? ['.svg'] : [],
});

export const documentFileTypes = (acceptedFileTypes: AcceptedFileTypes) => ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'application/pdf': acceptedFileTypes !== AcceptedFileTypes.IMAGES ? ['.pdf'] : [],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    acceptedFileTypes !== AcceptedFileTypes.DOCUMENTS ? ['.docx'] : [],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'application/msword': acceptedFileTypes !== AcceptedFileTypes.DOCUMENTS ? ['.doc'] : [],
});

export const csvFileTypes = (acceptedFileTypes: AcceptedFileTypes) => ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'application/vnd.ms-excel': acceptedFileTypes !== AcceptedFileTypes.DOCUMENTS ? ['.xls'] : [],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    acceptedFileTypes !== AcceptedFileTypes.DOCUMENTS ? ['.xlsx'] : [],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'text/csv': acceptedFileTypes !== AcceptedFileTypes.DOCUMENTS ? ['.csv'] : [],
});
