import { styled } from 'styled-components';

import { RemSize } from '../../..';

export const ProgressBarWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

export const StyledProgressBar = styled.div<{ $height: RemSize | 'auto' | '100%'; $backgroundColor: string }>`
  position: relative;
  width: 100%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  overflow: hidden;
  height: ${({ $height }) => $height};
  border-radius: ${({ theme }) => theme.dimensions.borderRadii.xxl};
`;

export const DisplayProgressBar = styled.div<{ $width: string; $backgroundColor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${({ $width }) => $width};
  border-radius: ${({ theme }) => theme.dimensions.borderRadii.large};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

export const ProgressBarTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const ProgressBarContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  ${ProgressBarTextWrapper}:last-child {
    align-items: flex-end;
  }
`;
