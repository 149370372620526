import {
  ArrowChartIcon,
  BarChartNoBorderIcon,
  CollectibleIcon,
  Heading,
  NftIcon,
  Text,
  ThemedIcon,
  IconProps,
} from '@metaswiss/ui-kit';
import { ArrowRotateRight } from '@metaswiss/ui-kit/src/iconography/ArrowRotateRight';
import { CoinNumber } from '@metaswiss/ui-kit/src/iconography/CoinNumber';
import { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { TransactionType } from '../../../../../../enums/transactionType';
import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';
import { tenantConfig } from '../../../../../../tenantConfig.ts';

import {
  BondCard,
  BondDescriptionWrapper,
  BondIconWrapper,
  BondWrapper,
  DescriptionWrapper,
  DescriptionWrapperSmall,
  IconWrapper,
  PortfolioCardTextWrapper,
  StyledContentWrapper,
  StyledPortfolioCard,
  StyledTextContainer,
  TextAboveHeader,
} from './portfolioCard.styles';

export type PortfolioCardProps = {
  price: string;
  title: string;
  count: string | number;
  titleCurrency: string;
  description: string;
  onClick?: () => void;
} & ({ type: TransactionType } | { type: TransactionType.BOND; bond: BondCardProps });

export type BondCardProps = {
  priceCoupon: string;
  priceReturn: string;
};

export const PortfolioCard: FC<PortfolioCardProps> = (props) => {
  const { textTranslation } = useTextTranslation();
  const theme = useTheme();
  const { title, count, description, price, type, onClick, titleCurrency } = props as PortfolioCardProps;

  const isBond = (type as TransactionType) === TransactionType.BOND;
  const bond = isBond ? (props as { type: TransactionType.BOND; bond: BondCardProps }).bond : undefined;

  const { color, icon } = useMemo(() => getTransactionType(type), [type]);

  return (
    <StyledPortfolioCard
      disableStateEffects
      onClick={onClick}
      override={{
        backgroundColor: theme.v2.surface.primary,
        borderColor: theme.v2.border.primary,
        borderRadius: theme.v2.radius.small,
      }}
    >
      <StyledContentWrapper>
        <IconWrapper $color={color}>
          <ThemedIcon icon={icon} size={'full'} customStrokeColor={theme.v2.text[color]} />
        </IconWrapper>
        <StyledTextContainer>
          <TextAboveHeader>{title}</TextAboveHeader>
          <DescriptionWrapper>
            <Text fontSize={'lg'} lineHeight={'h4'} fontWeight={'semi'} color={theme.v2.text.headingPrimary}>
              {count.toString()}
            </Text>
            <Text color={theme.v2.text.headingPrimary}>{description}</Text>
          </DescriptionWrapper>
        </StyledTextContainer>
      </StyledContentWrapper>
      <PortfolioCardTextWrapper>
        <DescriptionWrapper>
          <Heading headingType={'h4'} fontWeight={'bold'} lineHeight={'h4'} color={theme.v2.text[color]}>
            {price.toString()}
          </Heading>
          <Text fontSize={'lg'} lineHeight={'h4'} color={theme.v2.text.headingPrimary}>
            {titleCurrency}
          </Text>
        </DescriptionWrapper>
      </PortfolioCardTextWrapper>
      {type === TransactionType.BOND && (
        <BondWrapper>
          <BondCard
            disableStateEffects
            onClick={onClick}
            override={{
              backgroundColor: theme.v2.surface.primary,
              borderColor: theme.v2.border.primary,
              borderRadius: theme.v2.radius.smaller,
            }}
          >
            <BondIconWrapper>
              <ThemedIcon
                icon={CoinNumber}
                palette={'neutrals'}
                size={'full'}
                customStrokeColor={theme.v2.icon.default}
                customColor={theme.v2.surface.tertiary}
              />
            </BondIconWrapper>
            <BondDescriptionWrapper>
              <Text fontSize="sm" lineHeight="medium">
                {textTranslation('portfolio.totalCoupons')}
              </Text>
              <DescriptionWrapperSmall>
                <Text fontSize="sm" lineHeight="medium" fontWeight="bold">
                  {String(bond?.priceCoupon)}
                </Text>
              </DescriptionWrapperSmall>
            </BondDescriptionWrapper>
          </BondCard>
          <BondCard
            disableStateEffects
            onClick={onClick}
            override={{
              backgroundColor: theme.v2.surface.primary,
              borderColor: theme.v2.border.primary,
              borderRadius: theme.v2.radius.smaller,
            }}
          >
            <BondIconWrapper>
              <ThemedIcon
                icon={ArrowRotateRight}
                palette={'neutrals'}
                size={'full'}
                customStrokeColor={theme.v2.icon.default}
                customColor={theme.v2.surface.tertiary}
              />
            </BondIconWrapper>
            <BondDescriptionWrapper>
              <Text fontSize="sm" lineHeight="medium">
                {textTranslation('portfolio.totalReturn')}
              </Text>
              <DescriptionWrapper>
                <Text fontSize="sm" lineHeight="medium" fontWeight="bold">
                  {String(bond?.priceReturn)}
                </Text>
              </DescriptionWrapper>
            </BondDescriptionWrapper>
          </BondCard>
        </BondWrapper>
      )}
    </StyledPortfolioCard>
  );
};

function getTransactionType(type: string) {
  let color: 'success' | 'information' | 'action2';
  let icon: React.FC<IconProps> | undefined;
  switch (type) {
    case TransactionType.NFT:
      icon = tenantConfig.useNftName ? NftIcon : CollectibleIcon;
      color = 'success';
      break;
    case TransactionType.SHARE:
      icon = BarChartNoBorderIcon;
      color = 'information';
      break;
    case TransactionType.BOND:
      icon = ArrowChartIcon;
      color = 'action2';
      break;
    default:
      icon = ArrowChartIcon;
      color = 'action2';
  }

  return { color, icon };
}
