import { useEffect } from 'react';

import { useAppState } from '../../global-state/zustand.ts';

type Colors = 'purple' | 'blue' | 'green';
export const useDefineBackgroundColors = (leftColor?: Colors, rightColor?: Colors) => {
  const setBlurredSection = useAppState((state) => state.setBlurredSection);

  useEffect(() => {
    leftColor && setBlurredSection('left', leftColor);
    rightColor && setBlurredSection('right', rightColor);
    return () => {
      setBlurredSection('left', 'purple');
      setBlurredSection('right', 'blue');
    };
  }, [leftColor, rightColor, setBlurredSection]);
};
