import { styled } from 'styled-components';

import { TypographyLineHeight, TypographySize, TypographyWeight } from '../../../../theme';
import { ButtonColor } from '../../button';

type Props = {
  disabled?: boolean;
  $color: ButtonColor;
  $textSize: TypographySize;
  $padding: string;
  $fontWeight: TypographyWeight;
  $lineHeight: TypographyLineHeight;
  $fill: boolean;
};

export const TextLinkButton = styled.button.attrs({
  type: 'button',
})<Props>`
  width: ${({ $fill }) => $fill && '100%'};
  padding: ${({ $padding }) => $padding};
  font-size: ${({ theme, $textSize }) => theme.typography.sizes[$textSize]};
  font-weight: ${({ theme, $fontWeight }) => theme.typography.weights[$fontWeight]};
  color: ${({ theme, $color }) => theme.v2.colors[`${$color}100`]};
  user-select: none;
  line-height: ${({ theme, $lineHeight }) => theme.typography.lineHeight[$lineHeight]};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  path {
    stroke: ${({ theme, $color }) => theme.v2.colors[`${$color}100`]};
  }

  &:hover {
    color: ${({ theme, $color }) => theme.v2.colors[`${$color}50`]};
    path {
      stroke: ${({ theme, $color }) => theme.v2.colors[`${$color}50`]};
    }
  }

  &:active {
    color: ${({ theme, $color }) => theme.v2.colors[`${$color}200`]};
    path {
      stroke: ${({ theme, $color }) => theme.v2.colors[`${$color}200`]};
    }
  }

  &:disabled {
    color: ${({ theme }) => theme.v2.text.disabled};
    path {
      stroke: ${({ theme }) => theme.v2.text.disabled};
    }
    cursor: not-allowed;
  }
`;
