import { IconProps } from './iconProps';

export const ArrowRefresh02: React.FC<IconProps> = ({ strokeColor }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.075 9.59922C24.8511 5.77329 20.6944 3.19922 15.9337 3.19922C10.5358 3.19922 5.91458 6.50829 4.00654 11.1992M23.1686 11.1992H29.5996V4.79922M4.9242 22.3992C7.14811 26.2251 11.3048 28.7992 16.0656 28.7992C21.4634 28.7992 26.0846 25.4901 27.9927 20.7992M8.83064 20.7992H2.39961V27.1992"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
