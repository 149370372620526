import { styled } from 'styled-components';

import { RemSize } from '../../../../theme';

export const SectionHeaderContainer = styled.div<{ $height?: RemSize }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ $height }) => $height || 'auto'};
`;
