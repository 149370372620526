import { CurrencyResponse, UserResponse } from '@metaswiss/api';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { FilterItem } from '../pages/protected/account/transactions/all-transactions/AllTransactions';
import { NavigationTabProps } from '../shared/types/tabProps';

export interface AppState {
  user?: UserResponse;
  setUser: (user?: UserResponse) => void;
  removeUser: () => void;
  isError: boolean;
  setIsError: (isError: boolean) => void;
  currency: CurrencyResponse | undefined;
  redirectingFromVerifyViaMobile: boolean;
  setCurrency: (currency?: CurrencyResponse) => void;
  vat?: number;
  setVat: (vat: number) => void;
  sidebarVisible: boolean;
  headerVisible: boolean;
  setSidebarVisible: (sidebarVisible: boolean) => void;
  setHeaderVisible: (headerVisible: boolean) => void;
  setRedirectingFromVerifyViaMobile: (redirectingFromVerifyViaMobile: boolean) => void;
  isMaintenanceMode: boolean;
  leftBlurredSection: 'purple' | 'blue' | 'green';
  rightBlurredSection: 'purple' | 'blue' | 'green';
  setIsMaintenanceMode: (isMaintainanceMode: boolean) => void;
  setBlurredSection: (section: 'left' | 'right', color: 'purple' | 'blue' | 'green') => void;
}

export const useAppState = create<AppState>()(
  persist(
    devtools(
      immer((set) => ({
        user: undefined,
        isError: false,
        currency: undefined,
        vat: undefined,

        sidebarVisible: true,
        headerVisible: true,
        redirectingFromVerifyViaMobile: false,
        isMaintenanceMode: false,
        leftBlurredSection: 'purple',
        rightBlurredSection: 'blue',
        setUser: (user) =>
          set((state) => {
            state.user = user;
          }),

        removeUser: () =>
          set((state) => {
            state.user = undefined;
          }),

        setIsError: (isError) =>
          set((state) => {
            state.isError = isError;
          }),
        setCurrency: (currency) =>
          set((state) => {
            state.currency = currency;
          }),
        setVat: (vat) =>
          set((state) => {
            state.vat = vat;
          }),
        setSidebarVisible: (sidebarVisible) =>
          set((state) => {
            state.sidebarVisible = sidebarVisible;
          }),
        setHeaderVisible: (headerVisible) =>
          set((state) => {
            state.headerVisible = headerVisible;
          }),
        setRedirectingFromVerifyViaMobile: (redirectingFromVerifyViaMobile) =>
          set((state) => {
            state.redirectingFromVerifyViaMobile = redirectingFromVerifyViaMobile;
          }),
        setIsMaintenanceMode: (isMaintenanceMode) =>
          set((state) => {
            state.isMaintenanceMode = isMaintenanceMode;
          }),
        setBlurredSection: (section: 'left' | 'right', color: 'purple' | 'blue' | 'green') =>
          set((state) => {
            if (section === 'left') {
              state.leftBlurredSection = color;
            } else {
              state.rightBlurredSection = color;
            }
          }),
      }))
    ),
    {
      name: 'global-state',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

const ICON_NUMBER = 4;

export interface HeaderStoreOptions {
  search: {
    value: string;
    isUpdating: boolean;
  };
  showLogo: boolean;
  showTitle: boolean;
  showBreadcrumbs: boolean;
  showIcons: boolean[];
  headerTitle: string;
  headerLoading: boolean;
  isFilterButtonDisplayed: boolean;
  inputAction: (() => void) | undefined;
  buttonAction: (() => void) | undefined;
  setShowLogo: (showLogo: boolean) => void;
  setShowTitle: (showTitle: boolean) => void;
  setShowBreadcrumbs: (showBreadcrumbs: boolean) => void;
  setShowIcons: (showIcons: boolean[]) => void;
  setInputAction: (inputAction: () => void) => void;
  setButtonAction: (buttonAction: () => void) => void;
  setHeaderTitle: (headerTitle: string) => void;
  setHeaderLoading: (headerLoading: boolean) => void;
  isFilterMenuOpen: boolean;
  setIsFilterDisplayed: (value: boolean) => void;
  selectedFilters: FilterItem[];
  setSelectedFilters: (selectedFilters: FilterItem[]) => void;
  setSearch: (value: string, isUpdating: boolean) => void;
  setisFilterButtonDisplayed: (isFilterDisplayed: boolean) => void;
}

export const useHeaderOptions = create<HeaderStoreOptions>()(
  devtools(
    immer((set) => ({
      search: {
        value: '',
        isUpdating: false,
      },
      showLogo: true,
      showTitle: true,
      showBreadcrumbs: true,
      inputAction: undefined,
      buttonAction: undefined,
      showIcons: Array.from({ length: ICON_NUMBER }, () => true),
      headerTitle: '',
      headerLoading: false,
      isFilterMenuOpen: false,
      selectedFilters: [],
      isFilterButtonDisplayed: false,

      setSearch: (value: string, isUpdating: boolean) => {
        set((state) => {
          state.search = {
            value,
            isUpdating,
          };
        });
      },

      setShowLogo: (showLogo: boolean) => {
        set((state) => {
          state.showLogo = showLogo;
        });
      },

      setShowTitle: (showTitle: boolean) => {
        set((state) => {
          state.showTitle = showTitle;
        });
      },

      setShowBreadcrumbs: (showBreadcrumbs: boolean) => {
        set((state) => {
          state.showBreadcrumbs = showBreadcrumbs;
        });
      },

      setInputAction: (newFunction: () => void) => {
        set((state) => {
          state.inputAction = newFunction;
        });
      },

      setButtonAction: (newFunction: () => void) => {
        set((state) => {
          state.buttonAction = newFunction;
        });
      },

      setShowButton: (showBreadcrumbs: boolean) => {
        set((state) => {
          state.showBreadcrumbs = showBreadcrumbs;
        });
      },

      setShowIcons: (showIcons: boolean[]) => {
        set((state) => {
          state.showIcons = showIcons;
        });
      },

      setHeaderTitle: (title: string) => {
        set((state) => {
          state.headerTitle = title;
        });
      },

      setHeaderLoading: (loading: boolean) => {
        set((state) => {
          state.headerLoading = loading;
        });
      },

      setIsFilterDisplayed: (isOpen: boolean) => {
        set((state) => {
          state.isFilterMenuOpen = isOpen;
        });
      },

      setSelectedFilters: (selectedFilters: FilterItem[]) => {
        set((state) => {
          state.selectedFilters = selectedFilters;
        });
      },

      setisFilterButtonDisplayed: (isFilterDisplayed: boolean) => {
        set((state) => {
          state.isFilterButtonDisplayed = isFilterDisplayed;
        });
      },
    }))
  )
);

export interface ShellNavigationState {
  tabs?: NavigationTabProps[];
  selectedTab?: NavigationTabProps;
  setCurrentTabs: (tabs: NavigationTabProps[]) => void;
  setSelectedTab: (tab: NavigationTabProps) => void;
  removeTabs: () => void;
  isChangeRouteAvailable: boolean;
  setIsChangeRouteAvailable: (isChangeRouteAvailable: boolean) => void;
  allowInfiniteScroll: boolean;
  setAllowInfiniteScroll: (allowInfiniteScroll: boolean) => void;
}

export const useShellNavigationState = create<ShellNavigationState>()(
  devtools(
    immer((set) => ({
      tabs: undefined,
      selectedTab: undefined,
      isChangeRouteAvailable: true,
      allowInfiniteScroll: false,
      setCurrentTabs: (tabs) =>
        set((state) => {
          state.tabs = tabs;
        }),

      setSelectedTab: (tab) =>
        set((state) => {
          state.selectedTab = tab;
        }),

      setIsChangeRouteAvailable: (isChangeRouteAvailable) =>
        set((state) => {
          state.isChangeRouteAvailable = isChangeRouteAvailable;
        }),

      removeTabs: () =>
        set((state) => {
          state.selectedTab = undefined;
          state.tabs = undefined;
        }),

      setAllowInfiniteScroll: (allowInfiniteScroll) =>
        set((state) => {
          state.allowInfiniteScroll = allowInfiniteScroll;
        }),
    }))
  )
);
