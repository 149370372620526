/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AboutUsResponse } from '../models/AboutUsResponse';
import type { CompanyDocumentResponse } from '../models/CompanyDocumentResponse';
import type { ContentfulImagesResponse } from '../models/ContentfulImagesResponse';
import type { PosterResponse } from '../models/PosterResponse';
import type { SalesInformationResponse } from '../models/SalesInformationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ContentfulApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param language
   * @param offset
   * @param limit
   * @param page
   * @returns AboutUsResponse
   * @throws ApiError
   */
  public getAboutUs(
    language: string,
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<AboutUsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/contentful/about-us',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'language': language,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param language
   * @param name
   * @param offset
   * @param limit
   * @param page
   * @returns PosterResponse
   * @throws ApiError
   */
  public getPoster(
    language: string,
    name: string,
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PosterResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/contentful/poster',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'language': language,
        'name': name,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param language
   * @param offset
   * @param limit
   * @param page
   * @returns CompanyDocumentResponse
   * @throws ApiError
   */
  public getCompanyDetails(
    language: string,
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<Array<CompanyDocumentResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/contentful/company-documents',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'language': language,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param language
   * @param offset
   * @param limit
   * @param page
   * @returns any
   * @throws ApiError
   */
  public getNewsletters(
    language: string,
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/contentful/newsletters',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'language': language,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param language
   * @param type
   * @returns SalesInformationResponse
   * @throws ApiError
   */
  public getSalesInformation(
    language: string,
    type: string,
  ): CancelablePromise<SalesInformationResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/contentful/sales-information',
      query: {
        'language': language,
        'type': type,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns ContentfulImagesResponse
   * @throws ApiError
   */
  public getImages(): CancelablePromise<ContentfulImagesResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/contentful/images',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

}
