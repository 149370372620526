import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

const boxShadow = css`
  box-shadow:
    0px 0px 16px 0px #00000005,
    0px 1px 0px 0px #00000005,
    0px 10px 24px 0px #00000005;
`;

export const ExpandableCardWrapper = styled(motion.div)<{ $isAlwaysOpen: boolean }>`
  cursor: ${({ $isAlwaysOpen }) => !$isAlwaysOpen && 'pointer'};
  background-color: ${({ theme }) => theme.v2.surface.primary};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.large};
  padding: 1rem;
  overflow: hidden;

  ${({ $isAlwaysOpen }) => $isAlwaysOpen && boxShadow};

  &:hover {
    ${boxShadow};
  }
`;

export const CardHeader = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardBody = styled(motion.div)`
  padding-top: 1rem;
`;

export const DropdownIconWrapper = styled(motion.div)`
  flex-shrink: 0;
`;
