/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NftCollectionAssetsResponse } from '../models/NftCollectionAssetsResponse';
import type { NftCollectionV5Response } from '../models/NftCollectionV5Response';
import type { PageableItems } from '../models/PageableItems';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NftCollectionApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param offset
   * @param limit
   * @param page
   * @returns any Get All NFT Collections
   * @throws ApiError
   */
  public getAllNftCollections(
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/nft-collection',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns NftCollectionV5Response Get NFT Collection by id
   * @throws ApiError
   */
  public getNftCollectionById(
    id: string,
  ): CancelablePromise<NftCollectionV5Response> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/nft-collection/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns NftCollectionAssetsResponse Get NFT Collection Assets by id
   * @throws ApiError
   */
  public getNftCollectionAssetsById(
    id: string,
  ): CancelablePromise<Array<NftCollectionAssetsResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/nft-collection/{id}/assets',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns any Get NFT Collection statistics
   * @throws ApiError
   */
  public getNftCollectionStatistics(
    id: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/nft-collection/{id}/statistics',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
