import React from 'react';

import { IconProps } from './iconProps';

export const ArrowChartIcon: React.FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width={'100%'} height={'100%'} viewBox="0 0 53 52" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.86646 36.4L17.5145 25.2L27.4984 34.8L47.4664 15.6M47.4664 15.6H32.4905M47.4664 15.6V30"
        stroke={strokeColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
