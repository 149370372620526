import { tenantConfig } from '../../../../../tenantConfig';
import { AllShares } from '../all-shares/AllShares';
import { SingleShare } from '../single-share/SingleShare';

export const SharesHandler = () => {
  if (!tenantConfig.singleShare) {
    return <AllShares />;
  }
  return <SingleShare />;
};
