import styled from 'styled-components';

import { Padding } from '../../../../theme/theme';

type CardContainerProps = {
  $size: string;
  $isSelected: boolean;
  $padding?: Padding;
  $isPaymentCard?: boolean;
  $isDisabled?: boolean;
};

export const TooltipHolder = styled.div`
  position: absolute;
  display: none;
  z-index: 3;
  max-width: 28.75rem;
  width: 100%;
  top: -0.75rem;
  left: 50%;
  transform: translate(-50%, -100%);

  &::before {
    content: '';
    position: absolute;
    width: 0.75rem;
    height: 0.75rem;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: ${({ theme }) => theme.v2.surface.tertiary};
    border-radius: ${({ theme }) => theme.dimensions.borderRadii.small};
  }
`;

export const TooltipContent = styled.div<{ $backgroundColor?: string }>`
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  align-items: center;
  text-align: center;
  background-color: ${({ theme, $backgroundColor }) => $backgroundColor || theme.v2.surface.tertiary};
`;

export const CardContainer = styled.div<CardContainerProps>`
  position: ${({ $isPaymentCard }) => ($isPaymentCard ? 'static' : 'relative')};
  max-width: ${({ $size }) => $size};
  background-color: ${({ theme, $isDisabled }) => ($isDisabled ? theme.v2.surface.secondary : theme.v2.surface.field)};
  border: ${({ theme, $isSelected, $isPaymentCard }) =>
    $isSelected
      ? `${$isPaymentCard ? '1px' : '2px'} solid ${theme.v2.border.action}`
      : `1px solid ${theme.v2.border.primary}`};
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.large};
  padding: ${({ $padding }) => $padding ?? '1.5rem'};
  cursor: ${({ $isDisabled }) => !$isDisabled && 'pointer'};

  &:hover {
    box-shadow: ${({ $isDisabled, $isPaymentCard }) =>
      !$isDisabled &&
      !$isPaymentCard &&
      '0px 0px 16px 0px rgba(0, 0, 0, 0.02), 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 10px 24px 0px rgba(0, 0, 0, 0.02)'};
    background-color: ${({ theme, $isDisabled, $isPaymentCard, $isSelected }) =>
      !$isDisabled && $isPaymentCard && !$isSelected && theme.v2.surface.secondary};

    ${TooltipHolder} {
      display: initial;
    }
  }
`;
