import styled from 'styled-components';

export const TitleWrapper = styled.div`
  text-align: center;
  padding: 0.5rem 0;
`;

export const SelectedOptionTitleWrapper = styled.div<{ $activeOption: number }>`
  position: relative;
  height: 21px;
  width: 100%;

  span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    pointer-events: none;

    &:nth-child(${({ $activeOption }) => $activeOption + 1}) {
      opacity: 1;
      pointer-events: initial;
    }
  }
`;

export const SelectDateOptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
