import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 0.25rem;
  margin-bottom: 1rem;
  @media ${breakpoints.laptopMd} {
    margin-bottom: 0;
  }
`;
