import { Avatar, useOutsideClick } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useRef } from 'react';

import { api } from '../../api/msApi';
import { AssignedUserAssetEnum } from '../../enums/assignedUserAssetEnum';
import { ApiResource } from '../../enums/resource.enum.ts';
import { AppState, useAppState } from '../../global-state/zustand';
import { defaultUser } from '../../shared/helpers/defaultUser.ts';
import { findAssetByType } from '../../shared/helpers/findAssetByType.helper';
import { getQueryKey } from '../../shared/helpers/getQueryKey.helper.ts';
import { ClientProfileDropdown } from '../client-profile-dropdown/ClientProfileDropdown';

import { AvatarDropdownWrapper } from './avatarDropdown.styles';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onLogoutClick: () => void;
  onSupportClick: () => void;
};

export const AvatarDropdown: React.FC<Props> = ({ isOpen, setIsOpen, onLogoutClick, onSupportClick }) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const user = useAppState((state: AppState) => state.user) || defaultUser;
  useOutsideClick([dropdownRef], () => setIsOpen(false));

  const isUserPrivate = user?.role === 'private';

  const { data: assets } = useQuery({
    queryKey: getQueryKey(ApiResource.ASSET, user.id),
    queryFn: () => {
      if (isUserPrivate) {
        return api.users.getPrivateUserAssets(user.id);
      } else {
        return api.users.getCorporateUserAssets(user.id);
      }
    },
    enabled: !!user?.role && !!user?.id,
  });

  const thumbnail = useMemo(() => {
    return findAssetByType(assets, AssignedUserAssetEnum.THUMBNAIL);
  }, [assets]);

  const { data: thumbnailUrl, refetch } = useQuery({
    queryKey: getQueryKey(ApiResource.ASSET, `${AssignedUserAssetEnum.THUMBNAIL}/${user.id}/${thumbnail?.id}`),
    queryFn: () => {
      if (thumbnail) {
        return api.assets.getS3SignedAssignedUrl({ assetId: thumbnail.id });
      }
    },
    enabled: !!user.id && !!assets && !!thumbnail,
  });

  useEffect(() => {
    if (thumbnail?.state === 'completed') {
      const timer = setTimeout(() => {
        refetch();
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [assets, refetch, thumbnail?.state]);

  return (
    <AvatarDropdownWrapper ref={dropdownRef}>
      <Avatar imagePath={thumbnailUrl?.url} size="small" onClick={() => setIsOpen(!isOpen)} />
      <ClientProfileDropdown
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        imagePath={thumbnailUrl?.url}
        onLogoutClick={onLogoutClick}
        onSupportClick={onSupportClick}
      />
    </AvatarDropdownWrapper>
  );
};
