import styled, { css } from 'styled-components';

import { RemSize } from '../../../..';
import { TextTemplate } from '../../../../shared/typography/TextTemplate.styles';

export const ViewFileContainer = styled.div<{ $isDisabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: ${({ $isDisabled }) => !$isDisabled && 'pointer'};
  padding: 0 1rem;

  ${TextTemplate} {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const ImageContainer = styled.div<{ $padding?: RemSize }>`
  padding: ${({ $padding }) => $padding || '4rem'};
  height: 100%;
  width: 100%;
`;

export const ImageWrapper = styled.div<{ $isDisabled: boolean }>`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: ${({ theme }) => theme.v2.surface.tertiary};
        opacity: 0.3;
      }
    `}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
`;
