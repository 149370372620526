import isObject from 'lodash/isObject';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { routes } from '../../router/routes';

export const useGetCurrentRoute = () => {
  const [currentRoute, setCurrentRoute] = useState<string>();
  const location = useLocation();
  const locationPath = location.pathname;
  const locationPathRoute = `/${locationPath.split('/')[1]}`;

  useEffect(() => {
    Object.values(routes).forEach((value) => {
      if (locationPathRoute === value) {
        setCurrentRoute(value);
        return;
      }

      if (isObject(value) && value.root === locationPathRoute) setCurrentRoute(value.root);
    });
  }, [locationPathRoute]);

  return { currentRoute, locationPath };
};
