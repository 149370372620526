import { ButtonColor, Text, TypographyLineHeight } from '@metaswiss/ui-kit';
import { TypographyTextSize } from '@metaswiss/ui-kit/src/theme/theme';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';
import { NftCommonStatusEnum } from '../../pages/protected/offering/smart-nfts/nft.response';

import { RibbonWrapper } from './ribbon.styles';

type Props = {
  status: NftCommonStatusEnum;
  fontSize: TypographyTextSize;
  lineHeight: TypographyLineHeight;
  padding?: string;
  translateY?: string;
  translateX?: string;
};

export const Ribbon: FC<Props> = ({
  status,
  fontSize,
  lineHeight,
  padding = '0.5rem 0',
  translateX = '25%',
  translateY = '50%',
}) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();

  const color = getStylePropsByStatus(status);

  return (
    <RibbonWrapper $color={color} $padding={padding} $translateX={translateX} $translateY={translateY}>
      <Text color={theme.v2.text.onAction} fontWeight={'semi'} fontSize={fontSize} lineHeight={lineHeight}>
        {textTranslation(`offering.${status}`).toUpperCase()}
      </Text>
    </RibbonWrapper>
  );
};

const getStylePropsByStatus = (status: NftCommonStatusEnum): ButtonColor => {
  switch (status) {
    case NftCommonStatusEnum.RESERVED:
      return 'warning';
    case NftCommonStatusEnum.SOLD:
      return 'error';
    case NftCommonStatusEnum.OWNED:
      return 'success';
    default:
      throw Error('Not supported NFT status');
  }
};
