import { Button, Text } from '@metaswiss/ui-kit';
import { FC } from 'react';

import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation.ts';

import { ButtonWrapper, TryAgainContainer } from './tryAgain.styles.ts';

type Props = {
  action: () => void;
};

export const TryAgain: FC<Props> = ({ action }) => {
  const { textTranslation } = useTextTranslation();
  return (
    <TryAgainContainer>
      <Text fontWeight={'bold'}>{textTranslation('global.somethingWentWrong')}</Text>
      <ButtonWrapper>
        <Button size={'medium'} text={textTranslation('global.tryAgain')} fill onClick={action} />
      </ButtonWrapper>
    </TryAgainContainer>
  );
};
