import { AssetUploadUnassignedResponse } from '@metaswiss/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { api } from '../../api/msApi';
import { ApiResource } from '../../enums/resource.enum';
import { getQueryKey } from '../../shared/helpers/getQueryKey.helper';

type PostAndGetDocumentProps = {
  onMutate: (props: AssetUploadUnassignedResponse) => void;
  onRefetch: (documentUrl: string) => void;
  fileId: string;
  getDocumentInfo?: (props: { fileName: string; size: number }) => void;
};

export const usePostAndGetDocument = ({ onMutate, onRefetch, fileId, getDocumentInfo }: PostAndGetDocumentProps) => {
  const { mutate, isLoading, isError } = useMutation({
    mutationFn: (file: File) => {
      if (getDocumentInfo) {
        getDocumentInfo({ fileName: file.name, size: file.size });
      }
      return api.assets.uploadUnassignedFile({ file: file, fileType: file.type });
    },
    onSuccess: (response) => {
      onMutate(response);
    },
  });

  const { refetch } = useQuery({
    queryKey: getQueryKey(ApiResource.UNASSIGNED_URL, fileId),
    queryFn: () => {
      return api.assets.getS3SignedUnassignedUrl({ assetId: fileId });
    },
    enabled: !!fileId,
  });

  useEffect(() => {
    if (!fileId) return;

    refetch().then((response) => {
      onRefetch(response.data.url);
    });
  }, [fileId]);

  return { mutate, isLoading, isError };
};
