import styled, { css } from 'styled-components';

import { breakpoints } from '../../../../breakpoints.ts';
import { RemSize } from '../../../../theme';

type Props = {
  $numColumns: number | 'auto-fill';
  $numberOfColumnsOnSmallerDevices?: number;
  $gap: RemSize;
  $minItemWidth: RemSize;
  $reverseItems: boolean;
};

type SingleColumnWrapperProps = {
  $marginTop?: RemSize;
  $marginBottom?: RemSize;
};
export const Wrapper = styled.div<Props>`
  gap: ${({ $gap }) => $gap};
  max-width: 77.5rem;
  width: 100%;
  margin: 0 auto;
  ${({ $reverseItems }) =>
    $reverseItems
      ? css`
          display: flex;
          flex-direction: column-reverse;
          gap: 1rem;
        `
      : css`
          display: grid;
        `}

  grid-auto-rows: max-content;
  grid-template-columns: ${({ $numColumns, $numberOfColumnsOnSmallerDevices, $minItemWidth }) =>
    $numColumns === 'auto-fill'
      ? `repeat(${$numColumns}, minmax(${$minItemWidth}, 1fr))`
      : `repeat(${$numberOfColumnsOnSmallerDevices}, 1fr)`};

  @media ${breakpoints.laptopSm} {
    grid-template-columns: ${({ $numColumns, $minItemWidth }) =>
      `repeat(${$numColumns}, minmax(${$minItemWidth}, 1fr))`};
  }
`;

export const SingleColumnWrapper = styled.div<SingleColumnWrapperProps>`
  display: grid;
  gap: 1rem;
  grid-column: 1 / -1;
  margin-top: ${({ $marginTop }) => ($marginTop ? $marginTop : 0)};
  margin-bottom: ${({ $marginBottom }) => ($marginBottom ? $marginBottom : 0)};
`;
