import styled from 'styled-components';

import { Padding } from '../../../../theme/theme';

export const ItemLabelWrapper = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const ItemWrapper = styled.button<{
  $isSelected: boolean;
  $padding?: Padding;
}>`
  padding: ${({ $padding }) => $padding || '1rem'};
  cursor: pointer;
  border: 1px solid transparent;
  height: 3.5rem;
  margin-right: 1rem;
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.small};

  &:hover {
    background-color: ${({ theme, $isSelected }) =>
      $isSelected ? theme.v2.surface.informationLight : theme.v2.surface.tertiary};
  }

  &:active {
    background-color: ${({ theme }) => theme.v2.surface.informationLight};
    border: 1px solid ${({ theme }) => theme.v2.border.information};
  }

  ${({ $isSelected, theme }) =>
    $isSelected &&
    `
      background-color: ${theme.v2.surface.informationLight};
      border-color: ${theme.v2.border.information};

      * {
        font-weight: ${theme.typography.weights.bold};
        color: ${theme.v2.text.information};
      }

    `}
`;
