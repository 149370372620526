import * as zod from 'zod';

import { FormNames } from '../../../../../../../../shared/forms/formNames';
import { staticTranslationFunction } from '../../../../../../../../shared/forms/staticTranslationFunction.ts';

export const createFullCorporateSignatureSchema = (translate: (key: string) => string) =>
  zod.object({
    signature: zod.string().nonempty(translate('error.requiredField')),
  });

export const schema = createFullCorporateSignatureSchema(staticTranslationFunction);

export type FullCorporateRegistrationUploadSignatureData = zod.infer<typeof schema>;

export const fullCorporateRegistrationSchema: FormNames<FullCorporateRegistrationUploadSignatureData> = {
  signature: 'signature',
} as const;
