import { styled } from 'styled-components';

type ContainerProps = {
  $radius: string;
  $size: string;
  $isCursorPointer: boolean;
};

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ $isCursorPointer }) => ($isCursorPointer ? 'pointer' : 'auto')};
  border-radius: ${({ $radius }) => $radius};
  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};
`;

type ImageContainerProps = {
  $radius: string;
  $size: string;
  $active: boolean;
  $border: string;
};

export const ImageContainer = styled.div<ImageContainerProps>`
  overflow: hidden;
  position: relative;

  border-radius: ${({ $radius }) => $radius};
  border: ${({ $border }) => $border};
  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);

  &:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${({ $active }) =>
      $active && `background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%);`}
  }

  &:active::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${({ $active }) =>
      $active && `background: linear-gradient(0deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%);`}
  }
`;

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const BadgeContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
`;
