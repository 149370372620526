import styled from 'styled-components';

import { DropdownHeight } from '../../../../theme/theme.ts';

import { Fieldset } from './fieldset';

export const DropdownButton = styled.button<{ $isLocked?: boolean; $height: DropdownHeight; $isDisabled: boolean }>`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  background-color: ${({ theme, $isLocked }) => ($isLocked ? 'transparent' : theme.v2.surface.field)};
  border-radius: ${({ theme }) => theme.v2.componentsRadius.inputs.large};
  display: flex;
  align-items: center;
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'auto' : 'pointer')};
  height: ${({ theme, $height }) => theme.dropdown.height[$height]};
  padding: ${({ theme, $height }) =>
    $height === 'large'
      ? `${theme.v2.spacing['1']} ${theme.v2.spacing['1.25']}`
      : `${theme.v2.spacing['0.5']} ${theme.v2.spacing['1']}`};

  &:disabled {
    ${Fieldset} {
      color: ${({ theme }) => theme.v2.text.disabled};
      border-color: ${({ theme }) => theme.v2.border.primaryHover};
      background-color: ${({ theme }) => theme.v2.surface.disabled};
      font-weight: ${({ theme }) => theme.typography.weights.reg};
    }
  }
`;
