import { styled } from 'styled-components';

import { RemSize } from '../../../../theme';

export const StatisticInfoContainer = styled.div<{ $gap: RemSize }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $gap }) => $gap};
`;

export const TitleIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
