import { AssetUploadUnassignedResponse } from '@metaswiss/api';
import { convertDocumentSize } from '@metaswiss/lib';
import { UploadFile, Text } from '@metaswiss/ui-kit';
import { AcceptedFileTypes } from '@metaswiss/ui-kit/src/components/molecules/upload-file/enums';

import { usePostAndGetDocument } from '../../../../../../../../hooks/use-post-and-get-document/usePostAndGetDocument';
import { useTextTranslation } from '../../../../../../../../hooks/use-text-translation/useTextTranslation';
import { StepTextContainer } from '../../../../../register.styles';
import { initialFullPrivateRegistrationValues } from '../fullPrivateRegistrationState';

import { fullRegistrationPrivateDocumentUploadSchema } from './documentUploadSchema';
import { DocumentUploadProps } from './types';

export const SingleDocumentUpload = ({
  title,
  userDetails,
  documentSide,
  setValue,
  getValue,
  setRegistrationFlowDocument,
  isPassport = false,
}: DocumentUploadProps) => {
  const { textTranslation } = useTextTranslation();

  const { mutate, isLoading, isError } = usePostAndGetDocument({
    onMutate: handleDocumentMutation,
    onRefetch: handleDocumentRefetch,
    getDocumentInfo: ({ fileName, size }) => {
      setRegistrationFlowDocument({
        ...userDetails.identityDocument[documentSide],
        fileName,
        fileSize: convertDocumentSize(size),
        documentSide,
      });
    },
    fileId: userDetails?.identityDocument[documentSide].fileId,
  });

  function handleDocumentMutation({ fileId, url }: AssetUploadUnassignedResponse) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setValue(fullRegistrationPrivateDocumentUploadSchema[documentSide], fileId, {
      shouldValidate: true,
    });

    setRegistrationFlowDocument({
      ...userDetails.identityDocument[documentSide],
      fileId,
      url,
      documentSide: documentSide,
    });
  }

  function handleDocumentRefetch(documentUrl: string) {
    setRegistrationFlowDocument({
      ...userDetails.identityDocument[documentSide],
      fileId: getValue(documentSide),
      url: documentUrl,
      documentSide: documentSide,
    });
  }

  const handleIdUpload = (file: File) => {
    mutate(file);
  };

  const handleDocumentDelete = () => {
    setRegistrationFlowDocument({
      ...initialFullPrivateRegistrationValues.identityDocument[documentSide],
      documentSide,
    });
    setValue(documentSide, '', { shouldValidate: true });
  };

  return (
    <div>
      <StepTextContainer>
        <Text fontWeight="bold">{textTranslation(title)}</Text>
      </StepTextContainer>
      <UploadFile
        uploadFile={handleIdUpload}
        onDelete={handleDocumentDelete}
        isLoading={isLoading}
        isError={isError}
        initialTitle={textTranslation('registration.uploadDocumentTitle')}
        initialSubtitle={textTranslation('registration.uploadDocumentSubtitle')}
        invalidTitle={textTranslation('registration.invalidPhotoTitle')}
        invalidSubtitle={textTranslation('registration.uploadDocumentSubtitle')}
        failedTitle={textTranslation('registration.failedPhotoUploadTitle')}
        failedSubtitle={textTranslation('registration.failedPhotoUploadSubtitle')}
        viewFileTitle={userDetails.identityDocument[documentSide].fileName}
        viewFileSubtitle={userDetails.identityDocument[documentSide].fileSize}
        acceptedFileTypes={AcceptedFileTypes.ALL}
        $width="37.5rem"
        $height={isPassport ? '26rem' : '10.75rem'}
        imgContainerPadding={isPassport ? undefined : '2rem'}
        onViewDocument={() => window.open(userDetails.identityDocument[documentSide].url, '_blank')}
        value={
          userDetails?.identityDocument[documentSide] ||
          initialFullPrivateRegistrationValues?.identityDocument[documentSide]
        }
      />
    </div>
  );
};
