import styled from 'styled-components';

import { TypographySize, TypographyWeight } from '../../../..';

export const StyledLabelSpan = styled.span<{
  $fontSize: TypographySize;
  $fontWeight: TypographyWeight;
}>`
  font-size: ${({ theme, $fontSize }) => theme.typography.sizes[$fontSize]};
  font-weight: ${({ theme, $fontWeight }) => theme.typography.weights[$fontWeight]};
  color: ${({ theme }) => theme.v2.text.bodyPrimary};
  line-height: ${({ theme }) => theme.typography.lineHeight.base};
  margin-left: 0.75rem;
  display: inline-block;
`;
