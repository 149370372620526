import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 4.25rem 4.5rem;
  overflow: hidden;
`;

export const ErrorImageContainer = styled.div`
  margin: 0 auto;
  width: 37.5rem;
  height: 20rem;
`;

export const HeadingWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 42.5rem;
`;

export const ButtonWrapper = styled.div`
  margin-top: 3rem;
  width: 19.75rem;
`;

export const LogoWrapper = styled.img`
  width: 100%;
  height: 100%;
`;

export const LogoContainer = styled.div`
  width: 12.5rem;
  height: 3.5rem;
  margin-bottom: 4.25rem;
`;
