import { BusinessFocusResponse } from '@metaswiss/api';
import { composeBusinessFocusOptions } from '@metaswiss/lib';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { api } from '../../api/msApi';
import { ApiResource } from '../../enums/resource.enum';
import { getQueryKey } from '../../shared/helpers/getQueryKey.helper';
import { useTextTranslation } from '../use-text-translation/useTextTranslation';

export const useBusinesses = () => {
  const { textTranslation } = useTextTranslation();

  const { data } = useQuery({
    queryKey: getQueryKey(ApiResource.BUSINESS_FOCUS),
    queryFn: () => api.businessFocus.getBusinessFocus(),
  });

  const businesses = useMemo(() => {
    return composeBusinessFocusOptions<BusinessFocusResponse>(data || [], 'id', 'translationCode', textTranslation);
  }, [data, textTranslation]);

  const findBusiness = (businessSelect: BusinessFocusResponse) =>
    businesses.find((business) => business.value === businessSelect.id);

  return {
    businesses,
    findBusiness,
  };
};
