import { styled } from 'styled-components';

import { ColorPalette, Colors } from '../../../theme';

type ContainerProps = {
  $gradientStartingColorPalette: ColorPalette;
  $gradientStartingColor: Colors<ColorPalette>;
  $gradientEndingColorPalette: ColorPalette;
  $gradientEndingColor: Colors<ColorPalette>;
};

export const Container = styled.div<ContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding: 1rem 1.5rem;
  text-align: center;
  overflow-y: auto;
  background: linear-gradient(
    0deg,
    ${({ theme, $gradientEndingColorPalette, $gradientEndingColor }) =>
        theme.colors[$gradientEndingColorPalette][$gradientEndingColor]}
      0%,
    ${({ theme, $gradientStartingColorPalette, $gradientStartingColor }) =>
        theme.colors[$gradientStartingColorPalette][$gradientStartingColor]}
      100%
  );
`;

export const ContentWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LogoHolder = styled.div`
  width: 6.25rem;
  height: 6.25rem;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.neutrals.hue0};
  border: 1px solid ${({ theme }) => theme.colors.neutrals.hue100};
  border-radius: ${({ theme }) => theme.dimensions.borderRadii.xxxl};
`;

export const LogoImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const HeadingWrapper = styled.div`
  margin: 2rem 0 1.5rem 0;
`;

export const DescriptionWrapper = styled.div`
  max-width: 33.75rem;
`;

export const DownloadAppImagesContainer = styled.div`
  margin-top: 2.5rem;
  max-width: 33.75rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 13.5rem));
  place-items: center;
  justify-content: center;
`;

export const DownloadAppImageWrapper = styled.div`
  padding: 1rem;
`;

export const DownloadAppImage = styled.img`
  height: 3.5rem;
  width: auto;
`;

export const FixedLogoWrapper = styled.div`
  position: fixed;
  z-index: -1;
  width: 100%;
  padding: 0 1rem;
  opacity: 0.1;
  left: 0;
  bottom: 0;
  transform: translateY(50%);
`;

export const FixedLogo = styled.img`
  width: 100%;
  height: auto;
`;
