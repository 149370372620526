import styled from 'styled-components';

import { TextTemplate } from '../../../../components';
import { IconContainer } from '../../../../components/molecules/theme-icon/styles/iconContainer';

export const ToastContainer = styled.div<{ $isOpen: boolean; $borderColor: string }>`
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  overflow: hidden;
  padding: 1rem;
  border-radius: 0.75rem;
  max-width: 26.25rem;
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  border-bottom: 0;
  background-color: ${({ theme }) => theme.v2.surface.primary};
  box-shadow:
    0px 1px 12px 0px #00000014,
    0px -3px 0px 0px ${({ $borderColor }) => $borderColor} inset;
  transform: translateY(${({ $isOpen }) => ($isOpen ? '1.25rem' : 'calc(-100% - 1rem)')});
  transition: transform 0.6s cubic-bezier(0.11, 1.22, 0.53, 1.11);

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.25rem;
    background-color: ${({ $borderColor }) => $borderColor};
    bottom: 0;
    left: 0;
  }
`;

export const ToastHeaderContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  ${IconContainer} {
    flex-shrink: 0;
  }

  ${TextTemplate} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

export const CloseIconContainer = styled.div`
  margin-left: auto;
  cursor: pointer;
`;

export const DescriptionContainer = styled.div`
  margin: 0.25rem 2.5rem 0 2.5rem;
`;
