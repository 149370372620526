/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateNewDeliveryAddressRequest } from '../models/CreateNewDeliveryAddressRequest';
import type { UserDeliveryAddressResponse } from '../models/UserDeliveryAddressResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserDeliveryAddressesApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns UserDeliveryAddressResponse Create new delivery address
   * @throws ApiError
   */
  public createNewDeliveryAddress(
    requestBody: CreateNewDeliveryAddressRequest,
  ): CancelablePromise<UserDeliveryAddressResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/user-delivery-address',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns UserDeliveryAddressResponse Get User Delivery Address
   * @throws ApiError
   */
  public getUserDeliveryAddress(): CancelablePromise<UserDeliveryAddressResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/user-delivery-address',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
