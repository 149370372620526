import { motion } from 'framer-motion';
import { css, styled } from 'styled-components';

import { ColorPalette } from '../../../../theme';

const lineClampStyles = css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const iconsStyles = css`
  display: grid;
  place-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  flex-shrink: 0;
`;

export const NotificationCardWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

export const CardIconWrapper = styled.div<{ $palette: ColorPalette }>`
  ${iconsStyles}
  background-color: ${({ theme, $palette }) => theme.colors[$palette].hue400};
  margin-top: 1rem;
`;

export const LinkIconWrapper = styled.div`
  ${iconsStyles}
  background-color: ${({ theme }) => theme.colors.neutrals.hue100};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.hue0};

    path {
      stroke: ${({ theme }) => theme.colors.primary.hue300};
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.neutrals.hue100};

    path {
      stroke: ${({ theme }) => theme.colors.neutrals.hue900};
    }
  }
`;

export const TextsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ContentRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
`;

export const StatusDropdownIconContainer = styled.div`
  flex-shrink: 0;
`;

export const DropdownIconWrapper = styled(motion.div)`
  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  ${lineClampStyles}
  -webkit-line-clamp: 1;
`;

export const DescriptionWrapper = styled.div<{ $isCardOpen: boolean; $expendedHeight?: number }>`
  flex-grow: 1;
  overflow: hidden;
  height: ${({ $isCardOpen, $expendedHeight }) => ($isCardOpen ? `${$expendedHeight}px` : '2.5rem')};
  transition: height 0.2s;
  ${({ $isCardOpen }) =>
    !$isCardOpen &&
    css`
      ${lineClampStyles}
      -webkit-line-clamp: 2;
    `}
`;
