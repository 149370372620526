import { AssetSignedUrlResponse, DocumentDTO } from '@metaswiss/api';
import { Document } from '@metaswiss/ui-kit';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';

import { api } from '../../../../api/msApi';
import { DocumentState } from '../../../../enums/documentState';
import { ApiResource } from '../../../../enums/resource.enum';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';

export const DocumentAsset: FC<DocumentDTO> = ({ id, type, state, ...props }) => {
  const { textTranslation } = useTextTranslation();
  const queryClient = useQueryClient();

  const [isDocumentFetchEnabled, setIsDocumentFetchEnabled] = useState(false);

  const handleDocumentOpen = (data: AssetSignedUrlResponse | undefined) => {
    if (data?.url) {
      window.open(data.url, '_blank', 'noreferrer');
    }
  };

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: getQueryKey(ApiResource.ASSIGNED_URL, id),
    queryFn: () => api.assets.getS3SignedAssignedUrl({ assetId: id }),
    onSuccess: (data) => {
      handleDocumentOpen(data);
    },
    enabled: !!id && isDocumentFetchEnabled,
  });

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.ASSIGNED_URL, id) });
    };
  }, []);

  const onDocumentClick = () => {
    setIsDocumentFetchEnabled(true);
    handleDocumentOpen(data);
  };

  return (
    <Document
      text={textTranslation(`global.${type.replace(/\s/g, '')}`)}
      onClick={state === DocumentState.COMPLETED ? (isError ? refetch : onDocumentClick) : undefined}
      isLoading={isDocumentFetchEnabled && isLoading}
      isError={isError}
      status={state as DocumentState}
      processingText={textTranslation('global.documentProcessing')}
      processingFailedText={textTranslation('global.documentContactSupport')}
      loadingFailedText={textTranslation('global.documentTryOpeningAgain')}
      {...props}
    />
  );
};
