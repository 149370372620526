import styled, { css } from 'styled-components';

export const wizardContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledContainer = styled.div`
  ${wizardContainerStyles}
  align-items: center;
`;

export const StyledWizardContainer = styled.div`
  ${wizardContainerStyles};
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
`;

export const StyledWizardHeader = styled.div`
  text-align: left;
  width: 100%;
`;

export const StyledLogoContainer = styled.img`
  margin: 0 auto;
  width: 3.5rem;
  height: auto;
  margin-bottom: 1.25rem;
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

export const StyledHolderStepper = styled.div`
  margin-bottom: 2.5rem;
`;

export const StyledContentContainer = styled.div`
  display: block;
  width: 100%;
`;

export const StyledBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const WizardFooterName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4.25rem;
  gap: 1rem;
`;
