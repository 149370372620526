export enum WebSocketEvents {
  USER_DATA_UPDATE = 'USER_DATA_UPDATE',
  ADMIN_NOTIFICATION_SEND = 'ADMIN_NOTIFICATION_SEND',
  TRANSACTION_DOCUMENTS_GENERATED = 'TRANSACTION_DOCUMENTS_GENERATED',
  THUMBNAIL_GENERATED = 'THUMBNAIL_GENERATED',
  CASE_CREATED = 'CASE_CREATED',
  TRANSACTION_ACCEPTED = 'TRANSACTION_ACCEPTED',
  TRANSACTION_REJECTED = 'TRANSACTION_REJECTED',
  USER_DELETED = 'USER_DELETED',
  ADMIN_CREATED_CASE = 'ADMIN_CREATED_CASE',
  APPROVE_CASE = 'APPROVE_CASE',
  REJECT_CASE = 'REJECT_CASE',
  ADMIN_RESPONSE_TO_CASE = 'ADMIN_RESPONSE_TO_CASE',
  SUSPEND_USER = 'SUSPEND_USER',
}
