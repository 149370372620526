import { FC } from 'react';

import { IconProps } from './iconProps';

export const PlusIcon: FC<IconProps> = ({ strokeColor = 'white' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 4.5L9 13.5M13.5 9L4.5 9" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
