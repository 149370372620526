import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { ProductType } from '../../../enums/productType.enum';
import { AppState, useAppState } from '../../../global-state/zustand';
import { routes } from '../../../router/routes';

import { Payment } from './Payment';
import { PaymentContextProvider } from './context/PaymentContext';
import { PaymentRouteState } from './payment.types';

export const BasePayment = () => {
  const { state }: { state: PaymentRouteState } = useLocation();
  const user = useAppState((state: AppState) => state.user);
  const setSidebarVisible = useAppState((state: AppState) => state.setSidebarVisible);
  const setHeaderVisible = useAppState((state: AppState) => state.setHeaderVisible);

  useEffect(() => {
    setSidebarVisible(false);
    setHeaderVisible(false);
    return () => {
      setSidebarVisible(true);
      setHeaderVisible(true);
    };
  }, [setSidebarVisible, setHeaderVisible]);

  if (
    !state?.productType ||
    ((state?.productType === ProductType.BOND_PAPERS || state?.productType === ProductType.ACTIONS) &&
      !user?.isFullyRegistered)
  ) {
    return <Navigate to={routes.pageNotFound} />;
  }

  return (
    <PaymentContextProvider>
      <AnimatePresence>
        <Payment />
      </AnimatePresence>
    </PaymentContextProvider>
  );
};
