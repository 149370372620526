import styled, { css, keyframes } from 'styled-components';

import { TextTemplate } from '../../../../shared/typography/TextTemplate.styles';

type AnimatedCircleProps = { $circumference: number; $offset: number; $strokeColor: string };

const progress = (offset: number, circumference: number) => keyframes`
    0% {
        stroke-dashoffset: ${circumference};
    }
    100% {
        stroke-dashoffset: ${offset};
    }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Container = styled.svg`
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
`;

export const Circle = styled.circle`
  r: 40;
  cx: 50;
  cy: 50;
  fill: transparent;
  stroke: ${({ theme }) => theme.colors.neutrals.hue25};
  stroke-width: 10px;
`;

export const AnimatedCircle = styled.circle<AnimatedCircleProps>`
  r: 2.5rem;
  cx: 50%;
  cy: 50%;
  fill: transparent;
  stroke: ${(props) => props.$strokeColor};
  stroke-width: 10px;
  stroke-dasharray: ${(props) => props.$circumference};
  stroke-dashoffset: ${(props) => props.$offset};
  ${(props) => css`
    animation: ${progress(props.$offset, props.$circumference)} 1s linear forwards;
  `}
`;

export const StyledText = styled(TextTemplate)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
