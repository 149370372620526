import { FC } from 'react';

import { IconProps } from './iconProps';

export const LinkExternal: FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7999 2.40039H5.9999C4.01168 2.40039 2.3999 4.01217 2.3999 6.00039V18.0005C2.3999 19.9887 4.01168 21.6005 5.9999 21.6005H17.9999C19.9881 21.6005 21.5999 19.9887 21.5999 18.0005V13.2004M15.5993 2.40068L21.5999 2.40039M21.5999 2.40039V7.80054M21.5999 2.40039L11.399 12.6001"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
