import { SideBarItems, SideBarProps } from '../side-bar/SideBar';

import { StyledBottomNavigationBar, StyledMenu, StyledWrapper } from './bottomNavigationBar.styles';

export const BottomNavigationBar = ({ items, selectedItem, position }: SideBarProps) => {
  return (
    <StyledBottomNavigationBar $position={position}>
      <StyledWrapper>
        <StyledMenu>
          <SideBarItems items={items} selectedItem={selectedItem} />
        </StyledMenu>
      </StyledWrapper>
    </StyledBottomNavigationBar>
  );
};
