import { RemSize } from '@metaswiss/ui-kit';
import styled from 'styled-components';

export const Container = styled.div<{
  $width?: RemSize;
  $isHoverActive: boolean;
  $disabled: boolean;
}>`
  width: ${({ $width }) => $width ?? '22rem'};
  height: 16.5rem;
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.default};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  padding: 1.25rem;
  background-color: ${({ theme }) => theme.v2.surface.primary};
  transition: 0.25s transform;
  cursor: ${({ $isHoverActive }) => ($isHoverActive ? 'pointer' : 'initial')};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};

  &:hover {
    box-shadow: ${({ $isHoverActive }) =>
      $isHoverActive
        ? `0px 0px 16px 0px #00000005, 0px 1px 0px 0px #00000005, 0px 10px 24px 0px #00000005;
`
        : 'none'};
    transform: ${({ $isHoverActive }) => ($isHoverActive ? 'scale(1.02)' : 'none')};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
`;

export const HeadingWrapper = styled.div`
  width: 100%;
  height: 8rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const IconWrapper = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.v2.surface.informationLight};
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const FooterWrapper = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
`;

export const FooterPopupWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: self-end;
  position: relative;
`;

export const FooterSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: calc(100% - 1.25rem);

  > span {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const ArrowIconWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  position: absolute;
  align-items: center;
  top: 1.25rem;
  right: 0;
  justify-content: flex-end;
`;

export const DocumentStatusWrapper = styled.div`
  position: absolute;
  height: 1.5rem;
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  right: 0;
  align-items: center;
`;
