import {
  BondFullResponseDTO,
  CurrencyResponse,
  NftExtendedV5Response,
  ShareFullResponseDTO,
  ShareResponseDTO,
} from '@metaswiss/api';
import { extractNumericDigitsWithDecimals, formatAmount } from '@metaswiss/lib';

import { ProductType } from '../../../../enums/productType.enum';

export const getProductInfo = (
  productType: ProductType,
  item: BondFullResponseDTO | ShareFullResponseDTO | NftExtendedV5Response | undefined,
  selectedCurrency: CurrencyResponse | undefined
) => {
  switch (productType) {
    case ProductType.BOND_PAPERS: {
      const bond = item as BondFullResponseDTO;
      const rawDenomination = parseFloat(bond.denomination.replace(/[^\d.]/g, ''));
      return {
        title: 'payment.purchaseBonds',
        subTitle: 'payment.purchaseBondsSubtitle',
        rawProductPrice: rawDenomination * (selectedCurrency?.rate || 1),
        productPrice: formatAmount(
          rawDenomination,
          selectedCurrency?.decimals,
          selectedCurrency?.rate,
          selectedCurrency?.currencyCode
        ),
      };
    }
    case ProductType.ACTIONS: {
      const share = item as ShareResponseDTO;
      const fullShare = item as ShareFullResponseDTO;
      const rawPrice = fullShare.rawPricePerShare || extractNumericDigitsWithDecimals(share.pricePerShare);
      return {
        title: 'payment.purchaseShares',
        subTitle: 'payment.purchaseSharesSubtitle',
        rawProductPrice: rawPrice * (selectedCurrency?.rate || 1),
        productPrice: formatAmount(
          rawPrice,
          selectedCurrency?.decimals,
          selectedCurrency?.rate,
          selectedCurrency?.currencyCode
        ),
      };
    }
    default:
      return {
        title: '',
        subTitle: '',
        rawProductPrice: 0,
        productPrice: '',
        issuer: {},
      };
  }
};
