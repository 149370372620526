import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow: hidden;
`;

export const UpperContainer = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FirstWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media ${breakpoints.laptopSm} {
    flex-direction: row;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
  }
`;

export const SecondWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 2;

  @media ${breakpoints.laptopSm} {
    flex-direction: row;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;

  @media ${breakpoints.laptopSm} {
    max-width: 37%;
  }
`;

export const Image = styled.img`
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: ${({ theme }) => theme.v2.radius.medium};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
`;

export const BottomContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 5rem;

  @media ${breakpoints.laptopSm} {
    padding-bottom: 1rem;
  }
`;

export const ItemsWrapper = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(6, minmax(11.75rem, 1fr));
  grid-auto-rows: 0;
  overflow-x: scroll;

  @media ${breakpoints.laptopSm} {
    grid-template-columns: repeat(auto-fit, minmax(11.75rem, 1fr));
    grid-auto-rows: 0;
    overflow: hidden;
  }

  @media ${breakpoints.desktopSm} {
    grid-auto-rows: 0;
  }
`;

export const ButtonWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  box-shadow: 0 0.25rem 1.25rem 0 #0062ff80;
  border-radius: 0.5rem;
  width: calc(100vw - 2rem);
  z-index: 10;
  bottom: 7.5rem;
  left: 1rem;

  @media ${breakpoints.laptopSm} {
    position: initial;
    box-shadow: none;
    width: 22.5rem;
  }
`;

export const RightWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  right: 0;

  @media ${breakpoints.laptopSm} {
    position: absolute;
    height: calc(100% - 1rem);
    width: calc(63% - 1rem);
  }
`;

export const WrapperDetails = styled.div`
  width: 100%;
  @media ${breakpoints.laptopSm} {
    flex: 0 0 28.75rem;
    max-width: 28.75rem;
  }
`;
