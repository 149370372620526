/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageableItems } from '../models/PageableItems';
import type { PushRegistrationRequest } from '../models/PushRegistrationRequest';
import type { SendNotificationRequest } from '../models/SendNotificationRequest';
import type { SendNotificationToAllRequest } from '../models/SendNotificationToAllRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NotificationsApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns any Successfully registered device for Push
   * @throws ApiError
   */
  public registerDeviceForPush(
    requestBody: PushRegistrationRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/notifications/register-push',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any Send notification
   * @throws ApiError
   */
  public sendNotification(
    requestBody: SendNotificationRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/notifications/send-notification',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any Send notification
   * @throws ApiError
   */
  public sendNotificationToAll(
    requestBody: SendNotificationToAllRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/notifications/send-notification-to-all',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param offset
   * @param limit
   * @param page
   * @returns PageableItems Get notifications for user based on the preferred language
   * @throws ApiError
   */
  public getNotifications(
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/notifications/get-notifications',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns any Get notifications for user based on the preffered language
   * @throws ApiError
   */
  public readNotifications(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/notifications/read-notifications',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns any Read single notification by notification id
   * @throws ApiError
   */
  public readSingleNotification(
    id: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v5/notifications/read-notification/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
