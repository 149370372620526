import { CorporateAddress } from '../steps/wizard-step/register-form-steps/full-corporate-registration/corporate-address/CorporateAddress';
import { CorporateInformation } from '../steps/wizard-step/register-form-steps/full-corporate-registration/corporate-information/CorporateInformation';
import { UploadCompanyLogo } from '../steps/wizard-step/register-form-steps/full-corporate-registration/upload-company-logo/UploadCompanyLogo';
import { UploadDocuments } from '../steps/wizard-step/register-form-steps/full-corporate-registration/upload-documents/UploadDocuments';
import { UploadSignature } from '../steps/wizard-step/register-form-steps/full-corporate-registration/upload-signature/UploadSignature';
import { Address } from '../steps/wizard-step/register-form-steps/full-private-registration/address/Address';
import { DocumentUpload } from '../steps/wizard-step/register-form-steps/full-private-registration/document-upload/DocumentUpload';
import { PersonalInformationForm } from '../steps/wizard-step/register-form-steps/full-private-registration/personal-information/PersonalInformation';
import { ProfilePhoto } from '../steps/wizard-step/register-form-steps/full-private-registration/profile-photo/ProfilePhoto';
import { ProofOfDomicile } from '../steps/wizard-step/register-form-steps/full-private-registration/proof-of-domicile/ProofOfDomicile';
import { SelectIdentityVerification } from '../steps/wizard-step/register-form-steps/full-private-registration/select-identity-verification/SelectIdentityVerification';
import { QuickRegistrationCorporateFlow } from '../steps/wizard-step/register-form-steps/quick-registration-corporate/QuickRegistrationCorporateFlow';
import { QuickRegistrationPrivateFlow } from '../steps/wizard-step/register-form-steps/quick-registration-private/QuickRegistrationPrivateFlow';

import { Step } from './helpers/getWizardSteps.helper';

export const QuickPrivateRegistrationSteps: Step[] = [
  {
    component: <QuickRegistrationPrivateFlow />,
  },
];

export const QuickCorporateRegistrationSteps: Step[] = [
  {
    component: <QuickRegistrationCorporateFlow />,
  },
];

export const FullPrivateRegistrationSteps: Step[] = [
  { component: <PersonalInformationForm /> },
  { component: <Address /> },
  { component: <ProfilePhoto /> },
  { component: <ProofOfDomicile /> },
  { component: <SelectIdentityVerification /> },
  { component: <DocumentUpload /> },
];

export const FullCorporateRegistrationSteps: Step[] = [
  { component: <CorporateInformation /> },
  { component: <CorporateAddress /> },
  { component: <UploadCompanyLogo /> },
  { component: <UploadDocuments /> },
  { component: <UploadSignature /> },
];
