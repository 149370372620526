import { useQueryClient } from '@tanstack/react-query';
import { useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { ApiResource } from '../../../../enums/resource.enum';
import { useDefineBackgroundColors } from '../../../../hooks/use-define-background-colors/useDefineBackgroundColors';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';

export const SmartNftsPortfolio = () => {
  const queryClient = useQueryClient();

  useLayoutEffect(() => {
    queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.USER_INVESTED_PORTFOLIO) });
    queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.PORTFOLIO) });
    queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.PORTFOLIO_NFTS) });
    queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.NFT_PORTFOLIO_STATISTICS) });
  }, []);

  useDefineBackgroundColors('green', 'green');

  return <Outlet />;
};
