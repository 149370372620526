import { MetaswissApi, OpenAPIConfig } from '@metaswiss/api';

import { environmentVariables } from '../env/environmentVariables';

import { CustomAxiosHttpRequest } from './customAxiosClient';
import { getAccessToken } from './tokenHelpers';

const NO_TOKEN = '';
const ACCESS_TOKEN_URL_BLOCKLIST = ['login', 'refresh'];
function urlInBlocklist(url: string) {
  return ACCESS_TOKEN_URL_BLOCKLIST.some((blocklistUrlSegment) => url.includes(blocklistUrlSegment));
}

const config: Partial<OpenAPIConfig> = {
  BASE: environmentVariables.baseApiUrl,
  HEADERS: {
    tenant: environmentVariables.tenant,
  },
  VERSION: '4',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  TOKEN: async (options) => (urlInBlocklist(options.url) ? NO_TOKEN : getAccessToken() ?? NO_TOKEN),
};

export const api = new MetaswissApi(config, CustomAxiosHttpRequest);
