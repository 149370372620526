import { FC } from 'react';

import { IconProps } from './iconProps';

export const TrendUpWithBackgroundIcon: FC<IconProps> = ({ color = '#FFF4E5', strokeColor = '#FF9F1A' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 136 136" fill={'none'} xmlns="http://www.w3.org/2000/svg">
      <rect width="100%" height="100%" rx="10" fill={color} />
      <path
        d="M24.4805 89.7602L48.8517 66.3264L69.7413 86.4125L111.52 46.2402M111.52 46.2402H80.1861M111.52 46.2402V76.3695"
        stroke={strokeColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
