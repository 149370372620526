import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
`;

export const OverviewGraphContainer = styled.div`
  display: flex;
  padding-bottom: 1.5rem;
  gap: 2.5rem;
  align-items: center;
`;

export const OverviewGraphChart = styled.div`
  width: 6.25rem;
  height: 6.25rem;
`;

export const OverviewGraphContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const OverviewGraphCards = styled.div`
  display: flex;
  gap: 4.5rem;
  border-top: 1px solid ${({ theme }) => theme.colors.neutrals.hue100};
  padding-top: 1.5rem;
  @media ${breakpoints.laptopSm} {
    gap: 7.5rem;
  }
`;

export const StyledOverviewGraphCard = styled.div<{ $color: string }>`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  position: relative;
  justify-content: space-evenly;
  background-color: ${({ theme }) => theme.colors.neutrals.hue0};
  gap: 0.25rem;

  &:before {
    content: '';
    position: absolute;
    left: -0.125rem;
    width: 0.25rem;
    height: 100%;
    border-radius: 0.625rem;
    background-color: ${({ theme, $color }) => $color ?? theme.v2.text.information};
  }
`;
