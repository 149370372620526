/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LegalFormResponse } from '../models/LegalFormResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LegalFormApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns LegalFormResponse Fetch all Legal forms
   * @throws ApiError
   */
  public getLegalForms(): CancelablePromise<Array<LegalFormResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/legal-form',
      errors: {
        500: `Bad Request`,
      },
    });
  }

}
