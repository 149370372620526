import styled from 'styled-components';

type ButtonTypes = {
  $isSelected: boolean;
};

export const StyledBtn = styled.button.attrs(() => ({ type: 'button' }))<ButtonTypes>`
  display: grid;
  place-items: center;
  background-color: ${({ $isSelected, theme }) => $isSelected && theme.v2.surface.action1};
  color: ${({ $isSelected, theme }) => ($isSelected ? theme.v2.text.onAction : theme.v2.text.bodyPrimary)};

  &:hover,
  &:active,
  &:focus-visible {
    background-color: ${({ $isSelected, theme }) =>
      $isSelected ? theme.v2.surface.action1 : theme.v2.surface.informationLight};
    color: ${({ $isSelected, theme }) => ($isSelected ? theme.v2.text.onAction : theme.v2.text.information)};
  }

  &:disabled {
    color: ${({ theme }) => theme.v2.text.disabled};
    pointer-events: none;
  }
`;

export const ButtonMonth = styled(StyledBtn)<ButtonTypes>`
  width: 100%;
  height: 3.5rem;
`;

export const ButtonDayYear = styled(StyledBtn)<ButtonTypes>`
  width: 3.5rem;
  height: 3.5rem;
`;

export const ButtonYearArrow = styled(StyledBtn)`
  place-self: center;
  width: 2.5rem;
  height: 2.5rem;
`;
