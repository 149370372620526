import { ItemProps } from '@metaswiss/ui-kit/src/components/molecules/selection-popup/types';

import { IdentityVerification } from '../../../../../../../enums/identityVerification';
import { DocumentProps, PhotoDocumentProps } from '../../../../shared/types/documentUploadType';

export type FullPrivateRegistrationData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  citizenship: ItemProps;
  phoneNumberPrefix: string;
  phoneNumber: string;
  birthDate: string;
  qualifiedInvestor: boolean;
  termsAndConditions: boolean;
  country: ItemProps;
  city: string;
  address: string;
  zipCode: string;
  profilePhoto: PhotoDocumentProps;
  proofOfDomicile: DocumentProps;
  identityVerification: IdentityVerification;
  identityDocument: { frontSide: DocumentProps; backSide: DocumentProps; passport: DocumentProps };
};

export const initialFullPrivateRegistrationValues: FullPrivateRegistrationData = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  citizenship: {
    value: '',
    label: '',
  },
  phoneNumberPrefix: '',
  phoneNumber: '',
  birthDate: '',
  qualifiedInvestor: false,
  termsAndConditions: false,
  country: {
    value: '',
    label: '',
  },
  city: '',
  address: '',
  zipCode: '',
  profilePhoto: {
    fileId: '',
    url: '',
  },
  proofOfDomicile: {
    fileId: '',
    url: '',
    fileName: '',
    fileSize: '',
  },
  identityVerification: IdentityVerification.ID,
  identityDocument: {
    frontSide: {
      fileId: '',
      url: '',
      fileName: '',
      fileSize: '',
    },
    backSide: {
      fileId: '',
      url: '',
      fileName: '',
      fileSize: '',
    },
    passport: {
      fileId: '',
      url: '',
      fileName: '',
      fileSize: '',
    },
  },
};
