// Vite generated file, we didn't change the name to avoid causing any problems
/* eslint-disable check-file/filename-naming-convention */
import { HttpClient } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';

import App from './App';
import { environmentVariables } from './env/environmentVariables.ts';

import './i18n.ts';

Sentry.init({
  environment: environmentVariables.env,
  dsn: environmentVariables.sentryUrl,
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
    new HttpClient({
      failedRequestStatusCodes: [[400, 599]],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [environmentVariables.sentryAppUrl, environmentVariables.baseApiUrl],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Suspense fallback={<></>}>
      <App />
    </Suspense>
  </React.StrictMode>
);
