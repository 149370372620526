import { Text } from '@metaswiss/ui-kit';
import React, { FC } from 'react';
import { useTheme } from 'styled-components';

import { ContentContainer, StyledContainer } from './cardOfferingDetailsHolder.styles';

type Props = {
  heading: string;
  children: React.ReactNode;
  fitHeight?: boolean;
};

export const CardOfferingDetailsHolder: FC<Props> = ({ heading, children, fitHeight = true }) => {
  const theme = useTheme();
  return (
    <StyledContainer $fitHeight={fitHeight}>
      <Text fontWeight={'bold'} fontSize={'lg'} lineHeight={'h4'} color={theme.v2.text.headingPrimary}>
        {heading}
      </Text>
      <ContentContainer>{children}</ContentContainer>
    </StyledContainer>
  );
};
