import { styled } from 'styled-components';

export const MessageBodyWrapper = styled.div`
  padding-left: 2.75rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const DocumentWrapper = styled.div`
  width: 20rem;
`;
