import { useTheme } from 'styled-components';

import { ThemedIcon } from '../..';
import { ErrorFeedbackIcon, RemSize } from '../../..';

import { BaseFeedback } from '.';

export type Props = {
  title: string;
  subTitle: string;
  subTitleRender?: (subTitle: string) => JSX.Element;
  titleRender?: (title: string) => JSX.Element;
  fill?: boolean;
  imageWidth?: RemSize | 'auto';
  contentGap?: RemSize;
  padding?: `${RemSize} ${RemSize} ${RemSize} ${RemSize}` | `${RemSize} ${RemSize}` | RemSize;
  textWidth?: RemSize | 'max-content';
};

export const ErrorFeedback: React.FC<Props> = ({
  title,
  titleRender,
  subTitle,
  subTitleRender,
  fill,
  imageWidth,
  contentGap,
  textWidth,
}) => {
  const theme = useTheme();

  return (
    <BaseFeedback
      imageWidth={imageWidth}
      textWidth={textWidth}
      hasButton
      title={title}
      titleRender={titleRender}
      subTitle={subTitle}
      subTitleRender={subTitleRender}
      contentGap={contentGap}
      icon={
        <ThemedIcon
          icon={() => (
            <ErrorFeedbackIcon
              backgroundColor={theme.v2.colors.error25}
              foregroundColor={theme.v2.icon.error}
              borderColor={theme.v2.surface.secondary}
            />
          )}
          size="full"
        />
      }
      fill={fill}
    />
  );
};
