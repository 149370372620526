import styled from 'styled-components';

import { Verify } from '../../pages/verify/Verify';

export const VerifyLayout = () => {
  return (
    <Screen>
      <Verify />
    </Screen>
  );
};

const Screen = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  > img {
    height: 100%;
    width: 100%;
  }
`;
