import { FC } from 'react';

import { IconProps } from './iconProps';

export const ArrowRightIcon: FC<IconProps> = ({ color = 'none', strokeColor = '#222223' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7999 9.59985L19.1999 15.9999L12.7999 22.3999"
        stroke={strokeColor}
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
