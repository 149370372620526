import styled from 'styled-components';

import { CardContainer } from '../base-card/baseCard.styles';

export const TransactionCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderContainer = styled.div`
  padding: 0.75rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
`;

export const ProductInfoContainer = styled.div`
  padding: 1rem;
`;

export const ProductTypeDot = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
`;

export const TransactionInfoWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.75rem;
  height: 100%;
  max-height: 2.75rem;
`;

export const IconWrapper = styled.div`
  width: 2.75rem;
  height: 2.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.v2.surface.tertiary};
  border-radius: ${({ theme }) => theme.v2.radius.smaller};
`;

export const ProductTypeName = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const ProductTypeValuesLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductTypeArrow = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  transition: all 0.2s ease-out;

  ${CardContainer}:hover & {
    transform: translateX(0.5rem);
  }
`;
