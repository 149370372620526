import * as zod from 'zod';

import { FormNames } from '../../../../../../../../shared/forms/formNames';
import { staticTranslationFunction } from '../../../../../../../../shared/forms/staticTranslationFunction.ts';
import { regex } from '../../../../../../../../shared/regex/regex.ts';

export const createFullCorporateInformationSchema = (translate: (val: string) => string) =>
  zod.object({
    name: zod.string().trim().nonempty(translate('error.requiredField')),
    ceo: zod.string().trim().nonempty(translate('error.requiredField')),
    owner: zod.string().trim().nonempty(translate('error.requiredField')),
    email: zod.string().nonempty(translate('error.requiredField')).email(),
    password: zod.string().nonempty(translate('error.requiredField')).min(5),
    passwordConfirmation: zod.string().nonempty(translate('error.requiredField')).min(5),
    legalFormId: zod.array(dropdownItemSchema).nonempty(translate('error.requiredField')),
    businessFocusId: zod.object({
      value: zod.string(),
      label: zod.string(),
      iconSrc: zod.string().optional(),
      meta: zod.string().optional(),
    }),
    phoneNumberPrefix: zod.string().nonempty(translate('error.requiredField')),
    phoneNumber: zod
      .string()
      .nonempty(translate('error.requiredField'))
      .regex(regex.numbersOnly, translate('error.invalidPhoneNumber'))
      .min(5, { message: translate('error.invalidPhoneNumberMinLength') })
      .max(15, { message: translate('error.invalidPhoneNumberMaxLength') }),
    dateFounded: zod.string().nonempty(translate('error.requiredField')),
    termsAndConditions: zod.literal<boolean>(true),
  });

const dropdownItemSchema = zod.object({
  id: zod.string(),
  label: zod.string(),
});

export const schema = createFullCorporateInformationSchema(staticTranslationFunction);

export type FullCorporateRegistrationCorporateInfoFormData = zod.infer<typeof schema>;

export const fullCorporateRegistrationCorporateInfoSchema: FormNames<FullCorporateRegistrationCorporateInfoFormData> = {
  name: 'name',
  ceo: 'ceo',
  owner: 'owner',
  email: 'email',
  password: 'password',
  passwordConfirmation: 'passwordConfirmation',
  legalFormId: 'legalFormId',
  businessFocusId: 'businessFocusId',
  phoneNumberPrefix: 'phoneNumberPrefix',
  phoneNumber: 'phoneNumber',
  dateFounded: 'dateFounded',
  termsAndConditions: 'termsAndConditions',
} as const;
