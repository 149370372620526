import styled, { css } from 'styled-components';

import { breakpoints } from '../../../breakpoints.ts';

export const ShellContainer = styled.main<{
  $leftColor?: string;
  $rightColor?: string;
}>`
  grid-area: Content;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;

  background-color: ${({ theme }) => theme.v2.surface.secondary};
  ${({ theme, $leftColor, $rightColor }) => {
    if (theme.v2.tenant !== 'MetaSwiss') return null;
    return css`
      &::before {
        content: '';
        display: block;
        width: 43rem;
        height: 43rem;
        border-radius: 50%;
        filter: blur(12.5rem);
        background-color: ${$rightColor || theme.v2.colors.primary100};
        opacity: 0.2;
        position: fixed;
        right: -10%;
        top: -10%;
        transform: translate3d(0, 0, 0);
      }

      &::after {
        content: '';
        background-color: ${$leftColor || theme.v2.colors.secondary100};
        opacity: 0.2;
        display: block;
        width: 43rem;
        height: 43rem;
        border-radius: 50%;
        position: fixed;
        filter: blur(12.5rem);
        left: -10%;
        bottom: -30%;
        transform: translate3d(0, 0, 0);
      }

      @media ${breakpoints.laptopSm} {
        &::after {
          left: calc(6.5rem - 10%);
          bottom: -30%;
        }
      }

      @media ${breakpoints.desktopSm} {
        &::before {
          width: 43rem;
          height: 43rem;
        }

        &::after {
          width: 43rem;
          height: 43rem;
          left: calc(17.5rem - 10%);
          bottom: -30%;
        }
      }
      @media ${breakpoints.desktop2K} {
        &::before {
          width: 56.25rem;
          height: 56.25rem;
        }

        &::after {
          width: 56.25rem;
          height: 56.25rem;
        }
      }
    `;
  }};
`;
