import * as zod from 'zod';

import { FormNames } from '../../../../shared/forms/formNames';
import { staticTranslationFunction } from '../../../../shared/forms/staticTranslationFunction';
import { regex } from '../../../../shared/regex/regex';

export const createPurchaseStepSchema = (translate: (key: string) => string) =>
  zod.object({
    currency: currencyItemSchema,
    quantity: zod
      .string()
      .nonempty(translate('error.requiredField'))
      .regex(regex.numbersOnly, translate('error.invalidPhoneNumber')),
    totalAmount: zod.string().nonempty(translate('error.requiredField')),
    availableInvestment: zod.object({ id: zod.string(), value: zod.string() }),
  });
const currencyItemSchema = zod.object({
  value: zod.string(),
  label: zod.string(),
  iconSrc: zod.string().optional(),
});
export const schema = createPurchaseStepSchema(staticTranslationFunction);

export type PurchaseFormData = zod.infer<typeof schema>;

export const purchaseStepSchema: FormNames<PurchaseFormData> = {
  currency: 'currency',
  quantity: 'quantity',
  totalAmount: 'totalAmount',
  availableInvestment: 'availableInvestment',
} as const;
