import { CaseConversationResponse } from '@metaswiss/api';
import { useGetElementsHeight } from '@metaswiss/lib';
import { breakpoints, PageStateContainer } from '@metaswiss/ui-kit';
import { StyledBottomNavigationBar } from '@metaswiss/ui-kit/src/components/organism/bottom-navigation-bar/bottomNavigationBar.styles';
import { useMediaQuery } from '@metaswiss/ui-kit/src/shared/hooks/use-media-query/useMediaQuery';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { api } from '../../../../api/msApi';
import { CaseStatusEnum } from '../../../../enums/caseStatus.enum';
import { ApiResource } from '../../../../enums/resource.enum';
import { useHeaderOptions, useShellNavigationState } from '../../../../global-state/zustand';
import { usePagination } from '../../../../hooks/use-pagination/usePagination';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';

import { CaseChatHolder, CaseDetailsWrapper, StyledCaseDetailsContent } from './caseDetails.styles';
import { CaseChat } from './components/case-chat/CaseChat';
import { CaseChatHistory } from './components/case-chat-history/CaseChatHistory';
import { CaseHeader } from './components/case-header/CaseHeader';
import { StyledCaseHeader } from './components/case-header/caseHeader.styles';

export const CaseDetails = () => {
  const navigationHeight = useGetElementsHeight([StyledCaseHeader.styledComponentId]);
  const navigationHeightSidebar = useGetElementsHeight([StyledBottomNavigationBar.styledComponentId]);
  const remToNumber = (remValue: string) => parseFloat(remValue.replace('rem', ''));
  const navigationHeightSidebarMobile = remToNumber(navigationHeight) + remToNumber(navigationHeightSidebar);

  const isDesktopSize = useMediaQuery(breakpoints.laptopSm);

  const { textTranslation } = useTextTranslation();
  const queryClient = useQueryClient();
  const { id = '' } = useParams();
  const { removeTabs } = useShellNavigationState();
  const { setHeaderTitle } = useHeaderOptions();

  useEffect(() => {
    removeTabs();
    setHeaderTitle(textTranslation('cases.caseDetails'));
  }, [removeTabs, setHeaderTitle, textTranslation]);

  const {
    data: caseDetails,
    isLoading: isLoadingCaseDetails,
    isError: isErrorCaseDetails,
    refetch: isRefetchCaseDetails,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.CASE, id),
    queryFn: () => api.case.getCaseDetails1(id!),
    enabled: !!id,
  });
  const {
    data: caseConversations,
    isError: isErrorConversations,
    isLoading: isLoadingConversations,
    refetch: refetchConversations,
    lastItemRef,
  } = usePagination<CaseConversationResponse>({
    queryKey: getQueryKey(ApiResource.CASE_CONVERSATION, id),
    queryFn: (offset: number, limit: number) => api.case.getCaseConversation(id, offset, limit),
    enabled: true,
  });

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.CASE, id) });
    };
  }, [id]);

  return (
    <PageStateContainer
      showLoading
      isLoading={isLoadingCaseDetails || isLoadingConversations}
      showError
      isError={isErrorCaseDetails || isErrorConversations}
      textTranslation={textTranslation}
      onTryAgain={() => Promise.all([isRefetchCaseDetails(), refetchConversations()])}
      maxWidth={'100%'}
      removeMargins
    >
      <CaseDetailsWrapper $height={isDesktopSize ? navigationHeight : `${navigationHeightSidebarMobile}rem`}>
        <StyledCaseDetailsContent>
          <CaseHeader caseDetails={caseDetails!} caseId={id} />
          <CaseChatHistory lastItemRef={lastItemRef} data={caseConversations ?? []} />
          <CaseChatHolder>{caseDetails?.status !== CaseStatusEnum.APPROVED && <CaseChat caseId={id} />}</CaseChatHolder>
        </StyledCaseDetailsContent>
      </CaseDetailsWrapper>
    </PageStateContainer>
  );
};
