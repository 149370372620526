import { BaseCard } from '@metaswiss/ui-kit';
import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import styled from 'styled-components';

export const StyledBuyCard = styled(BaseCard)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  max-width: unset;
  padding: 1rem;

  @media ${breakpoints.tabletSm} {
    min-width: 14.625rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 1rem;
`;

export const IconWrapper = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.v2.surface.informationLight};
  padding: 0.625rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
`;

export const DescriptionWrapper = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
`;
