import { BaseCard } from '@metaswiss/ui-kit';
import { TextTemplate } from '@metaswiss/ui-kit/src/shared/typography/TextTemplate.styles';
import styled from 'styled-components';

export const StyledBenefitsCard = styled(BaseCard)`
  display: flex;
  max-width: unset;
  padding: 1.25rem;
  width: 100%;
  position: relative;
  border-style: dashed;
`;

export const BenefitsCardContainer = styled.div`
  display: flex;
  gap: 1.25rem;
  width: fit-content;
`;

export const BenefitsCardIconWrapper = styled.div`
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.v2.surface.informationLight};
  padding: 0.5rem;
  border-radius: ${({ theme }) => theme.v2.radius.smaller};
`;

export const BenefitsCardBgIconWrapper = styled.div`
  position: absolute;
  width: 4rem;
  height: 4rem;
  right: 0.75rem;
  top: 0.75rem;
  opacity: 0.1;
`;

export const BenefitsCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const BenefitsCardText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  ${TextTemplate}:first-child {
    letter-spacing: 0.1rem;
  }
`;
export const BenefitsCardList = styled.ul`
  display: flex;
  padding-left: 1.25rem;
  flex-direction: column;
  gap: 1rem;
`;

export const BenefitsCardItem = styled.li`
  line-height: 1.25rem;
  color: ${({ theme }) => theme.v2.text.bodyPrimary};
  font-size: 0.875rem;
`;
