import { RemSize, Text } from '@metaswiss/ui-kit';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import {
  DisplayProgressBar,
  ProgressBarContent,
  ProgressBarTextWrapper,
  ProgressBarWrapper,
  StyledProgressBar,
} from './progressBar.styles';

type ProgressBarProps = {
  displayedItems: number;
  totalItems: number;
  progressStartLabel?: string;
  progressStartText?: string;
  progressEndLabel?: string;
  progressEndText?: string;
  hasTextContent?: boolean;
  height?: RemSize | 'auto' | '100%';
  filledColor: string;
  emptyColor: string;
};

export const ProgressBar = ({
  displayedItems,
  totalItems = 0,
  progressStartLabel = '',
  progressStartText = '',
  progressEndLabel = '',
  progressEndText = '',
  height = '0.5rem',
  hasTextContent = true,
  filledColor,
  emptyColor,
}: ProgressBarProps) => {
  const theme = useTheme();
  const progressBarWidth = useMemo(() => (displayedItems / totalItems) * 100 + '%', [displayedItems, totalItems]);

  return (
    <ProgressBarWrapper>
      {hasTextContent && (
        <ProgressBarContent>
          <ProgressBarTextWrapper>
            <Text fontSize={'sm'} lineHeight={'medium'} color={theme.v2.text.disabled}>
              {progressStartLabel}
            </Text>
            <Text lineHeight={'base'} fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
              {progressStartText}
            </Text>
          </ProgressBarTextWrapper>
          <ProgressBarTextWrapper>
            <Text fontSize={'sm'} lineHeight={'medium'} color={theme.v2.text.disabled}>
              {progressEndLabel}
            </Text>
            <Text lineHeight={'base'} fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
              {progressEndText}
            </Text>
          </ProgressBarTextWrapper>
        </ProgressBarContent>
      )}
      <StyledProgressBar $height={height} $backgroundColor={emptyColor}>
        <DisplayProgressBar $width={progressBarWidth} $backgroundColor={filledColor} />
      </StyledProgressBar>
    </ProgressBarWrapper>
  );
};
