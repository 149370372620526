import React, { useEffect, useMemo, useRef, useState } from 'react';

import { ItemProps, ItemWithCustomIconProps } from '../../molecules/selection-popup';

import { InputProps } from './Input';
import {
  StyledError,
  StyledField,
  StyledInputNumber,
  StyledLabel,
  StyledLegend,
  StyledSpan,
  StyledTextNumber,
  TextWrapper,
} from './input.styles.ts';
import {
  StlyedIconNumberWrapper,
  StyledIconNumber,
  StyledInputNumberWrapper,
  StyledNumbers,
} from './inputPhoneNumber.styles.ts';
import { MissingValue } from './shared/components/MissingValue';

export type InputNumberProps = Omit<InputProps, 'renderStartIcon' | 'renderEndIcon' | 'onEndIconClicked'> & {
  activePrefixItem?: ItemProps | ItemWithCustomIconProps | undefined;
  openPopup?: () => void;
  defaultItem?: ItemProps | ItemWithCustomIconProps;
  selectedItem?: ItemProps | ItemWithCustomIconProps;
};

export const InputNumber: React.FC<InputNumberProps> = ({
  error,
  disableFocus = false,
  openPopup,
  activePrefixItem,
  disabled = false,
  value,
  label,
  onChange,
  fitHeight = false,
  isLocked = false,
  defaultItem,
  selectedItem,
  isVisibleLabel = true,
  isOptional,
  ...props
}) => {
  const numberButtonRef = useRef<HTMLButtonElement | null>(null);
  const [numberButtonWidth, setNumberButtonWidth] = useState<number>(0);

  const doesValueExist = !!value;

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setNumberButtonWidth(entries[0].contentRect.width);
    });

    if (numberButtonRef.current) {
      observer.observe(numberButtonRef.current);
    }

    return () => {
      numberButtonRef.current && observer.unobserve(numberButtonRef.current);
    };
  }, [isLocked, doesValueExist]);

  const iconSrc = useMemo(() => {
    return selectedItem?.iconSrc || defaultItem?.iconSrc;
  }, [selectedItem, defaultItem]);

  return (
    <>
      {(value || !isLocked) && (
        <StyledInputNumberWrapper ref={numberButtonRef} type={'button'} onClick={openPopup} $isLocked={isLocked}>
          <StlyedIconNumberWrapper $isLocked={isLocked} $isPrefixSelected={!!activePrefixItem}>
            {typeof iconSrc === 'string' && <StyledIconNumber src={iconSrc} $isLocked={isLocked} />}
          </StlyedIconNumberWrapper>
          <StyledNumbers disabled={disabled}>
            {selectedItem?.meta ? `+${selectedItem.meta}` : `+${defaultItem?.meta}`}
          </StyledNumbers>
        </StyledInputNumberWrapper>
      )}
      <StyledLabel $fitHeight={fitHeight} $isLocked={isLocked}>
        {isLocked && !value ? (
          <MissingValue label={label} isOptional={isOptional} />
        ) : (
          <>
            <StyledInputNumber
              $isVisibleLabel={isVisibleLabel}
              $isActiveInput={!!value}
              $isError={!!error}
              $disableFocus={disableFocus}
              value={value}
              onChange={onChange}
              disabled={disabled}
              type="text"
              $paddingLeft={numberButtonWidth}
              $isLocked={isLocked}
              {...props}
            />
            <TextWrapper $isLocked={isLocked}>
              {numberButtonWidth !== 0 && (
                <StyledTextNumber $leftPosition={numberButtonWidth}>{label}</StyledTextNumber>
              )}
            </TextWrapper>
            {error && <StyledError>{error}</StyledError>}
          </>
        )}
        <StyledField $isLocked={isLocked}>
          <StyledLegend $isLocked={isLocked} $hasContent={!!value}>
            <StyledSpan>{label}</StyledSpan>
          </StyledLegend>
        </StyledField>
      </StyledLabel>
    </>
  );
};
