import { css, styled } from 'styled-components';

const flexStyles = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const NftDetailsContainer = styled.div`
  ${flexStyles};
`;

export const TopDetailsWrapper = styled.div`
  ${flexStyles};
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
`;

export const DetailsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
`;

export const ImageHolder = styled.img<{ $withBorder?: boolean }>`
  width: 5.5rem;
  height: 5.5rem;
  flex-shrink: 0;
  border-radius: ${({ theme }) => theme.v2.radius.smaller};
  border: ${({ theme, $withBorder }) => ($withBorder ? `1px solid ${theme.v2.border.primary}` : 'none')};
  object-fit: cover;
`;

export const DetailsInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ImageDownloadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RealProductDescriptionWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
