import { Heading, Text } from '@metaswiss/ui-kit';

import { RegisterHeaderHolder, RegistrationHeaderContainer } from './registerHeader.styles';

export interface RegisterHeaderProps {
  headingText?: string;
  descriptionText?: string;
}

export const RegisterHeader: React.FC<RegisterHeaderProps> = ({ headingText = '', descriptionText = '' }) => {
  return (
    <RegistrationHeaderContainer>
      <RegisterHeaderHolder>
        <Heading headingType="h4" fontWeight="bold">
          {headingText}
        </Heading>
      </RegisterHeaderHolder>
      <Text>{descriptionText}</Text>
    </RegistrationHeaderContainer>
  );
};
