import * as zod from 'zod';

import { FormNames } from '../../../../../shared/forms/formNames';
import { staticTranslationFunction } from '../../../../../shared/forms/staticTranslationFunction.ts';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const createForgotPassSecondStepSchema = (_: (key: string) => string) =>
  zod.object({
    code: zod.string(),
  });

export const schema = createForgotPassSecondStepSchema(staticTranslationFunction);

export type FormData = zod.infer<typeof schema>;

export const secondStepFormSchema: FormNames<FormData> = {
  code: 'code',
} as const;
