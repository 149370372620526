import { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { Text } from '../../../../components';
import { Flex } from '../flex';

import { Container } from './smallChip.styles';

export enum SmallChipVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  NEUTRAL = 'neutral',
  DISABLED = 'disabled',
  TERTIARY = 'tertiary',
  RESOLVED = 'resolved',
}

type SmallChipWithComponent = {
  renderComponent: JSX.Element;
};

type SmallChipWithoutComponent = {
  renderComponent?: never;
};

export type SmallChipProps = {
  label: string;
  variant: SmallChipVariant;
} & (SmallChipWithComponent | SmallChipWithoutComponent);

export const SmallChipV2: FC<SmallChipProps> = ({ label, variant, renderComponent }) => {
  const theme = useTheme();

  const {
    backgroundColor,
    textColor,
  }: {
    backgroundColor: string;
    textColor: string;
  } = useMemo(() => {
    switch (variant) {
      case SmallChipVariant.PRIMARY:
        return {
          backgroundColor: theme.v2.surface.informationLight,
          textColor: theme.v2.text.information,
        };
      case SmallChipVariant.SECONDARY:
        return {
          backgroundColor: theme.v2.surface.action2Light,
          textColor: theme.v2.text.action2,
        };
      case SmallChipVariant.SUCCESS:
        return {
          backgroundColor: theme.v2.surface.successLight,
          textColor: theme.v2.text.success,
        };
      case SmallChipVariant.ERROR:
        return {
          backgroundColor: theme.v2.surface.errorLight,
          textColor: theme.v2.text.error,
        };
      case SmallChipVariant.WARNING:
        return {
          backgroundColor: theme.v2.surface.warningLight,
          textColor: theme.v2.text.warning,
        };
      case SmallChipVariant.NEUTRAL:
        return {
          backgroundColor: theme.v2.surface.secondary,
          textColor: theme.v2.text.disabled,
        };
      case SmallChipVariant.DISABLED:
        return {
          backgroundColor: theme.v2.surface.secondary,
          textColor: theme.v2.text.disabled,
        };
      case SmallChipVariant.TERTIARY:
        return {
          backgroundColor: theme.v2.surface.tertiary,
          textColor: theme.v2.text.disabled,
        };
      case SmallChipVariant.RESOLVED:
        return {
          backgroundColor: theme.v2.surface.tertiary,
          textColor: theme.v2.text.headingPrimary,
        };
      default:
        return {
          backgroundColor: theme.v2.surface.informationLight,
          textColor: theme.v2.text.information,
        };
    }
  }, [variant]);

  return (
    <Container $bgColor={backgroundColor}>
      <Flex alignItems={'center'} gap={'0.25rem'}>
        <Text fontSize={'xsm'} fontWeight={'semi'} lineHeight={'extraSmall'} color={textColor}>
          {label}
        </Text>
        {renderComponent && renderComponent}
      </Flex>
    </Container>
  );
};
