import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Languages } from '../../enums/language';

import { namespaces } from './constants/namespaces';

export const useTextTranslation = () => {
  const { t, i18n } = useTranslation(namespaces, {
    nsMode: 'fallback',
  });

  const setTranslationLanguage = useCallback(
    (selectedLanguage: Languages) => {
      switch (selectedLanguage) {
        case Languages.ENGLISH:
          i18n.changeLanguage(Languages.ENGLISH);
          break;
        case Languages.GERMAN:
          i18n.changeLanguage(Languages.GERMAN);
          break;
        default:
          i18n.changeLanguage(Languages.ENGLISH);
      }
    },
    [i18n]
  );

  const isTranslateReady = useMemo(() => i18n.isInitialized, [i18n, i18n.isInitialized]);

  const currentLanguage = useMemo(() => i18n.language as Languages, [i18n, i18n.language]);

  const isThisCurrentLanguage = useCallback((language: string) => i18n.language === language, [i18n, i18n.language]);

  return {
    textTranslation: (key: string, prop = {}) =>
      i18n.exists(key, { ns: namespaces }) ? t(key, { ...prop }) : t('global.missingTranslation'),
    setTranslationLanguage,
    isTranslateReady,
    currentLanguage,
    isThisCurrentLanguage,
  };
};
