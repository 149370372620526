import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface ForgotPasswordState {
  email: string;
  setEmail: (email: string) => void;
  clean: () => void;
  code: string;
  setCode: (code: string) => void;
}

export const useForgotPasswordState = create<ForgotPasswordState>()(
  devtools(
    immer((set) => ({
      email: '',
      code: '',

      setEmail: (email) =>
        set((state) => {
          state.email = email;
        }),

      setCode: (code) =>
        set((state) => {
          state.code = code;
        }),

      clean: () =>
        set((state) => {
          state.email = '';
          state.code = '';
        }),
    }))
  )
);
