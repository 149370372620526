/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EventsApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param shareId
   * @param auditSource
   * @param fromDate
   * @param toDate
   * @returns any Get price chart data
   * @throws ApiError
   */
  public getPriceChart(
    shareId: string,
    auditSource?: string,
    fromDate?: string,
    toDate?: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/audits/price-chart',
      query: {
        'auditSource': auditSource,
        'shareId': shareId,
        'fromDate': fromDate,
        'toDate': toDate,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param shareId
   * @param auditSource
   * @param fromDate
   * @param toDate
   * @returns any Get price chart data
   * @throws ApiError
   */
  public getShareAudit(
    shareId: string,
    auditSource?: string,
    fromDate?: string,
    toDate?: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/audits/share',
      query: {
        'auditSource': auditSource,
        'shareId': shareId,
        'fromDate': fromDate,
        'toDate': toDate,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
