/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderAdminApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param transactionId
   * @returns void
   * @throws ApiError
   */
  public getOrderByTransactionId(
    transactionId: string,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/admin/order/search',
      query: {
        'transactionId': transactionId,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
