import { useTheme } from 'styled-components';

import { BaseIconButton } from './BaseIconButton';

export type Props = {
  icon: JSX.Element;
  onClick?: () => void;
  padding?: string;
  selectedButton?: boolean;
};

export const SquareIconButton: React.FC<Props> = ({ onClick, icon, padding = '0.5rem', selectedButton = false }) => {
  const theme = useTheme();

  return (
    <BaseIconButton
      onClick={onClick}
      padding={padding}
      icon={icon}
      radius={theme.v2.radius.smaller}
      selectedButton={selectedButton}
      borderWidth="0"
    />
  );
};
