import { FC } from 'react';

import { IconProps } from './iconProps';

export const CreditCardIcon: FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 22 16" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.99798 5.29995H19.998M5.59798 9.49995H8.59798M3.79844 1.1001H18.1981C19.5235 1.1001 20.598 2.17377 20.5981 3.49923L20.5983 12.5011C20.5984 13.8266 19.5238 14.9001 18.1983 14.9001L3.79867 14.8999C2.47322 14.8999 1.39873 13.8254 1.39869 12.5L1.39844 3.50016C1.3984 2.17465 2.47293 1.1001 3.79844 1.1001Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
