import { FC, PropsWithChildren } from 'react';

import { ColorPalette } from '../../..';

import { Wrapper } from './blurredBackgroud.styles';

type Props = {
  palette: ColorPalette;
  customBlurBackground?: boolean;
};

export const BlurredBackground: FC<PropsWithChildren<Props>> = ({
  palette,
  children,
  customBlurBackground = false,
}) => {
  return (
    <Wrapper $palette={palette} $customBlurBackground={customBlurBackground}>
      {children}
    </Wrapper>
  );
};
