import { Button, Text } from '@metaswiss/ui-kit';
import { FC } from 'react';

import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';

import { NotificationErrorWrapper } from './notificationPopup.styles';

export type NotificationErrorState = {
  action: () => void;
};

export const NotificationErrorState: FC<NotificationErrorState> = ({ action }) => {
  const { textTranslation } = useTextTranslation();
  return (
    <NotificationErrorWrapper>
      <Text fontWeight="bold" fontSize="sm" lineHeight="medium">
        {textTranslation('cases.errorTitle')}
      </Text>
      <Button onClick={action} text={textTranslation('cases.errorButton')} size="medium" />
    </NotificationErrorWrapper>
  );
};
