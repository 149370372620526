import styled from 'styled-components';

type Props = {
  $fontWeight: 'reg' | 'bold' | 'semi';
  $isDisabled: boolean;
};

export const DropdownText = styled.p<Props>`
  flex: 1 0 auto;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  font-weight: ${({ theme, $fontWeight }) => theme.typography.weights[$fontWeight]};
  font-size: ${({ theme }) => theme.typography.sizes.base};
  color: ${({ theme, $isDisabled }) => ($isDisabled ? theme.v2.text.disabled : theme.v2.text.headingPrimary)};
`;
