import { motion } from 'framer-motion';
import styled from 'styled-components';

import { WindowProps } from '../types';

export const PopupWrapper = styled.div`
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
`;
export const PopupWindow = styled(motion.div)<WindowProps>`
  background-color: ${({ theme }) => theme.v2.surface.primary};
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.large};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  padding: ${({ $fill }) => ($fill ? '0rem' : '1rem')};
  max-width: ${({ $maxWidth }) => ($maxWidth ? $maxWidth : 'none')};
  width: ${({ $width, $maxWidth }) => ($maxWidth ? `calc(100% - 2rem)` : $width)};
  height: ${({ $height }) => $height};
  overflow: hidden;
`;
