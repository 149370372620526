import { formatAmountWithoutDecimals } from '@metaswiss/lib';
import { Text, TextLink } from '@metaswiss/ui-kit';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';

import { ContentContainer, StyledContainer, StyledContentWrapper } from './portfolioItemHolder.styles';

type Props = {
  heading: string;
  children: React.ReactNode;
  navigateUrl: string;
  countItems: number;
};

export const PortfolioItemHolder: FC<Props> = ({ heading, children, navigateUrl, countItems }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { textTranslation } = useTextTranslation();
  return (
    <>
      {countItems > 0 && (
        <StyledContainer>
          <StyledContentWrapper>
            <Text fontWeight={'bold'} fontSize={'lg'} lineHeight={'h4'} color={theme.v2.text.headingPrimary}>
              {heading}
            </Text>
            {countItems > 2 && (
              <TextLink onClick={() => navigate(navigateUrl)}>{`${textTranslation(
                'global.viewAll'
              )} (${formatAmountWithoutDecimals(countItems)})`}</TextLink>
            )}
          </StyledContentWrapper>
          <ContentContainer>{children}</ContentContainer>
        </StyledContainer>
      )}
    </>
  );
};
