import styled, { css } from 'styled-components';

import { breakpoints } from '../../../breakpoints';
import { TabButton } from '../../atoms/tab/tab.styles';

type TabsColorProps = {
  $hasTabs: boolean;
};

type ArrowIconProps = Omit<TabsColorProps, '$borderColor' | '$hasTabs'> & {
  width: string;
};

export const TabsWrapper = styled.div<TabsColorProps>`
  position: relative;
  background-color: ${({ theme }) => theme.v2.surface.primary};
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
  display: flex;
  flex-direction: column;

  ${TabButton} {
    flex-shrink: 0;
  }
`;

export const TabsSlider = styled.div<{ $isFilterActive: boolean; $hasTabs: boolean }>`
  scroll-behavior: smooth;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: ${({ $hasTabs }) => ($hasTabs ? '0.5rem 1rem' : '0')};
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-bottom: ${({ $isFilterActive, $hasTabs, theme }) =>
    $isFilterActive && $hasTabs ? `1px solid ${theme.colors.neutrals.hue100}` : 'none'};

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledIcon = css`
  display: none;

  @media ${breakpoints.laptopSm} {
    position: absolute;
    z-index: 1;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }
`;

export const LeftIconContainer = styled.button<ArrowIconProps>`
  ${StyledIcon};
  left: 0;
  width: ${(props) => props.width};
  background-color: ${({ theme }) => theme.v2.surface.primary};
  padding-left: 0.5rem;
  background: ${({ theme }) => `linear-gradient(to right, ${theme.v2.surface.primary} 50%, transparent)`};
  justify-content: flex-start;
`;

export const RightIconContainer = styled.button<ArrowIconProps>`
  ${StyledIcon};
  right: 0;
  width: ${(props) => props.width};
  justify-content: flex-end;
  padding-right: 0.5rem;
  background: ${({ theme }) => `linear-gradient(to left, ${theme.v2.surface.primary} 50%, transparent)`};
`;

export const FiltersContainer = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;
