import styled from 'styled-components';

import { breakpoints } from '../../../breakpoints';
import { TextTemplate } from '../../../shared/typography/TextTemplate.styles';

export const StyledItemTextWrapper = styled.div`
  @media ${breakpoints.laptopSm} {
    display: none;
  }

  @media ${breakpoints.desktopSm} {
    display: block;
  }
`;

export const StyledSidebarIcon = styled.span<{
  $isSelected: boolean;
}>`
  display: inline-block;

  path {
    stroke: ${({ $isSelected, theme }) => ($isSelected ? theme.v2.icon.primary : theme.v2.icon.neutral)};
  }

  font-size: 0;

  @media ${breakpoints.laptopSm} {
    path {
      stroke: ${({ theme, $isSelected }) => ($isSelected ? theme.v2.icon.primary : theme.v2.icon.neutral)};
    }
  }
`;

export const SidebarItemTooltip = styled.div`
  position: absolute;
  display: none;
  padding: 0.5rem 0.75rem;
  right: 0;
  transform: translateX(calc(100% + 1rem));
  background-color: ${({ theme }) => theme.v2.surface.tertiary};
  border-radius: ${({ theme }) => theme.v2.radius.smaller};
  white-space: nowrap;

  &::before {
    content: '';
    position: absolute;
    width: 0.75rem;
    height: 0.75rem;
    top: 50%;
    left: 0;
    transform: translate(-30%, -50%) rotate(-135deg);
    z-index: -1;
    background-color: ${({ theme }) => theme.v2.surface.tertiary};
    border-radius: ${({ theme }) => theme.v2.radius.small};
  }
`;

export const StyledSidebarItem = styled.div<{
  $isSelected: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0;
  border-radius: ${({ theme }) => theme.v2.radius.small};
  background-color: ${({ theme }) => theme.v2.surface.primary};

  ${StyledItemTextWrapper} {
    ${TextTemplate} {
      color: ${({ theme, $isSelected }) => ($isSelected ? theme.v2.text.action : theme.v2.text.disabled)};
      font-weight: ${({ theme, $isSelected }) => $isSelected && theme.typography.weights.semi};

      @media ${breakpoints.laptopSm} {
        font-weight: ${({ theme, $isSelected }) => $isSelected && theme.typography.weights.semi};
        color: ${({ theme, $isSelected }) => ($isSelected ? theme.v2.text.action : theme.v2.text.disabled)};
      }
    }
  }

  @media ${breakpoints.laptopSm} {
    padding: 1rem;
    width: 100%;
    flex-direction: row;
    gap: 1rem;
    background-color: ${({ theme, $isSelected }) => $isSelected && theme.v2.surface.informationLight};

    &:hover,
    &:active {
      ${StyledSidebarIcon} {
        path {
          stroke: ${({ theme, $isSelected }) => !$isSelected && theme.v2.icon.default};
        }
      }

      ${SidebarItemTooltip} {
        display: block;
      }
    }

    ${StyledItemTextWrapper} {
      ${TextTemplate} {
        color: ${({ theme, $isSelected }) => ($isSelected ? theme.v2.text.action : theme.v2.text.disabled)};
        font-weight: ${({ theme, $isSelected }) => $isSelected && theme.typography.weights.semi};
      }
    }

    &:hover {
      background-color: ${({ theme, $isSelected }) =>
        $isSelected ? theme.v2.surface.informationLight : theme.v2.surface.tertiary};
    }

    &:active {
      background-color: ${({ theme, $isSelected }) =>
        $isSelected ? theme.v2.surface.informationLight : theme.v2.surface.tertiary};
    }
  }

  @media ${breakpoints.desktopSm} {
    &:hover,
    &:active {
      ${SidebarItemTooltip} {
        display: none;
      }
    }
  }
`;
