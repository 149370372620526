import { TextTemplate } from '@metaswiss/ui-kit/src/shared/typography/TextTemplate.styles';
import styled, { css } from 'styled-components';

const containersStyles = css`
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  border-radius: ${({ theme }) => theme.v2.radius.medium};
  background-color: ${({ theme }) => theme.v2.surface.primary};
`;

export const PanelContainer = styled.div`
  ${containersStyles};
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
  padding: 3rem 0 1rem 0;
`;

export const PanelCover = styled.div`
  position: absolute;
  height: 7rem;
  width: 100%;
  top: 0;
  left: 0;
`;

export const PanelCoverImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const AvatarContainer = styled.div`
  border: 4px solid ${({ theme }) => theme.v2.surface.primary};
  position: relative;
  width: fit-content;
  border-radius: 50%;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AccountStatusWrapper = styled.div`
  padding: 1rem;
`;

export const StatusInformationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-radius: ${({ theme }) => theme.v2.border.smaller};
`;

export const PersonalInformationContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 0.5rem;
  padding: 1.5rem;
`;

export const SingleInformationContainer = styled.div`
  height: 5.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-left: 1.25rem;

  ${TextTemplate} {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const NoValueWrapper = styled.div`
  height: 5.25rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding-left: 1.25rem;
`;
