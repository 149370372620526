import styled from 'styled-components';

export const LayoutHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
`;

export const HeadingAmountWrapper = styled.div`
  margin-top: 0.5rem;
`;
export const TextWrapper = styled.div`
  margin-top: 0.75rem;
`;
export const ChipWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;
