import { styled } from 'styled-components';

export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
  height: 4rem;
`;

export const ModalMain = styled.div`
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  gap: 1.25rem;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  justify-content: center;
`;

export const StepSection = styled.div`
  width: 100%;
  height: 3rem;
  display: flex;
  gap: 1.5rem;
  padding: 0.75rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
  justify-content: center;
`;

export const SubHeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const OptionsContainer = styled.div<{ $step: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ $step }) => ($step === 0 ? '16.5rem' : '18.5rem')};
  overflow: hidden;
`;

export const DocumentTypeContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const RadioButtonWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 2rem;
  left: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const UploadWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const LineContainer = styled.div`
  width: 10.5rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  width: 9.25rem;
`;

export const SecondStepContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  width: 12.25rem;
`;

export const StepWrapper = styled.div`
  height: 100%;
  width: fit-content;
  display: flex;
  flex-direction: row;
`;

export const Description = styled.div`
  width: fit-content;
  height: 2.5rem;
  padding: 0.625rem 0.75rem;
  border-radius: 0.5rem;
  border: 1px dashed ${({ theme }) => theme.v2.border.primaryHover};
  background: ${({ theme }) => theme.v2.surface.tertiary};
  display: flex;
  align-items: center;
`;

export const Graphic = styled.div`
  width: 11.25rem;
  height: 11.25rem;
  position: absolute;
  bottom: -1rem;
  right: 0.625rem;
`;

export const UploadContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ResponseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
`;

export const ResponseButtonWrapper = styled.div`
  width: 100%;
`;
