import { FC } from 'react';

import { Button, SidePanel, Text, TextLink, ThemedIcon } from '../..';
import { ArrowLeftSmIcon } from '../../..';

import { BackArrowWrapper, Body, Footer, Header, Title } from './filter.styles';

export type FilterProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title: string;
  clearTitle: string;
  isDisabledHeaderButton?: boolean;
  titleButtonOnClick?: () => void;
  submitButtonText: string;
  submitButtonOnClick?: () => void;
  children: React.ReactNode;
};

export const Filter: FC<FilterProps> = ({
  isOpen,
  setIsOpen,
  title,
  clearTitle,
  isDisabledHeaderButton,
  titleButtonOnClick,
  submitButtonText,
  submitButtonOnClick,
  children,
}) => {
  const submit = () => {
    if (submitButtonOnClick) {
      submitButtonOnClick();
    }
    setIsOpen(false);
  };

  return (
    <SidePanel isOpen={isOpen} setIsOpen={setIsOpen}>
      <Header>
        <Title
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <BackArrowWrapper>
            <ThemedIcon icon={ArrowLeftSmIcon} size={'medium'} palette={'neutrals'} strokeColor={'hue700'} />
          </BackArrowWrapper>
          <Text fontWeight={'bold'}>{title}</Text>
        </Title>
        <TextLink color={'error'} padding={'0.25rem'} onClick={titleButtonOnClick} disabled={isDisabledHeaderButton}>
          {clearTitle}
        </TextLink>
      </Header>
      <Body>{children}</Body>
      <Footer>
        <Button text={submitButtonText} fill onClick={submit} disabled={isDisabledHeaderButton} />
      </Footer>
    </SidePanel>
  );
};
