export const breakpoints = {
  tabletSmHeight: '(min-height: 768px)',
  tabletSm: '(min-width: 768px)',
  tabletMd: '(min-width: 840px)',
  laptopSm: '(min-width: 1024px)',
  laptopMd: '(min-width: 1280px)',
  desktopSm: '(min-width: 1440px)',
  desktopMd: '(min-width: 1680px)',
  desktopLg: '(min-width: 1920px)',
  desktop2K: '(min-width: 2560px)',
};

export const breakpointValues = {
  tabletSm: 768,
  tabletMd: 840,
  laptopSm: 1024,
  laptopMd: 1280,
  desktopSm: 1440,
  desktopMd: 1680,
  desktopLg: 1920,
  desktop2K: 2560,
};
