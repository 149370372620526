export enum NftCommonStatusEnum {
  AVAILABLE = 'available',
  SOLD = 'sold',
  RESERVED = 'reserved',
  OWNED = 'owned',
}

export type Content = {
  content1?: string;
  content2?: string;
  content3?: string;
  content4?: string;
  content5?: string;
};

export type NftCommonResponse = {
  id: string;
  serialNumber: string;
  price: number;
  status: NftCommonStatusEnum;
  imageUrl: string;
  thumbnailUrl: string;
};
