import styled from 'styled-components';

export const ProtectedLayoutContainerV2 = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  gap: 0px;
  grid-template-areas:
    'Sidebar Header'
    'Sidebar Content';
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

export const ProtectedLayoutHeader = styled.div`
  grid-area: Header;
  height: fit-content;
  position: relative;
`;

export const ProtectedLayoutContent = styled.div`
  grid-area: Content;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
  background-color: ${({ theme }) => theme.colors.neutrals.hue25};
`;
