/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetResponse } from '../models/AssetResponse';
import type { NftProductResponse } from '../models/NftProductResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NftProductApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param id
   * @returns NftProductResponse Get NFT Product by Id
   * @throws ApiError
   */
  public getNftProductById(
    id: string,
  ): CancelablePromise<NftProductResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/nft-product/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns AssetResponse Get NFT Product Asset by Id
   * @throws ApiError
   */
  public getNftProductAssetById(
    id: string,
  ): CancelablePromise<AssetResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/nft-product/{id}/asset',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns NftProductResponse Get NFT Products
   * @throws ApiError
   */
  public getNftProducts(): CancelablePromise<Array<NftProductResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/nft-product',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
