import { RemSize } from '@metaswiss/ui-kit';
import { styled } from 'styled-components';

export const CaseDetailsWrapper = styled.div<{ $height: RemSize }>`
  height: ${({ $height }) => `calc(100vh - ${$height})`};
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'Content Sidebar';
  overflow-y: hidden;
`;

export const StyledCaseDetailsContent = styled.div`
  grid-area: 'Content';
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

export const CaseChatContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 2.25rem 1rem 1rem 1rem;
  gap: 2.25rem;
  display: flex;
  flex-direction: column-reverse;
  margin-top: auto;
`;

export const StyledFixedSidebar = styled.div`
  grid-area: 'Sidebar';
  width: 25rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.v2.surface.primary};
  border-left: 1px solid ${({ theme }) => theme.v2.border.primary};
`;

export const CaseChatHolder = styled.div`
  padding: 0 1rem 1rem 1rem;
`;
