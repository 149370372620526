import { Dropdown } from '@metaswiss/ui-kit';
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form';
import styled from 'styled-components';

import { DropdownItem, DropdownProps } from '../../atoms/dropdown/Dropdown';

export type FormDropdownProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  error?: FieldError;
  disabled?: boolean;
} & Omit<DropdownProps, 'onSelect' | 'selectedItems' | 'error'>;

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 0.5rem;
  height: 3.5rem;
  margin-bottom: 1.25rem;
  min-width: 0;
`;

export const FormDropdown = <T extends FieldValues>({
  name,
  error,
  control,
  disabled,
  ...dropdownProps
}: FormDropdownProps<T>) => {
  return (
    <StyledContainer>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Dropdown
            onSelect={onChange as (item: DropdownItem[]) => void}
            selectedItems={(value || []) as DropdownItem[]}
            error={value ? error?.message : undefined}
            disabled={disabled}
            {...dropdownProps}
          />
        )}
      />
    </StyledContainer>
  );
};
