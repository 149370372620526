import { FC } from 'react';

import { IconProps } from './iconProps';

export const FolderIconPlus: FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1029 19.515H4.30386C2.97836 19.515 1.90384 18.4405 1.90386 17.115L1.90395 7.99071C1.90395 7.07679 1.90361 5.77505 1.90332 4.83251C1.90312 4.16959 2.44045 3.63281 3.10337 3.63281H8.82158L11.5866 6.58645H19.9029C20.5657 6.58645 21.1029 7.12371 21.1029 7.78645V10.974M18.7029 20.3679L18.7029 16.9737M18.7029 16.9737L18.7029 13.5796M18.7029 16.9737L15.3088 16.9737M18.7029 16.9737L22.097 16.9737"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
