import { RemSize } from '@metaswiss/ui-kit';
import { styled } from 'styled-components';

type FlexWrapperProps = {
  $flexDirection: 'column' | 'row' | 'column-reverse';
  $alignItems: 'center' | 'flex-start' | 'flex-end' | 'normal';
  $justifyContent: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  $wrap: 'wrap' | 'nowrap';
  $gap: RemSize;
  $fill: boolean;
};

export const FlexWrapper = styled.div<FlexWrapperProps>`
  width: ${({ $fill }) => $fill && '100%'};
  display: flex;
  flex-direction: ${({ $flexDirection }) => $flexDirection};
  align-items: ${({ $alignItems }) => $alignItems};
  justify-content: ${({ $justifyContent }) => $justifyContent};
  gap: ${({ $gap }) => $gap};
  flex-wrap: ${({ $wrap }) => $wrap};
`;
