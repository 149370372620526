import { FC } from 'react';
import { useTheme } from 'styled-components';

import { ArrowRightIcon } from '../../../../iconography/ArrowRightIcon';
import { IconProps } from '../../../../iconography/iconProps';
import { Chip } from '../../../atoms/chip';
import { Text } from '../../../atoms/text';
import { ThemedIcon } from '../../theme-icon';
import { BaseCard } from '../base-card/BaseCard';

import {
  HeaderContainer,
  IconWrapper,
  IssuerInfoWrapper,
  IssuerLogoHolder,
  IssuerLogoImg,
  ProductInfoContainer,
  ProductTypeArrow,
  ProductTypeValuesLabel,
  TransactionCardHeader,
  TransactionInfoWrapper,
} from './portfolioTransactionCard.styles';

type Props = {
  headerLabel: string;
  headerValue: string;
  quantityLabel: string;
  quantity: string;
  amountLabel: string;
  amount: string;
  issuerInfo?: {
    name: string;
    logoUrl: string;
  };
  icon: FC<IconProps>;
  onClick?: () => void;
};

export const PortfolioTransactionCard: FC<Props> = ({
  headerLabel,
  headerValue,
  amountLabel,
  amount,
  quantityLabel,
  quantity,
  issuerInfo,
  onClick,
  icon,
}) => {
  const theme = useTheme();
  return (
    <BaseCard
      padding="0rem"
      borderBox={true}
      hasCursorPointer={true}
      onClick={onClick}
      removeBackgroundHover
      override={{
        borderRadius: theme.v2.componentsRadius.cards.default,
        backgroundColor: theme.v2.surface.primary,
        borderColor: theme.v2.border.primary,
        activeBackgroundColor: theme.v2.surface.primary,
        activeBorderColor: theme.v2.border.primary,
        hoverBorderColor: theme.v2.border.primary,
      }}
    >
      <HeaderContainer>
        <TransactionCardHeader>
          <Text fontSize={'sm'} fontWeight={'bold'} lineHeight={'medium'} color={theme.v2.text.headingPrimary}>
            {headerLabel}
          </Text>
          <Chip label={headerValue} status={'neutral'} />
        </TransactionCardHeader>
      </HeaderContainer>
      <ProductInfoContainer>
        {issuerInfo && (
          <IssuerInfoWrapper>
            <Text fontSize={'sm'} lineHeight={'medium'} color={theme.v2.text.headingPrimary}>
              {issuerInfo.name}
            </Text>
            {issuerInfo.logoUrl && (
              <IssuerLogoHolder>
                <IssuerLogoImg src={issuerInfo.logoUrl} />
              </IssuerLogoHolder>
            )}
          </IssuerInfoWrapper>
        )}
        <TransactionInfoWrapper>
          <IconWrapper>
            <ThemedIcon icon={icon} customStrokeColor={theme.v2.icon.default} />
          </IconWrapper>
          <ProductTypeValuesLabel>
            <Text lineHeight={'medium'} fontSize={'sm'} color={theme.v2.text.headingPrimary}>
              {quantityLabel}
            </Text>
            <Text lineHeight={'medium'} fontSize={'sm'} color={theme.v2.text.headingPrimary}>
              {amountLabel}
            </Text>
          </ProductTypeValuesLabel>
          <ProductTypeValuesLabel>
            <Text lineHeight={'medium'} fontSize={'sm'} fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
              {quantity.toString()}
            </Text>
            <Text lineHeight={'medium'} fontSize={'sm'} fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
              {amount}
            </Text>
          </ProductTypeValuesLabel>
          <ProductTypeArrow>
            <ThemedIcon icon={ArrowRightIcon} customStrokeColor={theme.v2.icon.default} />
          </ProductTypeArrow>
        </TransactionInfoWrapper>
      </ProductInfoContainer>
    </BaseCard>
  );
};
