import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.25rem;
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const BackArrowWrapper = styled.div`
  cursor: pointer;
`;

export const Footer = styled.div`
  padding: 1rem;
  border-top: 1px solid ${({ theme }) => theme.v2.border.primary};
`;

export const Section = styled.div`
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
  height: fit-content;
  width: 100%;
`;

export const Body = styled.div`
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  overflow-y: auto;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  & ${Section}:last-child {
    border-bottom: none;
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
`;
export const CheckboxGroup = styled.div`
  display: flex;
  gap: 1.25rem;
  flex-direction: column;
  align-items: flex-start;
`;
export const InputWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
