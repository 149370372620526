import { FC } from 'react';

import { IconProps } from './iconProps';

export const BarChartWithoutBorder: FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 136 136" fill={color} xmlns="http://www.w3.org/2000/svg">
      <rect width="100%" height="100%" rx="10" fill={color} />
      <path
        d="M24.4805 107.441V49.4139M53.4938 107.441V73.5917M82.5071 107.441V28.5605M111.52 107.441V49.4139"
        stroke={strokeColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
