import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
import { useTheme } from 'styled-components';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

type PieChartProps = {
  segments: { value: number; color: string }[];
};

export const PieChart = ({ segments }: PieChartProps) => {
  const theme = useTheme();
  const sortedSegments = segments.sort((a, b) => a.value - b.value);

  return (
    <Pie
      id={'pie-chart'}
      data={{
        datasets: [
          {
            data: sortedSegments.map((segment) => segment.value),
            backgroundColor: sortedSegments.map((segment) => segment.color),
            borderWidth: 0,
          },
        ],
      }}
      options={{
        layout: {
          autoPadding: true,
          padding: 46,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
          datalabels: {
            formatter: function (value, context) {
              const total = context.dataset.data.reduce((acc, val) => {
                return (acc as number) + (val as number);
              }, 0);
              return total ? Math.round((value / (total as number)) * 100) + '%' : '0%';
            },
            anchor: 'end',
            align: 'end',
            color: theme.v2.text.headingPrimary,
            padding: 8,
            font: {
              weight: 'bold',
              size: 16,
            },
          },
        },
      }}
    />
  );
};
