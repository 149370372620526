import { BaseCard, Chip, Text, ThemedIcon } from '@metaswiss/ui-kit';
import { CurrencyChfIcon } from '@metaswiss/ui-kit/src/iconography/CurrencyChfIcon';
import { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { CouponStatus } from '../../enums/couponStatus.enum';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';

import {
  CardWrapper,
  HeaderContainer,
  IconWrapper,
  InfoContainer,
  TextWrapper,
  TransactionCardHeader,
} from './couponCard.styles';

type Props = {
  serialNumber: string;
  status: string;
  price: string;
};

export const CouponCard: FC<Props> = ({ serialNumber, status, price }) => {
  const { textTranslation } = useTextTranslation();
  const theme = useTheme();
  const statusChip = useMemo(() => getCouponChip(status as CouponStatus), [status]);

  return (
    <BaseCard
      padding="0rem"
      disableStateEffects
      hasCursorPointer={false}
      override={{
        borderRadius: theme.v2.componentsRadius.cards.default,
        backgroundColor: theme.v2.surface.primary,
        borderColor: theme.v2.border.primary,
      }}
    >
      <HeaderContainer>
        <TransactionCardHeader>
          <Text fontSize={'sm'} fontWeight={'semi'} lineHeight={'medium'} color={theme.v2.text.headingPrimary}>
            {serialNumber}
          </Text>
          <Chip label={textTranslation(`portfolio.${status.toLowerCase()}`)} status={statusChip} />
        </TransactionCardHeader>
      </HeaderContainer>
      <InfoContainer>
        <CardWrapper>
          <IconWrapper>
            <ThemedIcon icon={CurrencyChfIcon} customStrokeColor={theme.v2.icon.primary} />
          </IconWrapper>
          <TextWrapper>
            <Text fontSize={'xsm'} lineHeight={'extraSmall'} color={theme.v2.text.disabled}>
              {textTranslation('portfolio.price')}
            </Text>
            <Text fontSize={'sm'} lineHeight={'medium'} fontWeight={'semi'} color={theme.v2.text.headingPrimary}>
              {price}
            </Text>
          </TextWrapper>
        </CardWrapper>
      </InfoContainer>
    </BaseCard>
  );
};

const getCouponChip = (status: CouponStatus) => {
  switch (status) {
    case CouponStatus.PAID: {
      return 'primary';
    }
    case CouponStatus.NOT_PAID: {
      return 'error';
    }
    default:
      throw new Error('Unsupported status');
  }
};
