import * as zod from 'zod';

import { FormNames } from '../../../../shared/forms/formNames';
import { staticTranslationFunction } from '../../../../shared/forms/staticTranslationFunction';

export const createSecondStepSchema = (translate: (key: string) => string) =>
  zod.object({
    country: zod.object({
      value: zod.string().nonempty(translate('error.requiredField')),
      label: zod.string().nonempty(translate('error.requiredField')),
      iconSrc: zod.string().optional(),
      meta: zod.string().optional(),
    }),
    city: zod.string().trim().nonempty(translate('error.requiredField')),
    address: zod.string().trim().nonempty(translate('error.requiredField')),
    zipCode: zod.string().trim().nonempty(translate('error.requiredField')),
  });

export const schema = createSecondStepSchema(staticTranslationFunction);

export type SecondStepFormData = zod.infer<typeof schema>;

export const secondStepSchema: FormNames<SecondStepFormData> = {
  country: 'country',
  city: 'city',
  address: 'address',
  zipCode: 'zipCode',
} as const;
