import styled from 'styled-components';

export const RegistrationHeaderContainer = styled.div`
  text-align: left;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const RegisterHeaderHolder = styled.div`
  height: 2rem;
  margin-top: 0.75rem;
`;

export const RegisterLogoContainer = styled.img`
  margin: 0 auto;
  width: 3.5rem;
  height: auto;
`;
