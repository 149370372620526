import { Button, FilterIcon, ThemedIcon } from '@metaswiss/ui-kit';
import { BaseHeader } from '@metaswiss/ui-kit/';
import React from 'react';
import { useTheme } from 'styled-components';

import { useHeaderOptions } from '../../global-state/zustand.ts';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation.ts';

import {
  ClientCenterBlockContainer,
  ClientInputContainer,
  ClientStartBlockContainer,
  EndBlockItem,
  EndBlockWrapper,
  FilterWrapper,
} from './clientHeader.styles';

interface StartBlockProps {
  title?: React.ReactNode;
  showTitle?: boolean;
  breadcrumbs: React.ReactNode;
  showBreadcrumbs?: boolean;
}

const StartBlock: React.FC<StartBlockProps> = ({ showTitle, showBreadcrumbs, title, breadcrumbs }) => {
  return (
    <ClientStartBlockContainer>
      {showTitle && title}
      {showBreadcrumbs && breadcrumbs}
    </ClientStartBlockContainer>
  );
};

interface CenterBlockProps {
  input: React.ReactNode;
  showInput?: boolean;
}

const CenterBlock: React.FC<CenterBlockProps> = ({ input, showInput }) => {
  return (
    showInput && (
      <ClientCenterBlockContainer>
        <ClientInputContainer>{input}</ClientInputContainer>
      </ClientCenterBlockContainer>
    )
  );
};

interface EndBlockProps {
  icons: React.ReactNode[];
  showIcons?: boolean[];
  isFilterVisible: boolean;
}

const EndBlock: React.FC<EndBlockProps> = ({ icons, showIcons, isFilterVisible }) => {
  const { setIsFilterDisplayed, isFilterMenuOpen } = useHeaderOptions((state) => state);
  const { textTranslation } = useTextTranslation();

  return (
    <EndBlockWrapper>
      {isFilterVisible && (
        <FilterWrapper>
          <Button
            renderStartIcon={() => <ThemedIcon size={'small'} icon={FilterIcon} palette="neutrals" color="hue700" />}
            text={textTranslation('global.filter')}
            size={'medium'}
            onClick={() => setIsFilterDisplayed(!isFilterMenuOpen)}
          />
        </FilterWrapper>
      )}
      {icons.map((item, index) => {
        return showIcons?.[index] && <EndBlockItem key={index}>{item}</EndBlockItem>;
      })}
    </EndBlockWrapper>
  );
};

export type Props = {
  logo: React.ReactNode;
  title: React.ReactNode;
  breadcrumbs: React.ReactNode;
  icons: React.ReactNode[];
  input?: React.ReactNode;
  headerOptions?: {
    showLogo?: boolean;
    showTitle?: boolean;
    showBreadcrumbs?: boolean;
    showInput?: boolean;
    showIcons?: boolean[];
  };
  isFilterVisible: boolean;
};

export const ClientHeader: React.FC<Props> = ({
  logo,
  breadcrumbs,
  icons,
  input,
  title,
  headerOptions,
  isFilterVisible,
}) => {
  const theme = useTheme();

  return (
    <BaseHeader
      headerOptions={headerOptions}
      logo={logo}
      startBlock={
        <StartBlock
          showTitle={headerOptions?.showTitle}
          showBreadcrumbs={headerOptions?.showBreadcrumbs}
          title={title}
          breadcrumbs={breadcrumbs}
        />
      }
      centerBlock={<CenterBlock showInput={headerOptions?.showInput} input={input} />}
      endBlock={<EndBlock isFilterVisible={isFilterVisible} icons={icons} showIcons={headerOptions?.showIcons} />}
      border={{
        width: '1px',
        style: 'solid',
        color: theme.v2.border.information,
      }}
    />
  );
};
