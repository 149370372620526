import styled from 'styled-components';

import { RemSize } from '../../..';

export const IconWrapper = styled.div<{
  $size: RemSize;
  $backgroundColor?: string;
}>`
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};
  align-items: center;
  background-color: ${({ $backgroundColor }) => $backgroundColor || 'transparent'};
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.small};
`;

export const TextWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardWrapper = styled.div<{ $gap: RemSize }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ $gap }) => $gap};
`;

export const TextContent = styled.div`
  display: flex;
  gap: 0.25rem;
`;
