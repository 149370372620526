export const ErrorRetrieveIcon = () => {
  return (
    <svg width="270" height="180" viewBox="0 0 270 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.1"
        d="M267.904 136.51H239.515C241.589 128.078 242.688 119.266 242.688 110.198C242.688 92.1743 238.344 75.1613 230.642 60.1407H250.774C251.932 60.1407 252.87 59.2058 252.87 58.0525C252.87 56.8992 251.932 55.9642 250.774 55.9642H228.384C209.383 22.5501 173.368 0 132.065 0C104.593 0 79.4615 9.97715 60.1185 26.4905H26.0537C24.8959 26.4905 23.9574 27.4255 23.9574 28.5788C23.9574 29.7321 24.8959 30.667 26.0537 30.667H55.4948C53.1439 32.9137 50.8926 35.263 48.7477 37.7073H2.09627C0.938531 37.7073 0 38.6422 0 39.7955C0 40.9488 0.938531 41.8837 2.09627 41.8837H45.26C30.3465 60.6627 21.4419 84.3944 21.4419 110.198C21.4419 124.639 24.2313 138.43 29.3011 151.068H23.7777C22.62 151.068 21.6814 152.003 21.6814 153.156C21.6814 154.309 22.62 155.244 23.7777 155.244H31.078C31.8406 156.938 32.6447 158.609 33.4893 160.256H8.68456C7.52682 160.256 6.58828 161.191 6.58828 162.344C6.58828 163.497 7.52682 164.432 8.68456 164.432H35.7456C38.3311 168.979 41.231 173.324 44.417 177.439H219.713C225.668 169.748 230.625 161.251 234.392 152.142H254.967C256.124 152.142 257.063 151.207 257.063 150.053C257.063 148.9 256.124 147.965 254.967 147.965H236.019C236.894 145.576 237.689 143.149 238.399 140.686H267.904C269.061 140.686 270 139.751 270 138.598C270 137.445 269.061 136.51 267.904 136.51Z"
        fill="#3381FF"
      />
      <path
        d="M178.562 177.433H165.16C165.16 176.469 165.945 175.688 166.912 175.688H176.81C177.777 175.688 178.562 176.469 178.562 177.433Z"
        fill="#CEDDF5"
      />
      <path d="M175.384 175.687H168.332L170.624 110.613L172.164 111.633L175.384 175.687Z" fill="#CEDDF5" />
      <path
        d="M177.562 177.433H164.16C164.16 176.469 164.945 175.688 165.912 175.688H175.81C176.777 175.688 177.562 176.469 177.562 177.433Z"
        fill="#CEDDF5"
      />
      <path
        d="M170.863 111.188L204.584 130.582L204.216 128.673C204.143 128.297 203.935 127.959 203.631 127.725L181.6 110.737C181.197 110.426 180.668 110.328 180.179 110.473L175.76 111.789C175.343 111.913 174.893 111.86 174.516 111.643L171.579 109.954L170.863 111.188Z"
        fill="#CEDDF5"
      />
      <path
        d="M170.147 109.955L136.426 129.349L138.267 129.985C138.632 130.111 139.032 130.1 139.39 129.954L165.167 119.445C165.641 119.252 165.993 118.842 166.112 118.345L167.176 113.883C167.277 113.459 167.549 113.095 167.928 112.877L170.862 111.189L170.147 109.955Z"
        fill="#CEDDF5"
      />
      <path
        d="M171.576 109.954V71.166L170.102 72.4374C169.81 72.6891 169.62 73.0383 169.568 73.4193L165.815 100.915C165.746 101.42 165.926 101.927 166.298 102.277L169.648 105.429C169.966 105.727 170.145 106.143 170.145 106.577V109.954L171.576 109.954Z"
        fill="#CEDDF5"
      />
      <path d="M174.101 175.687H167.629L169.921 110.613H171.809L174.101 175.687Z" fill="#CEDDF5" />
      <path
        d="M172.478 111.005C172.689 110.118 172.137 109.229 171.246 109.019C170.355 108.809 169.462 109.359 169.252 110.247C169.042 111.134 169.593 112.023 170.484 112.233C171.375 112.443 172.268 111.893 172.478 111.005Z"
        fill="#CEDDF5"
      />
      <path
        d="M170.75 112.022C171.585 112.022 172.261 111.348 172.261 110.517C172.261 109.685 171.585 109.011 170.75 109.011C169.915 109.011 169.238 109.685 169.238 110.517C169.238 111.348 169.915 112.022 170.75 112.022Z"
        fill="#CEDDF5"
      />
      <path d="M137.024 129.554L169.238 110.515L169.209 110.493L136.426 129.348L137.024 129.554Z" fill="#CEDDF5" />
      <path d="M174.098 175.265H167.641V175.687H174.098V175.265Z" fill="#CEDDF5" />
      <path d="M171.102 71.5792L171.545 109.154L171.579 109.14V71.166L171.102 71.5792Z" fill="#CEDDF5" />
      <path d="M204.464 129.963L171.806 111.691L171.801 111.728L204.584 130.582L204.464 129.963Z" fill="#CEDDF5" />
      <path
        d="M222.588 177.433H201.133C201.133 175.89 202.389 174.639 203.938 174.639H219.783C221.332 174.639 222.588 175.89 222.588 177.433Z"
        fill="#E6EEFB"
      />
      <path d="M217.509 174.639H206.219L209.888 70.457L212.354 72.0898L217.509 174.639Z" fill="#E6EEFB" />
      <path
        d="M220.994 177.434H199.539C199.539 175.891 200.795 174.64 202.344 174.64H218.19C219.739 174.64 220.994 175.891 220.994 177.434Z"
        fill="#CEDDF5"
      />
      <path
        d="M210.266 71.3792L264.252 102.429L263.595 99.0214C263.522 98.6445 263.314 98.3069 263.009 98.0719L227.07 70.359C226.666 70.0477 226.137 69.9495 225.647 70.0951L217.713 72.4569C217.295 72.5811 216.845 72.5286 216.468 72.3117L211.411 69.4033L210.266 71.3792Z"
        fill="#CEDDF5"
      />
      <path
        d="M209.119 69.4033L155.133 100.453L158.423 101.589C158.788 101.715 159.186 101.704 159.543 101.558L202.357 84.1037L204.617 74.6277L210.265 71.3795L209.119 69.4033Z"
        fill="#CEDDF5"
      />
      <path
        d="M211.409 69.4035V7.30469L208.776 9.57476C208.484 9.82654 208.295 10.1757 208.243 10.5568L202.12 55.4148C202.051 55.9198 202.231 56.427 202.603 56.777L208.621 62.4391C208.938 62.7377 209.118 63.1534 209.118 63.5883V69.4034L211.409 69.4035Z"
        fill="#CEDDF5"
      />
      <path d="M215.444 174.64H205.082L208.752 70.458H211.775L215.444 174.64Z" fill="#CEDDF5" />
      <path
        d="M212.72 71.4667C213.281 70.1178 212.638 68.5714 211.284 68.0127C209.93 67.4539 208.377 68.0945 207.816 69.4433C207.255 70.7922 207.898 72.3387 209.253 72.8974C210.607 73.4562 212.159 72.8156 212.72 71.4667Z"
        fill="#E6EEFB"
      />
      <path
        d="M212.327 71.2214C212.839 69.9914 212.252 68.5812 211.018 68.0717C209.783 67.5622 208.367 68.1463 207.856 69.3763C207.344 70.6064 207.931 72.0166 209.165 72.5261C210.4 73.0356 211.816 72.4515 212.327 71.2214Z"
        fill="#CEDDF5"
      />
      <path d="M156.09 100.783L207.664 70.3023L207.618 70.2666L155.133 100.452L156.09 100.783Z" fill="#E6EEFB" />
      <path d="M215.444 173.962H205.105V174.638H215.444V173.962Z" fill="#E6EEFB" />
      <path d="M210.645 7.96526L211.354 68.121L211.408 68.0987V7.30371L210.645 7.96526Z" fill="#E6EEFB" />
      <path d="M264.063 101.437L211.777 72.1846L211.77 72.2423L264.254 102.428L264.063 101.437Z" fill="#E6EEFB" />
      <path
        d="M50.7479 126.991C43.8396 126.991 38.1875 121.36 38.1875 114.479V85.6772C38.1875 78.7955 43.8396 73.165 50.7479 73.165C57.6561 73.165 63.3083 78.7955 63.3083 85.6772V114.479C63.3083 121.36 57.6561 126.991 50.7479 126.991Z"
        fill="#DBE9FF"
      />
      <path
        d="M51.433 177.495H50.0625V93.1777C50.0625 92.8007 50.3693 92.4951 50.7477 92.4951C51.1261 92.4951 51.4329 92.8007 51.4329 93.1777V177.495H51.433Z"
        fill="#C0CFE9"
      />
      <path
        d="M51.4329 104.605L47.6225 98.0308C47.4332 97.7044 47.0142 97.5926 46.6865 97.781C46.3587 97.9695 46.2465 98.3869 46.4356 98.7134L50.2461 105.288L51.4329 104.605Z"
        fill="#C0CFE9"
      />
      <path
        d="M51.2494 112.468L55.0598 105.894C55.249 105.568 55.1367 105.15 54.8089 104.962C54.4812 104.773 54.0621 104.885 53.8729 105.212L50.0625 111.786L51.2494 112.468Z"
        fill="#C0CFE9"
      />
      <path
        d="M82.821 113.287C74.0485 113.287 66.8711 106.137 66.8711 97.3979V60.8241C66.8711 52.0854 74.0485 44.9355 82.821 44.9355C91.5934 44.9355 98.7708 52.0854 98.7708 60.8241V97.3979C98.7708 106.137 91.5933 113.287 82.821 113.287Z"
        fill="#DBE9FF"
      />
      <path
        d="M83.6895 177.419H81.9492V70.3482C81.9492 69.8695 82.3388 69.4814 82.8193 69.4814C83.2999 69.4814 83.6895 69.8695 83.6895 70.3482V177.419Z"
        fill="#C0CFE9"
      />
      <path
        d="M83.693 84.8604L78.8543 76.5117C78.614 76.0971 78.0818 75.955 77.6657 76.1944C77.2496 76.4338 77.1069 76.9639 77.3472 77.3785L82.1859 85.7272L83.693 84.8604Z"
        fill="#C0CFE9"
      />
      <path
        d="M83.4564 94.8453L88.2951 86.4966C88.5353 86.0821 88.3928 85.5519 87.9765 85.3126C87.5604 85.0732 87.0282 85.2153 86.7879 85.6298L81.9492 93.9785L83.4564 94.8453Z"
        fill="#C0CFE9"
      />
      <path
        d="M58.4943 172.763C59.2263 172.077 59.5609 171.295 59.2723 170.744C58.915 170.062 57.7196 169.958 56.4438 170.438C56.5089 170.272 56.5695 170.102 56.6248 169.926C57.3048 167.763 56.9163 165.716 55.7573 165.355C54.6274 165.002 53.1824 166.381 52.4804 168.454C51.8988 166.079 50.4058 164.404 49.1257 164.704C47.9204 164.986 47.3298 166.919 47.7176 169.135C45.9517 168.358 44.2479 168.42 43.7051 169.357C43.1431 170.326 44.0186 171.901 45.6865 173.048C44.8958 173.312 44.3521 173.795 44.2777 174.401C44.1864 175.144 44.8266 175.878 45.8419 176.331C45.7856 176.475 45.7706 176.621 45.8037 176.761C45.9096 177.209 46.48 177.486 47.226 177.515V177.518H47.3371C47.3399 177.518 47.3429 177.518 47.3458 177.518H56.7674V177.51C56.9512 177.486 57.0882 177.409 57.1348 177.285C57.1659 177.202 57.1527 177.108 57.104 177.014C57.5999 176.966 57.9731 176.771 58.0956 176.445C58.1911 176.191 58.1195 175.897 57.9212 175.61C59.2094 175.524 60.1993 174.961 60.2307 174.238C60.2581 173.602 59.5364 173.026 58.4943 172.763Z"
        fill="#C0CFE9"
      />
      <path
        d="M78.3276 168.773C79.6738 167.513 80.289 166.074 79.7583 165.061C79.1013 163.808 76.9029 163.616 74.5564 164.498C74.6763 164.194 74.7877 163.88 74.8892 163.557C76.1398 159.579 75.4255 155.814 73.2937 155.15C71.2158 154.501 68.5585 157.037 67.2674 160.85C66.1979 156.481 63.4522 153.401 61.098 153.953C58.8815 154.472 57.7951 158.027 58.5083 162.102C55.2608 160.674 52.1274 160.787 51.129 162.51C50.0954 164.293 51.7054 167.189 54.7728 169.298C53.3186 169.784 52.3187 170.672 52.1819 171.786C52.014 173.154 53.1912 174.502 55.0586 175.335C54.9551 175.601 54.9274 175.868 54.9884 176.126C55.1832 176.951 56.2321 177.46 57.6041 177.514V177.519H57.8083C57.8136 177.519 57.8189 177.519 57.8242 177.519H75.1509V177.504C75.4888 177.459 75.7409 177.318 75.8266 177.089C75.8839 176.937 75.8595 176.765 75.77 176.592C76.6819 176.505 77.3682 176.145 77.5935 175.545C77.769 175.078 77.6372 174.538 77.2728 174.009C79.6419 173.851 81.4623 172.817 81.5201 171.486C81.5713 170.316 80.2441 169.257 78.3276 168.773Z"
        fill="#DBE9FF"
      />
      <path
        d="M196.294 169.674C195.095 168.552 194.547 167.271 195.02 166.369C195.605 165.252 197.562 165.081 199.652 165.867C199.545 165.596 199.446 165.316 199.355 165.029C198.242 161.487 198.878 158.135 200.776 157.543C202.626 156.965 204.992 159.224 206.142 162.619C207.094 158.728 209.539 155.986 211.636 156.477C213.609 156.939 214.577 160.105 213.942 163.733C216.834 162.462 219.624 162.563 220.513 164.097C221.433 165.685 219.999 168.263 217.268 170.142C218.563 170.574 219.453 171.364 219.575 172.356C219.724 173.574 218.676 174.775 217.013 175.517C217.105 175.754 217.13 175.992 217.076 176.221C216.902 176.956 215.968 177.409 214.747 177.457V177.461H214.565C214.56 177.461 214.555 177.461 214.551 177.461H199.122V177.448C198.821 177.408 198.597 177.282 198.52 177.079C198.469 176.943 198.491 176.79 198.571 176.636C197.759 176.558 197.148 176.238 196.947 175.704C196.791 175.288 196.908 174.807 197.233 174.336C195.123 174.195 193.502 173.275 193.45 172.09C193.405 171.048 194.587 170.105 196.294 169.674Z"
        fill="#C0CFE9"
      />
      <path
        d="M214.126 174.326C213.641 173.872 213.42 173.355 213.611 172.99C213.847 172.539 214.638 172.47 215.483 172.788C215.44 172.678 215.399 172.565 215.363 172.449C214.913 171.018 215.17 169.664 215.937 169.424C216.684 169.191 217.641 170.104 218.105 171.475C218.49 169.903 219.478 168.795 220.325 168.994C221.122 169.181 221.513 170.46 221.257 171.926C222.425 171.412 223.552 171.453 223.911 172.073C224.283 172.714 223.704 173.756 222.601 174.515C223.124 174.69 223.483 175.009 223.533 175.41C223.593 175.902 223.17 176.387 222.498 176.687C222.535 176.783 222.545 176.879 222.523 176.972C222.453 177.269 222.075 177.452 221.582 177.471V177.473H221.508C221.506 177.473 221.505 177.473 221.503 177.473H215.269V177.467C215.147 177.451 215.056 177.401 215.026 177.318C215.005 177.264 215.014 177.202 215.046 177.14C214.718 177.108 214.471 176.979 214.39 176.763C214.327 176.595 214.374 176.4 214.505 176.21C213.653 176.153 212.998 175.781 212.977 175.302C212.959 174.881 213.436 174.5 214.126 174.326Z"
        fill="#9EB3D7"
      />
      <path
        d="M138.154 170.124C137.024 169.066 136.508 167.859 136.953 167.008C137.505 165.956 139.35 165.795 141.319 166.536C141.218 166.28 141.125 166.016 141.04 165.746C139.99 162.407 140.59 159.247 142.379 158.689C144.123 158.145 146.353 160.274 147.437 163.474C148.335 159.807 150.639 157.222 152.615 157.684C154.476 158.12 155.388 161.105 154.789 164.525C157.515 163.326 160.145 163.421 160.983 164.867C161.85 166.364 160.499 168.794 157.924 170.565C159.145 170.973 159.984 171.718 160.099 172.653C160.24 173.801 159.252 174.933 157.684 175.632C157.771 175.855 157.794 176.079 157.743 176.296C157.58 176.988 156.699 177.416 155.548 177.461V177.464H155.376C155.372 177.464 155.367 177.464 155.363 177.464H140.82V177.452C140.536 177.415 140.325 177.296 140.253 177.104C140.205 176.977 140.225 176.833 140.3 176.687C139.535 176.613 138.959 176.312 138.77 175.808C138.622 175.416 138.733 174.963 139.039 174.519C137.05 174.386 135.522 173.518 135.474 172.402C135.431 171.419 136.545 170.53 138.154 170.124Z"
        fill="#DBE9FF"
      />
      <path
        d="M154.503 173.307C153.863 172.708 153.57 172.024 153.823 171.542C154.135 170.946 155.18 170.855 156.296 171.274C156.239 171.129 156.186 170.98 156.137 170.827C155.543 168.936 155.883 167.146 156.896 166.83C157.884 166.522 159.147 167.727 159.761 169.54C160.27 167.463 161.575 165.999 162.694 166.261C163.748 166.508 164.264 168.198 163.925 170.135C165.469 169.456 166.959 169.51 167.433 170.329C167.925 171.177 167.159 172.554 165.701 173.557C166.392 173.787 166.868 174.209 166.933 174.739C167.013 175.389 166.453 176.031 165.565 176.427C165.614 176.553 165.628 176.68 165.599 176.803C165.506 177.195 165.007 177.437 164.355 177.462V177.465H164.258C164.255 177.465 164.253 177.465 164.25 177.465H156.013V177.457C155.852 177.436 155.733 177.369 155.692 177.261C155.665 177.188 155.676 177.107 155.719 177.024C155.285 176.983 154.959 176.812 154.852 176.527C154.768 176.305 154.831 176.048 155.004 175.796C153.878 175.721 153.012 175.23 152.985 174.597C152.961 174.04 153.592 173.537 154.503 173.307Z"
        fill="#E4E4E4"
      />
      <path
        d="M162.782 74.4014C162.679 74.4014 162.58 74.4171 162.486 74.4462C162.49 74.3973 162.492 74.3479 162.492 74.2981C162.492 73.1546 161.561 72.2275 160.413 72.2275C159.461 72.2275 158.658 72.8656 158.412 73.7359C158.296 73.7033 158.173 73.6857 158.046 73.6857C157.302 73.6857 156.699 74.2863 156.699 75.0272C156.699 75.7681 157.302 76.3688 158.046 76.3688C158.07 76.3688 158.093 76.3682 158.116 76.367V76.3687H162.782C163.327 76.3687 163.769 75.9283 163.769 75.385C163.769 74.8419 163.327 74.4014 162.782 74.4014Z"
        fill="#DBE9FF"
      />
      <path
        d="M126.495 51.7898C125.933 51.7898 125.407 51.9443 124.958 52.2128C124.493 50.2095 122.692 48.7155 120.539 48.7155C120.35 48.7155 120.163 48.7284 119.98 48.7509C118.977 46.5245 116.733 44.9736 114.125 44.9736C110.582 44.9736 107.71 47.8346 107.71 51.3637C107.71 51.4014 107.711 51.439 107.711 51.4765C107.283 51.2743 106.804 51.1608 106.298 51.1608C104.47 51.1608 102.988 52.6367 102.988 54.4574C102.988 56.2781 104.47 57.7538 106.298 57.7538H126.495C128.148 57.7538 129.488 56.4186 129.488 54.7718C129.489 53.1248 128.148 51.7898 126.495 51.7898Z"
        fill="#DBE9FF"
      />
      <path
        d="M95.7114 122.802C96.081 122.802 96.4269 122.904 96.7224 123.08C97.0283 121.763 98.2131 120.78 99.629 120.78C99.7537 120.78 99.8762 120.788 99.9969 120.803C100.656 119.339 102.133 118.318 103.849 118.318C106.179 118.318 108.068 120.2 108.068 122.522C108.068 122.547 108.068 122.571 108.068 122.596C108.35 122.463 108.665 122.388 108.998 122.388C110.2 122.388 111.174 123.359 111.174 124.557C111.174 125.755 110.2 126.725 108.998 126.725H95.7114C94.6238 126.725 93.7422 125.847 93.7422 124.764C93.7422 123.68 94.6238 122.802 95.7114 122.802Z"
        fill="#DBE9FF"
      />
      <path
        d="M227.863 165.718L227.832 165.837C227.829 165.85 227.747 166.157 227.545 166.537C227.357 166.89 227.026 167.374 226.513 167.676C226 167.977 225.415 168.032 225.014 168.025C224.583 168.018 224.273 167.94 224.26 167.937L224.141 167.907L224.172 167.788C224.175 167.775 224.257 167.468 224.459 167.088C224.646 166.735 224.978 166.251 225.491 165.95C226.004 165.648 226.589 165.593 226.99 165.6C227.421 165.608 227.731 165.685 227.744 165.688L227.863 165.718ZM224.448 167.726C224.775 167.784 225.672 167.887 226.389 167.466C227.107 167.044 227.449 166.213 227.556 165.899C227.229 165.842 226.332 165.738 225.615 166.16C224.897 166.582 224.555 167.413 224.448 167.726Z"
        fill="#E4E4E4"
      />
      <path
        d="M226.152 168.575C225.012 169.003 223.742 168.436 223.742 168.436C223.742 168.436 224.32 167.175 225.46 166.747C226.6 166.319 227.87 166.886 227.87 166.886C227.87 166.886 227.292 168.147 226.152 168.575Z"
        fill="#DBE9FF"
      />
      <path
        d="M223.714 152.076L223.712 152.228C223.712 152.245 223.706 152.64 223.578 153.16C223.459 153.642 223.207 154.326 222.679 154.844C222.152 155.363 221.462 155.606 220.977 155.718C220.454 155.839 220.057 155.839 220.04 155.839L219.887 155.839L219.888 155.686C219.889 155.669 219.894 155.274 220.022 154.755C220.141 154.272 220.394 153.589 220.921 153.07C221.448 152.552 222.138 152.309 222.624 152.196C223.146 152.075 223.544 152.075 223.56 152.075L223.714 152.076ZM220.202 155.528C220.615 155.499 221.729 155.353 222.465 154.628C223.204 153.902 223.364 152.797 223.398 152.386C222.985 152.416 221.871 152.562 221.135 153.286C220.397 154.012 220.237 155.118 220.202 155.528Z"
        fill="#E4E4E4"
      />
      <path
        d="M222.512 156.037C221.267 156.897 219.562 156.596 219.562 156.596C219.562 156.596 219.877 154.9 221.122 154.04C222.367 153.18 224.072 153.481 224.072 153.481C224.072 153.481 223.757 155.177 222.512 156.037Z"
        fill="#DBE9FF"
      />
      <path
        d="M65.6106 148.073L65.7078 148.156C65.7183 148.165 65.969 148.38 66.2331 148.739C66.4787 149.073 66.782 149.6 66.8322 150.213C66.8824 150.826 66.669 151.395 66.4811 151.764C66.2789 152.161 66.0666 152.414 66.0576 152.425L65.9752 152.522L65.8781 152.439C65.8676 152.43 65.617 152.215 65.3527 151.856C65.1071 151.522 64.8038 150.995 64.7535 150.382C64.7033 149.769 64.9168 149.2 65.1047 148.831C65.3069 148.434 65.5192 148.181 65.5282 148.171L65.6106 148.073ZM65.9451 152.155C66.1471 151.877 66.6494 151.09 66.5792 150.234C66.5089 149.375 65.8855 148.682 65.6408 148.44C65.4388 148.719 64.9364 149.505 65.0066 150.362C65.077 151.22 65.7003 151.913 65.9451 152.155Z"
        fill="#E4E4E4"
      />
      <path
        d="M67.5108 150.952C67.396 152.204 66.2908 153.128 66.2908 153.128C66.2908 153.128 65.3716 152.02 65.4865 150.768C65.6013 149.515 66.7065 148.592 66.7065 148.592C66.7065 148.592 67.6255 149.699 67.5108 150.952Z"
        fill="#DBE9FF"
      />
      <path
        d="M41.3711 162.542L41.4783 162.535C41.4899 162.534 41.7675 162.517 42.1382 162.579C42.4827 162.636 42.9758 162.775 43.3672 163.115C43.7587 163.454 43.9656 163.921 44.0701 164.253C44.1826 164.611 44.2038 164.887 44.2047 164.898L44.2124 165.005L44.1052 165.012C44.0936 165.013 43.8161 165.03 43.4453 164.969C43.1007 164.911 42.6078 164.772 42.2163 164.432C41.8248 164.093 41.618 163.626 41.5134 163.294C41.4009 162.937 41.3797 162.66 41.3789 162.649L41.3711 162.542ZM43.9781 164.802C43.9356 164.517 43.7741 163.749 43.2272 163.275C42.679 162.8 41.8952 162.747 41.6055 162.745C41.648 163.031 41.8095 163.798 42.3565 164.272C42.9046 164.747 43.6885 164.8 43.9781 164.802Z"
        fill="#E4E4E4"
      />
      <path
        d="M44.2115 163.165C44.8808 163.986 44.76 165.188 44.76 165.188C44.76 165.188 43.5542 165.059 42.8851 164.239C42.216 163.418 42.3366 162.216 42.3366 162.216C42.3366 162.216 43.5423 162.345 44.2115 163.165Z"
        fill="#DBE9FF"
      />
      <path
        d="M191.566 159.286L191.687 159.283C191.7 159.283 192.012 159.276 192.425 159.362C192.809 159.442 193.355 159.621 193.778 160.02C194.202 160.418 194.412 160.951 194.514 161.328C194.624 161.734 194.635 162.045 194.635 162.058L194.639 162.178L194.519 162.181C194.506 162.181 194.194 162.188 193.781 162.102C193.397 162.022 192.851 161.843 192.427 161.444C192.004 161.046 191.793 160.513 191.691 160.136C191.582 159.73 191.57 159.419 191.57 159.406L191.566 159.286ZM194.386 161.939C194.351 161.617 194.205 160.749 193.614 160.193C193.021 159.635 192.145 159.54 191.82 159.525C191.855 159.847 192.001 160.714 192.592 161.271C193.185 161.829 194.061 161.923 194.386 161.939Z"
        fill="#E4E4E4"
      />
      <path
        d="M194.723 160.113C195.435 161.064 195.245 162.406 195.245 162.406C195.245 162.406 193.899 162.207 193.186 161.256C192.473 160.305 192.664 158.963 192.664 158.963C192.664 158.963 194.01 159.162 194.723 160.113Z"
        fill="#DBE9FF"
      />
      <path
        d="M158.176 170.114H102.484L103.498 166.46L105.476 159.324L106.675 155.003L108.442 148.633L111.848 136.353L113.614 129.983L126.034 85.1992H134.626L139.178 101.612L147.046 129.983L148.813 136.353L152.219 148.633L153.986 155.003L157.163 166.46L158.176 170.114Z"
        fill="#3381FF"
      />
      <path
        d="M158.176 170.114H102.484L103.498 166.46L105.476 159.324C144.285 157.04 139.178 101.612 139.178 101.612L157.163 166.46L158.176 170.114Z"
        fill="#4F92FF"
      />
      <path d="M169.571 170.114H91.0898V177.547H169.571V170.114Z" fill="#3381FF" />
      <path d="M158.176 170.115H102.484L103.498 166.46H157.163L158.176 170.115Z" fill="#3160AC" />
      <path
        d="M148.812 136.353C148.812 136.353 147.646 160.871 119.367 166.479L157.162 166.459L148.812 136.353Z"
        fill="#346CC6"
      />
      <path d="M148.813 136.354H111.848L113.614 129.983H147.046L148.813 136.354Z" fill="white" />
      <path d="M153.983 155.003H106.672L108.439 148.633H152.216L153.983 155.003Z" fill="white" />
      <path
        d="M151.418 170.114C151.418 170.114 141.015 177.938 102.484 177.547H169.571V170.114L151.418 170.114Z"
        fill="#294D89"
      />
      <path
        d="M181.101 180H78.4645C77.7679 180 77.2031 179.437 77.2031 178.743V178.707C77.2031 178.013 77.7678 177.45 78.4645 177.45H181.101C181.798 177.45 182.363 178.013 182.363 178.707V178.743C182.363 179.437 181.798 180 181.101 180Z"
        fill="#2E4070"
      />
      <path
        d="M80.4132 180H63.7926C63.096 180 62.5312 179.437 62.5312 178.743V178.707C62.5312 178.013 63.0959 177.45 63.7926 177.45H80.4132C81.1099 177.45 81.6745 178.013 81.6745 178.707V178.743C81.6745 179.437 81.1099 180 80.4132 180Z"
        fill="#2E4070"
      />
      <path
        d="M56.1687 180H38.7067C38.01 180 37.4453 179.437 37.4453 178.743V178.707C37.4453 178.013 38.01 177.45 38.7067 177.45H56.1686C56.8653 177.45 57.43 178.013 57.43 178.707V178.743C57.4301 179.437 56.8653 180 56.1687 180Z"
        fill="#2E4070"
      />
      <path
        d="M203.26 180H188.925C188.229 180 187.664 179.437 187.664 178.743V178.707C187.664 178.013 188.229 177.45 188.925 177.45H203.26C203.956 177.45 204.521 178.013 204.521 178.707V178.743C204.521 179.437 203.956 180 203.26 180Z"
        fill="#2E4070"
      />
      <path
        d="M222 180H211.097C210.401 180 209.836 179.437 209.836 178.743V178.707C209.836 178.013 210.401 177.45 211.097 177.45H222C222.697 177.45 223.261 178.013 223.261 178.707V178.743C223.261 179.437 222.697 180 222 180Z"
        fill="#2E4070"
      />
    </svg>
  );
};
