import styled from 'styled-components';

import { CardContainer } from '../../molecules/cards/base-card/baseCard.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  & + ${`.${CardContainer.styledComponentId}`} {
    border-top-left-radius: 0;
  }
`;
export const Tab = styled.div<{ $isMultipleTabs: boolean; $isActive: boolean }>`
  width: 20rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0.75rem 0.75rem 0 0;
  background-color: ${({ theme, $isActive }) => ($isActive ? theme.v2.surface.primary : theme.v2.surface.tertiary)};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  border-bottom: none;
  flex-direction: row;
  gap: 0.5rem;
  position: relative;
  top: 1px;
  z-index: 2;

  ${({ $isMultipleTabs }) => {
    if ($isMultipleTabs) {
      return `
        &:first-of-type {
           border-radius: 0.75rem 0 0 0;
           border-right: 0;
        }

        &:last-of-type {
          border-radius: 0 0.75rem 0 0;
          border-left: 0;
        }
      `;
    }
  }}
`;

export const Status = styled.div<{ $status?: string }>`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: ${({ $status, theme }) => {
    switch ($status) {
      case 'pending':
        return theme.v2.icon.warning;
      case 'live':
        return theme.v2.icon.success;
      default:
        return 'transparent';
    }
  }};
`;
