import * as zod from 'zod';

import { FormNames } from '../../../../shared/forms/formNames.ts';
import { staticTranslationFunction } from '../../../../shared/forms/staticTranslationFunction.ts';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const createWalletSchema = (_: (key: string) => string) =>
  zod.object({
    btcWallet: zod.string().nonempty(),
    ethWallet: zod.string().nonempty(),
  });

export const schema = createWalletSchema(staticTranslationFunction);

export type EditWalletFormData = zod.infer<typeof schema>;

export const editWalletSchemaNames: FormNames<EditWalletFormData> = {
  btcWallet: 'btcWallet',
  ethWallet: 'ethWallet',
};
