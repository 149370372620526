import { Text, ThemedIcon } from '@metaswiss/ui-kit';
import { IconProps } from '@metaswiss/ui-kit/src/iconography/iconProps';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { CardTipContainer, CardTipItem, CardTipList, CardTipWrapper, StyledCardTip } from './cardTip.styles';

export type CardTipProps = {
  title: string;
  descriptionList: string[];
  background: string;
  borderColor: string;
  allowOverflow?: boolean;
  icon: FC<IconProps>;
  iconColor: string;
};

export const CardTip: FC<CardTipProps> = ({
  background,
  borderColor,
  allowOverflow,
  title,
  descriptionList,
  icon,
  iconColor,
}) => {
  const theme = useTheme();
  return (
    <StyledCardTip
      $backgroundColor={background}
      $borderColor={borderColor}
      disableStateEffects={true}
      allowOverflow={allowOverflow}
      height={'100%'}
    >
      <CardTipWrapper>
        <CardTipContainer>
          <Text fontWeight="bold" textColor="hue700" color={theme.v2.text.headingPrimary}>
            {title}
          </Text>
          <ThemedIcon icon={icon} size={'medium'} customStrokeColor={iconColor} />
        </CardTipContainer>
        <CardTipList>
          {descriptionList?.map((item, index: number) => <CardTipItem key={index}>{item}</CardTipItem>)}
        </CardTipList>
      </CardTipWrapper>
    </StyledCardTip>
  );
};
