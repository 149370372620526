import styled from 'styled-components';

export const NoDataContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
`;

export const NoDataContentWrapper = styled.div<{ $hadAdditionalContent: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 44.5rem;
  text-align: center;

  > :nth-child(1) {
    margin-bottom: 2rem;
  }

  > :nth-child(2) {
    margin-bottom: 1.5rem;
  }

  > :nth-child(3) {
    margin-bottom: ${({ $hadAdditionalContent }) => ($hadAdditionalContent ? '0' : '3rem')};
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 19.875rem;
`;

export const IconWrapper = styled.div<{ $width?: string; $height?: string }>`
  width: 100%;
  height: 100%;
  max-width: ${({ $width }) => $width ?? '100%'};
  max-height: ${({ $height }) => $height ?? '100%'};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
