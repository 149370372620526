import { FC, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useSetRouteNavigation } from '../../../hooks/use-set-route-navigation/useSetRouteNavigation';
import { useTextTranslation } from '../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../router/routes';

import { casesTabs } from './shared/values/casesTabs.values';

export const Cases: FC = () => {
  const { textTranslation } = useTextTranslation();
  const { pathname } = useLocation();

  const casesTabsWithTranslation = useMemo(
    () =>
      casesTabs.map((tab) => ({
        ...tab,
        label: textTranslation(`cases.${tab.value}`),
      })),
    []
  );

  const isCasesDeepNestedRoute = useMemo(() => pathname.split('/').length > 3, [pathname]);

  useSetRouteNavigation(casesTabsWithTranslation, !isCasesDeepNestedRoute ? routes.cases.active.root : undefined);

  return <Outlet />;
};
