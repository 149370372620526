import { BaseFeedback } from './BaseFeedback';

export type Props = {
  title: string;
  subTitle: string;
  titleRender?: (title: string) => JSX.Element;
  subTitleRender?: (subTitle: string) => JSX.Element;
  fill?: boolean;
  icon: JSX.Element;
};

export const MaintenanceFeedback: React.FC<Props> = ({ title, titleRender, subTitleRender, subTitle, fill, icon }) => {
  return (
    <BaseFeedback
      imageWidth="auto"
      height="100%"
      title={title}
      titleRender={titleRender}
      subTitle={subTitle}
      textGap="1.5rem"
      contentGap="2.5rem"
      subTitleRender={subTitleRender}
      icon={icon}
      fill={fill}
    />
  );
};
