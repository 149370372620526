import { CaseConversationResponse } from '@metaswiss/api';
import { checkIsCurrentDay, groupDataByDate, GroupDataByDay } from '@metaswiss/lib';
import { FlatListV2, Flex } from '@metaswiss/ui-kit';
import { FC, useEffect, useMemo, useRef } from 'react';

import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';
import { CaseChatContainer } from '../../caseDetails.styles';

import { ChatHistorySectionHeader } from './components/chat-history-section-header/ChatHistorySectionHeader';
import { SingleChatMessage } from './components/single-chat-message/SingleChatMessage';

type CaseChatHistoryProps = {
  data: CaseConversationResponse[];
  lastItemRef: (node?: Element | null | undefined) => void;
};

export const CaseChatHistory: FC<CaseChatHistoryProps> = ({ data, lastItemRef }) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const itemRef = useRef<HTMLDivElement | null>(null);
  const { textTranslation } = useTextTranslation();

  useEffect(() => {
    const scrollToBottom = () => {
      if (wrapperRef.current && itemRef.current) {
        wrapperRef.current.scrollTop = itemRef.current.scrollHeight;
      }
    };

    const observer = new MutationObserver(scrollToBottom);
    const chatContainer = wrapperRef.current;
    if (chatContainer) {
      observer.observe(chatContainer, { childList: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [data]);

  const caseConversationData = useMemo(() => groupDataByDate(data), [data]);

  return (
    <CaseChatContainer ref={wrapperRef}>
      <FlatListV2<GroupDataByDay<CaseConversationResponse>>
        numColumns={1}
        ref={itemRef}
        data={caseConversationData}
        keyExtractor={(item) => Object.keys(item)[0]}
        contentGap={'2rem'}
        reverseItems
        renderItem={(item, index) => {
          const day = Object.keys(item)[0];
          const messages = item[day];
          const lastItem = caseConversationData.length - 1 === index;

          return (
            <Flex flexDirection="column" gap="1rem">
              <ChatHistorySectionHeader
                date={checkIsCurrentDay(day) ? textTranslation('global.today') : day}
                isActive={checkIsCurrentDay(day)}
              />

              <FlatListV2
                numColumns={1}
                data={messages}
                keyExtractor={(message) => message.id}
                contentGap={'2rem'}
                ref={lastItem ? lastItemRef : null}
                fetchingTriggerIndex={1}
                reverseItems
                renderItem={({ creator, message, files }) => (
                  <SingleChatMessage
                    imageUrl={creator?.thumbnailUrl}
                    name={creator?.name}
                    description={message}
                    files={files}
                  />
                )}
              />
            </Flex>
          );
        }}
      />
    </CaseChatContainer>
  );
};
