import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import { styled } from 'styled-components';

export const CollectionContainer = styled.div`
  width: 100%;
  height: fit-content;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  background: ${({ theme }) => theme.v2.surface.primary};
`;

export const BannerImageWrapper = styled.div`
  width: 100%;
  height: 17.5rem;
`;

export const BannerContainer = styled.img`
  border-radius: 1rem 1rem 0 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const BannerWrapper = styled.div`
  width: 100%;
  height: 22.5rem;
  position: relative;
`;

export const LogoWrapper = styled.div`
  position: absolute;
  left: 1.25rem;
  top: 12.5rem;
  height: 10rem;
  width: 10rem;
`;

export const LogoContainer = styled.img`
  height: auto;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
  border: 5px solid ${({ theme }) => theme.v2.surface.primary};
`;

export const DetailsWrapper = styled.div`
  padding: 1.25rem;
`;

export const DescriptionWrapper = styled.div<{ $isReadMoreOn: boolean }>`
  height: fit-content;
  width: 100%;
  text-align: justify;
  position: relative;
  max-height: ${({ $isReadMoreOn }) => ($isReadMoreOn ? 'fit-content' : '7.5rem')};
  overflow-y: hidden;

  @media ${breakpoints.laptopSm} {
    max-height: ${({ $isReadMoreOn }) => ($isReadMoreOn ? 'fit-content' : '4.5rem')};
  }
`;

export const UpperDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLink = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ContentContainer = styled.div`
  width: 100%;
  gap: 1.25rem;
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  height: 5.75rem;
`;
