import { IconStylesProps, PropertyProps } from './types';

export function getSize(size: 'xs' | 'sm' | 'md' | 'lg'): {
  subTitleProps: PropertyProps;
  titleProps: PropertyProps;
  iconStyles: IconStylesProps;
} {
  switch (size) {
    case 'xs':
      return {
        subTitleProps: {
          fontSize: 'xsm',
          fontWeight: 'reg',
          lineHeight: 'extraSmall',
        },
        titleProps: {
          fontSize: 'sm',
          fontWeight: 'semi',
          lineHeight: 'medium',
        },
        iconStyles: {
          size: 'medium',
        },
      };
    case 'sm':
      return {
        subTitleProps: {
          fontSize: 'xsm',
          fontWeight: 'reg',
          lineHeight: 'extraSmall',
        },
        titleProps: {
          fontSize: 'base',
          fontWeight: 'bold',
          lineHeight: 'base',
        },
        iconStyles: {
          size: 'large',
        },
      };
    case 'md':
      return {
        subTitleProps: {
          fontSize: 'sm',
          fontWeight: 'reg',
          lineHeight: 'medium',
        },
        titleProps: {
          fontSize: 'base',
          fontWeight: 'bold',
          lineHeight: 'base',
        },
        iconStyles: {
          size: 'large',
        },
      };
    case 'lg':
      return {
        subTitleProps: {
          fontSize: 'base',
          fontWeight: 'reg',
          lineHeight: 'base',
        },
        titleProps: {
          fontSize: 'h4',
          fontWeight: 'bold',
          lineHeight: 'h4',
        },
        iconStyles: {
          size: 'large',
        },
      };
    default:
      return {
        subTitleProps: {
          fontSize: 'sm',
          fontWeight: 'reg',
          lineHeight: 'medium',
        },
        titleProps: {
          fontSize: 'base',
          fontWeight: 'bold',
          lineHeight: 'base',
        },
        iconStyles: {
          size: 'full',
        },
      };
  }
}
