export const BrokenSheet = () => {
  return (
    <svg width="180" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14444_165969)">
        <path
          opacity="0.1"
          d="M78.3551 157.731C121.632 157.731 156.714 122.422 156.714 78.8655C156.714 35.3093 121.632 0 78.3551 0C35.0786 0 -0.00390625 35.3093 -0.00390625 78.8655C-0.00390625 122.422 35.0786 157.731 78.3551 157.731Z"
          fill="#767B83"
        />
        <path
          d="M158.579 175.976L60.0114 163.384C57.4003 163.055 55.5508 160.646 55.8772 158.018L71.9243 30.8782C72.2507 28.2502 74.6442 26.3888 77.2552 26.7173L175.823 39.3095C178.434 39.638 180.283 42.0469 179.957 44.6748L163.91 171.829C163.583 174.457 161.19 176.318 158.579 175.976Z"
          fill="#3381FF"
        />
        <path
          opacity="0.2"
          d="M171.973 63.8125L168.178 59.3094L154.498 43.0627L114.407 41.1055L123.886 65.8382L117.113 73.6399L111.265 80.374L102.344 90.653L108.899 97.6198L115.808 104.97L126.728 116.59L123.396 122.708L119.67 129.552L94.0352 176.581L155.721 179.592C156.469 179.633 157.163 179.305 157.653 178.785C157.993 178.415 158.237 177.936 158.292 177.416L171.973 63.8125Z"
          fill="#002766"
        />
        <path
          d="M143.05 41.5237L102.824 39.5391L114.07 64.6277L107.855 72.5388L140.874 74.1676C142.819 74.2634 144.315 75.9196 144.22 77.8768C144.125 79.8341 142.479 81.3397 140.534 81.2439L102.497 79.3687L94.2968 89.7847L101.341 96.8473L139.678 98.7361C141.622 98.8319 143.118 100.488 143.023 102.445C142.928 104.403 141.282 105.908 139.338 105.812L108.78 104.307L120.516 116.091L117.62 122.292L138.481 123.318C140.426 123.414 141.921 125.07 141.826 127.027C141.731 128.985 140.086 130.49 138.141 130.395L114.383 129.217L92.0938 176.917L153.984 179.969C154.732 180.011 155.412 179.682 155.861 179.148C156.174 178.779 156.378 178.286 156.405 177.766L162.008 62.5335L143.05 41.5237Z"
          fill="#EBF3FF"
        />
        <path
          d="M144.789 61.712L162.006 62.5606L143.062 41.5234L142.219 58.8514C142.137 60.357 143.293 61.6436 144.789 61.712Z"
          fill="#CCDFFF"
        />
        <path
          d="M140.535 81.2441C142.48 81.3399 144.125 79.8343 144.221 77.8771C144.316 75.9198 142.82 74.2636 140.875 74.1678L107.856 72.5391L102.484 79.369L140.535 81.2441Z"
          fill="#CCDFFF"
        />
        <path
          d="M139.336 105.828C141.281 105.924 142.926 104.419 143.022 102.461C143.117 100.504 141.621 98.8479 139.676 98.7521L101.34 96.8633L108.779 104.323L139.336 105.828Z"
          fill="#CCDFFF"
        />
        <path
          d="M138.141 130.407C140.085 130.503 141.731 128.998 141.826 127.04C141.921 125.083 140.425 123.427 138.481 123.331L117.619 122.305L114.383 129.244L138.141 130.407Z"
          fill="#CCDFFF"
        />
        <path
          opacity="0.2"
          d="M102.429 104.926L94.6505 97.9593L87.2797 91.3621L94.9769 80.7956L100.022 73.8699L105.856 65.8629L93.4538 41.8556L47.0938 41.7188C45.8155 41.7188 44.782 42.7316 44.782 43.9771L44.5508 174.265C44.5508 175.525 45.5843 176.537 46.8491 176.537L89.17 176.661L109.202 129.029L112.112 122.104L114.723 115.903L102.429 104.926Z"
          fill="#002766"
        />
        <path
          d="M62.1576 129.042C60.2129 129.096 58.5946 127.55 58.5538 125.592C58.4994 123.635 60.0361 122.006 61.9808 121.965L108.898 120.802L111.333 114.396L98.7668 103.515L61.5456 104.432C59.6009 104.487 57.9826 102.94 57.9418 100.983C57.8874 99.0257 59.4241 97.3969 61.3688 97.3558L90.7841 96.6304L83.2365 90.1016L90.6481 79.0971L60.9337 79.8362C58.989 79.891 57.3706 78.3443 57.3298 76.3871C57.2754 74.4298 58.8122 72.801 60.7569 72.76L95.503 71.8977L101.12 63.5485L88.0778 39.3633L41.7451 40.4993C40.4667 40.5267 39.4604 41.5943 39.5012 42.8809L42.765 175.961C42.7922 177.248 43.853 178.261 45.1313 178.22L87.4386 177.166L106.178 127.947L62.1576 129.042Z"
          fill="#EBF3FF"
        />
        <path
          d="M57.3452 76.3625C57.3995 78.3197 59.0043 79.8664 60.949 79.8116L90.6634 79.0725L95.5183 71.8594L60.7722 72.7217C58.8275 72.7901 57.2908 74.4052 57.3452 76.3625Z"
          fill="#CCDFFF"
        />
        <path
          d="M57.9422 100.968C57.9966 102.925 59.6013 104.472 61.546 104.417L98.7536 103.486L90.798 96.6016L61.3828 97.327C59.4381 97.3954 57.9014 99.0105 57.9422 100.968Z"
          fill="#CCDFFF"
        />
        <path
          d="M58.5522 125.578C58.6066 127.535 60.2113 129.082 62.156 129.027L106.163 127.932L108.883 120.773L61.9656 121.937C60.0345 121.992 58.4978 123.62 58.5522 125.578Z"
          fill="#CCDFFF"
        />
        <path
          opacity="0.2"
          d="M40.3319 154.376C57.9294 154.376 72.195 140.019 72.195 122.307C72.195 104.596 57.9294 90.2383 40.3319 90.2383C22.7343 90.2383 8.46875 104.596 8.46875 122.307C8.46875 140.019 22.7343 154.376 40.3319 154.376Z"
          fill="#002766"
        />
        <path
          d="M58.4193 141.336C70.8625 128.813 70.8625 108.508 58.4193 95.9841C45.9761 83.4605 25.8017 83.4605 13.3585 95.9841C0.915328 108.508 0.915328 128.813 13.3585 141.336C25.8017 153.86 45.9761 153.86 58.4193 141.336Z"
          fill="#F04438"
        />
        <path
          opacity="0.1"
          d="M12.8236 158.035C15.2347 155.608 15.2347 151.673 12.8236 149.247C10.4125 146.82 6.50335 146.82 4.09225 149.247C1.68115 151.673 1.68115 155.608 4.09225 158.035C6.50335 160.461 10.4125 160.461 12.8236 158.035Z"
          fill="#767B83"
        />
        <path
          opacity="0.1"
          d="M23.5927 170.349C24.5981 168.43 23.8673 166.054 21.9605 165.042C20.0537 164.031 17.6929 164.766 16.6875 166.685C15.6822 168.604 16.413 170.98 18.3198 171.992C20.2266 173.004 22.5874 172.269 23.5927 170.349Z"
          fill="#767B83"
        />
        <path
          d="M27.5784 137.789C27.0616 137.789 26.5448 137.666 26.0552 137.406C24.4641 136.557 23.8522 134.572 24.6953 132.971L41.3 101.285C42.1432 99.6838 44.1151 99.0679 45.7062 99.9165C47.2973 100.765 47.9093 102.75 47.0661 104.351L30.4614 136.037C29.8766 137.159 28.7479 137.789 27.5784 137.789Z"
          fill="white"
        />
        <path
          d="M51.6368 130.303C51.12 130.303 50.6032 130.18 50.1137 129.92L18.6177 113.208C17.0266 112.36 16.4147 110.375 17.2578 108.774C18.101 107.172 20.0729 106.556 21.664 107.405L53.1599 124.117C54.751 124.965 55.363 126.95 54.5198 128.551C53.9351 129.66 52.8063 130.303 51.6368 130.303Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14444_165969">
          <rect width="180" height="180" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
