import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';

import { useMediaQuery } from '../../../../../../hooks/use-media-query/useMediaQuery';

import { BannerContainer, BannerImage } from './homeBanner.styles';

type HomeBannerProps = {
  desktopImg: string;
  tabletImg: string;
};

export const HomeBanner = ({ desktopImg, tabletImg }: HomeBannerProps) => {
  const isDesktopSize = useMediaQuery(breakpoints.laptopMd);

  return (
    <BannerContainer>
      <BannerImage src={isDesktopSize ? desktopImg : tabletImg} />
    </BannerContainer>
  );
};
