/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateNftCollectionRequest } from '../models/CreateNftCollectionRequest';
import type { NftCollectionResponse } from '../models/NftCollectionResponse';
import type { NftOfferingesponse } from '../models/NftOfferingesponse';
import type { UpdateNftProductRequest } from '../models/UpdateNftProductRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NftCollectionCoreApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns any Created NFT Collection
   * @throws ApiError
   */
  public createNftCollection(
    requestBody: CreateNftCollectionRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/core/nft-collection',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns any Delete NFT Collection
   * @throws ApiError
   */
  public deleteNftCollection(
    id: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v5/core/nft-collection/{id}',
      path: {
        'id': id,
      },
      errors: {
        400: `Bad Request`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param name
   * @returns any Check if NFT Collection is already in use
   * @throws ApiError
   */
  public checkCollectionName(
    name: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/core/nft-collection/check-name',
      query: {
        'name': name,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns NftCollectionResponse Update NFT Product of Collection
   * @throws ApiError
   */
  public updateNftCollectionProduct(
    requestBody: UpdateNftProductRequest,
  ): CancelablePromise<NftCollectionResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v5/core/nft-collection/update-product',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns NftOfferingesponse NFT Tenant offering
   * @throws ApiError
   */
  public getTenantOffering(): CancelablePromise<NftOfferingesponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/core/nft-collection/offering',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
