import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import styled from 'styled-components';

export const DocumentsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding-top: 1rem;

  @media ${breakpoints.laptopMd} {
    border-top: ${({ theme }) => `1px solid ${theme.v2.border.primary}`};
  }
`;

export const DocumentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
`;
