import { FC } from 'react';
import { useTheme } from 'styled-components';

import { Heading } from '../heading';
import { Text } from '../text';

import { PriceContainer } from './priceHolder.styles';

type PriceHolder = {
  text: string;
  price: string;
};

export const PriceHolder: FC<PriceHolder> = ({ text, price }) => {
  const theme = useTheme();
  return (
    <PriceContainer>
      <Text color={theme.v2.text.disabled}>{text}</Text>
      <Heading headingType={'h4'} fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
        {price}
      </Heading>
    </PriceContainer>
  );
};
