import { swissCode } from '../constants/currencyCode';

const format = (
  amount: number,
  currencyDecimals = 2,
  currencyRate = 1,
  currencyName = '',
  hideCurrencyName = false,
  hideDecimals = false
): string => {
  const convertedAmount = amount * currencyRate;
  let formattedAmount;
  switch (currencyName) {
    case 'USD':
      formattedAmount = convertedAmount.toLocaleString('en-US', {
        minimumFractionDigits: currencyDecimals,
        maximumFractionDigits: currencyDecimals,
      });
      break;
    case 'EUR':
      formattedAmount = convertedAmount.toLocaleString('de-DE', {
        minimumFractionDigits: currencyDecimals,
        maximumFractionDigits: currencyDecimals,
      });
      break;
    case 'CHF':
      formattedAmount = convertedAmount.toLocaleString('de-CH', {
        minimumFractionDigits: currencyDecimals,
        maximumFractionDigits: currencyDecimals,
      });
      break;
    default:
      formattedAmount = convertedAmount.toFixed(currencyDecimals);
  }
  if (hideDecimals) {
    formattedAmount = formattedAmount.split('.')[0];
  }
  if (!hideCurrencyName) {
    formattedAmount = `${formattedAmount} ${currencyName}`;
  }
  return formattedAmount;
};

export const formatAmount = (amount: number, currencyDecimals = 2, currencyRate = 1, currencyName = ''): string => {
  return format(amount, currencyDecimals, currencyRate, currencyName || swissCode);
};

export const formatAmountWithoutCurrencyCode = (
  amount: number,
  currencyDecimals = 2,
  currencyRate = 1,
  currencyName = swissCode
): string => {
  return format(amount, currencyDecimals, currencyRate, currencyName, true);
};

export const formatAmountWithoutDecimals = (
  amount: number,
  currencyDecimals = 2,
  currencyRate = 1,
  currencyCode = swissCode
): string => {
  return format(amount, currencyDecimals, currencyRate, currencyCode, true, true);
};

export const formatAmountWithDot = (amount: number, currencyDecimals = 2, currencyName = ''): string => {
  const fixedAmount: string = Number(amount).toFixed(currencyDecimals);
  const fixedAmountWithoutDecimals: string = fixedAmount.split('.')[0];
  const separatedNumber: string = fixedAmountWithoutDecimals.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return `${separatedNumber} ${currencyName}`;
};

export const extractNumericDigits = (value: string) => {
  const numericDigitsOnly = value.split('.')[0].replace(/[^0-9]/g, '');
  return parseInt(numericDigitsOnly, 10);
};

export const extractNumericDigitsWithDecimals = (value: string) => {
  if (!value) return 0;
  const numericDigitsOnly = value.replace(/[^0-9.]/g, '');
  return parseFloat(numericDigitsOnly);
};
