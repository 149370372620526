import { css, styled } from 'styled-components';

type Props = {
  $isActive: boolean;
  $error: boolean;
  disabled: boolean;
  $hasInitialValue?: boolean;
};

export const StyledInput = styled.input<Props>`
  width: 4rem;
  height: 4rem;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  display: flex;
  padding: 0.4rem;
  align-items: center;
  gap: 0.4rem;
  align-self: stretch;
  border-radius: ${({ theme }) => theme.v2.componentsRadius.inputs.large};
  color: ${({ theme }) => theme.v2.text.headingPrimary};
  background-color: ${({ theme }) => theme.v2.surface.primary};
  border: 1px solid ${({ $error, theme }) => ($error ? theme.v2.border.error : theme.v2.border.primaryHover)};
  pointer-events: ${({ $hasInitialValue }) => ($hasInitialValue ? 'none' : 'auto')};

  &:focus {
    border-color: ${({ $error, $isActive, $hasInitialValue, theme }) => css`
      ${!$hasInitialValue && !$error && $isActive && theme.v2.border.information};
      ${$hasInitialValue && $isActive && theme.v2.border.primaryHover};
    `};

    caret-color: ${({ $isActive }) => ($isActive ? 'auto' : 'transparent')};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.v2.surface.disabled};
    border-color: ${({ theme }) => theme.v2.border.primaryHover};
    color: ${({ theme }) => theme.v2.text.disabled};
    pointer-events: none;
  }
`;
