import styled from 'styled-components';

import { ToastContainerProps } from '../types';

export const ToastContainer = styled.div<ToastContainerProps>`
  position: relative;
  top: 1.5rem;
  z-index: 999;
  right: 1.5rem;
  display: grid;
  grid-template-columns: auto minmax(auto, 19.25rem) auto;
  align-items: center;
  column-gap: 1rem;
  row-gap: 0.25rem;
  padding: 1rem;
  overflow: hidden;
  border: 1px solid ${({ $borderColor }) => $borderColor};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  transition: 0.25s transform
    ${({ $isToastOpen }) => ($isToastOpen ? 'cubic-bezier(0, 0, .51, 1.55)' : 'cubic-bezier(.51, -0.55, 1, 1)')};
  transform: translateX(${({ $isToastOpen }) => ($isToastOpen ? '0' : 'calc(100% + 1.5rem)')});
  max-width: 26.25rem;

  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: ${({ $backgroundColor }) => $backgroundColor};
    opacity: 0.8;
  }
`;
