import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { breakpoints } from '../../../breakpoints';

import { PositionsBar } from './SideBar';

export const StyledSidebar = styled.div<{ $position: PositionsBar }>`
  grid-area: Sidebar;
  border-right: 1px solid ${({ theme }) => theme.v2.border.primary};
  background-color: ${({ theme }) => theme.v2.surface.primary};
  position: ${({ $position }) => $position};
  top: 0;
  left: 0;
  height: 100%;
  width: 6.5rem;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  transform-origin: top;
  flex-direction: column;
  justify-content: space-between;
  // this needs to be 1 more than content because sidebar tooltip needs to be on top of content always
  z-index: 3;

  @media ${breakpoints.desktopSm} {
    width: 17.5rem;
    z-index: 1;
  }
`;
export const StyledIconDesktop = styled.img`
  display: none;
  max-height: 2.75rem;
  @media ${breakpoints.desktopSm} {
    display: block;
  }
`;

export const StyledIconMobile = styled.img`
  display: block;
  max-height: 2.625rem;
  @media ${breakpoints.desktopSm} {
    display: none;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SidebarHeader = styled.div`
  margin-bottom: 1rem;
`;

export const SidebarFooter = styled.div`
  display: block;
`;

export const StyledMenu = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const StyledItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 5.25rem;
  margin: 0.25rem 0;
  transition: 200ms ease;

  @media ${breakpoints.laptopSm} {
    max-width: unset;
  }
`;

export const StyledIconLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  @media ${breakpoints.desktopSm} {
    justify-content: flex-start;
  }
`;
