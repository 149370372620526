import { ShellContainer } from '@metaswiss/ui-kit/src/components/organism/page-shell/pageShell.styles';
import styled from 'styled-components';

import { ProtectedLayoutContainer } from '../../../../layouts/protected-layout/styles/protectedLayout.styles';

export const MetaSwissWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: ${({ theme }) => theme.v2.surface.secondary};

  &::before {
    display: block;
    width: 43.1875rem;
    height: 43.1875rem;
    border-radius: 50%;
    content: '';
    filter: blur(12.125rem);
    background-color: ${({ theme }) => theme.v2.colors.secondary100};
    opacity: 0.3;
    position: fixed;
    left: -0.98%;
    top: 55.02%;
    transform: translate3d(0, 0, 0);
  }

  &::after {
    display: block;
    width: 43.1875rem;
    height: 43.1875rem;
    border-radius: 50%;
    content: '';
    filter: blur(12.125rem);
    background-color: ${({ theme }) => theme.v2.colors.primary100};
    opacity: 0.3;
    position: fixed;
    right: 3.4375%;
    top: -20.619%;
    transform: translate3d(0, 0, 0);
  }

  ${ShellContainer} & {
    overflow: hidden;
  }

  ${ProtectedLayoutContainer} & {
    overflow-y: auto;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary.hue0};

  &::before {
    display: block;
    width: 51rem;
    height: 51rem;
    border-radius: 50%;
    content: '';
    filter: blur(12.125rem);
    background-color: ${({ theme }) => theme.colors.warning.hue50};
    position: fixed;
    left: 67.1875%;
    bottom: 88.6047%;
    transform: translate3d(0, 0, 0);
  }

  &::after {
    display: block;
    width: 51rem;
    height: 51rem;
    border-radius: 50%;
    content: '';
    filter: blur(12.125rem);
    background-color: ${({ theme }) => theme.colors.primary.hue200};
    position: fixed;
    right: 40.2083%;
    top: 104.375%;
    transform: translate3d(0, 0, 0);
  }

  ${ShellContainer} & {
    overflow: hidden;
  }

  ${ProtectedLayoutContainer} & {
    overflow-y: auto;
  }
`;
