import styled from 'styled-components';

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  align-items: center;
  justify-content: center;
  height: 12rem;
  width: 12rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.v2.surface.errorLight};
  border: 1px solid ${({ theme }) => theme.v2.border.error};
  color: ${({ theme }) => theme.v2.text.error};
`;
