import { LegalFormResponse } from '@metaswiss/api/src/api/models/LegalFormResponse';
import { composeDropdownOptions } from '@metaswiss/lib';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { api } from '../../api/msApi';
import { ApiResource } from '../../enums/resource.enum';
import { getQueryKey } from '../../shared/helpers/getQueryKey.helper';
import { useTextTranslation } from '../use-text-translation/useTextTranslation';

export const useLegalForm = () => {
  const { textTranslation } = useTextTranslation();

  const { data } = useQuery(getQueryKey(ApiResource.LEGAL_FOCUS), () => api.legalForm.getLegalForms());

  const legalForms = useMemo(() => {
    return composeDropdownOptions<LegalFormResponse>(data || [], 'id', 'translationCode', textTranslation);
  }, [data, textTranslation]);

  const findLegalForm = (selectedForm: LegalFormResponse) =>
    legalForms.find((legalForm) => legalForm.id === selectedForm.id);

  return {
    legalForms,
    findLegalForm,
  };
};
