import { tenantConfig } from '../../../../../tenantConfig.ts';
import { Collections } from '../collections/Collections.tsx';
import { SingleCollection } from '../single-collection/SingleCollection.tsx';

export const NftsHandler = () => {
  if (!tenantConfig.singleNft) {
    return <Collections />;
  }
  return <SingleCollection />;
};
