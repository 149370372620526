import styled from 'styled-components';

export const HistoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TransactionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(26.25rem, 1fr));
  grid-gap: 1rem;
`;

export const IndicatorGroup = styled.div`
  margin: 0 auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;
