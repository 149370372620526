import { FC } from 'react';

import { IconProps } from './iconProps';

export const LeavingIcon: FC<IconProps> = ({ strokeColor, color, hasBorderRadius }) => {
  return (
    <svg
      width={hasBorderRadius ? '3rem' : '22'}
      height={hasBorderRadius ? '3rem' : '22'}
      viewBox="0 0 22 22"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.53016 3.6001H5.82428C5.26265 3.6001 4.72401 3.82135 4.32688 4.21517C3.92974 4.609 3.70663 5.14314 3.70663 5.7001V18.3001C3.70663 18.8571 3.92974 19.3912 4.32688 19.785C4.72401 20.1788 5.26265 20.4001 5.82428 20.4001H9.53016M9.79335 12.0001H21.7934M21.7934 12.0001L17.2082 7.2001M21.7934 12.0001L17.2082 16.8001"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
