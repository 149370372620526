import { FC } from 'react';

import { IconProps } from './iconProps';

export const Lightbulb: FC<IconProps> = ({ strokeColor = 'black', color = 'none' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 14 22" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.6 20.5999H9.4M5.2 8.5999H8.8M1 7.3999C1 4.08619 3.68629 1.3999 7 1.3999C10.3137 1.3999 13 4.08619 13 7.3999C13 9.86029 11.5191 11.874 9.4 12.7999V16.3999C9.4 17.0626 8.86274 17.5999 8.2 17.5999H5.8C5.13726 17.5999 4.6 17.0626 4.6 16.3999V12.9006C2.48091 11.9748 1 9.86028 1 7.3999Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
