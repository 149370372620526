import { CaseConversationFilesResponse } from '@metaswiss/api';
import { AvatarV2, Document, Flex, Text } from '@metaswiss/ui-kit';
import { DocumentState } from '@metaswiss/ui-kit/src/components/atoms/document/enums.ts';
import { useQueries } from '@tanstack/react-query';
import { FC, useCallback } from 'react';

import { api } from '../../../../../../../../api/msApi.ts';
import { ApiResource } from '../../../../../../../../enums/resource.enum.ts';
import { getQueryKey } from '../../../../../../../../shared/helpers/getQueryKey.helper.ts';

import { DocumentWrapper, MessageBodyWrapper } from './singleChatMessage.styles';

type SingleChatMessageProps = {
  imageUrl: string;
  name: string;
  description?: string;
  files: CaseConversationFilesResponse[];
};

export const SingleChatMessage: FC<SingleChatMessageProps> = ({ imageUrl, name, description, files }) => {
  const documentUrls = useQueries({
    queries:
      files.map((file) => {
        return {
          queryKey: getQueryKey(ApiResource.ASSET, file.id),
          queryFn: () => api.assets.getS3SignedAssignedUrl({ assetId: file.id }),
          enabled: !!file.id,
        };
      }) || [],
  });

  const handleClick = useCallback(
    (index: number) => {
      const url = documentUrls[index];
      if (!url.isFetching) {
        window.open(url.data?.url, '_blank');
      }
    },
    [documentUrls]
  );

  return (
    <Flex flexDirection={'column'} gap={'1rem'}>
      <Flex flexDirection={'column'} gap={'0.5rem'}>
        <Flex alignItems={'center'} gap={'0.75rem'}>
          <AvatarV2 imagePath={imageUrl} customSize={'2rem'} />
          <Text fontWeight={'bold'}>{name}</Text>
        </Flex>
        <MessageBodyWrapper>
          {description && <Text>{description}</Text>}
          <Flex gap={'1rem'} wrap={'wrap'}>
            {files.map((file, index) => (
              <DocumentWrapper key={file.id}>
                <Document text={file.fileName} status={DocumentState.COMPLETED} onClick={() => handleClick(index)} />
              </DocumentWrapper>
            ))}
          </Flex>
        </MessageBodyWrapper>
      </Flex>
    </Flex>
  );
};
