/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImportSendShareRequest } from '../models/ImportSendShareRequest';
import type { PageableItems } from '../models/PageableItems';
import type { SendShareRequest } from '../models/SendShareRequest';
import type { ShareTransactionV5Response } from '../models/ShareTransactionV5Response';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ShareTransactionApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public importSendShare(
    requestBody: ImportSendShareRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/share-transaction/import-send-share',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public sendShare(
    requestBody: SendShareRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/share-transaction/send-share',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns ShareTransactionV5Response Get share transaction by id
   * @throws ApiError
   */
  public getShareTransactionById(
    id: string,
  ): CancelablePromise<ShareTransactionV5Response> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/share-transaction/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param userId
   * @param offset
   * @param limit
   * @param page
   * @returns any Get smart share ledger
   * @throws ApiError
   */
  public getSmartShareLedger(
    userId: string,
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/share-transaction',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'userId': userId,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
