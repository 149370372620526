import { styled } from 'styled-components';

import { RemSize } from '../../../../../theme';
import { StatisticInfoContainer } from '../../../atoms/statistic-info/statisticInfo.styles';

export const StatisticsBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TopRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BottomRowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  ${StatisticInfoContainer} {
    flex: 1 0 0;
  }
`;

export const BottomSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SectionItemsWrapper = styled.div<{ $padding: RemSize }>`
  display: flex;
  align-items: center;

  ${StatisticInfoContainer} {
    padding: 0 ${({ $padding }) => $padding};
    border-right: 1px solid ${({ theme }) => theme.colors.neutrals.hue100};

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
      border-right: none;
    }
  }
`;

export const BottomItemSeparationLine = styled.div`
  height: 1.5rem;
  width: 1px;
  background-color: ${({ theme }) => theme.v2.border.primary};
`;
