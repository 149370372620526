import { FC } from 'react';

import { IconProps } from './iconProps';

export const ArrowRotateLeft: FC<IconProps> = ({ strokeColor }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.17815 14.9984C4.4379 18.8312 8.04583 21.5984 12.3001 21.5984C17.602 21.5984 21.9001 17.3004 21.9001 11.9984C21.9001 6.6965 17.602 2.39844 12.3001 2.39844C8.74674 2.39844 5.64429 4.32899 3.98441 7.19844M6.9001 8.39844H2.1001V3.59844"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
