import styled from 'styled-components';

import { IconSize } from '../../../../theme';

type Props = {
  $size: IconSize | undefined;
};

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: ${({ $size }) => ($size === 'large' ? '2rem' : '1.5rem')};
  justify-content: center;
  position: relative;
`;
