import { BaseSelectableCard } from './BaseSelectableCard';
import { WrapperCardProps } from './types';

export const SelectableCard = ({ size = 'small', children, ...props }: WrapperCardProps) => {
  return (
    <BaseSelectableCard size={size} isExpandedDefault={true} {...props}>
      {children}
    </BaseSelectableCard>
  );
};
