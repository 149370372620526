import styled from 'styled-components';

export const StyledError = styled.span`
  top: 100%;
  position: absolute;
  left: 0;
  color: ${({ theme }) => theme.colors.error.hue400};
  font-weight: ${({ theme }) => theme.typography.weights.reg};
  font-size: ${({ theme }) => theme.typography.sizes.xsm};
  margin: 6px 0 6px 25px;
`;
