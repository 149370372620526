export enum ProductType {
  BOND_PAPERS = 'BOND_PAPERS',
  ACTIONS = 'ACTIONS',
  NFT = 'NFT',
}

export enum TransactionStatus {
  PENDING = 'PENDING',
  VALIDATING = 'VALIDATING',
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  STRIPE_PENDING = 'STRIPE_PENDING',
}
