import React, { forwardRef, memo, useDeferredValue } from 'react';

import { LocalSpinner } from '../../../../components';
import { RemSize } from '../../../../theme';

import { SingleColumnWrapper, Wrapper } from './flatList.styles';

type FlatListProps<T> = {
  data: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
  headerComponent?: React.ReactNode;
  footerComponent?: React.ReactNode;
  numColumns?: number | 'auto-fill';
  numberOfColumnsOnSmallerDevices?: number;
  keyExtractor: (item: T) => string;
  isFetching?: boolean;
  isError?: boolean;
  fetchingTriggerIndex?: number;
  contentGap?: RemSize;
  headerGap?: RemSize;
  footerGap?: RemSize;
  emptyListComponent?: React.ReactNode;
  minItemWidth?: RemSize;
  reverseItems?: boolean;
  errorComponent?: JSX.Element;
};

const FlatListComponent = forwardRef(<T,>(props: FlatListProps<T>, ref: React.Ref<HTMLDivElement>) => {
  const {
    data,
    renderItem,
    headerComponent,
    footerComponent,
    isFetching,
    numColumns = 2,
    numberOfColumnsOnSmallerDevices = 1,
    keyExtractor,
    fetchingTriggerIndex = 4,
    contentGap = '1rem',
    headerGap,
    footerGap,
    emptyListComponent,
    minItemWidth = '11.75rem',
    reverseItems = false,
    errorComponent,
    isError,
  } = props;
  const deferredData = useDeferredValue(data);

  return (
    <>
      {headerComponent && <SingleColumnWrapper $marginBottom={headerGap}>{headerComponent}</SingleColumnWrapper>}
      {deferredData?.length === 0 && emptyListComponent ? (
        emptyListComponent
      ) : (
        <Wrapper
          $numColumns={numColumns}
          $numberOfColumnsOnSmallerDevices={numberOfColumnsOnSmallerDevices}
          $gap={contentGap}
          $minItemWidth={minItemWidth}
          $reverseItems={reverseItems}
        >
          {deferredData?.map((item, index) => {
            const isFetchingTriggerIndex = index === deferredData.length - fetchingTriggerIndex;
            return (
              <div ref={ref && isFetchingTriggerIndex ? ref : null} key={keyExtractor(item)}>
                {renderItem(item, index)}
              </div>
            );
          })}
          {isFetching && (
            <SingleColumnWrapper>
              <LocalSpinner isActive={isFetching} />
            </SingleColumnWrapper>
          )}
          {isError && <SingleColumnWrapper>{errorComponent}</SingleColumnWrapper>}
        </Wrapper>
      )}

      {footerComponent && <SingleColumnWrapper $marginTop={footerGap}>{footerComponent}</SingleColumnWrapper>}
    </>
  );
});

export const FlatListV2 = memo(FlatListComponent) as <T>(
  props: FlatListProps<T> & { ref?: React.Ref<HTMLDivElement> }
) => JSX.Element;
