import {
  Heading,
  PortfolioBondIcon,
  PortfolioCollectibleIcon,
  PortfolioNftIcon,
  PortfolioShareIcon,
  Text,
  ThemedIcon,
} from '@metaswiss/ui-kit';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { ProductType } from '../../../../../../enums/productType.enum';
import { tenantConfig } from '../../../../../../tenantConfig.ts';

import { BannerContainer, IconWrapper, ImageWrapper, TextsWrapper } from './portfolioBanner.styles';

type PortfolioBannerProps = {
  type: ProductType;
  heading: string;
  text: string;
};

export const PortfolioBanner = ({ type, heading, text }: PortfolioBannerProps) => {
  const theme = useTheme();
  const isMetaswiss = theme.v2.tenant === 'MetaSwiss';
  const bannerGradientColor = useMemo(() => getBannerGradientColor(type, isMetaswiss), [type, isMetaswiss]);
  const bannerPictureUrl = useMemo(() => getBannerUrl(type), [type]);
  const icon = useMemo(() => getIcon(type), [type]);

  //TODO - this component is just for the presentation purposes. We will get these information from the be later

  return (
    <BannerContainer $gradientColor={bannerGradientColor}>
      <TextsWrapper>
        <Heading headingType={'h4'} fontWeight={'bold'} color={theme.v2.text.onAction}>
          {heading}
        </Heading>
        <Text textColor={'hue100'} color={theme.v2.text.onAction}>
          {text}
        </Text>
      </TextsWrapper>
      <IconWrapper>
        <ThemedIcon icon={icon} size={'full'} customStrokeColor={theme.v2.text.onAction} />
      </IconWrapper>
      <ImageWrapper src={bannerPictureUrl} />
    </BannerContainer>
  );
};

const getBannerGradientColor = (type: ProductType, isMetaswiss: boolean) => {
  //TODO - these colors don't exist in the theme, we should probably update them later
  switch (type) {
    case ProductType.NFT:
      return isMetaswiss
        ? `linear-gradient(307.69deg, #08DE73 -32.45%, #069E52 111.94%);`
        : `linear-gradient(307.69deg, #9126D9 -32.45%, #571782 111.94%);`;
    case ProductType.ACTIONS:
      return isMetaswiss
        ? 'linear-gradient(307.69deg, #68A2FF -32.45%, #3381FF 111.94%);'
        : `linear-gradient(307.69deg, #68A2FF -32.45%, #3381FF 111.94%);`;
    case ProductType.BOND_PAPERS:
      return isMetaswiss
        ? 'linear-gradient(307.69deg, #F05DF3 -32.45%, #A35AFF 111.94%, #A056FE 111.94%);'
        : `linear-gradient(307.69deg, #08DE73 -32.45%, #069E52 111.94%);
`;
    default:
      throw new Error('Wrong product type selected!');
  }
};

const getBannerUrl = (type: ProductType) => {
  switch (type) {
    case ProductType.NFT:
      return tenantConfig.portfolioNftBannerIcon;
    case ProductType.ACTIONS:
      return tenantConfig.portfolioSharesBannerIcon;
    case ProductType.BOND_PAPERS:
      return tenantConfig.portfolioBondsBannerIcon;
    default:
      throw new Error('Wrong product type selected!');
  }
};

const getIcon = (type: ProductType) => {
  switch (type) {
    case ProductType.ACTIONS:
      return PortfolioShareIcon;
    case ProductType.BOND_PAPERS:
      return PortfolioBondIcon;
    case ProductType.NFT:
      return tenantConfig.useNftName ? PortfolioNftIcon : PortfolioCollectibleIcon;
    default:
      throw new Error('Wrong product type selected!');
  }
};
