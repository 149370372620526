import styled from 'styled-components';

export const ButtonsContainer = styled.div<{ $addPadding?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: auto;
  padding: ${({ $addPadding }) => $addPadding && '1rem'};
`;
