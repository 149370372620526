import { styled } from 'styled-components';

type Props = {
  $padding: string;
  $radius: string;
  $borderWidth: string;
  $selectedButton: boolean;
  disabled?: boolean;
};

export const StyledIconButton = styled.button.attrs({ type: 'button' })<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ $padding }) => $padding};
  border-radius: ${({ $radius }) => $radius};
  border-width: ${({ $borderWidth }) => $borderWidth};
  border-color: ${({ theme }) => theme.v2.border.primary};
  border-style: solid;
  background-color: ${({ $selectedButton, theme }) =>
    $selectedButton ? theme.v2.surface.informationLight : theme.v2.surface.primary};

  path {
    fill: ${({ theme, $selectedButton }) => ($selectedButton ? theme.v2.colors.primary100 : theme.v2.icon.neutral)};
  }

  &:hover {
    background-color: ${({ $selectedButton, theme }) =>
      $selectedButton ? theme.v2.surface.informationLight : theme.v2.surface.tertiary};

    path {
      fill: ${({ $selectedButton, theme }) =>
        $selectedButton ? theme.v2.colors.primary100 : theme.v2.text.headingPrimary};
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.v2.surface.informationLight};
    border-color: ${({ theme }) => theme.v2.surface.informationLight};

    path {
      fill: ${({ theme }) => theme.v2.colors.primary100};
    }
  }

  &:disabled {
    pointer-events: none;
    background-color: ${({ theme }) => theme.v2.surface.disabled};
    border-color: ${({ theme }) => theme.v2.border.primary};

    path {
      fill: ${({ theme }) => theme.v2.colors.neutral50};
    }
  }
`;
