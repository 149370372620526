import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useState } from 'react';

import { IdentityVerification } from '../../../../../../enums/identityVerification';

export type DocumentOption = {
  id: IdentityVerification;
  value: string;
};

type IdentityProofProps = {
  step?: 0 | 1;
  document?: DocumentOption;
  frontSide?: { fileId?: string; url?: string };
  backSide?: { fileId?: string; url?: string };
  isSuccess?: boolean;
  isDisabledOutsideClick: boolean;
  isLoading?: boolean;
};

type VerificationDocumentProps = {
  file?: { fileId?: string; url?: string };
  isSuccess?: boolean;
};

export type VerificationDocumentContext = {
  verificationDocumentFlow: VerificationDocumentProps;
  setVerificationDocumentFlow: Dispatch<SetStateAction<VerificationDocumentProps>>;
  identityProofFlow: IdentityProofProps;
  setIdentityProofFlow: Dispatch<SetStateAction<IdentityProofProps>>;
};

export type VerificationDocumentProviderProps = {
  children: ReactNode;
};

export const VericationDocumentContext = createContext({} as VerificationDocumentContext);

// eslint-disable-next-line react-refresh/only-export-components
export const useVerificationDocumentContext = () => useContext(VericationDocumentContext);

export const VerificationDocumentProvider: FC<VerificationDocumentProviderProps> = ({ children }) => {
  const [verificationDocumentFlow, setVerificationDocumentFlow] = useState<VerificationDocumentProps>({
    file: undefined,
    isSuccess: undefined,
  });
  const [identityProofFlow, setIdentityProofFlow] = useState<IdentityProofProps>({
    step: 0,
    document: undefined,
    frontSide: undefined,
    backSide: undefined,
    isSuccess: undefined,
    isDisabledOutsideClick: false,
    isLoading: false,
  });

  return (
    <VericationDocumentContext.Provider
      value={{ verificationDocumentFlow, setVerificationDocumentFlow, identityProofFlow, setIdentityProofFlow }}
    >
      {children}
    </VericationDocumentContext.Provider>
  );
};
