import { IconProps } from './iconProps';

export const WalletIcon: React.FC<IconProps> = ({ strokeColor = 'black' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.6 15.5599V16.5599C21.1523 16.5599 21.6 16.1122 21.6 15.5599H20.6ZM20.6 10.2799H21.6C21.6 9.72761 21.1523 9.27989 20.6 9.27989V10.2799ZM6.13924 5.11872C5.67467 5.41737 5.54016 6.03608 5.83881 6.50065C6.13747 6.96522 6.75618 7.09972 7.22075 6.80107L6.13924 5.11872ZM13.4 1.63989L14.1893 1.02595C13.8716 0.617408 13.2946 0.518835 12.8592 0.798714L13.4 1.63989ZM15.9706 6.57383C16.3097 7.00978 16.938 7.08832 17.3739 6.74925C17.8099 6.41018 17.8884 5.7819 17.5493 5.34595L15.9706 6.57383ZM20.6 14.5599H17.48V16.5599H20.6V14.5599ZM17.48 11.2799H20.6V9.27989H17.48V11.2799ZM19.6 10.2799V15.5599H21.6V10.2799H19.6ZM15.84 12.9199C15.84 12.0141 16.5742 11.2799 17.48 11.2799V9.27989C15.4697 9.27989 13.84 10.9096 13.84 12.9199H15.84ZM17.48 14.5599C16.5742 14.5599 15.84 13.8256 15.84 12.9199H13.84C13.84 14.9302 15.4697 16.5599 17.48 16.5599V14.5599ZM7.22075 6.80107L13.9408 2.48107L12.8592 0.798714L6.13924 5.11872L7.22075 6.80107ZM12.6106 2.25383L15.9706 6.57383L17.5493 5.34595L14.1893 1.02595L12.6106 2.25383ZM2.35999 6.95989H18.68V4.95989H2.35999V6.95989ZM18.68 19.3599H2.35999V21.3599H18.68V19.3599ZM2.39999 19.3999V6.91989H0.399994V19.3999H2.39999ZM2.35999 19.3599C2.38209 19.3599 2.39999 19.3778 2.39999 19.3999H0.399994C0.399994 20.4824 1.27751 21.3599 2.35999 21.3599V19.3599ZM18.64 19.3999C18.64 19.3778 18.6579 19.3599 18.68 19.3599V21.3599C19.7625 21.3599 20.64 20.4824 20.64 19.3999H18.64ZM18.68 6.95989C18.6579 6.95989 18.64 6.94198 18.64 6.91989H20.64C20.64 5.83742 19.7625 4.95989 18.68 4.95989V6.95989ZM2.35999 4.95989C1.27752 4.95989 0.399994 5.83742 0.399994 6.91989H2.39999C2.39999 6.94198 2.38209 6.95989 2.35999 6.95989V4.95989ZM18.64 16.2799V19.3999H20.64V16.2799H18.64ZM18.64 6.91989V9.43989H20.64V6.91989H18.64Z"
        fill={strokeColor}
      />
    </svg>
  );
};
