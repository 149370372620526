import { styled } from 'styled-components';

import { TextTemplate } from '../../../../shared/typography/TextTemplate.styles';

export const HeaderCountryInfoSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${TextTemplate} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

export const TextFlagWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
`;
