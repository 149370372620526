import * as zod from 'zod';

import { FormNames } from '../../../../../../../../shared/forms/formNames';
import { staticTranslationFunction } from '../../../../../../../../shared/forms/staticTranslationFunction.ts';

export const createFullCorporateAddressSchema = (translate: (key: string) => string) =>
  zod.object({
    country: zod.object({
      value: zod.string().nonempty(translate('error.requiredField')),
      label: zod.string().nonempty(translate('error.requiredField')),
      iconSrc: zod.string().optional(),
      meta: zod.string().optional(),
    }),
    city: zod.string().trim().nonempty(translate('error.requiredField')),
    address: zod.string().trim().nonempty(translate('error.requiredField')),
    zipCode: zod.string().trim().nonempty(translate('error.requiredField')),
  });

const schema = createFullCorporateAddressSchema(staticTranslationFunction);

export type FullCorporateRegistrationAddressFormData = zod.infer<typeof schema>;

export const fullRegistrationCorporateAddressSchema: FormNames<FullCorporateRegistrationAddressFormData> = {
  country: 'country',
  city: 'city',
  address: 'address',
  zipCode: 'zipCode',
} as const;
