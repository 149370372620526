import { FC, MouseEventHandler, ReactNode } from 'react';
import { useTheme } from 'styled-components';

import { DocumentIcon } from '../../../iconography';
import { RemSize } from '../../../theme';
import { Text } from '../../atoms/text';
import { ThemedIcon } from '../theme-icon';

import { Image, ImageContainer, ImageWrapper, ViewFileContainer } from './styles/viewFile';

type ViewFileProps = {
  onClick: MouseEventHandler<HTMLDivElement>;
  title?: string;
  subtitle?: string;
  isImage?: boolean;
  children?: ReactNode;
  url: string;
  imgContainerPadding?: RemSize;
  isDisabled: boolean;
};

export const ViewFile: FC<ViewFileProps> = ({
  onClick,
  title = '',
  subtitle = '',
  isImage = false,
  children,
  url,
  imgContainerPadding,
  isDisabled,
}) => {
  const theme = useTheme();

  return (
    <ViewFileContainer onClick={onClick} $isDisabled={isDisabled}>
      {isImage ? (
        <ImageContainer $padding={imgContainerPadding}>
          <ImageWrapper $isDisabled={isDisabled}>
            <Image src={url} alt={title} />
          </ImageWrapper>
        </ImageContainer>
      ) : (
        <>
          <ThemedIcon icon={DocumentIcon} size={'xxl'} customStrokeColor={theme.v2.text.bodySecondary} />
          <Text fontWeight={'bold'} fontSize={'base'}>
            {title}
          </Text>
          <Text fontSize={'sm'} color={theme.v2.text.bodySecondary}>
            {subtitle}
          </Text>
        </>
      )}
      {children}
    </ViewFileContainer>
  );
};
