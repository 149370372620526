import { remSize } from '@metaswiss/lib';
import { RemSize } from '@metaswiss/ui-kit';
import { useLayoutEffect, useState } from 'react';

export const useGetElementsHeight = (elementClasses: string[]) => {
  const [elementHeight, setElementHeight] = useState<RemSize>('0rem');

  useLayoutEffect(() => {
    const elements = elementClasses.map((elementClass) => document.querySelector(`.${elementClass}`));

    const totalHeight = elements.reduce((acc, element) => (element ? acc + element.clientHeight / remSize : acc), 0);

    setElementHeight(`${totalHeight}rem`);
  }, [elementClasses]);

  return elementHeight;
};
