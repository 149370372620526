import styled from 'styled-components';

export const Container = styled.div<{ $width: number }>`
  width: ${({ $width }) => $width}px;
  height: ${({ $width }) => $width / 2}px;
  position: relative;
`;
export const Percent = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.v2.surface.primary};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  box-shadow:
    0 0 16px 0 #00000005,
    0 1px 0 0 #00000005,
    0 10px 24px 0 #00000005;
  display: flex;
  justify-content: center;
  align-items: center;
`;
