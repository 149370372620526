import {
  HeaderContainer,
  StartBlockContainer,
  LogoContainer,
  EndBlockContainer,
  TopRow,
  BottomRow,
  TopCenterBlockContainer,
  BottomCenterBlockContainer,
} from './header.styles';

export type Props = {
  logo: React.ReactNode;
  startBlock: React.ReactNode;
  centerBlock: React.ReactNode;
  endBlock: React.ReactNode;
  border?: {
    width: string;
    style: string;
    color: string;
  };
  headerOptions?: {
    showLogo?: boolean;
    showTitle?: boolean;
    showBreadcrumbs?: boolean;
    showInput?: boolean;
    showButton?: boolean;
    showIcons?: boolean[];
  };
};

export const BaseHeader: React.FC<Props> = ({
  logo,
  startBlock,
  centerBlock,
  endBlock,
  border = { width: '0px', style: 'solid', color: 'none' },
  headerOptions,
}) => {
  return (
    <HeaderContainer $border={border}>
      <TopRow>
        <LogoContainer $showLogo={headerOptions?.showLogo}>{logo}</LogoContainer>
        <StartBlockContainer>{startBlock}</StartBlockContainer>
        <TopCenterBlockContainer>{centerBlock}</TopCenterBlockContainer>
        <EndBlockContainer>{endBlock}</EndBlockContainer>
      </TopRow>
      {(headerOptions?.showInput || headerOptions?.showButton) && (
        <BottomRow>
          <BottomCenterBlockContainer>{centerBlock}</BottomCenterBlockContainer>
        </BottomRow>
      )}
    </HeaderContainer>
  );
};
