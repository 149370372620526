import { environmentVariables } from './env/environmentVariables.ts';

type TenantConfig = {
  singleShare: boolean;
  singleNft: boolean;
  error404Icon: string;
  maintenanceIcon: string;
  errorIcon: string;
  successIcon: string;
  portfolioNftBannerIcon: string;
  portfolioSharesBannerIcon: string;
  portfolioBondsBannerIcon: string;
  enDesktopHomeBanner: string;
  enTabletHomeBanner: string;
  deDesktopHomeBanner: string;
  deTabletHomeBanner: string;
  logoBig: string;
  logoSmall: string;
  blurBackground: boolean;
  useNftName: boolean;
  loginBannerUrl: string;
  footerCompanyName: string;
  footerCompanyLink: (currentLanguage: string) => string;
  logo: string;
  desktopLogo: string;
  termsAndConditionsLink: (currentLanguage: string) => string;
  privacyPolicyLink: (currentLanguage: string) => string;
  googlePlayAppLink: string;
  appStoreAppLink: string;
  sideBannerimageAlignment: string;
  accountCoverImg: string;
  aboutUsHeaderImg: string;
  aboutUsPlantsImg: string;
  aboutUsMicrogreenImg: string;
  aboutUsConferenceImg: string;
  aboutUsMeetingImg: string;
  pageWidth: string;
};

type ConfigData = {
  [key: string]: TenantConfig;
};

const configData: ConfigData = {
  greenstate: {
    singleShare: true,
    singleNft: false,
    error404Icon: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1712308751/GreenState/GS-404_thbozh.png',
    maintenanceIcon:
      'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1712308750/GreenState/GS-Maintenance_gjogxc.png',
    errorIcon: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1712308748/GreenState/GS-Error_lfr8do.png',
    successIcon: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1712308747/GreenState/GS-Success_onnbcz.png',
    portfolioNftBannerIcon: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1703852097/NFT_jhdv9n.png',
    portfolioSharesBannerIcon: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1703852113/Bonds_xqvq1d.png',
    portfolioBondsBannerIcon: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1703852106/Shares_dvgwvd.png',
    logoSmall: 'https://greenstate.ch/wp-content/uploads/2023/06/cropped-logo_gs-192x192.png',
    logoBig: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1671540575/greenstate-light_cnrmvb.png',
    enDesktopHomeBanner:
      'https://res.cloudinary.com/dcl0dmpgl/image/upload/v1728653864/GreenState%20App/web_app_ad_fbrv6i.webp',
    enTabletHomeBanner:
      'https://res.cloudinary.com/dcl0dmpgl/image/upload/v1728654043/GreenState%20App/tablet_ad_vejobm.webp',
    deDesktopHomeBanner:
      'https://res.cloudinary.com/dcl0dmpgl/image/upload/v1728653864/GreenState%20App/web_app_ad_fbrv6i.webp',
    deTabletHomeBanner:
      'https://res.cloudinary.com/dcl0dmpgl/image/upload/v1728654043/GreenState%20App/tablet_ad_vejobm.webp',
    blurBackground: false,
    useNftName: true,
    loginBannerUrl:
      'https://res.cloudinary.com/dcl0dmpgl/image/upload/v1679928486/GreenState%20AG/Landing%20Page/Landing%20Basil/invest-basil_iw4je0.webp',
    footerCompanyName: 'GreenState 2024 ©',
    footerCompanyLink: (currentLanguage) => `https://greenstate.ch/${currentLanguage}`,
    logo: 'https://greenstate.ch/wp-content/uploads/2023/06/cropped-logo_gs-192x192.png',
    desktopLogo: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1671540575/greenstate-light_cnrmvb.png',
    termsAndConditionsLink: (currentLanguage) => `https://greenstate.ch/${currentLanguage}/terms-of-use/`,
    privacyPolicyLink: (currentLanguage) => `https://greenstate.ch/${currentLanguage}/data-protection/`,
    googlePlayAppLink: 'https://play.google.com/store/apps/details?id=com.greenstate.investor.relations',
    appStoreAppLink: 'https://apps.apple.com/us/app/greenstate-investor-relations/id6445986981',
    sideBannerimageAlignment: 'center',
    accountCoverImg:
      'https://res.cloudinary.com/dcl0dmpgl/image/upload/v1685611010/GreenState%20AG/Home%20Page/Hero%20Area/header_home_wpghv5.webp',
    aboutUsHeaderImg: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1706273806/_DSC2959-min_lrw8q4.jpg',
    aboutUsPlantsImg: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1706273806/Slika_05-min_edbima.jpg',
    aboutUsMicrogreenImg: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1706273806/DSC09501-min_fygr1l.jpg',
    aboutUsConferenceImg: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1706273807/_DSC2955-min_tpbqxy.jpg',
    aboutUsMeetingImg: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1706273807/DYN09384-min_zs5nj7.jpg',
    pageWidth: '77.5rem',
  },
  metaswiss: {
    singleShare: false,
    singleNft: false,
    error404Icon: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1712307805/MetaSwiss/ms-404_jezufb.png',
    maintenanceIcon:
      'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1712307805/MetaSwiss/MS-Maintenance_ajpupi.png',
    errorIcon: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1712307805/MetaSwiss/MS-Error_imwbjq.png',
    successIcon: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1712307805/MetaSwiss/MS-Success_sorcse.png',
    portfolioNftBannerIcon:
      'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1712736569/MetaSwiss/portfolioNftBanner_tj2d3j.png',
    portfolioSharesBannerIcon:
      'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1712736569/MetaSwiss/portfolioSharesBanner_yv4jus.png',
    portfolioBondsBannerIcon:
      'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1712736569/MetaSwiss/portfolioBondsBanner_qrsley.png',
    logoSmall: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1712756626/MetaSwiss/logo_small_g3aqtr.png',
    logoBig: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1712758670/MetaSwiss/logo_big_fibdg3.png',
    enDesktopHomeBanner:
      'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1712824350/MetaSwiss/MS_web_app_ad_xtmnfj.png',
    enTabletHomeBanner:
      'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1712824348/MetaSwiss/MS_tablet_ad_dratgp.png',
    deDesktopHomeBanner:
      'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1712827895/MetaSwiss/MS_web_app_ad_DE_ia91iy.png',
    deTabletHomeBanner:
      'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1712827895/MetaSwiss/MS_tablet_ad_DE_qqzmdn.png',
    blurBackground: true,
    useNftName: false,
    loginBannerUrl: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1710930328/metaswiss-sidebaner_cxqs7y.png',
    footerCompanyName: 'Metaswiss 2024 ©',
    footerCompanyLink: (currentLanguage) => `https://meta.swiss/${currentLanguage}`,
    logo: 'https://res.cloudinary.com/dcl0dmpgl/image/upload/v1690362645/MS%20GS%20APP/Metaswiss%20App/logo-ms_tjjjpl.webp',
    desktopLogo:
      'https://res.cloudinary.com/dcl0dmpgl/image/upload/v1671720588/MetaSwiss%20Temporary/images/logos/metaswiss_logo_white_tflb9v.webp',
    termsAndConditionsLink: (currentLanguage) => `https://meta.swiss/${currentLanguage}/terms-and-conditions/`,
    privacyPolicyLink: (currentLanguage) => `https://meta.swiss/${currentLanguage}/privacy-policy/`,
    googlePlayAppLink: '',
    appStoreAppLink: '',
    sideBannerimageAlignment: 'left',
    accountCoverImg:
      'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1711611085/7Z_2104.w028.n002.57A.p15_2_nbq4an.png',
    aboutUsHeaderImg: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1706273806/_DSC2959-min_lrw8q4.jpg',
    aboutUsPlantsImg: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1706273806/Slika_05-min_edbima.jpg',
    aboutUsMicrogreenImg: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1706273806/DSC09501-min_fygr1l.jpg',
    aboutUsConferenceImg: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1706273807/_DSC2955-min_tpbqxy.jpg',
    aboutUsMeetingImg: 'https://res.cloudinary.com/dmfsrdbdb/image/upload/v1706273807/DYN09384-min_zs5nj7.jpg',
    pageWidth: '77.5rem',
  },
};

export const tenantConfig = configData[environmentVariables.tenant];
