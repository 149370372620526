export enum DropzoneStatus {
  INITIAL = 'initial',
  LOADING = 'loading',
  INVALID = 'error',
  FAILED = 'failed',
}

export enum AcceptedFileTypes {
  IMAGES = 'images',
  DOCUMENTS = 'documents',
  CSV = 'csv',
  ALL = 'all',
}
