import styled from 'styled-components';

export const HeaderSection = styled.div<{ $bannerUrl: string }>`
  height: 9.25rem;
  width: 100%;
  background: ${({ $bannerUrl }) => `url(${$bannerUrl})`};
  background-size: cover;
  border-top-left-radius: ${({ theme }) => theme.v2.radius.small};
  border-top-right-radius: ${({ theme }) => theme.v2.radius.small};
`;

export const BodySection = styled.div`
  padding: 1rem;
`;

export const LogoContainer = styled.div<{ $logoUrl: string }>`
  width: 4.25rem;
  height: 4.25rem;
  background: ${({ $logoUrl }) => `url(${$logoUrl})`};
  background-size: cover;
  border-radius: 0.5rem;
  border: 2px solid ${({ theme }) => theme.colors.neutrals.hue400};
  box-shadow: 0px 4.25px 12.75px 0px #00000040;
  position: absolute;
  top: 1rem;
  left: 1rem;
`;
