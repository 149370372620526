import { FC } from 'react';

import { IconProps } from './iconProps';

export const DraggingPlusIcon: FC<IconProps> = ({ color = 'black' }) => {
  return (
    <svg width="121" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M61.5 36C61.5 35.4477 61.0523 35 60.5 35C59.9477 35 59.5 35.4477 59.5 36H61.5ZM59.5 84C59.5 84.5523 59.9477 85 60.5 85C61.0523 85 61.5 84.5523 61.5 84H59.5ZM84.5 61C85.0523 61 85.5 60.5523 85.5 60C85.5 59.4477 85.0523 59 84.5 59V61ZM36.5 59C35.9477 59 35.5 59.4477 35.5 60C35.5 60.5523 35.9477 61 36.5 61V59ZM107.5 60C107.5 85.9574 86.4574 107 60.5 107V109C87.562 109 109.5 87.062 109.5 60H107.5ZM60.5 107C34.5426 107 13.5 85.9574 13.5 60H11.5C11.5 87.062 33.438 109 60.5 109V107ZM13.5 60C13.5 34.0426 34.5426 13 60.5 13V11C33.438 11 11.5 32.938 11.5 60H13.5ZM60.5 13C86.4574 13 107.5 34.0426 107.5 60H109.5C109.5 32.938 87.562 11 60.5 11V13ZM59.5 36L59.5 60H61.5L61.5 36H59.5ZM59.5 60V84H61.5V60H59.5ZM84.5 59H60.5V61H84.5V59ZM60.5 59H36.5V61H60.5V59Z"
        fill={color}
      />
    </svg>
  );
};
