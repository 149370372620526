import { BaseCard } from '@metaswiss/ui-kit';
import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainCard = styled(BaseCard)`
  height: auto;
  min-height: auto;
  display: flex;
  align-items: flex-start;

  align-self: stretch;

  @media ${breakpoints.laptopSm} {
    flex: 1 0 0;
  }
`;

export const ResourceWrapper = styled.div`
  padding-left: 0.5rem;
  display: flex;
  justify-content: center;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  min-height: 2.5rem;
`;
