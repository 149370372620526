import { AnimatePresence } from 'framer-motion';

import { VerificationDocumentProvider } from '../shared/context/VerificationDocumentContext';

import { Base } from './steps/Base';

type Props = {
  isActive: boolean;
  closeModal: () => void;
};

export const IdentityProof = ({ isActive, closeModal }: Props) => {
  return (
    <VerificationDocumentProvider>
      <AnimatePresence>{isActive && <Base closeModal={closeModal} />}</AnimatePresence>
    </VerificationDocumentProvider>
  );
};
