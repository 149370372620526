import { UserRole } from '../../../../../enums/userRole';

export type UserTypeRegistrationData = {
  title: UserRole;
  description: string;
};

export const registrationFirstStepData: UserTypeRegistrationData[] = [
  {
    title: UserRole.PRIVATE,
    description: 'registration.privateDescription',
  },
  {
    title: UserRole.CORPORATE,
    description: 'registration.corporateDescription',
  },
];
