import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import { PaymentWizardContainer } from '@metaswiss/ui-kit/src/components/organism/payment-wizard/paymentWizard.styles';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Wrapper = styled(motion.div)`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: fit-content;
  position: relative;
  overflow-x: hidden;
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const PaymentFlowContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  padding: 1.25rem;
  background-color: ${({ theme }) => theme.v2.surface.field};
  overflow-y: auto;
  z-index: 1;

  @media ${breakpoints.desktopSm} {
    position: fixed;
    padding: 0;
    width: 50%;
    justify-content: flex-end;
    flex-wrap: wrap;
    z-index: 5;

    ${({ theme }) =>
      theme.v2.tenant === 'MetaSwiss' &&
      `
       &::before {
        display: block;
        width: 51rem;
        height: 51rem;
        border-radius: 50%;
        content: '';
        filter: blur(12.125rem);
        background-color: ${theme.v2.colors.primary100};
        position: fixed;
        left: -25.93%;
        top: -40.31%;
        transform: translate3d(0, 0, 0);
        opacity: 0.3;
        }
      `}
    ${PaymentWizardContainer} {
      padding: 3.5rem 0;
      margin: 0 1.25rem;
      z-index: 6;
    }
  }

  @media ${breakpoints.desktop2K} {
    align-items: center;
  }
`;

export const PaymentBillContainer = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  @media ${breakpoints.desktopSm} {
    padding: 1rem;
    overflow-y: auto;
    top: 0;
    left: 50%;
    min-height: 100%;
    height: auto;
    width: 50%;
    padding: 3.5rem 1.25rem;
  }

  @media ${breakpoints.desktop2K} {
    display: flex;
    align-items: center;
    height: 100%;
  }
`;

export const MetaSwissBacgroundGradient = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.v2.surface.secondary};

  &::before {
    display: block;
    width: 51rem;
    height: 51rem;
    border-radius: 50%;
    content: '';
    filter: blur(12.125rem);
    background-color: ${({ theme }) => theme.v2.colors.secondary100};
    position: fixed;
    left: 69.375%;
    top: 53.52%;
    transform: translate3d(0, 0, 0);
    opacity: 0.3;
  }

  z-index: 0;
`;

export const BackgroundGradient = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary.hue0};

  &::before {
    display: block;
    width: 43.125rem;
    height: 43.125rem;
    border-radius: 50%;
    content: '';
    filter: blur(12.125rem);
    background-color: ${({ theme }) => theme.colors.warning.hue50};
    position: fixed;
    left: 67.1875%;
    bottom: 88.6047%;
    transform: translate3d(0, 0, 0);
  }

  &::after {
    display: block;
    width: 51rem;
    height: 51rem;
    border-radius: 50%;
    content: '';
    filter: blur(12.125rem);
    background-color: ${({ theme }) => theme.colors.primary.hue200};
    position: fixed;
    right: 40.2083%;
    top: 104.375%;
    transform: translate3d(0, 0, 0);
  }

  z-index: 0;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const DeliveryAddressForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
