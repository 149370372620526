/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NftTransactionV5Response } from '../models/NftTransactionV5Response';
import type { PageableItems } from '../models/PageableItems';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NftTransactionsApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param id
   * @returns NftTransactionV5Response Get NFT Transaction by id
   * @throws ApiError
   */
  public getNftTransactionById(
    id: string,
  ): CancelablePromise<NftTransactionV5Response> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/nft-transactions/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param offset
   * @param limit
   * @param page
   * @param language
   * @param startDate
   * @param endDate
   * @returns any Get all Invoices for accepted NFT Transactions
   * @throws ApiError
   */
  public getAcceptedNftInvoices(
    offset?: number,
    limit?: number,
    page?: number,
    language?: string,
    startDate?: string,
    endDate?: string,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/nft-transactions/accepted/invoices',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'language': language,
        'startDate': startDate,
        'endDate': endDate,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
