import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { api } from '../../api/msApi';
import { routes } from '../../router/routes';

type CheckedResetPasswordTokenProps = {
  resetToken: string;
};

export const useCheckResetTokenPassword = ({ resetToken }: CheckedResetPasswordTokenProps) => {
  const navigate = useNavigate();

  const {
    mutate: checkIsTokenValid,
    isLoading,
    isIdle,
  } = useMutation({
    mutationFn: (token: string) => {
      return api.auth.checkResetPasswordWithToken({
        token: token,
      });
    },
    onError: () => {
      navigate(routes.expiredPage, { replace: true });
    },
  });

  useEffect(() => {
    if (resetToken) {
      checkIsTokenValid(resetToken);
    }
  }, [checkIsTokenValid, resetToken]);

  return { isLoading, isIdle };
};
