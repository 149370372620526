import { motion } from 'framer-motion';
import { FC, useMemo } from 'react';

import { useAuthContext } from '../auth-context/authContext';

import { swipePageAnimation } from './animations';

type Props = {
  children: React.ReactNode;
  centerContent?: boolean;
};

export const AnimationContainer: FC<Props> = ({ children, centerContent = false }) => {
  const animationDirection = useAuthContext((state) => state.animationDirection);

  const wrapperStyles = useMemo(
    () =>
      centerContent
        ? {
            height: '100%',
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }
        : {},
    [centerContent]
  );

  return (
    <motion.div
      variants={swipePageAnimation(animationDirection)}
      initial="hidden"
      animate="visible"
      exit="exit"
      style={wrapperStyles}
      transition={{
        type: 'spring',
        mass: 0.85,
        stiffness: 529,
        damping: 36,
      }}
    >
      {children}
    </motion.div>
  );
};
