import { Chip, Heading, Text } from '@metaswiss/ui-kit';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { getTransactionChip } from '../../helpers/getTransactionChip';

import { ChipWrapper, HeadingAmountWrapper, LayoutHeaderContainer } from './layoutHeader.styles';
import { LayoutHeaderProps } from './types';

export const LayoutHeader = ({ amount, date, transactionStatus }: LayoutHeaderProps) => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();

  const status = useMemo(() => getTransactionChip(transactionStatus ?? ''), [transactionStatus]);

  return (
    <LayoutHeaderContainer>
      <Text color={theme.v2.text.disabled}>{textTranslation('account.totalAmount')}</Text>
      <HeadingAmountWrapper>
        <Heading headingType={'h4'} fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
          {amount}
        </Heading>
      </HeadingAmountWrapper>
      <Text fontSize="sm" lineHeight="medium" color={theme.v2.text.disabled}>
        {date}
      </Text>
      {transactionStatus && (
        <ChipWrapper>
          <Chip label={textTranslation(`chipStatus.${transactionStatus.toLocaleLowerCase()}`)} status={status} />
        </ChipWrapper>
      )}
    </LayoutHeaderContainer>
  );
};
