import styled from 'styled-components';

import { breakpoints } from '../../../breakpoints';

type Props = {
  $numColumns: number | 'auto-fill';
  $numberOfColumnsOnSmallerDevices?: number;
};

export const Wrapper = styled.div<Props>`
  gap: 1rem;
  max-width: 77.5rem;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: ${({ $numColumns, $numberOfColumnsOnSmallerDevices }) =>
    $numColumns === 'auto-fill'
      ? `repeat(${$numColumns}, minmax(11.75rem, 1fr))`
      : `repeat(${$numberOfColumnsOnSmallerDevices}, 1fr)`};

  @media ${breakpoints.laptopSm} {
    grid-template-columns: ${({ $numColumns }) => `repeat(${$numColumns}, minmax(11.75rem, 1fr))`};
  }
`;

export const SingleColumnWrapper = styled.div`
  display: grid;
  gap: 1rem;
  grid-column: 1 / -1;
`;
