import { FC } from 'react';

import { IconProps } from './iconProps';

export const BarChart: FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.40039 21.1998V8.39981M8.80039 21.1998V13.7331M15.2004 21.1998V3.7998M21.6004 21.1998V8.39981"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
