import { IconProps } from './iconProps';

export const SearchIcon: React.FC<IconProps> = ({ color, strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 25 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4269 17.0401L20.9 20.4001M19.78 11.4401C19.78 15.77 16.2699 19.2801 11.94 19.2801C7.61009 19.2801 4.10001 15.77 4.10001 11.4401C4.10001 7.11019 7.61009 3.6001 11.94 3.6001C16.2699 3.6001 19.78 7.11019 19.78 11.4401Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
