import { FC } from 'react';
import { ClipLoader } from 'react-spinners';

import { SpinnerContainer } from './spinner.styles.ts';

type SpinnerProps = {
  isActive: boolean;
  color?: string;
};

export const LocalSpinner: FC<SpinnerProps> = ({ isActive, color = '#3381FF' }) => {
  return (
    <>
      {isActive && (
        <SpinnerContainer>
          <ClipLoader color={color} />
        </SpinnerContainer>
      )}
    </>
  );
};
