import styled from 'styled-components';

export const StyledInputNumberWrapper = styled.button<{ $isLocked: boolean }>`
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 2;
  position: absolute;
  left: 1.25rem;
  pointer-events: ${({ $isLocked }) => $isLocked && 'none'};

  &::after {
    content: '';
    display: ${({ $isLocked }) => $isLocked && 'none'};
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1.5rem;
    width: 1px;
    background-color: ${({ theme }) => theme.v2.border.primaryHover};
  }
`;

export const StlyedIconNumberWrapper = styled.div<{ $isLocked: boolean; $isPrefixSelected: boolean }>`
  width: ${({ $isLocked }) => ($isLocked ? '0' : '2rem')};
  display: ${({ $isLocked }) => $isLocked && 'none'};
  height: 1.5rem;
  margin-right: 0.75rem;
  transition: all 0.4s;
`;

export const StyledIconNumber = styled.img<{ $isLocked: boolean }>`
  opacity: ${({ $isLocked }) => ($isLocked ? '0' : '1')};
  transition: opacity 0.2s;
`;

export const StyledNumbers = styled.div<{ disabled: boolean }>`
  margin-right: 0.75rem;
  color: ${({ disabled, theme }) => (disabled ? theme.v2.text.disabled : theme.v2.text.headingPrimary)};
  font-weight: ${({ theme }) => theme.typography.weights.semi};
`;
