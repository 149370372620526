import styled from 'styled-components';

export const Container = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: fit-content;
  margin: 0 auto;
`;

export const CardsContainer = styled.div`
  gap: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
`;

export const CardWrapper = styled.div`
  width: 100%;
`;
