/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns any Generate pdf
   * @throws ApiError
   */
  public previewDocument(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/product/pdf',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
