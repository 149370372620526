import { UserResponse } from '@metaswiss/api';
import { Avatar, Button, Popup, Text } from '@metaswiss/ui-kit';
import { UserStatus } from '@metaswiss/ui-kit/src/components/organism/profile-dropdown/types';
import { useQuery } from '@tanstack/react-query';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../api/msApi';
import { AssignedUserAssetEnum } from '../../enums/assignedUserAssetEnum';
import { ApiResource } from '../../enums/resource.enum';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../router/routes';
import { findAssetByType } from '../../shared/helpers/findAssetByType.helper';
import { getQueryKey } from '../../shared/helpers/getQueryKey.helper';

import { ButtonWrapper, OfferingPopupText, OfferingPopupWrapper } from './offeringPopup.styles';

type OfferingPopupProps = {
  user: UserResponse | undefined;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const OfferingPopup: FC<OfferingPopupProps> = ({ user, isOpen, setIsOpen }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { textTranslation } = useTextTranslation();
  const isUserPrivate = user?.role === 'private';

  const { data: assets } = useQuery({
    queryKey: getQueryKey(ApiResource.ASSET, user?.id),
    queryFn: () => {
      if (isUserPrivate) {
        return api.users.getPrivateUserAssets(user!.id);
      } else {
        return api.users.getCorporateUserAssets(user!.id);
      }
    },
    enabled: !!user?.role && !!user?.id,
  });

  const thumbnail = useMemo(() => {
    return findAssetByType(assets, AssignedUserAssetEnum.THUMBNAIL);
  }, [assets]);

  const { data: thumbnailUrl } = useQuery({
    queryKey: getQueryKey(ApiResource.ASSET, `${AssignedUserAssetEnum.THUMBNAIL}/${user?.id}`),
    queryFn: () => {
      if (thumbnail) {
        return api.assets.getS3SignedAssignedUrl({ assetId: thumbnail.id });
      }
    },
    enabled: !!user?.id && !!assets && !!thumbnail,
  });

  const getUserStatus = (isFullyRegistered?: boolean, status?: 'APPROVED' | 'PENDING' | 'SUSPENDED'): UserStatus =>
    !isFullyRegistered
      ? UserStatus.NOT_FULLY_REGISTERED
      : status === 'PENDING'
        ? UserStatus.PENDING
        : UserStatus.FULLY_REGISTERED;

  const userStatus = useMemo(() => {
    return getUserStatus(user?.isFullyRegistered, user?.status);
  }, [user]);

  return (
    <Popup isActive={isOpen} height={'fit-content'} maxWidth={'32.5rem'}>
      <OfferingPopupWrapper>
        <Avatar imagePath={thumbnailUrl?.url} size="regular" />
        <OfferingPopupText>
          <Text fontSize="lg" fontWeight="bold" lineHeight="h4" color={theme.v2.text.headingPrimary}>
            {userStatus === UserStatus.PENDING
              ? textTranslation('offering.notApprovedTitle')
              : textTranslation('offering.notFullyRegisteredTitle')}
          </Text>
          <Text fontWeight="semi" textColor="hue400" color={theme.v2.text.disabled}>
            {userStatus === UserStatus.PENDING
              ? textTranslation('offering.notApprovedMsg')
              : textTranslation('offering.notFullyRegisteredMsg')}
          </Text>
        </OfferingPopupText>
      </OfferingPopupWrapper>
      <ButtonWrapper>
        {userStatus === UserStatus.NOT_FULLY_REGISTERED && (
          <Button
            color="warning"
            text={textTranslation('offering.completeReg')}
            fill
            onClick={() => navigate(routes.account.root)}
          />
        )}
        <Button
          variant="outlined"
          color="neutral"
          text={textTranslation('home.cancel')}
          fill
          onClick={() => setIsOpen(!isOpen)}
        />
      </ButtonWrapper>
    </Popup>
  );
};
