import { FC, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useSetRouteNavigation } from '../../../hooks/use-set-route-navigation/useSetRouteNavigation';
import { useTextTranslation } from '../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../router/routes';

import { portfolioTabs } from './values/portfolioTabs.values';

export const Portfolio: FC = () => {
  const { textTranslation } = useTextTranslation();
  const { pathname } = useLocation();

  const portfolioTabsWithTranslation = useMemo(
    () =>
      portfolioTabs.map((tab) => ({
        ...tab,
        label: textTranslation(`portfolio.${tab.value}`),
      })),
    []
  );

  const isPortfolioDeepNestedRoute = useMemo(() => pathname.split('/').length > 3, [pathname]);

  useSetRouteNavigation(
    portfolioTabsWithTranslation,
    !isPortfolioDeepNestedRoute ? routes.portfolio.overview : undefined
  );

  return <Outlet />;
};
