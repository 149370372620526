import { IconProps } from './iconProps';

export const File04Icon: React.FC<IconProps> = ({ strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.5004 4.40002V11C27.5004 12.2151 28.4854 13.2 29.7004 13.2H36.3004M33.0004 7.70002C32.0213 6.82395 31.0052 5.78488 30.3638 5.11003C29.937 4.66097 29.3472 4.40002 28.7277 4.40002H12.0999C9.66984 4.40002 7.69989 6.36996 7.69988 8.8L7.69971 35.1999C7.69969 37.63 9.66963 39.5999 12.0997 39.5999L31.8998 39.6C34.3298 39.6 36.2997 37.6301 36.2998 35.2001L36.3004 11.8761C36.3004 11.3135 36.0858 10.7728 35.6954 10.3678C34.9734 9.61888 33.7678 8.38664 33.0004 7.70002Z"
        stroke={strokeColor}
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
