import styled from 'styled-components';

export const IconContainer = styled.div<{ $size: string }>`
  display: inline-block;
  vertical-align: top;
  justify-content: center;
  align-items: center;
  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};

  > svg {
    width: 100%;
    height: 100%;
  }
`;
