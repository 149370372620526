import { motion } from 'framer-motion';
import { FC } from 'react';

import { buttonVariants, buttonTransition } from './animations';

type Props = {
  children: React.ReactNode;
};

export const AnimationButtonWrapper: FC<Props> = ({ children }) => {
  return (
    <motion.div
      style={{ width: '100%' }}
      variants={buttonVariants}
      transition={buttonTransition}
      initial="hidden"
      animate="visible"
      exit="exit"
      layout
    >
      {children}
    </motion.div>
  );
};
