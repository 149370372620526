import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { useHeaderOptions } from '../../global-state/zustand';

type UsePaginationProps = {
  queryKey: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryFn: (offset: number, limit: number) => any;
  onError?: ((err: unknown) => void) | undefined;
  enabled: boolean;
  limit?: number;
  retry?: boolean;
};

type Page<T> = {
  items: T[];
  totalItems: number;
  totalPages: number;
  page: number;
};

export const usePagination = <T>({
  queryFn,
  enabled,
  queryKey,
  limit = 12,
  onError,
  retry = true,
}: UsePaginationProps) => {
  const [fetchingPoints, setFetchingPoints] = useState<boolean[]>([]);
  const { selectedFilters } = useHeaderOptions();
  const queryClient = useQueryClient();
  const [ref, inView, entry] = useInView({
    threshold: 0,
  });

  const { fetchNextPage, remove, refetch, hasNextPage, isFetchingNextPage, isLoading, isError, data } =
    useInfiniteQuery<Page<T>, unknown, Page<T>, string[]>({
      queryKey,
      queryFn: ({ pageParam = 0 }) => queryFn(pageParam * limit, limit),
      getNextPageParam: (lastPage) => (lastPage.page !== lastPage.totalPages ? lastPage.page : undefined),
      enabled,
      onError,
      retry: retry,
    });

  useEffect(() => {
    if (entry) {
      setFetchingPoints((prev) => [...prev, false]);
    }
  }, [entry]);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage && fetchingPoints.length && !fetchingPoints.at(-1)) {
      setFetchingPoints((prev) => [...prev.slice(0, -1), true]);
      fetchNextPage();
    }
  }, [fetchNextPage, fetchingPoints, hasNextPage, inView, isFetchingNextPage]);

  useEffect(() => {
    if (selectedFilters.length) {
      queryClient.removeQueries({ queryKey: [queryKey[0]], type: 'inactive' });
    }
  }, [selectedFilters.length, queryClient, queryKey]);

  useEffect(() => () => remove(), [remove]);

  const preparedData = useMemo(
    () => ({
      items: data?.pages?.reduce((acc: T[], page) => acc.concat(page.items), []) || [],
      totalItems: data?.pages?.[data.pages.length - 1]?.totalItems || 0,
    }),
    [data?.pages]
  );

  return {
    data: preparedData.items,
    totalItems: preparedData.totalItems,
    isLoading,
    isError,
    limit,
    isFetchingNextPage,
    lastItemRef: ref,
    fetchNextPage,
    refetch,
  };
};
