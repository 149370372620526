import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import styled from 'styled-components';

export const StyledContainer = styled.div<{ $fitHeight: boolean }>`
  height: ${({ $fitHeight }) => ($fitHeight ? '100%' : 'auto')};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ContentContainer = styled.div`
  height: 100%;
  width: 100%;

  @media ${breakpoints.laptopSm} {
    overflow: hidden;
  }
`;
