import { ItemProps } from '@metaswiss/ui-kit';

export const composeSelectionOptions = <T>(
  items: T[],
  valueProp: keyof T,
  labelProp: keyof T,
  codeProp?: keyof T,
  metaProp?: keyof T
): ItemProps[] => {
  return items.map((item) => {
    const mappedOptionItem = {
      value: item[valueProp] as string,
      label: item[labelProp] as string,
      meta: metaProp ? (item[metaProp] as string) : undefined,
    };

    const iconSrc = codeProp ? `../../../assets/flags/${(item[codeProp] as string).toLowerCase()}.svg` : undefined;

    return {
      ...mappedOptionItem,
      iconSrc,
    };
  });
};
