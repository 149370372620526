import { FC, useEffect } from 'react';

import { AnimationContainer } from '../shared/animations/AnimationContainer';
import { useAuthContext } from '../shared/auth-context/authContext';

import { FirstStep } from './steps/first-step/FirstStep';
import { ForgotPasswordFailed } from './steps/forgot-password-failed/ForgotPasswordFailed';
import { ForgotPasswordSuccess } from './steps/forgot-password-success/ForgotPasswordSuccess';
import { SecondStep } from './steps/second-step/SecondStep';
import { ThirdStep } from './steps/third-step/ThirdStep';

export const ForgotPassword: FC = () => {
  const { forgotPasswordStep, setForgotPasswordStep } = useAuthContext((state) => state);

  useEffect(() => {
    return () => {
      setForgotPasswordStep(1);
    };
  }, [setForgotPasswordStep]);

  return (
    <AnimationContainer key={forgotPasswordStep}>
      {forgotPasswordStep === 1 && <FirstStep />}
      {forgotPasswordStep === 2 && <SecondStep />}
      {forgotPasswordStep === 3 && <ThirdStep />}
      {forgotPasswordStep === 4 && <ForgotPasswordFailed />}
      {forgotPasswordStep === 5 && <ForgotPasswordSuccess />}
    </AnimationContainer>
  );
};
