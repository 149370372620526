import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import { styled } from 'styled-components';

export const ClientStartBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex-shrink: 1;
`;

export const ClientCenterBlockContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  max-width: 100%;
  height: 2.5rem;
  flex-grow: 1;
  gap: 1rem;
`;

export const ClientInputContainer = styled.div`
  width: 100%;

  @media ${breakpoints.tabletMd} {
    width: 20rem;
  }
`;

export const EndBlockWrapper = styled.div`
  display: flex;
  height: 1.25rem;
  align-items: center;
  gap: 0.5rem;
`;

export const FilterWrapper = styled.div`
  margin-right: 0.5rem;
`;

export const EndBlockItem = styled.div`
  width: fit-content;
  flex-shrink: 0;
`;
