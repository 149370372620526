import styled from 'styled-components';

import { Padding } from '../../../../theme/theme.ts';

export type BorderStyle =
  | 'solid'
  | 'dashed'
  | 'dotted'
  | 'double'
  | 'groove'
  | 'ridge'
  | 'inset'
  | 'outset'
  | 'none'
  | 'hidden';

export const BaseCardV2Container = styled.div<{
  $borderWidth: number;
  $borderColor?: string;
  $borderRadius?: string;
  $hasBoxShadow: boolean;
  $padding: Padding;
  $width: 'fit-content' | '100%' | 'auto';
  $hasCursorPointer: boolean;
  $borderStyle: BorderStyle;
  $disableSelectorStyles: boolean;
  $backgroundColor?: string;
}>`
  position: relative;
  padding: ${({ $padding }) => $padding};
  background-color: ${({ theme, $backgroundColor }) => $backgroundColor || theme.v2.surface.primary};
  border-radius: ${({ $borderRadius, theme }) => $borderRadius ?? theme.v2.componentsRadius.cards.default};
  border: ${({ $borderColor, $borderWidth, $borderStyle, theme }) =>
    `${$borderWidth}px ${$borderStyle} ${$borderColor ?? theme.v2.border.primary}`};
  width: ${({ $width }) => $width};
  ${({ $hasBoxShadow }) =>
    $hasBoxShadow && 'box-shadow: 0px 0px 16px 0px #00000005, 0px 1px 0px 0px #00000005, 0px 10px 24px 0px #00000005'};

  cursor: ${({ $hasCursorPointer }) => $hasCursorPointer && 'pointer'};

  &:hover {
    ${({ $disableSelectorStyles }) =>
      !$disableSelectorStyles &&
      `
      box-shadow: 0px 0px 16px 0px #00000005, 0px 1px 0px 0px #00000005, 0px 10px 24px 0px #00000005;
      `}
  }

  &:active {
    ${({ $disableSelectorStyles }) =>
      !$disableSelectorStyles &&
      `
        box-shadow: none;
      `}
  }
`;
