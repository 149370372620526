import styled from 'styled-components';

type Props = {
  $transition: boolean;
  $disableFloat: boolean;
  $isError: boolean;
  $isLocked: boolean;
  $hasValue: boolean;
  $isIconShown: boolean;
  $isDisabled: boolean;
};

export const Label = styled.label<Props>`
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'auto' : 'pointer')};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s ease-out;
  line-height: ${({ theme }) => theme.typography.lineHeight.base};
  color: ${({ theme, $isError, $isLocked, $isDisabled }) =>
    $isError ? theme.v2.text.error : ($isLocked || $isDisabled) && theme.v2.text.disabled};
  left: ${({ $hasValue, $isIconShown, $isLocked }) => ($isIconShown && !$hasValue && $isLocked ? '3.5rem' : 'auto')};
  ${({ $transition, $disableFloat, $isLocked, $hasValue, theme }) => {
    if ($transition && !$disableFloat && $hasValue) {
      return `
        display: block;
        top: 0%;
        transform: ${$isLocked ? 'translate(0, -50%)' : 'translate(0.25rem, -50%)'};
        font-size: ${theme.typography.sizes.xsm};
      `;
    } else if ($transition && $disableFloat) {
      return `
        opacity: 0;
      `;
    }
  }}
`;
