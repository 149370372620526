import throttle from 'lodash/throttle';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { ThemedIconProps } from '../../../iconography/iconProps';
import { ColorPalette, RemSize, TypographyTextSize } from '../../../theme/theme';
import { Text } from '../text';

import {
  BreadcrumbLabel,
  BreadcrumbsContainer,
  BreadcrumbsItem,
  ComponentContainer,
  IconContainer,
} from './breadcrumb.styles';
import { BreadcrumbType } from './types';

export type Props = {
  breadcrumbs: BreadcrumbType[];
  onSelect: (path: string) => void;
  palette?: ColorPalette;
  renderIcon: React.FC<ThemedIconProps>;
  fontSize: TypographyTextSize;
  iconSize: RemSize;
};

export const Breadcrumb: React.FC<Props> = ({
  breadcrumbs,
  onSelect,
  palette = 'neutrals',
  renderIcon,
  fontSize = 'base',
  iconSize = '1.5rem',
}) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const breadcrumbRef = useRef<HTMLDivElement | null>(null);
  const breakingWidth = useRef<number[]>([]);
  const [overflowItems, setOverflowItems] = useState<BreadcrumbType[]>([]);
  const [afterOverflowItems, setAfterOverflowItems] = useState<BreadcrumbType[]>(breadcrumbs.slice(1));

  useEffect(() => {
    setOverflowItems([]);
    setAfterOverflowItems(breadcrumbs.slice(1));
  }, [breadcrumbs]);

  const handleResize = useCallback(() => {
    const neededWidth = breadcrumbRef.current?.clientWidth;
    const availableWidth = containerRef.current?.clientWidth;

    if (!neededWidth || !availableWidth || !afterOverflowItems || !overflowItems) {
      return;
    }

    // list is overflowing
    if (neededWidth > availableWidth) {
      // record breaking width
      if (afterOverflowItems.length > 1) {
        breakingWidth.current.push(neededWidth);
        setOverflowItems((prevOverflow) => [...prevOverflow, afterOverflowItems[0]]);
        setAfterOverflowItems((prevAfterOverflow) => prevAfterOverflow.slice(1));
      }
      // check if we resized past breaking width
    } else if (
      breakingWidth.current[breakingWidth.current.length - 1] >= 0 &&
      availableWidth > breakingWidth.current[breakingWidth.current.length - 1] &&
      overflowItems.length > 0
    ) {
      // move item to the visible list
      setAfterOverflowItems((prevAfterOverflowItems) => [
        overflowItems[overflowItems.length - 1],
        ...prevAfterOverflowItems,
      ]);
      setOverflowItems((prevOverFlowItems) => prevOverFlowItems.slice(0, -1));
      breakingWidth.current.pop();
    }
  }, [containerRef, afterOverflowItems, overflowItems]);

  const throttleHandleResize = throttle(handleResize, 20);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', throttleHandleResize);

    return () => {
      window.removeEventListener('resize', throttleHandleResize);
    };
  }, [containerRef.current?.clientWidth, breadcrumbRef.current?.clientWidth, throttleHandleResize, handleResize]);

  const icon = useMemo(() => renderIcon({}), [renderIcon]);

  return (
    <ComponentContainer ref={containerRef}>
      <BreadcrumbsContainer ref={breadcrumbRef}>
        <BreadcrumbsItem>
          <BreadcrumbLabel $palette={palette} onClick={() => onSelect(breadcrumbs[0]?.path)}>
            <Text
              color={breadcrumbs.length === 1 ? theme.v2.text.bodyPrimary : theme.v2.text.disabled}
              fontSize={fontSize}
              lineHeight="extraSmall"
            >
              {breadcrumbs[0]?.label}
            </Text>
          </BreadcrumbLabel>
          {breadcrumbs.length > 1 && <IconContainer $size={iconSize}>{icon}</IconContainer>}
        </BreadcrumbsItem>

        {overflowItems.length !== 0 && (
          <BreadcrumbsItem>
            <Text palette={palette} color={theme.v2.text.disabled} fontSize={fontSize}>
              ...
            </Text>
            <IconContainer $size={iconSize}>{icon}</IconContainer>
          </BreadcrumbsItem>
        )}

        {afterOverflowItems.map((item, index) => {
          return (
            <BreadcrumbsItem key={`breadcrumb-${index + 1}`}>
              <BreadcrumbLabel $palette={palette} onClick={() => onSelect(item.path)}>
                <Text
                  color={index === afterOverflowItems.length - 1 ? theme.v2.text.bodyPrimary : theme.v2.text.disabled}
                  fontSize={fontSize}
                  lineHeight="extraSmall"
                >
                  {item.label}
                </Text>
              </BreadcrumbLabel>
              {index !== afterOverflowItems.length - 1 && <IconContainer $size={iconSize}>{icon}</IconContainer>}
            </BreadcrumbsItem>
          );
        })}
      </BreadcrumbsContainer>
    </ComponentContainer>
  );
};
