import { NftExtendedV5Response } from '@metaswiss/api';
import { ErrorDialog, Text, usePaymentWizard } from '@metaswiss/ui-kit';
import { TransactionStatus } from '@metaswiss/ui-kit/src/components/molecules/cards/transaction-card/enums';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
  StripeCardCvcElementOptions,
  StripeCardExpiryElementOptions,
  StripeCardNumberElementOptions,
  StripeElementChangeEvent,
  StripeElementStyle,
} from '@stripe/stripe-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { unstable_useBlocker, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi';
import { LoaderContainer } from '../../../../../components/loader-container/LoaderContainer';
import { PaymentMethod } from '../../../../../enums/paymentMethod.enum';
import { ApiResource } from '../../../../../enums/resource.enum';
import { StripeInputType } from '../../../../../enums/stripeInputType';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../router/routes';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper';
import { usePaymentContext } from '../../context/PaymentContext';

import {
  FullWidthInput,
  StripeInputLabel,
  StripeInputWrapper,
  StripePaymentFormContainer,
} from './creditCardPaymentForm.styles';

export const StripeCardPaymentForm = () => {
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    changeNextStepOnClick,
    isButtonLoading,
    setIsButtonLoading,
    setIsNextStepDisabled,
    changePrevStepOnClick,
    setActiveStep,
  } = usePaymentWizard();
  const { textTranslation } = useTextTranslation();
  const { clientSecret, item, productType, transactionId, paymentIntentId, setIsError, currency, setClientSecret } =
    usePaymentContext();

  const [cardNumberErrorMessage, setCardNumberErrorMessage] = useState<string>('');
  const [expiryErrorMessage, setExpiryErrorMessage] = useState<string>('');
  const [cvcErrorMessage, setCvcErrorMessage] = useState<string>('');
  const [isPaymentFailed, setIsPaymentFailed] = useState<boolean>(false);
  const [isBackButtonClicked, setIsBackButtonClicked] = useState<boolean>(false);
  const [isRedirectionBlocked, setIsRedirectionBlocked] = useState<boolean>(true);

  unstable_useBlocker(isRedirectionBlocked);

  const { mutate: cancelPaymentIntent } = useMutation({
    mutationFn: () => api.payment.cancelStripePayment({ paymentIntentId }),
    onSuccess: () => {
      setActiveStep(2);
      setClientSecret('');
    },
    onError: () => {
      setIsError(true);
    },
  });

  useEffect(() => {
    const handlePaymentRequest = () => {
      setIsNextStepDisabled(true);

      if (stripe && clientSecret) {
        const cardNumberStripeElement = elements?.getElement(CardNumberElement);

        if (!cardNumberStripeElement) {
          setIsPaymentFailed(true);
          return;
        }

        setIsButtonLoading(true);

        stripe
          .confirmCardPayment(clientSecret, {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            payment_method: {
              card: cardNumberStripeElement,
            },
          })
          .then((response) => {
            if (response.error) {
              setIsPaymentFailed(true);
            } else {
              setIsRedirectionBlocked(false);
            }
          })
          .catch(() => {
            setIsPaymentFailed(true);
          })
          .finally(() => {
            setIsButtonLoading(false);
            setIsNextStepDisabled(false);
          });
      } else {
        setIsPaymentFailed(true);
      }
    };
    changeNextStepOnClick(handlePaymentRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    elements,
    item,
    navigate,
    productType,
    transactionId,
    stripe,
    clientSecret,
    setIsButtonLoading,
    setIsNextStepDisabled,
  ]);

  useEffect(() => {
    const handleOnBackButtonClick = async () => {
      await cancelPaymentIntent();
    };

    changePrevStepOnClick(handleOnBackButtonClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStripeInputOnChange = (e: StripeElementChangeEvent, inputType: StripeInputType) => {
    const inputError = e.error?.message || '';

    switch (inputType) {
      case StripeInputType.CARD_NUMBER:
        setCardNumberErrorMessage(inputError);
        break;
      case StripeInputType.EXPIRY:
        setExpiryErrorMessage(inputError);
        break;
      case StripeInputType.CVC:
        setCvcErrorMessage(inputError);
        break;
      default:
        throw new Error('Wrong input type selected!');
    }
  };

  const handleErrorDialogClose = () => {
    setIsPaymentFailed(false);
  };

  const handleBackButtonClick = () => {
    setIsBackButtonClicked(true);
  };

  useEffect(() => {
    window.addEventListener('popstate', handleBackButtonClick);

    return () => {
      const nftItem = item as NftExtendedV5Response;
      queryClient.invalidateQueries(getQueryKey(ApiResource.NFT, nftItem.id));
      queryClient.invalidateQueries(getQueryKey(ApiResource.NFT, nftItem.nftCollection.id));
      queryClient.invalidateQueries(getQueryKey(ApiResource.COLLECTIBLES, nftItem.nftCollection.id));
      queryClient.invalidateQueries(getQueryKey(ApiResource.NFT_COLLECTION_STATISTICS, nftItem.nftCollection.id));
      queryClient.invalidateQueries(getQueryKey(ApiResource.PORTFOLIO));
      queryClient.invalidateQueries(getQueryKey(ApiResource.USER_INVESTED_PORTFOLIO));

      window.removeEventListener('popstate', handleBackButtonClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isBackButtonClicked) {
      cancelPaymentIntent();
    }
  }, [isBackButtonClicked, cancelPaymentIntent]);

  useEffect(() => {
    if (!isRedirectionBlocked && !isBackButtonClicked) {
      const nft = item as NftExtendedV5Response;
      const amount = nft.nftCollection.price;

      navigate(routes.payment.success, {
        state: {
          item: item,
          productType: productType,
          paymentMethod: PaymentMethod.STRIPE,
          paymentResponse: {
            fiat: amount,
            totalAmount: amount,
            quantity: 1,
            status: TransactionStatus.ACCEPTED,
            transactionId: transactionId,
          },
          currency,
        },
        replace: true,
      });
    }
  }, [isRedirectionBlocked, isBackButtonClicked, navigate, currency, item, productType, transactionId]);

  const stripeStyles = useMemo(() => {
    const stripeInputStyles: StripeElementStyle = {
      base: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        backgroundColor: theme.v2.surface.field,
        fontWeight: '300',
        color: theme.v2.text.bodyPrimary,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ':disabled': {
          backgroundColor: theme.v2.surface.disabled,
          color: theme.v2.text.disabled,
        },
      },
      empty: {
        color: theme.v2.text.disabled,
      },
      invalid: {
        color: theme.v2.text.error,
      },
    };

    const cardNumberOptions: StripeCardNumberElementOptions = {
      style: stripeInputStyles,
      iconStyle: 'solid',
      placeholder: '1234 1234 1234 1234',
    };

    const cardExpiryOptions: StripeCardExpiryElementOptions = {
      style: stripeInputStyles,
      placeholder: 'MM / YY',
    };

    const cardCvcOptions: StripeCardCvcElementOptions = {
      style: stripeInputStyles,
      placeholder: 'CVC',
    };

    return { cardNumberOptions, cardExpiryOptions, cardCvcOptions };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!elements || !stripe) return <LoaderContainer />;

  return (
    <>
      <ErrorDialog
        primaryText={textTranslation('global.somethingWentWrong')}
        secondaryText={textTranslation('payment.checkCardDetails')}
        primaryButtonText={textTranslation('global.tryAgain')}
        close={handleErrorDialogClose}
        onPrimaryButtonClick={handleErrorDialogClose}
        isOpen={isPaymentFailed}
      />
      <StripePaymentFormContainer>
        <FullWidthInput>
          <StripeInputLabel>
            <Text fontSize={'sm'} lineHeight={'medium'}>
              {textTranslation('payment.cardNumber')}
            </Text>
            <StripeInputWrapper $isError={!!cardNumberErrorMessage}>
              <CardNumberElement
                options={{
                  style: { ...stripeStyles.cardNumberOptions.style },
                  showIcon: !isButtonLoading,
                  disabled: isButtonLoading,
                }}
                onChange={(e) => handleStripeInputOnChange(e, StripeInputType.CARD_NUMBER)}
              />
            </StripeInputWrapper>
            {cardNumberErrorMessage && (
              <Text color={theme.v2.text.error} fontSize={'xxsm'} lineHeight={'extraSmall'}>
                {cardNumberErrorMessage}
              </Text>
            )}
          </StripeInputLabel>
        </FullWidthInput>
        <StripeInputLabel>
          <Text fontSize={'sm'} lineHeight={'medium'}>
            {textTranslation('payment.expiration')}
          </Text>
          <StripeInputWrapper $isError={!!expiryErrorMessage}>
            <CardExpiryElement
              options={{ ...stripeStyles.cardExpiryOptions, disabled: isButtonLoading }}
              onChange={(e) => handleStripeInputOnChange(e, StripeInputType.EXPIRY)}
            />
          </StripeInputWrapper>
          {expiryErrorMessage && (
            <Text color={theme.v2.text.error} fontSize={'xxsm'} lineHeight={'extraSmall'}>
              {expiryErrorMessage}
            </Text>
          )}
        </StripeInputLabel>
        <StripeInputLabel>
          <Text fontSize={'sm'} lineHeight={'medium'}>
            {textTranslation('payment.cvc')}
          </Text>
          <StripeInputWrapper $isError={!!cvcErrorMessage}>
            <CardCvcElement
              options={{ ...stripeStyles.cardCvcOptions, disabled: isButtonLoading }}
              onChange={(e) => handleStripeInputOnChange(e, StripeInputType.CVC)}
            />
          </StripeInputWrapper>
          {cvcErrorMessage && (
            <Text color={theme.v2.text.error} fontSize={'xxsm'} lineHeight={'extraSmall'}>
              {cvcErrorMessage}
            </Text>
          )}
        </StripeInputLabel>
      </StripePaymentFormContainer>
    </>
  );
};
