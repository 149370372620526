import { styled } from 'styled-components';

type YearContainerProps = {
  $isFirst: boolean;
};

export const NewsletterYearsIndicatorContainer = styled.div`
  position: absolute;
  left: 0.325rem;
  height: 100%;
  width: 1px;
  border: 1px dashed ${({ theme }) => theme.v2.border.primaryHover};
`;

export const NewsletterContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 1.125rem;
`;

export const YearBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-block: 1.25rem;
  gap: 1rem;

  &:last-of-type {
    margin-block-start: 1rem;
    margin-block-end: 0;
  }
`;

export const YearContainer = styled.div<YearContainerProps>`
  background-color: ${({ theme, $isFirst }) =>
    $isFirst ? theme.v2.surface.informationLight : theme.v2.surface.secondary};
  font-size: 0;
  padding: 0.5rem 0.75rem;
  border-radius: ${({ theme }) => theme.v2.radius.full};
`;

export const ItemOnTimeline = styled.div`
  display: flex;
  align-items: center;
`;

export const Dot = styled.div<YearContainerProps>`
  background-color: ${({ theme, $isFirst }) => ($isFirst ? theme.v2.icon.primary : theme.v2.border.primaryHover)};
  border-radius: 100%;
  width: 0.75rem;
  height: 0.75rem;
  position: absolute;
  left: 0;
`;
