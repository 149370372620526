import styled, { css } from 'styled-components';

import { StyledBtn } from './btnStyles';

export const SliderWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const DatePickerContainer = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const DatePickerSlider = styled.div<{
  $activeSlide: number;
  $numberOfSlides?: number;
}>`
  display: grid;
  grid-template-columns: repeat(${({ $numberOfSlides }) => ($numberOfSlides ? $numberOfSlides : 3)}, 1fr);
  width: ${({ $numberOfSlides }) => ($numberOfSlides ? `${$numberOfSlides * 100}%` : '300%')};
  transform: translateX(
    ${({ $activeSlide, $numberOfSlides }) => `${$activeSlide * ($numberOfSlides ? 100 / -$numberOfSlides : -33.33)}%`}
  );
  transition: transform 0.5s;

  ${StyledBtn} {
    border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.small};
  }
`;

const ContainerStyles = css`
  display: grid;
  column-gap: 0.25rem;
  row-gap: 0.5rem;
  width: 100%;
`;

export const DaysYearsContainer = styled.div`
  ${ContainerStyles};
  grid-template-columns: repeat(7, minmax(3.25rem, 1fr));
  grid-template-rows: repeat(5, 1fr);
`;

export const MonthsContainer = styled.div`
  ${ContainerStyles};
  grid-template-columns: repeat(3, 1fr);
  height: fit-content;
`;
