import styled from 'styled-components';

import { DropdownHeight } from '../../../../theme/theme';

type Props = {
  selected: boolean;
  $height: DropdownHeight;
};

export const DropdownItem = styled.button<Props>`
  align-self: stretch;
  flex: 1 0 auto;
  padding: ${({ theme, $height }) =>
    $height === 'large'
      ? `${theme.v2.spacing['1']} ${theme.v2.spacing['1.25']}`
      : `${theme.v2.spacing['0.5']} ${theme.v2.spacing['1']}`};
  cursor: pointer;
  display: flex;
  gap: 0.62rem;
  align-items: center;
  justify-content: space-between;
  ${({ selected, theme }) =>
    !selected &&
    `
    &:hover, &:focus {
      background-color: ${theme.v2.surface.tertiary};
    }
  `}
  ${({ theme, selected }) =>
    selected &&
    `
    background-color: ${theme.v2.surface.tertiary};
    font-weight: ${theme.typography.weights.bold};
  `}
`;
