import { Text, ThemedIcon, IconProps } from '@metaswiss/ui-kit';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import {
  BenefitsCardBgIconWrapper,
  BenefitsCardContainer,
  BenefitsCardIconWrapper,
  BenefitsCardItem,
  BenefitsCardList,
  BenefitsCardText,
  BenefitsCardWrapper,
  StyledBenefitsCard,
} from './benefitsCard.styles';

type Props = {
  title: string;
  subTitle: string;
  descriptionList: string[];
  allowOverflow?: boolean;
  icon: FC<IconProps>;
};

export const BenefitsCard: FC<Props> = ({ allowOverflow, title, subTitle, descriptionList, icon }) => {
  const theme = useTheme();
  return (
    <StyledBenefitsCard
      disableStateEffects={true}
      allowOverflow={allowOverflow}
      height={'100%'}
      override={{
        backgroundColor: theme.v2.surface.informationLight,
        borderColor: theme.v2.border.information,
        borderRadius: theme.v2.componentsRadius.cards.default,
      }}
    >
      <BenefitsCardWrapper>
        <BenefitsCardContainer>
          <BenefitsCardIconWrapper>
            <ThemedIcon
              icon={icon}
              palette={'primary'}
              size={'full'}
              customStrokeColor={theme.v2.icon.primary}
              customColor={theme.v2.surface.informationLight}
            />
          </BenefitsCardIconWrapper>
          <BenefitsCardText>
            <Text fontSize="sm" fontWeight="bold" lineHeight="medium" color={theme.v2.text.disabled}>
              {title}
            </Text>
            <Text fontWeight="bold" color={theme.v2.text.headingPrimary}>
              {subTitle}
            </Text>
          </BenefitsCardText>
        </BenefitsCardContainer>
        <BenefitsCardList>
          {descriptionList?.map((item, index: number) => <BenefitsCardItem key={index}>{item}</BenefitsCardItem>)}
        </BenefitsCardList>
      </BenefitsCardWrapper>
      <BenefitsCardBgIconWrapper>
        <ThemedIcon
          icon={icon}
          palette={'primary'}
          size={'full'}
          customStrokeColor={theme.v2.icon.primary}
          customColor={theme.v2.surface.informationLight}
        />
      </BenefitsCardBgIconWrapper>
    </StyledBenefitsCard>
  );
};
