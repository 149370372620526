import { StyledCheckbox } from '@metaswiss/ui-kit/src/components/atoms/checkbox/styles/styledCheckbox';
import styled from 'styled-components';

export const QuickCorporateRegistrationContainer = styled.div`
  display: grid;
  row-gap: 0.5rem;
`;

export const TermsCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${StyledCheckbox} {
    margin-right: 1rem;
  }
`;
