import styled from 'styled-components';

export const RegistrationBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0 1.25rem 0;
`;

export const RegisterFooterName = styled.p`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const FooterTextLinkWrapper = styled.span`
  display: flex;
  align-items: center;
`;
