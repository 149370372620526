import { motion } from 'framer-motion';
import styled from 'styled-components';

import { breakpoints } from '../../../breakpoints';
import { RemSize } from '../../../theme';

export const StatusWrapper = styled(motion.div)<{ $isPortfolio?: boolean }>`
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  z-index: 1;
  ${({ $isPortfolio }) =>
    $isPortfolio &&
    ` 
   height: calc(100% - 10rem);
  @media ${breakpoints.tabletSmHeight} {
    height: 80vh;
  }
`}
`;

export const PageStateContainerWrapper = styled(motion.div)<{ $tabsVisible: boolean }>`
  overflow-y: auto;
  height: 100%;
  z-index: 1;
  height: ${({ $tabsVisible }) => ($tabsVisible ? `calc(100% - 10rem)` : `calc(100% - 6.5rem)`)};

  @media ${breakpoints.laptopSm} {
    height: 100%;
  }
`;

export const ContentWrapper = styled.div<{ $maxWidth?: RemSize | '100%' }>`
  width: 100%;
  max-width: ${({ theme, $maxWidth }) => $maxWidth || theme.pageWidth};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 1;
`;

export const MarginWrapper = styled.div<{ $removeMargins: boolean }>`
  margin: ${({ $removeMargins }) => ($removeMargins ? '0' : '1rem')};
  display: flex;
  justify-content: center;

  @media ${breakpoints.laptopSm} {
    margin: ${({ $removeMargins }) => ($removeMargins ? '0' : '1.25rem')};
  }
`;
