import { ToastQueueProvider } from '@metaswiss/lib';
import { breakpointValues, InitialLoader, MobileAppScreen } from '@metaswiss/ui-kit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';

import { environmentVariables } from './env/environmentVariables';
import { useAppConfig } from './fetchAppConfig';
import { AppState, useAppState } from './global-state/zustand.ts';
import { GlobalStyles } from './globalStyles';
import { useBrowserLanguage } from './hooks/use-browser-language/useBrowserLanguage.ts';
import { useTextTranslation } from './hooks/use-text-translation/useTextTranslation.ts';
import { router, routerMaintenance } from './router/Router';
import { routes } from './router/routes.ts';
import { TenantLabelsProvider } from './shared/tenant-labels/context';
import { ThemeProvider } from './shared/theme/ThemeProvider';
import { tenantConfig } from './tenantConfig.ts';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, staleTime: Infinity } },
});

function App() {
  const { config } = useAppConfig(environmentVariables.tenant);
  const { setTranslationLanguage, textTranslation } = useTextTranslation();
  const { isMaintenanceMode } = useAppState((state) => state);
  const language = useBrowserLanguage();

  const currentUrl = window.location.pathname;
  const isVerifyRoute = currentUrl.includes(routes.verify);

  const [isMobileSizedScreen, setIsMobileSizedScreen] = useState<boolean>(
    window.innerWidth < breakpointValues.tabletSm && !isVerifyRoute
  );

  useEffect(() => {
    setTranslationLanguage(language);
  }, [language, setTranslationLanguage]);

  const { redirectingFromVerifyViaMobile, setRedirectingFromVerifyViaMobile } = useAppState((state: AppState) => state);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileSizedScreen(window.innerWidth < breakpointValues.tabletSm && !isVerifyRoute ? true : false);
      if (window.innerWidth < breakpointValues.tabletSm && !isVerifyRoute) {
        setRedirectingFromVerifyViaMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!config) return <InitialLoader />;

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyles theme={config.theme} />
      <QueryClientProvider client={queryClient}>
        <TenantLabelsProvider value={config.tenantLabels}>
          <ThemeProvider theme={config.theme}>
            <ToastQueueProvider>
              {redirectingFromVerifyViaMobile || isMobileSizedScreen ? (
                <MobileAppScreen
                  headingText={textTranslation('mobileAppScreen.downloadApp')}
                  descriptionText={textTranslation('mobileAppScreen.applicationIsNotOptimized')}
                  logo={tenantConfig.logo}
                  googlePlayAppLink={tenantConfig.googlePlayAppLink}
                  appStoreAppLink={tenantConfig.appStoreAppLink}
                />
              ) : (
                <RouterProvider router={isMaintenanceMode ? routerMaintenance : router} />
              )}
            </ToastQueueProvider>
          </ThemeProvider>
        </TenantLabelsProvider>
      </QueryClientProvider>
    </QueryClientProvider>
  );
}

export default App;
