/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminNftCollectionApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param id
   * @param offset
   * @param limit
   * @param page
   * @returns any Get Accepted Transactions by NFT Collection id
   * @throws ApiError
   */
  public getNftCollectionAcceptedTransactionsById(
    id: string,
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/admin/nft-collection/{id}/transactions',
      path: {
        'id': id,
      },
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @param offset
   * @param limit
   * @param page
   * @returns any Get NFT Collection Holders
   * @throws ApiError
   */
  public getNftCollectionHolders(
    id: string,
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/admin/nft-collection/{id}/holders',
      path: {
        'id': id,
      },
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
