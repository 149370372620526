import { FC } from 'react';
import { ClipLoader } from 'react-spinners';
import { useTheme } from 'styled-components';

import { Text, ThemedIcon } from '../..';
import { ArrowRotateLeft, FileIcon2 } from '../../../iconography';
import { IconProps } from '../../../iconography/iconProps';
import { IconSize, RemSize } from '../../../theme';

import { DocumentContainer, LeftSideContainer } from './documentV2.styles';

export type DocumentV2Props = {
  text: string;
  width?: RemSize | '100%' | 'auto';
  icon?: FC<IconProps>;
  iconSize?: IconSize | 'full';
  iconColor?: string;
  onClick: () => void;
  isLoading?: boolean;
  isError?: boolean;
  refetch?: () => void;
  disabled?: boolean;
};

export const DocumentV2 = ({
  text,
  icon = FileIcon2,
  width = 'auto',
  onClick,
  isLoading,
  isError,
  iconSize,
  iconColor,
  disabled,
  refetch,
}: DocumentV2Props) => {
  const theme = useTheme();

  return (
    <DocumentContainer onClick={onClick} $width={width} $disabled={disabled}>
      <LeftSideContainer>
        <ThemedIcon icon={icon} customStrokeColor={iconColor || theme.v2.icon.neutral} size={iconSize || 'large'} />
        <Text
          color={disabled ? theme.v2.text.disabled : theme.v2.text.headingPrimary}
          fontWeight={iconSize ? 'semi' : 'reg'}
        >
          {text}
        </Text>
      </LeftSideContainer>
      {isLoading && <ClipLoader size={theme.icon.sizes.medium} color={theme.v2.icon.primary} />}
      {isError && (
        <div onClick={refetch}>
          <ThemedIcon icon={ArrowRotateLeft} customStrokeColor={theme.v2.icon.neutral} />
        </div>
      )}
    </DocumentContainer>
  );
};
