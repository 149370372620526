import { Heading } from '@metaswiss/ui-kit';
import { useTheme } from 'styled-components';

import { SideBannerWrapper } from './sideBanner.styles';
import { SideBannerProps } from './types';

export const SideBanner = ({ imgUrl, text, imageAlignment }: SideBannerProps) => {
  const theme = useTheme();
  return (
    <SideBannerWrapper $imgUrl={imgUrl} $imageAlignment={imageAlignment}>
      {text && (
        <Heading headingType="h1" fontWeight={'semi'} lineHeight={'extraLarge'} color={theme.v2.text.onAction}>
          {text}
        </Heading>
      )}
    </SideBannerWrapper>
  );
};
