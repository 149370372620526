import { FC } from 'react';
import { useTheme } from 'styled-components';

import { ThemedIcon } from '../../../../components';
import { IconProps } from '../../../../iconography/iconProps';
import { RemSize } from '../../../../theme';

import { CircleIconWrapper } from './circleInteractiveIcon.styles';

type CircleInteractiveIconProps = {
  icon: FC<IconProps>;
  onClick: () => void;
  customSize?: RemSize;
};

export const CircleInteractiveIcon = ({ icon, onClick, customSize = '2.5rem' }: CircleInteractiveIconProps) => {
  const theme = useTheme();

  return (
    <CircleIconWrapper onClick={onClick} $size={customSize}>
      <ThemedIcon size={'full'} icon={icon} customStrokeColor={theme.v2.icon.default} />
    </CircleIconWrapper>
  );
};
