import { IconProps } from './iconProps';

export const XcircleContainedIcon: React.FC<IconProps> = ({ strokeColor }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3512 8.3129C15.7417 7.92237 15.7417 7.28921 15.3512 6.89868C14.9607 6.50816 14.3275 6.50816 13.937 6.89868L15.3512 8.3129ZM7.14877 13.6869C6.75825 14.0774 6.75825 14.7106 7.14877 15.1011C7.5393 15.4916 8.17246 15.4916 8.56299 15.1011L7.14877 13.6869ZM13.937 15.1011C14.3275 15.4916 14.9607 15.4916 15.3512 15.1011C15.7417 14.7106 15.7417 14.0774 15.3512 13.6869L13.937 15.1011ZM8.56299 6.89868C8.17246 6.50816 7.5393 6.50816 7.14877 6.89868C6.75825 7.28921 6.75825 7.92237 7.14877 8.3129L8.56299 6.89868ZM19.85 10.9999C19.85 15.7496 15.9996 19.5999 11.25 19.5999V21.5999C17.1042 21.5999 21.85 16.8541 21.85 10.9999H19.85ZM11.25 19.5999C6.50034 19.5999 2.64999 15.7496 2.64999 10.9999H0.649994C0.649994 16.8541 5.39578 21.5999 11.25 21.5999V19.5999ZM2.64999 10.9999C2.64999 6.25025 6.50034 2.3999 11.25 2.3999V0.399902C5.39578 0.399902 0.649994 5.14568 0.649994 10.9999H2.64999ZM11.25 2.3999C15.9996 2.3999 19.85 6.25025 19.85 10.9999H21.85C21.85 5.14568 17.1042 0.399902 11.25 0.399902V2.3999ZM13.937 6.89868L10.5429 10.2928L11.9571 11.707L15.3512 8.3129L13.937 6.89868ZM10.5429 10.2928L7.14877 13.6869L8.56299 15.1011L11.9571 11.707L10.5429 10.2928ZM15.3512 13.6869L11.9571 10.2928L10.5429 11.707L13.937 15.1011L15.3512 13.6869ZM11.9571 10.2928L8.56299 6.89868L7.14877 8.3129L10.5429 11.707L11.9571 10.2928Z"
        fill={strokeColor}
      />
    </svg>
  );
};
