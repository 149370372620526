/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangePasswordRequest } from '../models/ChangePasswordRequest';
import type { CorporateUserFullResponse } from '../models/CorporateUserFullResponse';
import type { PageableItems } from '../models/PageableItems';
import type { PrivateUserFullResponse } from '../models/PrivateUserFullResponse';
import type { RejectUserRequest } from '../models/RejectUserRequest';
import type { UpdateCorporateUserRequest } from '../models/UpdateCorporateUserRequest';
import type { UpdatePasswordWithoutCurrentPasswordRequest } from '../models/UpdatePasswordWithoutCurrentPasswordRequest';
import type { UpdatePreferredLanguageRequest } from '../models/UpdatePreferredLanguageRequest';
import type { UpdatePrivateUserRequestV4 } from '../models/UpdatePrivateUserRequestV4';
import type { UpdateTenantLogo } from '../models/UpdateTenantLogo';
import type { UserFullResponse } from '../models/UserFullResponse';
import type { UserResponse } from '../models/UserResponse';
import type { UserRoleResponse } from '../models/UserRoleResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param id
   * @param requestBody
   * @returns PrivateUserFullResponse Update private user
   * @throws ApiError
   */
  public updatePrivateDetails(
    id: string,
    requestBody: UpdatePrivateUserRequestV4,
  ): CancelablePromise<PrivateUserFullResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v5/users/{id}/private/update',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns UserResponse Get user response
   * @throws ApiError
   */
  public getUserResponse(): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/users/user-response',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param email
   * @returns boolean Check if user with given email already exists
   * @throws ApiError
   */
  public checkEmailExists(
    email: string,
  ): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/users/check-email/{email}',
      path: {
        'email': email,
      },
      errors: {
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param name
   * @returns boolean Check if user with given name already exists
   * @throws ApiError
   */
  public checkCorporateNameExists(
    name: string,
  ): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/users/check-name/{name}',
      path: {
        'name': name,
      },
      errors: {
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns any Update preferred language
   * @throws ApiError
   */
  public updatePreferredLanguage(
    id: string,
    requestBody: UpdatePreferredLanguageRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/users/{id}/update-preferred-language',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns any Change password
   * @throws ApiError
   */
  public changePassword(
    id: string,
    requestBody: ChangePasswordRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/users/{id}/change-password',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns any Private user assets
   * @throws ApiError
   */
  public getPrivateUserAssets(
    id: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/users/{id}/private/assets',
      path: {
        'id': id,
      },
      errors: {
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns any Corporate user assets
   * @throws ApiError
   */
  public getCorporateUserAssets(
    id: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/users/{id}/corporate/assets',
      path: {
        'id': id,
      },
      errors: {
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns UserRoleResponse Get user role
   * @throws ApiError
   */
  public getUserRole(
    id: string,
  ): CancelablePromise<UserRoleResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/users/{id}/role',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns CorporateUserFullResponse Get corporate user
   * @throws ApiError
   */
  public getCorporateUser(
    id: string,
  ): CancelablePromise<CorporateUserFullResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/users/{id}/corporate',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns PrivateUserFullResponse Get private user
   * @throws ApiError
   */
  public getPrivateUser(
    id: string,
  ): CancelablePromise<PrivateUserFullResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/users/{id}/private',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns CorporateUserFullResponse Update corporate user
   * @throws ApiError
   */
  public updateCorporateDetails(
    id: string,
    requestBody: UpdateCorporateUserRequest,
  ): CancelablePromise<CorporateUserFullResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v5/users/{id}/corporate/update',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns UserResponse Get all users
   * @throws ApiError
   */
  public getAllUsersByTenant(): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/users',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns any Delete profile picture
   * @throws ApiError
   */
  public deleteProfilePicture(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v5/users/profile-picture',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns any Get all ids from admin users
   * @throws ApiError
   */
  public getAdminsId(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/users/admins',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns any Approve user
   * @throws ApiError
   */
  public approveUser(
    id: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v5/admin/users/{id}/approve',
      path: {
        'id': id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns any Reject user
   * @throws ApiError
   */
  public rejectUser(
    id: string,
    requestBody: RejectUserRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v5/admin/users/{id}/reject',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns any Update tenant user logo
   * @throws ApiError
   */
  public updateTenantUserLogo(
    id: string,
    requestBody: UpdateTenantLogo,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v5/admin/users/{id}/update-logo',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @returns any Get tenant users
   * @throws ApiError
   */
  public getTenantUsers(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/admin/users/tenant',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param offset
   * @param limit
   * @param page
   * @param search
   * @param role
   * @param status
   * @param fullyCompletedRequest
   * @returns any
   * @throws ApiError
   */
  public getAllUsers(
    offset?: number,
    limit?: number,
    page?: number,
    search?: string,
    role?: string,
    status?: string,
    fullyCompletedRequest?: boolean,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/admin/users',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'search': search,
        'role': role,
        'status': status,
        'fullyCompletedRequest': fullyCompletedRequest,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns any Update password
   * @throws ApiError
   */
  public updatePassword(
    id: string,
    requestBody: UpdatePasswordWithoutCurrentPasswordRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v5/admin/users/{id}/update-password',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns CorporateUserFullResponse Update password
   * @throws ApiError
   */
  public getCorporateUser1(
    id: string,
  ): CancelablePromise<CorporateUserFullResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/admin/users/{id}/corporate',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns PrivateUserFullResponse Update password
   * @throws ApiError
   */
  public getPrivateUser1(
    id: string,
  ): CancelablePromise<PrivateUserFullResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/admin/users/{id}/private',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns UserFullResponse Get single user
   * @throws ApiError
   */
  public getUserById(
    id: string,
  ): CancelablePromise<UserFullResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/admin/users/{id}',
      path: {
        'id': id,
      },
      errors: {
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns any Update private user profile picture
   * @throws ApiError
   */
  public deleteUserProfilePicture(
    id: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v5/admin/users/{id}/profile-picture',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
