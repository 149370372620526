import styled from 'styled-components';

export const BondCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.5rem;
`;

export const HeaderContainer = styled.div`
  padding: 1rem 1.25rem;
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
`;

export const BondInfoContainer = styled.div`
  padding: 1.25rem;
`;

export const BondInfoWrapper = styled.div`
  display: flex;
  gap: 1.25rem;
  height: 100%;
  flex-direction: column;
`;

export const BondsInfoElementsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;

export const BondPriceInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 1.25rem;
`;

export const PricePerBondContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const IconWrapper = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.v2.surface.tertiary};
  border-radius: ${({ theme }) => theme.v2.radius.smaller};
`;

export const PriceInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BondsRemainingContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  margin: 1.25rem;
  width: 100%;
`;

export const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;

export const FlagHeaderContainerWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
`;

export const FlagWrapper = styled.div`
  height: 1.5rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.v2.radius.smallest};
  overflow: hidden;
`;

export const CountryFlag = styled.img`
  width: 2rem;
  height: 1.5rem;
`;
