import styled from 'styled-components';

import { BorderRadiusVariant, RemSize } from '../../../../theme';

export const IconHolder = styled.div<{
  $backgroundColor?: string;
  $size: RemSize;
  $borderRadius: BorderRadiusVariant;
}>`
  border-radius: ${({ theme, $borderRadius }) => theme.dimensions.borderRadii[$borderRadius]};
  display: grid;
  place-content: center;
  background-color: ${({ $backgroundColor }) => $backgroundColor ?? 'transparent'};
  padding: 0.75rem;
  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};
  cursor: pointer;
`;

export const TooltipHolder = styled.div<{
  $leftPosition: RemSize;
  $topPosition: RemSize;
  $isTooltipRightOriented: boolean;
  $backgroundColor?: string;
}>`
  position: fixed;
  top: ${({ $topPosition }) => $topPosition};
  left: ${({ $leftPosition }) => $leftPosition};
  transform: translateY(-50%);
  z-index: 3;
  max-width: 15rem;

  &::before {
    content: '';
    position: absolute;
    width: 0.75rem;
    height: 0.75rem;
    top: 50%;
    ${({ $isTooltipRightOriented }) => ($isTooltipRightOriented ? 'left: 0;' : 'right: 0;')}
    transform: translate(${({ $isTooltipRightOriented }) => ($isTooltipRightOriented ? '-30%' : '30%')}, -50%)
      rotate(-135deg);
    background-color: ${({ theme, $backgroundColor }) => $backgroundColor || theme.v2.surface.tertiary};
    border-radius: ${({ theme }) => theme.dimensions.borderRadii.small};
  }
`;

export const TooltipContent = styled.div<{ $backgroundColor?: string }>`
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  background-color: ${({ theme, $backgroundColor }) => $backgroundColor || theme.v2.surface.tertiary};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
