import { Theme } from '@metaswiss/ui-kit';
import { DarkTheme } from '@metaswiss/ui-kit/src/theme/theme';

import { greenstateTokens, metaswissTokens } from './tokens.ts';

export const greenStateTheme: Theme & { v2: typeof greenstateTokens } = {
  type: 'light',
  typography: {
    sizes: {
      xxsm: '0.625rem',
      xsm: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.25rem',
      h4: '1.5rem',
      h3: '2rem',
      h2: '2.75rem',
      h1: '4rem',
    },
    weights: {
      reg: '400',
      semi: '600',
      bold: '700',
    },
    lineHeight: {
      base: '1.5rem',
      sm: '1.2rem',
      medium: '1.25rem',
      large: '2.5rem',
      extraSmall: '1rem',
      extraLarge: '5rem',
      h4: '2rem',
      h3: '2.5rem',
      h2: '3.5rem',
      h1: '5rem',
    },
  },
  colors: {
    neutrals: {
      hue0: '#ffffff',
      hue25: '#f9fafb',
      hue50: '#f2f4f7',
      hue100: '#e4e7ec',
      hue200: '#ced2da',
      hue300: '#a6abb2',
      hue400: '#767b83',
      hue500: '#52565c',
      hue600: '#373a3d',
      hue700: '#222223',
      hue800: '#121213',
      hue900: '#000000',
    },
    error: {
      hue0: '#FFF6F6',
      hue25: '#FFECEB',
      hue50: '#FAC5C1',
      hue100: '#F8A9A3',
      hue200: '#F5827A',
      hue300: '#F36960',
      hue400: '#F04438',
      hue500: '#DA3E33',
      hue600: '#AA3028',
      hue700: '#84251F',
      hue800: '#651D18',
      hue900: '#40100C',
    },
    warning: {
      hue0: '#FFFBF6',
      hue25: '#FFF4E5',
      hue50: '#FFE1B8',
      hue100: '#FFD396',
      hue200: '#FFBF66',
      hue300: '#FFB248',
      hue400: '#FF9F1A',
      hue500: '#E89118',
      hue600: '#B57112',
      hue700: '#8C570E',
      hue800: '#6B430B',
      hue900: '#513309',
    },
    success: {
      hue0: '#F5FFFA',
      hue25: '#E5FFF2',
      hue50: '#B2F5D4',
      hue100: '#8DF0BF',
      hue200: '#5AE9A1',
      hue300: '#39E58F',
      hue400: '#08DE73',
      hue500: '#07CA69',
      hue600: '#069E52',
      hue700: '#047A3F',
      hue800: '#035D30',
      hue900: '#023F20',
    },
    primary: {
      hue0: '#E8F5E6',
      hue25: '#CEE9C6',
      hue50: '#B1DBA4',
      hue100: '#93D181',
      hue200: '#7BCC63',
      hue300: '#65C549',
      hue400: '#5AAD42',
      hue500: '#448332',
      hue600: '#356627',
      hue700: '#284E1D',
      hue800: '#284E1D',
      hue900: '#101E0C',
    },
    secondary: {
      hue0: '#FEF4EA',
      hue25: '#FDDDBF',
      hue50: '#FCCCA0',
      hue100: '#FAB574',
      hue200: '#F9A759',
      hue300: '#F89130',
      hue400: '#E2842C',
      hue500: '#B06722',
      hue600: '#88501A',
      hue700: '#683D14',
      hue800: '#44290E',
      hue900: '#271809',
    },
  },
  button: {
    borderWidth: '1px',
    borderStyle: 'solid',
    size: {
      small: {
        textPadding: {
          vertical: '0',
          horizontal: '0.5rem', //8px
        },
        btnContentPadding: {
          vertical: '0.43rem',
          horizontal: '0.5rem', //8px
        },
      },
      medium: {
        textPadding: {
          vertical: '0',
          horizontal: '0.5rem', //8px,
        },
        btnContentPadding: {
          vertical: '0.5rem',
          horizontal: '0.875rem', // 14px
        },
      },
      large: {
        textPadding: {
          vertical: '0',
          horizontal: '0.75rem', //12px
        },
        btnContentPadding: {
          vertical: '0.75rem',
          horizontal: '2rem', //16px
        },
      },
      extraLarge: {
        textPadding: {
          vertical: '0',
          horizontal: '0.75rem', //12px
        },
        btnContentPadding: {
          vertical: '1rem',
          horizontal: '1.5rem', //24px
        },
      },
    },
    variant: {
      primary: {
        borderColor: {
          default: '',
          disabled: '',
        },
      },
      secondary: {
        borderColor: {
          default: '#CED2DA',
          disabled: '',
        },
      },
      tertiary: {
        borderColor: {
          default: '',
          disabled: '#CED2DA',
        },
      },
    },
  },
  dimensions: {
    borderRadii: {
      small: '0.1rem',
      medium: '0.25rem',
      large: '0.5rem',
      xl: '0.75rem',
      xxl: '1rem',
      xxxl: '1.25rem',
    },
    borderWidth: '0.0625rem',
  },
  icon: {
    sizes: {
      extraSmall: '1rem',
      small: '1.125rem',
      medium: '1.5rem',
      large: '2rem',
      largeX: '2.5rem',
      xl: '2.75rem',
      xxl: '4rem',
      midLarge: '3rem',
      extraLarge: '4rem',
    },
  },
  dropdown: {
    horizontalPadding: {
      medium: '1rem',
      large: '1.25rem',
    },
    width: {
      small: '8.875rem',
      medium: '15rem',
      large: '18.25rem',
    },
    height: {
      medium: '2.5rem',
      large: '3.5rem',
    },
    size: {
      small: {
        width: {
          nonFlag: '15rem',
          flag: '0rem',
        },
        height: '2rem',
        paddingHorizontal: '0.75rem',
      },
      medium: {
        width: {
          nonFlag: '15rem',
          flag: '8.375rem',
        },
        height: '2.5rem',
        paddingHorizontal: '1rem',
      },
      large: {
        width: {
          nonFlag: '18.25rem',
          flag: '8.875rem',
        },
        height: '3.5rem',
        paddingHorizontal: '1.25rem',
      },
    },
  },
  checkbox: {
    width: {
      small: '1.2rem',
      medium: '1.5rem',
      large: '2rem',
    },
    height: {
      small: '1.2rem',
      medium: '1.5rem',
      large: '2rem',
    },
  },
  avatar: {
    size: {
      small: '2.5rem',
      regular: '6rem',
      medium: '8rem',
      large: '12rem',
    },
  },
  transitions: {
    durations: {
      toast: 4250,
    },
  },
  pageWidth: '77.5rem',
  v2: greenstateTokens,
};

export const metaswissTheme: DarkTheme & { v2: typeof metaswissTokens } = {
  type: 'dark',
  typography: {
    sizes: {
      xxsm: '0.625rem',
      xsm: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.25rem',
      h4: '1.5rem',
      h3: '2rem',
      h2: '2.75rem',
      h1: '4rem',
    },
    weights: {
      reg: '400',
      semi: '600',
      bold: '700',
    },
    lineHeight: {
      base: '1.5rem',
      sm: '1.2rem',
      medium: '1.25rem',
      large: '2.5rem',
      extraSmall: '1rem',
      extraLarge: '5rem',
      h4: '2rem',
      h3: '2.5rem',
      h2: '3.5rem',
      h1: '5rem',
    },
  },
  colors: {
    neutrals: {
      hue0: '#000000',
      hue25: '#121213',
      hue50: '#222223',
      hue100: '#373A3D',
      hue200: '#52565C',
      hue300: '#767B83',
      hue400: '#A6ABB2',
      hue500: '#CED2DA',
      hue600: '#E4E7EC',
      hue700: '#F2F4F7',
      hue800: '#F9FAFB',
      hue900: '#FFFFFF',
    },
    error: {
      hue0: '#FFF6F6',
      hue25: '#FEE4E2',
      hue50: '#FAC5C1',
      hue100: '#F8A9A3',
      hue200: '#F5827A',
      hue300: '#F04438',
      hue400: '#F04438',
      hue500: '#DA3E33',
      hue600: '#AA3028',
      hue700: '#84251F',
      hue800: '#651D18',
      hue900: '#40100C',
    },
    warning: {
      hue0: '#FFFBF6',
      hue25: '#FEEFC7',
      hue50: '#FFE1B8',
      hue100: '#FFD396',
      hue200: '#FFBF66',
      hue300: '#F79009',
      hue400: '#FF9F1A',
      hue500: '#E89118',
      hue600: '#B57112',
      hue700: '#8C570E',
      hue800: '#6B430B',
      hue900: '#513309',
    },
    success: {
      hue0: '#F5FFFA',
      hue25: '#ECFDF3',
      hue50: '#B2F5D4',
      hue100: '#8DF0BF',
      hue200: '#5AE9A1',
      hue300: '#32D583',
      hue400: '#08DE73',
      hue500: '#07CA69',
      hue600: '#069E52',
      hue700: '#047A3F',
      hue800: '#035D30',
      hue900: '#023F20',
    },
    primary: {
      hue0: '#EBF3FF',
      hue25: '#CCDFFF',
      hue50: '#99C0FF',
      hue100: '#7FB0FF',
      hue200: '#66A1FF',
      hue300: '#3381FF',
      hue400: '#0062FF',
      hue500: '#004ECC',
      hue600: '#003B99',
      hue700: '#002766',
      hue800: '#021F4D',
      hue900: '#02183A',
    },
    secondary: {
      hue0: '#F4E9FB',
      hue25: '#E9D4F7',
      hue50: '#D3A8F0',
      hue100: '#C893EC',
      hue200: '#BD7DE8',
      hue300: '#A752E0',
      hue400: '#9126D9',
      hue500: '#741FAD',
      hue600: '#571782',
      hue700: '#3A0F57',
      hue800: '#2F0C47',
      hue900: '#250939',
    },
  },
  colorsDark: {
    hue0: '#BDCEFF',
    hue25: '#9FAEDC',
    hue50: '#828FB7',
    hue100: '#616C8D',
    hue200: '#535F83',
    hue300: '#414A67',
    hue400: '#2C3247',
    hue500: '#1C2130',
    hue600: '#181C28',
    hue700: '#11141E',
    hue800: '#0D1017',
    hue900: '#0A0C11',
  },
  button: {
    borderWidth: '1px',
    borderStyle: 'solid',
    size: {
      small: {
        textPadding: {
          vertical: '0',
          horizontal: '0.5rem', //8px
        },
        btnContentPadding: {
          vertical: '0.43rem',
          horizontal: '0.5rem', //8px
        },
      },
      medium: {
        textPadding: {
          vertical: '0',
          horizontal: '0.5rem', //8px,
        },
        btnContentPadding: {
          vertical: '0.6rem',
          horizontal: '0.875rem', // 14px
        },
      },
      large: {
        textPadding: {
          vertical: '0',
          horizontal: '0.75rem', //12px
        },
        btnContentPadding: {
          vertical: '0.75rem',
          horizontal: '2rem', //16px
        },
      },
      extraLarge: {
        textPadding: {
          vertical: '0',
          horizontal: '0.75rem', //12px
        },
        btnContentPadding: {
          vertical: '1rem',
          horizontal: '1.5rem', //24px
        },
      },
    },
    variant: {
      primary: {
        borderColor: {
          default: '',
          disabled: '',
        },
      },
      secondary: {
        borderColor: {
          default: '',
          disabled: '',
        },
      },
      tertiary: {
        borderColor: {
          default: '',
          disabled: '',
        },
      },
    },
  },
  dimensions: {
    borderRadii: {
      small: '0.5rem',
      medium: '1rem',
      large: '2rem',
      xl: '3rem',
      xxl: '4rem',
      xxxl: '5rem',
    },
    borderWidth: '0.0625rem',
  },
  icon: {
    sizes: {
      extraSmall: '1rem',
      small: '1.125rem',
      medium: '1.5rem',
      large: '2rem',
      largeX: '2.5rem',
      midLarge: '3rem',
      extraLarge: '4rem',
      xl: '2.75rem',
      xxl: '4rem',
    },
  },
  dropdown: {
    horizontalPadding: {
      medium: '1rem',
      large: '1.25rem',
    },
    width: {
      small: '8.875rem',
      medium: '15rem',
      large: '18.25rem',
    },
    height: {
      medium: '2.5rem',
      large: '3.5rem',
    },
    size: {
      small: {
        width: {
          nonFlag: '15rem',
          flag: '0rem',
        },
        height: '2rem',
        paddingHorizontal: '0.75rem',
      },
      medium: {
        width: {
          nonFlag: '15rem',
          flag: '8.375rem',
        },
        height: '2.5rem',
        paddingHorizontal: '1rem',
      },
      large: {
        width: {
          nonFlag: '18.25rem',
          flag: '8.875rem',
        },
        height: '3.5rem',
        paddingHorizontal: '1.25rem',
      },
    },
  },
  checkbox: {
    width: {
      small: '1.2rem',
      medium: '1.5rem',
      large: '2rem',
    },
    height: {
      small: '1.2rem',
      medium: '1.5rem',
      large: '2rem',
    },
  },
  avatar: {
    size: {
      small: '2.5rem',
      regular: '6rem',
      medium: '8rem',
      large: '12rem',
    },
  },
  transitions: {
    durations: {
      toast: 4000,
    },
  },
  pageWidth: '77.5rem',
  v2: metaswissTokens,
};
