import { ToastType, useToastQueueContext } from '@metaswiss/lib';
import { Heading, Text } from '@metaswiss/ui-kit';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';

import { api } from '../../../api/msApi.ts';
import ControlledForm from '../../../components/controlled-form/ControlledForm.tsx';
import { PasswordValidationInputs } from '../../../components/passwords-validation-inputs/PasswordsValidationInputs';
import { ApiResource } from '../../../enums/resource.enum';
import { useAppState } from '../../../global-state/zustand.ts';
import { useTextTranslation } from '../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../router/routes.ts';
import { getQueryKey } from '../../../shared/helpers/getQueryKey.helper';
import { useAuthContext } from '../shared/auth-context/authContext.ts';
import { useSetAuthConfig } from '../shared/auth-context/useSetAuthConfig.ts';

import { FormWrapper, HeadingContainer, HeadingWrapper } from './changePassword.styles.ts';
import { FormData, changePasswordFormShema, changePasswordSchema } from './changePasswordShema.ts';

export const ChangePasswordView = () => {
  const navigate = useNavigate();
  const { textTranslation } = useTextTranslation();
  const { user, setUser } = useAppState((state) => state);
  const queryClient = useQueryClient();

  const [doPasswordsMatch, setDoPasswordsMatch] = useState<boolean>(true);
  const { enqueue } = useToastQueueContext();

  const { setLoading, setIsValid, clearAuthContext } = useAuthContext();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useFormContext<FormData>();

  const { mutate } = useMutation({
    mutationFn: (data: FormData) => {
      return api.auth.updatePassword(user?.id || '', {
        newPassword: data.password,
        passwordConfirmation: data.confirmPassword,
      });
    },
    onMutate: () => {
      setLoading(true);
    },
    onError: () => {
      enqueue(ToastType.ERROR, textTranslation('error.default'), textTranslation('error.error'));
      setLoading(false);
    },
    onSuccess: async () => {
      clearAuthContext();
      if (user) {
        setUser({
          ...user,
          firstTimeLogin: false,
        });
      }
      await queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.USER_RESPONSE) });
      navigate(routes.root, { replace: true });
      setLoading(false);
    },
  });

  useEffect(() => {
    setIsValid(isValid && doPasswordsMatch);
    return () => {
      setIsValid(true);
    };
  }, [doPasswordsMatch, isValid, setIsValid]);

  useSetAuthConfig(
    {
      nextButton: {
        animationDirection: 'login-in',
        text: 'changePassword.confirm',
        onClick: () => {
          handleSubmit((data) => mutate(data))();
        },
      },
    },
    [handleSubmit, mutate]
  );

  if (!user) {
    return <Navigate to={routes.root} replace={true} />;
  }

  return (
    <>
      <HeadingWrapper>
        <HeadingContainer>
          <Heading headingType="h4" fontWeight="bold">
            {textTranslation('changePassword.title')}
          </Heading>
          <Text>{textTranslation('changePassword.description')}</Text>
        </HeadingContainer>
      </HeadingWrapper>
      <FormWrapper>
        <PasswordValidationInputs
          control={control}
          passwordName={changePasswordFormShema.password}
          passwordConfirmationName={changePasswordFormShema.confirmPassword}
          passwordError={errors[changePasswordFormShema.password]}
          passwordConfirmationError={errors[changePasswordFormShema.confirmPassword]}
          passwordLabel={textTranslation('changePassword.newPassword')}
          passwordConfirmationLabel={textTranslation('changePassword.confirmPassword')}
          doPasswordsMatch={doPasswordsMatch}
          setDoPasswordsMatch={setDoPasswordsMatch}
        />
      </FormWrapper>
    </>
  );
};

export const ChangePassword = () => {
  return (
    <ControlledForm validationSchema={changePasswordSchema}>
      <ChangePasswordView />
    </ControlledForm>
  );
};
