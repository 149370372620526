import { useTheme } from 'styled-components';

import { GsNotificationFeedbackIcon, MsNotificationFeedbackIcon } from '../../../iconography';
import { ThemedIcon } from '../../index';

import { BaseFeedback } from './BaseFeedback';

export type Props = {
  title: string;
  subTitle: string;
  titleRender?: (title: string) => JSX.Element;
  subTitleRender?: (subTitle: string) => JSX.Element;
  fill?: boolean;
};

export const NotificationFeedback: React.FC<Props> = ({ title, titleRender, subTitleRender, subTitle, fill }) => {
  const theme = useTheme();
  const isMetaswiss = theme.v2.tenant === 'MetaSwiss'; // TODO: temporary solution
  return (
    <BaseFeedback
      imageWidth="8.75rem"
      height="31.5rem"
      title={title}
      titleRender={titleRender}
      subTitle={subTitle}
      textGap="1rem"
      contentGap="1.5rem"
      textWidth="19.75rem"
      subTitleRender={subTitleRender}
      icon={<ThemedIcon icon={isMetaswiss ? MsNotificationFeedbackIcon : GsNotificationFeedbackIcon} size="full" />}
      fill={fill}
    />
  );
};
