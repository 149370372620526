import styled, { css } from 'styled-components';

import { ColorPalette } from '../../..';
import { breakpoints } from '../../../breakpoints.ts';

export const Wrapper = styled.div<{ $palette: ColorPalette; $customBlurBackground: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  background: ${({ theme }) => theme.v2.surface.secondary};

  ${({ theme, $customBlurBackground, $palette }) => {
    if ($customBlurBackground) {
      return css`
        &::before {
          content: '';
          display: block;
          width: 43rem;
          height: 43rem;
          border-radius: 50%;
          filter: blur(12.5rem);
          background-color: ${theme.v2.colors.primary100};
          opacity: 0.3;
          position: fixed;
          right: -10%;
          top: -10%;
          transform: translate3d(0, 0, 0);
        }

        &::after {
          content: '';
          background-color: ${theme.v2.colors.secondary100};
          opacity: 0.3;
          display: block;
          width: 43rem;
          height: 43rem;
          border-radius: 50%;
          position: fixed;
          filter: blur(12.5rem);
          left: -10%;
          bottom: -30%;
          transform: translate3d(0, 0, 0);
        }

        @media ${breakpoints.desktopSm} {
          &::before {
            width: 43rem;
            height: 43rem;
          }

          &::after {
            width: 43rem;
            height: 43rem;
            left: -10%;
          }
        }
        @media ${breakpoints.desktopLg} {
          &::after {
            left: 0;
          }
        }
        @media ${breakpoints.desktop2K} {
          &::before {
            width: 56.25rem;
            height: 56.25rem;
          }

          &::after {
            width: 56.25rem;
            height: 56.25rem;
          }
        }
      `;
    }
    return css`
      &::before {
        content: '';
        display: block;
        width: 59%;
        height: 59%;
        border-radius: 50%;
        filter: blur(12.5rem);
        background-color: ${theme.colors[$palette].hue200};
        opacity: 0.3;
        position: fixed;
        right: 93.6041%;
        top: 4.0685%;
        transform: translate3d(0, 0, 0);
      }

      &::after {
        content: '';
        background-color: ${theme.colors[$palette].hue200};
        opacity: 0.3;
        display: block;
        width: 59%;
        height: 59%;
        border-radius: 50%;
        position: fixed;
        filter: blur(12.5rem);
        left: 88.1041%;
        top: 47.5374%;
        transform: translate3d(0, 0, 0);
      }
    `;
  }}
`;
