/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CouponTableResponse } from '../models/CouponTableResponse';
import type { PageableItems } from '../models/PageableItems';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CouponsApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param offset
   * @param limit
   * @param page
   * @param smartBondCode
   * @param currency
   * @param priceMinimum
   * @param priceMaximum
   * @param paid
   * @param buyer
   * @returns any Issued coupons
   * @throws ApiError
   */
  public getAllIssuedCoupons(
    offset?: number,
    limit?: number,
    page?: number,
    smartBondCode?: string,
    currency?: string,
    priceMinimum?: number,
    priceMaximum?: number,
    paid?: string,
    buyer?: string,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/coupons',
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
        'smartBondCode': smartBondCode,
        'currency': currency,
        'priceMinimum': priceMinimum,
        'priceMaximum': priceMaximum,
        'paid': paid,
        'buyer': buyer,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @param offset
   * @param limit
   * @param page
   * @returns any Issued coupons by transaction id
   * @throws ApiError
   */
  public getIssuedCouponsByTransactionId(
    id: string,
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/coupons/transaction/{id}',
      path: {
        'id': id,
      },
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @param offset
   * @param limit
   * @param page
   * @returns any Issued coupons by user id
   * @throws ApiError
   */
  public getIssuedCouponsByUserId(
    id: string,
    offset?: number,
    limit?: number,
    page?: number,
  ): CancelablePromise<PageableItems> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/coupons/user/{id}',
      path: {
        'id': id,
      },
      query: {
        'offset': offset,
        'limit': limit,
        'page': page,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns CouponTableResponse Change coupon status
   * @throws ApiError
   */
  public changeCouponStatus(
    id: string,
  ): CancelablePromise<CouponTableResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v5/coupons/status/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
