import { DropdownItem } from '@metaswiss/ui-kit/src/components/atoms/dropdown/Dropdown';
import { ItemProps } from '@metaswiss/ui-kit/src/components/molecules/selection-popup/types';

import { PhotoDocumentProps, DocumentProps } from '../../../../shared/types/documentUploadType';

export type FullCorporateRegistrationData = {
  email: string;
  password: string;
  passwordConfirmation: string;
  country: ItemProps;
  phoneNumberPrefix: string;
  phoneNumber: string;
  dateFounded: string;
  termsAndConditions: boolean;
  legalFormId: DropdownItem[];
  businessFocusId: ItemProps;
  city: string;
  address: string;
  zipCode: string;
  picture: PhotoDocumentProps;
  proofOfDomicile: DocumentProps;
  commercialRegister: DocumentProps;
  signature: PhotoDocumentProps;
  ceo: string;
  owner: string;
  name: string;
};

export const initialFullCorporateRegistrationValues: FullCorporateRegistrationData = {
  email: '',
  password: '',
  passwordConfirmation: '',
  termsAndConditions: false,
  address: '',
  city: '',
  zipCode: '',
  country: {
    value: '',
    label: '',
  },
  name: '',
  ceo: '',
  owner: '',
  phoneNumber: '',
  phoneNumberPrefix: '',
  legalFormId: [],
  businessFocusId: {
    value: '',
    label: '',
    iconSrc: '',
    meta: '',
  },
  dateFounded: '',
  picture: {
    fileId: '',
    url: '',
  },
  proofOfDomicile: {
    fileId: '',
    url: '',
    fileName: '',
    fileSize: '',
  },
  commercialRegister: {
    fileId: '',
    url: '',
    fileName: '',
    fileSize: '',
  },
  signature: {
    fileId: '',
    url: '',
  },
};
