import { styled } from 'styled-components';

import { RemSize } from '../../../../theme';

type Props = {
  $fill?: boolean;
  $height: RemSize | 'auto' | string;
  $contentGap: RemSize;
  $padding?: `${RemSize} ${RemSize} ${RemSize} ${RemSize}` | `${RemSize} ${RemSize}` | RemSize;
  $hasButton: boolean;
};

export const FeedbackContainer = styled.div<Props>`
  width: ${({ $fill }) => ($fill ? '100%' : '30rem')};
  height: ${({ $height }) => $height};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ $contentGap }) => $contentGap};
  margin-bottom: ${({ $hasButton }) => ($hasButton ? '1rem' : '0')};
`;
