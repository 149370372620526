import { styled } from 'styled-components';

import { ButtonSize } from '../Button.tsx';

type Props = {
  $size: ButtonSize;
};

export const TextContainer = styled.div<Props>`
  text-align: center;
  display: flex;
  align-items: center;
  ${({ theme, $size }) => {
    const paddingValue = $size === 'large' ? `${theme.v2.spacing['0.75']}` : `${theme.v2.spacing['0.5']}`;
    return `
      padding-left: ${paddingValue};
      padding-right: ${paddingValue};
    `;
  }}
`;
