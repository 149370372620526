type Props = {
  backgroundColor?: string;
  foregroundColor?: string;
  borderColor?: string;
};

export const SuccessFeedbackIcon: React.FC<Props> = ({ backgroundColor, foregroundColor, borderColor }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 264 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="29.9811" y="14.9902" width="43.306" height="33.3123" rx="16.6562" fill={backgroundColor} />
      <rect x="86.6121" y="14.9902" width="149.905" height="33.3123" rx="16.6562" fill={backgroundColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1577 82.8926C14.1577 74.8279 20.6955 68.29 28.7603 68.29H249.397C257.462 68.29 264 74.8279 264 82.8926C264 90.9574 257.462 97.4953 249.397 97.4953H219.059C211.587 97.4953 206.536 106.086 206.536 113.558C206.536 120.765 211.425 128.891 218.632 128.891H225.12C239.234 128.891 250.675 140.332 250.675 154.446C250.675 168.559 239.234 180 225.12 180H26.3873C12.2739 180 0.832764 168.559 0.832764 154.446C0.832764 140.332 12.2739 128.891 26.3873 128.891H59.5261C66.7323 128.891 71.6214 120.765 71.6214 113.558C71.6214 106.086 66.5707 97.4953 59.0984 97.4953H28.7603C20.6955 97.4953 14.1577 90.9574 14.1577 82.8926Z"
        fill={backgroundColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206.115 37.8066C212.66 44.2713 212.724 54.8172 206.26 61.3615L112.474 156.302C109.345 159.469 105.078 161.252 100.625 161.252C96.1721 161.252 91.9047 159.469 88.7754 156.302L56.8066 123.939C50.3419 117.395 50.4065 106.849 56.9508 100.384C63.4951 93.9195 74.041 93.984 80.5057 100.528L100.625 120.895L182.561 37.9508C189.025 31.4065 199.571 31.3419 206.115 37.8066Z"
        stroke={borderColor || 'white'}
        strokeWidth="6.66246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M194.41 49.6562L100.625 144.596L68.6561 112.234"
        stroke={foregroundColor}
        strokeWidth="33.3123"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8232 42.8066L15.8232 55.4653M22.1526 49.136L9.4939 49.136"
        stroke={foregroundColor}
        strokeWidth="2.63722"
        strokeLinecap="round"
      />
      <path
        d="M224.442 145.984L224.442 164.306M233.602 155.145L215.281 155.145"
        stroke={foregroundColor}
        strokeWidth="5.13565"
        strokeLinecap="round"
      />
      <path
        d="M79.5331 1.83203L79.5331 9.82698M83.5306 5.82951L75.5356 5.82951"
        stroke={foregroundColor}
        strokeWidth="1.66562"
        strokeLinecap="round"
      />
    </svg>
  );
};
