/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VatResponse } from '../models/VatResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class VatApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns VatResponse Get VAT
   * @throws ApiError
   */
  public getVat(): CancelablePromise<VatResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/vat',
      errors: {
        500: `Bad Request`,
      },
    });
  }

}
