import { FC } from 'react';

import { Checkbox, Text, TextLink } from '../../..';
import { CheckboxGroup, Section, SectionHeader } from '../filter.styles';

export type RangeSectionProps = {
  title: string;
  options: { id: string; label: string; value: string }[];
  selectedItems: { id: string; label: string; value: string }[];
  onSelect: (id: string, label: string, value: string) => void;
  cleanupButtonText: string;
  cleanupButtonOnClick: () => void;
};

export const SelectSection: FC<RangeSectionProps> = ({
  title,
  options,
  onSelect,
  selectedItems,
  cleanupButtonText,
  cleanupButtonOnClick,
}) => {
  return (
    <Section key={title}>
      <SectionHeader>
        <Text fontWeight={'bold'}>{title}</Text>
        {cleanupButtonText && (
          <TextLink onClick={cleanupButtonOnClick} padding={'0.25rem'}>
            {cleanupButtonText}
          </TextLink>
        )}
      </SectionHeader>
      <CheckboxGroup>
        {options.map((item) => (
          <Checkbox
            key={item.id}
            id={item.id}
            label={item.label}
            checked={selectedItems.some((selectedItem) => selectedItem.id === item.id)}
            onChange={() => {
              onSelect(item.id, item.label, item.value);
            }}
          />
        ))}
      </CheckboxGroup>
    </Section>
  );
};
