import {
  CorporateUserFullResponse,
  PrivateUserFullResponse,
  UpdateCorporateUserRequest,
  UpdatePrivateUserRequestV4,
} from '@metaswiss/api';
import { ToastType, useToastQueueContext } from '@metaswiss/lib';
import { Button, ErrorDialog, FormInput, HeaderCard, PageStateContainer, Text, TextLink } from '@metaswiss/ui-kit';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FieldError, Path, useFormContext, useWatch } from 'react-hook-form';
import { unstable_useBlocker } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../api/msApi.ts';
import ControlledForm from '../../../../components/controlled-form/ControlledForm.tsx';
import { ApiResource } from '../../../../enums/resource.enum.ts';
import { UserRole } from '../../../../enums/userRole.ts';
import {
  AppState,
  ShellNavigationState,
  useAppState,
  useShellNavigationState,
} from '../../../../global-state/zustand.ts';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation.ts';
import { defaultUser } from '../../../../shared/helpers/defaultUser.ts';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper.ts';
import { setFieldValues } from '../../../../shared/helpers/setFieldValues.helper.ts';

import { ButtonWrapper, FormContainer, HeaderWrapper } from './wallet.styles.ts';
import { createWalletSchema, EditWalletFormData, editWalletSchemaNames } from './walletSchema.ts';

export const WalletView = () => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const { enqueue } = useToastQueueContext();
  const { setIsChangeRouteAvailable } = useShellNavigationState((state: ShellNavigationState) => state);

  const user = useAppState((state: AppState) => state.user) || defaultUser;

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const {
    control,
    setValue,
    getValues,
    reset,
    formState: { isDirty, errors },
  } = useFormContext<EditWalletFormData>();

  const btcWalletValue = useWatch({ control, name: 'btcWallet' });
  const ethWalletValue = useWatch({ control, name: 'ethWallet' });

  const blocker = unstable_useBlocker(isDirty);

  useEffect(() => {
    if (isDirty && blocker.state === 'blocked') {
      setIsModalOpen(true);
    }
  }, [blocker, isDirty]);

  const { data: privateUser, refetch: refetchPrivateUser } = useQuery({
    queryKey: getQueryKey(ApiResource.PRIVATE_USER, user.id),
    queryFn: () => api.users.getPrivateUser(user.id),
    enabled: user.role === UserRole.PRIVATE,
  });

  const { data: corporateUser, refetch: refetchCorporateUser } = useQuery({
    queryKey: getQueryKey(ApiResource.CORPORATE_USER, user.id),
    queryFn: () => api.users.getCorporateUser(user.id),
    enabled: !!user && user.role === UserRole.CORPORATE,
  });

  const { mutate } = useMutation<PrivateUserFullResponse | CorporateUserFullResponse, unknown, EditWalletFormData>({
    mutationFn: (data: EditWalletFormData) => {
      return user && user?.role === UserRole.PRIVATE
        ? api.users.updatePrivateDetails(user.id, data as UpdatePrivateUserRequestV4)
        : api.users.updateCorporateDetails(user.id, data as UpdateCorporateUserRequest);
    },
    onSuccess: async (data: EditWalletFormData) => {
      user?.role === UserRole.PRIVATE ? await refetchPrivateUser() : await refetchCorporateUser();
      reset({ btcWallet: data.btcWallet, ethWallet: data.ethWallet });
    },
    onError: () => {
      enqueue(ToastType.ERROR, textTranslation('error.default'), textTranslation('error.error'));
    },
  });

  useEffect(() => {
    const user = privateUser || corporateUser;
    if (user) {
      setFieldValues<EditWalletFormData>({ btcWallet: user.btcWallet, ethWallet: user.ethWallet }, setValue);
    }
  }, [privateUser, corporateUser]);

  const onCancelHandler = async () => {
    setIsEditMode(false);
    setIsModalOpen(false);
    setIsChangeRouteAvailable(true);
    const userData = privateUser || corporateUser;
    if (userData) {
      setFieldValues<EditWalletFormData>({ btcWallet: userData?.btcWallet, ethWallet: userData?.ethWallet }, setValue);
    }
    reset({ btcWallet: userData?.btcWallet, ethWallet: userData?.ethWallet });
    blocker.proceed?.();
  };

  const onEditHandler = () => {
    setIsEditMode(false);
    mutate(getValues());
  };

  const onClose = () => {
    setIsModalOpen(false);
    blocker.reset?.();
  };

  const isNotEmptyFields = (value: string) => {
    return value === null || value.trim() === '';
  };

  return (
    <PageStateContainer
      showLoading={true}
      isLoading={!user}
      showEmptyState={false}
      showError={false}
      textTranslation={textTranslation}
      maxWidth="44.5rem"
    >
      {isEditMode && (
        <ErrorDialog
          primaryText={textTranslation('account.discardChanges')}
          secondaryText={textTranslation('account.areYouSureDiscard')}
          isOpen={isModalOpen}
          close={onClose}
          primaryButtonText={textTranslation('account.discard')}
          onPrimaryButtonClick={onCancelHandler}
          secondaryButtonText={textTranslation('account.cancel')}
          onSecondaryButtonClick={onClose}
        />
      )}
      <HeaderCard
        header={
          <HeaderWrapper>
            <Text fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
              {textTranslation('account.cryptoWallet')}
            </Text>
            <TextLink
              textSize="base"
              color={isEditMode ? 'error' : 'primary'}
              padding={'0.25rem'}
              onClick={() =>
                !isEditMode ? setIsEditMode(true) : isDirty ? setIsModalOpen(true) : setIsEditMode(false)
              }
            >
              {isEditMode ? textTranslation('account.discard') : textTranslation('account.edit')}
            </TextLink>
          </HeaderWrapper>
        }
        removeBackground={false}
      >
        <FormContainer>
          <FormInput<EditWalletFormData>
            name={editWalletSchemaNames.btcWallet as Path<EditWalletFormData>}
            control={control}
            error={errors[editWalletSchemaNames.btcWallet] as FieldError}
            label={textTranslation('account.btcWallet')}
            fill
            isLocked={!isEditMode}
            isOptional={true}
          />
          <FormInput<EditWalletFormData>
            name={editWalletSchemaNames.ethWallet as Path<EditWalletFormData>}
            error={errors[editWalletSchemaNames.ethWallet] as FieldError}
            control={control}
            label={textTranslation('account.ethWallet')}
            fill
            isLocked={!isEditMode}
            isOptional={true}
          />
          {isEditMode && (
            <ButtonWrapper>
              <Button
                text={textTranslation('account.saveChanges')}
                fill
                onClick={onEditHandler}
                disabled={isNotEmptyFields(ethWalletValue) && isNotEmptyFields(btcWalletValue)}
              />
            </ButtonWrapper>
          )}
        </FormContainer>
      </HeaderCard>
    </PageStateContainer>
  );
};

export const Wallet = () => {
  return (
    <ControlledForm validationSchema={createWalletSchema}>
      <WalletView />
    </ControlledForm>
  );
};
