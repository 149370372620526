import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import ControlledForm from '../../../../../../../../components/controlled-form/ControlledForm.tsx';
import { useTextTranslation } from '../../../../../../../../hooks/use-text-translation/useTextTranslation';
import { useAuthContext } from '../../../../../../shared/auth-context/authContext.ts';
import { useSetAuthConfig } from '../../../../../../shared/auth-context/useSetAuthConfig.ts';
import { RegisterHeader } from '../../../../../shared/register-header/RegisterHeader';
import { DocumentProps } from '../../../../../shared/types/documentUploadType';
import { FullCorporateRegistrationData } from '../fullCorporateRegistrationState';

import { SingleUploadDocuments } from './SingleUploadDocuments';
import { DynamicUploadFiles } from './enums';
import { CardsWrapper, IdCardUploadWrapper } from './uploadDocuments.styles';
import { createFullCorporateFilesSchema, FullCorporateRegistrationUploadDataFiles } from './uploadDocumentsSchema';

export const UploadDocumentsView = () => {
  const { setUserDetails, setIsValid, prevStep, nextStep } = useAuthContext((state) => state);
  const userDetails = useAuthContext((state) => state.userDetails) as FullCorporateRegistrationData;
  const { textTranslation } = useTextTranslation();

  const {
    getValues,
    setValue,
    formState: { isValid },
  } = useFormContext<FullCorporateRegistrationUploadDataFiles>();

  const setRegistrationFlowDocument = (documentProps: DocumentProps, property: string) => {
    setUserDetails({
      [property]: {
        ...documentProps,
      },
    });
  };

  useEffect(() => {
    setIsValid(isValid);
    return () => {
      setIsValid(true);
    };
  }, [isValid, setIsValid]);

  useSetAuthConfig(
    {
      wrapperStyle: 'registrationWizard',
      containerStyle: 'registrationWizard',
      backButton: {
        onClick: () => prevStep(),
        text: 'button.back',
      },
      nextButton: {
        text: 'button.next',
        onClick: () => nextStep(),
      },
      showStepper: true,
    },
    [nextStep, prevStep]
  );

  return (
    <>
      <RegisterHeader descriptionText={textTranslation('registration.corporateRegistration.uploadDocumentsHeader')} />
      <CardsWrapper>
        <IdCardUploadWrapper>
          <SingleUploadDocuments
            setValue={setValue}
            getValue={getValues}
            property={DynamicUploadFiles.COMMERCIAL_REGISTER}
            title={'registration.registerCommercial'}
            userDetails={userDetails}
            setRegistrationFlowDocument={setRegistrationFlowDocument}
          />
        </IdCardUploadWrapper>
        <IdCardUploadWrapper>
          <SingleUploadDocuments
            setValue={setValue}
            getValue={getValues}
            property={DynamicUploadFiles.PROOF_OF_DOMICILE}
            title={'registration.proofOfDomicilePageTitle'}
            userDetails={userDetails}
            setRegistrationFlowDocument={setRegistrationFlowDocument}
          />
        </IdCardUploadWrapper>
      </CardsWrapper>
    </>
  );
};

export const UploadDocuments = () => {
  const userDetails = useAuthContext((state) => state.userDetails) as FullCorporateRegistrationData;

  return (
    <ControlledForm
      validationSchema={createFullCorporateFilesSchema}
      defaultValues={{
        commercialRegister: userDetails?.commercialRegister?.fileId,
        proofOfDomicile: userDetails?.proofOfDomicile?.fileId,
      }}
    >
      <UploadDocumentsView />
    </ControlledForm>
  );
};
