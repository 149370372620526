import { FC } from 'react';

import { IconProps } from './iconProps';

export const DeleteIcon: FC<IconProps> = ({ color = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill={color} />
      <path d="M17 7L7 17M17 17L7 7" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
