import { IconProps } from './iconProps';

export const AlertTriangleIcon: React.FC<IconProps> = ({ color = 'none', strokeColor = 'yellow' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 22 20" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 10.9708V6.13251M11 14.5571V14.5996M17.0479 18.6292H4.9521C3.29987 18.6292 1.90554 17.525 1.46685 16.0143C1.27959 15.3694 1.50969 14.6977 1.86163 14.1257L7.90956 3.09779C9.32653 0.7952 12.6735 0.795203 14.0905 3.0978L20.1384 14.1257C20.4904 14.6977 20.7205 15.3694 20.5332 16.0143C20.0945 17.525 18.7002 18.6292 17.0479 18.6292Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
