import { FC } from 'react';
import { useTheme } from 'styled-components';

import { AvatarIcon } from '../../../../iconography/AvatarIcon';
import { AvatarSize, RemSize } from '../../../../theme';
import { CardsRadiusV2 } from '../../../../theme/theme';

import { AvatarImage, BadgeContainer, Container, ImageContainer } from './avatar.styles';

export type AvatarProps = {
  onClick?: () => void;
  imagePath?: string;
  badge?: JSX.Element;
  size?: AvatarSize;
  customSize?: RemSize;
  isCursorPointer?: boolean;
  borderWidth?: number;
  borderRadius?: CardsRadiusV2 | '50%';
  borderColor?: string;
  hasBorder?: boolean;
};

export const AvatarV2: FC<AvatarProps> = ({
  onClick,
  imagePath,
  badge,
  size = 'medium',
  isCursorPointer = true,
  customSize,
  borderWidth = 1,
  borderColor,
  borderRadius = '50%',
  hasBorder = true,
}) => {
  const theme = useTheme();

  const avatarBorderRadius = borderRadius === '50%' ? borderRadius : theme.v2.componentsRadius.cards[borderRadius];
  const avatarSize = customSize || theme.avatar.size[size];

  return (
    <Container $size={avatarSize} $radius={avatarBorderRadius} $isCursorPointer={isCursorPointer}>
      <ImageContainer
        $size={avatarSize}
        $radius={avatarBorderRadius}
        $border={hasBorder ? `${borderWidth}px solid ${borderColor || theme.v2.border.primary}` : 'none'}
        $active={!!onClick}
        onClick={onClick}
      >
        {!imagePath ? <AvatarIcon color={theme.v2.colors.neutral200} /> : <AvatarImage src={imagePath} />}
      </ImageContainer>
      {!!badge && <BadgeContainer>{badge}</BadgeContainer>}
    </Container>
  );
};
