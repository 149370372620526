import { FC, MouseEventHandler, ReactNode } from 'react';

import { IconProps } from '../../../iconography/iconProps';
import { TypographyLineHeight, TypographySize, TypographyWeight } from '../../../theme';
import { ThemedIcon } from '../../molecules/theme-icon';
import { ButtonColor } from '../button';

import { TextLinkButton } from './styles/textLinkButton';

type Props = {
  children: string | ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  color?: ButtonColor;
  disabled?: boolean;
  textSize?: TypographySize;
  padding?: string;
  fontWeight?: TypographyWeight;
  lineHeight?: TypographyLineHeight;
  icon?: FC<IconProps>;
  fill?: boolean;
};

export const TextLink: FC<Props> = ({
  children,
  onClick,
  disabled = false,
  color = 'primary',
  textSize = 'base',
  padding = '0rem',
  lineHeight = 'base',
  fontWeight = 'bold',
  icon,
  fill = false,
}) => {
  // TODO: this should be changed to a button type text
  return (
    <TextLinkButton
      onClick={onClick}
      disabled={disabled}
      $color={color}
      $textSize={textSize}
      $padding={padding}
      $lineHeight={lineHeight}
      $fontWeight={fontWeight}
      $fill={fill}
    >
      {children}
      {icon && <ThemedIcon icon={icon} />}
    </TextLinkButton>
  );
};
