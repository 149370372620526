import { FC } from 'react';
import { useTheme } from 'styled-components';

import { Text } from '../../../atoms/text';
import { BaseCard } from '../base-card/BaseCard';

import { Content, RowWrapper, Wrapper } from './nftDetailsCard.styles';

export type Props = {
  detailsTranslation: string;
  nftIdTranslation: string;
  chainTranslation: string;
  creatorTranslation: string;
  nftId?: string;
  chain?: string;
  creator?: string;
};

export const NftDetailsCard: FC<Props> = ({
  detailsTranslation,
  nftIdTranslation,
  creatorTranslation,
  chainTranslation,
  creator = '',
  chain = '',
  nftId = '',
}) => {
  const theme = useTheme();
  return (
    <BaseCard
      width={'100%'}
      height={'100%'}
      padding={'1rem'}
      disableStateEffects
      override={{
        borderRadius: theme.v2.componentsRadius.cards.default,
        backgroundColor: theme.v2.surface.primary,
        borderColor: theme.v2.border.primary,
      }}
    >
      <Wrapper>
        <Text fontWeight={'bold'} color={theme.v2.text.headingPrimary}>
          {detailsTranslation}
        </Text>
        <Content>
          <RowWrapper>
            <Text color={theme.v2.text.disabled}>{nftIdTranslation}</Text>
            <Text color={theme.v2.text.disabled}>{chainTranslation}</Text>
            <Text color={theme.v2.text.disabled}>{creatorTranslation}</Text>
          </RowWrapper>
          <RowWrapper>
            <Text fontWeight={'semi'} color={theme.v2.text.headingPrimary}>
              {nftId}
            </Text>
            <Text fontWeight={'semi'} color={theme.v2.text.headingPrimary}>
              {chain}
            </Text>
            <Text fontWeight={'semi'} color={theme.v2.text.headingPrimary}>
              {creator}
            </Text>
          </RowWrapper>
        </Content>
      </Wrapper>
    </BaseCard>
  );
};
