import styled from 'styled-components';

import { IconContainer } from '../../../molecules/theme-icon/styles/iconContainer.ts';
import { BorderRadius, ButtonColor, ButtonSize, ButtonType } from '../Button.tsx';

type ButtonProps = {
  $variant: ButtonType;
  $size: ButtonSize;
  $color: ButtonColor;
  $borderRadius?: BorderRadius;
  disabled?: boolean;
  $loading?: boolean;
  $fill?: boolean;
};

export const StyledButton = styled.button<ButtonProps>`
  font-weight: ${({ theme }) => theme.typography.weights.bold};
  border-radius: ${({ $borderRadius, theme, $size }) => theme.v2.componentsRadius.buttons[$borderRadius || $size]};
  pointer-events: ${({ $loading }) => ($loading ? 'none' : 'auto')};
  position: relative;
  padding: 0;
  box-sizing: border-box;
  width: ${({ $fill }) => ($fill ? '100%' : 'unset')};

  ${({ theme, $variant, $color }) => {
    switch ($variant) {
      case 'contained':
        return `background-color: ${theme.v2.colors[`${$color}100`]};`;
      case 'outlined':
        if ($color === 'neutral') {
          return `
          background-color: ${theme.v2.surface.primary};
          border: 1px solid ${theme.v2.border.primaryHover};
          `;
        }
        return `
            background-color: transparent;
             border: 1px solid ${theme.v2.colors[`${$color}100`]};
            `;
      case 'text':
        return `
            background-color: transparent;`;
    }
  }};

  &:hover {
    ${({ theme, $variant, $color }) => {
      switch ($variant) {
        case 'contained':
          return `background-color: ${theme.v2.colors[`${$color}50`]};`;
        case 'outlined':
          if ($color === 'neutral') {
            return `
          background-color: ${theme.v2.surface.tertiary};
          border: 1px solid ${theme.v2.border.primaryHover};
          `;
          }
          return `
            background-color: ${theme.v2.colors[`${$color}25`]};
             border: 1px solid ${theme.v2.colors[`${$color}100`]};
            `;
        case 'text':
          return `
            background-color: transparent;`;
      }
    }};
  }

  &:active {
    ${({ theme, $variant, $color }) => {
      switch ($variant) {
        case 'contained':
          return `background-color: ${theme.v2.colors[`${$color}200`]};`;
        case 'outlined':
          if ($color === 'neutral') {
            return `
          background-color: ${theme.v2.surface.tertiary};
          border: 1px solid ${theme.v2.border.primaryHover};
          `;
          }
          return `
            background-color: transparent;
             border: 1px solid ${theme.v2.colors[`${$color}200`]};
            `;
        case 'text':
          return `
            background-color: transparent;`;
      }
    }};
  }

  &:disabled {
    pointer-events: none;
    ${({ theme, $variant, $color }) => {
      switch ($variant) {
        case 'contained':
          return `background-color: ${theme.v2.surface.tertiary};`;
        case 'outlined':
          if ($color === 'neutral') {
            return `
          background-color: ${theme.v2.surface.tertiary};
          border: 1px solid ${theme.v2.surface.tertiary};
          `;
          }
          return `
            background-color: transparent;
             border: 1px solid ${theme.v2.border.primaryHover};
            `;
        case 'text':
          return `
            background-color: transparent;`;
      }
    }};

    ${IconContainer} {
      path {
        stroke: ${({ theme }) => theme.v2.text.disabled};
      }
    }
  }
`;
