export const composeBusinessFocusOptions = <T>(
  items: T[],
  valueProp: keyof T,
  labelProp: keyof T,
  textTranslation: (key: string) => string,
  metaProp?: keyof T
) => {
  return items.map((item) => ({
    value: item[valueProp] as string,
    label: textTranslation(item[labelProp] as string),
    meta: metaProp ? item?.[metaProp] : undefined,
  }));
};
