import styled from 'styled-components';

export const ItemsSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 1.5rem;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;
  overflow-y: hidden;
`;

export const ItemWrapper = styled.button<{
  $isSelected: boolean;
}>`
  padding: 1rem;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.small};

  &:hover,
  &:focus {
    background-color: ${({ theme, $isSelected }) => !$isSelected && theme.v2.surface.tertiary};
  }

  ${({ $isSelected, theme }) =>
    $isSelected &&
    `
      background-color: ${theme.v2.surface.informationLight};
      border-color: ${theme.v2.border.information};

      * {
        font-weight: ${theme.typography.weights.semi};
        color: ${theme.v2.text.information};
      }

    `}
`;

export const StyledElementContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledElementWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const StyledIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
`;
