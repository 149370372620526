import * as zod from 'zod';

import { FormNames } from '../../../../../shared/forms/formNames';
import { staticTranslationFunction } from '../../../../../shared/forms/staticTranslationFunction.ts';

export const createForgotPassFirstStepSchema = (translate: (key: string) => string) =>
  zod.object({
    email: zod.string().nonempty(translate('error.requiredField')).email(),
  });

export const schema = createForgotPassFirstStepSchema(staticTranslationFunction);

export type FormData = zod.infer<typeof schema>;

export const firstStepFormSchema: FormNames<FormData> = {
  email: 'email',
} as const;
