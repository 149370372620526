import styled from 'styled-components';

type Props = {
  $size: string;
};

export const HiddenRadio = styled.input.attrs({ type: 'radio' })<Props>`
  height: ${({ $size }) => $size};
  width: ${({ $size }) => $size};
  opacity: 0;
`;
