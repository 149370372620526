import { styled } from 'styled-components';

import { RemSize } from '../../../../theme';
import { Padding } from '../../../../theme/theme';

export const StyledDotsButton = styled.div`
  cursor: pointer;
`;

export const DotsButtonWrapper = styled.div`
  position: relative;
`;

export const RenderElementWrapper = styled.div<{ $width: RemSize; $padding: Padding }>`
  width: ${({ $width }) => $width};
  padding: ${({ $padding }) => $padding};
  z-index: 1;
  position: absolute;
  right: 0;
  top: 2rem;
  background-color: ${({ theme }) => theme.v2.surface.primary};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.medium};
  display: flex;
  align-items: center;
  box-shadow:
    0px 8px 8px -4px #10182808,
    0px 20px 24px -4px #10182814;
`;
