import styled, { css } from 'styled-components';

export const uploadFileDeleteIconContainerStyles = css`
  border-radius: 50%;

  &:hover rect {
    fill: ${({ theme }) => theme.v2.icon.error};
  }

  &:active rect {
    fill: ${({ theme }) => theme.v2.colors.error200};
  }
`;

export const UploadFileZone = styled.div<{ $isDragActive: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background: ${({ theme, $isDragActive }) => ($isDragActive ? theme.v2.surface.informationLight : 'inherit')};
`;

export const UploadFileStyled = styled.div<{
  $height: string;
  $width: string;
  isDragActive: boolean;
  $isLoading?: boolean;
  $isError?: boolean;
  $isFileUploaded: boolean;
  $isDisabled: boolean;
}>`
  background: ${({ theme, $isDisabled }) => ($isDisabled ? theme.v2.surface.tertiary : theme.v2.surface.field)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.v2.componentsRadius.inputs.large};
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};
  border: ${({ theme }) => `1px dashed ${theme.v2.border.primaryHover}`};
  ${({ theme, isDragActive }) => isDragActive && `border: 2px dashed ${theme.v2.border.information}`};
  ${({ theme, $isError }) => $isError && `border: 1px dashed ${theme.v2.border.error}`};
  ${({ theme, $isLoading }) => $isLoading && `border: 1px dashed ${theme.v2.border.information}`};

  &:hover {
    background: ${({ theme, $isDisabled }) => !$isDisabled && theme.v2.surface.primary};
    border: ${({ theme, $isError, $isDisabled }) =>
      $isDisabled
        ? `1px dashed ${theme.v2.border.primaryHover}`
        : $isError
          ? `1px dashed ${theme.v2.border.error}`
          : `1px dashed ${theme.v2.border.information}`};
  }

  &:focus {
    background: ${({ theme }) => theme.v2.surface.field};
    border: ${({ theme, $isError }) =>
      $isError ? `1px dashed ${theme.v2.border.error}` : `1px dashed ${theme.v2.border.information}`};
  }
`;

export const DeleteContainer = styled.div<{ $isDisabled: boolean }>`
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: ${({ $isDisabled }) => !$isDisabled && 'pointer'};
  display: ${({ $isDisabled }) => (!$isDisabled ? 'flex' : 'none')};
  ${uploadFileDeleteIconContainerStyles}
`;
