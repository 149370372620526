import { FC } from 'react';

import { IconProps } from './iconProps';

export const CoinsRotateIcon: FC<IconProps> = ({ strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2 7.79995C10.7457 5.39516 12.8963 3.59995 15.4662 3.59995C18.4485 3.59995 20.8662 6.01761 20.8662 8.99995C20.8662 11.7625 18.7918 14.0405 16.1157 14.3613M6.59999 5.99995L8.99999 3.59995L6.59999 1.19995M1.79999 8.39995V6.79995C1.79999 5.03264 3.23268 3.59995 4.99999 3.59995H7.39999M17.4 18L15 20.3999L17.4 22.7999M22.2 15.6V17.1999C22.2 18.9673 20.7673 20.4 19 20.4H16.6M12.6 16.2C12.6 19.1823 10.1823 21.5999 7.19999 21.5999C4.21765 21.5999 1.79999 19.1823 1.79999 16.2C1.79999 13.2176 4.21765 10.8 7.19999 10.8C10.1823 10.8 12.6 13.2176 12.6 16.2Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
