import { css, styled } from 'styled-components';

export const BannerContainer = styled.div<{ $gradientColor: string }>`
  width: 100%;
  padding: 1rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ $gradientColor }) => $gradientColor};
  border-radius: ${({ theme }) => theme.dimensions.borderRadii.xl};
  position: relative;
  box-shadow: 0px 6px 12px 2px #00000014; // doens't work in rems
`;

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 5.75rem;
`;

export const IconWrapper = styled.div<{ $totalAmount: boolean }>`
  width: 12rem;
  top: 50%;
  position: absolute;
  ${({ $totalAmount }) =>
    $totalAmount
      ? css`
          left: 50%;
          transform: translate(-50%, -50%) rotate(-21.62deg);
        `
      : css`
          transform: translateY(-50%) rotate(-21.62deg);
          right: 4.25rem;
        `};
  svg {
    path {
      stroke: ${({ theme }) => `${theme.v2.colors.neutral0}26`};
    }
  }
`;
