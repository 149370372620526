import { Controller, FieldValues } from 'react-hook-form';

import { ItemWithCustomIconProps, SelectionPopup } from '..';
import { FormSelectionPopupProps, ItemProps } from '../types';

export const FormSelectionPopup = <T extends FieldValues>({
  name,
  error,
  control,
  ...props
}: FormSelectionPopupProps<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <SelectionPopup
          onSelect={onChange as (item: ItemProps | ItemWithCustomIconProps) => void}
          selectedItem={value as ItemProps | ItemWithCustomIconProps}
          error={error?.message}
          {...props}
        />
      )}
    />
  );
};
