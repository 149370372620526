import { IconProps } from './iconProps';

export const ArrowRotateRight01: React.FC<IconProps> = ({ strokeColor }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.1735 27.5C35.8639 34.5267 29.2494 39.6 21.4499 39.6C11.7297 39.6 3.8499 31.7202 3.8499 22C3.8499 12.2798 11.7297 4.40002 21.4499 4.40002C27.9644 4.40002 33.6522 7.93937 36.6953 13.2M31.3499 15.4H40.1499V6.60002"
        stroke={strokeColor}
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
