import { motion } from 'framer-motion';
import styled from 'styled-components';

import { RemSize } from '../../../theme';

export const Container = styled.div<{ $width?: RemSize | '100%'; $height?: RemSize }>`
  border-radius: ${({ theme }) => theme.v2.radius.smallest};
  background: ${({ theme }) =>
    theme.v2.other.type !== 'light' ? theme.v2.colors.neutral25 : theme.v2.colors.neutral400};
  width: ${({ $width }) => $width || '3rem'};
  height: ${({ $height }) => $height || '3rem'};
  position: relative;
  overflow: hidden;
`;

export const GradientContainer = styled(motion.div)`
  position: absolute;
  background: ${({ theme }) =>
    theme.v2.other.type !== 'light'
      ? `linear-gradient(270deg, #e8e9f1 0%, #ffffff 34.67%, #ffffff 64.5%, #e8e9f1 100%)`
      : `linear-gradient(270deg, #262837 0%, #383B4D 34.67%, #383B4D 64.5%, #262837 100%)`};
  opacity: 0.4;
  width: 7.5rem;
  height: 100%;
`;
