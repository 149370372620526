import { FC, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useSetRouteNavigation } from '../../../hooks/use-set-route-navigation/useSetRouteNavigation';
import { useTextTranslation } from '../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../router/routes';

import { offeringTabs } from './shared/values/offeringTabs';

export const Offering: FC = () => {
  const { textTranslation } = useTextTranslation();
  const { pathname } = useLocation();

  const offeringTabsWithTranslation = useMemo(
    () =>
      offeringTabs.map((tab) => ({
        ...tab,
        value: textTranslation(`offering.${tab.value}`),
        label: textTranslation(`offering.${tab.value}`),
      })),
    []
  );

  const pathnameIncludes = useMemo(
    () =>
      pathname.includes(routes.offering.smartNfts.root) ||
      pathname.includes(routes.offering.smartBonds.root) ||
      pathname.includes(routes.offering.smartShares.root),
    [pathname]
  );

  useSetRouteNavigation(offeringTabsWithTranslation, !pathnameIncludes ? routes.offering.smartNfts.root : undefined);

  return <Outlet />;
};
