import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const AvatarContainer = styled.div`
  padding-bottom: 1.5rem;
`;

export const ProfileInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 0.5rem;
  column-gap: 1rem;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 1.5rem;
`;

export const ButtonWrapper = styled.div`
  padding-top: 1.5rem;
`;

export const FullRowWrapper = styled.div`
  grid-column: 1 / -1;
`;
