import { FC } from 'react';

import { IconProps } from './iconProps';

export const Loader1Icon: FC<IconProps> = ({ color = 'none', strokeColor = '#FF9F1A' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 4.2V1M8 15V11.8M11.8 8H15M1 8H4.2M10.6873 5.31313L12.95 3.05039M3.04976 12.9499L5.3125 10.6871M10.6873 10.6869L12.95 12.9496M3.04976 3.05015L5.3125 5.31289"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
