import { PrivateUserFullResponse, CorporateUserFullResponse } from '@metaswiss/api';
import { ItemProps } from '@metaswiss/ui-kit';

export const parseFormObjectUser = (
  response: PrivateUserFullResponse | CorporateUserFullResponse,
  country?: ItemProps
) => {
  return {
    country: {
      value: country?.value,
      label: country?.label,
      iconSrc: country?.iconSrc,
      meta: country?.meta,
    },
    city: response?.city,
    address: response?.address,
    zipCode: response?.zipCode,
  };
};
