import styled from 'styled-components';

import { RemSize } from '../../..';

type DocumentContainerProps = {
  $width?: RemSize | '100%' | 'auto';
  $disabled?: boolean;
};

export const DocumentContainer = styled.div<DocumentContainerProps>`
  width: ${({ $width }) => $width};
  padding: 1rem;
  background-color: ${({ theme, $disabled }) => ($disabled ? theme.v2.surface.secondary : theme.v2.surface.primary)};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  border-radius: ${({ theme }) => theme.v2.radius.small};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    ${({ $disabled, theme }) =>
      !$disabled &&
      `
        svg {
        path {
          stroke: ${theme.v2.icon.primary};
          }
        }
  
        box-shadow:
          0px 0px 16px 0px rgba(0, 0, 0, 0.02),
          0px 1px 0px 0px rgba(0, 0, 0, 0.02),
          0px 10px 24px 0px rgba(0, 0, 0, 0.02);
      `}
  }
`;

export const LeftSideContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
