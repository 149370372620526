import { zodResolver } from '@hookform/resolvers/zod';
import { UpdateCorporateUserRequest } from '@metaswiss/api';
import { convertDocumentSize } from '@metaswiss/lib';
import { AcceptedFileTypes, BaseModal, Button, ErrorDialog, Text, UploadFile } from '@metaswiss/ui-kit';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { unstable_useBlocker } from 'react-router-dom';

import { api } from '../../../../../../api/msApi';
import { ApiResource } from '../../../../../../enums/resource.enum';
import { AppState, useAppState } from '../../../../../../global-state/zustand';
import { useFormSchema } from '../../../../../../hooks/use-form-schema/useFormSchema';
import { useMotionExitTransition } from '../../../../../../hooks/use-motion-exit-transition/useMotionExitTransition';
import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';
import { getQueryKey } from '../../../../../../shared/helpers/getQueryKey.helper';
import { FileInfoProps } from '../../../../../../shared/types/fileInfo';
import { useVerificationDocumentContext } from '../../shared/context/VerificationDocumentContext';
import { ResponseModal } from '../../shared/response-modal/ResponseModal';
import { ButtonWrapper, ModalHeader, ModalMain, UploadWrapper } from '../../styles/verification.styles';
import {
  CommercialRegisterFormData,
  commercialRegisterSchema,
  createCommercialRegisterSchema,
} from '../commercialRegister.schema';

type Props = {
  closeModal: () => void;
};

export const Base: FC<Props> = ({ closeModal }) => {
  const { textTranslation } = useTextTranslation();
  const { verificationDocumentFlow, setVerificationDocumentFlow } = useVerificationDocumentContext();

  const user = useAppState((state: AppState) => state.user);
  const queryClient = useQueryClient();

  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<{ fileId?: string; url?: string } | undefined>(undefined);
  const [uploadedFileInfo, setUploadedFileInfo] = useState<FileInfoProps>({ name: '', size: '' });

  const isPresent = useMotionExitTransition();
  const schema = useFormSchema(createCommercialRegisterSchema);

  const { setValue, getValues, reset, handleSubmit } = useForm<CommercialRegisterFormData>({
    mode: 'onChange',
    resolver: zodResolver(schema),
  });

  const { mutate, isError, isLoading } = useMutation({
    mutationFn: (file: File) => {
      setUploadedFileInfo({ name: file.name, size: convertDocumentSize(file.size) });
      return api.assets.uploadUnassignedFile({ file: file, fileType: file.type });
    },
    onSuccess: (response) => {
      setValue(
        commercialRegisterSchema.file,
        { fileId: response?.fileId, url: response?.url },
        { shouldValidate: true, shouldDirty: true }
      );
      setUploadedFile({ fileId: response?.fileId, url: response?.url });
    },
  });

  const { refetch, isFetching } = useQuery({
    queryKey: getQueryKey(ApiResource.UNASSIGNED_URL),
    queryFn: () => {
      return api.assets.getS3SignedUnassignedUrl({ assetId: getValues(commercialRegisterSchema.file)?.fileId ?? '' });
    },
    enabled: !!verificationDocumentFlow.file?.fileId,
  });

  const isDirtyForm = useMemo(
    () => !!verificationDocumentFlow.file?.fileId || isLoading || isFetching,
    [verificationDocumentFlow, isLoading, isFetching]
  );

  const blocker = unstable_useBlocker(isDirtyForm);

  const { mutate: updateUser, isLoading: isLoadingUpdate } = useMutation({
    mutationKey: getQueryKey(ApiResource.CORPORATE_USER, user?.id),
    mutationFn: () => {
      const updateData: Partial<UpdateCorporateUserRequest> = {
        commercialRegister: verificationDocumentFlow.file,
      };
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return api.users.updateCorporateDetails(user!.id, updateData as UpdateCorporateUserRequest);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.CORPORATE_USER, user?.id) });
      await queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.ASSET, user?.id) });
      reset({}, { keepValues: true });
      setVerificationDocumentFlow({ ...verificationDocumentFlow, isSuccess: true });
    },
    onError: () => {
      setVerificationDocumentFlow({ ...verificationDocumentFlow, isSuccess: false });
    },
  });

  const handleUpload = (file: File) => {
    mutate(file);
  };

  const onSubmit = () => {
    updateUser();
  };

  const onCancel = () => {
    setIsDiscardModalOpen(false);
    blocker.reset?.();
  };

  const onDiscard = () => {
    setIsDiscardModalOpen(false);
    blocker.reset?.();
    closeModal();
    setVerificationDocumentFlow({
      ...verificationDocumentFlow,
      file: { fileId: undefined, url: undefined },
      isSuccess: undefined,
    });
    setUploadedFile(undefined);
    reset({}, { keepDirty: false });
  };

  const onDelete = () => {
    setValue(commercialRegisterSchema.file, { fileId: undefined, url: undefined }, { shouldValidate: true });
    setVerificationDocumentFlow({ ...verificationDocumentFlow, file: { fileId: undefined, url: undefined } });
    setUploadedFile(undefined);
  };

  useEffect(() => {
    if (!uploadedFile?.fileId) return;
    refetch().then((response) => {
      setVerificationDocumentFlow({
        ...verificationDocumentFlow,
        file: { url: response.data?.url, fileId: uploadedFile?.fileId },
      });
    });
  }, [uploadedFile]);

  useEffect(() => {
    if (isDirtyForm && blocker.state === 'blocked') {
      setIsDiscardModalOpen(true);
    }
  }, [blocker, isDirtyForm]);

  return (
    <>
      {typeof verificationDocumentFlow.isSuccess !== 'boolean' ? (
        <>
          <ErrorDialog
            primaryText={textTranslation('account.discardChanges')}
            secondaryText={textTranslation('account.areYouSureDiscard')}
            isOpen={isDiscardModalOpen}
            close={onCancel}
            primaryButtonText={textTranslation('account.discard')}
            onPrimaryButtonClick={onDiscard}
            secondaryButtonText={textTranslation('account.cancel')}
            onSecondaryButtonClick={onCancel}
          />
          <BaseModal
            isActive={isPresent}
            closeModal={closeModal}
            disabledOutsideClick={isDirtyForm}
            position={'fixed'}
            padding={'0rem'}
            width={'45rem'}
            height={'fit-content'}
            smallDevicesWidth={'44.5rem'}
            onOutsideClick={isDirtyForm ? () => setIsDiscardModalOpen(true) : closeModal}
          >
            <ModalHeader>
              <Text fontWeight={'bold'} fontSize={'lg'} lineHeight={'h4'}>
                {textTranslation('account.commercialRegister')}
              </Text>
            </ModalHeader>
            <ModalMain>
              <UploadWrapper>
                <Text fontWeight="bold">{textTranslation('account.commercialRegisterTitle')}</Text>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <UploadFile
                    uploadFile={handleUpload}
                    onDelete={onDelete}
                    initialTitle={textTranslation('registration.uploadDocumentTitle')}
                    initialSubtitle={textTranslation('registration.uploadDocumentSubtitle')}
                    invalidTitle={textTranslation('registration.invalidPhotoTitle')}
                    invalidSubtitle={textTranslation('registration.uploadDocumentSubtitle')}
                    failedTitle={textTranslation('registration.failedPhotoUploadTitle')}
                    failedSubtitle={textTranslation('registration.failedPhotoUploadSubtitle')}
                    acceptedFileTypes={AcceptedFileTypes.ALL}
                    $width={'100%'}
                    $height={'23.5rem'}
                    onViewDocument={() => window.open(verificationDocumentFlow.file?.url ?? '', '_blank')}
                    isLoading={isLoading || isFetching}
                    isError={isError}
                    value={verificationDocumentFlow.file}
                    viewFileTitle={uploadedFileInfo.name}
                    viewFileSubtitle={uploadedFileInfo.size}
                  />
                </form>
              </UploadWrapper>
              <ButtonWrapper>
                <Button
                  text={textTranslation('account.cancel')}
                  variant={'outlined'}
                  color={'neutral'}
                  onClick={isDirtyForm ? () => setIsDiscardModalOpen(true) : closeModal}
                  fill
                />
                <Button
                  text={textTranslation('account.submitDocument')}
                  onClick={onSubmit}
                  loading={isLoadingUpdate}
                  disabled={!verificationDocumentFlow.file?.fileId}
                  fill
                />
              </ButtonWrapper>
            </ModalMain>
          </BaseModal>
        </>
      ) : (
        <ResponseModal isActive={isPresent} closeModal={closeModal} />
      )}
    </>
  );
};
