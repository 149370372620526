import { FC } from 'react';

import { IconProps } from './iconProps';
export const CalendarArrowIcon: FC<IconProps> = ({ color = 'none ', strokeColor = '#3381FF' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 29 28" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.7757 12.3992L22.3313 16.3992M22.3313 16.3992L18.7757 20.3992M22.3313 16.3992H7.93125M7.93125 6.8H21.2646M27.1313 5.99922V21.9992C27.1313 24.6502 24.9822 26.7992 22.3313 26.7992H6.33125C3.68028 26.7992 1.53125 24.6502 1.53125 21.9992V5.99922C1.53125 3.34825 3.68028 1.19922 6.33125 1.19922H22.3313C24.9822 1.19922 27.1313 3.34825 27.1313 5.99922Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
