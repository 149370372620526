import styled from 'styled-components';

import { PaddingProp } from '../base-card/types';

export const Container = styled.div`
  position: relative;
`;

export const HeaderContainer = styled.div<{
  $padding?: PaddingProp;
  $hasEditButton: boolean;
}>`
  padding: ${({ $padding, $hasEditButton }) =>
    $padding ? $padding : $hasEditButton ? '1.25rem 1.5rem' : '1.25rem 1.75rem 1.25rem 1.5rem'};
  border-bottom: 1px solid ${({ theme }) => theme.v2.border.primary};
`;

export const ContentContainer = styled.div<{ $padding?: PaddingProp }>`
  padding: ${({ $padding }) => $padding || '1.5rem'};
`;
