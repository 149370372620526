import { BaseCard } from '@metaswiss/ui-kit';
import styled from 'styled-components';

export const StyledPortfolioCard = styled(BaseCard)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
`;

export const LeftSideWrapper = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const IconWrapper = styled.div`
  width: 4rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.v2.surface.informationLight};
  padding: 0.75rem;
  border-radius: ${({ theme }) => theme.v2.radius.smaller};
  margin-right: 1.25rem;
`;

export const BalanceWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TextWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  height: 100%;
  position: absolute;
  width: 100%;
  justify-content: flex-end;
`;
