import { BaseCard, RadioButton, Text, ThemedIcon, IconProps } from '@metaswiss/ui-kit';
import { LabelOption } from '@metaswiss/ui-kit/src/components/atoms/radio-button/RadioButton';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { CardWrapper, ContentWrapper, IconWrapper, TextWrapper } from './paymentMethodCard.styles';

type PaymentMethodCardProps = {
  icon: FC<IconProps>;
  paymentTypeText: string;
  description: string;
  isSelected: boolean;
  onClick: () => void;
  selectedRadioButton: LabelOption | undefined;
  labelRadioButton: LabelOption;
};
export const PaymentMethodCard: FC<PaymentMethodCardProps> = ({
  icon,
  paymentTypeText,
  description,
  onClick,
  isSelected,
  selectedRadioButton,
  labelRadioButton,
}) => {
  const theme = useTheme();
  return (
    <BaseCard
      removeBackgroundHover
      onClick={onClick}
      padding={'1.25rem'}
      borderBox
      isSelected={isSelected}
      override={{
        borderRadius: theme.v2.componentsRadius.cards.large,
        borderColor: theme.v2.border.primary,
        activeBorderColor: theme.v2.border.information,
        backgroundColor: theme.v2.surface.primary,
        hoverBackgroundColor: theme.v2.surface.secondary,
        activeBackgroundColor: theme.v2.surface.secondary,
      }}
    >
      <CardWrapper>
        <ContentWrapper>
          <IconWrapper $isSelected={isSelected}>
            <ThemedIcon icon={icon} customStrokeColor={theme.v2.icon.default} />
          </IconWrapper>
          <TextWrapper>
            <Text fontWeight={'semi'} color={theme.v2.text.headingPrimary}>
              {paymentTypeText}
            </Text>{' '}
            <Text fontSize={'xsm'} fontWeight={'reg'} color={theme.v2.text.headingPrimary} lineHeight={'extraSmall'}>
              {description}
            </Text>
          </TextWrapper>
        </ContentWrapper>
        <RadioButton onSelect={onClick} selected={selectedRadioButton} label={labelRadioButton} size={'medium'} />
      </CardWrapper>
    </BaseCard>
  );
};
