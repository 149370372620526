import { AnimatePresence } from 'framer-motion';
import { FC } from 'react';

import { VerificationDocumentProvider } from '../shared/context/VerificationDocumentContext';

import { Base } from './steps/Base';

type Props = {
  isActive: boolean;
  closeModal: () => void;
};

export const Signature: FC<Props> = ({ isActive, closeModal }) => {
  return (
    <VerificationDocumentProvider>
      <AnimatePresence>{isActive && <Base closeModal={closeModal} />}</AnimatePresence>
    </VerificationDocumentProvider>
  );
};
