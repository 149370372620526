import { CountryResponse } from '@metaswiss/api';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { api } from '../../api/msApi';
import { ApiResource } from '../../enums/resource.enum';
import { swissPhonePrefix } from '../../shared/constants/swissPhonePrefix';
import { composeSelectionOptions } from '../../shared/helpers/composeSelectionOptions.helper';
import { getQueryKey } from '../../shared/helpers/getQueryKey.helper';

export const useCountries = () => {
  const { data: countries } = useQuery({
    queryKey: getQueryKey(ApiResource.COUNTRY),
    queryFn: () => api.countries.getCountries(),
  });

  const swiss = useMemo(() => countries?.filter((country) => country.phonePrefix === swissPhonePrefix), [countries]);
  const countriesWithoutSwiss = useMemo(
    () => (countries ? countries.filter((country) => country.phonePrefix !== swissPhonePrefix) : []),
    [countries]
  );

  const modifiedCountries = useMemo(
    () => (swiss && Array.isArray(countriesWithoutSwiss) ? [...swiss, ...countriesWithoutSwiss] : []),
    [swiss, countriesWithoutSwiss]
  );

  const countriesAsDropdown = useMemo(() => {
    return composeSelectionOptions<CountryResponse>(modifiedCountries || [], 'id', 'name', 'code');
  }, [modifiedCountries]);

  const countriesPhoneNumberPrefixesAsDropdown = useMemo(() => {
    return composeSelectionOptions<CountryResponse>(modifiedCountries || [], 'id', 'name', 'code', 'phonePrefix');
  }, [modifiedCountries]);

  const countriesNationalityAsDropdown = useMemo(() => {
    return composeSelectionOptions<CountryResponse>(modifiedCountries || [], 'id', 'nationality', 'code');
  }, [modifiedCountries]);

  const selectedCitizenship = (citizenshipCountry = '') =>
    countriesNationalityAsDropdown.find((citizenship) => citizenship.label === citizenshipCountry);

  const selectedPhoneNumberPrefix = (phoneNumberPrefix = '') =>
    countriesPhoneNumberPrefixesAsDropdown.find(
      (country) => country.meta === phoneNumberPrefix || country.label === phoneNumberPrefix
    );

  const selectedCountry = (country = '') =>
    countriesAsDropdown.find((countryDropdownItem) => countryDropdownItem.value === country);

  return {
    countries: countries || [],
    countriesAsDropdown: countriesAsDropdown || [],
    countriesPhoneNumberPrefixesAsDropdown: countriesPhoneNumberPrefixesAsDropdown || [],
    countriesNationalityAsDropdown: countriesNationalityAsDropdown || [],
    selectedCitizenship,
    selectedPhoneNumberPrefix,
    selectedCountry,
  };
};
