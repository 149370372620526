import { IdentityVerification } from '../../../../../../../../enums/identityVerification';

export type SelectIdentityVerificationData = {
  value: IdentityVerification;
  title: string;
  description: string;
};

export const registrationSelectIdenityVerificationData: SelectIdentityVerificationData[] = [
  {
    value: IdentityVerification.ID,
    title: 'registration.idCardTitle',
    description: 'registration.idCardDescription',
  },
  {
    value: IdentityVerification.PASSPORT,
    title: 'registration.passportTitle',
    description: 'registration.passportDescription',
  },
];
