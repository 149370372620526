import { ToastType, useToastQueueContext } from '@metaswiss/lib';
import { Heading, Text, TextLink } from '@metaswiss/ui-kit';
import { FormCodeInput } from '@metaswiss/ui-kit/src/components/molecules/form-code-input';
import { useMutation } from '@tanstack/react-query';
import { FC, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi';
import ControlledForm from '../../../../../components/controlled-form/ControlledForm.tsx';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { regex } from '../../../../../shared/regex/regex.ts';
import { useAuthContext } from '../../../shared/auth-context/authContext.ts';
import { useSetAuthConfig } from '../../../shared/auth-context/useSetAuthConfig.ts';
import { useForgotPasswordState } from '../../shared/context';
import { CodeTextWrapper, FormWrapper, HeadingContainer, HeadingWrapper } from '../../shared/styles';

import { createForgotPassSecondStepSchema, FormData, secondStepFormSchema } from './secondStepSchema';

export const SecondStepView: FC = () => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();
  const { enqueue } = useToastQueueContext();
  const setForgotPasswordStep = useAuthContext((state) => state.setForgotPasswordStep);
  const { email, setCode } = useForgotPasswordState((state) => state);
  const [resendEmailCountdown, setResendEmailCountdown] = useState(60);

  const {
    control,
    getValues,
    setError,
    formState: { errors },
  } = useFormContext<FormData>();

  useEffect(() => {
    const interval = setInterval(() => {
      setResendEmailCountdown((prev) => prev - 1);
    }, 1000);
    if (resendEmailCountdown === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [resendEmailCountdown]);

  const checkCodeMutation = useMutation({
    mutationFn: (code: string) => {
      return api.auth.checkCode({ email, code });
    },
    onError: () => {
      enqueue(ToastType.ERROR, textTranslation('forgotPassword.verificationError'), textTranslation('error.error'));
      setError(secondStepFormSchema.code, { message: ' ' });
    },
    onSuccess: () => {
      setCode(getValues().code);
      setForgotPasswordStep(3);
    },
  });

  const { mutate } = useMutation({
    mutationFn: () => {
      return api.auth.forgotPassword({ email });
    },
    onError: () => {
      enqueue(ToastType.ERROR, textTranslation('error.wrongCredentials'), textTranslation('error.error'));
    },
    onSuccess: () => {
      setResendEmailCountdown(60);
    },
  });

  const linkText = useCallback(() => {
    return `${textTranslation('forgotPassword.resend')} ${resendEmailCountdown > 0 ? `(${resendEmailCountdown})` : ''}`;
  }, [resendEmailCountdown, textTranslation]);

  useSetAuthConfig(
    {
      actionText: {
        text: 'forgotPassword.didntGet',
        linkText: linkText,
        disabled: resendEmailCountdown > 0,
        onClick: () => {
          mutate();
        },
      },
    },
    [resendEmailCountdown]
  );

  return (
    <>
      <HeadingWrapper>
        <HeadingContainer>
          <Heading headingType="h4" fontWeight="bold" color={theme.v2.text.headingPrimary}>
            {textTranslation('forgotPassword.enterCode')}
          </Heading>
          <CodeTextWrapper>
            <Text color={theme.v2.text.bodyPrimary}>{textTranslation('forgotPassword.weHaveSent')}</Text>
            <TextLink>{email}</TextLink>
          </CodeTextWrapper>
        </HeadingContainer>
      </HeadingWrapper>
      <FormWrapper>
        <FormCodeInput<FormData>
          name={secondStepFormSchema.code}
          control={control}
          fill
          numInputs={6}
          error={errors[secondStepFormSchema.code]}
          regex={regex.numbersOnly}
          onComplete={() => {
            checkCodeMutation.mutate(getValues().code);
          }}
        />
      </FormWrapper>
    </>
  );
};

export const SecondStep = () => {
  return (
    <ControlledForm validationSchema={createForgotPassSecondStepSchema}>
      <SecondStepView />
    </ControlledForm>
  );
};
