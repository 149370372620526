export type GroupDataByDay<T> = {
  [day: string]: T[];
};

const removeTimeFromDate = (dateString: string) => {
  return dateString.includes(':')
    ? dateString
        .split(' ')
        .filter((_, i) => i !== 1)
        .join(' ')
    : dateString;
};

export const groupDataByDate = <T extends { createdAt: string; updatedAt?: string }>(
  inputData: T[]
): GroupDataByDay<T>[] => {
  const groupedData: GroupDataByDay<T> = {};
  inputData.forEach((item) => {
    const createdAtDate = removeTimeFromDate(item.createdAt);

    const day = createdAtDate;

    if (!groupedData[day]) {
      groupedData[day] = [];
    }
    groupedData[day].push({
      ...item,
      createdAt: createdAtDate,
      updatedAt: item.updatedAt,
    });
  });

  const mapGroupedData = Object.entries(groupedData).map(([day, values]) => ({
    [day]: values,
  }));

  return mapGroupedData;
};

export type GroupDataByYear<T> = {
  [year: string]: T[];
};

const isValidDateYear = (dateString: string): boolean => !isNaN(Date.parse(dateString));

export const groupDataByYear = <T extends { [key: string]: unknown }>(
  data: T[],
  dataKey: keyof T = 'publishedAt'
): GroupDataByYear<T>[] => {
  const groupedData: GroupDataByYear<T> = {};

  data.forEach((item) => {
    const dateValue = item[dataKey] as string;

    const dateArray = dateValue.split('/');
    const formattedDate = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;

    if (isValidDateYear(formattedDate)) {
      const date = new Date(formattedDate);
      const year = date.getFullYear().toString();

      if (!groupedData[year]) {
        groupedData[year] = [];
      }

      groupedData[year].push({
        ...item,
      });
    }
  });

  return Object.entries(groupedData)
    .sort(([yearA], [yearB]) => Number(yearB) - Number(yearA))
    .map(([year, values]) => ({
      [year]: values,
    }));
};
