import { AnimatePresence, DynamicAnimationOptions, useAnimate } from 'framer-motion';
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { CloseIcon, Lightbulb, Text, useOutsideClick } from '../../..';
import { IconProps } from '../../../iconography/iconProps';
import { ThemedIcon } from '../theme-icon';

import {
  FloatButtonHolder,
  FloatButtonIconWrapper,
  FloatButtonLabel,
  FloatButtonWrapper,
  FloatContentContainer,
  IconWrapper,
} from './floatButton.styles';

export type FloatButtonProps = {
  onClick: () => void;
  isOpenFloatButtonCard: boolean;
  closedFloatButtonCard: () => void;
  icon: FC<IconProps>;
  label: string;
  backgroundColor: string;
  hoverBackgroundColor: string;
};

const transitionOptions: DynamicAnimationOptions = { type: 'spring', mass: 1, stiffness: 406, damping: 30 };

export const FloatButton: FC<PropsWithChildren<FloatButtonProps>> = ({
  children,
  onClick,
  closedFloatButtonCard,
  isOpenFloatButtonCard,
  icon,
  label,
  backgroundColor,
  hoverBackgroundColor,
}) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [scope, animate] = useAnimate();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const buttonContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isOpenFloatButtonCard) {
      animate('#icon', { top: '-5rem' }, transitionOptions);
    } else if (!isOpenFloatButtonCard && isHovered) {
      animate('#icon', { top: '-2rem' }, transitionOptions);
    } else {
      animate('#icon', { top: '1rem' }, transitionOptions);
    }
  }, [isOpenFloatButtonCard, isHovered]);

  useOutsideClick([containerRef, buttonContainerRef], () => closedFloatButtonCard());

  return (
    <FloatButtonWrapper>
      <FloatContentContainer ref={containerRef}>{children}</FloatContentContainer>
      <AnimatePresence>
        {!isOpenFloatButtonCard && isHovered && (
          <FloatButtonLabel initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Text color={theme.v2.text.onAction} fontSize="base">
              {label}
            </Text>
          </FloatButtonLabel>
        )}
      </AnimatePresence>

      <FloatButtonIconWrapper
        $backgroundColor={backgroundColor}
        $hoverBackgroundColor={hoverBackgroundColor}
        onClick={onClick}
        onMouseLeave={() => setIsHovered(false)}
        onMouseEnter={() => setIsHovered(true)}
        ref={scope}
      >
        <FloatButtonHolder ref={buttonContainerRef}>
          <IconWrapper id="icon">
            <ThemedIcon icon={icon} customStrokeColor={theme.v2.icon.onAction} size="large" />
            <ThemedIcon icon={Lightbulb} customStrokeColor={theme.v2.icon.onAction} size="large" />
            <ThemedIcon icon={CloseIcon} customStrokeColor={theme.v2.icon.onAction} size="large" />
          </IconWrapper>
        </FloatButtonHolder>
      </FloatButtonIconWrapper>
    </FloatButtonWrapper>
  );
};
