import { FC } from 'react';

import { IconProps } from './iconProps';

export const FileCheck2: FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 44 48" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.0324 46.3995H6.8324C3.7396 46.3995 1.2324 43.8922 1.23242 40.7994L1.23264 7.19958C1.23266 4.1068 3.73986 1.59961 6.83264 1.59961H32.0333C35.1261 1.59961 37.6333 4.10681 37.6333 7.19961V22.5996M26.4333 38.4663L31.5666 43.5996L42.7666 32.3992M11.0333 12.7996H27.8333M11.0333 21.1996H27.8333M11.0333 29.5996H19.4333"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
