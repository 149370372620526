import { IconProps } from './iconProps';

export const MultipleFileIcon: React.FC<IconProps> = ({ color = 'none', strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5202 6.24003V9.12003C11.5202 9.65022 11.95 10.08 12.4802 10.08H15.3602M17.2802 2.40002H10.5599C9.49955 2.40002 8.63993 3.25963 8.63993 4.32001V6.24003M17.2802 2.40002H17.8157C18.086 2.40002 18.3434 2.51389 18.5296 2.70985C18.8095 3.00433 19.2529 3.45774 19.6802 3.84002C20.015 4.13964 20.5411 4.67734 20.8561 5.00413C21.0265 5.18085 21.1202 5.41682 21.1201 5.66229L21.1201 6.24003M17.2802 2.40002V5.28003C17.2802 5.81022 17.71 6.24003 18.2402 6.24003H21.1201M21.1201 6.24003L21.1199 15.8401C21.1199 16.9004 20.2603 17.76 19.1999 17.76H15.3599M13.9202 7.68003C13.4929 7.29774 13.0495 6.84433 12.7696 6.54985C12.5834 6.35389 12.326 6.24003 12.0557 6.24003H4.79993C3.73954 6.24003 2.87993 7.09963 2.87993 8.16001L2.87985 19.68C2.87985 20.7404 3.73946 21.6 4.79984 21.6L13.4399 21.6C14.5003 21.6 15.3599 20.7404 15.3599 19.6801L15.3601 9.50229C15.3602 9.25682 15.2665 9.02085 15.0961 8.84413C14.7811 8.51734 14.255 7.97964 13.9202 7.68003Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
