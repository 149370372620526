import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ToggleContainer = styled(motion.div)<{ $isDisabled: boolean; $isActive: boolean }>`
  display: flex;
  justify-content: ${({ $isActive }) => ($isActive ? 'flex-end' : 'flex-start')};
  width: 4rem;
  height: 2rem;
  border-radius: ${({ theme }) => theme.v2.radius.full};
  cursor: ${({ $isDisabled }) => !$isDisabled && 'pointer'};
  padding: 0.125rem;
`;

export const ToggleCircle = styled(motion.div)`
  height: 100%;
  width: 1.75rem;
  background-color: ${({ theme }) => theme.v2.surface.primary};
  border-radius: ${({ theme }) => theme.v2.radius.full};
`;
