/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CouponPaidOptionResponse } from '../models/CouponPaidOptionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CouponPaidOptionApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns CouponPaidOptionResponse Fetch all coupon paid options
   * @throws ApiError
   */
  public getCouponPaidOptions(): CancelablePromise<Array<CouponPaidOptionResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/coupon-paid-option',
      errors: {
        500: `Bad Request`,
      },
    });
  }

}
