import { FC } from 'react';

import { IconProps } from './iconProps';

export const UploadErrorIcon: FC<IconProps> = ({ color = 'none' }) => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4326 23.9938L12.2212 29.2051C10.2749 31.1515 9.15562 33.7997 9.17608 36.5829C9.19653 39.3662 10.2913 42.0306 12.33 44.0063C14.3056 46.045 16.9705 47.1397 19.7533 47.1602C22.5996 47.1811 25.1851 46.1249 27.1316 44.1785L32.343 38.9671M38.9995 32.4379L44.2109 27.2265C46.1572 25.2802 47.2765 22.6319 47.256 19.8487C47.2356 17.0655 46.1408 14.4011 44.1022 12.4254C42.127 10.4502 39.4625 9.35542 36.6793 9.33496C33.896 9.31451 31.2474 10.3702 29.301 12.3166L24.0896 17.528M20.3133 35.9792L35.9475 20.345M12.4513 12.173L9.86989 9.5916M21.4148 7.6259L21.8371 3.371M4.0176 21.1897L8.42914 20.7519M44.1954 43.1797L46.7768 45.7611M35.2319 47.7268L34.8096 51.9817M52.6291 34.163L48.2176 34.6009"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
