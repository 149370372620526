import { FC } from 'react';

import { IconProps } from './iconProps';

export const BottomCenterIcon: FC<IconProps> = ({ color = 'none', strokeColor = '#E4E7EC' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 16 9" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0711 0.485289C14.962 0.485289 15.4081 1.56243 14.7782 2.1924L8.70711 8.26347C8.31658 8.654 7.68342 8.654 7.29289 8.26347L1.22183 2.1924C0.591867 1.56243 1.03803 0.485289 1.92894 0.485289L14.0711 0.485289Z"
        fill={strokeColor}
      />
    </svg>
  );
};
