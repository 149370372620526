import { FC } from 'react';

import { IconProps } from './iconProps';

export const LinkAngled: FC<IconProps> = ({ strokeColor = '#3381FF' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.12851 10.1907L5.89505 12.4242C5.06092 13.2583 4.58124 14.3933 4.59001 15.5861C4.59877 16.7789 5.06796 17.9208 5.94167 18.7675C6.78836 19.6413 7.93048 20.1104 9.1231 20.1192C10.3429 20.1282 11.451 19.6755 12.2852 18.8414L14.5187 16.6079M17.3715 13.8097L19.6049 11.5762C20.4391 10.7421 20.9188 9.60711 20.91 8.4143C20.9012 7.22149 20.432 6.0796 19.5583 5.23287C18.7118 4.38638 17.5699 3.91717 16.3771 3.90841C15.1843 3.89964 14.0492 4.35209 13.215 5.18625L10.9815 7.4197M9.3631 15.3274L16.0635 8.62701"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
