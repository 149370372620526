import { InitialLoader } from '@metaswiss/ui-kit';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { routes } from '../../../../../router/routes';
import { AnimationContainer } from '../../../shared/animations/AnimationContainer';
import { useAuthContext } from '../../../shared/auth-context/authContext';
import { getWizardStepsHelper } from '../../shared/helpers/getWizardSteps.helper';

export const RegistrationWizardSteps = () => {
  const { pathname: locationPath } = useLocation();
  const navigate = useNavigate();
  const { activeRegistrationStep, wizardSteps, setWizardSteps, setMaxRegistrationStep } = useAuthContext();

  useEffect(() => {
    if (!wizardSteps.length) {
      const steps = getWizardStepsHelper(locationPath, () => navigate(routes.pageNotFound));
      setWizardSteps(steps);
      setMaxRegistrationStep(steps.length);
    }
  }, [locationPath, setMaxRegistrationStep, setWizardSteps, wizardSteps.length, navigate]);

  if (!wizardSteps.length) return <InitialLoader />;

  const Component = wizardSteps[activeRegistrationStep].component;
  return <AnimationContainer key={activeRegistrationStep}>{Component}</AnimationContainer>;
};
