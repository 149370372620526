import { IconProps } from './iconProps';

export const CheckContainedIcon: React.FC<IconProps> = ({ color, strokeColor, hasBorderRadius }) => {
  return (
    <svg
      width={hasBorderRadius ? '3rem' : '22'}
      height={hasBorderRadius ? '3rem' : '22'}
      viewBox="0 0 22 22"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3515 8.84843L9.80002 13.3999L8.24855 11.8484M11 1.3999C5.69809 1.3999 1.40002 5.69797 1.40002 10.9999C1.40002 16.3018 5.69809 20.5999 11 20.5999C16.302 20.5999 20.6 16.3018 20.6 10.9999C20.6 5.69797 16.302 1.3999 11 1.3999Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
