import { styled } from 'styled-components';

export const PriceContainer = styled.div`
  width: 100%;
  padding: 1.25rem 0.5rem;
  border: 1px dashed ${({ theme }) => theme.v2.border.information};
  border-radius: ${({ theme }) => theme.v2.radius.small};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;
