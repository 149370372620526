import { AssetUploadUnassignedResponse, FullRegistrationCorporateUserRequestV4 } from '@metaswiss/api';
import { UploadFile, Text } from '@metaswiss/ui-kit';
import { AcceptedFileTypes } from '@metaswiss/ui-kit/src/components/molecules/upload-file/enums';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { api } from '../../../../../../../../api/msApi';
import { setResendToken } from '../../../../../../../../api/tokenHelpers';
import ControlledForm from '../../../../../../../../components/controlled-form/ControlledForm.tsx';
import { usePostAndGetDocument } from '../../../../../../../../hooks/use-post-and-get-document/usePostAndGetDocument';
import { useTextTranslation } from '../../../../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../../../../router/routes';
import { useAuthContext } from '../../../../../../shared/auth-context/authContext.ts';
import { useSetAuthConfig } from '../../../../../../shared/auth-context/useSetAuthConfig.ts';
import { StepTextContainer } from '../../../../../register.styles';
import { RegisterHeader } from '../../../../../shared/register-header/RegisterHeader';
import {
  FullCorporateRegistrationData,
  initialFullCorporateRegistrationValues,
} from '../fullCorporateRegistrationState';

import {
  FullCorporateRegistrationUploadSignatureData,
  fullCorporateRegistrationSchema,
  createFullCorporateSignatureSchema,
} from './uploadSignatureSchema';

export const UploadSignatureView = () => {
  const navigate = useNavigate();
  const { textTranslation } = useTextTranslation();
  const { setUserDetails, setIsValid, setLoading, prevStep, clearRegistrationContext, setUserEmailField } =
    useAuthContext((state) => state);
  const userDetails = useAuthContext((state) => state.userDetails) as FullCorporateRegistrationData;

  const {
    getValues,
    setValue,
    formState: { isValid },
    handleSubmit,
  } = useFormContext<FullCorporateRegistrationUploadSignatureData>();

  const setRegistrationFlowSignature = ({ fileId, url }: { fileId: string; url: string }) => {
    setUserDetails({
      signature: { fileId: fileId, url: url },
    });
  };
  const { mutate, isLoading, isError } = usePostAndGetDocument({
    onMutate: handleSignatureMutation,
    onRefetch: handleSignatureRefetch,
    fileId: userDetails?.signature?.fileId ?? '',
  });

  function handleSignatureMutation({ fileId, url }: AssetUploadUnassignedResponse) {
    setValue(fullCorporateRegistrationSchema.signature, fileId, { shouldValidate: true });
    setRegistrationFlowSignature({ fileId: fileId, url: url });
  }

  function handleSignatureRefetch(documentUrl: string) {
    setRegistrationFlowSignature({
      fileId: getValues('signature'),
      url: documentUrl,
    });
  }
  const handleProfileSignatureUpload = (file: File) => {
    mutate(file);
  };

  const { currentLanguage } = useTextTranslation();

  const { mutate: createAccMutate } = useMutation(
    (data: FullRegistrationCorporateUserRequestV4) => {
      return api.auth.fullRegistrationCorporateUser({
        ...data,
      });
    },
    {
      onSuccess: (result) => {
        setResendToken(result.resendToken);
        setUserDetails({});
        navigate(routes.register.registerSuccess);
        clearRegistrationContext();
        setUserEmailField(userDetails.email);
      },
      onError: () => {
        navigate(routes.register.registerFailed);
      },
    }
  );

  useEffect(() => {
    setIsValid(isValid);
    setLoading(isLoading);
    return () => {
      setIsValid(true);
      setLoading(false);
    };
  }, [isValid, isLoading, setIsValid, setLoading]);

  const handleFinalFormSubmit = useCallback(
    (data: FullCorporateRegistrationUploadSignatureData) => {
      createAccMutate({
        email: userDetails.email,
        password: userDetails.password,
        passwordConfirmation: userDetails.passwordConfirmation,
        phoneNumber: userDetails.phoneNumber,
        phoneNumberPrefix: userDetails.phoneNumberPrefix,
        firstTimeLogin: false,
        preferredLanguage: currentLanguage,
        city: userDetails.city,
        zipCode: userDetails.zipCode,
        address: userDetails.address,
        ethWallet: '',
        btcWallet: '',
        termsAndConditions: userDetails.termsAndConditions,
        countryId: userDetails.country.value,
        image: userDetails.picture.fileId,
        proofOfDomicile: userDetails.proofOfDomicile.fileId,
        commercialRegister: userDetails.commercialRegister.fileId,
        signature: data.signature,
        name: userDetails.name,
        ceo: userDetails.ceo,
        dateFounded: userDetails.dateFounded,
        owner: userDetails.owner,
        legalFormId: userDetails.legalFormId[0].id,
        businessFocusId: userDetails.businessFocusId.value,
      });
    },
    [createAccMutate, currentLanguage, userDetails]
  );

  const handleProfileSignatureDelete = () => {
    setRegistrationFlowSignature({ fileId: '', url: '' });
    setValue(fullCorporateRegistrationSchema.signature, '', { shouldValidate: true });
  };

  useSetAuthConfig(
    {
      wrapperStyle: 'registrationWizard',
      containerStyle: 'registrationWizard',
      backButton: {
        onClick: () => prevStep(),
        text: 'button.back',
      },
      nextButton: {
        text: 'registration.createAccount',
        onClick: () => handleSubmit((data) => handleFinalFormSubmit(data))(),
      },
      showStepper: true,
    },
    [prevStep, handleSubmit, handleFinalFormSubmit]
  );

  return (
    <>
      <RegisterHeader descriptionText={textTranslation('registration.corporateRegistration.signatureTitle')} />
      <StepTextContainer>
        <Text fontWeight="bold">{textTranslation('registration.corporateRegistration.signatureSubtitle')}</Text>
      </StepTextContainer>
      <UploadFile
        uploadFile={handleProfileSignatureUpload}
        onDelete={handleProfileSignatureDelete}
        isLoading={isLoading}
        isError={isError}
        initialTitle={textTranslation('registration.corporateRegistration.uploadLogoTitle')}
        initialSubtitle={textTranslation('registration.corporateRegistration.uploadLogoSubtitle')}
        invalidTitle={textTranslation('registration.corporateRegistration.invalidLogoTitle')}
        invalidSubtitle={textTranslation('registration.corporateRegistration.uploadLogoSubtitle')}
        failedTitle={textTranslation('registration.corporateRegistration.failedPhotoLogoTitle')}
        failedSubtitle={textTranslation('registration.corporateRegistration.failedLogoUploadSubtitle')}
        acceptedFileTypes={AcceptedFileTypes.IMAGES}
        $width="37.5rem"
        $height="26rem"
        onViewDocument={() => window.open(userDetails.signature.url, '_blank')}
        value={userDetails?.signature || initialFullCorporateRegistrationValues.signature}
      />
    </>
  );
};

export const UploadSignature = () => {
  const userDetails = useAuthContext((state) => state.userDetails) as FullCorporateRegistrationData;

  return (
    <ControlledForm
      validationSchema={createFullCorporateSignatureSchema}
      defaultValues={{
        signature: userDetails?.signature?.fileId || '',
      }}
    >
      <UploadSignatureView />
    </ControlledForm>
  );
};
