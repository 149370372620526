import { FC } from 'react';

import { IconProps } from './iconProps';

export const CurrencyCoinCentIcon: FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0001 8.68889C14.1656 7.93457 13.0805 7.51761 11.9556 7.5191M11.9556 7.5191C11.3575 7.5195 10.7653 7.6377 10.2129 7.86696C9.66045 8.09622 9.15859 8.43204 8.73594 8.85525C8.31329 9.27846 7.97814 9.78078 7.74962 10.3335C7.5211 10.8863 7.40369 11.4786 7.40409 12.0767C7.40409 14.594 9.44133 16.6343 11.9556 16.6343M11.9556 7.5191L11.9564 5.9999M11.9556 16.6343C13.079 16.6359 14.1629 16.2201 14.997 15.4676M11.9556 16.6343L11.9564 18.1535M21.6 11.9999C21.6 17.3018 17.3019 21.5999 12 21.5999C6.69806 21.5999 2.39999 17.3018 2.39999 11.9999C2.39999 6.69797 6.69806 2.3999 12 2.3999C17.3019 2.3999 21.6 6.69797 21.6 11.9999Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
