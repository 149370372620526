import { FC } from 'react';

import { IconProps } from './iconProps';

export const FileIcon2: FC<IconProps> = ({ strokeColor }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5984 3.2002V9.60019C17.5984 10.4838 18.3148 11.2002 19.1984 11.2002H25.5984M17.873 3.2002H9.59844C7.83113 3.2002 6.39844 4.63288 6.39844 6.40019V25.6002C6.39844 27.3675 7.83113 28.8002 9.59844 28.8002H22.3984C24.1657 28.8002 25.5984 27.3675 25.5984 25.6002V10.9257C25.5984 10.077 25.2613 9.26305 24.6612 8.66294L20.1357 4.13745C19.5356 3.53734 18.7216 3.2002 17.873 3.2002Z"
        stroke={strokeColor}
        strokeWidth="1.30818"
      />
    </svg>
  );
};
