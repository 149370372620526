import { css } from 'styled-components';

import { StyledField } from './styledField';
import { StyledLegend } from './styledLegend';
import { StyledSpan } from './styledSpan';
import { StyledText, TextWrapper } from './styledText';

export const activeInputTextStyles = ($isLabelVisible = true) => css`
  display: ${$isLabelVisible ? 'block' : 'none'};
  padding: 0;
  top: 0;
  left: 0;
  transform: translate(0.25rem, -50%) scale(0.75);
  transform-origin: left;
`;

export const activeStylesInputFont = ($isLegendVisible = true) => css`
  ~ ${TextWrapper} {
    ${StyledText} {
      ${activeInputTextStyles($isLegendVisible)}
    }
  }

  ~ ${StyledField} {
    border: 1px solid ${({ theme }) => theme.v2.border.primary};

    ${StyledLegend} {
      max-width: ${$isLegendVisible ? '100%' : '0'};
      opacity: ${$isLegendVisible ? '1' : '0'};
    }
  }
`;

const errorStateCommonStyles = css`
  border: 0;
  outline: none;

  ~ ${StyledField} {
    border: 1px solid ${({ theme }) => theme.v2.border.error};

    ${StyledLegend} {
      max-width: 100%;
      opacity: 1;
    }
  }
`;

export const errorActiveStateStyles = css`
  ${errorStateCommonStyles}
  &:not(focus) {
    ~ ${TextWrapper} {
      ${StyledText} {
        color: ${({ theme }) => theme.v2.text.error};

        ${activeInputTextStyles()}
        ${StyledSpan} {
          color: ${({ theme }) => theme.v2.text.error};
        }
      }
    }
  }
`;

export const errorStateStyles = css`
  ${errorStateCommonStyles}
  &:not(:focus) {
    ~ ${StyledField} {
      ${StyledLegend} {
        max-width: 0;

        ${StyledSpan} {
          color: ${({ theme }) => theme.colors.error.hue100}; // TODO: check this
        }
      }
    }
  }
`;
