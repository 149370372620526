/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetSignedUrlRequest } from '../models/AssetSignedUrlRequest';
import type { AssetSignedUrlResponse } from '../models/AssetSignedUrlResponse';
import type { AssetUploadResponse } from '../models/AssetUploadResponse';
import type { AssetUploadUnassignedResponse } from '../models/AssetUploadUnassignedResponse';
import type { DeleteDocumentRequest } from '../models/DeleteDocumentRequest';
import type { GetS3SignedUrlRequest } from '../models/GetS3SignedUrlRequest';
import type { NftUploadResponse } from '../models/NftUploadResponse';
import type { Promise } from '../models/Promise';
import type { UploadAssetRequest } from '../models/UploadAssetRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AssetsApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param formData
   * @returns AssetUploadResponse Add assigned asset
   * @throws ApiError
   */
  public uploadAssignedFile(
    formData: UploadAssetRequest,
  ): CancelablePromise<AssetUploadResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/assets',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param formData
   * @returns AssetUploadUnassignedResponse Add unassigned asset
   * @throws ApiError
   */
  public uploadUnassignedFile(
    formData: UploadAssetRequest,
  ): CancelablePromise<AssetUploadUnassignedResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/assets/unassigned',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @returns Promise Add unassigned asset
   * @throws ApiError
   */
  public getS3SignedUnassignedUrl(
    requestBody: GetS3SignedUrlRequest,
  ): CancelablePromise<any | Promise> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/assets/unassigned-url',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns AssetSignedUrlResponse
   * @throws ApiError
   */
  public getS3SignedAssignedUrl(
    requestBody: AssetSignedUrlRequest,
  ): CancelablePromise<AssetSignedUrlResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/assets/assigned-url',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param formData
   * @returns NftUploadResponse Add unassigned NFT asset
   * @throws ApiError
   */
  public uploadUnassignedNftFile(
    formData: UploadAssetRequest,
  ): CancelablePromise<NftUploadResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v5/assets/unassigned-nft',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any Delete uploaded document
   * @throws ApiError
   */
  public deleteDocument(
    requestBody: DeleteDocumentRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v5/assets/delete-document',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
