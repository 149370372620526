import { BaseCard } from '@metaswiss/ui-kit';
import styled from 'styled-components';

export const StyledPortfolioCard = styled(BaseCard)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: unset;
  padding: 1.5rem;
  min-height: 7rem;
  height: 100%;
  flex-wrap: wrap;
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  gap: 1.25rem;
`;

export const StyledTextContainer = styled.div`
  display: block;
`;

export const PortfolioCardTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const IconWrapper = styled.div<{ $color: string }>`
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, $color }) => theme.v2.surface[`${$color}Light`]};
  padding: 0.75rem;
  border-radius: ${({ theme }) => theme.v2.radius.smaller};
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
`;

export const DescriptionWrapperSmall = styled(DescriptionWrapper)`
  gap: 0.25rem;
`;

export const TextAboveHeader = styled.div`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  user-select: none;
  color: ${({ theme }) => theme.colors.neutrals.hue700};
`;

export const BondWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.v2.border.primary};
  width: 100%;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

export const BondCard = styled(BaseCard)`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const BondIconWrapper = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.v2.surface.tertiary};
  padding: 0.5rem;
  border-radius: ${({ theme }) => theme.v2.radius.smaller};
`;

export const BondDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
