import { FC, memo, useMemo } from 'react';

import { AnimatedCircle, Container, Wrapper } from './styles/styledDoughnutChart';

export type PieChartProps = {
  segments: { value: number; color: string }[];
};

export const DoughnutChart: FC<PieChartProps> = ({ segments }) => {
  const sortedSegments = useMemo(() => {
    return segments.sort((a, b) => b.value - a.value);
  }, [segments]);

  const total = useMemo(
    () =>
      sortedSegments.reduce((acc, segment) => {
        const value = segment.value < 0 ? 0 : segment.value;
        return acc + value;
      }, 0),
    [sortedSegments]
  );

  const segmentData = useMemo(() => {
    let accumulatedValue = 0;

    return sortedSegments.map((segment) => {
      const value = segment.value < 0 ? 0 : segment.value;
      const percent = (value / total) * 100;
      const circumference = 2 * Math.PI * 40;
      const offset = circumference * (accumulatedValue / 100);
      accumulatedValue += percent;

      return { offset, circumference, color: segment.color, percent };
    });
  }, [sortedSegments, total]);

  return (
    <Wrapper>
      <Container viewBox="0 0 93 93">
        {segmentData.map((data, index) => (
          <AnimatedCircle
            key={index}
            $circumference={data.circumference}
            $offset={data.offset}
            $strokeColor={data.color}
          />
        ))}
      </Container>
    </Wrapper>
  );
};

export default memo(DoughnutChart);
