import { FC } from 'react';
import { useTheme } from 'styled-components';

import { IconSize } from '../../../theme';
import { Text } from '../text';

import { Circle } from './indicator.styles';

type Props = {
  size?: IconSize | 'fill';
  number: number;
  active: boolean;
};

export const Indicator: FC<Props> = ({ number = 0, active = false, size = 'medium' }) => {
  const theme = useTheme();
  return (
    <Circle
      $color={active ? theme.v2.icon.primary : theme.v2.border.primary}
      $size={size === 'fill' ? '100%' : theme.icon.sizes[size]}
    >
      <Text
        color={active ? theme.v2.text.onAction : theme.v2.text.disabled}
        fontSize={'xsm'}
        fontWeight={'semi'}
        lineHeight={'extraSmall'}
      >
        {number.toString()}
      </Text>
    </Circle>
  );
};
