import { useTheme } from 'styled-components';

import { VersionTabs } from '../../../atoms/version-tabs';
import { BaseCard } from '../base-card/BaseCard';

import { Container, ContentContainer, HeaderContainer } from './headerCard.styles';
import { HeaderCardProps } from './types';

export const HeaderCard = ({
  header,
  children,
  palette = 'neutrals',
  borderColor = 'hue200',
  borderRadius = 'large',
  removeBackground = false,
  headerPadding,
  bodyPadding = '1.5rem',
  hasEditButton = true,
  height,
  removeOverflow = false,
  override,
  versions,
  handleClickVersion,
}: HeaderCardProps) => {
  const theme = useTheme();
  const overrideProps = {
    backgroundColor: theme.v2.surface.primary,
    borderColor: theme.v2.border.primary,
    borderRadius: theme.v2.radius.medium,
    ...override,
  };
  return (
    <Container>
      {versions && handleClickVersion && <VersionTabs versions={versions} handleClickVersion={handleClickVersion} />}
      <BaseCard
        padding="0rem"
        disableStateEffects
        palette={palette}
        borderColor={borderColor}
        borderRadius={borderRadius}
        removeBackground={removeBackground}
        height={height}
        removeOverflow={removeOverflow}
        override={
          removeBackground
            ? {
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                borderRadius: theme.v2.radius.medium,
                ...override,
              }
            : overrideProps
        }
      >
        <HeaderContainer $hasEditButton={hasEditButton} $padding={headerPadding}>
          {header}
        </HeaderContainer>
        <ContentContainer $padding={bodyPadding}>{children}</ContentContainer>
      </BaseCard>
    </Container>
  );
};
