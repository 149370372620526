import { FC } from 'react';

import { IconProps } from './iconProps';

export const FileEditIcon: FC<IconProps> = ({ color, strokeColor }) => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.3003 50.3006H11.1003C8.00748 50.3006 5.50029 47.7934 5.50031 44.7006L5.50052 11.1007C5.50054 8.00792 8.00774 5.50073 11.1005 5.50073H36.3011C39.3939 5.50073 41.9012 8.00794 41.9012 11.1007V22.3007M15.3012 16.7007H32.1012M15.3012 25.1007H32.1012M15.3012 33.5007H23.7012M30.7008 42.5798L42.5802 30.7004L50.4998 38.62L38.6204 50.4994H30.7008V42.5798Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
