import styled from 'styled-components';

export const IconHolder = styled.div`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.v2.surface.tertiary};
`;

export const TooltipHolder = styled.div`
  width: max-content;
  height: 4.375rem;
  position: absolute;
  display: flex;
  flex-direction: column;
`;

export const TooltipContent = styled.div`
  width: fit-content;
  height: 2rem;
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.v2.surface.tertiary};
`;

export const TipWrapper = styled.div`
  position: absolute;
  top: 1.25rem;
  right: 0.25rem;
`;

export const Wrapper = styled.div`
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
`;
