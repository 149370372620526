/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BondCalculationResponse } from '../models/BondCalculationResponse';
import type { BondInvestorsResponse } from '../models/BondInvestorsResponse';
import type { SmartShareLedgerCalculationResponse } from '../models/SmartShareLedgerCalculationResponse';
import type { UserCalculationResponse } from '../models/UserCalculationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CalculationsApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param userId
   * @returns UserCalculationResponse User invested amount
   * @throws ApiError
   */
  public getUserInvestedAmount(
    userId: string,
  ): CancelablePromise<UserCalculationResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/calculations/user',
      query: {
        'userId': userId,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param userId
   * @returns BondCalculationResponse Bond calculations
   * @throws ApiError
   */
  public getBondCalculations(
    userId: string,
  ): CancelablePromise<BondCalculationResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/calculations/bond',
      query: {
        'userId': userId,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param userId
   * @returns any Bond portfolio calculations
   * @throws ApiError
   */
  public getBondPortfolioCalculations(
    userId: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/calculations/bond-portfolio',
      query: {
        'userId': userId,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param userId
   * @returns any Shares offered on platform
   * @throws ApiError
   */
  public getSmartSharesOfferedOnPlatform(
    userId: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/calculations/shares-offered',
      query: {
        'userId': userId,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns SmartShareLedgerCalculationResponse Smart share ledger calculations
   * @throws ApiError
   */
  public getSmartShareLedgerCalculations(
    id: string,
  ): CancelablePromise<SmartShareLedgerCalculationResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/calculations/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

  /**
   * @param id
   * @returns BondInvestorsResponse Smart bond investors
   * @throws ApiError
   */
  public getBondInvestors(
    id: string,
  ): CancelablePromise<BondInvestorsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/calculations/bond-investors/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        500: `Bad Request`,
      },
    });
  }

}
