import { IconProps } from './iconProps';

type NotificationIconProps = IconProps & {
  hasNotification?: boolean;
  color?: string;
  notifColor?: string;
};

export const EmailIcon: React.FC<NotificationIconProps> = ({
  color = 'black',
  notifColor = '#F04438',
  hasNotification = false,
}) => {
  return (
    <>
      {!hasNotification && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.41522 4.77643C3.02891 4.17245 3.87095 3.7998 4.80002 3.7998H19.2C20.2096 3.7998 21.1164 4.23984 21.7391 4.93859C21.7455 4.94563 21.7519 4.95277 21.7581 4.96003C22.2823 5.55824 22.6 6.34192 22.6 7.1998V16.7998C22.6 18.6776 21.0778 20.1998 19.2 20.1998H4.80002C2.92226 20.1998 1.40002 18.6776 1.40002 16.7998V7.19981C1.40002 6.26553 1.77686 5.41926 2.38682 4.80472C2.39613 4.79507 2.4056 4.78564 2.41522 4.77643ZM3.41876 6.97022C3.40643 7.04492 3.40002 7.12161 3.40002 7.19981V16.7998C3.40002 17.573 4.02683 18.1998 4.80002 18.1998H19.2C19.9732 18.1998 20.6 17.573 20.6 16.7998V7.1998C20.6 7.17478 20.5994 7.14991 20.5981 7.12521L13.2523 12.3492C12.499 12.8708 11.5011 12.8707 10.7478 12.3491C10.7402 12.3439 10.7327 12.3385 10.7253 12.3331L3.41876 6.97022ZM19.0108 5.7998H5.20422L11.8929 10.7092C11.9595 10.7514 12.0449 10.7507 12.1108 10.7068L19.0108 5.7998Z"
            fill={color}
          />
        </svg>
      )}
      {hasNotification && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.6 9.85247V16.7998C22.6 18.6776 21.0778 20.1998 19.2 20.1998H4.80002C2.92226 20.1998 1.40002 18.6776 1.40002 16.7998V7.19981C1.40002 6.26553 1.77686 5.41926 2.38682 4.80472C2.39613 4.79507 2.4056 4.78564 2.41522 4.77643C3.02891 4.17245 3.87095 3.7998 4.80002 3.7998H12.4162C12.1708 4.4223 12.0264 5.09571 12.0033 5.7998H5.20422L11.8929 10.7092C11.9595 10.7514 12.0449 10.7507 12.1108 10.7068L13.3673 9.81321C13.806 10.3455 14.3346 10.8009 14.9299 11.1561L13.2626 12.3419L13.2523 12.3492L13.2523 12.3491C12.4989 12.8707 11.5011 12.8707 10.7478 12.3491C10.7402 12.3439 10.7327 12.3385 10.7253 12.3331L3.41876 6.97022C3.40643 7.04492 3.40002 7.12161 3.40002 7.19981V16.7998C3.40002 17.573 4.02683 18.1998 4.80002 18.1998H19.2C19.9732 18.1998 20.6 17.573 20.6 16.7998V11.4089C21.3733 11.0366 22.0544 10.5033 22.6 9.85247Z"
            fill={color}
          />
          <circle cx="18" cy="6" r="4" fill={notifColor} />
        </svg>
      )}
    </>
  );
};
