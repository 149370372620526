import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { removeRefreshToken, removeAccessToken } from '../../api/tokenHelpers';
import { useAppState } from '../../global-state/zustand';
import { routes } from '../../router/routes';

export const useDeleteUser = () => {
  const navigate = useNavigate();
  const removeUser = useAppState((state) => state.removeUser);
  const queryClient = useQueryClient();

  const deleteUser = useCallback(() => {
    navigate(routes.login, { replace: true });
    removeRefreshToken();
    removeAccessToken();
    removeUser();
    queryClient.invalidateQueries();
    queryClient.removeQueries();
  }, [navigate, queryClient, removeUser]);

  return { deleteUser };
};
