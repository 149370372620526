import { Languages } from '../../enums/language';

export const getFormattedLanguageName = (languange: Languages): string => {
  switch (languange) {
    case Languages.ENGLISH:
      return 'english';
    case Languages.GERMAN:
      return 'german';
    default:
      throw new Error('Unsupported Language');
  }
};
