import { regex } from '@metaswiss/lib';
import { FormInput, ThemedIcon, CoinUnbrokenIcon, ProductType } from '@metaswiss/ui-kit';
import { StyledError } from '@metaswiss/ui-kit/src/components/atoms/input/input.styles';
import { Path, FieldError, FieldValues, Control } from 'react-hook-form';
import styled from 'styled-components';

import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { OneLineElement } from '../../../../public/register/shared/styles/registrationStyles';

type QunatityValidationProps<T extends FieldValues> = {
  control: Control<T>;
  quantityName: Path<T>;
  error?: FieldError;
  totalAmountError: boolean;
  totalAmoutMsgError: string;
  maxInvestmentProductLength: number;
  disabled?: boolean;
  productType: ProductType;
};

export const WrapperInput = styled.div`
  position: relative;
`;

export const StyledCustomError = styled(StyledError)`
  top: 100%;
`;

export const QuantityValidation = <T extends FieldValues>({
  productType,
  control,
  quantityName,
  error,
  maxInvestmentProductLength,
  totalAmountError,
  totalAmoutMsgError,
}: QunatityValidationProps<T>) => {
  const { textTranslation } = useTextTranslation();

  return (
    <OneLineElement>
      <WrapperInput>
        <FormInput
          name={quantityName}
          error={error}
          control={control}
          min={0}
          maxLength={maxInvestmentProductLength}
          renderStartIcon={() => (
            <ThemedIcon icon={CoinUnbrokenIcon} palette="neutrals" strokeColor="hue700" size="full" />
          )}
          fill
          customErrorExists={totalAmountError}
          pattern={regex.numbersOnly}
          color={'hue100'}
          label={textTranslation(
            productType === ProductType.ACTIONS ? 'payment.amountOfShares' : 'payment.amountOfBonds'
          )}
        />
        {quantityName && totalAmountError && !error && <StyledCustomError>{totalAmoutMsgError}</StyledCustomError>}
      </WrapperInput>
    </OneLineElement>
  );
};
