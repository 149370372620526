import { BaseCard, Text } from '@metaswiss/ui-kit';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import { InstructionCounter, InstructionWrapper, StyledInstarctionsWrapper } from './paymentBank.styles';

export type PaymentBankCardProps = {
  instructions: string[];
  title: string;
};

export const PaymentBankCard: FC<PaymentBankCardProps> = ({ instructions, title }) => {
  const theme = useTheme();
  return (
    <BaseCard
      padding="1.25rem"
      height="auto"
      disableStateEffects
      override={{
        borderRadius: theme.v2.componentsRadius.cards.default,
        backgroundColor: theme.v2.surface.tertiary,
        borderColor: theme.v2.border.primary,
      }}
    >
      <Text color={theme.v2.text.headingPrimary}>{title}</Text>
      <StyledInstarctionsWrapper>
        {instructions.map((item, index) => (
          <InstructionWrapper key={`key ${index}`}>
            <InstructionCounter>{index + 1}</InstructionCounter>
            <Text fontSize="sm" lineHeight="medium" fontWeight="semi" color={theme.v2.text.headingPrimary}>
              {item}
            </Text>
          </InstructionWrapper>
        ))}
      </StyledInstarctionsWrapper>
    </BaseCard>
  );
};
