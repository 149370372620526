import { FC } from 'react';

import { IconProps } from './iconProps';

export const PortfolioShareIcon: FC<IconProps> = ({ color = 'none', strokeColor = 'white' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 228 129" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.1">
        <path
          d="M59.3774 98.9267L33.7492 3.28091M107.2 86.1126L92.2505 30.3192M155.023 73.2985L120.185 -56.72M202.846 60.4844L177.218 -35.1614"
          stroke={strokeColor}
          strokeWidth="12"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
