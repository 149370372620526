import { formatAmount, getFirstSixDigitsOfSerialNumber } from '@metaswiss/lib';
import { DataStatusPage, FlatList, NoResourcesIcon, PageStateContainer, ThemedIcon } from '@metaswiss/ui-kit';
import { NftHolder } from '@metaswiss/ui-kit/src/components/molecules/nft-holder/NftHolder';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi';
import { Filters } from '../../../../../components/filters/Filters.tsx';
import { NumberOfItems } from '../../../../../components/number-of-items/NumberOfItems.tsx';
import { Ribbon } from '../../../../../components/ribbon/Ribbon.tsx';
import { FilterType } from '../../../../../enums/filterType.enum.ts';
import { NftStatus } from '../../../../../enums/nftStatus.ts';
import { ApiResource } from '../../../../../enums/resource.enum';
import { AppState, useAppState, useHeaderOptions, useShellNavigationState } from '../../../../../global-state/zustand';
import { useGetCollectionByParams } from '../../../../../hooks/use-get-collection-by-params/useGetCollectionByParams.ts';
import { usePagination } from '../../../../../hooks/use-pagination/usePagination';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../router/routes';
import { getQueryKey } from '../../../../../shared/helpers/getQueryKey.helper';
import { NftCommonResponse, NftCommonStatusEnum } from '../nft.response.ts';

type QueryType = {
  status?: Array<'available' | 'sold' | 'reserved' | 'owned'>;
};

export const Collectibles = () => {
  const { textTranslation } = useTextTranslation();
  const { removeTabs } = useShellNavigationState();
  const { setHeaderTitle, search, selectedFilters, setHeaderLoading } = useHeaderOptions();

  const theme = useTheme();
  const currency = useAppState((state: AppState) => state.currency);
  const navigate = useNavigate();

  const [query, setQuery] = useState<QueryType>({});

  const {
    id: collectionId,
    collection,
    isError: isErrorCollection,
    refetch: refetchCollection,
  } = useGetCollectionByParams();

  const nftFilterOptions = useMemo(
    () => [
      {
        id: '1',
        title: textTranslation('global.status'),
        param: FilterType.STATUS,
        options: [
          {
            id: '1',
            label: textTranslation('offering.nftStatus.sold'),
            value: NftStatus.SOLD,
          },
          {
            id: '2',
            label: textTranslation('offering.nftStatus.reserved'),
            value: NftStatus.RESERVED,
          },
          {
            id: '3',
            label: textTranslation('offering.nftStatus.owned'),
            value: NftStatus.OWNED,
          },
          {
            id: '4',
            label: textTranslation('offering.nftStatus.available'),
            value: NftStatus.AVAILABLE,
          },
        ],
      },
    ],
    []
  );

  const { data, totalItems, lastItemRef, isLoading, isFetchingNextPage } = usePagination<NftCommonResponse>({
    queryKey: getQueryKey(ApiResource.COLLECTIBLES, {
      collectionId: collectionId,
      status: query.status,
      search: search.value,
    }),
    queryFn: (offset: number, limit: number) =>
      api.nft.getNftByCollectionId(collectionId, offset, limit, undefined, query.status, search.value || undefined),
    enabled: !!collectionId,
    limit: 30,
  });

  const onTryAgain = async () => {
    await refetchCollection();
  };

  useEffect(() => {
    removeTabs();
    setHeaderTitle(collection?.name);
  }, [removeTabs, setHeaderTitle, textTranslation, collection?.name]);

  useEffect(() => {
    setHeaderLoading(!collection?.name);
  }, [collection?.name]);

  return (
    <>
      <Filters filterOptions={nftFilterOptions} setQuery={setQuery} totalItems={totalItems} />
      <PageStateContainer
        showLoading={true}
        isLoading={isLoading || search.isUpdating}
        showError={true}
        isError={isErrorCollection}
        showEmptyState={true}
        isEmptyState={!isLoading && !data?.length && !totalItems && !search.isUpdating}
        customEmptyStateComponent={
          selectedFilters.length > 0 || !!search.value ? (
            <DataStatusPage
              title={textTranslation('account.noResultsFound')}
              content={textTranslation('account.noResultsFoundSub')}
            />
          ) : (
            <DataStatusPage
              icon={
                <ThemedIcon
                  icon={() => (
                    <NoResourcesIcon
                      foregroundColor={theme.colors.primary.hue300}
                      backgroundColor={theme.colors.primary.hue100}
                    />
                  )}
                  size={'full'}
                />
              }
              title={textTranslation('offering.nothingHereYet')}
              content={textTranslation('offering.noNfts')}
              iconWidth={'11.185rem'}
              iconHeight={'11.875rem'}
            />
          )
        }
        onTryAgain={onTryAgain}
        textTranslation={textTranslation}
      >
        <FlatList
          data={data || []}
          isFetching={isFetchingNextPage}
          keyExtractor={(item) => item.id}
          numColumns={'auto-fill'}
          headerComponent={<NumberOfItems numberOfAssets={totalItems} />}
          ref={lastItemRef}
          renderItem={(item) => (
            <NftHolder
              serialNumber={getFirstSixDigitsOfSerialNumber(item.serialNumber)}
              price={formatAmount(item.price, currency?.decimals, currency?.rate, currency?.currencyCode)}
              priceText={textTranslation('global.price')}
              imageUrl={item.thumbnailUrl}
              isFavouriteFeatureAvailable={false}
              buyText={textTranslation('offering.buyNow')}
              isAvailable={false}
              onClick={() =>
                navigate(`${routes.offering.smartNfts.collections}/${collectionId}/collectibles/${item.id}`)
              }
              children={
                <>
                  {item?.status !== NftCommonStatusEnum.AVAILABLE && (
                    <Ribbon
                      status={item?.status as NftCommonStatusEnum}
                      fontSize={'sm'}
                      lineHeight={'medium'}
                      padding={'0.5rem 0'}
                    />
                  )}
                </>
              }
            />
          )}
        />
      </PageStateContainer>
    </>
  );
};
