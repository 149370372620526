import { differenceInYears, parse } from 'date-fns';
import * as zod from 'zod';

import { FormNames } from '../../../../../../../../shared/forms/formNames';
import { staticTranslationFunction } from '../../../../../../../../shared/forms/staticTranslationFunction.ts';
import { regex } from '../../../../../../../../shared/regex/regex.ts';

const isOldEnough = (date: string) => {
  const birthDate = parse(date, 'dd/MM/yyyy', new Date());
  const age = differenceInYears(new Date(), birthDate);
  if (age < 18) {
    return true;
  }
};

export const createFullPersonalInformationSchema = (translate: (key: string) => string) =>
  zod.object({
    firstName: zod.string().trim().nonempty(translate('error.requiredField')),
    lastName: zod.string().trim().nonempty(translate('error.requiredField')),
    email: zod.string().nonempty(translate('error.requiredField')).email(),
    password: zod.string().nonempty(translate('error.requiredField')).min(5),
    passwordConfirmation: zod.string().nonempty(translate('error.requiredField')).min(5),
    citizenship: zod.object({
      value: zod.string().nonempty(translate('error.requiredField')),
      label: zod.string().nonempty(translate('error.requiredField')),
      iconSrc: zod.string().optional(),
      meta: zod.string().optional(),
    }),
    phoneNumberPrefix: zod.string().nonempty(translate('error.requiredField')),
    phoneNumber: zod
      .string()
      .nonempty(translate('error.requiredField'))
      .regex(regex.numbersOnly, translate('error.invalidPhoneNumber'))
      .min(5, { message: translate('error.invalidPhoneNumberMinLength') })
      .max(15, { message: translate('error.invalidPhoneNumberMaxLength') }),
    birthDate: zod
      .string()
      .nonempty(translate('error.requiredField'))
      .refine((value) => !isOldEnough(value), {
        message: translate('error.isNot18YearsOld'),
      }),
    termsAndConditions: zod.literal<boolean>(true),
  });

export const schema = createFullPersonalInformationSchema(staticTranslationFunction);

export type FullPrivateRegistrationPersonalInfoFormData = zod.infer<typeof schema>;

export const fullRegistrationPrivatePersonalInfoSchema: FormNames<FullPrivateRegistrationPersonalInfoFormData> = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  password: 'password',
  passwordConfirmation: 'passwordConfirmation',
  citizenship: 'citizenship',
  phoneNumberPrefix: 'phoneNumberPrefix',
  phoneNumber: 'phoneNumber',
  birthDate: 'birthDate',
  termsAndConditions: 'termsAndConditions',
} as const;
