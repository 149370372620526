import { FC } from 'react';

import { RemSize } from '../../../theme';

import { Container, GradientContainer } from './skeleton.styles';

type SkeletonProps = {
  width?: RemSize | '100%';
  height?: RemSize;
};

export const Skeleton: FC<SkeletonProps> = ({ width = '3rem', height }) => {
  const widthWithoutRem = Number(width?.replace('rem', ''));
  const duration = widthWithoutRem * 0.2;

  return (
    <Container $width={width} $height={height}>
      <GradientContainer
        initial={{ x: `-100%` }}
        animate={{ x: `${width}` }}
        transition={{ duration: duration, repeat: Infinity, repeatType: 'loop' }}
      />
    </Container>
  );
};
