export const Xicon = ({ strokeColor = '#222223' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.75 5.25L5.25 12.75M12.75 12.75L5.25 5.25"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
