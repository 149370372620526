import styled, { css } from 'styled-components';

import { BaseCard } from '../../cards';

export const NftHolderBaseCard = styled(BaseCard)<{ $displayRemoveButton?: boolean }>`
  &:hover {
    ${({ $displayRemoveButton }) =>
      !$displayRemoveButton
        ? css`
            cursor: pointer;
            box-shadow: 0 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.1);

            img {
              transform: scale(1.25);
            }
          `
        : css`
            ${WrapperHoverButton} {
              opacity: 1;
            }
          `}
  }

  width: 100%;
  overflow: hidden;
`;

export const NftHolderContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
`;

export const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  aspect-ratio: 1/1;
`;

export const NftImage = styled.img`
  min-width: auto;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: all 0.2s ease-in;
`;

export const StyledTextContainer = styled.div<{ $isCollectionNameIncluded?: boolean }>`
  width: 100%;
  height: ${({ $isCollectionNameIncluded }) => ($isCollectionNameIncluded ? '4rem' : '3rem')};
  display: flex;
  flex-direction: column;
`;

export const BuyContainer = styled.div`
  width: calc(100% + 1.125rem);
  right: 0.5625rem;
  height: 3.5rem;
  background: ${({ theme }) => theme.colors.primary.hue300};
  position: relative;
  border-radius: 0 0 0.5rem 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
`;

export const SerialNumberWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const PriceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const IconWrapper = styled.div`
  height: 2.25rem;
  width: 2.25rem;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  padding: 0.5rem;
  background: #00000080;
`;

export const BuyButtonContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem 1rem 1rem 2rem;
  align-items: center;
  justify-content: space-between;
`;

export const VectorIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  height: 100%;
  align-items: center;
`;

export const VectorDiv = styled.div`
  height: 100%;
  border: 0.5px solid ${({ theme }) => theme.v2.surface.action1Hover};
`;

export const BuyIconWrapper = styled.div`
  height: 2.25rem;
  width: 2.25rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.v2.surface.action1Hover};
`;

export const NftErrorWrapper = styled.div<{ $isAbsolute?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  flex-direction: column;
  position: ${({ $isAbsolute }) => ($isAbsolute ? 'absolute' : 'relative')};
`;

export const WrapperHoverButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 1;
  background-color: ${({ theme }) => `${theme.v2.text.headingPrimary}1F`};
  backdrop-filter: blur(6px);
  opacity: 0;
  transition: opacity 0.3s 0.1s;
`;
