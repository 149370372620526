import { FC } from 'react';

import { IconProps } from './iconProps';

export const PhoneIcon: FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.5511 22.028C24.5511 22.028 23.0062 23.5454 22.6276 23.9902C22.0109 24.6483 21.2843 24.9591 20.3317 24.9591C20.2401 24.9591 20.1424 24.9591 20.0508 24.953C18.2372 24.8373 16.5519 24.1304 15.2879 23.5271C11.8318 21.8574 8.79694 19.4869 6.27506 16.4827C4.19282 13.9781 2.80059 11.6625 1.87855 9.17622C1.31066 7.65887 1.10305 6.47667 1.19464 5.36151C1.25571 4.64854 1.53049 4.05744 2.03731 3.55166L4.11954 1.47367C4.41875 1.19336 4.73628 1.04102 5.0477 1.04102C5.43239 1.04102 5.74381 1.27258 5.93921 1.46758C5.94532 1.47367 5.95142 1.47977 5.95753 1.48586C6.33001 1.83321 6.68417 2.19274 7.05666 2.57665C7.24595 2.77165 7.44135 2.96665 7.63675 3.16775L9.30376 4.83135C9.95103 5.47729 9.95103 6.07448 9.30376 6.72043C9.12668 6.89715 8.9557 7.07386 8.77862 7.24449C8.2657 7.76856 8.66863 7.36645 8.13738 7.84176C8.12517 7.85395 8.11296 7.86004 8.10685 7.87223C7.58171 8.3963 7.67941 8.90817 7.78933 9.25552C7.79543 9.2738 7.80154 9.29208 7.80765 9.31036C8.24119 10.3585 8.85182 11.3457 9.77997 12.5218L9.78608 12.5279C11.4714 14.5998 13.2483 16.2146 15.2084 17.4517C15.4588 17.6101 15.7153 17.7381 15.9595 17.8599C16.1793 17.9696 16.3869 18.0732 16.564 18.1829C16.5885 18.1951 16.6129 18.2134 16.6373 18.2256C16.8449 18.3292 17.0403 18.3779 17.2418 18.3779C17.7486 18.3779 18.0662 18.061 18.17 17.9574L19.3669 16.763C19.5745 16.5558 19.9042 16.306 20.2889 16.306C20.6675 16.306 20.9789 16.5436 21.1682 16.7508C21.1743 16.7569 21.1743 16.7569 21.1804 16.763L24.545 20.1207C25.1739 20.7422 24.5511 22.028 24.5511 22.028Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
