import { useTheme } from 'styled-components';

import { RemSize } from '../../../theme';
import { Heading } from '../heading';
import { Text } from '../text';

import { FeedbackContainer } from './styles/feedbackContainer';
import { ImageContainer } from './styles/imageContainer';
import { TextContainer } from './styles/textContainer';

export type Props = {
  title: string;
  subTitle: string;
  icon: JSX.Element;
  imageWidth?: string;
  titleRender?: (title: string) => JSX.Element;
  subTitleRender?: (subTitle: string) => JSX.Element;
  fill?: boolean;
  height?: RemSize | 'auto' | '100%';
  textGap?: RemSize;
  contentGap?: RemSize;
  textWidth?: RemSize | 'max-content';
  hasButton?: boolean;
};

export const BaseFeedback: React.FC<Props> = ({
  title,
  titleRender,
  subTitle,
  subTitleRender,
  icon,
  fill,
  height = 'auto',
  imageWidth = 'auto',
  textGap = '0.5rem',
  contentGap = '2rem',
  textWidth = 'max-content',
  hasButton = false,
}) => {
  const theme = useTheme();
  return (
    <FeedbackContainer $fill={fill} $height={height} $contentGap={contentGap} $hasButton={hasButton}>
      <ImageContainer $imageWidth={imageWidth}>{icon}</ImageContainer>
      <TextContainer $textGap={textGap} $textWidth={textWidth}>
        {titleRender ? titleRender(title) : <Heading headingType="h4" fontWeight="bold" children={title} />}
        {subTitleRender ? subTitleRender(subTitle) : <Text color={theme.v2.text.disabled} children={subTitle} />}
      </TextContainer>
    </FeedbackContainer>
  );
};
