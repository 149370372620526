import styled from 'styled-components';

export const InputItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 1rem;
  padding-top: 0.25rem;
  margin-top: -0.5rem;
`;

export const InputWrapper = styled.div`
  width: 100%;
  height: 3.5rem;
  padding: 0 1rem;
`;

export const RegularInputContainer = styled.div`
  width: 100%;
  margin-bottom: 1.25rem;
  height: 3.5rem;
  margin-top: 0.5rem;
`;
