import { FC } from 'react';

import { IconProps } from './iconProps.ts';

export const ErrorPageIcon: FC<IconProps> = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 272 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.1"
        d="M269.888 136.172H241.289C243.378 127.762 244.486 118.971 244.486 109.926C244.486 91.9463 240.109 74.9754 232.35 59.9919H252.632C253.798 59.9919 254.743 59.0593 254.743 57.9089C254.743 56.7584 253.798 55.8258 252.632 55.8258H230.075C210.934 22.4944 174.652 0 133.043 0C105.368 0 80.0501 9.95247 60.5638 26.425H26.2467C25.0803 26.425 24.1349 27.3576 24.1349 28.5081C24.1349 29.6585 25.0803 30.5911 26.2467 30.5911H55.9059C53.5375 32.8322 51.2696 35.1757 49.1088 37.614H2.1118C0.945483 37.614 0 38.5466 0 39.697C0 40.8475 0.945483 41.7801 2.1118 41.7801H45.5952C30.5713 60.5127 21.6007 84.1857 21.6007 109.926C21.6007 124.33 24.4108 138.087 29.5181 150.694H23.9538C22.7875 150.694 21.8421 151.627 21.8421 152.777C21.8421 153.927 22.7875 154.86 23.9538 154.86H31.3082C32.0764 156.55 32.8865 158.216 33.7374 159.859H8.74889C7.58257 159.859 6.63709 160.792 6.63709 161.943C6.63709 163.093 7.58257 164.026 8.74889 164.026H36.0104C38.6151 168.561 41.5365 172.896 44.746 177H221.341C227.34 169.328 232.333 160.853 236.128 151.765H256.855C258.022 151.765 258.967 150.833 258.967 149.682C258.967 148.532 258.022 147.599 256.855 147.599H237.768C238.649 145.216 239.449 142.795 240.165 140.338H269.888C271.055 140.338 272 139.406 272 138.255C272 137.105 271.054 136.172 269.888 136.172Z"
        fill="#767B83"
      />
      <g opacity="0.3">
        <path
          d="M178.558 177.433H165.156C165.156 176.469 165.941 175.688 166.908 175.688H176.806C177.773 175.688 178.558 176.469 178.558 177.433Z"
          fill="#A6ABB2"
        />
        <path d="M175.38 175.687H168.328L170.62 110.613L172.16 111.633L175.38 175.687Z" fill="#A6ABB2" />
        <path
          d="M177.558 177.432H164.156C164.156 176.468 164.941 175.687 165.908 175.687H175.806C176.773 175.687 177.558 176.468 177.558 177.432Z"
          fill="#A6ABB2"
        />
        <path d="M174.097 175.687H167.625L169.917 110.613H171.805L174.097 175.687Z" fill="#A6ABB2" />
        <path
          d="M172.474 111.005C172.685 110.118 172.133 109.229 171.242 109.019C170.351 108.809 169.458 109.359 169.248 110.247C169.038 111.134 169.589 112.023 170.48 112.233C171.371 112.443 172.264 111.893 172.474 111.005Z"
          fill="#A6ABB2"
        />
        <path
          d="M170.859 111.188L204.58 130.582L204.212 128.673C204.139 128.297 203.931 127.959 203.627 127.725L181.596 110.737C181.193 110.426 180.664 110.328 180.175 110.473L175.756 111.789C175.339 111.913 174.889 111.86 174.512 111.643L171.575 109.954L170.859 111.188Z"
          fill="#A6ABB2"
        />
        <path
          d="M170.143 109.955L136.422 129.349L138.263 129.985C138.629 130.111 139.028 130.1 139.386 129.954L165.163 119.445C165.638 119.252 165.989 118.842 166.108 118.345L167.172 113.883C167.273 113.459 167.545 113.095 167.924 112.877L170.858 111.189L170.143 109.955Z"
          fill="#A6ABB2"
        />
        <path
          d="M171.572 109.954V71.166L170.098 72.4374C169.806 72.6891 169.616 73.0383 169.564 73.4193L165.811 100.915C165.742 101.42 165.922 101.927 166.294 102.277L169.644 105.429C169.962 105.727 170.142 106.143 170.142 106.577V109.954L171.572 109.954Z"
          fill="#A6ABB2"
        />
        <path
          d="M170.746 112.022C171.581 112.022 172.258 111.348 172.258 110.517C172.258 109.685 171.581 109.011 170.746 109.011C169.911 109.011 169.234 109.685 169.234 110.517C169.234 111.348 169.911 112.022 170.746 112.022Z"
          fill="#A6ABB2"
        />
        <path d="M137.02 129.554L169.234 110.515L169.205 110.493L136.422 129.348L137.02 129.554Z" fill="#A6ABB2" />
        <path d="M174.098 175.265H167.641V175.687H174.098V175.265Z" fill="#A6ABB2" />
        <path d="M171.094 71.5792L171.537 109.154L171.571 109.14V71.166L171.094 71.5792Z" fill="#A6ABB2" />
        <path d="M204.461 129.963L171.802 111.691L171.797 111.728L204.58 130.582L204.461 129.963Z" fill="#A6ABB2" />
        <path
          d="M222.58 177.433H201.125C201.125 175.89 202.381 174.639 203.93 174.639H219.775C221.325 174.639 222.58 175.89 222.58 177.433Z"
          fill="#767B83"
        />
        <path d="M217.501 174.639H206.211L209.88 70.457L212.346 72.0898L217.501 174.639Z" fill="#767B83" />
        <path
          d="M220.987 177.434H199.531C199.531 175.891 200.787 174.64 202.336 174.64H218.182C219.731 174.64 220.987 175.891 220.987 177.434Z"
          fill="#A6ABB2"
        />
        <path
          d="M210.258 71.3792L264.244 102.429L263.587 99.0214C263.514 98.6445 263.306 98.3069 263.001 98.0719L227.062 70.359C226.658 70.0477 226.129 69.9495 225.64 70.0951L217.705 72.4569C217.288 72.5811 216.837 72.5286 216.46 72.3117L211.403 69.4033L210.258 71.3792Z"
          fill="#A6ABB2"
        />
        <path
          d="M209.112 69.4033L155.125 100.453L158.415 101.589C158.78 101.715 159.178 101.704 159.535 101.558L202.349 84.1037L204.609 74.6277L210.257 71.3795L209.112 69.4033Z"
          fill="#A6ABB2"
        />
        <path
          d="M211.397 69.4035V7.30469L208.765 9.57476C208.473 9.82654 208.283 10.1757 208.231 10.5568L202.108 55.4148C202.039 55.9198 202.219 56.427 202.591 56.777L208.609 62.4391C208.927 62.7377 209.107 63.1534 209.107 63.5883V69.4034L211.397 69.4035Z"
          fill="#A6ABB2"
        />
        <path d="M215.44 174.64H205.078L208.748 70.458H211.771L215.44 174.64Z" fill="#A6ABB2" />
        <path
          d="M212.716 71.4667C213.277 70.1178 212.634 68.5714 211.28 68.0127C209.926 67.4539 208.373 68.0945 207.812 69.4433C207.252 70.7922 207.895 72.3387 209.249 72.8974C210.603 73.4562 212.155 72.8156 212.716 71.4667Z"
          fill="#767B83"
        />
        <path
          d="M212.32 71.2214C212.831 69.9914 212.245 68.5812 211.01 68.0717C209.775 67.5622 208.359 68.1463 207.848 69.3763C207.337 70.6064 207.923 72.0166 209.158 72.5261C210.392 73.0356 211.808 72.4515 212.32 71.2214Z"
          fill="#A6ABB2"
        />
        <path d="M156.082 100.783L207.656 70.3023L207.61 70.2666L155.125 100.452L156.082 100.783Z" fill="#767B83" />
        <path d="M215.44 173.962H205.102V174.638H215.44V173.962Z" fill="#767B83" />
        <path d="M210.633 7.96526L211.343 68.121L211.397 68.0987V7.30371L210.633 7.96526Z" fill="#767B83" />
        <path d="M264.051 101.437L211.766 72.1846L211.758 72.2423L264.243 102.428L264.051 101.437Z" fill="#767B83" />
      </g>
      <g opacity="0.3">
        <path
          d="M50.7479 126.991C43.8396 126.991 38.1875 121.36 38.1875 114.479V85.6772C38.1875 78.7955 43.8396 73.165 50.7479 73.165C57.6561 73.165 63.3083 78.7955 63.3083 85.6772V114.479C63.3083 121.36 57.6561 126.991 50.7479 126.991Z"
          fill="#CED2DA"
        />
        <path
          d="M51.433 177.495H50.0625V93.1777C50.0625 92.8007 50.3693 92.4951 50.7477 92.4951C51.1261 92.4951 51.4329 92.8007 51.4329 93.1777V177.495H51.433Z"
          fill="#767B83"
        />
        <path
          d="M51.4329 104.605L47.6225 98.0308C47.4332 97.7044 47.0142 97.5926 46.6865 97.781C46.3587 97.9695 46.2465 98.3869 46.4356 98.7134L50.2461 105.288L51.4329 104.605Z"
          fill="#767B83"
        />
        <path
          d="M51.2494 112.468L55.0598 105.894C55.249 105.568 55.1367 105.15 54.8089 104.962C54.4812 104.773 54.0621 104.885 53.8729 105.212L50.0625 111.786L51.2494 112.468Z"
          fill="#767B83"
        />
        <path
          d="M82.8249 113.287C74.0524 113.287 66.875 106.137 66.875 97.3979V60.8241C66.875 52.0854 74.0524 44.9355 82.8249 44.9355C91.5974 44.9355 98.7747 52.0854 98.7747 60.8241V97.3979C98.7747 106.137 91.5972 113.287 82.8249 113.287Z"
          fill="#CED2DA"
        />
        <path
          d="M83.6934 177.419H81.9531V70.3482C81.9531 69.8695 82.3427 69.4814 82.8233 69.4814C83.3038 69.4814 83.6934 69.8695 83.6934 70.3482V177.419Z"
          fill="#767B83"
        />
        <path
          d="M83.6969 84.8604L78.8582 76.5117C78.618 76.0971 78.0857 75.955 77.6696 76.1944C77.2535 76.4338 77.1108 76.9639 77.3511 77.3785L82.1898 85.7272L83.6969 84.8604Z"
          fill="#767B83"
        />
        <path
          d="M83.4603 94.8453L88.299 86.4966C88.5393 86.0821 88.3967 85.5519 87.9804 85.3126C87.5643 85.0732 87.0321 85.2153 86.7918 85.6298L81.9531 93.9785L83.4603 94.8453Z"
          fill="#767B83"
        />
      </g>
      <path
        d="M58.4904 172.763C59.2224 172.077 59.5569 171.295 59.2684 170.744C58.9111 170.062 57.7157 169.958 56.4398 170.438C56.505 170.272 56.5656 170.102 56.6208 169.926C57.3009 167.763 56.9124 165.716 55.7533 165.355C54.6235 165.002 53.1785 166.381 52.4765 168.454C51.8949 166.079 50.4019 164.404 49.1218 164.704C47.9165 164.986 47.3259 166.919 47.7137 169.135C45.9478 168.358 44.244 168.42 43.7012 169.357C43.1392 170.326 44.0147 171.901 45.6826 173.048C44.8919 173.312 44.3482 173.795 44.2738 174.401C44.1825 175.144 44.8227 175.878 45.838 176.331C45.7817 176.475 45.7667 176.621 45.7998 176.761C45.9057 177.209 46.4761 177.486 47.2221 177.515V177.518H47.3332C47.336 177.518 47.339 177.518 47.3419 177.518H56.7635V177.51C56.9473 177.486 57.0843 177.409 57.1309 177.285C57.162 177.202 57.1487 177.108 57.1001 177.014C57.596 176.966 57.9692 176.771 58.0917 176.445C58.1872 176.191 58.1155 175.897 57.9173 175.61C59.2055 175.524 60.1954 174.961 60.2268 174.238C60.2542 173.602 59.5325 173.026 58.4904 172.763Z"
        fill="#A6ABB2"
      />
      <path
        d="M78.3237 168.773C79.6699 167.513 80.285 166.074 79.7544 165.061C79.0974 163.808 76.899 163.616 74.5525 164.498C74.6724 164.194 74.7838 163.88 74.8853 163.557C76.1359 159.579 75.4216 155.814 73.2898 155.15C71.2119 154.501 68.5545 157.037 67.2635 160.85C66.194 156.481 63.4483 153.401 61.0941 153.953C58.8776 154.472 57.7912 158.027 58.5044 162.102C55.2569 160.674 52.1235 160.787 51.1251 162.51C50.0915 164.293 51.7015 167.189 54.7689 169.298C53.3146 169.784 52.3148 170.672 52.178 171.786C52.01 173.154 53.1873 174.502 55.0547 175.335C54.9512 175.601 54.9235 175.868 54.9845 176.126C55.1792 176.951 56.2282 177.46 57.6001 177.514V177.519H57.8044C57.8097 177.519 57.815 177.519 57.8203 177.519H75.147V177.504C75.4849 177.459 75.737 177.318 75.8227 177.089C75.88 176.937 75.8556 176.765 75.7661 176.592C76.678 176.505 77.3643 176.145 77.5896 175.545C77.7651 175.078 77.6333 174.538 77.2689 174.009C79.638 173.851 81.4584 172.817 81.5162 171.486C81.5674 170.316 80.2402 169.257 78.3237 168.773Z"
        fill="#CED2DA"
      />
      <path
        d="M196.29 169.674C195.091 168.552 194.543 167.271 195.016 166.369C195.601 165.252 197.558 165.081 199.648 165.867C199.541 165.596 199.442 165.316 199.351 165.029C198.238 161.487 198.874 158.135 200.772 157.543C202.622 156.965 204.989 159.224 206.138 162.619C207.091 158.728 209.536 155.986 211.632 156.477C213.606 156.939 214.573 160.105 213.938 163.733C216.83 162.462 219.62 162.563 220.509 164.097C221.429 165.685 219.995 168.263 217.264 170.142C218.559 170.574 219.449 171.364 219.571 172.356C219.72 173.574 218.672 174.775 217.009 175.517C217.102 175.754 217.126 175.992 217.072 176.221C216.898 176.956 215.964 177.409 214.743 177.457V177.461H214.561C214.556 177.461 214.551 177.461 214.547 177.461H199.118V177.448C198.817 177.408 198.593 177.282 198.516 177.079C198.465 176.943 198.487 176.79 198.567 176.636C197.755 176.558 197.144 176.238 196.943 175.704C196.787 175.288 196.904 174.807 197.229 174.336C195.119 174.195 193.498 173.275 193.447 172.09C193.401 171.048 194.583 170.105 196.29 169.674Z"
        fill="#A6ABB2"
      />
      <path
        d="M214.118 174.326C213.634 173.872 213.412 173.355 213.603 172.99C213.84 172.539 214.631 172.47 215.475 172.788C215.432 172.678 215.392 172.565 215.355 172.449C214.905 171.018 215.162 169.664 215.929 169.424C216.677 169.191 217.633 170.104 218.097 171.475C218.482 169.903 219.47 168.795 220.317 168.994C221.115 169.181 221.505 170.46 221.249 171.926C222.417 171.412 223.544 171.453 223.904 172.073C224.275 172.714 223.696 173.756 222.593 174.515C223.116 174.69 223.476 175.009 223.525 175.41C223.585 175.902 223.162 176.387 222.49 176.687C222.527 176.783 222.537 176.879 222.515 176.972C222.445 177.269 222.068 177.452 221.574 177.471V177.473H221.501C221.499 177.473 221.497 177.473 221.495 177.473H215.261V177.467C215.139 177.451 215.049 177.401 215.018 177.318C214.997 177.264 215.006 177.202 215.038 177.14C214.71 177.108 214.463 176.979 214.382 176.763C214.319 176.595 214.366 176.4 214.497 176.21C213.645 176.153 212.99 175.781 212.969 175.302C212.951 174.881 213.428 174.5 214.118 174.326Z"
        fill="#CED2DA"
      />
      <path
        d="M138.15 170.124C137.02 169.066 136.504 167.859 136.949 167.008C137.501 165.956 139.346 165.795 141.315 166.536C141.215 166.28 141.121 166.016 141.036 165.746C139.986 162.407 140.586 159.247 142.375 158.689C144.119 158.145 146.35 160.274 147.433 163.474C148.331 159.807 150.635 157.222 152.611 157.684C154.472 158.12 155.384 161.105 154.785 164.525C157.511 163.326 160.141 163.421 160.979 164.867C161.846 166.364 160.495 168.794 157.92 170.565C159.141 170.973 159.98 171.718 160.095 172.653C160.236 173.801 159.248 174.933 157.68 175.632C157.767 175.855 157.79 176.079 157.739 176.296C157.576 176.988 156.695 177.416 155.544 177.461V177.464H155.372C155.368 177.464 155.363 177.464 155.359 177.464H140.816V177.452C140.532 177.415 140.321 177.296 140.249 177.104C140.201 176.977 140.221 176.833 140.296 176.687C139.531 176.613 138.955 176.312 138.766 175.808C138.619 175.416 138.729 174.963 139.035 174.519C137.046 174.386 135.519 173.518 135.47 172.402C135.428 171.419 136.541 170.53 138.15 170.124Z"
        fill="#CED2DA"
      />
      <path
        d="M154.495 173.307C153.855 172.708 153.562 172.024 153.815 171.542C154.127 170.946 155.172 170.855 156.288 171.274C156.231 171.129 156.178 170.98 156.13 170.827C155.535 168.936 155.875 167.146 156.888 166.83C157.876 166.522 159.139 167.727 159.753 169.54C160.262 167.463 161.567 165.999 162.686 166.261C163.74 166.508 164.257 168.198 163.917 170.135C165.461 169.456 166.951 169.51 167.426 170.329C167.917 171.177 167.152 172.554 165.693 173.557C166.385 173.787 166.86 174.209 166.925 174.739C167.005 175.389 166.445 176.031 165.557 176.427C165.607 176.553 165.62 176.68 165.591 176.803C165.498 177.195 165 177.437 164.347 177.462V177.465H164.25C164.248 177.465 164.245 177.465 164.243 177.465H156.005V177.457C155.845 177.436 155.725 177.369 155.684 177.261C155.657 177.188 155.668 177.107 155.711 177.024C155.277 176.983 154.951 176.812 154.844 176.527C154.761 176.305 154.823 176.048 154.997 175.796C153.87 175.721 153.005 175.23 152.977 174.597C152.953 174.04 153.584 173.537 154.495 173.307Z"
        fill="#A6ABB2"
      />
      <path
        d="M162.786 74.4014C162.683 74.4014 162.584 74.4171 162.49 74.4462C162.494 74.3973 162.496 74.3479 162.496 74.2981C162.496 73.1546 161.565 72.2275 160.417 72.2275C159.465 72.2275 158.662 72.8656 158.416 73.7359C158.3 73.7033 158.177 73.6857 158.05 73.6857C157.306 73.6857 156.703 74.2863 156.703 75.0272C156.703 75.7681 157.306 76.3688 158.05 76.3688C158.073 76.3688 158.097 76.3682 158.12 76.367V76.3687H162.786C163.331 76.3687 163.773 75.9283 163.773 75.385C163.773 74.8419 163.331 74.4014 162.786 74.4014Z"
        fill="#CED2DA"
      />
      <path
        opacity="0.2"
        d="M126.491 51.7898C125.929 51.7898 125.403 51.9443 124.954 52.2128C124.489 50.2095 122.688 48.7155 120.535 48.7155C120.346 48.7155 120.16 48.7284 119.976 48.7509C118.973 46.5245 116.729 44.9736 114.121 44.9736C110.578 44.9736 107.706 47.8346 107.706 51.3637C107.706 51.4014 107.707 51.439 107.707 51.4765C107.279 51.2743 106.8 51.1608 106.294 51.1608C104.466 51.1608 102.984 52.6367 102.984 54.4574C102.984 56.2781 104.466 57.7538 106.294 57.7538H126.491C128.144 57.7538 129.484 56.4186 129.484 54.7718C129.485 53.1248 128.144 51.7898 126.491 51.7898Z"
        fill="#767B83"
      />
      <path
        opacity="0.2"
        d="M95.7114 122.802C96.081 122.802 96.4269 122.904 96.7224 123.08C97.0283 121.763 98.2131 120.78 99.629 120.78C99.7537 120.78 99.8762 120.788 99.9969 120.803C100.656 119.339 102.133 118.318 103.849 118.318C106.179 118.318 108.068 120.2 108.068 122.522C108.068 122.547 108.068 122.571 108.068 122.596C108.35 122.463 108.665 122.388 108.998 122.388C110.2 122.388 111.174 123.359 111.174 124.557C111.174 125.755 110.2 126.725 108.998 126.725H95.7114C94.6238 126.725 93.7422 125.847 93.7422 124.764C93.7422 123.68 94.6238 122.802 95.7114 122.802Z"
        fill="#767B83"
      />
      <path
        d="M227.863 165.718L227.832 165.837C227.829 165.85 227.747 166.157 227.545 166.537C227.357 166.89 227.026 167.374 226.513 167.676C226 167.977 225.415 168.032 225.014 168.025C224.583 168.018 224.273 167.94 224.26 167.937L224.141 167.907L224.172 167.788C224.175 167.775 224.257 167.468 224.459 167.088C224.646 166.735 224.978 166.251 225.491 165.95C226.004 165.648 226.589 165.593 226.99 165.6C227.421 165.608 227.731 165.685 227.744 165.688L227.863 165.718ZM224.448 167.726C224.775 167.784 225.672 167.887 226.389 167.466C227.107 167.044 227.449 166.213 227.556 165.899C227.229 165.842 226.332 165.738 225.615 166.16C224.897 166.582 224.555 167.413 224.448 167.726Z"
        fill="#A6ABB2"
      />
      <path
        d="M226.152 168.575C225.012 169.003 223.742 168.436 223.742 168.436C223.742 168.436 224.32 167.175 225.46 166.747C226.6 166.319 227.87 166.886 227.87 166.886C227.87 166.886 227.292 168.147 226.152 168.575Z"
        fill="#CED2DA"
      />
      <path
        d="M223.725 152.076L223.724 152.228C223.724 152.245 223.718 152.64 223.59 153.16C223.471 153.642 223.218 154.326 222.691 154.844C222.164 155.363 221.474 155.606 220.988 155.718C220.466 155.839 220.069 155.839 220.052 155.839L219.898 155.839L219.9 155.686C219.9 155.669 219.906 155.274 220.034 154.755C220.153 154.272 220.405 153.589 220.933 153.07C221.46 152.552 222.15 152.309 222.636 152.196C223.158 152.075 223.555 152.075 223.572 152.075L223.725 152.076ZM220.214 155.528C220.627 155.499 221.741 155.353 222.477 154.628C223.215 153.902 223.375 152.797 223.41 152.386C222.997 152.416 221.883 152.562 221.147 153.286C220.408 154.012 220.248 155.118 220.214 155.528Z"
        fill="#A6ABB2"
      />
      <path
        d="M222.52 156.037C221.275 156.897 219.57 156.596 219.57 156.596C219.57 156.596 219.885 154.9 221.13 154.04C222.375 153.18 224.079 153.481 224.079 153.481C224.079 153.481 223.765 155.177 222.52 156.037Z"
        fill="#CED2DA"
      />
      <path
        d="M65.6145 148.073L65.7117 148.156C65.7222 148.165 65.9729 148.38 66.237 148.739C66.4826 149.073 66.7859 149.6 66.8361 150.213C66.8863 150.826 66.673 151.395 66.485 151.764C66.2828 152.161 66.0705 152.414 66.0616 152.425L65.9791 152.522L65.882 152.439C65.8715 152.43 65.6209 152.215 65.3566 151.856C65.1111 151.522 64.8077 150.995 64.7574 150.382C64.7072 149.769 64.9207 149.2 65.1087 148.831C65.3109 148.434 65.5231 148.181 65.5321 148.171L65.6145 148.073ZM65.949 152.155C66.151 151.877 66.6533 151.09 66.5831 150.234C66.5128 149.375 65.8894 148.682 65.6447 148.44C65.4427 148.719 64.9403 149.505 65.0105 150.362C65.0809 151.22 65.7042 151.913 65.949 152.155Z"
        fill="#A6ABB2"
      />
      <path
        d="M67.5186 150.952C67.4038 152.204 66.2986 153.128 66.2986 153.128C66.2986 153.128 65.3794 152.02 65.4943 150.768C65.6091 149.515 66.7143 148.592 66.7143 148.592C66.7143 148.592 67.6334 149.699 67.5186 150.952Z"
        fill="#CED2DA"
      />
      <path
        d="M41.375 162.542L41.4822 162.535C41.4938 162.534 41.7714 162.517 42.1421 162.579C42.4866 162.636 42.9797 162.775 43.3711 163.115C43.7626 163.454 43.9695 163.921 44.074 164.253C44.1865 164.611 44.2077 164.887 44.2086 164.898L44.2163 165.005L44.1091 165.012C44.0975 165.013 43.82 165.03 43.4492 164.969C43.1046 164.911 42.6117 164.772 42.2202 164.432C41.8288 164.093 41.6219 163.626 41.5173 163.294C41.4048 162.937 41.3836 162.66 41.3828 162.649L41.375 162.542ZM43.982 164.802C43.9395 164.517 43.778 163.749 43.2311 163.275C42.683 162.8 41.8991 162.747 41.6094 162.745C41.6519 163.031 41.8134 163.798 42.3604 164.272C42.9085 164.747 43.6924 164.8 43.982 164.802Z"
        fill="#A6ABB2"
      />
      <path
        d="M44.2193 163.165C44.8886 163.986 44.7678 165.188 44.7678 165.188C44.7678 165.188 43.562 165.059 42.8929 164.239C42.2238 163.418 42.3444 162.216 42.3444 162.216C42.3444 162.216 43.5501 162.345 44.2193 163.165Z"
        fill="#CED2DA"
      />
      <path
        d="M191.57 159.286L191.691 159.283C191.704 159.283 192.016 159.276 192.429 159.362C192.813 159.442 193.359 159.621 193.782 160.02C194.206 160.418 194.416 160.951 194.518 161.328C194.628 161.734 194.639 162.045 194.639 162.058L194.643 162.178L194.523 162.181C194.51 162.181 194.197 162.188 193.785 162.102C193.401 162.022 192.855 161.843 192.431 161.444C192.008 161.046 191.797 160.513 191.695 160.136C191.586 159.73 191.574 159.419 191.574 159.406L191.57 159.286ZM194.39 161.939C194.355 161.617 194.209 160.749 193.618 160.193C193.025 159.635 192.149 159.54 191.824 159.525C191.859 159.847 192.004 160.714 192.596 161.271C193.189 161.829 194.065 161.923 194.39 161.939Z"
        fill="#A6ABB2"
      />
      <path
        d="M194.73 160.113C195.443 161.064 195.253 162.406 195.253 162.406C195.253 162.406 193.907 162.207 193.194 161.256C192.481 160.305 192.672 158.963 192.672 158.963C192.672 158.963 194.018 159.162 194.73 160.113Z"
        fill="#CED2DA"
      />
      <path
        d="M158.176 170.114H102.484L103.498 166.46L105.476 159.324L106.675 155.003L108.442 148.633L111.848 136.353L113.614 129.983L126.034 85.1992H134.626L139.178 101.612L147.046 129.983L148.813 136.353L152.219 148.633L153.986 155.003L157.163 166.46L158.176 170.114Z"
        fill="#F04438"
      />
      <path
        d="M158.176 170.114H102.484L103.498 166.46L105.476 159.324C144.285 157.04 139.178 101.612 139.178 101.612L157.163 166.46L158.176 170.114Z"
        fill="#F36960"
      />
      <path d="M169.567 170.114H91.0859V177.547H169.567V170.114Z" fill="#F04438" />
      <path d="M158.176 170.115H102.484L103.498 166.46H157.163L158.176 170.115Z" fill="#AA3028" />
      <path
        d="M148.812 136.353C148.812 136.353 147.646 160.871 119.367 166.479L157.162 166.459L148.812 136.353Z"
        fill="#DA3E33"
      />
      <path d="M148.809 136.354H111.844L113.61 129.983H147.042L148.809 136.354Z" fill="white" />
      <path d="M153.983 155.003H106.672L108.439 148.633H152.216L153.983 155.003Z" fill="white" />
      <path
        d="M151.418 170.114C151.418 170.114 141.015 177.938 102.484 177.547H169.571V170.114L151.418 170.114Z"
        fill="#DA3E33"
      />
      <path
        d="M181.101 180H78.4645C77.7679 180 77.2031 179.437 77.2031 178.743V178.707C77.2031 178.013 77.7678 177.45 78.4645 177.45H181.101C181.798 177.45 182.363 178.013 182.363 178.707V178.743C182.363 179.437 181.798 180 181.101 180Z"
        fill="#84251F"
      />
      <path
        d="M80.4132 180H63.7926C63.096 180 62.5312 179.437 62.5312 178.743V178.707C62.5312 178.013 63.0959 177.45 63.7926 177.45H80.4132C81.1099 177.45 81.6745 178.013 81.6745 178.707V178.743C81.6745 179.437 81.1099 180 80.4132 180Z"
        fill="#84251F"
      />
      <path
        d="M56.1687 180H38.7067C38.01 180 37.4453 179.437 37.4453 178.743V178.707C37.4453 178.013 38.01 177.45 38.7067 177.45H56.1686C56.8653 177.45 57.43 178.013 57.43 178.707V178.743C57.4301 179.437 56.8653 180 56.1687 180Z"
        fill="#84251F"
      />
      <path
        d="M203.26 180H188.925C188.229 180 187.664 179.437 187.664 178.743V178.707C187.664 178.013 188.229 177.45 188.925 177.45H203.26C203.956 177.45 204.521 178.013 204.521 178.707V178.743C204.521 179.437 203.956 180 203.26 180Z"
        fill="#84251F"
      />
      <path
        d="M222 180H211.097C210.401 180 209.836 179.437 209.836 178.743V178.707C209.836 178.013 210.401 177.45 211.097 177.45H222C222.697 177.45 223.261 178.013 223.261 178.707V178.743C223.261 179.437 222.697 180 222 180Z"
        fill="#84251F"
      />
    </svg>
  );
};
