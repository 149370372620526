import { CurrencyBitcoinIcon, Text, ThemedIcon, WalletIcon } from '@metaswiss/ui-kit';
import { FC } from 'react';
import { useTheme } from 'styled-components';

import {
  NotificationBaseCard,
  NotificationCardWrapper,
  NotificationContainer,
  NotificationDate,
  NotificationDot,
  NotificationIconWrapper,
  NotificationInfo,
  NotificationItemContent,
  NotificationItemIcon,
  TextEllipsisHelper,
} from './notificationCard.styles';
import { NotificationCardProps } from './types';

export const NotificationCard: FC<NotificationCardProps> = ({
  title,
  createdAt,
  isRead,
  content,
  onClick,
  selectedItem = false,
  type,
  dateSize,
  dateWeight,
  titleSize,
  titleWeight,
  descriptionSize,
  descriptionWeight,
  selectedItemRef,
}) => {
  const theme = useTheme();
  const getBackgroundColor = {
    EMAIL: theme.v2.surface.information,
    CUSTOM: theme.v2.surface.warning,
  };

  return (
    <NotificationBaseCard onClick={onClick} padding="0rem" disableStateEffects={true} removeBorder removeBackground>
      <NotificationCardWrapper ref={selectedItem ? selectedItemRef : null} $selectedItem={selectedItem}>
        <NotificationItemContent>
          <NotificationIconWrapper>
            <NotificationItemIcon style={{ backgroundColor: getBackgroundColor[type] }}>
              <ThemedIcon
                icon={type === 'EMAIL' ? WalletIcon : CurrencyBitcoinIcon}
                size="medium"
                customStrokeColor={theme.v2.icon.onAction}
              />
            </NotificationItemIcon>
          </NotificationIconWrapper>
          <NotificationInfo>
            <NotificationContainer>
              <Text
                fontSize={titleSize}
                fontWeight={titleWeight}
                lineHeight={'medium'}
                color={theme.v2.text.headingPrimary}
              >
                {title}
              </Text>
              <NotificationDate>
                <Text fontSize={dateSize} fontWeight={dateWeight} color={theme.v2.text.headingPrimary}>
                  {createdAt}
                </Text>
                {!isRead && <NotificationDot />}
              </NotificationDate>
            </NotificationContainer>
            <TextEllipsisHelper>
              <Text
                fontSize={descriptionSize}
                fontWeight={descriptionWeight}
                lineHeight={'extraSmall'}
                color={theme.v2.text.disabled}
              >
                {content}
              </Text>
            </TextEllipsisHelper>
          </NotificationInfo>
        </NotificationItemContent>
      </NotificationCardWrapper>
    </NotificationBaseCard>
  );
};
