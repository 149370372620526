import { FC } from 'react';

import { IconProps } from './iconProps';

export const CameraIcon: FC<IconProps> = ({ color, strokeColor }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.1999 6.12023V6.87023C7.46665 6.87023 7.71333 6.72855 7.84774 6.49814L7.1999 6.12023ZM8.8799 3.24023V2.49023C8.61315 2.49023 8.36648 2.63192 8.23207 2.86233L8.8799 3.24023ZM15.1199 3.24023L15.7677 2.86233C15.6333 2.63192 15.3867 2.49023 15.1199 2.49023V3.24023ZM16.7999 6.12023L16.1521 6.49814C16.2865 6.72855 16.5331 6.87023 16.7999 6.87023V6.12023ZM3.1499 18.3602V8.52023H1.6499V18.3602H3.1499ZM4.7999 6.87023H7.1999V5.37023H4.7999V6.87023ZM7.84774 6.49814L9.52774 3.61814L8.23207 2.86233L6.55207 5.74233L7.84774 6.49814ZM8.8799 3.99023H15.1199V2.49023H8.8799V3.99023ZM14.4721 3.61814L16.1521 6.49814L17.4477 5.74233L15.7677 2.86233L14.4721 3.61814ZM16.7999 6.87023H19.1999V5.37023H16.7999V6.87023ZM20.8499 8.52023V18.3602H22.3499V8.52023H20.8499ZM20.8499 18.3602C20.8499 19.2715 20.1112 20.0102 19.1999 20.0102V21.5102C20.9396 21.5102 22.3499 20.0999 22.3499 18.3602H20.8499ZM19.1999 6.87023C20.1112 6.87023 20.8499 7.60896 20.8499 8.52023H22.3499C22.3499 6.78054 20.9396 5.37023 19.1999 5.37023V6.87023ZM3.1499 8.52023C3.1499 7.60896 3.88863 6.87023 4.7999 6.87023V5.37023C3.06021 5.37023 1.6499 6.78053 1.6499 8.52023H3.1499ZM4.7999 20.0102C3.88863 20.0102 3.1499 19.2715 3.1499 18.3602H1.6499C1.6499 20.0999 3.06021 21.5102 4.7999 21.5102V20.0102ZM14.8499 12.8402C14.8499 14.4142 13.5739 15.6902 11.9999 15.6902V17.1902C14.4023 17.1902 16.3499 15.2427 16.3499 12.8402H14.8499ZM11.9999 15.6902C10.4259 15.6902 9.1499 14.4142 9.1499 12.8402H7.6499C7.6499 15.2427 9.59746 17.1902 11.9999 17.1902V15.6902ZM9.1499 12.8402C9.1499 11.2662 10.4259 9.99023 11.9999 9.99023V8.49023C9.59746 8.49023 7.6499 10.4378 7.6499 12.8402H9.1499ZM11.9999 9.99023C13.5739 9.99023 14.8499 11.2662 14.8499 12.8402H16.3499C16.3499 10.4378 14.4023 8.49023 11.9999 8.49023V9.99023ZM19.1999 20.0102H4.7999V21.5102H19.1999V20.0102Z"
        stroke={strokeColor}
      />
    </svg>
  );
};
