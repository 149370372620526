import styled from 'styled-components';

import { breakpoints } from '../../../breakpoints';
import { PositionsBar } from '../side-bar/SideBar';

export const StyledBottomNavigationBar = styled.div<{
  $position: PositionsBar;
}>`
  box-shadow: 0px 0px 50px 0px #0000001a;
  background-color: ${({ theme }) => theme.v2.surface.primary};
  position: ${({ $position }) => $position};
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 6.5rem;
  width: 100%;
  padding: 1.5rem 4rem;
  display: flex;
  justify-content: space-between;
`;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const StyledMenu = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media ${breakpoints.laptopSm} {
    display: none;
  }
`;
