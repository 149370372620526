export enum DocumentType {
  INVOICE = 'invoice',
  CONTRACT = 'contract',
  CERTIFICATE = 'certificate',
  BUSINESS_PLAN = 'businessplan',
  ONE_PAGER = 'onepager',
  SECOND_PARTY_OPINION = 'secondpartyopinion',
  WHITE_PAPER = 'whitepaper',
  EVALUATION = 'evaluation',
  PRESENTATION = 'presentation',
  BANNER = 'banner',
  LOGO = 'logo',
  TERMSHEET = 'termsheet',
}
