import { NftPortfolioAssetsResponse, PortfolioBondAssetsDTO, PortfolioShareAssetsDTO } from '@metaswiss/api';
import { formatAmountWithoutDecimals } from '@metaswiss/lib';
import {
  ArrowChartIcon,
  DataStatusPage,
  Lightbulb,
  NoResourcesIcon,
  PageStateContainer,
  PieChartProps,
  PortfolioTransactionCard,
  ThemedIcon,
} from '@metaswiss/ui-kit';
import { NftHolder } from '@metaswiss/ui-kit/src/components/molecules/nft-holder/NftHolder';
import { BarChart } from '@metaswiss/ui-kit/src/iconography/BarChart';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { api } from '../../../../api/msApi';
import { BenefitsCard } from '../../../../components/benefits-card/BenefitsCard';
import { ProductType } from '../../../../enums/productType.enum';
import { ApiResource } from '../../../../enums/resource.enum';
import { AppState, useAppState } from '../../../../global-state/zustand';
import { useTextTranslation } from '../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../router/routes';
import { defaultUser } from '../../../../shared/helpers/defaultUser';
import { getFormattedLanguageName } from '../../../../shared/helpers/getFormattedLanguageName.helper';
import { getQueryKey } from '../../../../shared/helpers/getQueryKey.helper';

import { OverviewGraph } from './components/overview-graph/OverviewGraph';
import { InvesmentCards } from './components/overview-graph/overviewGraphTypes';
import { PortfolioDetailsHolder } from './components/portfolio-details-holder/PortfolioDetailsHolder';
import { PortfolioItemHolder } from './components/portfolio-item-holder/PortfolioItemHolder';
import { PortfolioCardsWapper, PortfolioItemsWapper, TransactionCardWrapper } from './overview.styles';

export const Overview = () => {
  const { textTranslation, currentLanguage } = useTextTranslation();

  const queryClient = useQueryClient();
  const theme = useTheme();
  const navigate = useNavigate();
  const user = useAppState((state: AppState) => state.user) || defaultUser;

  const {
    data: salesInformationData,
    isError: isErrorSalesInformationData,
    isLoading: isLoadingSalesInformationData,
    refetch: refetchSalesInformationData,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.SALES_INFORMATION, currentLanguage),
    queryFn: () => api.contentful.getSalesInformation(getFormattedLanguageName(currentLanguage), 'investing-tip'),
  });

  const {
    data: investmentStatistics,
    isError: isErrorInvesmentStatistics,
    isLoading: isLoadinginvesmentStatistics,
    refetch: refetchInvesmentStatisticsData,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.PORTFOLIO),
    queryFn: () => api.clientPortfolio.getInvestmentStatistics(),
  });

  const {
    data: nftsData,
    isError: isErrorNfts,
    isLoading: isLoadingNfts,
    refetch: refetchNfts,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.PORTFOLIO_NFTS),
    queryFn: () => api.clientPortfolio.getSmartNftInvestments(0, 6),
  });

  const {
    data: portfolioShare,
    isLoading: isLoadingShareData,
    isError: isErrorShareData,
    remove: removeShare,
    refetch: refetchShareData,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.PORTFOLIO, `${ProductType.ACTIONS}{2}`),
    queryFn: () => api.clientPortfolio.getSmartShareInvestments(0, 2, 0),
  });

  useEffect(() => {
    return () => removeShare();
  }, []);

  const {
    data: portfolioBond,
    isLoading: isLoadingBondData,
    isError: isErrorBondData,
    remove: removeBond,
    refetch: refetchBondData,
  } = useQuery({
    queryKey: getQueryKey(ApiResource.PORTFOLIO, `${ProductType.BOND_PAPERS}{2}`),
    queryFn: () => api.clientPortfolio.getSmartBondInvestments(0, 2, 0),
    enabled: !!user?.id,
  });

  useEffect(() => {
    return () => removeBond();
  }, []);

  const portfolioNftItems: NftPortfolioAssetsResponse[] = useMemo(
    () => nftsData?.items as unknown as NftPortfolioAssetsResponse[],
    [nftsData]
  );

  const portfolioShareItems: PortfolioShareAssetsDTO[] = useMemo(
    () => portfolioShare?.items as unknown as PortfolioShareAssetsDTO[],
    [portfolioShare]
  );

  const portfolioBondItems: PortfolioBondAssetsDTO[] = useMemo(
    () => portfolioBond?.items as unknown as PortfolioBondAssetsDTO[],
    [portfolioBond]
  );

  const isLoading = useMemo(
    () =>
      isLoadingSalesInformationData ||
      isLoadingNfts ||
      isLoadingBondData ||
      isLoadingShareData ||
      isLoadinginvesmentStatistics,
    [isLoadingBondData, isLoadingNfts, isLoadingSalesInformationData, isLoadingShareData, isLoadinginvesmentStatistics]
  );

  const isError = useMemo(
    () =>
      isErrorNfts ||
      isErrorInvesmentStatistics ||
      isErrorBondData ||
      isErrorShareData ||
      isErrorNfts ||
      isErrorSalesInformationData,
    [isErrorBondData, isErrorInvesmentStatistics, isErrorNfts, isErrorSalesInformationData, isErrorShareData]
  );

  function extractTotalValue(investmentOverview: { totalInvestedAmount?: string | null }): number {
    const totalString = investmentOverview?.totalInvestedAmount?.split(' ')[0]?.split('.')[0]?.replace("'", '') ?? '0';
    return +totalString;
  }

  const { nftInvestmentOverview, bondInvestmentOverview, shareInvestmentOverview } = investmentStatistics || {};
  const nftTotal = extractTotalValue(nftInvestmentOverview || {});
  const bondTotal = extractTotalValue(bondInvestmentOverview || {});
  const shareTotal = extractTotalValue(shareInvestmentOverview || {});
  const segmentsOverview: PieChartProps['segments'] = useMemo(
    () => [
      {
        value: Number(nftTotal) || 0,
        color: theme.v2.text.success,
      },
      {
        value: Number(shareTotal) || 0,
        color: theme.v2.text.information,
      },
      {
        value: Number(bondTotal) || 0,
        color: theme.v2.text.action2,
      },
    ],
    [investmentStatistics]
  );

  const investmentData = useMemo(() => {
    if (!investmentStatistics) return [];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { totalNFTsBought, ...restNft } = investmentStatistics.nftInvestmentOverview;
    const { totalSharesBought, ...restShares } = investmentStatistics.shareInvestmentOverview;
    const { totalBondsBought, ...restBonds } = investmentStatistics.bondInvestmentOverview;

    return [
      {
        ...restNft,
        totalBought: totalNFTsBought,
      },
      {
        ...restShares,
        totalBought: totalSharesBought,
      },
      {
        ...restBonds,
        totalBought: totalBondsBought,
      },
    ] as InvesmentCards[];
  }, [investmentStatistics]);

  const hasFalsyTotalBought = investmentData.every((item) => !item.totalBought);

  const isEmptyState = useMemo(() => hasFalsyTotalBought, [hasFalsyTotalBought]);

  const onTryAgain = async () => {
    await Promise.all([
      refetchNfts(),
      refetchInvesmentStatisticsData(),
      refetchBondData(),
      refetchShareData(),
      refetchNfts(),
      refetchSalesInformationData(),
    ]);
  };

  useLayoutEffect(() => {
    queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.SALES_INFORMATION) });
    queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.PORTFOLIO) });
    queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.PORTFOLIO_NFTS) });
  }, []);

  return (
    <>
      <PageStateContainer
        showLoading={true}
        isLoading={isLoading}
        showError={true}
        isError={isError}
        onTryAgain={onTryAgain}
        textTranslation={textTranslation}
        showEmptyState={true}
        isEmptyState={isEmptyState}
        customEmptyStateComponent={
          <DataStatusPage
            icon={
              <>
                <ThemedIcon
                  icon={() => (
                    <NoResourcesIcon
                      foregroundColor={theme.v2.icon.primary}
                      backgroundColor={theme.v2.surface.informationLight}
                    />
                  )}
                  size={'full'}
                />
              </>
            }
            title={textTranslation('portfolio.nothingHere')}
            content={textTranslation('portfolio.noResources')}
            buttonText={textTranslation('portfolio.startInvesting')}
            action={() => navigate(routes.offering.smartNfts.root)}
            iconWidth={'11.25rem'}
            iconHeight={'11.875rem'}
          />
        }
      >
        <BenefitsCard
          icon={Lightbulb}
          title={salesInformationData?.heading[0] || ''}
          subTitle={salesInformationData?.heading[1] || ''}
          descriptionList={salesInformationData?.content || []}
        />
        <PortfolioDetailsHolder heading={textTranslation('account.overview')}>
          <OverviewGraph
            title={textTranslation('portfolio.totalBalance')}
            price={investmentStatistics?.totalInvestedAmount?.split(' ')[0]}
            titleCurrency={investmentStatistics?.totalInvestedAmount?.split(' ')[1] || ''}
            cards={investmentData}
            chartData={segmentsOverview}
          />
        </PortfolioDetailsHolder>
        <PortfolioItemHolder
          heading={textTranslation('portfolio.smartHistoryNfts')}
          navigateUrl={routes.portfolio.smartNfts.nftHistory}
          countItems={nftsData?.totalItems ?? 0}
        >
          <PortfolioItemsWapper>
            {portfolioNftItems?.map((nftPortfolioAsset) => {
              return (
                <NftHolder
                  key={nftPortfolioAsset.id}
                  serialNumber={nftPortfolioAsset.serialNumber}
                  price={nftPortfolioAsset.nftPrice}
                  priceText={textTranslation('global.price')}
                  buyText={textTranslation('offering.buyNow')}
                  imageUrl={nftPortfolioAsset.nftThumbnailUrl}
                  collectionName={nftPortfolioAsset.collectionName}
                  onClick={() => navigate(`${routes.portfolio.smartNfts.root}/${nftPortfolioAsset.id}`)}
                />
              );
            })}
          </PortfolioItemsWapper>
        </PortfolioItemHolder>
        <PortfolioItemHolder
          heading={textTranslation('portfolio.smartHistoryShares')}
          navigateUrl={routes.portfolio.smartShares.history}
          countItems={portfolioShare?.totalItems ?? 0}
        >
          <PortfolioCardsWapper>
            {portfolioShareItems?.map((portfolioShareAssets) => {
              return (
                <TransactionCardWrapper key={portfolioShareAssets.id}>
                  <PortfolioTransactionCard
                    headerLabel={textTranslation('portfolio.pricePerShare')}
                    headerValue={portfolioShareAssets.pricePerShare}
                    quantityLabel={textTranslation('portfolio.quantity')}
                    quantity={formatAmountWithoutDecimals(portfolioShareAssets.sharesBought)}
                    amountLabel={textTranslation('portfolio.amount')}
                    amount={portfolioShareAssets.investedAmount}
                    icon={BarChart}
                    onClick={() => navigate(`${routes.portfolio.smartShares.root}/${portfolioShareAssets.id}`)}
                  />
                </TransactionCardWrapper>
              );
            })}
          </PortfolioCardsWapper>
        </PortfolioItemHolder>
        <PortfolioItemHolder
          heading={textTranslation('portfolio.smartHistoryBonds')}
          navigateUrl={routes.portfolio.smartBonds.bondHistory}
          countItems={portfolioBond?.totalItems ?? 0}
        >
          <PortfolioCardsWapper>
            {portfolioBondItems?.map((portfolioBondAsset) => {
              return (
                <TransactionCardWrapper key={portfolioBondAsset.id}>
                  <PortfolioTransactionCard
                    headerLabel={textTranslation('portfolio.annualInterest')}
                    headerValue={`${portfolioBondAsset.annualInterest}%`}
                    quantityLabel={textTranslation('portfolio.quantity')}
                    quantity={formatAmountWithoutDecimals(portfolioBondAsset.bondsBought)}
                    amountLabel={textTranslation('portfolio.amount')}
                    amount={portfolioBondAsset.investedAmount}
                    issuerInfo={{
                      name: portfolioBondAsset.issuerName,
                      logoUrl: portfolioBondAsset.issuerLogoUrl,
                    }}
                    icon={ArrowChartIcon}
                    onClick={() => navigate(`${routes.portfolio.smartBonds.root}/${portfolioBondAsset.id}`)}
                  />
                </TransactionCardWrapper>
              );
            })}
          </PortfolioCardsWapper>
        </PortfolioItemHolder>
      </PageStateContainer>
    </>
  );
};
