import styled from 'styled-components';

type Props = {
  $show: boolean;
};

export const CheckContainer = styled.div<Props>`
  flex: 0 0 auto;
  opacity: ${({ $show }) => ($show ? '1' : '0')};
`;
