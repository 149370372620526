import styled from 'styled-components';

export const StyledField = styled.fieldset<{ $isLocked: boolean }>`
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  right: 0;
  width: 100%;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0 1rem;
  pointer-events: none;
  overflow: hidden;
  min-width: 0%;
  border-radius: ${({ theme }) => theme.v2.componentsRadius.inputs.large};
  border: ${({ theme }) => `1px solid ${theme.v2.border.primary}`};
  background: transparent;
  opacity: ${({ $isLocked }) => ($isLocked ? '0' : '1')};
  transition: opacity 0.4s;
  display: flex;
  align-items: baseline;
`;
