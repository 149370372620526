import styled from 'styled-components';

import { ColorPalette, Colors, Theme, TypographyLineHeight, TypographySize, TypographyWeight } from '../../theme';

type TextTemplateColorsProp = {
  $textColor?: Colors<ColorPalette>;
  $palette?: ColorPalette;
  $color?: string;
};

type TextTemplateSizeProps = {
  $fontSize: TypographySize;
  $fontWeight: TypographyWeight;
  $lineHeight?: TypographyLineHeight;
};

type TextTemplateProps = TextTemplateColorsProp & TextTemplateSizeProps;

type TextLineHeightProps = TextTemplateSizeProps & { lineHeights: Theme['typography']['lineHeight'] };

const setTextLineHeight = ({ $fontSize, $fontWeight, lineHeights, $lineHeight }: TextLineHeightProps): string =>
  $lineHeight
    ? lineHeights[$lineHeight]
    : ($fontSize === 'lg' && $fontWeight === 'bold') || $fontSize[0] === 'h'
      ? lineHeights.sm
      : lineHeights.base;

export const TextTemplate = styled.div<TextTemplateProps>`
  color: ${({ $textColor, $palette, theme, $color }) =>
    $color || theme.colors[$palette || 'primary'][$textColor || 'hue400']};
  font-size: ${({ theme, $fontSize }) => theme.typography.sizes[$fontSize]};
  font-weight: ${({ theme, $fontWeight }) => theme.typography.weights[$fontWeight]};
  line-height: ${({ $fontSize, $fontWeight, $lineHeight, theme }) =>
    setTextLineHeight({ $fontSize, $fontWeight, lineHeights: theme.typography.lineHeight, $lineHeight })};
  user-select: none;
  word-break: break-word;
`;
