import * as zod from 'zod';

import { FormNames } from '../../../../../shared/forms/formNames';

export const createCommercialRegisterSchema = () =>
  zod.object({
    file: zod.object({ fileId: zod.string().optional(), url: zod.string().optional() }),
  });

export const schema = createCommercialRegisterSchema();

export type CommercialRegisterFormData = zod.infer<typeof schema>;

export const commercialRegisterSchema: FormNames<CommercialRegisterFormData> = {
  file: 'file',
} as const;
