import debounce from 'lodash/debounce';
import { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { ChevronLeftIcon, ChevronRightIcon } from '../../../iconography';
import { Tab } from '../../atoms/tab';
import { ThemedIcon } from '../theme-icon';

import {
  FiltersContainer,
  LeftIconContainer,
  RightIconContainer,
  TabsSlider,
  TabsWrapper,
} from './tabsContainer.styles';
import { TabsContainerProps } from './types';

export const TabsContainer = ({
  tabs,
  onTabSelect,
  selectedTab,
  isFilterArrayEmpty = false,
  children,
}: TabsContainerProps) => {
  const theme = useTheme();
  const selectedTabRef = useRef<HTMLButtonElement | null>(null);
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const [isScrollbarOnEnd, setIsScrollbarOnEnd] = useState<boolean>(false);
  const [isScrollbarOnStart, setIsScrollbarOnStart] = useState<boolean>(true);

  const handleSlideLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft -= 300;
    }
  };

  const handleSlideRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += 300;
    }
  };

  const handleSliderScroll = () => {
    if (!sliderRef.current) return;

    setIsScrollbarOnEnd(false);

    setIsScrollbarOnStart(false);

    // arrow paddingshave to be subtracted 32px = left + right padding
    if (sliderRef.current.scrollLeft + sliderRef.current.clientWidth >= sliderRef.current.scrollWidth - 32) {
      setIsScrollbarOnEnd(true);
    }

    if (sliderRef.current.scrollLeft === 0) {
      setIsScrollbarOnStart(true);
    }
  };

  const debouncedHandleScroll = debounce(handleSliderScroll, 75);

  const handleScrollToSelectedTab = () => {
    if (selectedTabRef.current) selectedTabRef.current.scrollIntoView();
  };

  useEffect(() => {
    handleSliderScroll();

    window.addEventListener('resize', debouncedHandleScroll);

    return () => {
      window.removeEventListener('resize', debouncedHandleScroll);
    };
  }, []);

  useEffect(() => {
    handleScrollToSelectedTab();
  }, [selectedTab]);

  return (
    <TabsWrapper $hasTabs={!!tabs.length}>
      {!isScrollbarOnStart && (
        <LeftIconContainer onClick={handleSlideLeft} width="4rem">
          <ThemedIcon icon={ChevronLeftIcon} customStrokeColor={theme.v2.icon.default} size="medium" />
        </LeftIconContainer>
      )}
      <TabsSlider
        ref={sliderRef}
        onScroll={debouncedHandleScroll}
        $isFilterActive={isFilterArrayEmpty}
        $hasTabs={!!tabs.length}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            isSelected={tab.value === selectedTab?.value}
            selectedTabRef={selectedTabRef}
            onClick={() => {
              onTabSelect(tab);
            }}
            addIndicator={tab.indicator}
          >
            {tab.label}
          </Tab>
        ))}
      </TabsSlider>
      {!isScrollbarOnEnd && (
        <RightIconContainer onClick={handleSlideRight} width="4rem">
          <ThemedIcon icon={ChevronRightIcon} customStrokeColor={theme.v2.icon.default} size="medium" />
        </RightIconContainer>
      )}
      {isFilterArrayEmpty && <FiltersContainer>{children}</FiltersContainer>}
    </TabsWrapper>
  );
};
