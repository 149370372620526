import { TransactionStatus } from '@metaswiss/ui-kit/src/components/molecules/cards/transaction-card/enums';

export const getTransactionChip = (status: string) => {
  switch (status) {
    case TransactionStatus.ACCEPTED:
      return 'primary';
    case TransactionStatus.FAILED:
      return 'error';
    case TransactionStatus.PENDING:
      return 'warning';
    case TransactionStatus.STRIPE_PENDING:
      return 'warning';
    default:
      return 'neutral';
  }
};
