import styled from 'styled-components';

export const RegistrationForm = styled.div`
  margin-top: 0.5rem;
`;

export const RegistrationGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 0.5rem;
  column-gap: 1rem;
`;

export const OneLineElement = styled.div`
  grid-column: 1 / span 2;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  margin-top: 0.5rem;
`;

export const TextLinkWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
`;

export const SelectionCard = styled.div<{ $disabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  position: relative;
  z-index: 1;
  padding: 1rem 1.25rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  background: ${({ theme, $disabled }) => ($disabled ? theme.v2.surface.secondary : theme.v2.surface.primary)};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    ${({ $disabled, theme }) =>
      !$disabled &&
      `
      box-shadow: 0px 0px 16px 0px ${theme.v2.colors.black}05, 0px 1px 0px 0px ${theme.v2.colors.black}05, 0px 10px 24px 0px ${theme.v2.colors.black}05;
 
      `}
  }
`;
