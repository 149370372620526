import styled, { keyframes } from 'styled-components';

import { IconProps } from './iconProps';

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled.svg<IconProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  animation: ${spinAnimation} 3s linear infinite;
`;

export const SpinnerLoaderItemsIcon: React.FC<IconProps> = ({ color = 'none', strokeColor }) => {
  return (
    <StyledSpinner width="100%" height="100%" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.6987 14.3178C20.8953 13.5783 21 12.8014 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C12.6267 21 13.2384 20.9359 13.829 20.814"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSpinner>
  );
};
