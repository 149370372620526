import { ToastType, useToastQueueContext } from '@metaswiss/lib';
import { Heading, Text } from '@metaswiss/ui-kit';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { api } from '../../../../../api/msApi';
import ControlledForm from '../../../../../components/controlled-form/ControlledForm.tsx';
import { PasswordValidationInputs } from '../../../../../components/passwords-validation-inputs/PasswordsValidationInputs';
import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { useAuthContext } from '../../../shared/auth-context/authContext.ts';
import { useSetAuthConfig } from '../../../shared/auth-context/useSetAuthConfig.ts';
import { useForgotPasswordState } from '../../shared/context';
import { FormWrapper, HeadingContainer, HeadingWrapper } from '../../shared/styles';

import { createForgotPassThirdStepSchema, FormData, thirdStepFormSchema } from './thirdStepSchema';

export const ThirdStepView = () => {
  const theme = useTheme();
  const { textTranslation } = useTextTranslation();

  const [doPasswordsMatch, setDoPasswordsMatch] = useState<boolean>(true);
  const { enqueue } = useToastQueueContext();

  const { email, code, clean } = useForgotPasswordState((state) => state);

  const { setLoading, setIsValid, setForgotPasswordStep } = useAuthContext();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useFormContext<FormData>();

  const { isError, isSuccess, mutate } = useMutation({
    mutationFn: (data: FormData) => {
      return api.auth.resetPasswordWithCode({
        email,
        code,
        newPassword: data.password,
        passwordConfirmation: data.confirmPassword,
      });
    },
    onMutate: () => {
      setLoading(true);
    },
    onError: () => {
      enqueue(ToastType.ERROR, textTranslation('error.default'), textTranslation('error.error'));
      setLoading(false);
    },
    onSuccess: () => {
      setLoading(false);
      clean();
    },
  });

  useEffect(() => {
    if (isError) {
      setForgotPasswordStep(4);
    } else if (isSuccess) {
      setForgotPasswordStep(5);
    }
  }, [isError, isSuccess, setForgotPasswordStep]);

  useEffect(() => {
    setIsValid(isValid && doPasswordsMatch);
    return () => {
      setIsValid(true);
    };
  }, [doPasswordsMatch, isValid, setIsValid]);

  useSetAuthConfig(
    {
      nextButton: {
        text: 'forgotPassword.confirm',
        onClick: () => {
          handleSubmit((data) => mutate(data))();
        },
      },
    },
    [handleSubmit, mutate]
  );

  return (
    <>
      <HeadingWrapper>
        <HeadingContainer>
          <Heading headingType="h4" fontWeight="bold" color={theme.v2.text.headingPrimary}>
            {textTranslation('forgotPassword.setPassword')}
          </Heading>
          <Text color={theme.v2.text.bodyPrimary}>{textTranslation('forgotPassword.setNewPassword')}</Text>
        </HeadingContainer>
      </HeadingWrapper>
      <FormWrapper>
        <PasswordValidationInputs
          control={control}
          passwordName={thirdStepFormSchema.password}
          passwordConfirmationName={thirdStepFormSchema.confirmPassword}
          passwordError={errors[thirdStepFormSchema.password]}
          passwordConfirmationError={errors[thirdStepFormSchema.confirmPassword]}
          passwordLabel={textTranslation('forgotPassword.newPassword')}
          passwordConfirmationLabel={textTranslation('registration.passwordConfirmation')}
          doPasswordsMatch={doPasswordsMatch}
          setDoPasswordsMatch={setDoPasswordsMatch}
        />
      </FormWrapper>
    </>
  );
};

export const ThirdStep = () => {
  return (
    <ControlledForm validationSchema={createForgotPassThirdStepSchema}>
      <ThirdStepView />
    </ControlledForm>
  );
};
