import { motion } from 'framer-motion';
import styled from 'styled-components';

import { RemSize } from '../../../theme';

export const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  background: rgba(0, 0, 0, 0.2);
`;

export const SidePanelWrapper = styled(motion.div)<{ $padding: RemSize }>`
  width: 26.25rem;
  height: calc(100% - 2.5rem);
  margin: 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1001;
  background-color: #fff;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  box-shadow: 0 1.5rem 3rem -0.75rem rgba(16, 24, 40, 0.18);
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.v2.surface.primary};
  padding: ${({ $padding }) => $padding};
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
