import * as zod from 'zod';

import { FormNames } from '../../../../../shared/forms/formNames';
import { staticTranslationFunction } from '../../../../../shared/forms/staticTranslationFunction.ts';
import { regex } from '../../../../../shared/regex/regex.ts';

export const createEditProfileSchema = (translate: (key: string) => string) =>
  zod.object({
    firstName: zod.string().trim().nonempty(translate('error.requiredField')),
    lastName: zod.string().trim().nonempty(translate('error.requiredField')),
    birthDate: zod.string().nonempty(translate('error.requiredField')),
    citizenship: zod.object({
      value: zod.string().nonempty(translate('error.requiredField')),
      label: zod.string().nonempty(translate('error.requiredField')),
      iconSrc: zod.string().optional(),
      meta: zod.string().optional(),
    }),
    phoneNumber: zod
      .string()
      .nonempty(translate('error.requiredField'))
      .regex(regex.numbersOnly, translate('error.invalidPhoneNumber'))
      .min(5, { message: translate('error.invalidPhoneNumberMinLength') })
      .max(15, { message: translate('error.invalidPhoneNumberMaxLength') }),
    picture: zod.object({ fileId: zod.string(), url: zod.string() }),
    phoneNumberPrefix: zod.object({
      value: zod.string(),
      label: zod.string(),
      iconSrc: zod.string().optional(),
      meta: zod.string().optional(),
    }),
  });

export const schema = createEditProfileSchema(staticTranslationFunction);

export type ProfileFormData = zod.infer<typeof schema>;

export const profileSchema: FormNames<ProfileFormData> = {
  firstName: 'firstName',
  lastName: 'lastName',
  birthDate: 'birthDate',
  citizenship: 'citizenship',
  phoneNumber: 'phoneNumber',
  phoneNumberPrefix: 'phoneNumberPrefix',
  picture: 'picture',
} as const;
