import styled from 'styled-components';

export const Circle = styled.div<{ $size: string; $color: string }>`
  width: ${({ $size }) => ($size === 'fill' ? '100%' : $size)};
  height: ${({ $size }) => ($size === 'fill' ? '100%' : $size)};

  border-radius: 50%;
  background-color: ${({ $color }) => $color};

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;
