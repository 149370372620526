import { useMemo, useState } from 'react';

import { ThemedIcon } from '../../../components';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../iconography';

import { ButtonDayYear, ButtonYearArrow } from './styles/btnStyles';
import { DatePickerSlider, DaysYearsContainer, SliderWrapper } from './styles/datePickerSlider';
import { PickerProps } from './types';
import { isYearInvalid } from './utils/getBtnsValidation';

export const YearPicker = ({ onChange, date, minDate, maxDate, activePicker }: PickerProps) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const { yearsBySlide, numberOfSlides } = useMemo(() => {
    const numberOfYears = maxDate.getFullYear() - minDate.getFullYear() + 1;

    const allYears = Array.from({ length: numberOfYears }, (_, index) => maxDate.getFullYear() - index);

    const yearsBySlide: number[][] = [];

    let chunkSize = 34;

    for (let i = 0; i < allYears.length; i += chunkSize) {
      if (i !== 0) chunkSize = 33;

      if (allYears.length - i === 34) chunkSize = 34;

      const chunk = allYears.slice(i, i + chunkSize);
      yearsBySlide.push(chunk);
    }

    const numberOfSlides = yearsBySlide.length;

    return { yearsBySlide, numberOfSlides };
  }, [minDate, maxDate]);

  const isPickerOnYear = activePicker === 2;

  return (
    <SliderWrapper>
      <DatePickerSlider $activeSlide={activeSlide} $numberOfSlides={numberOfSlides}>
        {yearsBySlide.map((yearsArr, index) => (
          <DaysYearsContainer key={index}>
            {!!index && (
              <ButtonYearArrow
                $isSelected={false}
                onClick={() => setActiveSlide((prevSlide) => prevSlide - 1)}
                tabIndex={isPickerOnYear && index === activeSlide ? 0 : -1}
              >
                <ThemedIcon icon={ChevronLeftIcon} palette="neutrals" strokeColor="hue700" />
              </ButtonYearArrow>
            )}
            {yearsArr.map((year) => {
              const isButtonInvalid = isYearInvalid({ date, year, maxDate, minDate });

              return (
                <ButtonDayYear
                  key={year}
                  $isSelected={Number(date.year) === year}
                  onClick={() => onChange(year.toString())}
                  disabled={isButtonInvalid}
                  tabIndex={isPickerOnYear && index === activeSlide ? 0 : -1}
                >
                  {year}
                </ButtonDayYear>
              );
            })}
            {index + 1 < yearsBySlide.length && (
              <ButtonYearArrow
                $isSelected={false}
                onClick={() => setActiveSlide((prevSlide) => prevSlide + 1)}
                tabIndex={isPickerOnYear && index === activeSlide ? 0 : -1}
              >
                <ThemedIcon icon={ChevronRightIcon} palette="neutrals" strokeColor="hue700" />
              </ButtonYearArrow>
            )}
          </DaysYearsContainer>
        ))}
      </DatePickerSlider>
    </SliderWrapper>
  );
};
