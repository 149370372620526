import styled from 'styled-components';

type Props = {
  $disabled: boolean;
};

export const RadioContainer = styled.div<Props>`
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;
