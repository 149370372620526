import { ReactNode } from 'react';
import { useTheme } from 'styled-components';

import { TextTemplate } from '../../../shared/typography/TextTemplate.styles';
import { TextSizeProps } from '../../../shared/typography/textSizeProps';

export type TextProps = TextSizeProps & {
  children: ReactNode;
};

export const Text = ({
  children,
  palette = 'neutrals',
  textColor = 'hue700',
  fontSize = 'base',
  fontWeight = 'reg',
  lineHeight = 'base',
  color,
}: TextProps) => {
  const theme = useTheme();

  return (
    <TextTemplate
      as="span"
      $textColor={textColor}
      $palette={palette}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
      $lineHeight={lineHeight}
      $color={color || theme.v2.text.bodyPrimary}
    >
      {children}
    </TextTemplate>
  );
};
