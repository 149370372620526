import { ReactNode, useState } from 'react';
import { useTheme } from 'styled-components';

import { Text, ThemedIcon } from '../../../components';
import { ChevronUpIcon } from '../../../iconography';

import {
  ButtonWrapper,
  ContentBodyContainer,
  ContentWrapper,
  TogglableWrapper,
  ToggleButtonContainer,
} from './toggleButton.styles';

export type ToggleButtonProps = {
  text: string;
  children: ReactNode;
};

export const ToggleButton = ({ text, children }: ToggleButtonProps) => {
  const theme = useTheme();
  const [isContentOpen, setIsContentOpen] = useState<boolean>(false);

  const toggleContentVisibility = () => {
    setIsContentOpen((prevState) => !prevState);
  };

  return (
    <ToggleButtonContainer>
      <ButtonWrapper onClick={toggleContentVisibility} $isContentOpen={isContentOpen}>
        <Text color={theme.v2.text.bodyPrimary} fontSize={'sm'} lineHeight={'medium'}>
          {text}
        </Text>
        <ThemedIcon icon={ChevronUpIcon} customStrokeColor={theme.v2.icon.default} />
      </ButtonWrapper>
      <ContentBodyContainer $isContentOpen={isContentOpen}>
        <TogglableWrapper>
          <ContentWrapper $isContentOpen={isContentOpen}>{children}</ContentWrapper>
        </TogglableWrapper>
      </ContentBodyContainer>
    </ToggleButtonContainer>
  );
};
