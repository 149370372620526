import { FC } from 'react';

import { IconProps } from './iconProps';

export const CoinNumber: FC<IconProps> = ({ color = 'none', strokeColor = '#3381FF' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.85639 7.63639C8.63145 4.62508 11.365 2.40002 14.6183 2.40002C18.4742 2.40002 21.6001 5.52589 21.6001 9.38184C21.6001 12.456 19.6132 15.0662 16.8535 15.9982M7.85639 17.1257H9.38025M9.38025 17.1257H10.7984M9.38025 17.1257V11.5621C9.38025 11.5621 8.31644 12.2934 7.6348 12.7621M16.3621 14.6182C16.3621 18.4742 13.2362 21.6 9.38025 21.6C5.5243 21.6 2.39844 18.4742 2.39844 14.6182C2.39844 10.7623 5.5243 7.63639 9.38025 7.63639C13.2362 7.63639 16.3621 10.7623 16.3621 14.6182Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
