import { styled } from 'styled-components';

export const BannerContainer = styled.div<{ $gradientColor: string }>`
  width: 100%;
  overflow: hidden;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  background: ${({ $gradientColor }) => $gradientColor};
  border-radius: ${({ theme }) => theme.dimensions.borderRadii.xl};
  position: relative;
`;

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 5.75rem;
`;

export const BannerContentWrapper = styled.div`
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => `${theme.colors.neutrals.hue0}26`};
`;

export const BannerItemsWrapper = styled.div`
  width: 100%;
  padding-top: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const BannerItemCard = styled.div`
  flex: 0 1 20rem;
  padding: 0.5rem 0.75rem;
  &:first-child {
    border-radius: ${({ theme }) => theme.dimensions.borderRadii.xl};
    background-color: ${({ theme }) => `${theme.colors.neutrals.hue0}26`};
  }
`;
