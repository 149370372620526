import { FC } from 'react';

import { IconProps } from './iconProps';

export const CoinUnbrokenIcon: FC<IconProps> = ({ color = 'none', strokeColor = '"#3381FF"' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.85639 7.63675C8.63145 4.62545 11.365 2.40039 14.6183 2.40039C18.4742 2.40039 21.6001 5.52626 21.6001 9.38221C21.6001 12.4564 19.6132 15.0666 16.8535 15.9985M16.3621 14.6186C16.3621 18.4745 13.2362 21.6004 9.38025 21.6004C5.5243 21.6004 2.39844 18.4745 2.39844 14.6186C2.39844 10.7626 5.5243 7.63675 9.38025 7.63675C13.2362 7.63675 16.3621 10.7626 16.3621 14.6186Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
