import { styled } from 'styled-components';

export const StripeLoaderWrapper = styled.div`
  height: 10rem;
`;

export const StripePaymentFormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.75rem;
`;

export const FullWidthInput = styled.div`
  grid-column: span 2;
`;

export const StripeInputLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-size: ${({ theme }) => theme.typography.sizes.sm};
  line-height: ${({ theme }) => theme.typography.lineHeight.medium};
`;

export const StripeInputWrapper = styled.div<{ $isError: boolean }>`
  border: 1px solid ${({ theme, $isError }) => ($isError ? theme.v2.border.error : theme.v2.border.primary)};
  border-radius: ${({ theme }) => theme.v2.radius.small};
  padding: 0.75rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.v2.surface.field};
  cursor: text;
  // There is a strange behavior on initial load where Stripe inputs briefly have an unexpected height before returning to the desired one.
  // Setting a max-height helps prevent visual jumping caused by this behavior.
  max-height: 2.938rem;
`;
