import { FC } from 'react';

import { IconProps } from './iconProps.ts';

export const ShareTransactionIcon: FC<IconProps> = ({ color = 'none', strokeColor = '#222223' }) => (
  <svg width="100%" height="100%" viewBox="0 0 32 32" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.19922 27.6004V10.5337M11.7326 27.6004V17.6448M20.2659 27.6004V4.40039M28.7992 27.6004V10.5337"
      stroke={strokeColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
