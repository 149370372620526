import { ColorPalette, DocumentV2, IconProps } from '@metaswiss/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';

import { api } from '../../api/msApi';
import { ApiResource } from '../../enums/resource.enum';
import { useTextTranslation } from '../../hooks/use-text-translation/useTextTranslation';
import { getQueryKey } from '../../shared/helpers/getQueryKey.helper';

import { DocumentType } from './Documents';

type DocumentProps = {
  asset: DocumentType;
  documentIcon: FC<IconProps>;
  forceUrl?: string;
  palette: ColorPalette;
};

export const Document: FC<DocumentProps> = ({ asset, documentIcon, forceUrl }) => {
  const { textTranslation } = useTextTranslation();

  const { data: response } = useQuery({
    queryKey: getQueryKey(ApiResource.ASSIGNED_URL, asset.id),
    queryFn: () => api.assets.getS3SignedAssignedUrl({ assetId: asset.id }),
    enabled: !!asset?.id && !forceUrl,
  });

  return (
    <DocumentV2
      text={forceUrl ? asset.name : textTranslation(`offering.${asset.name.replace(/ /g, '')}`)}
      onClick={() =>
        forceUrl ? window.open(forceUrl, '_blank') : response?.url ? window.open(response?.url, '_blank') : undefined
      }
      icon={documentIcon}
    />
  );
};
