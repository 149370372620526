import { motion } from 'framer-motion';
import styled from 'styled-components';

import { DropdownHeight } from '../../../../theme/theme';

type Props = {
  $open: boolean;
  $height: DropdownHeight;
};

const DROPDOWN_ITEMS = 6;

export const DropdownList = styled(motion.ul)<Props>`
  max-height: ${({ $height, theme }) => `calc(${DROPDOWN_ITEMS} * ${theme.dropdown.height[$height]})`};
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.375rem;
    display: block;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.v2.surface.tertiary};
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.v2.colors.neutral100};
    border-radius: 100px;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  border-radius: ${({ theme }) => theme.v2.componentsRadius.inputs.large};
  width: 100%;
  top: 100%;
  left: 0;
  margin-top: 0.5rem;
  background-color: ${({ theme }) => theme.v2.surface.field};
  list-style-type: none;
  border: 1px solid ${({ theme }) => theme.v2.border.primaryHover};
  z-index: 3;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  li:first-child {
    border-top-left-radius: ${({ theme }) => theme.v2.componentsRadius.inputs.large};
    border-top-right-radius: ${({ theme }) => theme.v2.componentsRadius.inputs.large};
  }

  li:last-child {
    border-bottom-left-radius: ${({ theme }) => theme.v2.componentsRadius.inputs.large};
    border-bottom-right-radius: ${({ theme }) => theme.v2.componentsRadius.inputs.large};
  }
`;

export const Image = styled.img`
  width: 2rem;
  height: 1.5rem;
`;
