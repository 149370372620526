import styled from 'styled-components';

import { IconProps } from '../types';

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  padding-top: 1rem;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
`;

export const IconWrapper = styled.div<IconProps>`
  display: flex;
  padding: 1.5rem;
  justify-content: center;
  align-items: center;
  height: 6rem;
  width: 6rem;
  gap: 0.5rem;
  border-radius: 62.5rem;
  background-color: ${({ theme, $iconBackgroundColor }) => theme.v2.colors[`${$iconBackgroundColor}100`]};
`;

export const TextContainer = styled.div<{ $hasChildren: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
  margin-bottom: ${({ $hasChildren }) => ($hasChildren ? '0rem' : '1rem')};
  width: 100%;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

export const AvatarWrapper = styled.div`
  margin: 0.5rem 0 1.5rem;
  border: 0.5px solid ${({ theme }) => theme.v2.border.primary};
`;

export const AvatarWrapperWithoutBorder = styled.div`
  margin: 0.5rem 0 1.5rem;
`;
export const TextWrapper = styled.div`
  max-width: 28.5rem;
  width: 100%;
`;
