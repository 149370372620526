import * as zod from 'zod';

import { FormNames } from '../../../../../../../../shared/forms/formNames';
import { staticTranslationFunction } from '../../../../../../../../shared/forms/staticTranslationFunction.ts';

export const createFullCorporateFilesSchema = (translate: (key: string) => string) =>
  zod.object({
    commercialRegister: zod.string().nonempty(translate('error.requiredField')),
    proofOfDomicile: zod.string().nonempty(translate('error.requiredField')),
  });

const schema = createFullCorporateFilesSchema(staticTranslationFunction);

export type FullCorporateRegistrationUploadDataFiles = zod.infer<typeof schema>;

export const fullCorporateRegistrationUploadDataFilesSchema: FormNames<FullCorporateRegistrationUploadDataFiles> = {
  commercialRegister: 'commercialRegister',
  proofOfDomicile: 'proofOfDomicile',
} as const;
