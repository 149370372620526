import { SuccessFeedback } from '@metaswiss/ui-kit';
import { useNavigate } from 'react-router-dom';

import { useTextTranslation } from '../../../../../hooks/use-text-translation/useTextTranslation';
import { routes } from '../../../../../router/routes';
import { useSetAuthConfig } from '../../../shared/auth-context/useSetAuthConfig';
import { FeedbackWrapper } from '../../shared/styles';

export const ForgotPasswordSuccess = () => {
  const navigate = useNavigate();
  const { textTranslation } = useTextTranslation();

  useSetAuthConfig(
    {
      wrapperStyle: 'authFinish',
      containerStyle: 'authFinish',
      removeLogo: true,
      nextButton: {
        animationDirection: 'login-in',
        text: 'login.logIn',
        type: 'button',
        onClick: () => navigate(routes.login, { replace: true }),
      },
    },
    [navigate]
  );

  return (
    <FeedbackWrapper>
      <SuccessFeedback
        fill
        title={textTranslation('forgotPassword.passwordChanged')}
        subTitle={textTranslation('forgotPassword.successfullyChangedPassword')}
      />
    </FeedbackWrapper>
  );
};
