import styled, { css, keyframes } from 'styled-components';

import { ToastCountDownProps } from '../types';

const countDownAnimation = keyframes`
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
`;

export const CountDown = styled.div<ToastCountDownProps>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: ${({ $isToastOpen }) => ($isToastOpen ? '100%' : '0')};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  transform: translateY(1px);
  height: 0.25rem;
  animation: ${({ $isToastTranslated, $isToastOpen }) =>
    $isToastTranslated && $isToastOpen
      ? css`
          ${countDownAnimation} 4s linear forwards
        `
      : 'none'};
`;
