import { MouseEvent } from 'react';
import { ClipLoader } from 'react-spinners';
import { useTheme } from 'styled-components';

import { Text, ThemedIcon } from '../../../../components';
import { ArrowRotateRight01, DeleteIcon, File04Icon } from '../../../../iconography';

import { UploadedDocumentProps } from './types';
import { ElementColorConfigProps, Props } from './uploadedDocument.helpers';
import {
  DeleteIconWrapper,
  DocumentContainer,
  DocumentInfoWrapper,
  DocumentTitleWrapper,
  SpinnerWrapper,
} from './uploadedDocument.styles';

export const UploadedDocument = ({
  title,
  documentSize,
  isDisabled = false,
  isLoading = false,
  isLocked = false,
  hasUploadFailed,
  failedUploadTitle,
  failedUploadSubtitle,
  onFailedDocumentClick,
  onDocumentClick,
  onDeleteDocumentClick,
}: UploadedDocumentProps) => {
  const theme = useTheme();

  const getElementsColorVariations = ({
    isDisabled,
    isLoading,
    hasUploadFailed,
    onFailedDocumentClick,
    onDocumentClick,
  }: Props): ElementColorConfigProps => {
    if (isDisabled) {
      return { titleColor: theme.v2.text.disabled, iconColor: theme.v2.icon.neutral };
    } else if (hasUploadFailed) {
      return {
        titleColor: theme.v2.text.error,
        iconColor: theme.v2.icon.error,
        handleDocumentClick: onFailedDocumentClick,
      };
    } else {
      return {
        titleColor: theme.v2.text.headingPrimary,
        iconColor: theme.v2.icon.default,
        handleDocumentClick: () => {
          if (!isLoading) {
            onDocumentClick();
          }
        },
      };
    }
  };

  const { titleColor, iconColor, handleDocumentClick } = getElementsColorVariations({
    isDisabled,
    isLoading,
    hasUploadFailed,
    onDocumentClick,
    onFailedDocumentClick,
  });

  const handleDeleteDocumentClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onDeleteDocumentClick();
  };

  return (
    <DocumentContainer onClick={handleDocumentClick} $isDisabled={isDisabled}>
      <DocumentInfoWrapper>
        <ThemedIcon
          icon={hasUploadFailed ? ArrowRotateRight01 : File04Icon}
          size={'xl'}
          customStrokeColor={iconColor}
        />
        <DocumentTitleWrapper>
          <Text fontWeight={'semi'} color={titleColor}>
            {hasUploadFailed ? failedUploadTitle : title}
          </Text>
          <Text
            fontSize={'sm'}
            lineHeight={'medium'}
            color={isDisabled ? theme.v2.text.disabled : theme.v2.text.headingPrimary}
          >
            {hasUploadFailed ? failedUploadSubtitle : documentSize}
          </Text>
        </DocumentTitleWrapper>
      </DocumentInfoWrapper>
      {!isDisabled && !isLoading && !isLocked && (
        <DeleteIconWrapper onClick={handleDeleteDocumentClick}>
          <ThemedIcon icon={DeleteIcon} color={'hue300'} />
        </DeleteIconWrapper>
      )}
      {isLoading && (
        <SpinnerWrapper>
          <ClipLoader size={theme.icon.sizes.medium} color={theme.v2.icon.primary} />
        </SpinnerWrapper>
      )}
    </DocumentContainer>
  );
};
