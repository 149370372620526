import { IconProps } from './iconProps';

export const EyeClosedIcon: React.FC<IconProps> = ({ color, strokeColor = 'black' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 22 18" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.60004 16.5L17.6 1.5M12.8 7.44157C13.1735 7.85326 13.4 8.39403 13.4 8.98631C13.4 10.2761 12.3255 11.3217 11 11.3217C10.3889 11.3217 9.8311 11.0994 9.40739 10.7334M2.56123 11.3217C1.73501 10.0848 1.40004 9.07613 1.40004 9.07613C1.40004 9.07613 3.58465 2.1 11 2.1C11.4163 2.1 11.8162 2.12199 12.2 2.16349M5.60004 14.3494C6.97746 15.2281 8.75071 15.8495 11 15.8127C18.3231 15.693 20.6 9.07613 20.6 9.07613C20.6 9.07613 19.5422 5.69808 16.4 3.64332"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
