import { useTheme } from 'styled-components';

import { Text, ThemedIcon } from '../../../../../components';
import { AlertIcon } from '../../../../../iconography';
import { StyledMissingValueContainer } from '../../input.styles';

export const MissingValue = ({ label, isOptional }: { label?: string; isOptional?: boolean }) => {
  const theme = useTheme();
  return (
    <StyledMissingValueContainer $isOptional={!!isOptional}>
      {!isOptional && <ThemedIcon icon={AlertIcon} customStrokeColor={theme.v2.icon.error} size="medium" />}
      {label && <Text color={theme.v2.text.disabled}>{label}</Text>}
    </StyledMissingValueContainer>
  );
};
