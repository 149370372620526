import styled from 'styled-components';

export const UploadFileMultipleStyledWrapper = styled.div<{ $width: string; $height: string }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: ${({ $width }) => $width};
  height: 100%;
`;

export const UploadFileMultipleStyled = styled.div<{
  isDragActive: boolean;
  isLoading: boolean;
  $isError?: boolean;
  $isDisabled: boolean;
}>`
  border-radius: ${({ theme }) => theme.v2.componentsRadius.inputs.large};
  border: ${({ theme, isDragActive, isLoading, $isError }) =>
    isDragActive
      ? `2px dashed ${theme.v2.border.information}`
      : $isError
        ? `1px dashed ${theme.v2.border.error}`
        : isLoading
          ? `1px dashed ${theme.v2.border.information}`
          : `1px dashed ${theme.v2.border.primaryHover};`};

  background: ${({ theme, isDragActive, $isDisabled }) =>
    isDragActive
      ? theme.v2.surface.informationLight
      : $isDisabled
        ? theme.v2.surface.disabled
        : theme.v2.surface.field};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover {
    background: ${({ theme, $isDisabled }) => !$isDisabled && theme.v2.surface.primary};
    cursor: ${({ $isError }) => ($isError ? 'auto' : 'pointer')};
    border: ${({ theme, $isError }) =>
      $isError ? `1px dashed ${theme.v2.border.error}` : `1px dashed ${theme.v2.border.information};`};
    ${({ theme, $isDisabled }) =>
      $isDisabled &&
      `
      cursor: auto; 
      border: 1px dashed ${theme.v2.border.primaryHover}
      `}
  }

  &:active {
    background: ${({ theme, $isDisabled }) => !$isDisabled && theme.v2.surface.field};
    border: ${({ theme, $isError }) =>
      $isError ? `1px dashed ${theme.v2.border.error}` : `1px dashed ${theme.v2.border.information};`};
    ${({ theme, $isDisabled }) =>
      $isDisabled &&
      `
      cursor: auto; 
      border: 1px dashed ${theme.v2.border.primaryHover}
      `}
  }
`;

export const UploadFileMultipleZone = styled.div<{ $width: string; $height: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;

  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
  cursor: default;
  overflow-y: auto;
`;

export const UploadFileTextWrapper = styled.div`
  margin: 0.5rem 0 0.25rem 0;
`;

export const WrapperCustomElements = styled.div`
  padding-top: 1.5rem;
`;
