import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import { ThemedIcon, Text } from '../../../../components';
import { AlertIcon, CheckContained, XcircleContainedIcon, Xicon } from '../../../../iconography';

import { ToastVariant } from './enum';
import { DescriptionContainer, ToastHeaderContainer, CloseIconContainer, ToastContainer } from './toast.styles';

export type ToastProps = {
  heading: string;
  message: string;
  variant: ToastVariant;
  id: string;
  clearToast: (id: string) => void;
};

export const ToastV2 = ({ heading, message, variant, id, clearToast }: ToastProps) => {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  const { icon, iconTextColor, bottomBorderColor } = useMemo(() => {
    switch (variant) {
      case ToastVariant.SUCCESS:
        return {
          icon: CheckContained,
          iconTextColor: theme.v2.text.success,
          bottomBorderColor: theme.v2.surface.success,
        };
      case ToastVariant.WARNING:
        return {
          icon: AlertIcon,
          iconTextColor: theme.v2.text.warning,
          bottomBorderColor: theme.v2.surface.warning,
        };
      case ToastVariant.ERROR:
        return {
          icon: XcircleContainedIcon,
          iconTextColor: theme.v2.text.error,
          bottomBorderColor: theme.v2.surface.error,
        };
      default:
        return {
          icon: AlertIcon,
          iconTextColor: theme.v2.text.error,
          bottomBorderColor: theme.v2.surface.error,
        };
    }
  }, [variant]);

  const closeToast = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => clearToast(id), 600);
  }, [clearToast]);

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 25);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        closeToast();
      }, theme.transitions.durations.toast);
    }
  }, [isOpen, closeToast]);

  return (
    <ToastContainer $isOpen={isOpen} $borderColor={bottomBorderColor}>
      <ToastHeaderContainer>
        <ThemedIcon icon={icon} customStrokeColor={iconTextColor} />
        <Text fontWeight={'semi'} fontSize={'lg'} lineHeight={'h4'} color={iconTextColor}>
          {heading}
        </Text>
        <CloseIconContainer onClick={closeToast}>
          <ThemedIcon size={'medium'} icon={Xicon} customStrokeColor={theme.v2.icon.neutral} />
        </CloseIconContainer>
      </ToastHeaderContainer>
      <DescriptionContainer>
        <Text color={theme.v2.text.disabled}>{message}</Text>
      </DescriptionContainer>
    </ToastContainer>
  );
};

type SingleToastVariationProps = Omit<ToastProps, 'variant'>;

export const SuccessToastV2 = ({ ...props }: SingleToastVariationProps) => {
  return <ToastV2 {...props} variant={ToastVariant.SUCCESS} />;
};

export const WarningToastV2 = ({ ...props }: SingleToastVariationProps) => {
  return <ToastV2 {...props} variant={ToastVariant.WARNING} />;
};

export const ErrorToastV2 = ({ ...props }: SingleToastVariationProps) => {
  return <ToastV2 {...props} variant={ToastVariant.ERROR} />;
};
