import { TextTemplate } from '@metaswiss/ui-kit/src/shared/typography/TextTemplate.styles';
import styled from 'styled-components';

export const StyledInstarctionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1.25rem;

  ${TextTemplate} {
    margin-left: 1rem;
  }
`;

export const InstructionCounter = styled.span`
  height: 1.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  width: 1.75rem;
  border: 1px dashed ${({ theme }) => theme.v2.border.information};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: ${({ theme }) => theme.v2.text.headingPrimary};
  background-color: ${({ theme }) => theme.v2.surface.primary};
`;

export const InstructionWrapper = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    > ${InstructionCounter}:before {
      content: '';
      height: 2rem;
      bottom: -2rem;
      border-left: 1px dashed ${({ theme }) => theme.v2.border.primary};
      position: absolute;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;
