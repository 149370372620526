import { breakpoints } from '@metaswiss/ui-kit/src/breakpoints';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

type WrapperProps = {
  $page?: string;
};

type ContentContainerProps = {
  $page?: string;
};

export const PublicLayoutContainer = styled.div`
  height: 100vh;
  overflow: hidden;

  @media ${breakpoints.laptopSm} {
    display: grid;
    grid-template-columns: 41.66% 58.33%;
  }
`;

export const PublicLayoutMain = styled.main`
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const MainContentContainer = styled.div<{ $isScrollBarActive: boolean }>`
  position: relative;
  height: ${({ $isScrollBarActive }) => ($isScrollBarActive ? 'auto' : '100%')};
  background-color: ${({ theme }) => theme.v2.surface.field};
  ${({ theme }) => {
    if (theme.v2.tenant !== 'MetaSwiss') return null;
    return css`
      &::before {
        content: '';
        display: block;
        width: 31.125rem;
        height: 31.125rem;
        border-radius: 50%;
        filter: blur(12.5rem);
        background-color: ${theme.v2.colors.secondary100};
        opacity: 0.3;
        position: absolute;
        right: -24.28%;
        top: -14.1%;
        transform: translate3d(0, 0, 0);
      }

      &::after {
        content: '';
        background-color: ${theme.v2.colors.primary100};
        opacity: 0.3;
        display: block;
        width: 31.125rem;
        height: 31.125rem;
        border-radius: 50%;
        position: fixed;
        filter: blur(12.5rem);
        left: -15%;
        bottom: -16.6%;
        transform: translate3d(0, 0, 0);
      }

      @media ${breakpoints.laptopSm} {
        &::after {
          left: 26.66%; // 41.66% + -15%
        }
      }
    `;
  }};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: 2;
`;

export const LogoContainer = styled.img`
  margin: 0 auto;
  width: 3.5rem;
  height: auto;
`;

export const InnerWrapper = styled.div<WrapperProps>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  ${({ $page }) => {
    switch ($page) {
      case 'registration':
        return css`
          margin-top: 3rem;
        `;
      case 'registrationWizard':
        return css`
          margin-top: 3rem;
        `;
      case 'authFinish':
        return css`
          justify-content: center;
        `;
      default:
        return ``;
    }
  }}
`;

export const ContentContainer = styled.div<ContentContainerProps>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;

  ${({ $page }) => {
    switch ($page) {
      case 'registration':
        return css`
          justify-content: space-between;
          width: 37.5rem;
          max-height: 51rem;
        `;
      case 'registrationWizard':
        return css`
          justify-content: space-between;
          width: 37.5rem;
          max-height: 51rem;
          @media ${breakpoints.tabletSm} {
            max-height: 53rem;
          }
        `;
      case 'authFinish':
        return css`
          justify-content: space-between;
          width: 100%;
          max-width: 47rem;
          padding: 6rem 0 4.5rem 0;

          @media ${breakpoints.laptopSm} {
            padding: 3rem 1rem 0 1rem;
          }

          @media ${breakpoints.desktop2K} {
            max-height: 50.75rem;
          }
          button {
            width: 100%;
            max-width: 30rem;
            margin: 0 auto;
            display: block;
          }
        `;
      default:
        return css`
          justify-content: flex-start;
          width: 30rem;
          max-height: 39.5rem;
        `;
    }
  }}
`;

export const ContentHeader = styled.div<ContentContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ $page }) => $page === 'authFinish' && '100%'};
  justify-content: ${({ $page }) => $page === 'authFinish' && 'center'};
  ${({ $page }) => $page === 'authFinish' && 'flex-grow: 1;'}
`;

export const ContentFooter = styled.div`
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-top: 2rem;
`;

export const ActionTextWrapper = styled(motion.div)`
  text-align: center;
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StepperWrapper = styled.div`
  margin-top: 1rem;
  width: 100%;
`;
