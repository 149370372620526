import { remSize } from '@metaswiss/lib';
import { FC, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { Text, ThemedIcon } from '../../../../components';
import { PageStateContainerWrapper } from '../../../../components/organism/page-state-container/pageStateContainer.styles.ts';
import { IconProps } from '../../../../iconography/iconProps.ts';
import { BorderRadiusVariant, IconSize, RemSize } from '../../../../theme';

import { IconHolder, TooltipContent, TooltipHolder, Wrapper } from './tooltip.styles.ts';

type TooltipWithIcon = {
  icon: FC<IconProps>;
  iconColor?: string;
  label?: never;
};

type TooltipWithoutIcon = {
  icon?: never;
  iconColor?: never;
  label: string;
};

export type Props = {
  description: string;
  backgroundColor?: string;
  contentColor?: string;
  iconSize?: IconSize;
  borderRadius?: BorderRadiusVariant;
  holderBackgroundColor?: string;
  holderSize?: RemSize;
} & (TooltipWithIcon | TooltipWithoutIcon);

type TooltipHolderPositionProps = {
  leftPosition: RemSize;
  topPosition: RemSize;
};

const minTooltipWidth = 240;

export const TooltipV2: FC<Props> = ({
  icon,
  description,
  iconSize = 'medium',
  holderBackgroundColor,
  contentColor = 'hue700',
  backgroundColor,
  iconColor,
  borderRadius = 'xxl',
  label,
  holderSize = '1.5rem',
}) => {
  const tooltipIconRef = useRef<HTMLDivElement | null>(null);

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isTooltipRightOriented, setIsTooltipRightOriented] = useState<boolean>(true);
  const [tooltipPosition, setTooltipPosition] = useState<TooltipHolderPositionProps>({
    topPosition: '0rem',
    leftPosition: '0rem',
  });

  const onMouseEnter = () => {
    setIsVisible(true);
  };

  const onMouseLeave = () => {
    setIsVisible(false);
  };

  useLayoutEffect(() => {
    const scrollContainer = document.querySelector(`.${PageStateContainerWrapper.styledComponentId}`);

    const updateTooltipPosition = () => {
      if (isVisible) {
        const tooltipSize = tooltipIconRef.current && tooltipIconRef.current.getBoundingClientRect();

        if (tooltipSize) {
          let leftPosition: RemSize;
          let topPosition: RemSize;

          setIsTooltipRightOriented(window.innerWidth > tooltipSize.right + minTooltipWidth + remSize);

          if (isTooltipRightOriented) {
            leftPosition = `${(tooltipSize.left + tooltipSize.width + remSize) / remSize}rem`;
            topPosition = `${(tooltipSize.top + tooltipSize.height / 2) / remSize}rem`;
          } else {
            leftPosition = `${(tooltipSize.left - minTooltipWidth - remSize) / remSize}rem`;
            topPosition = `${(tooltipSize.top + tooltipSize.height / 2) / remSize}rem`;
          }

          setTooltipPosition({ leftPosition, topPosition });
        }
      }
    };

    updateTooltipPosition();

    window.addEventListener('resize', updateTooltipPosition);
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', updateTooltipPosition);
    }

    return () => {
      window.removeEventListener('resize', updateTooltipPosition);
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', updateTooltipPosition);
      }
    };
  }, [isVisible, isTooltipRightOriented]);

  return (
    <Wrapper>
      <IconHolder
        ref={tooltipIconRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        $backgroundColor={holderBackgroundColor}
        $size={holderSize}
        $borderRadius={borderRadius}
      >
        {icon && <ThemedIcon icon={icon} customStrokeColor={iconColor} size={iconSize} />}
        {label && (
          <Text fontSize={'xsm'} lineHeight={'extraSmall'} fontWeight={'semi'}>
            {label}
          </Text>
        )}
      </IconHolder>
      {isVisible &&
        createPortal(
          <TooltipHolder
            $leftPosition={tooltipPosition.leftPosition}
            $topPosition={tooltipPosition.topPosition}
            $isTooltipRightOriented={isTooltipRightOriented}
            $backgroundColor={backgroundColor}
          >
            <TooltipContent $backgroundColor={backgroundColor}>
              <Text fontSize={'xsm'} lineHeight={'extraSmall'} color={contentColor}>
                {description}
              </Text>
            </TooltipContent>
          </TooltipHolder>,
          document.body
        )}
    </Wrapper>
  );
};
