export const getDateLabels = (value: string | number, interval: 'year' | 'quarter' | 'month' | 'day') => {
  const date = new Date(value);
  if (interval === 'year') {
    return date.getFullYear();
  }
  if (interval === 'quarter') {
    const month = date.getMonth();
    const quarter = Math.floor(month / 3) + 1;
    return `Q${quarter}`;
  }
  if (interval === 'month') {
    const monthNames = ['JA', 'FE', 'MR', 'AP', 'MA', 'JN', 'JL', 'AU', 'SE', 'OC', 'NO', 'DE'];
    return monthNames[date.getMonth()];
  }

  if (interval === 'day') {
    return date.toLocaleDateString();
  }
};
