import styled from 'styled-components';

import { ChipProps } from './Chip.tsx';

export const StyledChipContainer = styled.div<{
  $status: ChipProps['status'];
}>`
  padding: ${({ theme }) => `${theme.v2.spacing['0.25']} ${theme.v2.spacing['0.5']}`};
  border-radius: ${({ theme }) => theme.v2.radius.full};
  display: flex;
  align-items: center;
  user-select: none;
  line-height: 1rem;
  ${({ $status, theme }) => {
    switch ($status) {
      case 'disabled':
        return `background-color: ${theme.v2.surface.secondary}`;
      case 'error':
        return `background-color: ${theme.v2.surface.errorLight}`;
      case 'neutral':
        return `background-color: ${theme.v2.surface.tertiary}`;
      case 'primary':
        return `background-color: ${theme.v2.surface.informationLight}`;
      case 'secondary':
        return `background-color: ${theme.v2.surface.action2Light}`;
      case 'success':
        return `background-color: ${theme.v2.surface.successLight}`;
      case 'warning':
        return `background-color: ${theme.v2.surface.warningLight}`;
    }
  }}
`;

export const StyledChipText = styled.span<{
  $status: ChipProps['status'];
}>`
  font-size: ${({ theme }) => theme.typography.sizes.xsm};
  font-weight: ${({ theme }) => theme.typography.weights.semi};
  line-height: ${({ theme }) => theme.typography.lineHeight.extraSmall};
  display: flex;
  ${({ $status, theme }) => {
    switch ($status) {
      case 'disabled':
        return `color: ${theme.v2.text.disabled}`;
      case 'error':
        return `color: ${theme.v2.text.error}`;
      case 'neutral':
        return `color: ${theme.v2.text.bodyPrimary}`;
      case 'primary':
        return `color: ${theme.v2.text.information}`;
      case 'secondary':
        return `color: ${theme.v2.text.action2}`;
      case 'success':
        return `color: ${theme.v2.text.success}`;
      case 'warning':
        return `color: ${theme.v2.text.warning}`;
    }
  }}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
`;
