import { motion } from 'framer-motion';
import styled from 'styled-components';

import { breakpoints } from '../../../breakpoints';

export const FloatButtonWrapper = styled.div`
  position: fixed;
  bottom: 8rem;
  right: 2rem;
  z-index: 1;
  display: flex;
  width: 17.5rem;
  align-items: flex-end;
  flex-direction: column;
  gap: 1rem;
  @media ${breakpoints.laptopSm} {
    bottom: 2rem;
    right: 2rem;
  }
`;
export const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  top: 1rem;
`;
export const FloatButtonIconWrapper = styled.div<{ $backgroundColor: string; $hoverBackgroundColor: string }>`
  position: relative;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &:hover {
    background-color: ${({ $hoverBackgroundColor }) => $hoverBackgroundColor};
  }
`;
export const FloatButtonHolder = styled.div`
  display: flex;
  height: 4rem;
  width: 4rem;
  align-items: center;
  justify-content: center;
`;

export const FloatButtonLabel = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  backdrop-filter: blur(60%);
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0.5rem;
  z-index: 1;
  right: 4.5rem;
`;

export const FloatContentContainer = styled.div`
  display: block;
`;
