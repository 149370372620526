import { createContext, FC, useCallback, useContext, useMemo, useRef, useState } from 'react';

import { WizardComponent } from '../Wizard';
import { OnBackHandler, OnNextHandler, Step, WizardButtonProps, WizardContext, WizardProps } from '../wizardTypes';

import { useWizardReducer } from './wizardState';
import { createInitialState } from './wizardUtils';

const WizardContext = createContext({} as WizardContext);

WizardContext.displayName = 'WizardContext';

export const useWizardContext = () => useContext(WizardContext);

export const Wizard: FC<WizardProps> = (props) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const initialState = useMemo(() => createInitialState(props.steps), []);
  const [state, dispatch] = useWizardReducer(initialState);
  const [wizardSteps, setWizardSteps] = useState<Step[]>(props.steps);
  const [wizardHeader, setWizardHeader] = useState<React.ReactElement<unknown>>();
  const [onBackClick, setOnBackClick] = useState<OnBackHandler>();
  const [onNextClick, setOnNextClick] = useState<OnNextHandler>();

  const setOnBackClickHandler = useCallback((handler: (() => void) | null) => setOnBackClick(() => handler), []);

  const setOnNextClickHandler = useCallback((handler: (() => void) | null) => setOnNextClick(() => handler), []);

  const onNextClickAction = useRef<() => Promise<void>>();
  const onNextErrorCallback = useRef<(err: Error) => void>();

  const setOnNextClickAction = (action: () => Promise<void>) => {
    onNextClickAction.current = action;
  };

  const setOnNextErrorCallback = (action: (err: Error) => void) => {
    onNextErrorCallback.current = action;
  };

  const modifyWizardHeader = (header: React.ReactElement<unknown>) => {
    setWizardHeader(header);
  };

  const modifyNextButtonProps = useCallback(
    (partialProps: Partial<WizardButtonProps>) => {
      dispatch({
        type: 'insertNextProps',
        position: activeStep,
        props: partialProps,
      });
    },
    [dispatch, activeStep]
  );
  const modifyBackButtonProps = useCallback(
    (partialProps: Partial<WizardButtonProps>) => {
      dispatch({
        type: 'insertBackProps',
        position: activeStep,
        props: partialProps,
      });
    },
    [dispatch, activeStep]
  );

  const defaultBackButtonClickHandler = () => {
    goToPreviousPage();
  };

  const defaultNextButtonClickHandler = () => {
    if (!onNextClickAction.current) {
      goToNextPage();
      return;
    }

    modifyNextButtonProps({ loading: true });

    onNextClickAction
      .current()
      .then(() => {
        modifyNextButtonProps({ loading: false });
        goToNextPage();
      })
      .catch((err) => {
        modifyNextButtonProps({ error: true });
        if (onNextErrorCallback.current) {
          onNextErrorCallback.current(err);
        }
      });
  };

  const goToNextPage = () => {
    if (activeStep === wizardSteps.length - 1) return;
    setActiveStep(activeStep + 1);
  };

  const goToPreviousPage = () => {
    if (activeStep <= 0) return;
    setActiveStep(activeStep - 1);
  };

  const contextValue = useMemo<WizardContext>(
    () => ({
      setWizardSteps,
      activeStep,
      modifyBackButtonProps,
      modifyNextButtonProps,
      setOnNextClickAction,
      setOnNextErrorCallback,
      modifyWizardHeader,
      ejected: {
        goToPreviousPage,
        goToNextPage,
        setOnNextClickHandler,
        setOnBackClickHandler,
      },
    }),
    [
      setWizardSteps,
      activeStep,
      modifyBackButtonProps,
      modifyNextButtonProps,
      goToPreviousPage,
      goToNextPage,
      setOnNextClickHandler,
      setOnBackClickHandler,
    ]
  );

  return (
    <WizardContext.Provider value={contextValue}>
      <WizardComponent
        hasStepper={props.hasStepper}
        header={wizardHeader ?? props.header}
        steps={wizardSteps}
        currentStep={activeStep}
        onBackButtonClicked={onBackClick ?? defaultBackButtonClickHandler}
        onNextButtonClicked={onNextClick ?? defaultNextButtonClickHandler}
        nextButtonProps={state.nextButtonProps[activeStep]}
        backButtonProps={state.backButtonProps[activeStep]}
        footerLink={props.footerLink}
        footerText={props.footerText}
      />
    </WizardContext.Provider>
  );
};
