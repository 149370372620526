/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ShareTypeOptionResponse } from '../models/ShareTypeOptionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ShareTypeApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns ShareTypeOptionResponse Fetch all share type options
   * @throws ApiError
   */
  public getAllShareTypeOptions(): CancelablePromise<Array<ShareTypeOptionResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/share-type-option',
      errors: {
        500: `Bad Request`,
      },
    });
  }

}
