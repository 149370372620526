import * as zod from 'zod';

import { FormNames } from '../../../../../shared/forms/formNames';

export const createDomicileSchema = () =>
  zod.object({
    file: zod.object({ fileId: zod.string().optional(), url: zod.string().optional() }),
  });

export const schema = createDomicileSchema();

export type DomicileFormData = zod.infer<typeof schema>;

export const domicileSchema: FormNames<DomicileFormData> = {
  file: 'file',
} as const;
