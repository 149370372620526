import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ButtonWrapper = styled.div`
  margin-top: 1.5rem;
`;
