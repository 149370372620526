/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvailabilityOptionResponse } from '../models/AvailabilityOptionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AvailabilityOptionApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns AvailabilityOptionResponse Fetch all availability options
   * @throws ApiError
   */
  public getAvailabilityOptions(): CancelablePromise<Array<AvailabilityOptionResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v5/availability-option',
      errors: {
        500: `Bad Request`,
      },
    });
  }

}
