import { FC } from 'react';

import { IconProps } from './iconProps';

export const PortfolioBondIcon: FC<IconProps> = ({ color = 'none', strokeColor = 'white' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 248 140" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.1">
        <path
          d="M48.266 112.786L86.3442 59.239L133.485 90.0532L198.762 -1.74093M198.762 -1.74093L138.928 9.57119M198.762 -1.74093L209.639 55.7925"
          stroke={strokeColor}
          strokeWidth="12"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
